import { Paragraph, UppercaseTitle, Title } from '../styles';

const YourAccount = () => (
  <>
    <UppercaseTitle>4. Your Account with Empower Global</UppercaseTitle>
    <Paragraph>
      Although guest checkout is available to all of our customers, you’ll need
      to create an account with Empower Global to fully use our Services. Here
      are a few rules about the process:
    </Paragraph>
    <Title>A. You must be 18 years or older to use our Services</Title>
    <Paragraph>
      Minors under 18 and at least 13 years of age are only permitted to use our
      Services through an account owned by a parent or legal guardian, with
      their appropriate permission, and under their direct supervision. Children
      under 13 years of age are not permitted to use Empower Global or the
      Services. You are responsible for any and all account activity conducted
      by a minor on your account, and there may be commercial products or
      services available that you may want to consider utilizing to help limit a
      minor&apos;s access to online material.
    </Paragraph>
    <Title>B. Be honest with us</Title>
    <Paragraph>
      Provide accurate information about yourself. It’s prohibited to use false
      information or impersonate another person or company through your account.
    </Paragraph>
    <Title>C. Choose an appropriate name</Title>
    <Paragraph>
      If you decide to not have your full name serve as the name associated with
      your account, you may not use language to describe yourself that is
      offensive, vulgar, infringes someone’s intellectual property rights, or
      otherwise violates the Terms.
    </Paragraph>
    <Title>D. You&apos;re responsible for your account</Title>
    <Paragraph>
      You’re solely responsible for any activity on your account. If you’re
      sharing an account with other people, then the person whose financial
      information is on the account will ultimately be responsible for all
      activity. If you’re registering as a business entity, you personally
      guarantee that you have the authority to agree to the Terms on behalf of
      the business. Lastly, your accounts are non-transferable.
    </Paragraph>
    <Title>E. Protect your password</Title>
    <Paragraph>
      As we mentioned above, you’re solely responsible for any activity on your
      account, so it’s important to keep your account password secure.
    </Paragraph>
    <Title>F. Let&apos;s be clear about our relationship</Title>
    <Paragraph>
      The Terms don&apos;t create any agency, partnership, joint venture,
      employment, or franchisee relationship between you and Empower Global. You
      and Empower Global are and remain independent contractors.
    </Paragraph>
  </>
);

export default YourAccount;
