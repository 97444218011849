import { combineReducers } from 'redux';
import { publicUserReducer } from './slices/publicUserSlice';
import { categoriesReducer } from './slices/categoriesSlice';
import { modalReducer } from './slices/modalSlice';
import { productListReducer } from './slices/productListSlice';
import { productReducer } from './slices/productSlice';
import { customerProductListsReducer } from './slices/customerProductLists';
import { brandsListReducer } from './slices/brandListSlice';
import { brandReducer } from './slices/brandSlice';
import { basketReducer } from './slices/basketSlice';
import { customerReducer } from './slices/customerSlice';
import { orderReducer } from './slices/orderSlice';
import { productSearchResultReducer } from './slices/productSearchResultSlice';
import { savedSearchesReducer } from './slices/savedSearchesSlice';

const rootReducer = combineReducers({
  publicUserReducer,
  categoriesReducer,
  modalReducer,
  productListReducer,
  productReducer,
  customerProductListsReducer,
  brandsListReducer,
  brandReducer,
  basketReducer,
  customerReducer,
  orderReducer,
  productSearchResultReducer,
  savedSearchesReducer,
});

export const persistentStoreBlacklist = [
  'modalReducer',
  'productListReducer',
  'categoriesReducer',
  'productReducer',
  'productSearchResultReducer',
  'savedSearchesReducer',
];
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
