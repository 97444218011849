import React, { useState, MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Menu, Tab, Tabs, Box, Grid } from '@mui/material';

import { Loader, TextButton } from 'components';
import { getBrandsList } from 'eg_SFCC_FE_core/store/selectors';
import ROUTES from 'router/Routes';
import useDimensions from 'hooks/useDimensions';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import { formatNameForUrl } from 'helpers/formatters';
import { CatalogItemType } from 'types/CatalogTypes';
import { featuredBrands } from 'data/brands';
import BrandsNavigation from './BrandsNavigation';

import {
  StyledSubcategoryTextButton,
  StyledCategoryTextButton,
} from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, paddingLeft: 'calc(10px + 5%)' }}>{children}</Box>
      )}
    </div>
  );
}

const NavigationMenu = ({
  catalogData,
}: {
  catalogData: CatalogItemType[] | undefined;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { height } = useDimensions();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const brandsList = useSelector(getBrandsList);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = useCallback(
    (
      props:
        | string
        | {
            catName?: string;
            catId?: string;
          },
    ) => {
      handleClose();
      if (typeof props === 'string') {
        navigate(props);
      } else if (props.catName && props.catId) {
        navigate(
          `${ROUTES.PRODUCTS}/${formatNameForUrl(props.catName)}/${
            props.catId
          }`,
        );
      }
    },
    [],
  );

  const filteredCatalog = catalogData?.filter((item) => !!item.display);
  const navigationTabs = filteredCatalog?.map((item) => item.name);
  const navigationContents = filteredCatalog?.map((item) => {
    return item.name.toLowerCase() === 'brands'
      ? { categories: [], isBrands: true }
      : { categories: item.categories };
  });

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        size="small"
        sx={{ p: 0 }}
        aria-label="open hamburger menu"
        aria-controls={open ? 'hamburger-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableRipple
        id="hamburger-menu"
      >
        <TextButton aria-label="shop" onClick={() => {}} label="shop" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="hamburger-menu"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: height - HEADER_NAV_HEIGHT - 100,
            overflow: 'auto',
            width: '100%',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: 0,
            mt: HEADER_NAV_HEIGHT / 28,
            '& .MuiList-root': {
              padding: 0,
            },
          },
        }}
      >
        {catalogData ? (
          <div style={{ width: '100vw' }}>
            <Box sx={{ maxWidth: '1300px', margin: '0 auto' }}>
              <Box>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  visibleScrollbar={false}
                  sx={{
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiButtonBase-root': {
                      textAlign: 'left',
                      fontWeight: 'bold',
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                >
                  {navigationTabs?.map((navigationTab) => {
                    return (
                      <Tab
                        label={navigationTab}
                        disableRipple
                        style={{ fontSize: 16, fontFamily: 'HelveticaBold' }}
                      />
                    );
                  })}
                </Tabs>
              </Box>

              {navigationContents?.map((navigationContent: any, index: any) => {
                return (
                  <CustomTabPanel value={tabValue} index={index}>
                    {navigationContent.isBrands ? (
                      <BrandsNavigation
                        brandsList={brandsList}
                        onClickCallback={navigateTo}
                      />
                    ) : (
                      <Grid container direction="row" spacing={0}>
                        {navigationContent.categories?.map((navCat: any) => {
                          return (
                            <Grid
                              item
                              xs="auto"
                              style={{ marginRight: '120px' }}
                            >
                              <StyledCategoryTextButton
                                label={navCat.name}
                                onClick={() =>
                                  navigateTo({
                                    catName: navCat.name,
                                    catId: navCat.id,
                                  })
                                }
                              />

                              {navCat.categories?.map((navSubcat: any) => {
                                return (
                                  <StyledSubcategoryTextButton
                                    label={navSubcat.name}
                                    onClick={() =>
                                      navigateTo({
                                        catName: navSubcat.name,
                                        catId: navSubcat.id,
                                      })
                                    }
                                  />
                                );
                              })}
                            </Grid>
                          );
                        })}

                        <Grid item xs="auto">
                          <StyledCategoryTextButton
                            label="Featured Brands"
                            onClick={() => {}}
                          />

                          {featuredBrands.map((brand: any) => {
                            return (
                              <StyledSubcategoryTextButton
                                label={brand.brandName}
                                onClick={() =>
                                  navigateTo(`${ROUTES.BRANDS}/${brand.id}`)
                                }
                              />
                            );
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </CustomTabPanel>
                );
              })}
            </Box>
          </div>
        ) : (
          <Loader />
        )}
      </Menu>
    </div>
  );
};

export default NavigationMenu;
