import styled from 'styled-components';

export const FullImagesWrapper = styled.div`
  overflow: auto;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;
