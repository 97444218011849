import { Typography } from 'components';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import styled from 'styled-components';
import { FONTS, COLORS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_NAV_HEIGHT}px);
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-height: 700px) {
    height: auto;
  }
`;

export const AboutLoyaltyBlock = styled.div`
  max-width: 750px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 1000px) {
    padding: 20px;
  }

  @media (max-width: 1440px) {
    width: 90%;
  }
`;

export const AboutText = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 24px;
  line-height: 26px;

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const ProgramMemberWrapper = styled.div`
  margin-top: 25px;
`;

export const DarkTitle = styled.h1`
  font-family: ${FONTS.Bison.bold};
  font-weight: 600;
  font-size: 300px;
  line-height: 200px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${COLORS.black};

  @media (max-width: 1000px) {
    font-size: 160px;
    bottom: 30px;
    line-height: 120px;
  }

  @media (max-width: 650px) {
    font-size: 120px;
    line-height: 100px;
  }
`;

export const DarkSubtitle = styled(DarkTitle)`
  font-size: 130px;

  @media (max-width: 1000px) {
    font-size: 70px;
  }

  @media (max-width: 650px) {
    font-size: 50px;
  }
`;
