export const pressSlides = [
  {
    id: 1,
    quote:
      '“Being a part of a community that is committed to growing my brand, not just by exposure but through guidance & resources, made the choice to be a part of Empower Global a no-brainer.”',
    author: 'Lenise, .Made Leather Co',
    background: 'assets/jpg/seller/lenise.jpg',
    brandId: 'U2VsbGVyLTk1',
  },
  {
    id: 2,
    quote:
      '“We’re a part of Empower Global because of our mutual pursuit to uplift and amplify the beauty and authenticity of the Black community.”',
    author: 'Jamila, Jamila Mariama',
    background: 'assets/jpg/seller/jamila.jpg',
    brandId: 'U2VsbGVyLTcy',
  },
  {
    id: 3,
    quote:
      '"We believe in Empower Global’s mission to build the Black economy. Nta-woven connects the tapestry of women’s lives across the globe through the vibrant colors of African fashion. By supporting the economic vitality of Black women, we also build vibrant Black families and communities."',
    author: 'Zubi and Jasmine, NTA Wowens',
    background: 'assets/jpg/seller/zubi.jpg',
    brandId: 'U2VsbGVyLTE0NQ==',
  },
  {
    id: 4,
    quote:
      '"Being based between Togo, West Africa and the U.S. we believe that it literally takes a village. Coming from our village in the mountains of Togo we are thankful to be a part of the Empower Global Community which unites us worldwide. Platforms like this are needed and necessary."',
    author: 'Tabi, Itadi',
    background: 'assets/jpg/seller/tabi.jpg',
    brandId: 'U2VsbGVyLTE0OA==',
  },
];
