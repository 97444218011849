import styled from 'styled-components';
import { Box } from '@mui/material';

import { Typography } from 'components';
import { COLORS } from 'styles';

export const FormWrapper = styled(Box)`
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  position: relative;
  align-items: flex-end;
`;

export const ErrorText = styled(Typography)`
  color: ${COLORS.accent};
  font-size: 12px;
  position: absolute;
  bottom: -20px;
`;
