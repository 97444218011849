import styled from 'styled-components';

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 320px;

  & svg {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  margin-right: 1rem;
`;
