import { FC, ReactNode } from 'react';

import { Loader } from 'components';
import { ColoredButtonText, ColoredButtonWrapper } from './styles';

export const ColoredButton: FC<any> = ({
  children,
  isLoading,
  inverted,
  fontStyle,
  ...props
}: {
  children: ReactNode;
  isLoading?: boolean;
  inverted?: boolean;
  fontStyle?: any;
}) => {
  return (
    <ColoredButtonWrapper {...props} inverted={inverted}>
      {isLoading ? (
        <Loader />
      ) : (
        <ColoredButtonText
          color={inverted ? 'primaryInverted' : 'primary'}
          style={fontStyle}
        >
          {children}
        </ColoredButtonText>
      )}
    </ColoredButtonWrapper>
  );
};
