import {
  IconWithInfoWrapper,
  IconWrapper,
  InfoSubtitle,
  InfoTitle,
} from './styles';

const IconWithInfo = ({
  title,
  subtitle,
  Icon,
}: {
  title: string;
  subtitle: string;
  Icon: any;
}) => {
  return (
    <IconWithInfoWrapper>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <InfoTitle>{title}</InfoTitle>
      <InfoSubtitle>{subtitle}</InfoSubtitle>
    </IconWithInfoWrapper>
  );
};

export default IconWithInfo;
