import styled from 'styled-components';
import {
  ArrowRightIcon,
  DoubleArrowRightIcon,
  PaginationArrowLeftIcon,
} from 'assets/svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { Typography } from 'components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 70px;
  padding: 0 80px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    padding: 0 15px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    justify-content: center;
    margin: 20px 0;
    padding: 0;
  }

  .Mui-selected {
    color: ${COLORS.black};
  }

  & button {
    font-size: 16px;
    line-height: 19px;
    font-family: ${FONTS.Helvetica.bold};
    color: #828989;

    @media (max-width: ${BREAKPOINTS.xs}px) {
      font-size: 12px;
      padding: 0 2px;
    }

    &.MuiPaginationItem-root.Mui-selected {
      background-color: transparent;
    }
  }
`;

export const Text = styled(Typography).attrs({
  type: 'nav',
})`
  opacity: 0.6;
  font-family: ${FONTS.TTNorms.regular};

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)<{
  disabled: boolean;
}>`
  cursor: ${({ disabled }) => {
    return disabled ? 'auto' : 'pointer';
  }};
  color: ${({ disabled }) => {
    return disabled ? COLORS.border : 'auto';
  }};
`;

export const Rotated180Icon = styled(StyledArrowRightIcon)`
  transform: rotate(180deg);
`;

export const Rotated180DoubleIcon = styled(DoubleArrowRightIcon)`
  transform: rotate(180deg);
`;

export const PaginationArrowRightIcon = styled(PaginationArrowLeftIcon)`
  transform: rotate(180deg);
`;
