import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import uuid from 'react-uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import { getCustomerError } from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { CustomerAddressType, RegisterParamsType } from 'eg_SFCC_FE_core/types';
import { customerActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerSlice';
import ROUTES from 'router/Routes';
import { Typography, LabelCheckbox } from 'components';
import { SuggestedAddressBox } from 'components/AccountComponents/styles';
import FormInputController from 'components/Inputs/FormInputController';
import BasicAutocomplete from 'components/Dropdowns/Selects/BasicAutocomplete';
import AddressAutocomplete from 'components/Dropdowns/Selects/AddressAutocomplete';
import StatesSelect from 'components/Dropdowns/Selects/StateSelect';
import BasicDatePicker from 'components/Dropdowns/Selects/BasicDatePicker';
import CustomNavLink from 'components/Links/CustomNavLink';
import { getLoyaltySignUpFormSchema } from 'helpers/validationSchemas';
import { countries, states } from 'data/autocompleteData';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { addressErrorStatuses } from '../AddressForm/AddressForm';
import AddressTooltip from '../AddressTooltip';
import ErrorTooltip from '../ErrorTooltip';
import { FieldsBox, FormWrapper } from '../styles';
import { StyledColoredButton } from './styles';

interface IFormValues {
  firstName: string;
  lastName: string;
  pronouns: string;
  email: string;
  password: string;
  confirmPassword: string;
  address1: string;
  address2: string;
  city: string;
  stateCode: string;
  countryCode: string;
  phone: string;
  postalCode: string;
  birthday: string;
}

const LoyaltyRegistrationForm = ({
  isLoggedIn,
  customerData,
  isLoading,
  ageRestriction,
  registerCustomer,
  setAddressValues,
  suggestedAddress,
  setSuggestedAddress,
  updatePersonalInfo,
}: {
  isLoggedIn: boolean;
  customerData: any;
  isLoading: boolean;
  ageRestriction: boolean;
  registerCustomer: (params: RegisterParamsType) => void;
  setAddressValues: Dispatch<SetStateAction<CustomerAddressType | null>>;
  suggestedAddress?: AddressFormFieldsType;
  setSuggestedAddress: Dispatch<
    SetStateAction<AddressFormFieldsType | undefined>
  >;
  updatePersonalInfo: (params: any) => void;
}) => {
  const dispatch = useAppDispatch();
  const customerError = useSelector(getCustomerError);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isChecked, setCheckboxValue] = useState<boolean>(true);

  const defaultValues = {
    firstName: '',
    lastName: '',
    pronouns: '',
    email: '',
    password: '',
    confirmPassword: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    countryCode: '',
    postalCode: '',
    phone: '',
    birthday: '',
  };

  const methods = useForm<IFormValues>({
    resolver: yupResolver(
      getLoyaltySignUpFormSchema(isLoggedIn, customerData.birthday),
    ),
    defaultValues,
  });

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();

  const onSubmit: SubmitHandler<IFormValues> = (data: IFormValues, e: any) => {
    if (data) {
      if (!isLoggedIn) {
        registerCustomer({
          customer: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            login: data.email,
            birthday: data.birthday,
            c_LoyaltyMember: isChecked,
          },
          password: data.password,
        });
        setAddressValues({
          addressId: uuid(),
          firstName: data.firstName,
          lastName: data.lastName,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          stateCode: data.stateCode,
          countryCode: data.countryCode,
          postalCode: data.postalCode,
          phone: data.phone,
        });
      }

      if (isLoggedIn && !customerData.birthday) {
        updatePersonalInfo({
          birthday: data.birthday,
          c_LoyaltyMember: isChecked,
        });
      }

      if (isLoggedIn) {
        updatePersonalInfo({
          c_LoyaltyMember: isChecked,
        });
      }
    }
    e.target.reset();
  };

  const onError = () => {};

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmitSuggestedAddress = () => {
    methods.reset({ ...values, ...suggestedAddress });
    dispatch(customerActions.clearError());
    setSuggestedAddress(undefined);
  };

  const updateAddressFields = () => {
    const fullAddress = `${values.address1} ${values.address2}`;
    if (fullAddress.length <= 30) {
      setValue('address1', fullAddress);
      setValue('address2', '');
    }
  };

  useEffect(() => {
    if (customerError) {
      dispatch(customerActions.clearError());
    }

    return () => {
      dispatch(customerActions.clearError());
    };
  }, []);

  return (
    <FormWrapper
      id="loyalty_signup_form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      {!isLoggedIn && (
        <>
          <FieldsBox>
            <FormInputController
              control={control}
              name="firstName"
              label="First Name"
              error={!!errors.firstName}
              errorMessage={errors?.firstName?.message}
            />
            <FormInputController
              control={control}
              name="lastName"
              label="Last Name"
              error={!!errors.lastName}
              errorMessage={errors?.lastName?.message}
            />
          </FieldsBox>
          <FormInputController
            control={control}
            name="pronouns"
            label="Pronouns"
            error={!!errors.pronouns}
            errorMessage={errors?.pronouns?.message}
          />
          <FormInputController
            control={control}
            name="email"
            label="Email Address"
            error={!!errors.email}
            errorMessage={errors?.email?.message}
          />
          <FormInputController
            control={control}
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            error={!!errors.password}
            errorMessage={errors?.password?.message}
            InputProps={{
              endAdornment: (
                <>
                  {!!errors.password && (
                    <ErrorTooltip
                      title={errors.password.message}
                      placement="right-start"
                    />
                  )}
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
          <FormInputController
            control={control}
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            error={!!errors.confirmPassword}
            errorMessage={errors?.confirmPassword?.message}
            InputProps={{
              endAdornment: (
                <>
                  {!!errors.confirmPassword && (
                    <ErrorTooltip
                      title={errors.confirmPassword.message}
                      placement="right-start"
                    />
                  )}
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />

          <AddressAutocomplete
            control={control}
            name="address1"
            label="Address Line 1"
            values={values}
            methods={methods}
            defaultValue={values.address1}
            error={!!errors.address1}
            errorMessage={errors?.address1?.message}
          />
          <FormInputController
            control={control}
            name="address2"
            label="Apartment, Suite, Building (Optional)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AddressTooltip />
                </InputAdornment>
              ),
            }}
            error={!!errors.address2}
            errorMessage={errors?.address2?.message}
            handleAddressesOnBlur={updateAddressFields}
          />
          <FieldsBox>
            <BasicAutocomplete
              name="countryCode"
              control={control}
              label="Country"
              defaultValue={values.countryCode}
              error={!!errors.countryCode}
              errorMessage={errors?.countryCode?.message}
              items={countries}
              freeInput
            />
            <StatesSelect
              name="stateCode"
              control={control}
              label="State"
              defaultValue={values.stateCode}
              error={!!errors.stateCode}
              errorMessage={errors?.stateCode?.message}
              items={states}
            />
          </FieldsBox>
          <FieldsBox>
            <FormInputController
              name="city"
              control={control}
              label="City"
              error={!!errors.city}
              errorMessage={errors?.city?.message}
            />
            <FormInputController
              control={control}
              name="postalCode"
              label="ZIP code"
              error={!!errors.postalCode}
              errorMessage={errors?.postalCode?.message}
            />
          </FieldsBox>
          <FormInputController
            control={control}
            name="phone"
            label="Phone Number"
            error={!!errors.phone}
            errorMessage={errors?.phone?.message}
          />
        </>
      )}

      {!customerData.birthday && (
        <BasicDatePicker
          name="birthday"
          control={control}
          label="Birthday"
          defaultValue={values.birthday}
          error={!!errors.birthday}
          errorMessage={errors?.birthday?.message}
        />
      )}

      {ageRestriction && (
        <Typography color="accent" style={{ fontSize: 14 }}>
          Due to age restrictions, you are not eligible to sign up.
        </Typography>
      )}

      {customerError && (
        <Typography color="accent" style={{ fontSize: 14 }}>
          {customerError.status_code === 502 ||
          (customerError.status_code === 400 &&
            customerError.statusCode === 'is_not_vip_customer')
            ? 'You are not allowed to register.'
            : addressErrorStatuses[customerError?.statusCode] ||
              customerError.title}
        </Typography>
      )}

      {suggestedAddress && (
        <SuggestedAddressBox onClick={handleSubmitSuggestedAddress}>
          <Typography type="subtitle3" style={{ marginBottom: 10 }}>
            Use suggested address
          </Typography>
          {suggestedAddress.address1}
          {suggestedAddress.address2 ? (
            <>
              <br />
              {suggestedAddress.address2}
            </>
          ) : null}
          <br />
          {suggestedAddress.city}, {suggestedAddress.stateCode}{' '}
          {suggestedAddress.postalCode} <br />
        </SuggestedAddressBox>
      )}

      <LabelCheckbox
        label="I consent to joining Empower Global's loyalty program, EG Elite."
        handleChange={() => setCheckboxValue(!isChecked)}
        checked={isChecked}
      />

      <Typography
        type="body"
        style={{
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 'normal',
          marginTop: 10,
        }}
      >
        For more information see EG Elite&apos;s{' '}
        <CustomNavLink
          to={ROUTES.TERMS_CONDITIONS}
          style={{ color: 'black', fontWeight: 700 }}
        >
          Terms & Conditions.
        </CustomNavLink>
      </Typography>

      <StyledColoredButton
        aria-label="join now"
        isLoading={isLoading}
        disabled={!isChecked}
      >
        join now
      </StyledColoredButton>
    </FormWrapper>
  );
};

export default LoyaltyRegistrationForm;
