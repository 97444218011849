import { useLoyaltyModals } from 'hooks/useModals';
import { formatTierName } from 'helpers/formatters';
import { WBackground } from 'assets/svg';
import { AccentColoredButton } from 'components';
import {
  Wrapper,
  DarkSubtitle,
  DarkTitle,
  AboutLoyaltyBlock,
  ProgramMemberWrapper,
  AboutText,
} from './styles';

const LoyaltyMainSection = ({
  currentTierName,
  isLoyaltyMember,
}: {
  currentTierName: string;
  isLoyaltyMember: boolean;
}) => {
  const { showLoyaltyRegistrationModal } = useLoyaltyModals();
  return (
    <Wrapper>
      <WBackground
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 0,
          right: '-20%',
        }}
      />
      <div style={{ zIndex: 1 }}>
        <DarkTitle>eg elite</DarkTitle>
        <DarkSubtitle>loyalty program</DarkSubtitle>
      </div>
      <AboutLoyaltyBlock>
        <AboutText>
          EG Elite is a free rewards program offered by Empower Global. Account
          holders are eligible to receive tier benefits and rewards. You will
          receive 50 points just for joining! Once you&apos;re a member,
          you&apos;ll earn 1 point for every $1 spent!
        </AboutText>
        <ProgramMemberWrapper>
          {isLoyaltyMember ? (
            <p>
              Thank you for being a {formatTierName(currentTierName, 'Member ')}
              of EG Elite Loyalty Program!
            </p>
          ) : (
            <AccentColoredButton
              padding="20px 58px"
              aria-label="join eg elite"
              onClick={showLoyaltyRegistrationModal}
            >
              join eg elite
            </AccentColoredButton>
          )}
        </ProgramMemberWrapper>
      </AboutLoyaltyBlock>
    </Wrapper>
  );
};

export default LoyaltyMainSection;
