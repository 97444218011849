import styled from 'styled-components';
import { Box } from '@mui/material';
import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';

export const FormWrapper = styled(Box)`
  max-width: 400px;
  width: 100%;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const Title = styled(Typography).attrs({
  type: 'subtitle4Helv',
})`
  opacity: 0.6;
`;
