import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const SavedSearchWrapper = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${COLORS.black}30;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 20px 0;
  }
`;

export const SavedSearchHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const SavedSearchName = styled(Typography)`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 14px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;

export const SavedSearchActionsWrapper = styled.div`
  display: flex;

  & a,
  & button {
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    opacity: 0.6;
    color: ${COLORS.black};
  }

  & button {
    margin-left: 30px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    & a,
    & button {
      font-size: 12px;
    }
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    & a,
    & button {
      font-size: 8px;
    }
  }
`;

export const ProductCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 7px;
  }
`;

export const ProductCardWrapper = styled.div`
  flex-basis: calc(33.33% - 15px);

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-basis: calc(50% - 4px);
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    & > div {
      height: 300px;
    }
  }
`;
