import { ChangeEvent, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import SearchInput from 'components/Inputs/SearchInput';

const SearchForm = ({
  searchValue,
  handleSearchValueChange,
  handleSubmit,
}: {
  searchValue: string;
  handleSearchValueChange: (val: string) => void;
  handleSubmit: (e: { preventDefault: () => void }) => void;
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    handleSearchValueChange(event.target.value);
  }, []);

  const clearInput = useCallback(() => {
    handleSearchValueChange('');
  }, []);

  return (
    <Box
      style={{ maxWidth: 950 }}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <FormControl fullWidth>
        <SearchInput
          searchValue={searchValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          clearInput={clearInput}
        />
      </FormControl>
    </Box>
  );
};

export default SearchForm;
