import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';
import { EGLogoIcon } from 'assets/svg';
import { Container } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const StyledEGLogoIcon = styled(EGLogoIcon)`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 51%;
  transform: translate(-51%, -50%);
  text-align: center;
  white-space: nowrap;
  width: 150px;
  height: 150px;

  path {
    fill: white;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;

export const StyledContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;
