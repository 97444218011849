import { Typography } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 915px;
  min-width: 300px;
  padding: 71px 25px;
  position: relative;
  left: 15%;

  @media (max-width: ${BREAKPOINTS.m}px) {
    left: 0;
    padding: 30px 25px;
  }
`;

export const PageTitle = styled(Typography).attrs({
  type: 'title1Helv',
})`
  margin-bottom: 21px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }
`;

export const Subtitle = styled(Typography).attrs({
  type: 'title4Helv',
})`
  margin-bottom: 21px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-bottom: 16px;
    font-size: 12px;
  }
`;

export const BodyText = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
