import { Loader } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import { ProductType } from 'types/ProductsTypes';
import {
  ProductCardsWrapper,
  ProductCardWrapper,
  Title,
  Wrapper,
} from './styles';

const ExploreMoreProducts = ({
  products,
  isLoading,
  withoutTitle,
  verticalPadding,
}: {
  products?: ProductType[];
  isLoading: boolean;
  withoutTitle?: boolean;
  verticalPadding?: number;
}) => {
  const cardNum = 3;
  const shownProducts = products?.slice(0, cardNum);

  return (
    <Wrapper verticalPadding={verticalPadding}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {withoutTitle ? null : <Title>Explore more</Title>}
          <ProductCardsWrapper>
            {!isLoading && shownProducts
              ? shownProducts.map((product: ProductType) => {
                  return (
                    <ProductCardWrapper key={product.productId}>
                      <ProductCard product={product} />
                    </ProductCardWrapper>
                  );
                })
              : null}
          </ProductCardsWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default ExploreMoreProducts;
