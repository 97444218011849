import styled, { css } from 'styled-components';

import { Typography } from 'components/Typography/Typography';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const MenuWrapper = styled.div`
  min-width: 350px;
  flex-basis: 40%;
  padding: 75px 0 0 94px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    min-width: unset;
    padding: 37px 25px 0;
  }
`;

export const MenuTitle = styled(Typography)`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  padding-bottom: 55px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 19px;
  }
`;

export const MenuLink = styled(Typography)<{
  active?: boolean;
}>`
  text-transform: uppercase;
  font-family: ${FONTS.Helvetica.medium};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  opacity: 0.6;
  transition: 0.3s ease-out;

  & a {
    color: ${COLORS.black};
  }

  &:hover {
    opacity: 1;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const IconWrapper = styled.div<{
  clickable?: boolean;
}>`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    96.05% 96.05% at 50% 50%,
    rgba(240, 148, 62, 0.2) 0%,
    rgba(240, 148, 62, 0) 100%
  );
  box-shadow: inset 0px 4px 14px rgba(240, 37, 73, 0.08);
  border-radius: 140px;
  margin-right: 15px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`;

export const ButtonsWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  gap: 50px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    gap: 22px;
  }
`;

export const SuggestedAddressBox = styled.div`
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  background-color: ${COLORS.background};
  padding: 19px 30px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    opacity: 0.5;
  }

  h3 {
    font-family: ${FONTS.Helvetica.medium};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    padding: 10px 20px;
    min-width: 100px;
    max-width: 260px;
  }
`;
