import styled from 'styled-components';

export const PaymentContainer = styled.div`
  .checkout-address-form {
    padding-top: 15px;

    h1 {
      display: none;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: 20px;
`;
