/* eslint-disable import/no-dynamic-require */
import BrandCard from 'components/BrandCard/BrandCard';
import { BrandType } from 'types/BrandsTypes';
import { CardsWrapper, BrandCardWrapper } from './styles';

const BrandCards = ({
  brandList,
  total,
  handleLikeIconClick,
}: {
  brandList: BrandType[];
  total: number;
  handleLikeIconClick: (brandId: string) => Promise<boolean>;
}) => {
  return (
    <CardsWrapper container rowSpacing={2} columnSpacing={{ l: 2, xxxs: 1 }}>
      {brandList
        ? brandList?.map((brand: BrandType) => (
            <BrandCardWrapper
              item
              key={brand.id}
              // @ts-ignore
              xxxs={6}
              l={3}
            >
              <BrandCard
                brand={brand}
                handleLikeIconClick={handleLikeIconClick}
              />
            </BrandCardWrapper>
          ))
        : null}
    </CardsWrapper>
  );
};

export default BrandCards;
