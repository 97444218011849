import { Typography } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  padding: 58px 7% 70px;
  text-align: center;

  @media (max-width: 1150px) {
    padding: 50px 20px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 36px;
  text-transform: uppercase;
  color: ${COLORS.black};
  margin-bottom: 38px;

  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

export const Text = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  color: ${COLORS.black};
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
