import { styled as muiStyled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ArrowRightIcon } from 'assets/svg';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: FONTS.TTNorms.medium,
    fontSize: 18,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: FONTS.TTNorms.regular,
    fontSize: 18,
    borderBottom: 'none',
  },

  '@media (max-width: 650px)': {
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  },
}));

export const IconWrapper = styled.div``;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  width: 9px;
  height: 13px;
  color: ${COLORS.accent};
  cursor: pointer;
`;

export const StyledArrowDownIcon = styled(StyledArrowRightIcon)`
  transform: rotate(90deg);
`;
