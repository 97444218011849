import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LoyaltyTableLogoIcon } from 'assets/svg';
import {
  StyledTableContainer,
  IconWrapper,
  TableTab,
  TableTitle,
  PointsTitle,
  StyledTableCell,
} from './styles';

const rows = [
  {
    title: 'Access to the EG Elite \n Member Community',
    available: ['platinum', 'gold', 'silver', 'basic'],
  },
  {
    title: 'EG Welcome Kit',
    available: ['platinum', 'gold', 'silver', 'basic'],
  },
  {
    title: 'Birthday Gift: 1 Free shipping credit',
    available: ['platinum', 'gold', 'silver'],
  },
  {
    title: 'Early access to exclusive drops',
    available: ['platinum', 'gold', 'silver'],
  },
  {
    title: 'Member-only event invitations',
    available: ['platinum', 'gold', 'silver'],
  },
  {
    title:
      'Sweepstakes invitation for tickets to events sponsored by Ciroc/DeLeón',
    available: ['platinum', 'gold', 'silver'],
  },
  {
    title: 'Early access to 12 Days of Deals',
    available: ['platinum', 'gold'],
  },
  {
    title: 'A chance to win 2 passes to Afrochella\'s "Fashion Night Out"',
    available: ['platinum', 'gold'],
  },
  {
    title: 'Free Shipping on 3 orders',
    available: ['platinum', 'gold'],
  },
  {
    title: '"New Year, New You" wellness gift box',
    available: ['platinum'],
  },
  {
    title: 'Special invitations to Revolt TV, Ciroc and DeLeón events',
    available: ['platinum'],
  },
  {
    title: 'Free shipping credit on 5 orders',
    available: ['platinum'],
  },
];

const LoyaltyPointsOverview = () => {
  return (
    <StyledTableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell align="center" sx={{ padding: '8px 16px' }}>
              <TableTab>Eg Elite Member</TableTab>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: '8px 16px' }}>
              <TableTab>silver elite</TableTab>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: '8px 16px' }}>
              <TableTab>gold elite</TableTab>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: '8px 16px' }}>
              <TableTab>platinum elite</TableTab>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell sx={{ padding: 0 }} />
            <StyledTableCell align="center" sx={{ padding: 0 }}>
              <PointsTitle>0 - 499 points earned</PointsTitle>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: 0 }}>
              <PointsTitle>500 - 1,000 points earned</PointsTitle>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: 0 }}>
              <PointsTitle>1001 - 2,499 points earned</PointsTitle>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ padding: 0 }}>
              <PointsTitle>2500+ points earned</PointsTitle>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" width="32%">
                <TableTitle>{row.title}</TableTitle>
              </TableCell>
              <TableCell align="center">
                {row.available.includes('basic') && (
                  <IconWrapper>
                    <LoyaltyTableLogoIcon />
                  </IconWrapper>
                )}
              </TableCell>
              <TableCell align="center">
                {row.available.includes('silver') && (
                  <IconWrapper>
                    <LoyaltyTableLogoIcon />
                  </IconWrapper>
                )}
              </TableCell>
              <TableCell align="center">
                {row.available.includes('gold') && (
                  <IconWrapper>
                    <LoyaltyTableLogoIcon />
                  </IconWrapper>
                )}
              </TableCell>
              <TableCell align="center">
                {row.available.includes('platinum') && (
                  <IconWrapper>
                    <LoyaltyTableLogoIcon />
                  </IconWrapper>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default LoyaltyPointsOverview;
