import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AnimatedLinkUpdated from 'components/Links/AnimatedLinkUpdated';
import { formatNameForUrl, formatPrice } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { ProductAttributeType, ProductType } from 'types/ProductsTypes';
import useDimensions from 'hooks/useDimensions';
import { useProductWishList } from 'hooks/useWishList';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { EyeIcon } from 'assets/svg';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import QuickViewContent from 'components/Modals/ModalContent/QuickViewContent';
import { COLORS } from 'styles';
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon';
import {
  BottomProductPrice,
  CardBottom,
  CardContainer,
  CardTop,
  ViewDetailsButton,
  PaleText,
  ProductBrand,
  ProductInfo,
  ProductName,
  QuickView,
  QuickViewWrapper,
  ViewDetailsText,
} from './styles';

const ProductCard = ({
  product: {
    productName,
    productId,
    image,
    c_prices: prices,
    price,
    variationAttributes,
    c_brandName: brandName,
    name,
    id,
    imageGroups,
  },
  handleClick,
}: {
  product: ProductType;
  handleClick?: () => void;
}) => {
  const pName = productName || name;
  const pId = productId || id;
  const productImage =
    image || (imageGroups && imageGroups[0] && imageGroups[0].images[0]);
  const [hover, setHover] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [like, setLike] = useState<boolean>(false);
  const { width } = useDimensions();
  const { updateCustomerProductWishList, isInWishList } = useProductWishList();
  const inWishList = isInWishList(pId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const originalPrice = prices?.list?.value;
  const availableColorsNum = variationAttributes?.find(
    (attr: ProductAttributeType) => {
      return attr.id === 'color';
    },
  )?.values?.length;

  const handleMouseEnter = () => {
    setHover(true);
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
    setShowButton(false);
  };

  const toggleFavoriteIcon = () => {
    const isWishListUpdated = updateCustomerProductWishList(pId);
    isWishListUpdated && setLike(!inWishList);
  };

  const handleQuickView = () => {
    dispatch(
      modalActions.showModal({
        id: 'QUICK_VIEW_MODAL',
        modalContent: (
          <QuickViewContent
            closeModal={() =>
              dispatch(modalActions.hideModal('QUICK_VIEW_MODAL'))
            }
            productId={pId}
          />
        ),
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: COLORS.white,
        },
      }),
    );
  };

  useEffect(() => {
    setLike(inWishList);
  }, [inWishList]);

  return (
    <CardContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardTop
        backgroundImage={productImage?.link}
        hover={hover}
        aria-label={productImage?.alt}
        onClick={() =>
          navigate(`${ROUTES.PRODUCTS}/${formatNameForUrl(pName)}-${pId}`)
        }
      />
      <CardBottom>
        <ProductInfo>
          {brandName ? (
            <ProductBrand>{brandName.toUpperCase()}</ProductBrand>
          ) : null}
          <ProductName>{pName.toLocaleLowerCase()}</ProductName>
          <div style={{ display: 'flex' }}>
            <BottomProductPrice>
              {price && formatPrice(price)}
            </BottomProductPrice>
            {originalPrice ? (
              <PaleText
                style={{
                  textDecoration: 'line-through',
                  marginLeft: 10,
                }}
              >
                {originalPrice && formatPrice(originalPrice)}
              </PaleText>
            ) : null}
          </div>
          {availableColorsNum ? (
            <PaleText>
              {availableColorsNum} color{availableColorsNum === 1 ? '' : 's'}{' '}
              available
            </PaleText>
          ) : (
            <div style={{ height: 16.8 }} />
          )}
        </ProductInfo>
        <FavoriteIcon
          onClick={toggleFavoriteIcon}
          isFavorite={like}
          iconPosition={{ top: '10%', right: '0' }}
        />
      </CardBottom>
      <ViewDetailsButton show={showButton}>
        <AnimatedLinkUpdated
          to={`${ROUTES.PRODUCTS}/${formatNameForUrl(pName)}-${pId}`}
          type="white"
          fontSize="20px"
          onClick={handleClick}
        >
          <ViewDetailsText>view details</ViewDetailsText>
        </AnimatedLinkUpdated>
      </ViewDetailsButton>
      {width > 1000 && (
        <QuickViewWrapper onClick={handleQuickView} show={showButton}>
          <EyeIcon />
          <QuickView>Quick View</QuickView>
        </QuickViewWrapper>
      )}
    </CardContainer>
  );
};

export default ProductCard;
