/* eslint-disable import/no-dynamic-require */
import Slider from 'react-slick';

import { AccentLogoIcon } from 'assets/svg';
import { COLORS } from 'styles';
import {
  SplashPageBackground,
  SplashPageText,
  SplashPageTitle,
  LogoIconWrapper,
  SplashPageSkipBtn,
} from './styles';

const SplashPageContent = ({ closeModal }: { closeModal: () => void }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCss: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings}>
      <div>
        <SplashPageBackground height={window.innerHeight}>
          <SplashPageSkipBtn onClick={closeModal}>skip</SplashPageSkipBtn>
          <img
            src={require('../../../assets/jpg/splash-screen.jpg')}
            alt=""
            style={{ width: '100%' }}
          />
          <SplashPageTitle
            style={{ position: 'absolute', color: COLORS.accent }}
          >
            Shopping Black looks good on you.
          </SplashPageTitle>
        </SplashPageBackground>
      </div>
      {/* ready */}
      <div>
        <SplashPageBackground height={window.innerHeight}>
          <SplashPageSkipBtn onClick={closeModal}>skip</SplashPageSkipBtn>
          <SplashPageTitle>FASHION.art.beauty.</SplashPageTitle>
        </SplashPageBackground>
      </div>
      <div>
        <SplashPageBackground
          height={window.innerHeight}
          style={{ flexDirection: 'column' }}
        >
          <SplashPageSkipBtn onClick={closeModal}>skip</SplashPageSkipBtn>
          <SplashPageText>The Best In Class Black Brands</SplashPageText>
          <LogoIconWrapper>
            <AccentLogoIcon />
          </LogoIconWrapper>
        </SplashPageBackground>
      </div>
    </Slider>
  );
};

export default SplashPageContent;
