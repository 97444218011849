import styled from 'styled-components';

export const BrandPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 60px;

  @media (max-width: 1000px) {
    gap: 20px;
  }
`;
