import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Loader } from 'components';
import Pagination from 'components/Pagination/Pagination';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getCustomerId,
  getFavoriteBrands,
  getCustomerLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { BrandType } from 'types/BrandsTypes';
import { useBrandsFavoritesList } from 'hooks/useWishList';
import BrandCards from '../../components/FavoritesComponents/BrandCards';

const PAGE_SIZE = 16;

const FavoriteBrands = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customerId = useSelector(getCustomerId);
  const fetchedBrands = useSelector(getFavoriteBrands);
  const isLoading = useSelector(getCustomerLoader);
  const [favoriteBrands, setFavoriteBrands] = useState<BrandType[]>([]);
  const { isBrandFavorite, updateFavoriteBrands } = useBrandsFavoritesList();

  const dispatch = useAppDispatch();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return favoriteBrands?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, favoriteBrands]);

  const fetchCustomerData = async () => {
    await dispatch(AsyncThunks.getCustomer(customerId));
  };

  const handleLikeIconClick = useCallback(
    async (brandId: string) => {
      return !!updateFavoriteBrands(brandId);
    },
    [updateFavoriteBrands],
  );

  useEffect(() => {
    fetchCustomerData();
  }, []);

  useEffect(() => {
    const updatedBrand = fetchedBrands?.map((brand: BrandType) => {
      return {
        ...brand,
        image: brand.businessOwnerImage,
        isFavorite: isBrandFavorite(brand.id),
      };
    });

    setFavoriteBrands(updatedBrand || []);
  }, [fetchedBrands]);

  if (isLoading) {
    return <Loader style={{ height: 200 }} />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Favorite Brands</title>
        <meta name="description" content="Favorite Brands" />
      </Helmet>
      <BrandCards
        total={currentTableData?.length}
        brandList={favoriteBrands}
        handleLikeIconClick={handleLikeIconClick}
      />
      <Pagination
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        totalCount={favoriteBrands?.length}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default FavoriteBrands;
