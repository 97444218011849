import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isEmpty, values } from 'lodash';
import axios from 'axios';

import { Loader } from 'components';
import ProductOverview from 'components/ProductDetailsComponents/ProductOverview';
import ImageList from 'components/Images/ImageList/ImageList';
import BreadcrumbMenu from 'components/BreadcrumbMenu/BreadcrumbMenu';

import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getProduct,
  getProductLoader,
  getBasketError,
  getBrand,
  getBrandLoader,
  getProductCategories,
  getBrandError,
} from 'eg_SFCC_FE_core/store/selectors';
import {
  ImageGroupType,
  ImageType,
  ProductCategoryType,
  ProductItemType,
  ProductVariantType,
} from 'types/ProductsTypes';
import { BrandType } from 'types/BrandsTypes';
import useHtml from 'hooks/useHtml';
import useHeader from 'hooks/useHeader';
import useGoogleTags from 'hooks/useGoogleTags';
import { useAddToCartConfirmationModal } from 'hooks/useModals';
import { BREAKPOINTS } from 'styles';
import {
  Wrapper,
  OverviewWrapper,
  ImagesWrapper,
  OverviewBlockWrapper,
  ProductOverviewWrapper,
  ReviewsSection,
} from './styles';

const ProductDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { productId: productIdFromUrl } = useParams();
  const productId = productIdFromUrl?.split('-').reverse()[0];
  const { addScript } = useHtml();
  const product = useSelector<any, ProductItemType>(getProduct);
  const isProductLoading = useSelector<any, boolean>(getProductLoader);
  const brand = useSelector<any, BrandType>(getBrand);
  const brandError = useSelector(getBrandError);
  const brandId = product?.c_marketplacerSellerId;
  const isBrandLoading = useSelector(getBrandLoader);
  const basketError = useSelector<any, any>(getBasketError);
  const productCategories = useSelector<any, ProductCategoryType[]>(
    getProductCategories,
  );
  const isMasterProduct = !!product?.master;
  const [selectedProductQuantity, setSelectedProductQuantity] =
    useState<number>(1);
  const [selectedProductVariant, setSelectedProductVariant] = useState<
    ProductVariantType | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { trackEvent, addPageView } = useGoogleTags();
  const { showAddToCartConfirmationModal } = useAddToCartConfirmationModal();
  // handle scroll effect
  const overviewWrapperRef = useRef<null | HTMLDivElement>(null);
  const overviewDetailsRef = useRef<null | HTMLDivElement>(null);
  const [overviewWrapperHeight, setOverviewWrapperHeight] = useState<number>(0);
  const [overviewDetailsHeight, setOverviewDetailsHeight] = useState<number>(0);

  const [images, setImages] = useState<ImageType[]>([
    { alt: '', link: '', title: '' },
  ]);
  const [detailsPosition, setDetailsPosition] = useState<
    'fixed' | 'absolute' | 'static'
  >('static');
  const { scrollPosition } = useHeader();

  const imageGroups =
    product &&
    product.imageGroups?.filter((group: ImageGroupType) => {
      return !group.variationAttributes;
    });

  const fetchProduct = async () => {
    if (!productId) return;

    await dispatch(AsyncThunks.getProductById(productId));
  };

  const fetchBrand = async () => {
    brandId && (await dispatch(AsyncThunks.getBrandById(brandId)));
  };

  const trackAddToCartEvent = () => {
    trackEvent({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            currency: product.currency,
            item_brand: product.brand,
            item_variant:
              values(selectedProductVariant?.variationValues).join(',') || '',
            price: product.price || 0,
            quantity: selectedProductQuantity,
            primaryCategoryId: product.primaryCategoryId,
          },
        ],
      },
    });
  };

  const addToCartHandler = useCallback(async () => {
    if (!product) return null;

    if (!selectedProductVariant?.productId && isMasterProduct) {
      setErrorMessage('Select product variant');
      return;
    }

    const productIdForBasket = isMasterProduct
      ? selectedProductVariant?.productId
      : product.id;

    const response = await dispatch(
      AsyncThunks.addItemToBasket([
        {
          productId: productIdForBasket || '',
          quantity: selectedProductQuantity,
        },
      ]),
    );

    // @ts-ignore
    if (!response.error) {
      trackAddToCartEvent();
      showAddToCartConfirmationModal(productIdForBasket || '');
    }
  }, [
    product,
    selectedProductVariant,
    selectedProductQuantity,
    isMasterProduct,
  ]);

  const trackViewItemEvent = () => {
    trackEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            currency: product.currency,
            item_brand: product.brand,
            item_variant: '',
            price: product.price || 0,
            quantity: 1,
            primaryCategoryId: product.primaryCategoryId,
          },
        ],
      },
    });
  };

  const getProductVideo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STREAM_VIDEO_URL}${productId}.json`,
      );

      if (response && response.data.playback_url.includes('cloudflarestream')) {
        setImages((prevImages: ImageType[]) => {
          return [
            ...prevImages,
            {
              alt: 'Product Video',
              link: response.data.playback_url,
              title: 'Product Video',
            },
          ];
        });
      }
    } catch (e) {
      // @ts-ignore
      // console.log(e);
    }
  };

  useEffect(() => {
    if (product?.id !== productId) {
      fetchProduct();
    }

    if (brandId) {
      fetchBrand();
    }

    return () => {
      setSelectedProductVariant(undefined);
    };
  }, [brandId]);

  useEffect(() => {
    if (!isEmpty(product)) {
      addScript({
        src: `https:${product?.c_bvData?.bvDisplay?.bvScout}`,
        id: 'bazaarvoice-script',
      });

      if (product?.id === productId) {
        product.primaryCategoryId &&
          dispatch(AsyncThunks.getProductCategories(product.primaryCategoryId));
        trackViewItemEvent();
        imageGroups && setImages(imageGroups[0].images);
        getProductVideo();
      }

      addPageView('product', { brand: product.brand });
    }
  }, [product]);

  useEffect(() => {
    overviewWrapperRef.current &&
      setOverviewWrapperHeight(overviewWrapperRef.current?.clientHeight);
  }, [product, overviewWrapperRef.current?.clientHeight]);

  useEffect(() => {
    overviewDetailsRef.current &&
      setOverviewDetailsHeight(overviewDetailsRef.current?.clientHeight);
  }, [product, overviewDetailsRef.current?.clientHeight]);

  useEffect(() => {
    if (
      overviewDetailsHeight >= overviewWrapperHeight ||
      window.innerWidth < BREAKPOINTS.s
    ) {
      setDetailsPosition('static');
    } else if (scrollPosition + overviewDetailsHeight > overviewWrapperHeight) {
      setDetailsPosition('absolute');
    } else {
      setDetailsPosition('fixed');
    }
  }, [product, scrollPosition]);

  useEffect(() => {
    basketError && setErrorMessage(basketError.detail);
  }, [basketError]);

  if (isProductLoading || product?.id !== productId) return <Loader />;

  const bvJson = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': window.location.href,
    name: product.name,
    image:
      product.imageGroups &&
      product.imageGroups[0] &&
      product.imageGroups[0].images[0].link,
    description: product.shortDescription,
    sku: product.id,
    brand: product.brand,
  });
  return (
    <>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Product Details</title>
          <meta name="description" content="Product Details" />
          <script type="application/ld+json">{bvJson}</script>
        </Helmet>
        <BreadcrumbMenu productCategories={productCategories} />
        <OverviewWrapper ref={overviewWrapperRef}>
          <ImagesWrapper>
            {imageGroups ? <ImageList imageGroup={images} /> : 'No image'}
          </ImagesWrapper>
          <OverviewBlockWrapper>
            <ProductOverviewWrapper
              ref={overviewDetailsRef}
              position={detailsPosition}
            >
              <ProductOverview
                product={product}
                addToCartHandler={addToCartHandler}
                selectedProductQuantity={selectedProductQuantity}
                selectProductQuantity={setSelectedProductQuantity}
                selectedProductVariant={selectedProductVariant}
                selectProductVariant={setSelectedProductVariant}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isBrandLoading={isBrandLoading}
                brandDetails={
                  brand?.id === product.c_marketplacerSellerId ? brand : null
                }
                brandError={brandError}
              />
            </ProductOverviewWrapper>
          </OverviewBlockWrapper>
        </OverviewWrapper>
      </Wrapper>

      {product?.id ? (
        <ReviewsSection
          data-bv-show="reviews"
          data-bv-product-id={product.id}
        />
      ) : null}
    </>
  );
};

export default ProductDetailsPage;
