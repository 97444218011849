import { values } from 'lodash';
import { useSelector } from 'react-redux';

import { BasketProductItemType } from 'types/ProductsTypes';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getBasketLoader,
  getIsLoggedIn,
  getBasketError,
} from 'eg_SFCC_FE_core/store/selectors';
import useGoogleTags from './useGoogleTags';
import { useProductWishList } from './useWishList';

const useBasket = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getBasketLoader);
  const basketError = useSelector(getBasketError);
  const dispatch = useAppDispatch();
  const { trackEvent } = useGoogleTags();
  const { addToWishList } = useProductWishList();

  const trackRemoveFromCartEvent = async (cartItem: BasketProductItemType) => {
    let products = await getProductsByIds(cartItem.productId);
    let product = products[0];

    if (product.master) {
      products = await getProductsByIds(product.master.masterId);
      [product] = products;
    }

    const items = [
      {
        item_id: product.id,
        item_name: product.name,
        currency: 'USD',
        item_brand: product.brand,
        item_variant: values(cartItem?.variationValues).join(',') || '',
        price: cartItem.price || 0,
        quantity: cartItem.quantity,
        primaryCategoryId: product.primaryCategoryId,
      },
    ];

    trackEvent({
      event: 'remove_from_cart',
      ecommerce: { items },
    });
  };

  const removeProductFromCart = async (cartItem: BasketProductItemType) => {
    try {
      await dispatch(
        AsyncThunks.removeItemFromBasket({
          itemId: cartItem.itemId,
          productId: cartItem.productId,
        }),
      ).unwrap();
      trackRemoveFromCartEvent(cartItem);
    } catch (e: any) {
      return e?.detail;
    }
  };

  const addProductToWishlist = async (
    itemId: string,
    productId: string,
    quantity: number,
  ) => {
    try {
      isLoggedIn &&
        (await dispatch(
          AsyncThunks.removeItemFromBasket({ itemId, productId }),
        ));
      addToWishList(productId, quantity);
    } catch (e: any) {
      return e?.detail;
    }
  };

  return {
    removeProductFromCart,
    addProductToWishlist,
    isLoading,
    basketError,
  };
};

export default useBasket;
