import { compact } from 'lodash';
import { InfoBlockWrapper, Title, Data } from './styles';

const InfoBlock = ({
  id,
  data,
  title,
}: {
  id?: string;
  data: (string | undefined)[];
  title: string;
}) => {
  return (
    <InfoBlockWrapper id={id}>
      <Title>{title}</Title>
      <Data>
        {compact(data).map((value: string, index: number) => (
          <span key={index}>{value}</span>
        ))}
      </Data>
    </InfoBlockWrapper>
  );
};

export default InfoBlock;
