import styled, { css } from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS } from 'styles';
import { PageContentWrapper } from 'styles/common';

export const PLPWrapper = styled(PageContentWrapper)`
  flex-direction: column;
  padding-top: 60px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-top: 10px;
  }
`;

export const DesktopFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 50px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
    margin: 0;
  }
`;

export const FilteredBy = styled(Typography).attrs({
  type: 'subtitle3Helv',
})`
  text-transform: capitalize;
`;

export const SelectedFiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 25px;
`;

export const SelectedFilterLabel = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-transform: capitalize;
  color: ${COLORS.grey};
  position: relative;
  margin-right: 30px;
`;

export const Cross = styled.span`
  position: absolute;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 4px;
    right: -7px;
    content: ' ';
    height: 5px;
    width: 1px;
    background-color: ${COLORS.grey};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const MobileFiltersWrapper = styled.div<{
  hidden?: boolean;
}>`
  display: flex;

  & > div:last-child {
    margin-left: 5px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @media (min-width: ${BREAKPOINTS.s + 1}px) {
    display: none;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
    `}
`;
