/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { OrderListType, ReturnPlisInfo } from 'types/OrdersTypes';

export const ORDER_SLICE_NAME = 'order';

type OrderStateType = StateType & {
  orderList: OrderListType[];
  orderListTotal: number;
  orderDetails: OrderListType | null;
  returnParams: {
    c_action: 'returnProductLineItems';
    c_plis_info: ReturnPlisInfo[];
    c_orderNo: string;
    c_returnComment: string;
    c_addressId: string;
  };
  lastReturnRequestResponse: any;
};

const initialState: OrderStateType = {
  pending: false,
  error: null,
  result: [],
  orderList: [],
  orderListTotal: 0,
  orderDetails: null,
  returnParams: {
    c_action: 'returnProductLineItems',
    c_plis_info: [{ id: '', qty: 0 }],
    c_orderNo: '',
    c_returnComment: '',
    c_addressId: '',
  },
  lastReturnRequestResponse: {},
};

const orderSlice = createSlice({
  name: ORDER_SLICE_NAME,
  initialState,
  reducers: {
    addUpdatedOrders: (state, action) => {
      state.result = action.payload;
    },
    addUpdatedOrderList: (state, action) => {
      state.orderList = action.payload.orderList;
    },
    addUpdatedOrder: (state, action) => {
      state.orderDetails = action.payload;
    },
    updatedReturnParams: (state, action) => {
      state.returnParams = {
        ...state.returnParams,
        ...action.payload,
      };
    },
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.createOrder.pending, onPending);
    builder.addCase(AsyncThunks.createOrder.fulfilled, (state, action) => {
      state.orderDetails = action.payload;
      state.pending = false;
      state.error = null;
    });
    builder.addCase(AsyncThunks.createOrder.rejected, onError);

    builder.addCase(AsyncThunks.getCustomerOrderList.pending, onPending);
    builder.addCase(
      AsyncThunks.getCustomerOrderList.fulfilled,
      (state, action) => {
        state.orderListTotal = action.payload.total;
        state.orderList = action.payload.data?.filter(
          (order: OrderListType) => order.status !== 'failed',
        );
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getCustomerOrderList.rejected, onError);

    builder.addCase(AsyncThunks.getCustomerOrderById.pending, onPending);
    builder.addCase(
      AsyncThunks.getCustomerOrderById.fulfilled,
      (state, action) => {
        state.orderDetails = action.payload;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getCustomerOrderById.rejected, onError);

    builder.addCase(AsyncThunks.returnProductLineItem.pending, onPending);
    builder.addCase(
      AsyncThunks.returnProductLineItem.fulfilled,
      (state, action) => {
        const lastReturnRequestResponse = JSON.parse(
          action.payload.c_lastReturnRequestResponse,
        );

        if (lastReturnRequestResponse.error) {
          // @ts-ignore
          state.error.statusCode = lastReturnRequestResponse.error.code;
        } else {
          state.lastReturnRequestResponse = {
            ...lastReturnRequestResponse,
            c_plis_info: state.returnParams.c_plis_info,
          };

          state.returnParams = {
            c_action: 'returnProductLineItems',
            c_plis_info: [{ id: '', qty: 0 }],
            c_orderNo: '',
            c_returnComment: '',
            c_addressId: '',
          };
          state.error = null;
        }

        state.pending = false;
      },
    );
    builder.addCase(AsyncThunks.returnProductLineItem.rejected, onError);

    builder.addCase(AsyncThunks.updatePaymentInstrument.pending, onPending);
    builder.addCase(
      AsyncThunks.updatePaymentInstrument.fulfilled,
      (state, action) => {
        state.orderDetails = action.payload;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.updatePaymentInstrument.rejected, onError);
  },
});

export const orderActions = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
