import ImageListItem from '@mui/material/ImageListItem';
import { ImageType } from 'types/ProductsTypes';

const DoubleImageListItemComponent = ({ group }: { group: ImageType[] }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      {group.map(({ link, alt }: ImageType) => (
        <ImageListItem
          key={link}
          sx={{ height: '49% !important', width: '100%' }}
        >
          <img src={link} srcSet={link} alt={alt} />
        </ImageListItem>
      ))}
    </div>
  );
};

export default DoubleImageListItemComponent;
