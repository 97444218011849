import { useState } from 'react';
import { AccordionSummary } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { ExtendedCategoryType } from 'types/CatalogTypes';
import {
  RotatedMinus,
  RotatedPlus,
  StyledSubCatalogAccordion,
  SubCatalogItemTitle,
} from './styles';

const SubCatalogAccordion = ({
  category: { id, name, categories },
  navigateTo,
}: {
  category: ExtendedCategoryType;
  navigateTo: (props: string | { catName: string; catId: string }) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <StyledSubCatalogAccordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={
        { unmountOnExit: true, mountOnEnter: true } as TransitionProps
      }
    >
      <AccordionSummary
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        sx={{ padding: 0 }}
      >
        <SubCatalogItemTitle
          onClick={() => navigateTo({ catName: name, catId: id })}
        >
          {name}
        </SubCatalogItemTitle>
        {categories && categories.length ? (
          <div style={{ position: 'absolute', right: 0 }}>
            {expanded ? <RotatedMinus /> : <RotatedPlus />}
          </div>
        ) : null}
      </AccordionSummary>
      {categories
        ? categories?.map((category: ExtendedCategoryType) => {
            if (category.categories) {
              return (
                <SubCatalogAccordion
                  category={category}
                  key={category.id}
                  navigateTo={navigateTo}
                />
              );
            }
            return (
              <SubCatalogItemTitle
                key={category.id}
                style={{ marginLeft: 16 }}
                onClick={() =>
                  navigateTo({ catName: category.name, catId: category.id })
                }
              >
                {category.name}
              </SubCatalogItemTitle>
            );
          })
        : null}
    </StyledSubCatalogAccordion>
  );
};

export default SubCatalogAccordion;
