import { Paragraph, UppercaseTitle } from '../styles';

const FinerLegalPoints = () => (
  <>
    <UppercaseTitle>13. Some Finer Legal Points</UppercaseTitle>
    <Paragraph>
      The Terms, including all of the policies that make up the Terms, supersede
      any other agreement between you and Empower Global regarding the Services.
      If any part of the Terms is found to be unenforceable, that part will be
      limited to the minimum extent necessary so that the Terms will otherwise
      remain in full force and effect. Our failure to enforce any part of the
      Terms is not a waiver of our right to later enforce that or any other part
      of the Terms. You may not assign any of your rights and obligations under
      the Terms.
    </Paragraph>
  </>
);

export default FinerLegalPoints;
