import { useState, ReactNode, memo } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { TransitionProps } from '@mui/material/transitions';
import { COLORS } from 'styles';
import { AccordionContainer, ArrowDown, ArrowUp } from './styles';

interface IOrderItem {
  title: ReactNode | string;
  content: ReactNode | string;
  id: string;
}

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  borderBottom: `1px solid ${COLORS.black}60`,
  background: 'transparent',
  minHeight: 19,
  '&:first-of-type': {
    borderTop: `1px solid ${COLORS.black}60`,
    borderRadius: 0,
  },
  '&:last-child': {
    borderRadius: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionItem = (item: IOrderItem) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = async () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={
        { unmountOnExit: true, mountOnEnter: true } as TransitionProps
      }
    >
      <AccordionSummary
        aria-controls={`${item.id}a-content`}
        id={`${item.id}a-header`}
        sx={{
          padding: 0,
          minHeight: 20,
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
          },
        }}
      >
        {item.title}
        {expanded ? <ArrowUp /> : <ArrowDown />}
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {expanded ? item.content : null}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const OrderAccordion = ({ data }: { data: IOrderItem[] }) => {
  return (
    <AccordionContainer>
      {data.map(({ title, content, id }: any) => {
        return (
          <AccordionItem
            key={Math.random()}
            title={title}
            content={content}
            id={id}
          />
        );
      })}
    </AccordionContainer>
  );
};

export default memo(OrderAccordion);
