// temporary data for demo purposes
import { isDev } from 'appConstants';

export const categories = [
  {
    id: isDev ? '24' : '256',
    name: 'womens',
    routeName: 'women',
    image: 'assets/png/home/womens.png',
    linkPosition: 'top-left',
  },
  {
    id: isDev ? '4' : '225',
    name: 'mens',
    routeName: 'men',
    image: 'assets/png/home/mens.png',
    linkPosition: 'top-right',
  },
  {
    id: isDev ? '48' : '302',
    name: 'fashion for all',
    routeName: 'unisex',
    image: 'assets/png/home/unisex.png',
    linkPosition: 'bottom-left',
  },
  {
    id: isDev ? '141' : '176',
    name: 'beauty',
    routeName: 'beauty',
    image: 'assets/png/home/beauty.png',
    linkPosition: 'bottom-right',
  },
];

export const notFoundPageLinks = [
  {
    id: isDev ? '24' : '256',
    name: 'women',
    routeName: 'women',
  },
  {
    id: isDev ? '4' : '225',
    name: 'men',
    routeName: 'men',
  },
  {
    id: isDev ? '170' : '78',
    name: 'art',
    routeName: 'art',
  },
  {
    id: isDev ? '141' : '176',
    name: 'beauty',
    routeName: 'beauty',
  },
];

export const gallery = [
  {
    id: '1',
    image: 'assets/jpg/about/image11.jpg',
  },
  {
    id: '2',
    image: 'assets/jpg/about/image12.jpg',
  },
  {
    id: '3',
    image: 'assets/jpg/about/image13.jpg',
  },
  {
    id: '4',
    image: 'assets/jpg/about/image14.jpg',
  },
  {
    id: '5',
    image: 'assets/jpg/about/image15.jpg',
  },
  {
    id: '6',
    image: 'assets/jpg/about/image16.jpg',
  },
  {
    id: '7',
    image: 'assets/jpg/about/image13.jpg',
  },
  {
    id: '8',
    image: 'assets/jpg/about/image6.jpg',
  },
  {
    id: '9',
    image: 'assets/jpg/about/image13.jpg',
  },
  {
    id: '10',
    image: 'assets/jpg/about/image11.jpg',
  },
];

export const heroSliderData = [
  {
    imageDesktop: 'assets/png/home/hero-1-desktop.png',
    imageMobile: 'assets/png/home/hero-1-mobile.png',
    heading: 'Wardrobe Essentials',
    copy: 'Elevate your style effortlessly with must-have essentials.',
    isLeft: false,
    isDark: true,
    isHeadingLarge: true,
    link: '/products/category/wardrobe-essentials',
  },
  {
    imageDesktop: 'assets/png/home/hero-2-desktop.png',
    imageMobile: 'assets/png/home/hero-2-mobile.png',
    heading: 'Sustainably Sourced',
    copy: 'Discover planet-friendly products built to go the distance.',
    isLeft: true,
    isDark: true,
    isHeadingLarge: false,
    link: '/products/category/sustainably-sourced',
  },
  {
    imageDesktop: 'assets/png/home/hero-3-desktop.png',
    imageMobile: 'assets/png/home/hero-3-mobile.png',
    copy: 'Explore the freshest streetwear apparel, curated to elevate your style.',
    isLeft: false,
    isDark: false,
    isHeadingLarge: false,
    link: '/brands/U2VsbGVyLTMyOQ==',
  },
  {
    imageDesktop: 'assets/png/home/hero-4-desktop.png',
    imageMobile: 'assets/png/home/hero-4-mobile.png',
    heading: 'Accessible Fashion',
    copy: 'Your style, your way. Discover designs that embrace all body types by Love, Vera',
    isLeft: false,
    isDark: true,
    isHeadingLarge: false,
    link: '/brands/U2VsbGVyLTMzNA==',
  },
];

export const categorySliderData = [
  {
    imageUrl: 'assets/png/home/category-slider-skin.webp',
    cta: 'skincare',
    link: '/products/skin-care/177',
  },
  {
    imageUrl: 'assets/png/home/category-slider-mens.webp',
    cta: "men's",
    link: '/products/men/225',
  },
  {
    imageUrl: 'assets/png/home/category-slider-womens.webp',
    cta: "women's",
    link: '/products/shop/seller',
  },
  {
    imageUrl: 'assets/png/home/category-slider-shoes.webp',
    cta: 'shoes',
    link: '/products/shoes/339',
  },
  {
    imageUrl: 'assets/png/home/category-slider-jewelry.webp',
    cta: 'jewelry',
    link: '/products/jewelry/360',
  },
  {
    imageUrl: 'assets/png/home/category-slider-candles.webp',
    cta: 'candles',
    link: '/search?candles',
  },
];
