import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import {
  onPending,
  onError,
  onFulfilled,
  resetState,
} from 'eg_SFCC_FE_core/store/stateResults';
import { ProductType } from 'types/ProductsTypes';

export const BRAND_SLICE_NAME = 'brand';

const initialState: StateType & {
  productList: ProductType[];
  productListTotal: number;
} = {
  pending: false,
  error: null,
  result: null,
  productList: [],
  productListTotal: 0,
};

const brandSlice = createSlice({
  name: BRAND_SLICE_NAME,
  initialState,
  reducers: {
    reset: resetState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getBrandById.pending, onPending);
    builder.addCase(AsyncThunks.getBrandById.fulfilled, onFulfilled);
    builder.addCase(AsyncThunks.getBrandById.rejected, onError);

    builder.addCase(AsyncThunks.getProductListByBrand.pending, onPending);
    builder.addCase(
      AsyncThunks.getProductListByBrand.fulfilled,
      (state, action) => {
        state.productList = action.payload.hits;
        state.productListTotal = action.payload.total;
        state.error = null;
        state.pending = false;
      },
    );
    builder.addCase(AsyncThunks.getProductListByBrand.rejected, onError);
  },
});

export const brandActions = brandSlice.actions;
export const brandReducer = brandSlice.reducer;
