import styled from 'styled-components';
import Slider from 'react-slick';

import { Divider, Card, CardMedia } from '@mui/material';
import { Typography, CustomNavLink, ColoredButton } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const StyledContainer = styled.div`
  padding: 60px;
  background: #fcfbfb;
`;

export const StyledContainerInner = styled.div`
  margin: 0 auto;
  max-width: 1470px;
`;

export const StyledHeading = styled(Typography).attrs({
  type: 'title1Helv',
})`
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.s}px) {
    font-size: 48px;
  }
`;

export const StyledCopy = styled(Typography).attrs({
  type: 'sliderCopyHelv',
})`
  font-size: 14px;
  text-align: center;
  font-family: ${FONTS.Helvetica.regular};

  @media (min-width: ${BREAKPOINTS.s}px) {
    font-size: 20px;
  }
`;

export const StyledBrandsButton = styled(ColoredButton).attrs({
  inverted: true,
})`
  text-transform: uppercase;
  background-color: #eeeae6 !important;
  border-radius: 2px;
  border-color: #eeeae6 !important;

  p {
    font-size: 14px !important;
    color: ${COLORS.black} !important;

    @media (min-width: ${BREAKPOINTS.s}px) {
      font-size: 24px;
    }
  }
`;

export const StyledAllBrandsLink = styled(CustomNavLink)`
  margin: 0 auto;
  width: 100%;
  max-width: 170px;

  @media (min-width: ${BREAKPOINTS.s}px) {
    max-width: 250px;
  }
`;

export const SliderWrapper = styled.div`
  margin: 60px 0;
`;

export const StyledSlider = styled(Slider)`
  text-align: center;
`;

export const StyledBrandName = styled(Typography).attrs({
  type: 'title2Helv',
})`
  margin: 10px 0;
  font-size: 32px;
  text-transform: uppercase;
`;

export const StyledDivider = styled(Divider)`
  max-width: 90px;
  margin: 20px auto;
  color: ${COLORS.black};
`;

export const StyledBrandFounders = styled.span`
  font-size: 20px;
`;

export const StyledBrandDescription = styled(Typography).attrs({
  type: 'body2Helv',
})`
  font-size: 14px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.s}px) {
    font-size: 20px;
  }
`;

export const StyledCard = styled(Card)`
  max-width: 425px;
  max-height: 713px;
  height: 100%;
  padding: 10px;
`;

export const StyledCardMedia = styled(CardMedia)`
  background-size: contain !important;
`;

export const StyledBrandLink = styled(CustomNavLink)`
  text-decoration: underline;
  color: ${COLORS.black};
`;
