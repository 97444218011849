import { ColoredButton, Typography } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const Text = styled.p`
  font-family: ${FONTS.Helvetica.regular};
  color: ${COLORS.black};
  font-size: 12px;
  margin: 20px 0 15px;
  opacity: 0.8;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    margin: 10px 0;
  }
`;

export const CheckoutAuthFormWrapper = styled.form`
  max-width: 410px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin: 0;
  }
`;

export const Button = styled(ColoredButton).attrs({
  inverted: true,
})`
  @media (max-width: ${BREAKPOINTS.s}px) {
    height: 23px;
  }

  & p {
    font-size: 14px;

    @media (max-width: ${BREAKPOINTS.s}px) {
      font-size: 8px;
    }
  }
`;

export const ByCreatingText = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  a {
    font-size: 12px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 8px;

    a {
      font-size: 8px;
    }
  }
`;
