import styled, { css } from 'styled-components';
import { CustomNavLink } from 'components';

import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.nav`
  background: ${COLORS.black};
  max-height: 60px;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: hidden;
  padding: 0 5px;
  position: fixed;
  top: 126px;
  width: 100%;
  z-index: 10;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Links = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
`;

export const NavigationLink = styled.div<{
  active?: boolean;
}>`
  color: ${COLORS.white};
  cursor: pointer;
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid ${COLORS.black} 4px;

  &:hover,
  &:focus {
    border-bottom: solid ${COLORS.accent} 4px;
    transition: 0.3s ease-out;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: solid ${COLORS.accent} 4px;
      transition: 0.3s ease-out;
    `}
`;

export const Tooltip = styled.span`
  background: ${COLORS.accent};
  color: ${COLORS.white};
  width: 37px;
  height: 15px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
  top: 10px;
  right: -33px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkText = styled.div`
  font-size: 15px;
  font-family: ${FONTS.TTNorms.medium};
  text-transform: uppercase;
`;

export const DropDownWrapper = styled.nav`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const DropdownOption = styled(CustomNavLink)<{
  icon?: boolean;
}>`
  height: 68px;
  background-color: ${({ icon }) => (icon ? COLORS.white : COLORS.black)};
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.primary};
  color: ${({ icon }) => (icon ? COLORS.black : COLORS.white)};
`;
