/* eslint-disable prefer-destructuring */
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { find, includes, isEmpty } from 'lodash';
import { BasketProductItemType, ProductType } from 'types/ProductsTypes';
import { AsyncThunks } from '../actions';
import { basketActions } from '../reducers/slices/basketSlice';
import { orderActions } from '../reducers/slices/orderSlice';

const listener = createListenerMiddleware();

export const getProductItemsUpdatedWithVariationAttributes = async (
  productItems: any,
  imageSize: 'small' | 'medium' | 'large' = 'medium',
) => {
  const ids = productItems
    .map((item: any) => {
      return item.productId;
    })
    .join(',');

  const products = await getProductsByIds(ids);
  if (!products) return productItems;

  return productItems.map((productItem: ProductType) => {
    const product = find(products, { id: productItem.productId });

    const variationValues =
      find(product?.variants, { productId: productItem.productId })
        ?.variationValues || '';
    const image = find(product?.imageGroups, { viewType: imageSize })
      ?.images[0];
    const productPromotions = product?.productPromotions;
    const prices = product?.c_prices;
    const originalPrice = prices?.list?.value;
    const discountedPrice = prices?.sales?.value;
    const inventory = product?.inventory;

    return {
      ...productItem,
      image,
      variationValues,
      productPromotions,
      originalPrice: originalPrice || discountedPrice,
      discountedPrice,
      inventory,
    };
  });
};

let retry = false;

// add image, variationValues to product items to display in basket
listener.startListening({
  predicate: () => true,
  effect: async (action: any, listenerApi: any) => {
    const prevProductItems =
      listenerApi.getOriginalState().basketReducer.productItems;
    const productItems = listenerApi.getState().basketReducer.productItems;

    if (!productItems || !productItems.length) return;

    const isBasketEmpty = !prevProductItems?.length && !productItems?.length;
    const areAttributesMissing = !(
      productItems[0].image || productItems[0].variationValues
    );

    if (!isBasketEmpty && areAttributesMissing && !retry) {
      retry = true;

      const updatedProducts =
        await getProductItemsUpdatedWithVariationAttributes(productItems);

      if (!updatedProducts) return;

      const basket = listenerApi.getState().basketReducer.result;

      listenerApi.dispatch(
        basketActions.addUpdatedBasket({
          ...basket,
          productItems: updatedProducts,
        }),
      );

      retry = false;
    }
  },
});

// add image, variationValues to product items to display in orders
listener.startListening({
  predicate: () => true,
  effect: async (action: any, listenerApi: any) => {
    const prevOrderProductItems =
      listenerApi.getOriginalState().orderReducer.result?.productItems;
    const orderProductItems =
      listenerApi.getState().orderReducer.result?.productItems;

    if (!orderProductItems) return;

    const hasOrders = !prevOrderProductItems && !orderProductItems;
    const areOrdersAttributesMissing = !(
      orderProductItems[0].image || orderProductItems[0].variationValues
    );

    if (!hasOrders && areOrdersAttributesMissing && !retry) {
      retry = true;

      const updatedProducts =
        await getProductItemsUpdatedWithVariationAttributes(orderProductItems);

      if (!updatedProducts) return;

      const orders = listenerApi.getState().orderReducer.result;

      listenerApi.dispatch(
        orderActions.addUpdatedOrders({
          ...orders,
          productItems: updatedProducts,
        }),
      );

      retry = false;
    }
  },
});

// add image, variationValues to product items to display in my orders
listener.startListening({
  type: AsyncThunks.getCustomerOrderList.fulfilled.type,
  effect: async (action: any, listenerApi: any) => {
    const ordersList = listenerApi.getState().orderReducer.orderList;

    if (isEmpty(ordersList)) return;

    const areOrdersListAttributesMissing = !(
      ordersList[0]?.productItems[0].image ||
      ordersList[0]?.productItems[0].variationValues
    );

    if (areOrdersListAttributesMissing && !retry) {
      retry = true;

      const updatedProducts = await Promise.all(
        ordersList?.map(async (order: any) => {
          return {
            ...order,
            productItems: await getProductItemsUpdatedWithVariationAttributes(
              order.productItems,
            ),
          };
        }),
      );

      if (!updatedProducts) return;

      await listenerApi.dispatch(
        orderActions.addUpdatedOrderList({ orderList: updatedProducts }),
      );

      retry = false;
    }
  },
});

// add image, variationValues to order
listener.startListening({
  predicate: () => true,
  effect: async (action: any, listenerApi: any) => {
    const productItems =
      listenerApi.getState().orderReducer.orderDetails?.productItems;

    if (!productItems || !productItems?.length) return;

    const areAttributesMissing = !(
      productItems[0].image || productItems[0].variationValues
    );

    if (areAttributesMissing && !retry) {
      retry = true;

      const updatedProducts =
        await getProductItemsUpdatedWithVariationAttributes(
          productItems,
          'large',
        );

      if (!updatedProducts) return;

      const orderDetails = listenerApi.getState().orderReducer.orderDetails;

      listenerApi.dispatch(
        orderActions.addUpdatedOrder({
          ...orderDetails,
          productItems: updatedProducts,
        }),
      );

      retry = false;
    }
  },
});

let removeItemsRetry = false;
const basketActionTypes = [
  'getBaskets/fulfilled',
  'updateBasket/fulfilled',
  'getOrCreateCustomerBaskets/fulfilled',
  'addItemToBasket/fulfilled',
  'updateItemInBasket/fulfilled',
  'addShippingMethodToBasketShipment/fulfilled',
  'addBillingAddressToBasket/fulfilled',
  'addShippingAddressToBasketShipment/fulfilled',
];

// remove products without price from basket
listener.startListening({
  predicate: () => true,
  effect: async (action: any, listenerApi: any) => {
    if (includes(basketActionTypes, action.type)) {
      const productItems = listenerApi.getState().basketReducer.productItems;
      const productsWithoutPrice = productItems?.filter(
        (productItem: BasketProductItemType) => {
          return !productItem.price;
        },
      );

      if (
        !productsWithoutPrice?.length ||
        (productsWithoutPrice?.length && removeItemsRetry)
      ) {
        return;
      }

      removeItemsRetry = true;

      productsWithoutPrice.forEach(
        async (productItem: BasketProductItemType) => {
          await listenerApi.dispatch(
            AsyncThunks.removeItemFromBasket({
              itemId: productItem.itemId,
              productId: productItem.productId,
            }),
          );
        },
      );
      removeItemsRetry = false;
    }
  },
});

// add defaultShippingMethodId once the basket is created or got
listener.startListening({
  predicate: () => true,
  effect: async (action: any, listenerApi: any) => {
    if (
      includes(
        [
          'getBaskets/fulfilled',
          'createBasket/fulfilled',
          'getOrCreateCustomerBaskets/fulfilled',
        ],
        action.type,
      )
    ) {
      const isShippingMethodInBasket =
        !!listenerApi.getState().basketReducer.result.shipments[0]
          .shippingMethod;

      if (!isShippingMethodInBasket) {
        const { payload } = await listenerApi.dispatch(
          AsyncThunks.getShippingMethods(),
        );

        await listenerApi.dispatch(
          AsyncThunks.addShippingMethodToBasketShipment(
            payload.defaultShippingMethodId,
          ),
        );
      }
    }
  },
});

export default listener;
