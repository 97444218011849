import { useState, MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu } from '@mui/material';

import { Loader } from 'components';
import CatalogAccordion from 'components/Accordion/CatalogAccordion';
import ROUTES from 'router/Routes';
import useDimensions from 'hooks/useDimensions';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import { formatNameForUrl } from 'helpers/formatters';
import { CatalogItemType } from 'types/CatalogTypes';
import { CrossIcon } from 'assets/svg';
import { useAuthModals } from 'hooks/useModals';
import {
  BottomWrapper,
  CatalogWrapper,
  Divider,
  HamburgerTitle,
  LoginButtonsWrapper,
  Logo,
  StyledHamburgerIcon,
  StyledTextButton,
  Trademark,
} from './styles';

const HamburgerMenu = ({
  catalogData,
  isLoggedIn,
  logout,
}: {
  catalogData: CatalogItemType[] | undefined;
  isLoggedIn: boolean;
  logout: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { height } = useDimensions();
  const { showLoginModal, showRegisterModal } = useAuthModals();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (cb: () => void) => {
    handleClose();
    cb();
  };

  const navigateTo = useCallback(
    (
      props:
        | string
        | {
            catName?: string;
            catId?: string;
          },
    ) => {
      handleClose();

      if (typeof props === 'string') {
        navigate(props);
      } else if (props.catName && props.catId) {
        navigate(
          `${ROUTES.PRODUCTS}/${formatNameForUrl(props.catName)}/${
            props.catId
          }`,
        );
      }
    },
    [],
  );

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        size="small"
        sx={{ p: 0 }}
        aria-label="open hamburger menu"
        aria-controls={open ? 'hamburger-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableRipple
        id="hamburger-menu"
      >
        <StyledHamburgerIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="hamburger-menu"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: height - HEADER_NAV_HEIGHT - 100,
            overflow: 'auto',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: 0,
            mt: HEADER_NAV_HEIGHT / 28,
            '& .MuiList-root': {
              padding: 0,
            },
          },
        }}
      >
        {catalogData ? (
          <div>
            <HamburgerTitle>
              <Logo />
              <CrossIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
            </HamburgerTitle>
            <CatalogWrapper>
              {catalogData
                .filter((catalogItem: CatalogItemType) => !!catalogItem.display)
                .map((catalogItem: CatalogItemType) => {
                  return (
                    <CatalogAccordion
                      key={catalogItem.id}
                      catalogItem={catalogItem}
                      navigateTo={navigateTo}
                    />
                  );
                })}
            </CatalogWrapper>
            <BottomWrapper>
              {isLoggedIn ? (
                <>
                  <LoginButtonsWrapper>
                    <StyledTextButton
                      label="LOGOUT"
                      onClick={() => handleClick(logout)}
                    />
                  </LoginButtonsWrapper>
                  <StyledTextButton
                    label="ACCOUNT"
                    onClick={() => navigateTo(ROUTES.ACCOUNT_PERSONAL_INFO)}
                  />
                </>
              ) : (
                <LoginButtonsWrapper>
                  <StyledTextButton
                    label="LOGIN"
                    onClick={() => handleClick(showLoginModal)}
                  />
                  <Divider>|</Divider>
                  <StyledTextButton
                    label="SIGNUP"
                    onClick={() => handleClick(showRegisterModal)}
                  />
                </LoginButtonsWrapper>
              )}
              <Trademark>
                2023 empower global
                <span style={{ verticalAlign: 'super', fontSize: 6 }}>TM</span>
              </Trademark>
            </BottomWrapper>
          </div>
        ) : (
          <Loader />
        )}
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
