import { assign, clone, compact, findIndex, mapValues, values } from 'lodash';
import {
  CustomerAddressType,
  PaymentInstrumentDataType,
} from 'eg_SFCC_FE_core/types';
import { AddressType } from 'types/OrdersTypes';
import { PaymentCardType } from 'types/CheckoutTypes';
import { ProductAttributeType } from 'types/ProductsTypes';

export const formatPrice = (price: number) => {
  const format = (pr: number) => {
    return `$ ${pr.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  if (price === 0) {
    return format(price);
  }

  return price && format(price);
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const formatShippingAddress = (address: AddressType) => {
  if (!address) return [''];

  const result = {
    fullName: address.fullName,
    address: address.address1,
    address2: address.address2,
    cityStatePostal: `${address.city}, ${address.stateCode} ${address.postalCode}`,
  };

  return compact(values(result));
};

export const formatPaymentInstrument = ({
  paymentCard,
}: PaymentInstrumentDataType): any[] => {
  const result = {};

  if (paymentCard.cardType === 'Afterpay') {
    assign(result, {
      card: paymentCard.cardType,
    });
  } else {
    // for credit cards
    assign(result, {
      card: `Credit ${paymentCard.cardType}`,
      number: paymentCard.maskedNumber,
      expires: `${paymentCard.expirationMonth}/${paymentCard.expirationYear}`,
    });
  }

  return values(result);
};

export const setDefaultAddressFirst = (addresses: CustomerAddressType[]) => {
  const defaultAddressIndex = findIndex(addresses, { preferred: true });
  const defaultAddress = addresses[defaultAddressIndex];

  if (!defaultAddress) return;

  const newAddresses = clone(addresses);

  [newAddresses[defaultAddressIndex], newAddresses[0]] = [
    newAddresses[0],
    newAddresses[defaultAddressIndex],
  ];

  return newAddresses;
};

export const formatAccountPaymentInstrument = (
  paymentCard: PaymentCardType,
): { number: string; cardBrand: string; expires: string } => {
  const result = {
    cardBrand: paymentCard.card.brand.toLocaleUpperCase(),
    number: `*${paymentCard.card.last4}`,
    expires: `${paymentCard.card.exp_month}/${paymentCard.card.exp_year
      .toString()
      .slice(-2)}`,
  };

  return result;
};

export const formatTierName = (tierName: string, suffix: string) => {
  return `${tierName} ${tierName.toLowerCase().startsWith('eg') ? '' : suffix}`;
};

export const formatHtmlToText = (html: string) => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const formatSuggestedAddress = (suggestedAddress: string) => {
  try {
    const suggestedAddressResponse = JSON.parse(suggestedAddress);

    return mapValues(
      suggestedAddressResponse,
      (addressLine: string | string[]) => {
        if (Array.isArray(addressLine)) {
          return addressLine.join(' ');
        }
        return addressLine;
      },
    );
  } catch (e) {
    // console.log(e);
  }
};

export const formatProductAttributeName = (
  productAttribute: ProductAttributeType,
) => {
  switch (productAttribute.id) {
    case 'size':
      return 'Size';
    case 'color':
      return 'Color';
    default:
      return productAttribute.name;
  }
};

export const formatNameForUrl = (name: string) => {
  return name
    .replace(/^[^A-z0-9]|[^A-z0-9]$|\[|]/g, '')
    .replace(/[^A-z0-9]+/g, '-')
    .toLowerCase();
};
