import { Typography } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  margin: 30px 0 20px;
`;

export const Title = styled.h2`
  background: ${COLORS.black};
  color: ${COLORS.white};
  text-align: center;
  font-family: ${FONTS.TTNorms.bold};
  font-size: 40px;
  text-transform: uppercase;
  padding: 8px 0;

  @media (max-width: 750px) {
    font-size: 28px;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 4px;

  .slick-next {
    right: 14px;
  }

  .slick-prev {
    left: 14px;
    z-index: 1;
  }
`;

export const PressItemCard = styled.div`
  width: 100%;
  height: 509px;
  object-fit: cover;
  padding: 0 4px;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 90px;

  @media (max-width: 1700px) {
    padding: 0 40px;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const CardText = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  font-style: italic;

  @media (max-width: 1350px) {
    font-size: 16px;
  }

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const BackgroundImage = styled.div<{
  imageUrl?: string;
}>`
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease;
  position: relative;
`;
