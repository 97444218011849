import { Typography } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const FormWrapper = styled.div`
  max-width: 550px;

  & button {
    max-width: 210px;
    margin-top: 30px;

    @media (max-width: ${BREAKPOINTS.s}px) {
      margin-top: 15px;
    }
  }
`;

export const PersonalData = styled(Typography).attrs({
  type: 'body2Helv',
})`
  opacity: 0.6;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;
