/* eslint-disable indent */
import styled from 'styled-components';

import { COLORS, FONTS } from 'styles';

interface ILinkProps {
  type: 'white' | 'black';
  fontSize?: string;
}
export type LinkType = 'white' | 'black';

export const AnimatedLinkWrapper = styled.div.attrs(
  ({ type, fontSize }: ILinkProps) => ({
    type,
    fontSize: fontSize || '20px',
  }),
)`
  cursor: pointer;
  display: inline-block;
  background: transparent;
  border: 1px solid transparent;
  padding: 7px 11px;
  position: relative;
  transition: all 0.3s;
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${FONTS.Bison.bold};
  line-height: 36px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${({ type }) => {
    return type === 'white' ? COLORS.white : COLORS.primary;
  }};
  text-align: center;

  &:before,
  &:after {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;
    background: transparent;
  }
  &:before {
    width: 0;
    top: 0;
    left: 0;
  }

  &:after {
    border-bottom: 2px solid;
    border-color: ${({ type }) => {
      return type === 'white' ? COLORS.white : COLORS.primary;
    }};
    transition: all 0.3s;
    width: 90%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  }
  &:hover:before,
  &:hover:after,
  &:focus:before,
  &:focus:after {
    width: 100%;
    height: 100%;
  }

  &:hover:before,
  &:focus:before {
    border: 2px solid;
  }
`;

export const AnimatedLinkWrapperUpdated = styled(AnimatedLinkWrapper)`
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${FONTS.Helvetica.medium};
  line-height: 17px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${({ type }) => {
    return type === 'white' ? COLORS.white : COLORS.primary;
  }};
  text-align: center;
  width: auto;
  letter-spacing: 1px;
`;

export const LinkTo = styled.div`
  cursor: pointer;
  padding: 7px 11px;
`;

export const AnimatedArrowLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  padding: 7px 11px;
  font-size: 24px;
  font-family: ${FONTS.Bison.bold};
  letter-spacing: 0.2em;
  line-height: 36px;
  cursor: pointer;
  transition: all 0.4s;

  svg {
    margin-left: 4px;
    opacity: 0;
    transition: all 0.4s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
