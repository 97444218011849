import { Paragraph, UppercaseTitle, Title } from '../styles';

const YourUseOfServices = () => (
  <>
    <UppercaseTitle>6. Your Use of Our Services</UppercaseTitle>
    <Paragraph>
      License to Use Our Services. We grant you a limited, non-exclusive,
      non-transferable, and revocable license to use our Services—subject to the
      Terms and the following restrictions in particular:
    </Paragraph>
    <Title>A. Don’t Use Our Services to Break the Law</Title>
    <Paragraph>
      You agree that you will not violate any laws in connection with your use
      of the Services. This includes any local, state, federal, and
      international laws that may apply to you. For example, it’s your
      responsibility as a seller to obtain any permits or licenses that your
      shop requires, and to meet applicable legal requirements in applicable
      jurisdiction(s). This includes the sale and delivery of your items, such
      as age verification upon delivery, where required by law. You may not sell
      anything that violates any laws and you may not engage in fraud (including
      false claims or infringement notices), theft, anti-competitive conduct,
      threatening conduct, or any other unlawful acts or crimes against Empower
      Global, any Empower Global user, or a third party.
    </Paragraph>
    <Title>B. Pay Your Bills</Title>
    <Paragraph>
      You are responsible for paying all fees that you owe to Empower Global.
      Except as set forth below, you are also solely responsible for collecting
      and/or paying any applicable taxes for any purchases or sales you make
      through our Services. In addition, Empower Global will calculate, collect,
      and remit sales taxes to the appropriate taxing authorities where
      applicable.
    </Paragraph>
    <Title>C. Don’t Steal Our Stuff</Title>
    <Paragraph>
      You agree not to crawl, scrape, or spider any page of the Services or to
      reverse engineer or attempt to obtain the source code of the Services.
    </Paragraph>
    <Title>D. Don’t Try to Harm Our Systems</Title>
    <Paragraph>
      You agree not to interfere with or try to disrupt our Services or their
      security, such as (including, but not limited to) distributing a virus or
      other harmful computer code.
    </Paragraph>
    <Title>E. Follow Our Trademark Policy</Title>
    <Paragraph>
      The name &quot;Empower Global&quot; and the other Empower Global marks,
      phrases, logos, and designs that we use in connection with our Services
      (the Empower Global Trademarks), are trademarks, service marks, or trade
      dress of Empower Global in the US and other countries. You may not use the
      Empower Global Trademarks without our prior written consent.
    </Paragraph>
    <Title>F. Share Your Ideas</Title>
    <Paragraph>
      We love your suggestions and ideas. They can help us improve your
      experience and our Services. Any unsolicited ideas or other materials you
      submit to Empower Global (not including Your Content or items you sell
      through our Services) are considered non-confidential and non-proprietary
      to you. You grant us a non-exclusive, worldwide, royalty-free,
      irrevocable, sub-licensable, perpetual license to use and publish those
      ideas and materials for any purpose, without compensation to you.
    </Paragraph>
    <Title>G. Talk to Us Online</Title>
    <Paragraph>
      From time to time, Empower Global will provide you with certain legal
      information in writing. We may send you information electronically, (such
      as by email) instead of mailing you paper copies (it’s better for the
      environment). Your electronic agreement is the same as your signature on
      paper.
    </Paragraph>
  </>
);

export default YourUseOfServices;
