import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import {
  onFulfilled,
  onPending,
  onError,
} from 'eg_SFCC_FE_core/store/stateResults';

export const PUBLIC_USER_SLICE_NAME = 'publicUser';

const initialState: StateType = {
  pending: false,
  result: null,
  error: null,
};

const publicUserSlice = createSlice({
  name: PUBLIC_USER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getPublicGuestToken.pending, onPending);
    builder.addCase(AsyncThunks.getPublicGuestToken.fulfilled, onFulfilled);
    builder.addCase(AsyncThunks.getPublicGuestToken.rejected, onError);
  },
});

export const publicUserActions = publicUserSlice.actions;
export const publicUserReducer = publicUserSlice.reducer;
