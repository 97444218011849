export const CHANNEL_ID = 'EmpowerGlobal';

export const GRANT_TYPES = {
  authorization_code: 'authorization_code_pkce',
  refresh_token: 'refresh_token',
};

export const TOKEN_EXP = {
  access: 1 / 48,
  refresh: 90,
};

export const COOKIES_KEYS = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  tokenType: 'tokenType',
  ageRestriction: 'ageRestriction',
};
