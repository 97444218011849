import { Loader } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import { ProductType } from 'types/ProductsTypes';
import {
  ProductCardsWrapper,
  ProductCardWrapper,
  Title,
  Wrapper,
} from './styles';

const BottomProducts = ({
  suggestedProducts,
  isLoading,
}: {
  suggestedProducts?: ProductType[];
  isLoading: boolean;
}) => {
  return (
    <Wrapper>
      <Title>You may also like</Title>
      <ProductCardsWrapper>
        {isLoading ? <Loader /> : null}
        {!isLoading && suggestedProducts?.length
          ? suggestedProducts.map((product: ProductType) => {
              return (
                <ProductCardWrapper key={product.productId}>
                  <ProductCard product={product} />
                </ProductCardWrapper>
              );
            })
          : 'There are no products'}
      </ProductCardsWrapper>
    </Wrapper>
  );
};

export default BottomProducts;
