import styled from 'styled-components';
import { COLORS } from 'styles';

export const CheckboxWrapper = styled.div`
  margin-top: 20px;
`;

export const Code = styled.span`
  background-color: ${COLORS.accent};
  height: 25px;
  border-radius: 10px;
  width: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 5px;
`;

export const Cross = styled.span`
  position: absolute;
  right: 20px;
  width: 8px;
  height: 8px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 8px;
    width: 2px;
    background-color: ${COLORS.black};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;
