import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { LineTitle } from 'components';
import MainSection from 'components/ExploreComponents/MainSection/MainSection';
import PopularCategories from 'components/ExploreComponents/PopularCategories/PopularCategories';
import TrendingProducts from 'components/ExploreComponents/TrendingProducts/TrendingProducts';
import ExploreFeaturedBrands from 'components/ExploreComponents/ExploreFeaturedBrands/ExploreFeaturedBrands';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { trending } from 'data/exploreData';
import { ProductType } from 'types/ProductsTypes';
import useGoogleTags from 'hooks/useGoogleTags';

const ExplorePage = () => {
  const [trendingProducts, setTrendingProducts] = useState<ProductType[]>();
  const [trendingProductsLoader, setTrendingProductsLoader] =
    useState<boolean>(false);
  const { addPageView } = useGoogleTags();
  const ids = trending.map((item: { id: string }) => item.id).join(',');

  const fetchTrendingProducts = async () => {
    try {
      setTrendingProductsLoader(true);
      const products = await getProductsByIds(ids);

      setTrendingProducts(
        products?.map((item: any) => ({
          productId: item.id,
          productName: item.name,
          price: item.price,
          image: item.imageGroups[0]?.images[0],
          c_prices: item.c_prices,
        })),
      );
      setTrendingProductsLoader(false);
    } catch (e) {
      setTrendingProductsLoader(false);
    }
  };

  useEffect(() => {
    fetchTrendingProducts();
    addPageView('explore');
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shop Black Owned Businesses | Empower Global</title>
        <meta
          name="description"
          content="Shop Better. Buy Black.Explore the best in class black brands. Check out our featured brands and trending products."
        />
      </Helmet>
      <MainSection />
      <ExploreFeaturedBrands />
      <LineTitle text="TRENDING Products" marginLeft={-85} />
      <TrendingProducts
        trendingProducts={trendingProducts}
        isLoading={trendingProductsLoader}
      />
      <LineTitle
        text="popular categories"
        marginLeft={-220}
        accentTextPosition={3}
      />
      <PopularCategories />
    </>
  );
};

export default ExplorePage;
