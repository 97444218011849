import { CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import { COLORS } from 'styles';
import { Paragraph, UppercaseTitle, List } from '../styles';

const OtherDocuments = () => (
  <>
    <UppercaseTitle>2. Those Other Documents We Mentioned</UppercaseTitle>
    <Paragraph>
      Empower Global’s Services connect people, both online and offline, and
      promote the exchange of goods from Black-owned businesses. Below are
      clutch references that help you understand the specific rules that are
      relevant to you, depending on how you use the Services:
    </Paragraph>
    <List>
      <li>
        Our House Rules for Everyone. If you use any of our Services, you agree
        to these Terms of Use and our{' '}
        <CustomNavLink
          to={ROUTES.PRIVACY_POLICY}
          style={{ color: COLORS.accent }}
        >
          Privacy Policy
        </CustomNavLink>
        .
      </li>
      <li>
        Our House Rules for Sellers. If you list any items for sale through our
        Services, these policies apply to you.
      </li>
      <li>
        Our House Rules for Buyers. If you use Services to browse or shop, these
        policies apply to you.
      </li>
    </List>
    <Paragraph>
      All of these policies are a part of our Terms, so be sure to read the ones
      that are relevant for you. Additionally, there’s still plenty of vital
      information below that applies to everyone.
    </Paragraph>
  </>
);

export default OtherDocuments;
