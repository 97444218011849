import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, FONTS } from 'styles';

export const ShareIconsWrapper = styled.div`
  margin: 10px 0;

  & button {
    margin-right: 20px;

    svg {
      width: 20px;
    }
  }
`;

export const ShareText = styled(Typography).attrs({
  type: 'title4Helv',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography).attrs({
  type: 'title4Helv',
  tag: 'h3',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const ConfirmationText = styled(Typography).attrs({
  type: 'navHelv',
})`
  opacity: 0.6;
  margin: 10px 0 30px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

export const LoyaltyPointsText = styled(Typography).attrs({
  type: 'subtitle3',
})`
  font-family: ${FONTS.TTNorms.bold};
  opacity: 0.6;
`;

export const FormWrapper = styled.div`
  margin: 40px 0 20px;
`;
