import { ExplorePageTitleIcon } from 'assets/svg';

import { Wrapper, BackgroundImage, Title, EGStamp } from './styles';

const MainSection = () => {
  return (
    <>
      <Wrapper>
        <BackgroundImage aria-label="Shop Better Buy Black slogan" role="img" />
        <ExplorePageTitleIcon width="100%" />
        <Title>EXPLORE THE BEST IN BLACK BRANDS</Title>
      </Wrapper>
      <EGStamp />
    </>
  );
};

export default MainSection;
