import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'components';
import SavedSearchUpdateForm from 'components/Forms/SavedSearchUpdateForm/SavedSearchUpdateForm';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { getCustomerLoader } from 'eg_SFCC_FE_core/store/selectors';
import { SavedSearchQueryType } from 'types/AccountTypes';
import { COLORS } from 'styles';
import {
  StyledColoredButton,
  ConfirmationWrapper,
  ConfirmationContentCrossIcon,
  ConfirmationTitle,
  ConfirmationButtonsWrapper,
} from './styles';

const SavedSearchUpdateContent = ({
  savedSearchQuery,
}: {
  savedSearchQuery: SavedSearchQueryType;
}) => {
  const [message, setMessage] = useState<string>();
  const isLoading = useSelector(getCustomerLoader);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(modalActions.hideModal('SAVED_SEARCH_UPDATE_MODAL'));
  };

  const onSubmit = async ({
    savedSearch: updatedSavedSearch,
  }: {
    savedSearch: string;
  }) => {
    try {
      await dispatch(
        AsyncThunks.updateSavedSearchResult({
          id: savedSearchQuery.c_id,
          q: updatedSavedSearch,
        }),
      ).unwrap();

      setMessage('Saved search was updated successfully');
    } catch (e) {
      setMessage('Something went wrong');
    }
  };

  return (
    <ConfirmationWrapper style={{ alignItems: 'center' }}>
      <ConfirmationContentCrossIcon onClick={closeModal} />
      <ConfirmationTitle>Update Saved Search</ConfirmationTitle>
      <SavedSearchUpdateForm
        onError={() => {}}
        onSubmit={onSubmit}
        savedSearch={savedSearchQuery.c_q}
      />
      {message ? (
        <Typography style={{ color: COLORS.accent }}>{message}</Typography>
      ) : null}
      <ConfirmationButtonsWrapper>
        <StyledColoredButton aria-label="cancel" onClick={closeModal}>
          CANCEL
        </StyledColoredButton>
        <StyledColoredButton
          form="saved-search-form"
          aria-label="confirm"
          type="submit"
          isLoading={isLoading}
        >
          SAVE
        </StyledColoredButton>
      </ConfirmationButtonsWrapper>
    </ConfirmationWrapper>
  );
};

export default SavedSearchUpdateContent;
