import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatedWrapper, ColoredButton } from 'components';
import AccountPaymentForm from 'components/AccountComponents/AccountPaymentForm';
import PaymentBlock from 'components/PaymentBlock/PaymentBlock';
import { BackIcon, DeleteIcon, LoyaltyIcon } from 'assets/svg';
import {
  getCustomerId,
  getCustomerLoader,
  getCustomerPaymentInstruments,
  getDefaultPaymentInstrumentId,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { PaymentsPageMode } from 'types/CheckoutTypes';
import { useConfirmationModal } from 'hooks/useModals';
import useStripePayment from 'hooks/useStripePayment';
import { RightBlockWrapper, BlockTitle } from './styles';

type TitlesType = {
  [T in PaymentsPageMode]: {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
  };
};

const titles: TitlesType = {
  paymentsList: {
    Icon: LoyaltyIcon,
    title: 'Saved Payments',
  },
  addPayment: {
    Icon: BackIcon,
    title: 'Add New Payment',
  },
};

const PaymentsPage = () => {
  const dispatch = useAppDispatch();
  const [pageMode, setPageMode] = useState<PaymentsPageMode>('paymentsList');
  const [paymentError, setPaymentError] = useState<string>('');
  const customerPaymentInstruments = useSelector(getCustomerPaymentInstruments);
  const defaultPaymentInstrumentId = useSelector(getDefaultPaymentInstrumentId);
  const openConfirmationModal = useConfirmationModal();
  const isLoading = useSelector(getCustomerLoader);
  const customerId = useSelector(getCustomerId);
  const { createStripePaymentCard } = useStripePayment();
  const switchToAddMode = useCallback(() => setPageMode('addPayment'), []);

  const deletePaymentInstrument = async (id: string) => {
    await dispatch(
      AsyncThunks.removeCustomerPaymentInstrument({
        c_action: 'delete_card',
        c_card_info: id,
      }),
    );
  };

  const handleDeletePaymentInstrument = useCallback(async (id: string) => {
    openConfirmationModal({
      Icon: DeleteIcon,
      title: 'delete payment method',
      text: 'Deleting a payment method would permanently remove it from here. Are you sure you wish to proceed?',
      cancelButtonText: 'cancel',
      confirmButtonText: 'delete',
      confirmButtonAction: () => deletePaymentInstrument(id),
    });
  }, []);

  const handleSavePaymentInstrument = useCallback(async (data: any) => {
    const { stripePaymentMethod, error } = await createStripePaymentCard({
      country: data.country,
      zipCode: data.zipCode,
    });

    if (error) {
      setPaymentError(error);
      return;
    }

    const response = await dispatch(
      AsyncThunks.addCustomerPaymentInstrument({
        c_action: 'save_card',
        c_card_info: stripePaymentMethod.id,
      }),
    );

    // @ts-ignore
    if (response.error) {
      setPaymentError(response.payload.detail);
      return;
    }

    await dispatch(AsyncThunks.getCustomer(customerId));
    setPageMode('paymentsList');
  }, []);

  let retry = false;
  const setDefaultCard = useCallback(async (id: string | null) => {
    if (!id || retry) return;

    retry = true;
    await dispatch(
      AsyncThunks.setDefaultPaymentInstrument({
        c_action: 'set_default_card',
        c_card_info: id,
      }),
    );
    retry = false;
  }, []);

  const handleDefaultCard = useCallback(
    async (id: string | null, cardData?: string) => {
      if (!id) return;

      openConfirmationModal({
        Icon: DeleteIcon,
        title: 'set card as default card',
        text: `Set ${cardData || 'card'} as default card?`,
        cancelButtonText: 'cancel',
        confirmButtonText: 'confirm',
        confirmButtonAction: () => setDefaultCard(id),
      });
    },
    [],
  );

  return (
    <AnimatedWrapper animationKey={pageMode}>
      <RightBlockWrapper>
        <BlockTitle>{titles[pageMode].title}</BlockTitle>

        {pageMode === 'paymentsList' ? (
          <PaymentBlock
            paymentsData={customerPaymentInstruments}
            handleDelete={handleDeletePaymentInstrument}
            isLoading={isLoading}
            selectPaymentInstrument={handleDefaultCard}
            defaultPaymentInstrumentId={defaultPaymentInstrumentId}
          />
        ) : (
          <AccountPaymentForm
            handleSave={handleSavePaymentInstrument}
            isLoading={isLoading}
            paymentError={paymentError}
          />
        )}

        {pageMode === 'paymentsList' ? (
          <ColoredButton
            aria-label="add new"
            width="214px"
            onClick={switchToAddMode}
          >
            add new payment
          </ColoredButton>
        ) : null}
      </RightBlockWrapper>
    </AnimatedWrapper>
  );
};

export default PaymentsPage;
