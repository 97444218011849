import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { ContentWrapper } from 'styles/common';

export const PageWrapper = styled(ContentWrapper)`
  margin: 100px 0;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 30px 0 0;
    padding: 0 25px 22px;
    flex-direction: column;
  }
`;

export const MobilePageTitle = styled(Typography).attrs({
  type: 'title2Helv',
})`
  font-size: 16px;
  margin-bottom: 20px;
  @media (min-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  min-width: 200px;
  max-width: 221px;
  margin-right: 90px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-right: 0;
    max-width: 100%;
    justify-content: center;
  }
`;

export const ClickableMenuItem = styled(Typography)<{
  bold?: boolean;
  isActive?: boolean;
}>`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${COLORS.black};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  margin-bottom: 18px;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    color: ${COLORS.black};
  }
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    margin-bottom: 8px;
  }
`;

export const MobileContentWrapper = styled.div`
  padding-bottom: 50px;
`;

export const DesktopContentWrapper = styled.div`
  max-width: 900px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
    margin: 0;
  }
`;

export const DesktopPageTitle = styled(Typography).attrs({
  type: 'title2Helv',
})`
  margin-bottom: 30px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;

export const ContactInfoWrapper = styled.div`
  max-width: 280px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;
