import styled from 'styled-components';

import { ColoredButton, Typography } from 'components';
import { PageContentWrapper } from 'styles/common';
import { BREAKPOINTS, COLORS } from 'styles';

export const CheckoutPageWrapper = styled(PageContentWrapper)`
  padding: 68px 90px 68px 160px;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.l}px) {
    padding: 23px 40px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 23px 20px;
  }
`;

export const PagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

export const CheckoutButton = styled(ColoredButton).attrs({
  inverted: true,
})`
  & p {
    font-size: 14px;

    @media (max-width: ${BREAKPOINTS.s}px) {
      font-size: 8px;
    }
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    height: 23px;
  }
`;

export const MainTitle = styled(Typography).attrs({
  type: 'title4Helv',
  tag: 'h1',
})`
  text-transform: uppercase;
  margin-bottom: 30px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-bottom: 23px;
  }
`;

export const Title = styled(Typography).attrs({
  type: 'title4Helv',
  tag: 'h2',
})`
  text-transform: uppercase;
  margin-bottom: 27px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

export const OutletPage = styled.div`
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: ${COLORS.cardBackground};
`;

export const Text = styled(Typography).attrs({ type: 'body2Helv' })`
  opacity: 0.6;
  margin-left: 40px;
  text-transform: capitalize;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 14px;
    margin-left: 20px;
  }
`;
