import { InputAdornment } from '@mui/material';
import BasicAutocomplete from 'components/Dropdowns/Selects/BasicAutocomplete';
import FormInputController from 'components/Inputs/FormInputController';
import { countries, states } from 'data/autocompleteData';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { Typography } from 'components/Typography/Typography';
import AddressAutocomplete from 'components/Dropdowns/Selects/AddressAutocomplete';
import StatesSelect from 'components/Dropdowns/Selects/StateSelect';
import AddressTooltip from '../AddressTooltip';
import { FormWrapper } from './styles';
import { FieldsBox } from '../styles';

export const addressErrorStatuses: any = {
  invalid_addres: 'The address entered is invalid.',
  invalid_request: 'Invalid request.',
  services_error: 'Services error.',
  duplicate_address: 'This address already exists',
};

const AddressForm = ({
  onSubmit,
  onError,
  onFocus,
  onChange,
  methods,
  addressError,
}: {
  onSubmit: (value: any) => void;
  onError: (e: any, errors: any) => void;
  onFocus?: () => void;
  onChange?: () => void;
  methods: any;
  addressError: any;
}) => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values: AddressFormFieldsType = watch();

  const updateAddressFields = () => {
    const fullAddress = `${values.address1} ${values.address2}`;
    if (fullAddress.length <= 30) {
      setValue('address1', fullAddress);
      setValue('address2', '');
    }
  };

  return (
    <FormWrapper
      component="form"
      id="address-form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
      onFocus={onFocus}
      onChange={onChange}
    >
      {addressError && (
        <Typography color="accent" type="bodyHelv">
          {addressErrorStatuses[addressError?.statusCode] ||
            'The address entered is invalid.'}
        </Typography>
      )}
      <FieldsBox flexible>
        <FormInputController
          control={control}
          name="firstName"
          label="First Name*"
          error={!!errors.firstName}
          errorMessage={errors?.firstName?.message}
        />
        <FormInputController
          control={control}
          name="lastName"
          label="Last Name*"
          error={!!errors.lastName}
          errorMessage={errors?.lastName?.message}
        />
      </FieldsBox>
      <AddressAutocomplete
        control={control}
        name="address1"
        label="Address Line 1*"
        values={values}
        methods={methods}
        defaultValue={values.address1}
        error={!!errors.address1}
        errorMessage={errors?.address1?.message}
      />
      <FormInputController
        control={control}
        name="address2"
        label="Apartment, Suite, Building (Optional)"
        error={!!errors.address2}
        errorMessage={errors?.address2?.message}
        handleAddressesOnBlur={updateAddressFields}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AddressTooltip />
            </InputAdornment>
          ),
        }}
      />

      <FieldsBox>
        <BasicAutocomplete
          name="countryCode"
          control={control}
          label="Country*"
          defaultValue={values.countryCode}
          error={!!errors.countryCode}
          errorMessage={errors?.countryCode?.message}
          items={countries}
          freeInput
        />

        <StatesSelect
          name="stateCode"
          control={control}
          label="State*"
          defaultValue={values.stateCode}
          error={!!errors.stateCode}
          errorMessage={errors?.stateCode?.message}
          items={states}
        />
      </FieldsBox>

      <FieldsBox>
        <FormInputController
          name="city"
          control={control}
          label="City*"
          error={!!errors.city}
          errorMessage={errors?.city?.message}
        />

        <FormInputController
          control={control}
          name="postalCode"
          label="ZIP code*"
          error={!!errors.postalCode}
          errorMessage={errors?.postalCode?.message}
        />
      </FieldsBox>
      <FormInputController
        control={control}
        name="phone"
        label="Phone Number*"
        error={!!errors.phone}
        errorMessage={errors?.phone?.message}
      />
    </FormWrapper>
  );
};

export default AddressForm;
