import { formatNameForUrl } from 'helpers/formatters';
import ROUTES from 'router/Routes';

import {
  BackgroundImage,
  HoverableCardWrapper,
  HoverableTitle,
  HoverContent,
  HoverOverlay,
  Link,
} from './styles';

const HoverableCategoryCard = ({
  image,
  alt,
  name,
  id,
  linkPosition,
}: {
  image: string;
  alt: string;
  name: string;
  id: string;
  linkPosition: string;
}) => {
  return (
    <HoverableCardWrapper>
      <BackgroundImage src={require(`../../${image}`)} alt={alt} />
      <HoverContent>
        <Link
          to={`${ROUTES.PRODUCTS}/${formatNameForUrl(name)}/${id}`}
          linkPosition={linkPosition}
        >
          <HoverableTitle>{name}</HoverableTitle>
        </Link>
      </HoverContent>
      <HoverOverlay />
    </HoverableCardWrapper>
  );
};

export default HoverableCategoryCard;
