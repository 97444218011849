import { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import CustomModal from 'components/Modals/CustomModal';
import useHeader from 'hooks/useHeader';
import { GlobalStyles } from 'styles/GlobalStyles';
import { theme } from 'styles/themes';
import Footer from './Footer/Footer';
import { Container, Main } from './styles';
import Header from './Header/Header';
import HeaderBottomBar from './Header/HeaderBottomBar';

const Layout = ({ children }: { children: ReactNode }) => {
  const { isHeaderSticky } = useHeader();

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <GlobalStyles />
        <Header />
        <HeaderBottomBar />
        <Main isHeaderSticky={isHeaderSticky}>{children}</Main>
        <Footer />
      </Container>
      <CustomModal />
    </ThemeProvider>
  );
};

export default Layout;
