import { Helmet } from 'react-helmet';

import { vacancies } from 'data/vacancies';
import AboutEmpower from 'components/CareersComponents/AboutEmpower/AboutEmpower';
import OpenPositions from 'components/CareersComponents/OpenPositions/OpenPositions';
import TeamMembersSlide from 'components/AboutComponents/AboutTeamSection/TeamMembersSlide';
import PressSection from 'components/AboutComponents/PressSection/PressSection';
import { PlayVideoIcon } from 'assets/svg';
import { HeroBackgroundImage, IconWrapper } from './styles';

const CareersPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers</title>
        <meta name="description" content="Careers" />
      </Helmet>
      <HeroBackgroundImage
        imageUrl={require('../../assets/jpg/careers/hero-careers.jpg')}
      >
        <IconWrapper>
          <PlayVideoIcon />
        </IconWrapper>
      </HeroBackgroundImage>

      <OpenPositions vacancies={vacancies} />
      <TeamMembersSlide />
      <AboutEmpower />
      <PressSection />
    </div>
  );
};

export default CareersPage;
