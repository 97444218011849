import { find, forEach } from 'lodash';

import { ExtendedCategoryType } from 'types/CatalogTypes';
import { CategoryTitle, SubcategoryTitle, Wrapper } from './styles';

const getCategoriesHeaders = (
  allCategories: ExtendedCategoryType[],
  id: string,
): {
  catName: string;
  subcatName: string;
} => {
  let result = {
    catName: '',
    subcatName: '',
  };

  forEach(allCategories, (category: ExtendedCategoryType) => {
    if (category.id === id) {
      result = { ...result, catName: category.name };
      return result;
    }

    const selectedCategory = find(category.categories, { id });

    if (selectedCategory) {
      result = { ...result, catName: selectedCategory.name };
      return false;
    }

    forEach(category.categories, (cat: any) => {
      const selectedSubcategory = find(cat.categories, { id });

      if (selectedSubcategory) {
        result = {
          catName: cat.name,
          subcatName: selectedSubcategory.name,
        };
        return false;
      }
    });
  });

  return result;
};

const ProductListingHeader = ({
  categories,
  categoryId,
}: {
  categories: ExtendedCategoryType[];
  categoryId: string;
}) => {
  const headings = getCategoriesHeaders(categories, categoryId);
  const category = headings?.catName;
  const subcategory = headings?.subcatName;

  return (
    <Wrapper>
      {subcategory && <CategoryTitle>{category}</CategoryTitle>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: 10,
        }}
      >
        <SubcategoryTitle>{subcategory || category}</SubcategoryTitle>
      </div>
    </Wrapper>
  );
};

export default ProductListingHeader;
