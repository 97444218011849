export default {
  TTNorms: {
    light: 'TTNormsLight', // 300
    regular: 'TTNormsRegular', // 400
    medium: 'TTNormsMedium', // 500
    bold: 'TTNormsBold', // 700
  },
  Bison: {
    bold: 'BisonBold', // 700
    regular: 'BisonRegular', // 400
  },
  DMSans: {
    regular: 'DMSansRegular', // 400
  },
  Helvetica: {
    regular: 'HelveticaRegular', // 400
    medium: 'HelveticaMedium', // 500
    bold: 'HelveticaBold', // 700
  },
};
