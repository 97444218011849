import { CSSProperties, ReactNode } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { ArrowDownIcon } from 'assets/svg';
import { COLORS, FONTS } from 'styles';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 420,
      width: 319,
      fontFamily: `${FONTS.TTNorms.regular}`,
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.1)',
    },
  },
  MenuListProps: { style: { padding: 0 } },
};

const BasicSelect = ({
  id,
  optionValues,
  renderLabel,
  variant = 'standard',
  multiple = false,
  children,
  formControlSx,
  error,
  endAdornment,
  label,
  onClose,
  disabled,
  sxStyles,
  ...props
}: {
  id?: string;
  optionValues: string[];
  renderLabel: (_: any) => ReactNode;
  multiple?: boolean;
  variant?: 'standard' | 'outlined';
  children: ReactNode;
  formControlSx?: any;
  error?: boolean;
  endAdornment?: ReactNode;
  label?: string;
  onClose?: () => void;
  disabled?: boolean;
  sxStyles?: CSSProperties;
}) => {
  return (
    <FormControl
      sx={formControlSx}
      variant={variant}
      size="small"
      margin="dense"
      error={error}
      disabled={disabled}
    >
      {label && (
        <InputLabel
          id="select-label"
          sx={{
            fontFamily: `${FONTS.TTNorms.regular}`,
            '&.Mui-focused': {
              color: 'orange',
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        labelId="select-label"
        IconComponent={ArrowDownIcon}
        multiple={multiple}
        displayEmpty
        value={optionValues}
        renderValue={renderLabel}
        label={label}
        MenuProps={MenuProps}
        endAdornment={endAdornment}
        disableUnderline
        sx={{
          borderRadius: 0,
          '&:after, &:before': { border: 'none' },
          '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
          '& .MuiInput-input': {
            paddingTop: 0,
            paddingBottom: 0,

            '&:focus': { backgroundColor: 'transparent' },
          },
          '& .MuiSelect-icon': { top: '50%', marginTop: '-3px' },
          '&': {
            width: '100%',

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: label && `1px solid ${COLORS.accent}`,
            },
            ...sxStyles,
          },
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        onClose={onClose}
        {...props}
      >
        <MenuItem value="" key="" sx={{ display: 'none' }} />
        {children}
      </Select>
    </FormControl>
  );
};

export default BasicSelect;
