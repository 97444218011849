import { NavLink } from 'react-router-dom';
import ROUTES from 'router/Routes';
import {
  List,
  ListItem,
  MainTitle,
  NestedList,
  Paragraph,
  Title,
} from '../styles';

const VirginiaResidentRights = () => {
  return (
    <>
      <MainTitle>VIRGINIA RESIDENT RIGHTS</MainTitle>
      <Paragraph>
        If you are a Virginia resident, you have the rights set forth under the
        Virginia Consumer Data Protection Act (“VCDPA”). Please see the
        “Exercising Your Rights” section below for instructions regarding how to
        exercise these rights. Please note that we may process Personal Data of
        our customers’ end users or employees in connection with our provision
        of certain services to our customers. If we are processing your Personal
        Data as a service provider, you should contact the entity that collected
        your Personal Data in the first instance to address your rights with
        respect to such data. Additionally, please note that these rights are
        subject to certain conditions and exceptions under applicable law, which
        may permit or require us to deny your request.
      </Paragraph>

      <Paragraph>
        If there are any conflicts between this section and any other provision
        of this Privacy Policy and you are a Virginia resident, the portion that
        is more protective of Personal Data shall control to the extent of such
        conflict. If you have any questions about this section or whether any of
        the following rights apply to you, please contact us at{' '}
        <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>.
      </Paragraph>

      <Title space>A. ACCESS</Title>
      <Paragraph>
        You have the right to request confirmation of whether or not we are
        processing your Personal Data and to access your Personal Data.
      </Paragraph>

      <Title space>B. CORRECTION</Title>
      <Paragraph>
        You have the right to correct inaccuracies in your Personal Data, to the
        extent such correction is appropriate in consideration of the nature of
        such data and our purposes of processing your Personal Data.
      </Paragraph>

      <Title space>C. PORTABILITY</Title>
      <Paragraph>
        You have the right to request a copy of your Personal Data in a
        machine-readable format, to the extent technically feasible.
      </Paragraph>

      <Title space>D. DELETION</Title>
      <Paragraph>You have the right to delete your Personal Data.</Paragraph>

      <Title space>E. OPT-OUT OF CERTAIN PROCESSING ACTIVITIES</Title>
      <List style={{ marginTop: 10 }}>
        <ListItem>
          You have the right to opt-out of the processing of your Personal Data
          for targeted advertising purposes. We process your Personal Data for
          targeted advertising purposes. To opt-out of our processing of
          Personal Data for targeted advertising purposes, please
          <NestedList>
            <li>
              Email us at{' '}
              <a href="mailto:legal@EmpowerGlobal.shop">
                legal@EmpowerGlobal.shop
              </a>
              , or
            </li>
            <li>
              o Opt out from though the “Do Not Sell or Share My Personal
              Information” option available in our website{' '}
              <NavLink to={ROUTES.COOKIES_PREFERENCES}>
                Cookie Preferences
              </NavLink>
              .
            </li>
          </NestedList>
        </ListItem>
        <ListItem>
          You have the right to opt-out to the sale of your Personal Data. We do
          not currently sell your Personal Data as defined under the VCDPA.
        </ListItem>
        <ListItem>
          You have the right to opt-out from the processing of your Personal
          Data for the purposes of profiling in furtherance of decisions that
          produce legal or similarly significant effects to you, if applicable.
        </ListItem>
      </List>

      <Title space>F. APPEALING A DENIAL</Title>
      <Paragraph>
        If we refuse to take action on a request within a reasonable period of
        time after receiving your request in accordance with this section, you
        may appeal our decision. In such appeal, you must (1) provide sufficient
        information to allow us to verify that you are the person about whom the
        original request pertains and to identify the original request, and (2)
        provide a description of the basis of your appeal. Please note that your
        appeal will be subject to your rights and obligations afforded to you
        under the VCDPA. We will respond to your appeal within 60 days of
        receiving your request. If we deny your appeal, you have the right to
        contact the Virginia Attorney General using the methods described at{' '}
        <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
          https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint
        </a>
        .
      </Paragraph>
      <Paragraph space>
        You may appeal a decision by us using the following methods:
      </Paragraph>
      <List>
        <ListItem>
          Email us at:{' '}
          <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>{' '}
          (title must include “VCDPA Appeal”)
        </ListItem>
      </List>
    </>
  );
};

export default VirginiaResidentRights;
