import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';

const createCustomerProductListThunk = async (customerId: string) => {
  const productListParams = {
    name: 'Wishlist',
    public: false,
    type: 'wish_list',
  };
  const params = { siteId: process.env.REACT_APP_SITE_ID };

  const response = await axiosInstance.post(
    endpoints.customer.customerProductLists(customerId),
    productListParams,
    { params },
  );

  return response;
};

export const getCustomerProductListsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (customerId, { rejectWithValue }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    let response = await axiosInstance.get(
      endpoints.customer.customerProductLists(customerId),
      { params },
    );

    if (!response.data.total) {
      response = await createCustomerProductListThunk(customerId);
      return response.data;
    }

    if (response.data.data && response.data.data.length) {
      return response.data.data[0];
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const createCustomerProductListItemThunk: AsyncThunkPayloadCreator<
  any,
  {
    uriParams: {
      customerId: string;
      listId: string;
    };
    productId: string;
  },
  { rejectValue: ErrorResponse }
> = async (
  { uriParams: { customerId, listId }, productId },
  { rejectWithValue },
) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const productParams = {
      type: 'product',
      priority: 1,
      public: false,
      product_id: productId,
      quantity: 1,
    };

    const response = await axiosInstance.post(
      endpoints.customer.customerProductListItem(customerId, listId),
      productParams,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const deleteCustomerProductListItemThunk: AsyncThunkPayloadCreator<
  any,
  {
    uriParams: {
      customerId: string;
      listId: string;
      itemId: string;
    };
  },
  { rejectValue: ErrorResponse }
> = async (
  { uriParams: { customerId, listId, itemId } },
  { rejectWithValue },
) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    await axiosInstance.delete(
      `${endpoints.customer.customerProductListItem(
        customerId,
        listId,
      )}/${itemId}`,
      { params },
    );

    return itemId;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
