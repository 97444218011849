import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PersonalInfoForm from 'components/Forms/PersonalInfoForm/PersonalInfoForm';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import {
  getCustomerData,
  getCustomerLoader,
  getAuthError,
  getWasEmailUpdated,
} from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { COLORS } from 'styles';
import EnterPasswordContent from 'components/Modals/ModalContent/EnterPasswordContent';
import { Typography } from 'components';
import { RightBlockWrapper, BlockSubtitle, BlockTitle } from './styles';

const PersonalInfoPage = () => {
  const [password, setPassword] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const customerError = useSelector(getAuthError);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch = useAppDispatch();
  const { firstName, lastName, email, phoneMobile, ...data } =
    useSelector(getCustomerData);
  const isLoading = useSelector(getCustomerLoader);
  const emailWasUpdated = useSelector(getWasEmailUpdated);
  const loyaltyInfo = data?.c_loyaltyInfo;
  const membersInfo = loyaltyInfo?.consumersInfo?.[0].membersInfo;
  const currentTierName = membersInfo ? membersInfo[0]?.currentTierName : '';

  const updatePersonalInfo = useCallback(async (customerData: any) => {
    const response = await dispatch(AsyncThunks.updateCustomer(customerData));

    // @ts-ignore
    if (!response.error) {
      setErrorMessage('Data have been updated');
    } else {
      setErrorMessage(response.payload.detail);
    }
  }, []);

  const handleAddPassword = () => {
    dispatch(
      modalActions.showModal({
        id: 'ENTER_PASSWORD_MODAL',
        modalContent: <EnterPasswordContent setPassword={setPassword} />,
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: COLORS.white,
        },
      }),
    );
  };

  const updateEmail = useCallback((updatedEmail: string) => {
    setNewEmail(updatedEmail);
    handleAddPassword();
  }, []);

  useEffect(() => {
    if (password) {
      dispatch(modalActions.hideModal('ENTER_PASSWORD_MODAL'));

      updatePersonalInfo({
        email: newEmail,
        c_password: password,
      });
    }
  }, [password]);

  useEffect(() => {
    if (customerError) {
      // @ts-ignore
      if (customerError?.statusCode === 'password_invalid') {
        setErrorMessage('Wrong password');
      }
    }
  }, [customerError]);

  useEffect(() => {
    if (emailWasUpdated) {
      setErrorMessage('You must re-login with a new mail');
    }
  }, [emailWasUpdated]);

  return (
    <RightBlockWrapper>
      <BlockTitle>Personal Information</BlockTitle>
      <BlockSubtitle>Edit your preferences below.</BlockSubtitle>
      {errorMessage && (
        <Typography style={{ color: COLORS.accent }}>{errorMessage}</Typography>
      )}
      <PersonalInfoForm
        firstName={firstName || ''}
        lastName={lastName || ''}
        email={email || ''}
        phoneMobile={phoneMobile || ''}
        loyaltyMember={data?.c_LoyaltyMember || false}
        subscribedToEmails={data?.c_MarketingOptin || false}
        updatePersonalInfo={updatePersonalInfo}
        updateEmail={updateEmail}
        isLoading={isLoading}
        currentTierName={currentTierName || ''}
      />
    </RightBlockWrapper>
  );
};

export default PersonalInfoPage;
