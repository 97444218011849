import styled from 'styled-components';
import { FONTS } from 'styles';

export const Wrapper = styled.div`
  padding: 80px;
  display: grid;
  place-items: center;
  background: #ddd6d020;

  @media (max-width: 1000px) {
    padding: 40px 20px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 28px;
  }
`;

export const ProductCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ProductCardWrapper = styled.div`
  width: 24%;

  @media (max-width: 1000px) {
    width: 31%;
  }

  @media (max-width: 750px) {
    height: 300px;
    width: 45%;
  }
`;
