import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout/Layout';
import {
  HomePage,
  AboutPage,
  SellerPage,
  BrandsPage,
  BrandLandingPage,
  ProductListingPage,
  ProductDetailsPage,
  CheckoutPage,
  AccountPage,
  AddressesPage,
  PaymentsPage,
  MyOrdersPage,
  PersonalInfoPage,
  AccountResetPasswordPage,
  ConfirmationPage,
  AccountSetupPage,
  SearchPage,
  NotFoundPage,
  FavoritesPage,
  PrivacyPolicyPage,
  ContactUsPage,
  ContactUsHelpPage,
  ContactUsCommunityPage,
  CartPage,
  FaqPage,
  OrderReturnsPage,
  OrderReturnReasonPage,
  OrderReturnAddressesPage,
  OrderReturnConfirmPage,
  ResetPasswordPage,
  TermsConditionsPage,
  ShippingReturnsPage,
  AcceptRedirectPage,
  SavedSearchesPage,
  CheckoutDetailsPage,
} from 'pages';
import { getIsLoggedIn } from 'eg_SFCC_FE_core/store/selectors';
import RoutesAnimation from 'styles/RoutesAnimation';
import ROUTES from './Routes';

const Router = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <Layout>
      <RoutesAnimation>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.ACCEPT_REDIRECT} element={<AcceptRedirectPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
        {isLoggedIn || process.env.REACT_APP_GUEST_LOGIN === 'true' ? (
          <>
            <Route path={ROUTES.ABOUT} element={<AboutPage />} />
            <Route path={ROUTES.SEARCH} element={<SearchPage />} />
            <Route path={ROUTES.SELLER} element={<SellerPage />} />

            <Route path={ROUTES.BRANDS} element={<BrandsPage />} />
            <Route path={ROUTES.BRAND_LANDING} element={<BrandLandingPage />} />
            <Route
              path={ROUTES.PRODUCT_LISTING_BY_CATEGORY}
              element={<ProductListingPage />}
            />
            <Route
              path={ROUTES.PRODUCTS_DETAILS}
              element={<ProductDetailsPage />}
            />
            <Route path={ROUTES.CHECKOUT} element={<CheckoutPage />}>
              <Route index element={<AccountSetupPage />} />
              <Route
                path={ROUTES.CHECKOUT_ACCOUNT_SETUP}
                element={<AccountSetupPage />}
              />
              <Route
                path={ROUTES.CHECKOUT_DETAILS}
                element={<CheckoutDetailsPage />}
              />
              <Route
                path={ROUTES.CHECKOUT_CONFIRMATION}
                element={<ConfirmationPage />}
              />
            </Route>
            <Route
              path={ROUTES.PRIVACY_POLICY}
              element={<PrivacyPolicyPage />}
            />
            <Route
              path={ROUTES.SHIPPING_RETURNS}
              element={<ShippingReturnsPage />}
            />
            <Route
              path={ROUTES.TERMS_CONDITIONS}
              element={<TermsConditionsPage />}
            />
            <Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
            <Route
              path={ROUTES.CONTACT_US_HELP}
              element={<ContactUsHelpPage />}
            />
            <Route
              path={ROUTES.CONTACT_US_COMMUNITY}
              element={<ContactUsCommunityPage />}
            />
            <Route path={ROUTES.FAQ} element={<FaqPage />} />

            <Route path={ROUTES.CART} element={<CartPage />} />

            <Route path={ROUTES.FAVORITES} element={<FavoritesPage />} />
            <Route path={ROUTES.ACCOUNT} element={<AccountPage />}>
              <Route index element={<PersonalInfoPage />} />
              <Route
                path={ROUTES.ACCOUNT_PERSONAL_INFO}
                element={<PersonalInfoPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_RESET_PASSWORD}
                element={<AccountResetPasswordPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_MY_ORDERS}
                element={<MyOrdersPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_ORDER_RETURNS}
                element={<OrderReturnsPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_ORDER_RETURNS_REASON}
                element={<OrderReturnReasonPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_ORDER_RETURNS_ADDRESSES}
                element={<OrderReturnAddressesPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_ORDER_RETURNS_CONFIRM}
                element={<OrderReturnConfirmPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_ADDRESSES}
                element={<AddressesPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_PAYMENTS}
                element={<PaymentsPage />}
              />

              <Route
                path={ROUTES.ACCOUNT_SAVED_SEARCHES}
                element={<SavedSearchesPage />}
              />
            </Route>
          </>
        ) : null}
        <Route path="*" element={<NotFoundPage />} />
      </RoutesAnimation>
    </Layout>
  );
};

export default Router;
