import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { WBackground } from 'assets/svg';
import { vacancies } from 'data/vacancies';
import { Typography } from 'components';
import ShareIcons from 'components/CareersComponents/ShareIcons/ShareIcons';
import { FONTS } from 'styles';
import {
  BoldTitle,
  LeftBlock,
  HeroBackgroundImage,
  List,
  Location,
  Text,
  Title,
  Wrapper,
  RightBlock,
  QuoteText,
  Quote,
  RotatedQuote,
  QuoteWrapper,
  StyledColoredButton,
} from './styles';

const CareerDetailsPage = () => {
  const { careerId } = useParams();
  const vacancy = vacancies.find((v) => v.id === careerId);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Details</title>
        <meta name="description" content="Career Details" />
      </Helmet>
      <HeroBackgroundImage
        imageUrl={require('../../assets/jpg/careers/hero-career.jpg')}
      />

      <Wrapper>
        <WBackground
          style={{
            position: 'absolute',
            zIndex: -1,
            top: 0,
            left: '-50%',
          }}
        />
        <WBackground
          style={{
            position: 'absolute',
            zIndex: -1,
            top: '70%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'inherit',
          }}
        />
        <LeftBlock>
          <Title>{vacancy?.position}</Title>
          <Location>
            {vacancy?.city
              ? `${vacancy.city} (${vacancy.type})`
              : vacancy?.type}
          </Location>
          <Typography type="subtitle1" style={{ textTransform: 'uppercase' }}>
            {vacancy?.category}
          </Typography>

          <div style={{ marginBottom: 30 }}>
            <BoldTitle>Role Overview</BoldTitle>
            <Text>{vacancy?.roleOverview}</Text>
            <BoldTitle>Reporting Structure</BoldTitle>
            <Text>{vacancy?.reportingStructure}</Text>
            <BoldTitle>About Empower Global</BoldTitle>
            <Text>
              Founded by Sean &quot;Diddy&quot; Combs in 2021 and under the
              umbrella of Combs Global, Empower Global is an immersive
              e-commerce experience offering best in class products - apparel,
              beauty, fashion, art, travel - from Black-owned businesses. With
              culture and community at its core, Empower Global&apos;s digital
              marketplace elevates the Black economy by creating opportunities
              for Black entrepreneurs to build and scale successful businesses
              and for everyone to &quot;Shop Black&quot; daily with ease.
              Headquartered in Atlanta Empower Global is the premier destination
              to discover and explore Black-owned businesses worldwide. Join us
              and the culture.
            </Text>

            <div style={{ marginTop: 35 }}>
              {vacancy?.information.map(({ name, details }) => (
                <div key={name}>
                  <Title>{name}</Title>
                  <List>
                    {details.map((item, index) => (
                      <li key={index}>
                        <Text>{item}</Text>
                      </li>
                    ))}
                  </List>
                </div>
              ))}
            </div>
          </div>
        </LeftBlock>

        <RightBlock>
          <QuoteWrapper>
            <QuoteText>
              <div style={{ position: 'absolute', left: -60 }}>
                <Quote />
                <Quote />
              </div>
              Working at Empower means Black empowerment at every level.{' '}
              <RotatedQuote />
              <RotatedQuote />
            </QuoteText>
            <Typography style={{ fontSize: 32, margin: '30px 0' }}>
              Khadijah Robinson
            </Typography>
            <a
              href="https://app.clickup.com/36140889/v/fm/12exut-4884?pr=60017638"
              target="_blank"
              rel="noreferrer"
            >
              <StyledColoredButton aria-label="apply now">
                apply now
              </StyledColoredButton>
            </a>
          </QuoteWrapper>

          <div>
            <Typography
              style={{
                textTransform: 'uppercase',
                fontFamily: FONTS.TTNorms.bold,
                marginBottom: 22,
                fontSize: 20,
              }}
            >
              share this listing
            </Typography>
            <ShareIcons vacancy={vacancy} />
          </div>
        </RightBlock>
      </Wrapper>
    </div>
  );
};

export default CareerDetailsPage;
