import styled from 'styled-components';
import { Typography, CustomNavLink } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const Title = styled(Typography)<{
  space?: boolean;
}>`
  margin-top: ${({ space }) => (space ? '16px' : 0)};
  font-size: 16px;
  line-height: 21px;
  font-family: ${FONTS.Helvetica.bold};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 11px;
    line-height: 14px;
    margin-top: 10px;
  }
`;

export const MainTitle = styled(Title)`
  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;

export const Paragraph = styled(Typography)<{
  space?: boolean;
}>`
  margin-top: ${({ space }) => (space ? '14px' : '6px')};
  font-size: 14px;
  line-height: 17px;
  font-family: ${FONTS.Helvetica.regular};

  a {
    color: ${COLORS.black};
    font-family: ${FONTS.Helvetica.medium};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: ${({ space }) => (space ? '10px' : '2px')};
    font-size: 12px;
    line-height: 15px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const List = styled.ul`
  list-style: disc;
  list-style-position: inside;
  margin-left: 10px;
  & p {
    display: inline;
  }
  a {
    color: ${COLORS.black};
    font-family: ${FONTS.Helvetica.medium};
  }
`;

export const OrderedList = styled.ol`
  list-style: decimal;
  list-style-position: inside;
  padding-bottom: 6px;
`;

export const ListItem = styled.li`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 14px;
  line-height: 17px;

  & p {
    display: inline;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const NestedList = styled.ul<{
  listStyle?: string;
}>`
  list-style: ${({ listStyle }) => listStyle || 'circle'};
  list-style-position: inside;
  margin-left: 20px;
  font-size: 14px;
  line-height: 17px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 13px;
  }

  li {
    margin-bottom: 6px;
  }
`;

export const StyledLink = styled(CustomNavLink)`
  color: ${COLORS.black};
  font-family: ${FONTS.Helvetica.medium};
`;

export const TableShipping = styled.table`
  margin-top: 10px;
  td {
    border-right: 40px solid white;
    &:last-child {
      border-right: 0;
    }
  }

  p {
    margin: 0;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    td {
      border-right: 20px solid white;
    }
  }
`;

export const UppercaseTitle = styled.h2`
  padding-top: 20px;
  font-size: 18px;
  font-family: ${FONTS.TTNorms.bold};
  text-transform: uppercase;
`;

export const TableWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: 280px;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid black;

  font-size: 14px;
  line-height: 17px;

  td,
  th {
    border: 1px solid black;
    padding: 10px;
  }

  th {
    font-weight: 700;
    vertical-align: middle;
    text-transform: capitalize;
  }

  td {
    height: 50px;
  }

  tr td:first-child {
    font-weight: 700;
    text-transform: capitalize;
  }

  ul {
    list-style: disc;
    list-style-position: inside;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 13px;
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    td:first-child,
    th:first-child {
      width: 90px;
    }
  }
`;
