import { useState } from 'react';
import { AccordionSummary } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { CatalogItemType, ExtendedCategoryType } from 'types/CatalogTypes';
import {
  CatalogItemTitle,
  RotatedMinus,
  RotatedPlus,
  StyledCatalogAccordion,
  SubCatalogItemTitle,
} from './styles';
import SubCatalogAccordion from './SubCatalogAccordion';

const CatalogAccordion = ({
  catalogItem: { id, name, expands, route, categories },
  navigateTo,
}: {
  catalogItem: CatalogItemType;
  navigateTo: (props: string | { catName: string; catId: string }) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleTitleClick = () => {
    if (route) {
      navigateTo(route);
    } else {
      navigateTo({ catName: name, catId: id });
    }
  };

  return (
    <StyledCatalogAccordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={
        { unmountOnExit: true, mountOnEnter: true } as TransitionProps
      }
    >
      <AccordionSummary
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        sx={{ padding: 0 }}
      >
        <CatalogItemTitle onClick={handleTitleClick}>{name}</CatalogItemTitle>
        {expands && categories && categories.length ? (
          <div style={{ position: 'absolute', right: 0 }}>
            {expanded ? <RotatedMinus /> : <RotatedPlus />}
          </div>
        ) : null}
      </AccordionSummary>
      {categories
        ? categories?.map((category: ExtendedCategoryType) => {
            if (category.categories && category.categories.length) {
              return (
                <SubCatalogAccordion
                  category={category}
                  key={category.id}
                  navigateTo={navigateTo}
                />
              );
            }
            return (
              <SubCatalogItemTitle
                key={category.id}
                onClick={() =>
                  navigateTo({ catName: category.name, catId: category.id })
                }
              >
                {category.name}
              </SubCatalogItemTitle>
            );
          })
        : null}
    </StyledCatalogAccordion>
  );
};

export default CatalogAccordion;
