import styled, { css } from 'styled-components';
import { Box } from '@mui/material';
import { Input } from 'components/Inputs/styles';

import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import TextButton from 'components/Buttons/TextButton';

export const FormWrapper = styled(Box)`
  max-width: 556px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;

  & .StripeElement {
    width: 100%;
    height: 14px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    justify-content: center;
    width: 100%;
  }
`;

export const FormInput = styled(Input)<{
  half?: boolean;
}>`
  color: ${COLORS.primary};
  border: 1px solid ${COLORS.border};
  padding: 4px 14px;
  position: relative;

  ${({ half }) =>
    half &&
    css`
      width: calc(50% - 5px);

      @media (max-width: 895px) {
        width: 100%;
      }
    `}
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 100px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    gap: 50px;
    margin-bottom: 15px;
  }
`;

export const StyledButton = styled(TextButton).attrs((props: any) => ({
  fontStyle: {
    fontSize: window.innerWidth < BREAKPOINTS.s ? 10 : 12,
    fontFamily: FONTS.Helvetica.regular,
    color: COLORS.grey,
    textDecoration: props?.isActive ? 'underline' : 'none',
  },
}))<{
  isActive: boolean;
}>``;

export const CardsImage = styled.img`
  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 80px;
  }
`;
