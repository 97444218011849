import { CustomNavLink } from 'components';
import { useLoyaltyModals } from 'hooks/useModals';
import ROUTES from 'router/Routes';
import { COLORS } from 'styles';
import {
  LoyaltyRulesWrapper,
  StyledColoredButton,
  Title,
  ListItem,
  List,
  LoyaltyTermsWrapper,
  LoyaltyTermsText,
} from './styles';

const rules = [
  'Rewards are available to registered Loyalty members ONLY',
  'Points earned or redeemed do not apply to taxes or shipping',
  'Points are non-transferable',
  'Points are currently accumulated only',
  "While we'd love for you to be in our community forever, violations of our T&Cs can result in account termination",
  'Points can not be used to purchase items outside of the Empower Global platform, unless explicitly expressed or allowed',
  'Points will expire if there is no account activity after 1 year (365 calendar days). Terms subject to change.',
];

const LoyaltyRulesBlock = ({
  currentTierName,
  isLoyaltyMember,
}: {
  currentTierName: string;
  isLoyaltyMember: boolean;
}) => {
  const { showLoyaltyRegistrationModal } = useLoyaltyModals();

  return (
    <>
      <LoyaltyRulesWrapper>
        <Title>Things you should know</Title>
        <List>
          {rules.map((rule) => (
            <ListItem key={rule}>{rule}</ListItem>
          ))}
        </List>
      </LoyaltyRulesWrapper>
      <LoyaltyTermsWrapper>
        <LoyaltyTermsText>
          See full rewards Terms & Conditions{' '}
          <CustomNavLink
            to={ROUTES.TERMS_CONDITIONS}
            style={{ color: `${COLORS.white}` }}
          >
            here
          </CustomNavLink>
          .
        </LoyaltyTermsText>
        {!isLoyaltyMember && (
          <StyledColoredButton
            aria-label="join eg elite"
            onClick={showLoyaltyRegistrationModal}
          >
            join eg elite
          </StyledColoredButton>
        )}
      </LoyaltyTermsWrapper>
    </>
  );
};

export default LoyaltyRulesBlock;
