import { ReactNode, CSSProperties } from 'react';

import { IconButtonWrapper, IconButtonText } from './styles';

type ButtonType = {
  children: ReactNode;
  Icon: any;
  style?: CSSProperties;
  onClick?: () => void;
};

export const ButtonWithIcon = ({
  children,
  Icon,
  style,
  onClick,
}: ButtonType) => {
  return (
    <IconButtonWrapper type="button" style={style} onClick={onClick}>
      {Icon && <Icon />}
      <IconButtonText>{children}</IconButtonText>
    </IconButtonWrapper>
  );
};
