import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ColoredButton, Typography } from 'components';
import EmailForm from 'components/Forms/EmailForm/EmailForm';
import { EmailFormFieldsType } from 'types/AccountTypes';
import { emailFormSchema } from 'helpers/validationSchemas';
import { generateCSRFToken, sendResetEmail } from 'eg_SFCC_FE_core/axios/api';
import { COLORS } from 'styles';
import { ForgotPasswordContentWrapper } from './styles';

export const statusData: any = {
  invalid_email: 'Invalid email',
  email_sent_successfully: 'Email sent successfully. Check your email',
};

const ForgotPasswordContent = ({ closeModal }: { closeModal: () => void }) => {
  const [sendStatus, setSendStatus] = useState<string>('');
  const [isLoading, setLoader] = useState<boolean>(false);
  const methods = useForm<EmailFormFieldsType>({
    resolver: yupResolver(emailFormSchema),
    defaultValues: { email: '' },
  });

  const onSubmit: SubmitHandler<EmailFormFieldsType> = async (
    data: EmailFormFieldsType,
    e: any,
  ) => {
    setLoader(true);
    const { token } = await generateCSRFToken();
    const response = await sendResetEmail(data.email, token);
    setSendStatus(response.sendResult.status);
    setLoader(false);
    e.target.reset();
  };

  const handleError = (e: any, error: any) => {
    setSendStatus(error.message);
  };

  return (
    <ForgotPasswordContentWrapper>
      <Typography type="subtitle2">
        Enter the email address associated with your account.
      </Typography>
      <div style={{ paddingTop: 20, width: '100%' }}>
        <EmailForm
          onSubmit={onSubmit}
          onError={handleError}
          methods={methods}
        />
      </div>
      <div style={{ height: 40 }}>
        {sendStatus && (
          <Typography style={{ color: COLORS.accent }}>
            {statusData[sendStatus]}
          </Typography>
        )}
      </div>
      {sendStatus === 'email_sent_successfully' ? (
        <ColoredButton width="250px" onClick={closeModal} aria-label="close">
          Close
        </ColoredButton>
      ) : (
        <ColoredButton
          width="250px"
          isLoading={isLoading}
          form="email-form"
          aria-label="continue"
          type="submit"
        >
          continue
        </ColoredButton>
      )}
    </ForgotPasswordContentWrapper>
  );
};

export default ForgotPasswordContent;
