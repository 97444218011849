import styled from 'styled-components';

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;

  &.fade-enter {
    opacity: 0;
    z-index: 1;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.fade-exit {
    opacity: 1;
    z-index: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;
