import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import BasicSelect from './BasicSelect';
import {
  StyledMenuItem,
  StyledListItemText,
  SelectedAddressLabel,
} from '../styles';

const AddressSelect = ({
  addresses,
  currentMenuItem,
  setCurrentMenuItem,
}: {
  addresses: CustomerAddressType[];
  currentMenuItem: CustomerAddressType | null;
  setCurrentMenuItem: (address: CustomerAddressType) => Promise<void> | void;
}) => {
  const generateAddressText = (address: CustomerAddressType) => {
    if (!address) return '';

    return (
      <span>
        {address.address1}, {address.city}, {address.stateCode}{' '}
        {address.postalCode}
      </span>
    );
  };

  const handleClick = (_: any, address: CustomerAddressType) => {
    setCurrentMenuItem(address);
  };

  const renderLabel = () => (
    <SelectedAddressLabel>
      {currentMenuItem ? generateAddressText(currentMenuItem) : ''}
    </SelectedAddressLabel>
  );

  return (
    <BasicSelect
      id="savedCardsDropdown"
      renderLabel={renderLabel}
      optionValues={[]}
      variant="standard"
      formControlSx={{ width: '100%', borderBottom: '1px solid black' }}
    >
      {addresses.map((address: CustomerAddressType, index: number) => (
        <StyledMenuItem
          value={address.addressId}
          onClick={(event: any) => handleClick(event, address)}
          key={index}
        >
          <StyledListItemText primary={generateAddressText(address)}>
            {generateAddressText(address)}
          </StyledListItemText>
        </StyledMenuItem>
      ))}
    </BasicSelect>
  );
};

export default AddressSelect;
