import { ReactNode } from 'react';
import { PageWrapper, MobilePageTitle } from './styles';

const InfoPageWrapper = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <PageWrapper>
      <MobilePageTitle>{title}</MobilePageTitle>
      {children}
    </PageWrapper>
  );
};

export default InfoPageWrapper;
