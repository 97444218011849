import styled, { css } from 'styled-components';

import { Typography } from 'components';
import { BREAKPOINTS, COLORS } from 'styles';

export const BreadcrumbText = styled(Typography).attrs({
  type: 'subtitle2Helv',
})<{
  isCategoryActive: boolean;
}>`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }

  ${({ isCategoryActive }) =>
    isCategoryActive &&
    css`
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: ${COLORS.updatedAccentBackground};
      color: ${COLORS.updatedAccentBackground};
    `}

  &:hover {
    color: ${COLORS.accent};
    text-decoration-color: ${COLORS.accent};
    transition: 0.3s;
  }
`;
