import { Helmet } from 'react-helmet';

import { CalendarIcon, ClockIcon, HalfWBackground } from 'assets/svg';
import { CustomNavLink, Typography } from 'components';
import EventCard from 'components/EventCard/EventCard';
import { empowerEvents } from 'data/events';
import ROUTES from 'router/Routes';
import {
  ButtonsWrapper,
  CardsWrapper,
  EventBannerBlock,
  EventBannerSubtitle,
  EventBannerTime,
  EventBannerTitle,
  EventCardWrapper,
  EventStatusButton,
  EventTab,
  HeroBackgroundImage,
  StyledColoredButton,
} from './styles';

const eventData = {
  id: '1',
  title: 'French Balayage summer',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  day: 'June 2022',
  time: '8 PM EST',
  image: 'assets/jpg/events/events-1.jpg',
  isLive: true,
};

const PastEvents = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Past Events</title>
        <meta name="description" content="Past Events" />
      </Helmet>
      <HeroBackgroundImage
        imageUrl={require('../../assets/jpg/events/hero-events.jpg')}
      >
        <EventBannerBlock>
          <EventStatusButton
            aria-label="upcoming events"
            style={{
              color: 'black',
              backgroundColor: '#F0943E16',
            }}
          >
            upcoming
          </EventStatusButton>
          <EventBannerTitle>{eventData.title}</EventBannerTitle>
          <EventBannerSubtitle>{eventData.text}</EventBannerSubtitle>
          <EventBannerTime>
            <span>
              <CalendarIcon />
              <Typography type="subtitle2">{eventData.day}</Typography>
            </span>
            <span>
              <ClockIcon />
              <Typography type="subtitle2">{eventData.time}</Typography>
            </span>
          </EventBannerTime>
          <StyledColoredButton
            aria-label="add to calendar"
            style={{ position: 'absolute' }}
          >
            add to calendar
          </StyledColoredButton>
        </EventBannerBlock>
      </HeroBackgroundImage>

      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <HalfWBackground
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '900px',
            width: '1200px',
            zIndex: -1,
          }}
        />

        <ButtonsWrapper>
          <CustomNavLink to={ROUTES.EVENTS_CURRENT}>
            <EventTab>eg this week</EventTab>
          </CustomNavLink>
          <EventTab isActive="true">past events</EventTab>
        </ButtonsWrapper>

        <CardsWrapper>
          {empowerEvents.map((event) => (
            <EventCardWrapper key={event.id}>
              <EventCard event={event} />
            </EventCardWrapper>
          ))}
        </CardsWrapper>
      </div>
    </>
  );
};

export default PastEvents;
