import React, { useState } from 'react';
import ROUTES from 'router/Routes';
import { letters } from 'data/letters';
import { Grid } from '@mui/material';
import LettersMenu from '../../BrandsComponents/SearchBrands/LettersMenu';
import {
  StyledCategoryTextButton,
  StyledSubcategoryTextButton,
  StyledViewAllTextButton,
} from './styles';

const sortBrands = ({ brandsList }: { brandsList: Array<any> }) => {
  const columns = [
    {
      id: 'Featured Brands',
      brandIds: [
        'Book of Timothy',
        'Cool Creative',
        'Elo Vegan Lifestyle',
        'Fulaba',
        'Goodwinxxii',
      ],
      brands: [],
    },
    {
      id: 'Skin and Body Care',
      brandIds: [
        'Bronze Glory',
        'KLEVIJ PERSONAL CARE',
        'Kilali Cosmetics',
        'LANI.XO',
        'The Tsuri Company',
      ],
      brands: [],
    },
    {
      id: 'Beauty Brands',
      brandIds: [
        'glamazon beauty',
        'marie hunter beauty',
        'Coloured Raine Cosmetics',
        'pound cake',
        '2.4.1 COSMETICS',
      ],
      brands: [],
    },
    {
      id: 'Woman Owned Brands',
      brandIds: [
        'SED SKIN',
        'Perry Co',
        'ora ana',
        'Nuudii System',
        'roam loud',
      ],
      brands: [],
    },
    {
      id: 'HOME BRANDS',
      brandIds: [
        'Black Mint Home Decor',
        'Ozemi Beauty',
        'Georgie & Ophelia Luxury Goods LLC',
        'the stuyvesant',
        'whtart',
      ],
      brands: [],
    },
  ];

  columns.forEach((column) => {
    brandsList.forEach((brand) => {
      const brandIsIncluded = column.brandIds.find(
        (brandId) =>
          brandId?.toLowerCase() === brand.businessName.toLowerCase(),
      );

      if (brandIsIncluded) {
        // @ts-ignore
        column.brands.push(brand);
      }
    });
  });

  return columns;
};

const BrandsNavigation = ({
  brandsList,
  onClickCallback,
}: {
  brandsList: Array<any>;
  onClickCallback: Function;
}) => {
  const [currentMenuItem, setCurrentMenuItem] = useState<string>('');
  const brandColumns = sortBrands({ brandsList });

  return (
    <Grid container direction="row">
      <Grid item style={{ marginBottom: 40, width: '100%' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <StyledViewAllTextButton
              label="view all"
              onClick={() => onClickCallback(ROUTES.BRANDS)}
            />
          </Grid>
          <Grid item>
            <LettersMenu
              isInline
              letters={letters}
              onClickCallback={onClickCallback}
              brandsList={brandsList}
              currentMenuItem={currentMenuItem}
              setCurrentMenuItem={setCurrentMenuItem}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={0}>
          {brandColumns?.map((brandColumn: any) => {
            return (
              <Grid item xs="auto" style={{ marginRight: '69px' }}>
                <StyledCategoryTextButton
                  label={brandColumn.id}
                  onClick={() => {}}
                />

                {brandColumn.brands?.map((brand: any) => {
                  return (
                    <StyledSubcategoryTextButton
                      label={brand.businessName}
                      onClick={() =>
                        onClickCallback(`${ROUTES.BRANDS}/${brand.id}`)
                      }
                    />
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BrandsNavigation;
