/* eslint-disable @typescript-eslint/no-unused-vars */
import { forOwn, isEqual, mapValues, method, pick, toLower } from 'lodash';
import { encode as base64encode } from 'base64-arraybuffer';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { SocialAuthType } from 'types/AccountTypes';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { GOOGLE_REDIRECT_URI, LOCAL_STORAGE_KEYS } from 'appConstants';
import { CHANNEL_ID, COOKIES_KEYS, TOKEN_EXP } from './apiConstants';
import endpoints from './endpoints';

export const generateCodeVerifier = () =>
  Array(128)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~')
    .map(
      (char: string) =>
        char[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              char.length,
          )
        ],
    )
    .join('');

export const generateCodeChallenge = async (codeVerifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = base64encode(digest);

  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const verifier = generateCodeVerifier();
export const getGeneratedCodeChallenge = () => generateCodeChallenge(verifier);
export const getUniqueCodeChallenge = () => {
  const uniqueVerifier = generateCodeVerifier();

  return generateCodeChallenge(uniqueVerifier);
};

export const adaptParamsToURLEncoded = (params: {}) => {
  const URLSearchParamsInstance = new URLSearchParams();

  forOwn(params, (value, key) => {
    if (value) URLSearchParamsInstance.append(key, value);
  });

  return URLSearchParamsInstance;
};

export const updateLocalTokens = (data: any) => {
  const {
    access_token: accessToken,
    expires_in: expiresIn,
    refresh_token: refreshToken,
    token_type: tokenType,
    customer_id: customerId,
    enc_user_id: encUserId,
    usid,
  } = data;

  Cookies.set(COOKIES_KEYS.accessToken, accessToken, {
    expires: TOKEN_EXP.access,
  });
  Cookies.set(COOKIES_KEYS.refreshToken, refreshToken, {
    expires: TOKEN_EXP.refresh,
  });
  Cookies.set(COOKIES_KEYS.tokenType, tokenType.toLowerCase(), {
    expires: TOKEN_EXP.access,
  });
  localStorage.setItem(LOCAL_STORAGE_KEYS.usid, usid);
};

export const isUserEligableToRegister = (
  birthday: string | undefined,
): boolean => {
  const isOlderThanRequiredAge = dayjs().diff(birthday, 'year') > 16;
  const hasAgeRestriction = !!Cookies.get(COOKIES_KEYS.ageRestriction);

  if (hasAgeRestriction) return false;

  if (!hasAgeRestriction && !isOlderThanRequiredAge) {
    Cookies.set(COOKIES_KEYS.ageRestriction, 'true', { expires: 1 });
    return false;
  }

  return true;
};

export const formatTokenType = (token: string) => {
  return toLower(token) === 'bearer' ? 'Bearer' : null;
};

export const adaptParamsToFormData = (params: {}) => {
  const formData = new FormData();

  forOwn(params, (value, key) => {
    if (value) {
      formData.append(key, value);
    }
  });

  return formData;
};

export const getRequestUriForGoogleLogin = async (
  generatedVerifier: string,
  hint: SocialAuthType | 'guest',
) => {
  const codeChallenge = await generateCodeChallenge(generatedVerifier);

  const searchParams = new URLSearchParams();
  searchParams.append(
    'client_id',
    process.env.REACT_APP_PUBLIC_CLIENT_ID || '',
  );
  searchParams.append('redirect_uri', GOOGLE_REDIRECT_URI);
  searchParams.append('hint', hint);
  searchParams.append('response_type', 'code');
  searchParams.append('code_challenge', codeChallenge);
  searchParams.append('channel_id', CHANNEL_ID);

  return `${process.env.REACT_APP_BASE_URL}${
    endpoints.shopper.publicGuestLoginCode
  }?${searchParams.toString()}`;
};

export const checkIsAddressExists = (
  addresses: CustomerAddressType[],
  customerAddress: CustomerAddressType,
): boolean => {
  const props = ['address1', 'countryCode', 'stateCode', 'city', 'postalCode'];
  const customerAddressValues = mapValues(
    pick(customerAddress, props),
    method('toLowerCase'),
  );

  return addresses?.some((address) => {
    const addressValues = mapValues(
      pick(address, props),
      method('toLowerCase'),
    );
    return isEqual(addressValues, customerAddressValues);
  });
};
