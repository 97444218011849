import { range } from 'lodash';

const Separator = (props: any) => {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${props.turns + 0.1}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
};

const RadialSeparators = (props: any) => {
  const turns = 1 / props.count;
  return (
    <>
      {range(props.count).map((index) => (
        <Separator key={index} turns={index * turns} style={props.style} />
      ))}
    </>
  );
};

export default RadialSeparators;
