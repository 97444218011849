import { Typography } from 'components';
import styled from 'styled-components';
import { COLORS } from 'styles';

export const Text = styled(Typography).attrs({ type: 'subtitle2' })`
  color: ${COLORS.primary};
  text-transform: capitalize;
  text-align: center;
  margin: 27px 0 35px;
`;
