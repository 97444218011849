import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'components';
import { useInfoModal } from 'hooks/useModals';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import ROUTES from 'router/Routes';

const AcceptRedirectPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const openInfoModal = useInfoModal();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const navigate = useNavigate();

  const navigateToHomePage = useCallback(() => navigate(ROUTES.HOME), []);

  const loginViaGoogle = async () => {
    let message;
    setIsLoading(true);

    if (code) {
      // @ts-ignore
      const { payload, error } = await dispatch(
        AsyncThunks.loginCustomerViaGoogle(code),
      );

      if (!payload) {
        message = 'Something went wrong. Try again later';
      } else if (error) {
        message = payload.message || payload.detail;
      } else {
        message = 'You have successfully authorized';
      }
    } else {
      message = 'Provided code is not valid';
    }

    setIsLoading(false);
    openInfoModal(message, navigateToHomePage);
  };

  useEffect(() => {
    loginViaGoogle();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return <span />;
};

export default AcceptRedirectPage;
