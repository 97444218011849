import AnimatedLink from 'components/Links/AnimatedLink';
import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const HoverContent = styled.div`
  width: 100%;
  height: 36%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  padding: 20px;
  position: absolute;
  bottom: 0;
  transition: all 0.5s;
`;

export const Title = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 24px;
  text-transform: capitalize;
  text-align: center;
  transition: 0.5s;

  @media (max-width: 600px) {
    text-align: start;
  }
`;

export const Subtitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 16px;
  text-align: center;
  transition: 0.5s;
  text-transform: capitalize;

  @media (max-width: 600px) {
    font-size: 14px;
    text-align: start;
  }
`;

export const LiveTitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.Bison.bold};
  font-size: 20px;
  letter-spacing: 0.26em;
  text-transform: uppercase;
  position: absolute;
  top: 4%;
  right: 4%;
  z-index: 6;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const StyledAnimatedLink = styled(AnimatedLink)`
  font-size: 30px;
  opacity: 0;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.5s;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const BackgroundImage = styled.div<{
  imageUrl: string;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease;
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 520px;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 460px;
  }

  &:hover {
    ${HoverContent} {
      height: 100%;
      z-index: 5;
    }

    ${StyledAnimatedLink} {
      opacity: 1;
    }
  }
`;

export const PastEventCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &:before {
    content: 'No image';
    position: absolute;
    color: ${COLORS.searchInputBorder};
    top: 50%;
    left: 50%;
    margin-left: -35px;
    z-index: -1;
  }

  &:hover {
    ${HoverContent} {
      z-index: 5;
    }

    ${BackgroundImage} {
      transform: scale(1.1);
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 425px;
  overflow: hidden;
  position: relative;
`;

export const Bottom = styled.div`
  background-color: transparent;
  transition: 0.5s ease;
  max-height: 80px;
  height: 100%;
  position: relative;
`;

export const BottomEventHost = styled(Typography).attrs({
  type: 'subtitle1',
})`
  margin: 10px 0;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  bottom: 35px;
  width: 85%;
`;

export const BottomEventTitle = styled(Typography).attrs({
  type: 'subtitle3',
})`
  position: absolute;
  font-size: 20px;
  white-space: nowrap;
  bottom: 15px;
`;

export const BottomIconsWrapper = styled.div`
  position: absolute;
  top: 20%;
  right: 0;
  display: flex;
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`;
