import { CouponItemType } from 'types/CheckoutTypes';
import { Code, Cross } from './styles';

const PromoCode = ({
  coupon,
  handleCouponDelete,
}: {
  coupon: CouponItemType;
  handleCouponDelete: ((id: string) => void) | null;
}) => {
  return (
    <Code style={{ paddingRight: handleCouponDelete ? 20 : 5 }}>
      {coupon.code}
      {handleCouponDelete ? (
        <Cross onClick={() => handleCouponDelete(coupon.couponItemId)} />
      ) : null}
    </Code>
  );
};

export default PromoCode;
