import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ColoredButton, Typography } from 'components';
import AddressForm from 'components/Forms/AddressForm/AddressForm';
import { addressFormSchema } from 'helpers/validationSchemas';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { getCustomerError } from 'eg_SFCC_FE_core/store/selectors';
import { customerActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerSlice';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { SuggestedAddressBox, ButtonsWrapper } from './styles';

const AccountAddressForm = ({
  address,
  handleAddress,
  handleDelete,
  isLoading,
  suggestedAddress,
  setSuggestedAddress,
}: {
  address?: CustomerAddressType;
  handleAddress: (address: CustomerAddressType) => void;
  handleDelete?: (id: string) => void;
  isLoading: boolean;
  suggestedAddress?: AddressFormFieldsType;
  setSuggestedAddress: Dispatch<
    SetStateAction<AddressFormFieldsType | undefined>
  >;
}) => {
  const dispatch = useAppDispatch();
  const addressError = useSelector(getCustomerError);
  const [addressValues, setAddressValues] = useState<AddressFormFieldsType>({
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    countryCode: address?.countryCode || '',
    stateCode: address?.stateCode || '',
    city: address?.city || '',
    phone: address?.phone || '',
    postalCode: address?.postalCode || '',
  });

  useEffect(() => {
    if (addressError) {
      dispatch(customerActions.clearError());
    }

    return () => {
      dispatch(customerActions.clearError());
    };
  }, []);

  const methods = useForm<AddressFormFieldsType>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: addressValues,
  });

  const onSubmit: SubmitHandler<AddressFormFieldsType> = (
    data: AddressFormFieldsType,
    e: any,
  ) => {
    if (data) {
      setAddressValues(data);
      handleAddress({
        addressId: address?.addressId || uuid(),
        ...data,
      });
    }

    e.target.reset();
  };

  const onError = () => {};

  const handleSubmitSuggestedAddress = () => {
    methods.reset({ ...addressValues, ...suggestedAddress });
    dispatch(customerActions.clearError());
    setSuggestedAddress(undefined);
  };

  return (
    <div style={{ maxWidth: 560 }}>
      <AddressForm
        onSubmit={onSubmit}
        onError={onError}
        methods={methods}
        addressError={addressError}
      />

      {suggestedAddress && (
        <SuggestedAddressBox onClick={handleSubmitSuggestedAddress}>
          <Typography tag="h3" style={{ marginBottom: 10 }}>
            Use suggested address
          </Typography>
          {suggestedAddress.address1}
          {suggestedAddress.address2 ? (
            <>
              <br />
              {suggestedAddress.address2}
            </>
          ) : null}
          <br />
          {suggestedAddress.city}, {suggestedAddress.stateCode}{' '}
          {suggestedAddress.postalCode} <br />
        </SuggestedAddressBox>
      )}

      <ButtonsWrapper>
        <ColoredButton
          width="214px"
          form="address-form"
          type="submit"
          aria-label="save"
          isLoading={isLoading}
        >
          {address ? 'update address' : 'save address'}
        </ColoredButton>
        {address && !!handleDelete && (
          <ColoredButton
            inverted
            width="214px"
            aria-label="cancel"
            onClick={() => handleDelete(address.addressId)}
          >
            delete address
          </ColoredButton>
        )}
      </ButtonsWrapper>
    </div>
  );
};

export default AccountAddressForm;
