import ROUTES from 'router/Routes';
import {
  ProductBrandCardWrapper,
  BrandCardText,
  BrandName,
  BrandImage,
  BrandCardTitle,
  BrandTextWrapper,
  StyledBrandLink,
} from './styles';

const ProductBrandCard = ({
  name,
  businessOwnerImage,
  businessDescription,
  brand: { brandId, isBrandDisabled },
}: {
  name: string;
  businessOwnerImage?: string;
  businessDescription?: string;
  brand: { brandId: string; isBrandDisabled: boolean };
}) => {
  const navigateTo = isBrandDisabled
    ? `${ROUTES.PRODUCTS}/shop/seller?c_marketplacerSellerName=${name}`
    : `${ROUTES.BRANDS}/${brandId}`;

  const MAX_DESCRIPTION_LENGTH = 80;

  return (
    <ProductBrandCardWrapper>
      {businessOwnerImage ? (
        <BrandImage src={businessOwnerImage} alt="business-owner-image" />
      ) : null}

      <BrandTextWrapper>
        <div>
          <BrandCardTitle>meet the founder</BrandCardTitle>
          {name ? <BrandName>{name}</BrandName> : null}
        </div>
        <BrandCardText>
          {businessDescription &&
          businessDescription.length > MAX_DESCRIPTION_LENGTH
            ? `${businessDescription.substring(0, MAX_DESCRIPTION_LENGTH)}...`
            : businessDescription || ''}
        </BrandCardText>
        <StyledBrandLink to={navigateTo}>More from the Founder</StyledBrandLink>
      </BrandTextWrapper>
    </ProductBrandCardWrapper>
  );
};

export default ProductBrandCard;
