import { Loader } from 'components';
import { CSSProperties } from 'styled-components';
import { TextButtonText, TextButtonWrapper } from './styles';

const TextButton = ({
  label,
  isLoading,
  fontStyle,
  onClick,
  style,
  id,
  type = 'button',
}: {
  label: string;
  isLoading?: boolean;
  fontStyle?: any;
  onClick: () => void;
  style?: CSSProperties;
  id?: string;
  type?: 'button' | 'submit';
}) => {
  return (
    <TextButtonWrapper id={id} type={type} onClick={onClick} style={style}>
      {isLoading ? (
        <Loader />
      ) : (
        <TextButtonText
          style={{
            ...fontStyle,
            transition: '0.3s',
          }}
        >
          {label}
        </TextButtonText>
      )}
    </TextButtonWrapper>
  );
};

export default TextButton;
