import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup';

import { ColoredButton } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
import { passwordFormSchema } from 'helpers/validationSchemas';
import { EnterPasswordFormFieldsType } from 'types/AccountTypes';
import { FormWrapper } from '../styles';
import ErrorTooltip from '../ErrorTooltip';

const EnterPasswordForm = ({
  isLoading,
  setPassword,
}: {
  isLoading: boolean;
  setPassword: Dispatch<SetStateAction<string>>;
}) => {
  const [showPass, setShowPass] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EnterPasswordFormFieldsType>({
    resolver: yupResolver(passwordFormSchema),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = (data: EnterPasswordFormFieldsType) => {
    setPassword(data.password);
  };

  return (
    <FormWrapper id="add-password-form" onSubmit={handleSubmit(onSubmit)}>
      <FormInputController
        control={control}
        type={showPass ? 'text' : 'password'}
        name="password"
        label="Password"
        error={!!errors.password}
        errorMessage={errors?.password?.message}
        InputProps={{
          endAdornment: errors?.password?.message ? (
            <ErrorTooltip
              title={errors?.password?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPass(!showPass)}
              >
                {showPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPass(!showPass)}
              >
                {showPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ColoredButton
        aria-label="save changes"
        width="250px"
        isLoading={isLoading}
        style={{ margin: '20px auto 0' }}
      >
        save changes
      </ColoredButton>
    </FormWrapper>
  );
};

export default EnterPasswordForm;
