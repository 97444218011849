import { useSelector } from 'react-redux';
import { Backdrop, Fade, Box } from '@mui/material';

import { getModals } from 'eg_SFCC_FE_core/store/selectors';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { StyledModal } from './styles';

const ANIMATION_DURATION = 500;

const CustomModal = () => {
  const modals = useSelector(getModals);
  const dispatch = useAppDispatch();

  return (
    <div>
      {modals.map(({ isOpen, modal }) => {
        const { id, modalContent, offsetY, boxStyle, actionOnClose } = modal;
        const closeModal = () => {
          dispatch(modalActions.hideModal(id));
          actionOnClose && actionOnClose();
        };
        const top = offsetY || 0;

        return (
          <StyledModal
            key={id}
            open={isOpen}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: ANIMATION_DURATION, sx: { top } }}
            disableAutoFocus
            top={top}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Fade in={isOpen}>
              <Box sx={boxStyle}>{modalContent || null}</Box>
            </Fade>
          </StyledModal>
        );
      })}
    </div>
  );
};

export default CustomModal;
