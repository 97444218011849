import { createSlice, current } from '@reduxjs/toolkit';
import { differenceBy, filter, sumBy } from 'lodash';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { PaymentInstrumentDataType, StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { BasketProductItemType } from 'types/ProductsTypes';
import { CouponItemType } from 'types/CheckoutTypes';

export const BASKET_SLICE_NAME = 'basket';

const getProductTotal = (items: BasketProductItemType[] | undefined) => {
  return items ? Number(sumBy(items, 'price').toFixed(2)) : 0;
};

type BasketStateType = StateType & {
  totalQuantity: number;
  productTotal: number;
  discountTotal: number;
  subtotal: number;
  shippingFee: number;
  taxAmount: number;
  estimatedTotal: number;
  paymentMethods: any[];
  shippingMethods: any;
  productItems: BasketProductItemType[];
  paymentInProgressData: null | {
    orderNo: string;
    paymentInstrumentId: string;
    paymentInstrumentData: PaymentInstrumentDataType;
  };
  coupons: CouponItemType[];
  couponError: string | null;
};

const initialState: BasketStateType = {
  pending: false,
  result: null,
  error: null,
  productItems: [],
  totalQuantity: 0,
  productTotal: 0,
  discountTotal: 0,
  subtotal: 0,
  shippingFee: 0,
  taxAmount: 0,
  estimatedTotal: 0,
  paymentMethods: [],
  shippingMethods: null,
  paymentInProgressData: null,
  coupons: [],
  couponError: null,
};

const onBasketFulfilled = (
  state: BasketStateType,
  action: { payload: any },
) => {
  state.result = {
    ...state.result,
    ...action.payload,
  };
  state.pending = false;
  state.error = null;

  if (action.payload) {
    const productTotal = getProductTotal(action.payload.productItems);
    state.productItems = action.payload.productItems || [];
    state.productTotal = productTotal;
    state.discountTotal = productTotal - action.payload.productTotal;
    state.subtotal = action.payload.productTotal;
    state.shippingFee = action.payload.shippingTotal;
    state.taxAmount = action.payload.taxTotal;
    state.estimatedTotal = action.payload.orderTotal;
    state.totalQuantity = action.payload.productItems
      ? sumBy(action.payload.productItems, 'quantity')
      : 0;
    state.coupons = action.payload.couponItems || [];
  }
};

const onBasketFulfilledWithoutProductsChange = (
  state: BasketStateType,
  action: { payload: any },
) => {
  if (action.payload) {
    if (action.payload.productItems) {
      state.result = {
        ...state.result,
        ...action.payload,
        productItems: state.result.productItems,
      };
    } else {
      state.result = {
        ...state.result,
        ...action.payload,
      };
    }
    const productTotal = getProductTotal(action.payload.productItems);
    state.productTotal = productTotal;
    state.discountTotal = productTotal - action.payload.productTotal;
    state.subtotal = action.payload.productTotal;
    state.shippingFee = action.payload.shippingTotal;
    state.taxAmount = action.payload.taxTotal;
    state.estimatedTotal = action.payload.orderTotal;
    state.totalQuantity = action.payload.productItems
      ? sumBy(action.payload.productItems, 'quantity')
      : 0;
    state.coupons = action.payload.couponItems || [];
  }

  state.pending = false;
  state.error = null;
};

const onCouponActionsFulfilled = (
  state: BasketStateType,
  action: { payload: any },
) => {
  onBasketFulfilledWithoutProductsChange(state, action);

  state.coupons = action.payload.couponItems || [];
  state.couponError = null;
};

const basketSlice = createSlice({
  name: BASKET_SLICE_NAME,
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    // used in listeners for adding image, variation values
    addUpdatedBasket: (state, action) => {
      state.result = {
        ...state.result,
        ...action.payload,
      };
      state.productItems = action.payload.productItems;
    },
    clearError: (state) => {
      state.error = null;
      state.couponError = null;
    },
    clearShippingAddressInBasketShipment: (state) => {
      state.result = {
        ...state.result,
        shipments: [
          {
            ...state.result.shipments[0],
            shippingAddress: null,
          },
        ],
      };
    },
    updatePaymentInProgressData: (state, action) => {
      state.paymentInProgressData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getBaskets.pending, onPending);
    builder.addCase(AsyncThunks.getBaskets.fulfilled, onBasketFulfilled);
    builder.addCase(AsyncThunks.getBaskets.rejected, onError);

    builder.addCase(AsyncThunks.createBasket.pending, onPending);
    builder.addCase(AsyncThunks.createBasket.fulfilled, onBasketFulfilled);
    builder.addCase(AsyncThunks.createBasket.rejected, onError);

    builder.addCase(AsyncThunks.updateBasket.pending, onPending);
    builder.addCase(AsyncThunks.updateBasket.fulfilled, onBasketFulfilled);
    builder.addCase(AsyncThunks.updateBasket.rejected, onError);

    builder.addCase(AsyncThunks.getOrCreateCustomerBaskets.pending, onPending);
    builder.addCase(
      AsyncThunks.getOrCreateCustomerBaskets.fulfilled,
      onBasketFulfilled,
    );
    builder.addCase(AsyncThunks.getOrCreateCustomerBaskets.rejected, onError);

    builder.addCase(AsyncThunks.addItemToBasket.pending, onPending);
    builder.addCase(AsyncThunks.addItemToBasket.fulfilled, onBasketFulfilled);
    builder.addCase(AsyncThunks.addItemToBasket.rejected, onError);

    builder.addCase(AsyncThunks.updateItemInBasket.pending, onPending);
    builder.addCase(
      AsyncThunks.updateItemInBasket.fulfilled,
      onBasketFulfilled,
    );
    builder.addCase(AsyncThunks.updateItemInBasket.rejected, onError);

    builder.addCase(AsyncThunks.removeItemFromBasket.pending, onPending);
    builder.addCase(
      AsyncThunks.removeItemFromBasket.fulfilled,
      (state: BasketStateType, action: { payload: any }) => {
        if (!action.payload) {
          return;
        }

        const productTotal = getProductTotal(action.payload.productItems);
        state.productTotal = productTotal;
        state.discountTotal = productTotal - action.payload.productTotal;
        state.subtotal = action.payload.productTotal;
        state.shippingFee = action.payload.shippingTotal;
        state.taxAmount = action.payload.taxTotal;
        state.estimatedTotal = action.payload.orderTotal;
        state.totalQuantity = action.payload.productItems
          ? sumBy(action.payload.productItems, 'quantity')
          : 0;
        const prevProductItems = current(state.result.productItems);
        const updatedProductItems = action.payload.productItems;

        if (updatedProductItems) {
          const removedItem = differenceBy(
            prevProductItems,
            updatedProductItems,
            'itemId',
          )[0];

          const filteredProductItems = filter(prevProductItems, (item) => {
            // @ts-ignore
            return item?.itemId !== removedItem?.itemId;
          });

          state.result = {
            ...state.result,
            ...action.payload,
            productItems: filteredProductItems,
          };
          state.productItems = filteredProductItems;
        } else {
          state.result = {
            ...state.result,
            ...action.payload,
          };
        }

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.removeItemFromBasket.rejected, onError);

    builder.addCase(AsyncThunks.getPaymentMethods.pending, onPending);
    builder.addCase(
      AsyncThunks.getPaymentMethods.fulfilled,
      (state, action) => {
        state.pending = false;
        state.paymentMethods = action.payload;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getPaymentMethods.rejected, onError);

    builder.addCase(AsyncThunks.getShippingMethods.pending, onPending);
    builder.addCase(
      AsyncThunks.getShippingMethods.fulfilled,
      (state, action) => {
        state.pending = false;
        state.shippingMethods = action.payload;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getShippingMethods.rejected, onError);

    builder.addCase(
      AsyncThunks.addShippingMethodToBasketShipment.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.addShippingMethodToBasketShipment.fulfilled,
      onBasketFulfilledWithoutProductsChange,
    );
    builder.addCase(
      AsyncThunks.addShippingMethodToBasketShipment.rejected,
      onError,
    );

    builder.addCase(AsyncThunks.addBillingAddressToBasket.pending, onPending);
    builder.addCase(
      AsyncThunks.addBillingAddressToBasket.fulfilled,
      onBasketFulfilledWithoutProductsChange,
    );
    builder.addCase(AsyncThunks.addBillingAddressToBasket.rejected, onError);

    builder.addCase(
      AsyncThunks.addShippingAddressToBasketShipment.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.addShippingAddressToBasketShipment.fulfilled,
      onBasketFulfilledWithoutProductsChange,
    );
    builder.addCase(
      AsyncThunks.addShippingAddressToBasketShipment.rejected,
      onError,
    );

    // the payment instrument isn't saved in state
    // add save in fulfilled case when needed
    builder.addCase(AsyncThunks.addPaymentInstrument.pending, onPending);
    builder.addCase(AsyncThunks.addPaymentInstrument.fulfilled, (state) => {
      state.pending = false;
      state.error = null;
    });
    builder.addCase(AsyncThunks.addPaymentInstrument.rejected, onError);

    // basket coupons
    builder.addCase(AsyncThunks.addCoupon.pending, (state) => {
      state.pending = true;
      state.couponError = null;
    });
    builder.addCase(AsyncThunks.addCoupon.fulfilled, onCouponActionsFulfilled);
    builder.addCase(AsyncThunks.addCoupon.rejected, (state, action: any) => {
      state.pending = false;
      state.couponError = action.payload.title;
    });

    builder.addCase(AsyncThunks.removeCoupon.pending, (state) => {
      state.pending = true;
      state.couponError = null;
    });
    builder.addCase(
      AsyncThunks.removeCoupon.fulfilled,
      onCouponActionsFulfilled,
    );
    builder.addCase(AsyncThunks.removeCoupon.rejected, (state, action: any) => {
      state.pending = false;
      state.couponError = action.payload.title;
    });
  },
});

export const basketActions = basketSlice.actions;
export const basketReducer = basketSlice.reducer;
