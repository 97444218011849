import { Paragraph, UppercaseTitle } from '../styles';

const AcceptingTerms = () => (
  <>
    <Paragraph>
      Welcome to Empower Global, a socially-immersive online marketplace focused
      on growing Black-owned brands. We’ve created a one-of-a-kind interactive
      and communal shopping experience fueled by Black entrepreneurs.
    </Paragraph>
    <Paragraph>
      We are building an economy obsessed with providing resources to Black
      founders looking to generate greater global scale for their businesses.
      Before you dive in with us, let’s run through some housekeeping.
    </Paragraph>
    <UppercaseTitle>1. Accepting These Terms</UppercaseTitle>
    <Paragraph>
      This document and the other documents that we reference below make up how
      we operate, or what we officially call our Terms of Use (“Terms” for
      short).
    </Paragraph>
    <Paragraph>
      These Terms are a legally binding contract between you and CE Marketplace,
      LLC, dba Empower Global. We’ll just refer to Empower Global and all of its
      affiliates collectively as “Empower Global.”
    </Paragraph>
    <Paragraph>
      Please note that Section 11. Disputes with Empower Global contain an
      arbitration clause and class action waiver. By agreeing to the Terms, you
      agree to resolve all disputes through binding individual arbitration,
      which means that you waive any right to have those disputes decided by a
      judge or jury, and that you waive your right to participate in class
      actions, class arbitrations, or representative actions.
    </Paragraph>
    <Paragraph>
      The Terms lay out your rights and responsibilities when you use
      EmpowerGlobal.shop, and the other services provided by Empower Global
      (we’ll refer to all of these collectively as our “Services”), so please
      read them carefully. By using any of our Services (or even simply browsing
      our website), you’re agreeing to the Terms. If you don’t agree with the
      Terms, feel free to stop reading now. If you do agree, read on!
    </Paragraph>
  </>
);

export default AcceptingTerms;
