import { CSSProperties, Dispatch, SetStateAction } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';

import { DoubleArrowRightIcon, PaginationArrowLeftIcon } from 'assets/svg';
import {
  Wrapper,
  Rotated180DoubleIcon,
  PaginationArrowRightIcon,
} from './styles';

const BasicPagination = ({
  pageSize,
  totalCount,
  setCurrentPage,
  currentPage,
  size,
  style,
  showFirstButton,
  showLastButton,
}: {
  pageSize: number;
  totalCount: number;
  setCurrentPage: Dispatch<SetStateAction<number>> | ((page: number) => void);
  currentPage: number;
  size?: 'large' | 'small';
  style?: CSSProperties;
  showFirstButton?: boolean;
  showLastButton?: boolean;
}) => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  return (
    <Wrapper style={style}>
      <Stack spacing={2}>
        <Pagination
          count={totalPageCount}
          size={size}
          page={currentPage}
          onChange={(_, page: number) => setCurrentPage(page)}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: PaginationArrowLeftIcon,
                next: PaginationArrowRightIcon,
                last: DoubleArrowRightIcon,
                first: Rotated180DoubleIcon,
              }}
              {...item}
            />
          )}
        />
      </Stack>
    </Wrapper>
  );
};

export default BasicPagination;
