import { useState } from 'react';

import { Typography } from 'components';
import BasicSelect from './BasicSelect';
import { StyledMenuItem, StyledListItemText } from '../styles';

const ReviewsDropDown = ({
  label,
  items,
}: {
  label: string;
  items: string[];
}) => {
  const [optionValues, setValue] = useState<string[]>([]);

  const handleClick = (_: any, value: string) => {
    setValue([value]);
  };

  const renderLabel = () => (
    <Typography type="body" style={{ opacity: 0.7, lineHeight: 'normal' }}>
      {optionValues[0] || label}
    </Typography>
  );

  return (
    <BasicSelect
      renderLabel={renderLabel}
      optionValues={optionValues}
      variant="outlined"
    >
      {items.map((item: string) => (
        <StyledMenuItem
          value={item}
          onClick={(event: any) => handleClick(event, item)}
          key={item}
        >
          <StyledListItemText primary={item}>{item}</StyledListItemText>
        </StyledMenuItem>
      ))}
    </BasicSelect>
  );
};

export default ReviewsDropDown;
