import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormInputController from 'components/Inputs/FormInputController';
import { productReturnFormSchema } from 'helpers/validationSchemas';
import { ReturnCommentFormFieldType } from 'types/OrdersTypes';
import { COLORS } from 'styles';
import { FormWrapper, Title } from './styles';

const ReturnCommentForm = ({
  onSubmit,
  disabled,
}: {
  onSubmit: (data: string, e: any) => void;
  disabled?: boolean;
}) => {
  const {
    control,
    formState: { errors },
  } = useForm<ReturnCommentFormFieldType>({
    resolver: yupResolver(productReturnFormSchema),
    defaultValues: { comment: '' },
  });

  return (
    <FormWrapper
      component="form"
      id="return-comment-form"
      style={{ maxWidth: 720, margin: '20px 0 0 0' }}
      onSubmit={(e) => {
        e.preventDefault();
        e.target &&
          // @ts-ignore
          e.target?.textContent &&
          // @ts-ignore
          onSubmit(e.target.textContent.substring(8), e);
      }}
    >
      <Title>Comments</Title>
      <FormInputController
        control={control}
        name="comment"
        label=""
        error={!!errors.comment}
        errorMessage={
          errors?.comment ? errors.comment.message : 'Incorrect or empty entry'
        }
        rows={4}
        disabled={disabled}
        sxStyle={{
          backgroundColor: disabled ? COLORS.background : COLORS.white,
          '& .Mui-disabled fieldset': {
            border: 'none',
          },
        }}
      />
    </FormWrapper>
  );
};

export default ReturnCommentForm;
