import { Dispatch, SetStateAction } from 'react';
import BasicSelect from './BasicSelect';
import { StyledMenuItem, StyledListItemText } from '../styles';

const PageSelect = ({
  currentPage,
  setCurrentPage,
  pagesNumber,
}: {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>> | ((page: number) => void);
  pagesNumber: number;
}) => {
  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <BasicSelect
      renderLabel={() => `page ${currentPage} of ${pagesNumber}`}
      optionValues={[]}
      variant="outlined"
      formControlSx={{ width: '100%' }}
    >
      {Array.from({ length: pagesNumber }).map((_, index) => {
        const page = index + 1;

        return (
          <StyledMenuItem
            value={page}
            onClick={() => handleClick(page)}
            key={page}
          >
            <StyledListItemText primary={page}>{page}</StyledListItemText>
          </StyledMenuItem>
        );
      })}
    </BasicSelect>
  );
};

export default PageSelect;
