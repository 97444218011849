import styled, { css } from 'styled-components';

import { ColoredButton, CustomNavLink, Typography } from 'components';
import { HalfWBackground } from 'assets/svg';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
  }
`;

export const RightBlockWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 75px 80px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 0;
    padding-bottom: 29px;
  }

  &.fade-enter {
    opacity: 0;
    z-index: 1;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.fade-exit {
    opacity: 1;
    z-index: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const BlockTitle = styled.h1`
  font-size: 24px;
  line-height: 29px;
  font-family: ${FONTS.Helvetica.bold};
  color: ${COLORS.primary};
  text-transform: uppercase;
  margin-bottom: 25px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
    font-size: 10px;
    line-height: 12px;
    font-family: ${FONTS.Helvetica.medium};
    margin-bottom: 20px;
  }
`;

export const BlockSubtitle = styled.h3`
  font-size: 14px;
  line-height: 17px;
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.black};
  margin-bottom: 14px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const MiniCircularProgressWrapper = styled.div`
  max-width: 180px;

  @media (max-width: 1000px) {
    max-width: 140px;
  }
`;

export const TierCardWrapper = styled.div<{
  accent?: boolean;
}>`
  max-width: 400px;
  flex: 1;
  padding: 30px 35px;
  height: fit-content;

  ${({ accent }) =>
    accent &&
    css`
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    `}
`;

export const UserLoyaltyInfoTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0 20px 30px;

  @media (max-width: 1350px) {
    flex-direction: column;
    padding: 50px 15px 20px;
  }
`;

export const UserLoyaltyTiersWrapper = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

export const LoyaltyPoints = styled(Typography)`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 66px;
  margin: -80px 0 -10px;
`;

export const TierName = styled(Typography)`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 16px;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const OrderTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
`;

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TierInfoWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 50px 40px;
`;

export const StyledLink = styled(CustomNavLink)`
  color: ${COLORS.black}60;
  font-family: ${FONTS.Helvetica.bold};
  font-size: 13px;

  &:after {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.966796 0.719297L5.31346 5.06596C5.8268 5.5793 5.8268 6.4193 5.31346 6.93263L0.966797 11.2793' stroke='black' stroke-width='0.75' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
    margin-left: 10px;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  max-width: 315px;
  margin: 35px 0 55px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    max-width: 300px;
    margin: 20px 0 38px;
  }

  @media print {
    display: none;
  }
`;

export const HalfBackground = styled(HalfWBackground)`
  z-index: -5;
  position: absolute;
  top: ${HEADER_NAV_HEIGHT}px;
  left: 0;
  width: 100%;
  height: inherit;

  & path {
    fill: rgba(240, 148, 62, 0.16);
  }
`;

export const PdfWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  background: ${COLORS.white};

  @media (max-width: 600px) {
    padding: 50px 0;
  }
`;

export const OrdersCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

// returns
export const OrderNumber = styled(Typography).attrs({
  tag: 'h2',
  type: 'bodyHelv',
})`
  border-bottom: 1px solid ${COLORS.black};
  font-family: ${FONTS.Helvetica.medium};
  padding-bottom: 14px;
`;

export const DescReturnsText = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  font-family: ${FONTS.Helvetica.medium};
  opacity: 0.6;
  margin: 16px 0;
`;

export const ReturnCardsWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  margin-right: 50px;
`;

export const ReturnReasonToCardWrapper = styled.div`
  margin-top: 125px;
  text-align: right;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-top: 20px;
    text-align: left;
  }
`;

export const ReturnReasonSummaryWrapper = styled.div`
  margin-top: 20px;
  text-align: right;

  @media (max-width: ${BREAKPOINTS.m}px) {
    text-align: left;

    & div {
      justify-content: flex-start;
      & > p {
        width: 100px;
      }
    }
  }
`;

export const ReturnReasonToAddressWrapper = styled.div`
  margin-top: 50px;
  border: 1px solid #000000;
  width: fit-content;
  padding: 10px 50px 10px 10px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-top: 18px;
  }
`;

export const EditButton = styled(Typography).attrs({
  type: 'subtitle4Helv',
})`
  margin: 50px 0 0 18px;
  opacity: 0.6;
  text-transform: uppercase;

  &:hover {
    color: ${COLORS.accent};
    transition: 0.3s;
  }

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-top: 18px;
  }
`;

export const ReturnCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    flex-direction: column;
  }
`;

export const ReturnInfoBlocks = styled.div`
  /* @media (max-width: ${BREAKPOINTS.m}px) {
    display: flex;
    justify-content: space-between;
  } */
`;

export const StyledRightBlockWrapper = styled(RightBlockWrapper)`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.m}px) {
    flex-direction: column;
  }
`;

export const ConfirmReturnSubtitle = styled(Typography).attrs({
  type: 'subtitle2Helv',
})`
  font-family: ${FONTS.Helvetica.bold};
`;

export const ReturnConfirmToAddressWrapper = styled.div`
  margin: 32px 0;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin: 18px 0 29px;
  }
`;

export const ReturnConfirmInfoBlocks = styled.div`
  display: flex;
  justify-content: end;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-top: 20px;
    justify-content: space-between;
  }
`;

export const ReturnConfirmSummaryWrapper = styled.div`
  text-align: right;
  margin-left: 50px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    text-align: left;
  }
`;

export const ReturnConfirmToCardWrapper = styled.div`
  text-align: right;

  @media (max-width: ${BREAKPOINTS.m}px) {
    text-align: left;
  }
`;

export const ConfirmReturnInstructionsSubtitle = styled(ConfirmReturnSubtitle)`
  @media (max-width: ${BREAKPOINTS.m}px) {
    margin-bottom: 10px;
  }
`;

export const ReturnExperience = styled(Typography).attrs({
  type: 'subtitle3Helv',
})`
  font-style: italic;
  font-family: ${FONTS.Helvetica.regular};

  @media (max-width: ${BREAKPOINTS.m}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const InstructionTitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 60px 0 30px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    flex-direction: column;
    margin: 40px 0 10px;
    align-items: start;
  }
`;

export const AddInstructionsTitle = styled(ConfirmReturnSubtitle)`
  margin: 37px 0 14px;

  @media (max-width: ${BREAKPOINTS.m}px) {
    margin: 13px 0 12px;
  }
`;

export const AddInstructionsList = styled.ul`
  list-style: disc;
  margin-left: 25px;

  li {
    font-family: ${FONTS.Helvetica.regular};
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: ${BREAKPOINTS.m}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
