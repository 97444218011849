import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';

export const getWishlistProductsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (ids, { rejectWithValue }) => {
  try {
    if (!ids.length) return [];

    const response = await getProductsByIds(ids);

    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
