import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { COLORS, FONTS } from 'styles';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  & a {
    text-decoration: none;
  }

  button {
    text-decoration: none;
    background: none;
    cursor: pointer;
  }

  & ol, ul {
    list-style: none;
  }

  & div, p {
    font-family: ${FONTS.Helvetica.regular};
  }

  :root {
    --accent-color: ${COLORS.accent};
  }
  
  @media print {
    body *:not(#section-to-print, #section-to-print *) {
      visibility: hidden;
      height: 0;
      overflow: visible;
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
      height: auto;
    }
  }
`;
