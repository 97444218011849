import { NavLink } from 'react-router-dom';
import ROUTES from 'router/Routes';
import {
  List,
  ListItem,
  MainTitle,
  NestedList,
  Paragraph,
  Title,
} from '../styles';

const PersonalInfoShare = () => (
  <>
    <MainTitle>HOW WE DISCLOSE YOUR PERSONAL INFORMATION</MainTitle>
    <Paragraph>
      We disclose your Personal Information to the categories of service
      providers and other third parties listed in this section. Depending on
      state laws that may be applicable to you, some of these disclosures may
      constitute a “sale” or “share” of your Personal Information. For more
      information, please refer to the state-specific sections below.
    </Paragraph>

    <Title space>A. DISCLOSURES TO OUR SERVICE PROVIDERS</Title>
    <Paragraph>
      We may disclose your Personal Information with our third-party service
      providers who use that information to help us provide our Services. This
      could include:
    </Paragraph>
    <List>
      <ListItem>IT support, hosting, and communications providers</ListItem>
      <ListItem>Security and fraud prevention consultants</ListItem>
      <ListItem>
        Payment processors:
        <NestedList>
          <li>
            Our payment processing partner, Stripe, Inc. (“Stripe”) collects
            your voluntarily-provided payment card information necessary to
            process your payment.
          </li>
          <li>
            Please see Stripe’s terms of service and privacy policy for
            information on its use and storage of your Personal Information.
          </li>
        </NestedList>
      </ListItem>
      <ListItem>Support and customer service vendors.</ListItem>
      <ListItem>Product fulfillment and delivery providers.</ListItem>
    </List>

    <Title space>B. DISCLOSURES TO OUR BUSINESS PARTNERS</Title>
    <Paragraph>
      We may disclose your Personal Information with business partners who
      partner with us in offering various services. They include:
    </Paragraph>
    <List>
      <ListItem>Businesses that you have a relationship with</ListItem>
      <ListItem>
        Companies with whom we jointly offer products, promotional offers,
        opportunities, or services
      </ListItem>
    </List>

    <Title space>C. DISCLOSURES TO OUR AFFILIATES</Title>
    <Paragraph>
      We may share your personal information with our current or future company
      affiliates, such as Ciroc, Deleon, AquaHydrate, Harlem Capital School,
      Combs Enterprises, Love Records, REVOLT
    </Paragraph>

    <Title space>
      D. DISCLOSURES TO PARTIES YOU AUTHORIZE, ACCESS, OR AUTHENTICATE
    </Title>
    <List>
      <ListItem>
        Third parties you access through the services, including buyers or
        sellers, or other users
      </ListItem>
      <ListItem>
        Social media services: Anyone you refer our Services to, such as by an
        email inviting your friend to use our Services. Please only share with
        us contact information of people with whom you have a relationship
        (e.g., relative, friend, neighbor, or co-worker).
      </ListItem>
    </List>

    <Title space>E. DISCLOSURES TO THE PUBLIC</Title>
    <Paragraph>
      Certain information that you upload to our Services will be made public
      (even to individuals who do not have an account with us). This includes
      reviews of products that you may submit and any identifier you choose to
      include related to your review.
    </Paragraph>

    <Title space>F. DISCLOSURES TO OUR ANALYTICS PARTNERS</Title>
    <Paragraph>
      These parties provide analytics on web traffic or usage of the Services.
      They include:
    </Paragraph>
    <List>
      <ListItem>
        Companies that track how users are found or were referred to the
        Services
      </ListItem>
      <ListItem>
        Companies that track how users interact with the Services
      </ListItem>
    </List>

    <Title space>G. DISCLOSURES TO OUR ADVERTISING PARTNERS</Title>
    <Paragraph>
      We may share your Personal Information with third-party advertising
      partners who help us market our services and provide you with other offers
      that may be of interest to you. They include:
    </Paragraph>
    <List>
      <ListItem>Ad networks</ListItem>
      <ListItem>Data brokers</ListItem>
    </List>

    <Title space>H. APIS/SDKS</Title>
    <Paragraph>
      We may use third-party application program interfaces (“APIs”) and
      software development kits (“SDKs”) as part of the functionality of our For
      more information about our use of APIs and SDKs, please contact us{' '}
      <NavLink to={ROUTES.CONTACT_US}>here</NavLink>.
    </Paragraph>

    <Title space>I. OTHER DISCLOSURES</Title>
    <List>
      <ListItem>
        <Title>
          Disclosures to Comply with Legal Obligations, Protect Us or Others
        </Title>
        <NestedList>
          <li>
            We may access, preserve, and disclose any Personal Information we
            store associated with you to external parties in conjunction with
            any of the activities set forth under “Meeting Legal Requirements
            and Enforcing Legal Terms” in the “Our Commercial or Business
            Purposes for Collecting Personal Information” section above.
          </li>
        </NestedList>
      </ListItem>
      <ListItem>
        <Title>
          Disclosure in the Event of Merger, Sale, or Other Asset Transfers
        </Title>
        <NestedList>
          <li>
            All of your Personal Information that we collect may be transferred
            to a third party if we are involved in a merger, acquisition,
            financing due diligence, reorganization, bankruptcy, receivership,
            purchase or sale of assets, or any other transaction in which that
            third party assumes control of our business (in whole or in part).
            Should one of these events occur, we will make reasonable efforts to
            notify you before your information becomes subject to different
            privacy and security policies and practices.
          </li>
        </NestedList>
      </ListItem>
      <ListItem>
        <Title>Information that is Not Personal Information</Title>
        <NestedList>
          <li>
            We may create aggregated, de-identified or anonymized data from the
            Personal Information and other information we collect, including by
            removing information that makes the information personally
            identifiable to a particular user. We may use such aggregated,
            de-identified data, or anonymized data and share it with third
            parties for our lawful business purposes, including to analyze,
            build and improve the Services, and promote our business, provided
            that we will not share such data in a manner that could identify
            you.
          </li>
        </NestedList>
      </ListItem>
    </List>
  </>
);

export default PersonalInfoShare;
