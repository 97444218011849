import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 38px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    gap: 10px;
    margin-bottom: 18px;
  }
`;

export const PaymentBlockTitle = styled.h3`
  font-size: 14px;
  line-height: 17px;
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.black};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const PaymentItemWrapper = styled.div`
  width: 100%;
  min-width: 258px;
  max-width: 390px;
  border: 1px solid ${COLORS.black}60;
  padding: 8px 5px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-width: 290px;
  }
`;

export const PaymentItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PaymentItemTitle = styled(Typography)`
  width: 144px;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.6;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const PaymentActionsWrapper = styled.div`
  width: 210px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 125px;
  }
`;

export const PaymentData = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.black};
  opacity: 0.6;
  word-spacing: 9px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const CheckboxLabel = styled(Typography).attrs({ type: 'subtitle2' })`
  color: ${COLORS.primary};
  opacity: 0.5;
  font-size: 10px;
  position: absolute;
  line-height: 12px;
  text-align: center;
  width: 40px;
  margin-left: 4px;
`;

export const ActionButton = styled.button<{
  disabled?: boolean;
}>`
  height: 12px;
  text-align: start;
  text-transform: uppercase;
  font-family: ${FONTS.Helvetica.medium};
  font-size: 11px;
  line-height: 14px;
  opacity: 0.6;
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:disabled {
    color: inherit;
  }

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 9px;
    line-height: 11px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-width: 55px;
  }
`;
