import * as yup from 'yup';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { states } from 'data/autocompleteData';

const VALIDATION_MESSAGES = {
  password:
    'Password must include at least 8 characters, one lowercase letter, one uppercase letter, one number and one special character',
};

yup.addMethod(
  yup.MixedSchema,
  'phoneMobileSchemas',
  function test(schemas: yup.AnySchema[]) {
    return this.test(
      'one-of-schemas',
      'Phone number must be 10 digits, cannot start with 0',
      (item) =>
        schemas.some((schema) => schema.isValidSync(item, { strict: true })),
    );
  },
);

const containsPoBox = (str: string) =>
  /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/gim.test(str);

const getPasswordValidationSchema = (initialMessage: string) =>
  yup
    .string()
    .required(initialMessage)
    .min(8, 'Password must be 8 characters or more')
    .matches(/[a-z]+/, 'At least one lowercase character is required')
    .matches(/[A-Z]+/, 'At least one uppercase character is required')
    .matches(/[@$!%*#?&]+/, 'At least one special character is required')
    .matches(/\d+/, 'At least one number is required');

const getNameValidationSchema = (fieldName: string) =>
  yup
    .string()
    .required(`Must enter a ${fieldName}`)
    .min(2, `${capitalize(fieldName)} must be 2 to 30 characters`)
    .max(30, `${capitalize(fieldName)} must be 2 to 30 characters`)
    .matches(/^[a-zA-Z ]+$/, 'Only letters are allowed')
    .matches(
      /\b(?!(MR|DR|MISS|MS|MRS)\b)\w+/i,
      `${capitalize(fieldName)} field must include a name with a prefix`,
    )
    .matches(
      /^(?!.*(Empower Global|EmpowerGlobal|EG)).*$/,
      `Invalid ${fieldName} field`,
    )
    .test('and-or', 'Name field must be only 1 name', (value: any) => {
      return !value?.split(' ').some((word: string) => {
        return ['or', 'and'].includes(word.toLowerCase());
      });
    });

const getEmailValidationSchema = () =>
  yup
    .string()
    .email('Not a valid e-mail address')
    .max(50, 'Email must be up to 50 characters')
    .required('Must enter an email address');

const getAddressValidationSchema = (fieldName: string) =>
  yup
    .string()
    .required(`Must enter valid ${fieldName}`)
    .max(75, `${capitalize(fieldName)} must be up to 75 characters`)
    .matches(/^[ A-Za-z0-9./-]*$/, `Invalid ${capitalize(fieldName)}`)
    .test(
      'no-po-box',
      'Empower Global does not currently ship to P.O. Boxes',
      (value: any) => !containsPoBox(value),
    )
    .test(
      'valid-address',
      `${capitalize(fieldName)} must not be "WILL NOTIFY"`,
      (value: any) => value.toLowerCase() !== 'will notify',
    );

const getPhoneValidationSchema = (initialMessage: string) =>
  yup
    .string()
    .required(initialMessage)
    .length(10, 'Phone number must be 10 characters')
    .matches(/^\d+$/, 'Only numbers are allowed')
    .test(
      'no-leading-zero',
      'Phone number cannot start with 0',
      (value, context: any) => {
        return context.originalValue && !context.originalValue.startsWith('0');
      },
    );

const getBirthdayValidationSchema = () =>
  yup.string().test('valid-date', 'Must enter valid date', (value) => {
    return dayjs(value).isValid();
  });

const getPostalCodeValidationSchema = () =>
  yup
    .string()
    .required('Must enter a ZIP code')
    .matches(
      /^(?!00000\b)(^\d{5}$)/,
      'Zip code not accepted. US zip codes go from 00001 to 99950',
    )
    .test(
      'valid-zip-code',
      'Zip code not accepted. US zip codes go from 00001 to 99950',
      (value) => Number(value) <= 99950,
    );

export const addressFormSchema = yup.object().shape({
  firstName: getNameValidationSchema('first name'),
  lastName: getNameValidationSchema('last name'),
  address1: getAddressValidationSchema('address'),
  address2: getAddressValidationSchema('address')
    .optional()
    .test(
      'address1-is-required',
      'Address1 field cannot be empty',
      (value, context) => {
        return context.parent.address1;
      },
    ),
  countryCode: yup
    .string()
    .required('Must enter country')
    .matches(/^([A-Z][A-Z])$/, 'Country code should consist of 2 letters'),
  stateCode: yup
    .string()
    .required('Must enter state')
    .test('valid-state', 'Choose a valid state from input', (str: any) => {
      return states.some((state) => state.code === str);
    }),
  city: yup
    .string()
    .required('Must enter city')
    .min(2, 'City must be 2 to 30 characters')
    .max(30, 'City must be 2 to 30 characters')
    .matches(/^[a-zA-Z ]+$/, 'Only letters are allowed'),
  postalCode: getPostalCodeValidationSchema(),
  phone: getPhoneValidationSchema('Must enter phone number'),
});

export const loginFormSchema = yup.object().shape({
  email: getEmailValidationSchema(),
  password: getPasswordValidationSchema('Enter your password'),
});

export const registerFormSchema = yup.object().shape({
  firstName: getNameValidationSchema('first name'),
  lastName: getNameValidationSchema('last name'),
  birthday: getBirthdayValidationSchema(),
  password: getPasswordValidationSchema(VALIDATION_MESSAGES.password),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
  email: getEmailValidationSchema(),
});

export const accountResetPasswordFormSchema = yup.object().shape({
  oldPassword: yup.string().required('Must enter old password'),
  newPassword: getPasswordValidationSchema(VALIDATION_MESSAGES.password),
  confirmNewPassword: yup
    .string()
    .required('Passwords must match')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export const paymentFormSchema = yup.object().shape({
  zipCode: getPostalCodeValidationSchema(),
  country: yup
    .string()
    .required('Must enter country')
    .matches(/^([A-Z][A-Z])$/, 'Country code should consist of 2 letters'),
});

export const resetPasswordFormSchema = yup.object().shape({
  newPassword: getPasswordValidationSchema(VALIDATION_MESSAGES.password),
  confirmNewPassword: yup
    .string()
    .required('Passwords must match')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export const passwordFormSchema = yup.object().shape({
  password: getPasswordValidationSchema('Password is required'),
});

export const emailFormSchema = yup.object().shape({
  email: getEmailValidationSchema(),
});

export const updatePersonalInfoFormSchema = yup.object().shape({
  firstName: getNameValidationSchema('first name'),
  lastName: getNameValidationSchema('last name'),
  email: getEmailValidationSchema(),
  phoneMobile: yup
    .string()
    .required('Must enter phone number')
    .matches(/^\d+$/, 'Only numbers are allowed')
    .length(10, 'Phone number must be 10 characters')
    .test(
      'no-leading-zero',
      'Phone number cannot start with 0',
      (value, context: any) => {
        return context.originalValue && !context.originalValue.startsWith('0');
      },
    ),
});

export const productReturnFormSchema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
  comment: yup.string().max(200, 'Comment must be no more than 200 characters'),
});

export const talkToSupportFormSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Not a valid e-mail address')
    .required('Email is required'),
  phone: getPhoneValidationSchema('Phone number is required'),
  subject: yup.string().required('Subject is required'),
  description: yup.string().required('Description is required'),
});

export const submitInquiryFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required')
    .min(2, 'First name must be 2 to 30 characters.')
    .max(30, 'First name must be 2 to 30 characters.')
    .matches(/^[a-zA-Z ]+$/, 'Only letters are allowed.')
    .matches(/^\S*$/, 'Name field must be only 1 name.'),
  last_name: yup
    .string()
    .required('Last name is required')
    .min(2, 'Last name must be 2 to 30 characters.')
    .max(30, 'Last name must be 2 to 30 characters.')
    .matches(/^[a-zA-Z ]+$/, 'Only letters are allowed.')
    .matches(/^\S*$/, 'Name field must be only 1 name.'),
  company: yup
    .string()
    .required('Company is required')
    .min(2, 'Company must be 2 to 20 characters.')
    .max(20, 'Company must be 2 to 20 characters.'),
  email: yup
    .string()
    .required('Email is required')
    .email('Not a valid e-mail address.'),
  website: yup.string(),
  instagram: yup.string(),
  tikTok: yup.string(),
  blackOwned: yup.boolean(),
});

export const getLoyaltySignUpFormSchema = (
  isLoggedIn: boolean,
  birthday: any,
) => {
  if (isLoggedIn && birthday) return yup.object().shape({});

  if (isLoggedIn) {
    return yup.object().shape({
      birthday: getBirthdayValidationSchema(),
    });
  }

  return yup.object().shape({
    firstName: getNameValidationSchema('first name'),
    lastName: getNameValidationSchema('last name'),
    pronouns: yup.string().required('Enter pronouns'),
    password: getPasswordValidationSchema(
      'Password must: contain a minimum of 10 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character',
    ),
    confirmPassword: getPasswordValidationSchema(
      'Password must: contain a minimum of 10 characters, 1 uppercase letter, 1 lowercase letter, and 1 special character',
    ).oneOf([yup.ref('password')], 'Passwords must match'),
    email: getEmailValidationSchema(),
    address1: getAddressValidationSchema('address 1'),
    address2: getAddressValidationSchema('address 2')
      .optional()
      .test(
        'address1-is-required',
        'Address1 field cannot be empty',
        (value, context) => {
          return context.parent.address1;
        },
      ),
    countryCode: yup
      .string()
      .required('Select your country')
      .matches(/^([A-Z][A-Z])$/, 'Country code should consist of 2 letters'),
    stateCode: yup
      .string()
      .required('Select your state')
      .test('valid-state', 'Choose a valid state from input', (str: any) => {
        return states.some((state) => state.code === str);
      }),
    city: yup
      .string()
      .required('Select your city')
      .min(2, 'City must be 2 to 30 characters')
      .max(30, 'City must be 2 to 30 characters')
      .matches(/^[a-zA-Z ]+$/, 'Only letters are allowed'),
    postalCode: getPostalCodeValidationSchema(),
    phone: getPhoneValidationSchema('Enter your phone number'),
    birthday: getBirthdayValidationSchema(),
  });
};

export const promoCodeFormSchema = yup.object().shape({
  promoCode: yup.string().required('Enter promo code'),
});
