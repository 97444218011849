import { values } from 'lodash';
import { TrashIcon } from 'assets/svg';
import { formatAccountPaymentInstrument } from 'helpers/formatters';
import { PaymentCardType } from 'types/CheckoutTypes';
import {
  PaymentItemWrapper,
  PaymentItemHeaderWrapper,
  PaymentActionsWrapper,
  PaymentItemTitle,
  PaymentData,
  ActionButton,
} from './styles';

const PaymentItem = ({
  data,
  handleDelete,
  selectPaymentInstrument,
  defaultPaymentInstrumentId,
}: {
  data: PaymentCardType;
  handleDelete?: (param: any) => void;
  selectPaymentInstrument?: (id: string | null, cardData?: string) => void;
  defaultPaymentInstrumentId: string;
}) => {
  const isDefault = defaultPaymentInstrumentId === data.id;
  const paymentData = formatAccountPaymentInstrument(data);

  const handleDefaultPaymentInstrument = () => {
    if (!selectPaymentInstrument) return;

    if (isDefault) {
      selectPaymentInstrument(null);
    } else {
      selectPaymentInstrument(data.id, values(paymentData).join(', '));
    }
  };

  const handleDeleteIconClick = () => {
    if (handleDelete) {
      handleDelete(data.id);
    }
  };

  return (
    <PaymentItemWrapper>
      <PaymentItemHeaderWrapper>
        <PaymentItemTitle>{paymentData.cardBrand}</PaymentItemTitle>
        <PaymentActionsWrapper>
          <ActionButton
            disabled={isDefault}
            onClick={handleDefaultPaymentInstrument}
          >
            {isDefault ? 'default' : 'set as default'}
          </ActionButton>
          <TrashIcon
            style={{ cursor: 'pointer' }}
            onClick={handleDeleteIconClick}
          />
        </PaymentActionsWrapper>
      </PaymentItemHeaderWrapper>
      <PaymentData>
        {paymentData.number} {paymentData.expires}
      </PaymentData>
    </PaymentItemWrapper>
  );
};

export default PaymentItem;
