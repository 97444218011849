import { List, ListItem, MainTitle, Paragraph } from '../styles';

const ExercisingRights = () => {
  return (
    <>
      <MainTitle>EXERCISING YOUR RIGHTS UNDER CCPA AND VCDPA</MainTitle>
      <Paragraph>
        To exercise the rights described in this Privacy Policy, you or, if you
        are a California resident, your Authorized Agent (defined below) must
        send us a request that (1) provides sufficient information to allow us
        to verify that you are the person about whom we have collected Personal
        Data (such as your login or billing information), and (2) describes your
        request in sufficient detail to allow us to understand, evaluate and
        respond to it. Each request that meets both of these criteria will be
        considered a “Valid Request.” We may not respond to requests that do not
        meet these criteria. We will only use Personal Data provided in a Valid
        Request to verify your identity and complete your request. You do not
        need an account to submit a Valid Request.
      </Paragraph>
      <Paragraph space>
        We will work to respond to your Valid Request within the time period
        required by applicable law. We will not charge you a fee for making a
        Valid Request unless your Valid Request(s) is excessive, repetitive or
        manifestly unfounded. If we determine that your Valid Request warrants a
        fee, we will notify you of the fee and explain that decision before
        completing your request.
      </Paragraph>
      <Paragraph space>
        Except as otherwise specified in the above sections, you may submit a
        Valid Request using the following methods:
      </Paragraph>

      <List>
        <ListItem>
          Email us at:{' '}
          <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>
        </ListItem>
        <ListItem>
          Send an official notice via mail to CE Marketplace c/o Legal
          Department, General Counsel Empower Global, at 384 Northyards Blvd NW,
          #67, Atlanta, GA 30313.
        </ListItem>
      </List>

      <Paragraph space>
        If you are a California resident, you may also authorize an agent (an
        “Authorized Agent”) to exercise your rights on your behalf. To do this,
        you must provide your Authorized Agent with written permission to
        exercise your rights on your behalf, and we may request a copy of this
        written permission from your Authorized Agent when they make a request
        on your behalf.
      </Paragraph>
    </>
  );
};

export default ExercisingRights;
