import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { ErrorText, Label } from 'components/Inputs/styles';
import { StyledTextField } from 'components/Forms/styles';
import { DatePickerIcon } from 'assets/svg';

const BasicDatePicker = ({
  control,
  name,
  label,
  defaultValue,
  error,
  errorMessage,
}: {
  control: any;
  name: string;
  label: string;
  defaultValue: string;
  error?: any;
  errorMessage: string | undefined;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              value={field.value}
              maxDate={new Date()}
              inputFormat="MM/DD/YYYY"
              showToolbar={false}
              components={{ OpenPickerIcon: DatePickerIcon }}
              onChange={(newValue) => {
                if (newValue) {
                  field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
                } else {
                  field.onChange('');
                }
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  error={!!error}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: '',
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />

      {error && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default BasicDatePicker;
