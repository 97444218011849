import { Paragraph, Title } from './styles';

const DummyTemplate = () => (
  <>
    <Title>Lorem ipsum dolor sit</Title>
    <Paragraph>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis,
      esse, necessitatibus tenetur veritatis consectetur exercitationem maiores
      beatae accusamus nemo, maxime earum reiciendis magnam eius quidem cum?
      Unde quasi velit deleniti ipsa, nemo magni eveniet explicabo similique,
      quis ad sunt in nostrum possimus vel error accusantium officiis architecto
      officia est deserunt a delectus incidunt iusto. Iusto illo praesentium,
      dolores atque id vero illum ipsa ratione odit reprehenderit quos cum
      voluptate repellendus consequatur veniam laboriosam ab iure explicabo
      earum tempore ullam? Rerum, nulla reiciendis quaerat eveniet laborum quod
      consectetur delectus dolor modi beatae cumque, illum optio. Odit iusto
      error numquam voluptatibus nostrum veniam ratione necessitatibus obcaecati
      nisi sed. Vitae cumque ipsum aperiam corporis libero praesentium fugit
      error odio ullam sint accusamus aspernatur laboriosam, aut ducimus minima
      nobis cum dolores soluta modi enim? Cum eaque eius unde suscipit
      reprehenderit magni praesentium dolorum nulla quo aperiam magnam vel,
      perspiciatis tempora animi enim pariatur quia deserunt consequuntur vero
      nemo placeat ut. Distinctio quasi ipsum ab harum quisquam illo quae unde
      inventore assumenda, a iure aperiam? Voluptatum neque harum, quas nostrum
      nihil ipsam at aperiam maxime necessitatibus, accusantium adipisci eius
      non voluptate nulla cum aliquid aspernatur. Amet explicabo, laborum
      mollitia in eveniet perferendis suscipit sapiente temporibus voluptates
      eos alias fugit pariatur quis dolores voluptatibus distinctio quo quos
      impedit magnam ipsa repellendus possimus neque! Aut optio voluptate dolor
      suscipit voluptatem in recusandae veritatis, laboriosam hic quia!
      Laboriosam explicabo odit aperiam ex ratione. Maiores delectus esse
      suscipit amet in repudiandae mollitia velit, eveniet tempore. Facilis eius
      quae modi enim laborum quia inventore. Obcaecati ratione eligendi deleniti
      delectus, saepe nobis reiciendis quod sunt laboriosam, perspiciatis iusto
      temporibus quis? Accusamus rem ad adipisci aperiam? Ad quam beatae tenetur
      veritatis veniam, fugit quas? Voluptatum minima excepturi doloribus
      facilis ex sit, sapiente odit voluptates eaque eius aperiam commodi dolor
      dolorem optio id. Molestias, sint sit soluta aperiam dicta eaque,
      inventore, nisi aliquid sunt fugiat saepe. Eaque corrupti necessitatibus
      debitis ratione quos nesciunt repellendus nisi, illum iusto quibusdam.
      Quas minus eos, rerum mollitia earum consequuntur modi sed voluptatem
      fugiat maxime, porro ullam sapiente quae, doloremque suscipit beatae amet
      repellat numquam illum officiis! Delectus eaque molestias magni a aliquam
      id accusamus, maiores provident corrupti porro assumenda fugit suscipit
      dolores architecto incidunt blanditiis facilis! Nostrum, sunt illo!
    </Paragraph>
  </>
);

export default DummyTemplate;
