import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Select } from '@mui/material';

import { Typography } from 'components';
import ErrorTooltip from 'components/Forms/ErrorTooltip';
import { ArrowDownIcon } from 'assets/svg';
import { COLORS, FONTS } from 'styles';
import { StyledListItemText, StyledMenuItem } from '../styles';

const ValidatedSelect = ({
  control,
  name,
  label,
  items,
  defaultValue,
  error,
  errorMessage = '',
  disabled,
}: {
  control: any;
  name: string;
  label: string;
  items: { code: string; label: string }[];
  defaultValue: string;
  error?: any;
  errorMessage: string | undefined;
  disabled?: boolean;
}) => {
  const [optionValues, setValue] = useState<string[]>([]);

  const handleClick = (_: any, value: string) => {
    setValue([value]);
  };

  const renderLabel = () => (
    <Typography
      type="subtitle4Helv"
      style={{
        fontFamily: FONTS.Helvetica.regular,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {optionValues[0] || label}
    </Typography>
  );

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            variant="standard"
            IconComponent={ArrowDownIcon}
            displayEmpty
            renderValue={renderLabel}
            inputProps={{ 'aria-label': 'Without label' }}
            {...field}
            ref={null}
            sx={{
              '&:after, &:before': { borderBottom: '1px solid black' },
              '&:hover:not(.Mui-disabled):before': {
                borderBottom: '1px solid black',
              },
              '& .MuiInput-input': {
                paddingTop: 0,
                paddingBottom: 0,

                '&:focus': { backgroundColor: 'transparent' },
              },
              '& .MuiSelect-icon': { top: '50%', marginTop: '-3px' },
              '&': {
                width: '100%',
                backgroundColor: disabled ? COLORS.background : COLORS.white,

                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${COLORS.accent}`,
                },

                '& .MuiOutlinedInput-notchedOutline': {
                  border: error
                    ? `1px solid ${COLORS.accent}`
                    : disabled
                    ? 'none'
                    : '',
                },
              },
            }}
            disabled={disabled}
          >
            {items.map((item: { code: string; label: string }) => (
              <StyledMenuItem
                value={item.code}
                onClick={(event: any) => handleClick(event, item.code)}
                key={item.code}
              >
                <StyledListItemText primary={item.code}>
                  {item.label}
                </StyledListItemText>
              </StyledMenuItem>
            ))}
          </Select>
        )}
      />

      {error && (
        <ErrorTooltip
          title={errorMessage}
          placement="right-end"
          style={{ position: 'absolute', right: 26, top: 10 }}
        />
      )}
    </div>
  );
};

export default ValidatedSelect;
