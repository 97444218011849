import { ColoredButton } from 'components';
import ProductCards from 'components/ProductListingComponents/ProductCards';
import { ProductType } from 'types/ProductsTypes';
import { CardsBlockWrapper } from './styles';

const SearchProductCards = ({
  total,
  productList,
  isLoading,
  searchValue,
  isLoggedIn,
  processSearchResult,
  isSearchQuerySaved,
  savedSearchLoading,
}: {
  total: number;
  productList: ProductType[];
  isLoading: boolean;
  searchValue: string | undefined;
  isLoggedIn: boolean;
  processSearchResult: () => void;
  isSearchQuerySaved: boolean;
  savedSearchLoading: boolean;
}) => {
  return (
    <CardsBlockWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {searchValue ? (
          <div>
            {isLoggedIn ? (
              <ColoredButton
                type="button"
                style={{
                  height: 30,
                  margin: '10px 0',
                  width: 'auto',
                  minWidth: 115,
                  padding: '0 10px',
                }}
                fontStyle={{ fontSize: 14 }}
                onClick={processSearchResult}
                isLoading={savedSearchLoading}
              >
                {isSearchQuerySaved ? 'Update saved search' : 'Save search'}
              </ColoredButton>
            ) : null}
          </div>
        ) : null}
      </div>
      <ProductCards
        total={total}
        productList={productList}
        isLoading={isLoading}
        emptyListTitle=""
      />
    </CardsBlockWrapper>
  );
};

export default SearchProductCards;
