import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const ErrorText = styled(Typography)`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.accent};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
