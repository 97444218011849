import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  getIsLoggedIn,
  getBasketError,
  getCustomerError,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { customerActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerSlice';
import { basketActions } from 'eg_SFCC_FE_core/store/reducers/slices/basketSlice';
import { Typography, LabelCheckbox } from 'components';
import AddressForm from 'components/Forms/AddressForm/AddressForm';
import { SuggestedAddressBox } from 'components/AccountComponents/styles';
import { addressFormSchema } from 'helpers/validationSchemas';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { MainTitle } from 'pages/CheckoutPage/styles';

const CheckoutAddressForm = ({
  addressValues,
  handleAddress,
  handleError,
  isErrorCleared,
  addressMethods,
  onAddressSubmit,
  suggestedAddress,
  setSuggestedAddress,
  isAddressSaved,
  setIsAddressSaved,
  handleFocus,
  handleChange,
}: {
  addressValues: AddressFormFieldsType;
  handleAddress?: (address: AddressFormFieldsType) => void;
  handleError: () => void;
  isErrorCleared?: boolean;
  addressMethods?: any;
  onAddressSubmit?: (data: AddressFormFieldsType, e: any) => void;
  suggestedAddress?: AddressFormFieldsType;
  setSuggestedAddress: Dispatch<
    SetStateAction<AddressFormFieldsType | undefined>
  >;
  isAddressSaved: boolean;
  setIsAddressSaved: Dispatch<SetStateAction<boolean>>;
  handleFocus?: () => void;
  handleChange?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const basketAddressError = useSelector(getBasketError);
  const customerAddressError = useSelector(getCustomerError);

  const methods = useForm<AddressFormFieldsType>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: addressValues,
  });

  const onSubmit: SubmitHandler<AddressFormFieldsType> = (
    data: AddressFormFieldsType,
    e: any,
  ) => {
    e.preventDefault();
    data && handleAddress && handleAddress(data);
    e.target.reset();
  };

  const handleSubmitSuggestedAddress = () => {
    // @ts-ignore
    Object.keys(suggestedAddress).forEach((addressFieldKey) => {
      addressMethods.setValue(
        addressFieldKey,
        // @ts-ignore
        suggestedAddress?.[addressFieldKey],
      );
    });
    dispatch(customerActions.clearError());
    dispatch(basketActions.clearError());
    setSuggestedAddress(undefined);
  };

  useEffect(() => {
    if (customerAddressError) {
      dispatch(customerActions.clearError());
    }

    return () => {
      dispatch(customerActions.clearError());
    };
  }, []);

  useEffect(() => {
    isErrorCleared && methods.reset();
  }, [isErrorCleared]);

  useEffect(() => {
    addressMethods.reset(addressValues);
  }, [addressValues]);

  return (
    <div className="checkout-address-form">
      <MainTitle>Shipping Details</MainTitle>
      <AddressForm
        onSubmit={onAddressSubmit || onSubmit}
        onError={handleError}
        onFocus={handleFocus}
        onChange={handleChange}
        methods={addressMethods || methods}
        addressError={basketAddressError || customerAddressError}
      />

      {isLoggedIn && (
        <div style={{ marginTop: 8 }}>
          <LabelCheckbox
            label="Add New Address To Address Book"
            checked={isAddressSaved}
            handleChange={() => setIsAddressSaved(!isAddressSaved)}
          />
        </div>
      )}

      {suggestedAddress && (
        <SuggestedAddressBox onClick={handleSubmitSuggestedAddress}>
          <Typography type="subtitle3" style={{ marginBottom: 10 }}>
            Use suggested address
          </Typography>
          {suggestedAddress.address1}
          {suggestedAddress.address2 ? (
            <>
              <br />
              {suggestedAddress.address2}
            </>
          ) : null}
          <br />
          {suggestedAddress.city}, {suggestedAddress.stateCode}{' '}
          {suggestedAddress.postalCode} <br />
        </SuggestedAddressBox>
      )}
    </div>
  );
};

export default CheckoutAddressForm;
