import { SortingOptionType } from 'types/ProductsTypes';
import { SortingOptionWrapper, SortingOptionTitle } from './styles';

const SortingOption = ({
  sortingOption,
  selectedSortingId,
  selectOption,
}: {
  sortingOption: SortingOptionType;
  selectedSortingId: string;
  selectOption: (id: string) => void;
}) => {
  const isOptionSelected = selectedSortingId === sortingOption.id;

  const handleClick = () => {
    if (isOptionSelected) {
      selectOption('');
    } else {
      selectOption(sortingOption.id);
    }
  };

  return (
    <SortingOptionWrapper
      onClick={handleClick}
      selected={sortingOption.id === selectedSortingId}
    >
      <SortingOptionTitle>{sortingOption.label}</SortingOptionTitle>
    </SortingOptionWrapper>
  );
};

export default SortingOption;
