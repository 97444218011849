import { Paragraph, UppercaseTitle } from '../styles';

const Indemnification = () => (
  <>
    <UppercaseTitle>
      9. Indemnification (aka What Happens If You Get Us Sued)
    </UppercaseTitle>
    <Paragraph>
      We hope this never happens, but if Empower Global gets sued because of
      something that you did, you agree to defend and indemnify us. That means
      you’ll defend Empower Global (including any of our employees) and hold us
      harmless from any legal claim or demand (including reasonable attorney’s
      fees) that arises from your actions, your use (or misuse) of our Services,
      your breach of the Terms, or you or your account’s infringement of someone
      else’s rights. The moral of the story is to not do anything that gets us
      sued!
    </Paragraph>
    <Paragraph>
      We reserve the right to handle our legal defense as we deem appropriate,
      even if you are indemnifying us, in which case you agree to cooperate with
      us so we can execute our strategy.
    </Paragraph>
  </>
);

export default Indemnification;
