import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextButton } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { promoCodeFormSchema } from 'helpers/validationSchemas';
import { COLORS } from 'styles';
import { FormWrapper, ErrorText } from './styles';

const PromoCodeForm = ({
  onPromoCodeSubmit,
  couponError,
}: {
  onPromoCodeSubmit: (promoCode: string, cb: () => void) => void;
  couponError?: string | null;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ promoCode: string }>({
    resolver: yupResolver(promoCodeFormSchema),
    defaultValues: { promoCode: '' },
  });

  const onSubmit: SubmitHandler<{ promoCode: string }> = (data: {
    promoCode: string;
  }) => {
    onPromoCodeSubmit(data.promoCode, reset);
  };

  return (
    <div>
      <FormWrapper
        component="form"
        id="promo-code-form"
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        <FormInputController
          control={control}
          name="promoCode"
          label="PROMO"
          error={!!errors.promoCode}
          errorMessage={
            errors?.promoCode
              ? errors.promoCode.message
              : 'Incorrect or empty entry'
          }
          sxStyle={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderBottom: '1px solid black',
            },
            margin: 0,
          }}
          id="promoCode"
        />
        <TextButton
          aria-label="apply promocode"
          label="apply"
          id="apply"
          type="submit"
          style={{ marginLeft: 60 }}
          onClick={() => {}}
        />
        {couponError && (
          <ErrorText style={{ color: COLORS.accent, fontSize: 12 }}>
            {couponError}
          </ErrorText>
        )}
      </FormWrapper>
    </div>
  );
};

export default PromoCodeForm;
