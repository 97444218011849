import { useState } from 'react';

import { Typography } from 'components';
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon';
import { BrandType } from 'types/BrandsTypes';
import {
  BusinessOwnerImage,
  FavoriteIconWrapper,
  FavoriteText,
  MainSectionWrapper,
  Title,
} from './styles';

const wordLimit = 400;

const BrandMainSection = ({
  brand,
  isFavorite,
  handleLikeIconClick,
}: {
  brand: BrandType;
  isFavorite: boolean;
  handleLikeIconClick?: () => Promise<boolean | undefined>;
}) => {
  const [isLiked, setIsLiked] = useState<boolean>(!!isFavorite);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const businessOwnerBioWordsNumber = brand.businessOwnerBio.split(' ').length;

  const toggleFavoriteIcon = async () => {
    if (handleLikeIconClick) {
      const wasUpdated = await handleLikeIconClick();
      wasUpdated && setIsLiked(!isFavorite);
    }
  };

  return (
    <MainSectionWrapper>
      {brand.businessOwnerImage ? (
        <div>
          <BusinessOwnerImage loading="lazy" src={brand.businessOwnerImage} />
        </div>
      ) : null}
      <div>
        <Title>{brand.businessName.toUpperCase()}</Title>
        <Typography type="title4Helv" tag="h2" style={{ marginBottom: 20 }}>
          About the brand
        </Typography>
        <Typography type="bodyHelv">{brand.businessDescription}</Typography>
        <Typography type="title4Helv" tag="h2" style={{ marginTop: 25 }}>
          Meet the Founders
        </Typography>
        <Typography type="title5Helv" tag="h2" style={{ marginBottom: 25 }}>
          {brand.firstName} {brand.lastNmae}
        </Typography>
        <div style={{ display: 'inline' }}>
          <Typography type="bodyHelv">
            {seeMore || businessOwnerBioWordsNumber < wordLimit
              ? brand.businessOwnerBio
              : `${brand.businessOwnerBio
                  .split(' ')
                  .slice(0, wordLimit)
                  .join(' ')}...`}
          </Typography>
          {businessOwnerBioWordsNumber > wordLimit ? (
            <Typography
              type="title4Helv"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setSeeMore((prev: boolean) => !prev)}
            >
              {seeMore ? 'See less' : 'See more'}
            </Typography>
          ) : null}
        </div>
        <FavoriteIconWrapper>
          {handleLikeIconClick ? (
            <FavoriteIcon
              onClick={toggleFavoriteIcon}
              isFavorite={isLiked}
              iconPosition={{ left: '0px' }}
            />
          ) : null}
          <FavoriteText>
            {isFavorite ? 'favorite brand' : 'add to favorite brands'}
          </FavoriteText>
        </FavoriteIconWrapper>
      </div>
    </MainSectionWrapper>
  );
};

export default BrandMainSection;
