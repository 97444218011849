import { useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { InputAdornment } from '@mui/material';

import BasicAutocomplete from 'components/Dropdowns/Selects/BasicAutocomplete';
import StripeInput from 'components/Inputs/StripeInput';
import FormInputController from 'components/Inputs/FormInputController';
import { countries } from 'data/autocompleteData';
import { PaymentValuesType } from 'types/AccountTypes';
import CardCvcTooltip from '../CardCvcTooltip';
import { CardsImage, FormWrapper } from './styles';
import { FieldsBox } from '../styles';

const DEFAULT_OPTIONS = { placeholder: '' };

const ELEMENT_OPTIONS = {
  showIcon: true,
  ...DEFAULT_OPTIONS,
};

interface State {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
}

const PaymentForm = ({
  values,
  onSubmit,
  methods,
  handleChange,
}: {
  values: PaymentValuesType;
  onSubmit: (value: any) => void;
  methods: any;
  handleChange?: () => void;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [inputError, setInputError] = useState({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  });

  const handleInputChange =
    (prop: keyof State) => (e: { error: { message: any } }) => {
      handleChange && handleChange();

      setInputError((prevError) => ({
        ...prevError,
        [prop]: e.error?.message,
      }));
    };

  return (
    <FormWrapper
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id="payment-form"
    >
      <FormInputController
        control={control}
        name="ccnumber"
        label="Card Number*"
        error={!!inputError.cardNumber}
        errorMessage={inputError?.cardNumber}
        InputLabelProps={{ shrink: true }}
        stripeInput
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
            options: ELEMENT_OPTIONS,
            onChange: handleInputChange('cardNumber'),
          },
          endAdornment: (
            <InputAdornment position="end">
              <CardsImage
                src={require('../../../assets/jpg/cards.jpg')}
                alt="cards"
              />
            </InputAdornment>
          ),
        }}
      />

      <FieldsBox>
        <FormInputController
          control={control}
          name="ccexp"
          label="Expiration*"
          error={!!inputError.cardExpiry}
          errorMessage={inputError?.cardExpiry}
          InputLabelProps={{ shrink: true }}
          stripeInput
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
              options: DEFAULT_OPTIONS,
              onChange: handleInputChange('cardExpiry'),
            },
          }}
        />
        <FormInputController
          control={control}
          name="cvc"
          label="CVC*"
          error={!!inputError.cardCvc}
          errorMessage={inputError?.cardCvc}
          InputLabelProps={{ shrink: true }}
          stripeInput
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
              options: DEFAULT_OPTIONS,
              onChange: handleInputChange('cardCvc'),
            },
            endAdornment: (
              <InputAdornment position="end">
                <CardCvcTooltip />
              </InputAdornment>
            ),
          }}
        />
      </FieldsBox>

      <FieldsBox>
        <BasicAutocomplete
          name="country"
          control={control}
          label="Country*"
          defaultValue={values.country}
          error={!!errors.country}
          errorMessage={errors?.country?.message}
          items={countries}
          shrinkLabel
        />

        <FormInputController
          control={control}
          name="zipCode"
          label="ZIP*"
          error={!!errors.zipCode}
          errorMessage={errors?.zipCode?.message}
          InputLabelProps={{ shrink: true }}
          sxStyle={{ marginTop: '8px' }}
        />
      </FieldsBox>
    </FormWrapper>
  );
};

export default PaymentForm;
