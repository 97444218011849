import styled from 'styled-components';
import { PageContentWrapper } from 'styles/common';

export const BlockWrapper = styled(PageContentWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 60px 0 40px 0;
  width: 100%;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding-top: 10px;
  }
`;

export const ProductCardWrapper = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    width: 49%;
  }
`;
