import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';

export const getSearchSuggestionsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (q, { rejectWithValue }) => {
  try {
    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
      q,
    };

    const response = await axiosInstance.get(
      endpoints.search.searchSuggestions,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
