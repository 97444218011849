import { upperFirst } from 'lodash';
import { ProductItemPrice } from 'components';
import CustomNavLink from 'components/Links/CustomNavLink';
import { Typography } from 'components/Typography/Typography';
import { formatNameForUrl } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { COLORS } from 'styles';
import { BasketProductItemType } from 'types/ProductsTypes';
import {
  CartItemText,
  CartItemVariationWrapper,
  CartProductNameText,
  OrderItemWrapper,
  OrderItemsWrapper,
} from './styles';

const OrderItem = ({
  item: {
    productId,
    priceAfterItemDiscount,
    priceAfterOrderDiscount,
    image,
    c_marketplacerSellerName: marketplacerSellerName,
    productName,
    price,
    discountedPrice,
    originalPrice,
    inventory,
    variationValues,
  },
  showAvailabilityMessage = true,
}: {
  item: BasketProductItemType;
  showAvailabilityMessage?: boolean;
}) => {
  const isItemOrderable = inventory?.orderable;

  return (
    <CustomNavLink
      to={`${ROUTES.PRODUCTS}/${formatNameForUrl(productName)}-${productId}`}
    >
      <div>
        <OrderItemWrapper orderable={isItemOrderable}>
          {image ? (
            <img
              src={image?.link}
              alt={image?.alt}
              style={{ width: 50, marginRight: 20 }}
            />
          ) : (
            <div style={{ width: 70 }} />
          )}
          <div style={{ flex: 1 }}>
            <CartItemText>{marketplacerSellerName}</CartItemText>
            <CartProductNameText>{productName}</CartProductNameText>
            {variationValues
              ? Object.keys(variationValues).map((key: string) => (
                  <CartItemVariationWrapper key={key}>
                    <Typography type="xsmallHelv" style={{ marginRight: 5 }}>
                      {upperFirst(key)}:
                    </Typography>
                    <CartItemText>{variationValues[key]}</CartItemText>
                  </CartItemVariationWrapper>
                ))
              : null}
          </div>
          <div style={{ textAlign: 'end', color: COLORS.primary }}>
            <ProductItemPrice
              price={price}
              priceAfterOrderDiscount={priceAfterOrderDiscount}
              priceAfterItemDiscount={priceAfterItemDiscount}
              discountedPrice={discountedPrice}
              originalPrice={originalPrice}
            />
          </div>
        </OrderItemWrapper>
        {isItemOrderable || !showAvailabilityMessage ? null : (
          <Typography color="accent" style={{ textAlign: 'center' }}>
            Item is not available for order. Remove it from basket
          </Typography>
        )}
      </div>
    </CustomNavLink>
  );
};

const OrderItems = ({
  products,
  showAvailabilityMessage = true,
}: {
  products: any;
  showAvailabilityMessage?: boolean;
}) => {
  if (!products) return null;

  return (
    <OrderItemsWrapper>
      {products.map((item: any) => (
        <OrderItem
          item={item}
          key={item.productId}
          showAvailabilityMessage={showAvailabilityMessage}
        />
      ))}
    </OrderItemsWrapper>
  );
};

export default OrderItems;
