import { categorySliderData } from 'data/homeData';

import {
  Image,
  StyledSlider,
  SlideContainer,
  StyledCustomNavLink,
} from './styles';

const HomepageCategorySlider = () => {
  const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: true,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
        },
      },
    ],
  };

  return (
    <StyledSlider {...sliderSettings}>
      {categorySliderData.map((slide) => (
        <SlideContainer>
          <Image
            component="img"
            sx={{
              content: {
                xxxs: `url(${require(`../../../${slide.imageUrl}`)})`,
              },
            }}
          />
          <StyledCustomNavLink to={slide.link}>{slide.cta}</StyledCustomNavLink>
        </SlideContainer>
      ))}
    </StyledSlider>
  );
};

export default HomepageCategorySlider;
