import { ReactNode } from 'react';
import { DesktopContentWrapper, DesktopPageTitle } from './styles';

const InfoPageText = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <DesktopContentWrapper>
      <DesktopPageTitle>{title}</DesktopPageTitle>
      {children}
    </DesktopContentWrapper>
  );
};

export default InfoPageText;
