import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import LoyaltyRulesBlock from 'components/LoyaltyComponents/LoyaltyRulesBlock/LoyaltyRulesBlock';
import LoyaltyMainSection from 'components/LoyaltyComponents/LoyaltyMainSection/LoyaltyMainSetion';
import LoyaltyPointsOverview from 'components/LoyaltyComponents/LoyaltyPointsOverview/LoyaltyPointsOverview';
import { getCustomerData } from 'eg_SFCC_FE_core/store/selectors';
import useGoogleTags from 'hooks/useGoogleTags';

const LoyaltyProgramPage = () => {
  const { addPageView } = useGoogleTags();
  const customerData = useSelector(getCustomerData);
  const isLoyaltyMember = customerData?.c_LoyaltyMember;
  const loyaltyInfo = customerData?.c_loyaltyInfo;
  const membersInfo = loyaltyInfo?.consumersInfo?.[0].membersInfo;

  const currentTierName: string = membersInfo
    ? membersInfo[0]?.currentTierName
    : '';

  useEffect(() => {
    addPageView('loyalty');
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EG Elite Shopping Loyalty Program | Empower Global</title>
        <meta
          name="description"
          content="EG Elite is a free rewards program offered by Empower Global. Account holders are eligible to receive tier benefits and rewards. Earn 1 point for every $1 spent.  "
        />
      </Helmet>
      <LoyaltyMainSection
        currentTierName={currentTierName}
        isLoyaltyMember={isLoyaltyMember}
      />
      <LoyaltyPointsOverview />
      <LoyaltyRulesBlock
        currentTierName={currentTierName}
        isLoyaltyMember={isLoyaltyMember}
      />
    </>
  );
};

export default LoyaltyProgramPage;
