import { Typography } from 'components';
import { COLORS } from 'styles';
import { ContactInfoWrapper } from './styles';

const ContactInfoBlock = () => {
  return (
    <ContactInfoWrapper>
      <Typography
        type="subtitle2Helv"
        style={{
          fontSize: 14,
          textTransform: 'uppercase',
          marginBottom: 15,
        }}
      >
        Customer Care
      </Typography>
      <Typography type="bodyHelv">
        You can also contact us by email at{' '}
        <a
          href="mailto:wegotyou@empowerglobal.shop"
          style={{ color: COLORS.black }}
        >
          wegotyou@empowerglobal.shop
        </a>
        .
      </Typography>
    </ContactInfoWrapper>
  );
};

export default ContactInfoBlock;
