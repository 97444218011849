import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import { ModalIds } from 'eg_SFCC_FE_core/types';

export const getIsLoggedIn = (state: RootState) =>
  state.customerReducer.isLoggedIn;
export const getRegistrationResult = (state: RootState) =>
  state.customerReducer.registrationResult;
export const getAuthError = (state: RootState) => state.customerReducer.error;
export const getLoginResult = (state: RootState) =>
  state.customerReducer.loginResult;
export const getCustomerId = (state: RootState) =>
  state.customerReducer.customerId;
export const getCustomerData = (state: RootState) =>
  state.customerReducer.customerData;
export const getWasEmailUpdated = (state: RootState) =>
  state.customerReducer.emailWasUpdated;
export const getCustomerLoader = (state: RootState) =>
  state.customerReducer.pending;
export const getCustomerError = (state: RootState) =>
  state.customerReducer.error;
export const getResetPasswordResult = (state: RootState) =>
  state.customerReducer.resetPasswordResult;
export const getCustomerPaymentInstruments = (state: RootState) =>
  state.customerReducer.customerPaymentInstruments;
export const getFavoriteBrandsIds = (state: RootState) =>
  state.customerReducer.favoriteBrandsIds;
export const getFavoriteBrands = (state: RootState) =>
  state.customerReducer.customerData.c_followedSellers;
export const getDefaultPaymentInstrumentId = (state: RootState) =>
  state.customerReducer.defaultPaymentInstrumentId;
export const getSavedSearchQueries = (state: RootState) =>
  state.customerReducer.customerData?.c_savedSearchJson || [];

export const getCategories = (state: RootState) =>
  state.categoriesReducer.result;
export const getCategory = (state: RootState) => state.categoriesReducer.result;
export const getSelectedCategory = (state: RootState) =>
  state.categoriesReducer.selectedCategory;

export const getModals = (state: RootState) => state.modalReducer.modals;
export const getModalById = (id: ModalIds) => (state: RootState) => {
  return state.modalReducer.modals.find(({ modal }) => {
    return modal.id === id;
  });
};
export const isModalOpen = (id: ModalIds) => (state: RootState) => {
  const modal = state.modalReducer.modals.find(
    (modalItem) => modalItem.modal.id === id,
  );

  return modal?.isOpen;
};

export const getProductsData = (state: RootState) => state.productListReducer;
export const getProductsList = (state: RootState) =>
  state.productListReducer.productList;
export const getProductsLoader = (state: RootState) =>
  state.productListReducer.pending;
export const getProductsListCategories = (state: RootState) =>
  state.productListReducer.categories;
export const getProduct = (state: RootState) => state.productReducer.result;
export const getProductCategories = (state: RootState) =>
  state.productReducer.categories;
export const getProductLoader = (state: RootState) =>
  state.productReducer.pending;

export const getProductSearchResultLoader = (state: RootState) =>
  state.productSearchResultReducer.pending;
export const getProductSearchResult = (state: RootState) =>
  state.productSearchResultReducer;
export const getProductListSearchResult = (state: RootState) =>
  state.productSearchResultReducer.productList;

export const getCustomerProductLists = (state: RootState) =>
  state.customerProductListsReducer.result;
export const getCustomerProductListsLoader = (state: RootState) =>
  state.customerProductListsReducer.pending;
export const getWishlistProducts = (state: RootState) =>
  state.customerProductListsReducer.wishlistProducts;

export const getBrandsList = (state: RootState) =>
  state.brandsListReducer.brandList;
export const getBrandsListLoader = (state: RootState) =>
  state.brandsListReducer.pending;
export const getBrand = (state: RootState) => state.brandReducer.result;
export const getBrandError = (state: RootState) => state.brandReducer.error;
export const getBrandLoader = (state: RootState) => state.brandReducer.pending;
export const getBrandProductList = (state: RootState) =>
  state.brandReducer.productList;
export const getBrandProductListTotal = (state: RootState) =>
  state.brandReducer.productListTotal;

export const getBasket = (state: RootState) => state.basketReducer;
export const getBasketResult = (state: RootState) => state.basketReducer.result;
export const getBasketProductItems = (state: RootState) =>
  state.basketReducer.productItems;
export const getPaymentInProgressData = (state: RootState) =>
  state.basketReducer.paymentInProgressData;
export const getBasketLoader = (state: RootState) =>
  state.basketReducer.pending;
export const getCoupons = (state: RootState) => state.basketReducer.coupons;
export const getBasketError = (state: RootState) => state.basketReducer.error;
export const getCouponError = (state: RootState) =>
  state.basketReducer.couponError;

export const getOrders = (state: RootState) => state.orderReducer;
export const getOrderLoader = (state: RootState) => state.orderReducer.pending;
export const getCustomerOrderLists = (state: RootState) => state.orderReducer;
export const getOrderDetails = (state: RootState) =>
  state.orderReducer.orderDetails;
export const getReturnParamsOrder = (state: RootState) =>
  state.orderReducer.returnParams;
export const getCustomerOrderListsLoader = (state: RootState) =>
  state.orderReducer.pending;
export const getOrderError = (state: RootState) => state.orderReducer.error;
export const getOrderLastReturnRequestResponse = (state: RootState) =>
  state.orderReducer.lastReturnRequestResponse;

export const getCheckoutLoader = (state: RootState) => {
  return (
    state.orderReducer.pending ||
    state.customerReducer.pending ||
    state.productReducer.pending ||
    state.productListReducer.pending ||
    state.basketReducer.pending
  );
};

export const getSearchSuggestionsResult = (state: RootState) =>
  state.productSearchResultReducer.searchSuggestions;
export const getSearchFilterValues = (state: RootState) =>
  state.productSearchResultReducer.filterValues;
export const getSearchSortingId = (state: RootState) =>
  state.productSearchResultReducer.sortingId;

export const getSavedSearchResults = (state: RootState) =>
  state.savedSearchesReducer.result;
export const getSavedSearchResultsLoader = (state: RootState) =>
  state.savedSearchesReducer.pending;
export const getSavedSearchResultsError = (state: RootState) =>
  state.savedSearchesReducer.error;
