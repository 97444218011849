import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';
import { PageContentWrapper } from 'styles/common';

export const Title = styled(Typography).attrs({
  type: 'title2Helv',
})`
  font-size: 32px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
  }
`;

export const PageWrapper = styled(PageContentWrapper)`
  padding: 50px 90px;

  & > div:first-child {
    width: 40%;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
    padding: 20px 15px;
    width: auto;
  }
`;
