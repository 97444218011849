import { Dispatch, SetStateAction, useState } from 'react';
import { indexOf } from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import SortingOption from 'components/ProductListingComponents/SortingOption';
import { SortingOptionType } from 'types/ProductsTypes';
import { RotatedMinus, RotatedPlus } from 'components/Accordion/styles';
import {
  Cross,
  FilteredBy,
  SelectedFilterLabel,
  SelectedFiltersWrapper,
} from './styles';

const sortingOptionIdsToApply = [
  'price',
  'price-low-to-high',
  'price-high-to-low',
  'product-name-ascending',
  'product-name-descending',
  'brand',
  'most-popular',
];

const Sorting = ({
  sortingOptions,
  sortingId,
  setSortingId,
  isExpanded,
}: {
  sortingOptions: SortingOptionType[];
  sortingId: string;
  setSortingId: Dispatch<SetStateAction<string>>;
  isExpanded?: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const sortingOptionsToApply = sortingOptions?.filter(
    (sortingOption: SortingOptionType) => {
      return indexOf(sortingOptionIdsToApply, sortingOption.id) > -1;
    },
  );

  const sortingLabel = sortingOptions?.find(
    (sortingOption: SortingOptionType) => {
      return sortingOption.id === sortingId;
    },
  )?.label;

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  if (isExpanded) {
    return (
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={expanded}
        TransitionProps={
          { unmountOnExit: true, mountOnEnter: true } as TransitionProps
        }
        sx={{
          width: 300,
          '&::before': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <AccordionSummary
          aria-controls="sorting-content"
          id="sorting-header"
          sx={{
            padding: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
              flexDirection: 'column',
            },
          }}
        >
          <div
            onClick={handleChange}
            aria-hidden="true"
            style={{ display: 'flex' }}
          >
            <FilteredBy>Sorted by:</FilteredBy>
            {expanded ? <RotatedMinus /> : <RotatedPlus />}
          </div>

          {sortingLabel ? (
            <SelectedFiltersWrapper>
              <SelectedFilterLabel onClick={() => {}}>
                {sortingLabel}
                <Cross onClick={() => setSortingId('')} />
              </SelectedFilterLabel>
            </SelectedFiltersWrapper>
          ) : null}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {sortingOptionsToApply?.length
            ? sortingOptionsToApply?.map((sortingOption: SortingOptionType) => {
                return (
                  <div style={{ margin: '4px 0' }} key={sortingOption.id}>
                    <SortingOption
                      sortingOption={sortingOption}
                      selectedSortingId={sortingId}
                      selectOption={setSortingId}
                    />
                  </div>
                );
              })
            : null}
        </AccordionDetails>
      </MuiAccordion>
    );
  }

  return (
    <>
      <FilteredBy style={{ marginBottom: 20 }}>Sorted by:</FilteredBy>
      {sortingOptionsToApply?.length
        ? sortingOptionsToApply?.map((sortingOption: SortingOptionType) => {
            return (
              <div style={{ margin: '4px 0' }} key={sortingOption.id}>
                <SortingOption
                  sortingOption={sortingOption}
                  selectedSortingId={sortingId}
                  selectOption={setSortingId}
                />
              </div>
            );
          })
        : null}
    </>
  );
};

export default Sorting;
