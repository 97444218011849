import { createTheme } from '@mui/material/styles';

import TTNormsLight from 'assets/fonts/TTNorms-Light.otf';
import TTNormsRegular from 'assets/fonts/TTNorms-Regular.otf';
import TTNormsMedium from 'assets/fonts/TTNorms-Medium.otf';
import TTNormsBold from 'assets/fonts/TTNorms-Bold.otf';
import BisonBold from 'assets/fonts/Bison-Bold.ttf';
import BisonRegular from 'assets/fonts/Bison-Regular.ttf';
import HelveticaRegular from 'assets/fonts/HelveticaNeue-Roman.otf';
import HelveticaMedium from 'assets/fonts/HelveticaNeue-Medium.otf';
import HelveticaBold from 'assets/fonts/HelveticaNeue-Bold.otf';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const theme = createTheme({
  palette: {
    primary: { main: COLORS.primary },
    secondary: { main: COLORS.accent },
    error: { main: COLORS.accent },
  },
  breakpoints: {
    values: {
      xxxs: BREAKPOINTS.xxxs,
      xxs: BREAKPOINTS.xxs,
      xs: BREAKPOINTS.xs,
      s: BREAKPOINTS.s,
      m: BREAKPOINTS.m,
      l: BREAKPOINTS.l,
      xl: BREAKPOINTS.xl,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: ${FONTS.TTNorms.light};
          src: local(${FONTS.TTNorms.light}), url(${TTNormsLight}) format('opentype');
          font-weight: 300;
          font-style: normal;
        }
      
        @font-face {
          font-family: ${FONTS.TTNorms.regular};
          src: local(${FONTS.TTNorms.regular}), url(${TTNormsRegular}) format('opentype');
          font-weight: 400;
          font-style: normal;
        }
      
        @font-face {
          font-family: ${FONTS.TTNorms.medium};
          src: local(${FONTS.TTNorms.medium}), url(${TTNormsMedium}) format('opentype');
          font-weight: 500;
          font-style: normal;
        }
        
        @font-face {
          font-family: ${FONTS.TTNorms.bold};
          src: local(${FONTS.TTNorms.bold}), url(${TTNormsBold}) format('opentype');
          font-weight: 700;
          font-style: normal;
        }
      
        @font-face {
          font-family: ${FONTS.Bison.bold};
          src: local(${FONTS.Bison.bold}), url(${BisonBold}) format('truetype');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: ${FONTS.Bison.regular};
          src: local(${FONTS.Bison.regular}), url(${BisonRegular}) format('truetype');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: ${FONTS.Helvetica.regular};
          src: local(${FONTS.Helvetica.regular}), url(${HelveticaRegular}) format('opentype');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: ${FONTS.Helvetica.medium};
          src: local(${FONTS.Helvetica.medium}), url(${HelveticaMedium}) format('opentype');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: ${FONTS.Helvetica.bold};
          src: local(${FONTS.Helvetica.bold}), url(${HelveticaBold}) format('opentype');
          font-weight: 700;
          font-style: normal;
        }
      `,
    },
  },
});
