import { Dispatch, ReactNode, SetStateAction } from 'react';

import {
  Wrapper,
  AuthFormWrapper,
  ButtonsWrapper,
  AuthTitle,
  LoginTitle,
  Btn,
  Logo,
  LogoIcon,
  Cross,
  LogosWrapper,
} from './styles';

interface SignInProps {
  children: ReactNode;
  isLogin: boolean;
  setIsLogin: Dispatch<SetStateAction<boolean>>;
  unSetModal: () => void;
}

const AuthContainer = ({
  children,
  isLogin,
  setIsLogin,
  unSetModal,
}: SignInProps) => {
  const handleContentChange = () => setIsLogin(!isLogin);

  return (
    <Wrapper isLogin={isLogin}>
      <Cross onClick={unSetModal} />
      <LogosWrapper>
        <LogoIcon title="Empower Global Logo Icon" />
        <Logo title="Empower Global Logo" />
      </LogosWrapper>
      <ButtonsWrapper>
        <Btn onClick={unSetModal}>Skip for now</Btn>
        <Btn onClick={handleContentChange}>
          {isLogin ? 'Create Account' : 'Existing User'}
        </Btn>
      </ButtonsWrapper>
      <AuthTitle>{isLogin ? 'Sign in' : 'Create account'}</AuthTitle>
      {isLogin ? <LoginTitle>My Account</LoginTitle> : null}
      <AuthFormWrapper>{children}</AuthFormWrapper>
    </Wrapper>
  );
};

export default AuthContainer;
