import { useState } from 'react';
import { upperFirst, xor } from 'lodash';
import { InputAdornment } from '@mui/material';

import { SearchIcon, CheckIcon } from 'assets/svg';
import { COLORS } from 'styles';
import { Typography, AnimatedWrapper } from 'components';
import { StyledTextField } from 'components/Dropdowns/styles';
import {
  FilterBlockWrapper,
  FilterLabelsWrapper,
  FilterLabelWrapper,
  ResetBlockWrapper,
  FilterItem,
  FilterItemsWrapper,
} from './styles';

type FilterType = { label: string; items: string[] };

const MobileFilters = ({ filters }: { filters: FilterType[] }) => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>(filters[0]);
  const [optionValues, setValue] = useState<string[]>([]);

  const handleClick = (value: string) => {
    setValue(xor(optionValues, [value]));
  };

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <FilterLabelsWrapper>
        {filters.map((filter: FilterType) => (
          <FilterLabelWrapper
            key={filter.label}
            onClick={() => setCurrentFilter(filter)}
          >
            <Typography type="subtitle3">{upperFirst(filter.label)}</Typography>
          </FilterLabelWrapper>
        ))}
      </FilterLabelsWrapper>
      <AnimatedWrapper animationKey={currentFilter?.label}>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <FilterBlockWrapper>
            <StyledTextField
              placeholder={`SEARCH ${currentFilter.label.toUpperCase()}`}
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FilterBlockWrapper>
          <FilterItemsWrapper>
            {currentFilter.items.map((item: string, index: number) => (
              <div
                key={index}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <FilterItem
                  onClick={() => handleClick(item)}
                  style={{
                    color:
                      optionValues.indexOf(item) > -1
                        ? COLORS.accent
                        : COLORS.black,
                  }}
                >
                  {upperFirst(item)}
                </FilterItem>
                {optionValues.indexOf(item) > -1 && <CheckIcon />}
              </div>
            ))}
          </FilterItemsWrapper>
          <ResetBlockWrapper
            style={{ cursor: 'pointer' }}
            onClick={() => setValue([])}
          >
            RESET
          </ResetBlockWrapper>
        </div>
      </AnimatedWrapper>
    </div>
  );
};

export default MobileFilters;
