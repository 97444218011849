/* eslint-disable no-await-in-loop */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { CustomNavLink, Typography, Loader } from 'components';
import CartItem from 'components/CartComponents/CartItem';
import PromoCode from 'components/CheckoutComponents/PromoCode';
import PromoCodeForm from 'components/Forms/PromoCodeForm/PromoCodeForm';
import ROUTES from 'router/Routes';
import { formatPrice } from 'helpers/formatters';
import { areAllItemsInBasketAvailable } from 'helpers/basketUtils';
import useBasket from 'hooks/useBasket';
import { BasketProductItemType } from 'types/ProductsTypes';
import { CouponItemType } from 'types/CheckoutTypes';
import { FONTS } from 'styles';
import {
  getBasket,
  getCouponError,
  getCoupons,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  TitleWrapper,
  PageTitle,
  BodyWrapper,
  TextWrapper,
  Text,
  CartSummary,
  CartTitle,
  Divider,
  CartWrapper,
  Amount,
  StyledColoredButton,
} from './styles';

const CartPage = () => {
  const {
    productItems,
    productTotal,
    discountTotal,
    subtotal,
    totalQuantity,
    pending,
    taxAmount,
    shippingFee,
    estimatedTotal,
    result: { c_totals: cTotals },
  } = useSelector(getBasket);
  const coupons = useSelector(getCoupons);
  const couponError = useSelector(getCouponError);
  const dispatch = useAppDispatch();
  const { removeProductFromCart, addProductToWishlist, isLoading } =
    useBasket();
  const isOrderPossible =
    totalQuantity && areAllItemsInBasketAvailable(productItems);

  const updateItem = async (
    itemId: string,
    productId: string,
    updatedQuantity: number,
  ) => {
    try {
      await dispatch(
        AsyncThunks.updateItemInBasket({
          itemId,
          valuesToUpdate: {
            productId,
            quantity: updatedQuantity,
          },
        }),
      ).unwrap();
    } catch (e: any) {
      return e.detail;
    }
  };

  const onPromoCodeSubmit = useCallback(
    async (promoCode: string, cb: () => void) => {
      await dispatch(AsyncThunks.addCoupon(promoCode));
      cb();
    },
    [],
  );

  const handleCouponDelete = useCallback(async (id: string) => {
    await dispatch(AsyncThunks.removeCoupon(id));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shopping Cart</title>
        <meta name="description" content="Shopping Cart" />
      </Helmet>

      <TitleWrapper>
        <PageTitle>My Shopping Cart</PageTitle>
      </TitleWrapper>
      <BodyWrapper>
        <CartWrapper>
          {(isLoading || pending) && <Loader fullscreen />}
          {!totalQuantity && (
            <Typography type="subtitle1Helv">Your cart is empty</Typography>
          )}
          {!!totalQuantity && productItems.length
            ? productItems.map((item: BasketProductItemType, index: number) => {
                return (
                  <CartItem
                    cartItem={item}
                    key={index}
                    updateItem={updateItem}
                    removeFromCart={removeProductFromCart}
                    addToWishList={addProductToWishlist}
                  />
                );
              })
            : null}
        </CartWrapper>

        <CartSummary>
          <TextWrapper>
            <CartTitle>order summary</CartTitle>
          </TextWrapper>
          <PromoCodeForm
            onPromoCodeSubmit={onPromoCodeSubmit}
            couponError={couponError}
          />
          {coupons.length ? (
            <TextWrapper>
              <Text>Applied promo codes</Text>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {coupons.map((coupon: CouponItemType) => (
                  <PromoCode
                    handleCouponDelete={handleCouponDelete}
                    coupon={coupon}
                    key={coupon.couponItemId}
                  />
                ))}
              </div>
            </TextWrapper>
          ) : null}
          <TextWrapper>
            <Text>Product Total</Text>
            <Amount id="productTotal">{formatPrice(productTotal)}</Amount>
          </TextWrapper>
          <TextWrapper>
            <Text>Discount Total</Text>
            <Amount id="discountTotal">{formatPrice(discountTotal)}</Amount>
          </TextWrapper>
          <TextWrapper>
            <Text>Subtotal</Text>
            <Amount id="subTotal">{formatPrice(subtotal)}</Amount>
          </TextWrapper>
          <TextWrapper>
            <Text>Tax Amount</Text>
            <Amount id="taxAmount">{formatPrice(taxAmount || 0)}</Amount>
          </TextWrapper>
          <TextWrapper>
            <Text>Shipping Fee</Text>
            <Amount id="shippingFee">{formatPrice(shippingFee || 0)}</Amount>
          </TextWrapper>
          <Divider />
          <TextWrapper>
            <Text style={{ fontFamily: FONTS.Helvetica.bold }}>Total</Text>
            <Amount
              style={{ fontFamily: FONTS.Helvetica.bold }}
              id="totalAmount"
            >
              {formatPrice(estimatedTotal)}
            </Amount>
          </TextWrapper>
          <div style={{ margin: '20px 0' }}>
            {cTotals.discounts?.length
              ? cTotals.discounts.map(
                  (discount: { callOutMsg: string; UUID: string }) => (
                    <Typography key={discount.UUID} color="accent">
                      {discount.callOutMsg}
                    </Typography>
                  ),
                )
              : null}
          </div>
          <CustomNavLink
            to={isOrderPossible ? ROUTES.CHECKOUT : ''}
            style={{ width: '20%' }}
          >
            <StyledColoredButton
              disabled={!isOrderPossible}
              aria-label="checkout"
              id="checkout"
            >
              checkout
            </StyledColoredButton>
          </CustomNavLink>
        </CartSummary>
      </BodyWrapper>
    </>
  );
};

export default CartPage;
