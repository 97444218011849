import { TableCell, tableCellClasses, TableContainer } from '@mui/material';
import { Typography } from 'components';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    border: 'none',
  },
}));

export const AboutLoyaltyBlock = styled.div`
  display: grid;
  background: #dee4eb;
  font-family: ${FONTS.TTNorms.regular};
  font-size: 28px;
  line-height: 34px;
  margin-top: 20px;
  padding: 20px 80px;
  text-align: center;

  @media (max-width: 1000px) {
    padding: 20px;
    font-size: 20px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  padding: 10px 5vw;

  @media (max-width: 1000px) {
    padding: 10px 20px;
  }
`;

export const TableTab = styled.h3`
  min-width: 181px;
  min-height: 70px;
  font-family: ${FONTS.TTNorms.bold};
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0 22px;

  border: 1px solid #f0943e;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    min-width: 140px;
  }
`;

export const PointsTitle = styled.h4`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  font-family: ${FONTS.TTNorms.bold};
  color: ${COLORS.black}C4;
  margin: 20px 0 40px;

  @media (max-width: 1000px) {
    font-size: 12px;
    margin: 10px 0 20px;
  }
`;

export const TableTitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 18px;
  line-height: 31px;
  color: ${COLORS.black};

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const IconWrapper = styled.div`
  svg {
    width: 72px;
    height: 34px;
  }
`;
