import { Typography } from 'components';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const HeroBackgroundImage = styled.div<{
  imageUrl?: string;
}>`
  width: 100%;
  height: calc(100vh - ${HEADER_NAV_HEIGHT}px);
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  position: relative;
  background-size: cover;
  background-position: center;

  @media (max-width: 1000px) {
    background-position: right;
  } ;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  & svg {
    width: 160px;
    height: 160px;
  }

  @media (max-width: 1000px) {
    & svg {
      width: 100px;
      height: 100px;
    }
  }
`;

export const SectionTitle = styled(Typography)`
  background: ${COLORS.black};
  color: ${COLORS.white};
  text-align: center;
  font-family: ${FONTS.TTNorms.medium};
  font-size: 40px;
  text-transform: uppercase;
  padding: 8px 0;

  @media (max-width: 750px) {
    font-size: 28px;
  }
`;
