import { useEffect, useState } from 'react';

import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { ProductItemsType } from 'types/OrdersTypes';
import { getProductItemsUpdatedWithVariationAttributes } from 'eg_SFCC_FE_core/store/listeners/listener';
import { Typography, Loader } from 'components';
import { COLORS, FONTS } from 'styles';
import { Image, LoyaltyPointDetailsWrapper, Title } from './styles';

const LoyaltyPointDetails = ({ orderNumber }: { orderNumber: string }) => {
  const [orderData, setOrderData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useAppDispatch();

  const fetchOrderByOrderId = async () => {
    if (!orderNumber) return null;
    setErrorMessage('');

    setLoader(true);
    const order = await dispatch(AsyncThunks.getCustomerOrderById(orderNumber));

    // @ts-ignore
    if (order?.error) {
      setErrorMessage(order?.payload.title);
      setLoader(false);
    }

    const updated = await getProductItemsUpdatedWithVariationAttributes(
      order?.payload?.productItems,
      'medium',
    );

    setOrderData(updated);
    setLoader(false);
  };

  useEffect(() => {
    fetchOrderByOrderId();
  }, []);

  return (
    <div>
      {orderNumber && (
        <Typography
          style={{
            fontSize: 16,
            fontFamily: FONTS.TTNorms.bold,
            marginBottom: 20,
          }}
        >
          INCLUDED IN YOUR ORDER
        </Typography>
      )}
      {errorMessage && (
        <Typography style={{ color: COLORS.accent }}>{errorMessage}</Typography>
      )}
      {loader && <Loader />}
      {orderData?.map((product: ProductItemsType, index: number) => (
        <LoyaltyPointDetailsWrapper key={index}>
          <div style={{ display: 'flex', gap: 20, maxWidth: 400 }}>
            {product?.image ? (
              <Image src={product.image.link} alt={product.image.alt} />
            ) : (
              <div
                style={{
                  width: '120px',
                  height: '120px',
                  border: `1px solid ${COLORS.border}`,
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Title style={{ textTransform: 'none', opacity: 0.5 }}>
                  No image
                </Title>
              </div>
            )}
            <div style={{ flex: 1 }}>
              <Typography type="subtitle2" style={{ fontSize: 15 }}>
                {product?.c_marketplacerSellerName.toUpperCase()}
              </Typography>
              <Title>{product?.productName.toLowerCase()}</Title>
              <Title>{product?.variationValues?.color}</Title>
              <Title>{product?.variationValues?.size}</Title>
            </div>
          </div>

          <Typography style={{ fontSize: 16 }}>
            +{Math.round(product?.price)} Points
          </Typography>
        </LoyaltyPointDetailsWrapper>
      ))}
    </div>
  );
};

export default LoyaltyPointDetails;
