/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LabelCheckbox, Typography } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { submitInquiryFormSchema } from 'helpers/validationSchemas';
import { isProd } from 'appConstants';
import { generateCSRFToken, postSFForm } from 'eg_SFCC_FE_core/axios/api';
import { COLORS, FONTS } from 'styles';
import { FieldsBox, FormWrapper } from '../styles';
import { StyledColoredButton } from './styles';

const errorMessages: any = {
  missing_fields: 'Some fields are missing',
  duplicate_record: 'Duplicate form submission',
  invalid_field: 'Invalid field',
  invalid_type_fields: 'Invalid type fields',
  service_error: 'Service error',
  invalid_type:
    'For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience',
};

type FormValues = {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  website: string;
  instagram: string;
  tikTok: string;
  blackOwned: boolean;
};

const webToLeadStageVars = {
  orgId: '00D530000008gf1',
  retURLValue: 'https://stg.shopwitheg.com/%22',
  keyName: 'Web2CaseForm',
};

const webToLeadProdVars = {
  orgId: '00D5Y00000264i7',
  retURLValue: 'http://www.empowerglobal.shop',
  keyName: 'Web2CasePROD',
};

const WebToLeadForm = ({
  recaptchaToken,
}: {
  recaptchaToken: string | undefined;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [recaptchaMessage, setRecaptchaMessageMessage] = useState<string>();
  const [isLoading, setLoader] = useState<boolean>(false);
  const webToLeadVars = isProd ? webToLeadProdVars : webToLeadStageVars;

  const defaultValues = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    website: '',
    instagram: '',
    tikTok: '',
    blackOwned: isChecked,
  };

  const methods = useForm<FormValues>({
    resolver: yupResolver(submitInquiryFormSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (
    data: FormValues,
    e: any,
  ) => {
    setLoader(true);

    if (!recaptchaToken) {
      setRecaptchaMessageMessage('Verify recaptcha');
      setLoader(false);
      return;
    }
    setRecaptchaMessageMessage('');

    const { token } = await generateCSRFToken();

    try {
      const response = await postSFForm(
        'Lead',
        {
          FirstName: data.first_name,
          LastName: data.last_name,
          Brand_Name__c: data.company,
          Email: data.email,
          Website: data.website,
          Instagram__c: data.instagram,
          TikTok__c: data.tikTok,
          My_business_is_more_than_51_Black_Owned__c: isChecked ? 'yes' : 'no',
        },
        recaptchaToken,
        token,
      );

      if (
        response.sendForm.statusCode === 200 &&
        response.sendForm.status === 'success'
      ) {
        setErrorMessage('The form was submitted');
        methods.reset();
      }
    } catch (err: any) {
      setErrorMessage(errorMessages[err.response.data.sendForm.status]);
    } finally {
      setLoader(false);
      window.googleRecaptchaToken = '';
    }

    e.target.reset();
  };

  const onError = () => {};

  return (
    <FormWrapper
      id="web-to-lead-form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
      style={{ gap: 15 }}
    >
      <input
        type="hidden"
        name="captcha_settings"
        value={`{"keyname":"${webToLeadVars.keyName}","fallback":"true","orgId":"${webToLeadVars.orgId}","ts":""}`}
      />
      <input type="hidden" name="oid" value={webToLeadVars.orgId} />
      <input type="hidden" name="retURL" value={webToLeadVars.retURLValue} />
      <FieldsBox>
        <FormInputController
          control={control}
          name="first_name"
          label="First Name*"
          error={!!errors.first_name}
          errorMessage={errors?.first_name && errors.first_name.message}
        />
        <FormInputController
          control={control}
          name="last_name"
          label="Last Name*"
          error={!!errors.last_name}
          errorMessage={errors?.last_name && errors.last_name.message}
        />
      </FieldsBox>
      <FormInputController
        control={control}
        name="company"
        label="Company*"
        error={!!errors.company}
        errorMessage={errors?.company && errors.company.message}
      />
      <FormInputController
        control={control}
        name="email"
        label="Email*"
        error={!!errors.email}
        errorMessage={errors?.email && errors.email.message}
      />
      <FormInputController
        control={control}
        name="website"
        label="Website"
        error={!!errors.website}
        errorMessage={errors?.website && errors.website.message}
      />
      <FormInputController
        control={control}
        name="instagram"
        label="Instagram"
        error={!!errors.instagram}
        errorMessage={errors?.instagram && errors.instagram.message}
      />
      <FormInputController
        control={control}
        name="tikTok"
        label="TikTok"
        error={!!errors.tikTok}
        errorMessage={errors?.tikTok && errors.tikTok.message}
      />
      <LabelCheckbox
        label="My business is more than 51% Black Owned "
        handleChange={() => setIsChecked(!isChecked)}
        checked={isChecked}
      />

      {recaptchaMessage ? (
        <Typography style={{ fontFamily: FONTS.TTNorms.medium, fontSize: 13 }}>
          {recaptchaMessage}
        </Typography>
      ) : null}
      <StyledColoredButton
        type="submit"
        form="web-to-lead-form"
        aria-label="submit"
        isLoading={isLoading}
      >
        Submit
      </StyledColoredButton>
      {errorMessage ? (
        <Typography style={{ color: COLORS.accent }}>{errorMessage}</Typography>
      ) : null}
    </FormWrapper>
  );
};
export default WebToLeadForm;
