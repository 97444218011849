import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Loader } from 'components';
import BrandMainSection from 'components/BrandLandingComponents/BrandMainSection/BrandMainSection';
import { useBrandsFavoritesList } from 'hooks/useWishList';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import {
  getBrand,
  getBrandLoader,
  getBrandProductList,
} from 'eg_SFCC_FE_core/store/selectors';
import useGoogleTags from 'hooks/useGoogleTags';
import FeaturedProducts from 'components/BrandsComponents/FeaturedProducts/FeaturedProducts';
import { BrandType } from '../../types/BrandsTypes';

const BrandLandingPage = () => {
  const dispatch = useAppDispatch();
  const { brandId } = useParams();
  const brand = useSelector<any, BrandType>(getBrand);
  const isLoading = useSelector(getBrandLoader);
  const productList = useSelector(getBrandProductList);
  const { isBrandFavorite, updateFavoriteBrands } = useBrandsFavoritesList();
  const isFavorite = isBrandFavorite(brandId || '');
  const { addPageView } = useGoogleTags();

  const fetchBrand = useCallback(async () => {
    if (!brandId) return;
    await dispatch(AsyncThunks.getBrandById(brandId));
  }, [dispatch, brandId]);

  const fetchProductList = async () => {
    await dispatch(
      AsyncThunks.getProductListByBrand({
        sellerName: brand?.businessName,
        offset: 0,
        limit: 12,
      }),
    );
  };

  const handleLikeIconClick = useCallback(async () => {
    if (brandId) {
      return updateFavoriteBrands(brandId);
    }
  }, [brandId, updateFavoriteBrands]);

  useEffect(() => {
    if (brand?.id !== brandId) {
      fetchBrand();
    }
    fetchProductList();

    if (brand?.id === brandId) {
      addPageView('brand', { brand: brand.businessName });
    }
  }, [brand]);

  if (isLoading || !brand) return <Loader />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brand</title>
        <meta name="description" content="Brand" />
      </Helmet>
      <BrandMainSection
        brand={brand}
        isFavorite={isFavorite}
        handleLikeIconClick={handleLikeIconClick}
      />
      <FeaturedProducts
        productList={productList}
        brand={{ brandId: brand?.id, brandName: brand?.businessName }}
      />
    </>
  );
};

export default BrandLandingPage;
