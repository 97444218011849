import styled from 'styled-components';

import { ColoredButton } from 'components';
import { COLORS, FONTS } from 'styles';

const AccentColoredButton = styled(ColoredButton).attrs({
  height: 'auto',
})<{
  padding?: string;
}>`
  width: fit-content;
  background-color: ${COLORS.accent};
  padding: ${({ padding }) => padding || '18px 50px'};
  transition: 0.3s;

  & p {
    color: ${COLORS.black};
    font-family: ${FONTS.TTNorms.medium};
  }
`;

export default AccentColoredButton;
