import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components';
import SavedSearch from 'components/SavedSearchesComponents/SavedSearch';
import { SearchIcon } from 'assets/svg';
import {
  getCustomerData,
  getSavedSearchResults,
  getSavedSearchResultsLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { SavedSearchQueryType } from 'types/AccountTypes';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useConfirmationModal } from 'hooks/useModals';
import { SavedSearchResultType } from 'types/SearchTypes';
import { BlockTitle, RightBlockWrapper } from './styles';

const SavedSearchesPage = () => {
  const dispatch = useAppDispatch();
  const customerData = useSelector(getCustomerData);
  const savedSearchResults = useSelector(getSavedSearchResults);
  const isLoading = useSelector(getSavedSearchResultsLoader);
  const openConfirmationModal = useConfirmationModal();

  const fetchSavedSearchResults = async () => {
    await Promise.all(
      customerData?.c_savedSearchJson.map(
        async (savedSearch: SavedSearchQueryType) => {
          await dispatch(AsyncThunks.getSavedSearchResult(savedSearch));
        },
      ),
    );
  };

  const deleteSavedSearchQuery = async (id: string) => {
    return dispatch(AsyncThunks.removeSavedSearch(id));
  };

  const handleDeleteSavedSearchQuery = useCallback((id: string) => {
    openConfirmationModal({
      Icon: SearchIcon,
      title: 'Remove saved search',
      text: 'Are you sure you want to remove saved search?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Remove',
      confirmButtonAction: deleteSavedSearchQuery.bind(null, id),
    });
  }, []);

  useEffect(() => {
    if (customerData?.c_savedSearchJson) {
      fetchSavedSearchResults();
    }
  }, [customerData.c_savedSearchJson]);

  return (
    <RightBlockWrapper style={{ paddingLeft: 0 }}>
      <BlockTitle>Saved Searches</BlockTitle>
      {customerData?.c_savedSearchJson?.length
        ? customerData?.c_savedSearchJson.map(
            (savedSearch: SavedSearchQueryType) => {
              return (
                <SavedSearch
                  key={savedSearch.c_id}
                  query={savedSearch}
                  savedSearchResult={savedSearchResults.find(
                    (savedSearchResult: SavedSearchResultType) =>
                      savedSearchResult.query === savedSearch.c_q,
                  )}
                  deleteSavedSearchQuery={handleDeleteSavedSearchQuery}
                />
              );
            },
          )
        : 'No saved searches'}
      {isLoading ? <Loader fullscreen /> : null}
    </RightBlockWrapper>
  );
};

export default SavedSearchesPage;
