import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div<{
  verticalPadding?: number;
}>`
  padding: ${({ verticalPadding }) => {
    return verticalPadding ? `${verticalPadding}px 20px` : '40px 20px';
  }};
`;

export const Title = styled.h1`
  background: ${COLORS.black};
  color: ${COLORS.white};
  text-align: center;
  font-family: ${FONTS.TTNorms.medium};
  font-size: 40px;
  text-transform: uppercase;
  padding: 8px 0;
  margin: 0 -20px 10px;

  @media (max-width: 750px) {
    font-size: 28px;
  }
`;

export const ProductCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProductCardWrapper = styled.div`
  width: 31%;

  @media (max-width: 1000px) {
    width: 46%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
