import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomNavLink, TextButton } from 'components';
import CartContent from 'components/Modals/ModalContent/CartContent';
import HamburgerMenu from 'components/Dropdowns/Menus/HamburgerMenu';
import NavigationMenu from 'components/Dropdowns/Menus/NavigationMenu';
import ROUTES from 'router/Routes';
import { CartIcon, HeartIcon, SearchIcon } from 'assets/svg';
import useDimensions from 'hooks/useDimensions';
import useHeader from 'hooks/useHeader';
import { useAuthModals } from 'hooks/useModals';
import { catalog, meganavCatalog } from 'data/catalog';
import { CatalogItemType, ExtendedCategoryType } from 'types/CatalogTypes';
import { BREAKPOINTS, COLORS } from 'styles';

import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getIsLoggedIn,
  getCategories,
  getBasket,
} from 'eg_SFCC_FE_core/store/selectors';
import useBasket from 'hooks/useBasket';
import {
  Wrapper,
  IconsBlock,
  Icon,
  RightBlockWrapper,
  StyledSearchIcon,
  TextButtonWrapper,
  CartItemsNumber,
  StyledHeartIcon,
  StyledEGLogoIcon,
  LeftBlockWrapper,
  StyledUpdatedLogoIcon,
} from './styles';

const Header = () => {
  const [catalogData, setCatalogData] = useState<CatalogItemType[]>();
  const [navigationMenuData, setNavigationMenuData] =
    useState<CatalogItemType[]>();
  const { width } = useDimensions();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isHeaderSticky, isHeaderTransparent, hasHeaderWhiteMenuText } =
    useHeader();
  const { showLoginModal } = useAuthModals();
  const { removeProductFromCart, addProductToWishlist } = useBasket();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const categories = useSelector(getCategories);
  const { totalQuantity } = useSelector(getBasket);

  const showCartModal = () => {
    if (!totalQuantity) return;

    dispatch(
      modalActions.showModal({
        id: 'BASKET_MODAL',
        modalContent: (
          <CartContent
            closeModal={() => dispatch(modalActions.hideModal('BASKET_MODAL'))}
            removeFromCart={removeProductFromCart}
            addToWishList={addProductToWishlist}
          />
        ),
        boxStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          width: width > 1000 ? '40%' : '100%',
          backgroundColor: `${COLORS.white}`,
        },
      }),
    );
  };

  const logout = async () => {
    navigate(ROUTES.HOME);
    await dispatch(AsyncThunks.logoutCustomer());
  };

  const wishlistAction = isLoggedIn
    ? () => navigate(ROUTES.FAVORITES)
    : showLoginModal;

  useEffect(() => {
    if (categories) {
      const updateData = (data: any, source: any) =>
        data.map((catalogItem: CatalogItemType) => {
          return {
            ...catalogItem,
            categories: source?.find((cat: ExtendedCategoryType) => {
              return cat.id === catalogItem.id;
            })?.categories,
          };
        });

      const updatedCatalog = updateData(catalog, categories.hamburgerMenu);
      const updatedNavigation = updateData(
        meganavCatalog,
        categories.navigationMenu,
      );

      setCatalogData(updatedCatalog);
      setNavigationMenuData(updatedNavigation);
    }
  }, [categories]);

  const isMobile = width < BREAKPOINTS.xs;

  return (
    <Wrapper
      isTransparent={isHeaderTransparent}
      isHeaderSticky={isHeaderSticky}
      hasHeaderWhiteMenuText={hasHeaderWhiteMenuText}
    >
      {isMobile ? (
        <CustomNavLink to={ROUTES.HOME} style={{ display: 'flex' }}>
          <StyledEGLogoIcon title="Empower Global Logo" />
        </CustomNavLink>
      ) : (
        <LeftBlockWrapper>
          <TextButtonWrapper>
            <NavigationMenu catalogData={navigationMenuData} />
          </TextButtonWrapper>

          <TextButtonWrapper>
            <CustomNavLink to={ROUTES.BRANDS}>
              <TextButton
                aria-label="meet the brands"
                onClick={() => {}}
                label="meet the brands"
              />
            </CustomNavLink>
          </TextButtonWrapper>

          <TextButtonWrapper>
            <CustomNavLink to={ROUTES.ABOUT}>
              <TextButton aria-label="about" onClick={() => {}} label="about" />
            </CustomNavLink>
          </TextButtonWrapper>
        </LeftBlockWrapper>
      )}

      {isMobile ? null : (
        <CustomNavLink to={ROUTES.HOME} style={{ display: 'flex' }}>
          <StyledUpdatedLogoIcon title="Empower Global Logo" />
        </CustomNavLink>
      )}

      <RightBlockWrapper>
        <IconsBlock isLoggedIn={isLoggedIn}>
          {isLoggedIn || isMobile ? null : (
            <TextButtonWrapper>
              <TextButton
                aria-label="login"
                onClick={showLoginModal}
                label="login"
              />
            </TextButtonWrapper>
          )}
          <StyledHeartIcon onClick={wishlistAction} id="favorites">
            <HeartIcon />
          </StyledHeartIcon>
          <StyledSearchIcon id="search">
            <CustomNavLink to={ROUTES.SEARCH}>
              <SearchIcon />
            </CustomNavLink>
          </StyledSearchIcon>
          {!totalQuantity && isMobile ? null : (
            <Icon
              onClick={showCartModal}
              style={{ position: 'relative' }}
              id="cart"
            >
              <CartIcon />
              <CartItemsNumber>{totalQuantity}</CartItemsNumber>
            </Icon>
          )}
          {!isMobile ? null : (
            <Icon id="hamburger-menu">
              <HamburgerMenu
                catalogData={catalogData}
                isLoggedIn={isLoggedIn}
                logout={logout}
              />
            </Icon>
          )}
        </IconsBlock>
      </RightBlockWrapper>
    </Wrapper>
  );
};

export default memo(Header);
