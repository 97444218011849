import { isDev } from 'appConstants';

export const exploreFeaturesBrands: any[] = [
  {
    id: isDev ? 'U2VsbGVyLTE5Ng==' : 'U2VsbGVyLTE1OQ==',
    businessName: 'Actively Black Inc.',
  },
  {
    id: isDev ? 'U2VsbGVyLTIwNA==' : 'U2VsbGVyLTExNA==',
    businessName: 'ANOVE',
  },
  {
    id: isDev ? 'U2VsbGVyLTIwNQ==' : 'U2VsbGVyLTExMQ==',
    businessName: 'AQUA Waterproof Headwear',
  },
  {
    id: isDev ? 'U2VsbGVyLTkw' : 'U2VsbGVyLTkw',
    businessName: 'Black Travel Box',
  },
  {
    id: isDev ? 'U2VsbGVyLTkx' : 'U2VsbGVyLTkx',
    businessName: 'Breukelen Polished',
  },
  {
    id: isDev ? 'U2VsbGVyLTE1Nw==' : 'U2VsbGVyLTE1Nw==',
    businessName: 'Buttah Skin',
  },
  {
    id: isDev ? 'U2VsbGVyLTEyNA==' : 'U2VsbGVyLTEyNA==',
    businessName: 'Cise',
  },
  {
    id: isDev ? 'U2VsbGVyLTc3' : 'U2VsbGVyLTc3',
    businessName: 'Coco and Breezy',
  },
  {
    id: isDev ? 'U2VsbGVyLTQ2' : 'U2VsbGVyLTE4MQ==',
    businessName: 'Courtney Noelle',
  },
  {
    id: isDev ? 'U2VsbGVyLTIwMg==' : 'U2VsbGVyLTE4Mw==',
    businessName: 'DOMI by Chloe',
  },
  {
    id: isDev ? 'U2VsbGVyLTQ3' : 'U2VsbGVyLTQ3',
    businessName: 'Glamazon Beauty',
  },
  {
    id: isDev ? 'U2VsbGVyLTU3' : 'U2VsbGVyLTU3',
    businessName: 'Gwen Belotti Collection',
  },
  {
    id: isDev ? 'U2VsbGVyLTE0OA==' : 'U2VsbGVyLTE0OA==',
    businessName: 'Itadi',
  },
  {
    id: isDev ? 'U2VsbGVyLTU4' : 'U2VsbGVyLTE5OQ==',
    businessName: 'Kushae',
  },
  {
    id: isDev ? 'U2VsbGVyLTExMA==' : 'U2VsbGVyLTExMA==',
    businessName: 'Marie Hunter Beauty',
  },
  {
    id: isDev ? 'U2VsbGVyLTk2' : 'U2VsbGVyLTk2',
    businessName: 'Miskeen',
  },
  {
    id: isDev ? 'U2VsbGVyLTE0Mg==' : 'U2VsbGVyLTE0Mg==',
    businessName: 'Olori',
  },
  {
    id: isDev ? 'U2VsbGVyLTExNw==' : 'U2VsbGVyLTExNw==',
    businessName: 'Rebecca Allen',
  },
  {
    id: isDev ? 'U2VsbGVyLTk4' : 'U2VsbGVyLTk4',
    businessName: 'Silver & Riley',
  },
  {
    id: isDev ? 'U2VsbGVyLTE1NA==' : 'U2VsbGVyLTE1NA==',
    businessName: 'Sucre couture',
  },
  {
    id: isDev ? 'U2VsbGVyLTEzMw==' : 'U2VsbGVyLTEzMw==',
    businessName: 'Urban hydration',
  },
  {
    id: isDev ? 'U2VsbGVyLTEzNw==' : 'U2VsbGVyLTEzNw==',
    businessName: 'Vanity Couture',
  },
  {
    id: isDev ? 'U2VsbGVyLTEzNg==' : 'U2VsbGVyLTEzNg==',
    businessName: 'Vontélle Eyewear',
  },
  {
    id: isDev ? 'U2VsbGVyLTgz' : 'U2VsbGVyLTgz',
    businessName: 'WHTART',
  },
];

export const trending = [
  {
    id: isDev ? '100000148' : '100002526',
  },
  {
    id: isDev ? '1179' : '14164',
  },
  {
    id: isDev ? '1156' : '100000785',
  },
  {
    id: isDev ? '852' : '100001932',
  },
  {
    id: isDev ? '100000297' : '100004818',
  },
  {
    id: isDev ? '100000009' : '100003853',
  },
];

export const popularCategories = [
  {
    id: isDev ? '5' : '395',
    name: 'JACKETS',
    image: 'assets/png/explore/popular-categories1.png',
    alt: 'Blue jacket with flowers',
  },
  {
    id: isDev ? '132' : '381',
    name: 'HATS',
    image: 'assets/png/explore/popular-categories2.png',
    alt: 'Off white fedora hat with tan bow',
  },
  {
    id: isDev ? '142' : '177',
    name: 'SKIN CARE',
    image: 'assets/png/explore/popular-categories3.png',
    alt: 'Applying skin care product to black hand ',
  },
  {
    id: isDev ? '170' : '174',
    name: 'PHOTOGRAPHY',
    image: 'assets/png/explore/popular-categories4.png',
    alt: "Photo of a woman's silhouette ",
  },
];

export const pressSlides = [
  {
    id: 1,
    quote:
      '“Building Black wealth starts with investing in Black-owned businesses and giving entrepreneurs access to the consumers needed to build sustainable companies that can thrive.”',
    author: 'Sean Combs - Chairman and CEO, Combs Global',
    image: 'assets/png/about/press-logo2.png',
    background: 'assets/jpg/about/press-background2.jpg',
    link: 'https://www.vibe.com/news/tech/diddy-salesforce-shop-circulate-black-owned-businesses-1234620206/',
    shortenedAuth: 'Sean Combs',
    pub: 'Vibe',
  },
  {
    id: 2,
    quote:
      '“The wealth gap is growing and a big wealth driver in communities is the entrepreneurs in those communities. We want the ability to give entrepreneurs a stage and to transact with people around the world.”',
    author: 'Tarik Brooks - President, Combs Global',
    image: 'assets/png/about/press-logo1.png',
    background: 'assets/jpg/about/press-background1.jpg',
    link: 'https://www.forbes.com/sites/forbesfellows/2021/06/03/combs-enterprises-announces-shop-circulate-a-digital-marketplace-for-black-owned-businesses/?sh=5a3c0fec4aed',
    shortenedAuth: 'Tarik Brooks',
    pub: 'Forbes Magazine',
  },
  {
    id: 3,
    quote:
      '“We’ve always moved culture. So now we are going to be moving dollars and circulating those dollars within our community.”',
    author: 'Khadijah Robinson - CEO, Empower Global',
    image: 'assets/png/about/press-logo3.png',
    background: 'assets/jpg/about/press-background3.jpg',
    link: 'https://thegrio.com/2021/06/02/woman-working-diddy-revolutionize-buying-black/',
    shortenedAuth: 'Khadijah Robinson',
    pub: 'the Grio',
  },
];
