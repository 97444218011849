import styled from 'styled-components';

import { Typography } from 'components';
import { CrossIcon, EGLogoIcon, UpdatedLogoIcon } from 'assets/svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

const AUTH_WRAPPER_STYLES = {
  login: {
    desktopWidth: '510px',
    mobileWidth: '375px',
    desktopPaddings: '45px 55px',
    mobilePaddings: '32px 42px 48px',
  },
  registration: {
    desktopWidth: '560px',
    mobileWidth: '375px',
    desktopPaddings: '43px 28px 62px',
    mobilePaddings: '28px 38px 18px',
  },
};

export const Wrapper = styled.div<{
  isLogin: boolean;
}>`
  width: 100%;
  max-height: ${window.innerHeight - 50}px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isLogin }) =>
    isLogin
      ? AUTH_WRAPPER_STYLES.login.desktopPaddings
      : AUTH_WRAPPER_STYLES.registration.desktopPaddings};
  width: ${({ isLogin }) =>
    isLogin
      ? AUTH_WRAPPER_STYLES.login.desktopWidth
      : AUTH_WRAPPER_STYLES.registration.desktopWidth};

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: ${({ isLogin }) =>
      isLogin
        ? AUTH_WRAPPER_STYLES.login.mobilePaddings
        : AUTH_WRAPPER_STYLES.registration.mobilePaddings};
    width: ${({ isLogin }) =>
      isLogin
        ? AUTH_WRAPPER_STYLES.login.mobileWidth
        : AUTH_WRAPPER_STYLES.registration.mobileWidth};
  }
`;

export const LogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-bottom: 33px;
  }
`;

export const LogoIcon = styled(EGLogoIcon)`
  width: 70px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 32px;
    margin-bottom: 10px;
  }
`;

export const Logo = styled(UpdatedLogoIcon)`
  width: 220px;
  height: auto;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 102px;
  }
`;

export const Cross = styled(CrossIcon)`
  position: absolute;
  top: 26px;
  right: 32px;
  cursor: pointer;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.s}px) {
    top: 22px;
    right: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AuthTitle = styled(Typography).attrs({ type: 'title2Helv' })`
  width: 100%;
  margin: 12px 0 25px;
  text-transform: uppercase;
  text-align: start;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
    line-height: 19px;
    margin: 12px 0 10px;
  }
`;

export const LoginTitle = styled(Typography)`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 14px;
  margin-bottom: 16px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    margin-bottom: 9px;
  }
`;

export const Btn = styled.button`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 12px;
  color: ${COLORS.black};
  opacity: 0.6;
  transition: 0.3s;
  background: none;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;

export const SkipBtn = styled(Btn)`
  color: ${COLORS.black}99;
  text-transform: uppercase;
  margin-right: 10px;

  @media (max-width: 650px) {
    font-size: 14px;
  }
`;

export const AuthFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;
