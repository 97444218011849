import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { find } from 'lodash';

import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import { ErrorResponse, CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { checkIsAddressExists } from 'eg_SFCC_FE_core/axios/helpers';

export const createCustomerAddressThunk: AsyncThunkPayloadCreator<
  any,
  CustomerAddressType,
  { rejectValue: ErrorResponse; state: RootState }
> = async (customerAddress, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const {
      customerData: { addresses },
    } = state.customerReducer;

    const isDuplicate = checkIsAddressExists(addresses, customerAddress);

    if (isDuplicate) throw new Error('duplicate_address');
  } catch (error: any) {
    return rejectWithValue({
      statusCode: error.message,
      message: 'Duplicate address',
    });
  }

  try {
    const state = getState();
    const { customerId } = state.customerReducer;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.post(
      endpoints.customer.customerAddress(customerId),
      customerAddress,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const removeCustomerAddressThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse; state: RootState }
> = async (addressId, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const { customerId } = state.customerReducer;
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    await axiosInstance.delete(
      `${endpoints.customer.customerAddress(customerId)}/${addressId}`,
      { params },
    );

    return addressId;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateCustomerAddressThunk: AsyncThunkPayloadCreator<
  any,
  { addressId: string; customerAddress: CustomerAddressType },
  { rejectValue: ErrorResponse; state: RootState }
> = async ({ addressId, customerAddress }, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const { customerId } = state.customerReducer;
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.patch(
      `${endpoints.customer.customerAddress(customerId)}/${addressId}`,
      customerAddress,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const setDefaultAddressThunk: AsyncThunkPayloadCreator<
  any,
  { addressId: string; customerAddress: CustomerAddressType },
  { rejectValue: ErrorResponse; state: RootState }
> = async ({ addressId, customerAddress }, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const {
      customerId,
      customerData: { addresses },
    } = state.customerReducer;
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const defaultAddress = find(addresses, { preferred: true });

    // set address as default
    const defaultAddressResponse = await axiosInstance.patch(
      `${endpoints.customer.customerAddress(customerId)}/${addressId}`,
      {
        ...customerAddress,
        preferred: true,
      },
      { params },
    );

    if (!defaultAddress) {
      return [defaultAddressResponse.data];
    }

    // set previous default address as normal
    const prevDefaultAddressResponse = await axiosInstance.patch(
      `${endpoints.customer.customerAddress(customerId)}/${
        defaultAddress.addressId
      }`,
      {
        ...defaultAddress,
        preferred: false,
      },
      { params },
    );

    return [defaultAddressResponse.data, prevDefaultAddressResponse.data];
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
