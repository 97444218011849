import { MouseEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthenticationParamsType } from 'eg_SFCC_FE_core/types';
import { loginFormSchema } from 'helpers/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';

import { LabelCheckbox } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
// import SocialMediaBtns from 'components/FormComponents/SocialMediaBtns';
// import AuthFormDivider from 'components/FormComponents/AuthFormDivider';
import { SocialAuthType } from 'types/AccountTypes';
import { AuthFormWrapper, ErrorMessage, Btn, AuthButton } from '../styles';

interface State {
  password: string;
  email: string;
}

const LogInForm = ({
  loginCustomer,
  loginCustomerViaSocials,
  authError,
  isLoading,
  showForgotPasswordModal,
}: {
  loginCustomer: (params: AuthenticationParamsType) => void;
  loginCustomerViaSocials: (type: SocialAuthType) => void;
  authError: any;
  isLoading: boolean;
  showForgotPasswordModal: () => void;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const values = {
    password: '',
    email: '',
  };

  const methods = useForm<State>({
    resolver: yupResolver(loginFormSchema),
    defaultValues: values,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<State> = (data: State, e: any) => {
    data &&
      loginCustomer({
        password: data.password,
        username: data.email,
      });
    e.target.reset();
  };

  const onError = () => {};

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <AuthFormWrapper
      isLogin
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      {authError && <ErrorMessage>{authError.message}</ErrorMessage>}

      <FormInputController
        control={control}
        name="email"
        label="Email*"
        error={!!errors.email}
        errorMessage={errors?.email?.message}
      />
      <FormInputController
        control={control}
        name="password"
        type={showPassword ? 'text' : 'password'}
        label="Password*"
        error={!!errors.password}
        errorMessage={errors?.password?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormGroup
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <LabelCheckbox
          label="Remember me"
          checked={remember}
          handleChange={() => setRemember(!remember)}
        />
        <Btn type="button" onClick={showForgotPasswordModal}>
          Forgot Password?
        </Btn>
      </FormGroup>

      <AuthButton type="submit" aria-label="login" isLoading={isLoading}>
        sign in
      </AuthButton>

      {/* <AuthFormDivider /> */}
      {/* <SocialMediaBtns handleClick={loginCustomerViaSocials} /> */}
    </AuthFormWrapper>
  );
};

export default LogInForm;
