import { Dispatch, SetStateAction } from 'react';
import { ProductItemPrice } from 'components';
import ReturnQuantitySelect from 'components/Dropdowns/Selects/ReturnQuantitySelect';
import { formatNameForUrl } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { IMarketPlacerLineItems, ProductItemsType } from 'types/OrdersTypes';
import { parseMarketplacerData, parseStatus } from 'helpers/utils';
import { chain } from 'lodash';
import {
  CardBodyWrapper,
  CardImage,
  PriceWrapper,
  ProductInfoContainer,
  ProductTitle,
  ProductLink,
  TextWrapper,
  Title,
  ProductBrandName,
  ReturnStatus,
  ReturnStatusesWrapper,
} from './styles';

const OrderCardItem = ({
  productItem,
  maxQuantity,
  quantity,
  setQuantity,
  quantityToReturn,
  withStatus = true,
}: {
  productItem: ProductItemsType;
  // maxQuantity is used for dynamic quantity selection when returning
  maxQuantity?: number;
  quantity?: number;
  setQuantity?: Dispatch<SetStateAction<number>>;
  quantityToReturn?: number;
  withStatus?: boolean;
}) => {
  const marketplacerReturnStatus = parseMarketplacerData(
    productItem?.c_returnStatus || '',
  );
  const isItemReturnable = marketplacerReturnStatus?.eligible;
  const marketplacerLineItems: IMarketPlacerLineItems[] = parseMarketplacerData(
    productItem.c_marketplacerLineItems || '',
  );

  const statuses = chain(marketplacerLineItems)
    .map((item: IMarketPlacerLineItems) => {
      // item was cancelled
      if (!item.shipment) {
        if (item.returnInfo?.refundRequests.length) {
          return `${item.returnInfo?.refundRequests[0].reason}.
            ${parseStatus(
              item.returnInfo?.refundRequests[0].status || '',
            )}`.trim();
        }
      }

      // item was returned
      if (item.returnInfo?.refundRequests.length) {
        return item.returnInfo?.refundRequests.map((request) => {
          return `${request.reason}. ${parseStatus(request.status || '')}. ${
            request.denyRefundReason ? request.denyRefundReason : ''
          }`.trim();
        });
      }

      return '';
    })
    .flatten()
    .compact()
    .value();

  return (
    <CardBodyWrapper>
      {productItem.image ? (
        <CardImage src={productItem.image?.link} alt={productItem.image?.alt} />
      ) : (
        <div
          style={{
            width: '115px',
            height: '105px',
            border: '1px solid grey',
            marginRight: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title style={{ textTransform: 'none' }}>No image</Title>
        </div>
      )}
      <TextWrapper>
        <div>
          <ReturnStatusesWrapper>
            {!isItemReturnable && withStatus
              ? statuses.map((status: string, index: number) => (
                  <ReturnStatus key={index}>
                    {statuses.length > 1
                      ? `Item ${index + 1}: ${status}`
                      : status}
                  </ReturnStatus>
                ))
              : null}
          </ReturnStatusesWrapper>
          <ProductBrandName>
            {productItem.c_marketplacerSellerName}
          </ProductBrandName>
          <ProductTitle>
            <ProductLink
              href={`${ROUTES.PRODUCTS}/${formatNameForUrl(
                productItem.productName,
              )}-${productItem.productId}`}
            >
              {productItem.productName}
            </ProductLink>
          </ProductTitle>
          <PriceWrapper>
            <ProductItemPrice
              price={productItem.price}
              priceAfterOrderDiscount={productItem.priceAfterOrderDiscount}
              priceAfterItemDiscount={productItem.priceAfterItemDiscount}
              discountedPrice={productItem.discountedPrice}
              originalPrice={productItem.originalPrice}
            />
          </PriceWrapper>
          <ProductInfoContainer>
            {productItem.variationValues?.color && (
              <div>
                <Title>Color: </Title>
                <Title>{productItem.variationValues?.color}</Title>
              </div>
            )}
            {productItem.variationValues?.size && (
              <div>
                <Title>Size: </Title>
                <Title>{productItem.variationValues?.size}</Title>
              </div>
            )}
            <div>
              <Title>Quantity: </Title>
              {maxQuantity && quantity && setQuantity && isItemReturnable ? (
                <ReturnQuantitySelect
                  maxQuantity={maxQuantity}
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
              ) : (
                <Title>{quantityToReturn || productItem.quantity}</Title>
              )}
            </div>
          </ProductInfoContainer>
        </div>
      </TextWrapper>
    </CardBodyWrapper>
  );
};

export default OrderCardItem;
