import { useState } from 'react';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup';

import { ColoredButton } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import PasswordCriteria from 'components/FormComponents/PasswordCriteria';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
import { resetPasswordFormSchema } from 'helpers/validationSchemas';
import { ResetPasswordFormFieldsType } from 'types/AccountTypes';
import { FormWrapper } from '../styles';
import ErrorTooltip from '../ErrorTooltip';

const ResetPasswordForm = ({
  isLoading,
  changePassword,
}: {
  isLoading: boolean;
  changePassword: (passwordData: ResetPasswordFormFieldsType) => void;
}) => {
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [showNewPassConf, setNewShowPassConf] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormFieldsType>({
    resolver: yupResolver(resetPasswordFormSchema),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const onSubmit = (data: ResetPasswordFormFieldsType) => {
    changePassword(data);
  };

  return (
    <FormWrapper id="update-password-form" onSubmit={handleSubmit(onSubmit)}>
      <PasswordCriteria />
      <FormInputController
        control={control}
        type={showNewPass ? 'text' : 'password'}
        name="newPassword"
        label="New Password"
        error={!!errors.newPassword}
        errorMessage={errors?.newPassword?.message}
        InputProps={{
          endAdornment: errors?.newPassword?.message ? (
            <ErrorTooltip
              title={errors?.newPassword?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPass(!showNewPass)}
              >
                {showNewPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPass(!showNewPass)}
              >
                {showNewPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormInputController
        control={control}
        type={showNewPassConf ? 'text' : 'password'}
        name="confirmNewPassword"
        label="Confirm New Password"
        error={!!errors.confirmNewPassword}
        errorMessage={errors?.confirmNewPassword?.message}
        InputProps={{
          endAdornment: errors?.confirmNewPassword?.message ? (
            <ErrorTooltip
              title={errors?.confirmNewPassword?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setNewShowPassConf(!showNewPassConf)}
              >
                {showNewPassConf ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setNewShowPassConf(!showNewPassConf)}
              >
                {showNewPassConf ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ColoredButton
        width="250px"
        aria-label="save changes"
        isLoading={isLoading}
        style={{ marginTop: 40 }}
      >
        save changes
      </ColoredButton>
    </FormWrapper>
  );
};

export default ResetPasswordForm;
