/* eslint-disable import/no-dynamic-require */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { gallery } from 'data/homeData';
import useDimensions from 'hooks/useDimensions';
import {
  Wrapper,
  OverlayContent,
  Image,
  Title,
  Subtitle,
  MobileImagesWrapper,
  MobileImage,
  OverlayContentsWrapper,
  StyledAnimatedLink,
} from './styles';

const CarouselGallery = () => {
  const { width } = useDimensions();

  const settings = {
    infinite: true,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <OverlayContentsWrapper>
        <OverlayContent>
          <Title>a new wave in realness</Title>
          <Subtitle>Discover the best in Black Brands.</Subtitle>
          <StyledAnimatedLink>Shop with us</StyledAnimatedLink>
        </OverlayContent>
        <OverlayContent>
          <Title>power the black economy</Title>
          <Subtitle>
            Want to be part of the Black-Owned Businesses on our site?
          </Subtitle>
          <StyledAnimatedLink>SELL WITH US</StyledAnimatedLink>
        </OverlayContent>
      </OverlayContentsWrapper>

      {width > 1000 ? (
        <div style={{ width: '100%' }}>
          <Slider {...settings}>
            {gallery.map(({ id, image }) => (
              <Image key={id} src={require(`../../../${image}`)} />
            ))}
          </Slider>
        </div>
      ) : (
        <MobileImagesWrapper>
          {gallery.map(({ id, image }) => (
            <MobileImage key={id} src={require(`../../../${image}`)} />
          ))}
        </MobileImagesWrapper>
      )}
    </Wrapper>
  );
};

export default CarouselGallery;
