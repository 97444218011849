import { useCallback } from 'react';
import { chain, omit } from 'lodash';
import TagManager from 'react-gtm-module';

import { CategoryType } from 'types/CatalogTypes';
import {
  EcommmerceType,
  EventType,
  EcommerceItemType,
} from 'types/GoogleTagTypes';
import { googleTagsAffiliation } from 'appConstants';
import { getCategoryById } from 'eg_SFCC_FE_core/axios/api';

type EcommersDataType = {
  event: string;
  ecommerce?: {
    items: any[];
  };
};

const useGoogleTags = () => {
  const getCategoriesForEventTracking = async (primaryCategoryId: string) => {
    if (!primaryCategoryId) return;

    const response = await getCategoryById(primaryCategoryId);

    return chain(response.parentCategoryTree)
      .cloneDeep()
      .reverse()
      .reduce(
        (
          result: { [key: string]: string },
          value: CategoryType,
          key: number,
        ) => {
          if (value.id !== 'seller') {
            if (key === 0) {
              result.item_category = value.name;
            } else {
              result[`item_category${key + 1}`] = value.name;
            }
          }
          return result;
        },
        {},
      )
      .value();
  };

  const trackEvent = useCallback(async ({
    event,
    ecommerce,
  }: {
    event: EventType;
    ecommerce?: EcommmerceType;
  }) => {
    const eventData: EcommersDataType = { event };

    if (ecommerce) {
      eventData.ecommerce = {
        ...ecommerce,
        items: await Promise.all(
          ecommerce.items.map(async (item: EcommerceItemType) => {
            item.affiliation = googleTagsAffiliation;
            const categories = await getCategoriesForEventTracking(
              item.primaryCategoryId,
            );

            return {
              ...omit(item, 'primaryCategoryId'),
              ...categories,
            };
          }),
        ),
      };
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  }, []);

  const addPageView = (
    pageType: string,
    pageData?: { [key: string]: string },
  ) => {
    window.dataLayer.push({ page: null });
    window.dataLayer.push({
      page: {
        page_type: pageType,
        ...pageData,
      },
    });
  };

  const trackEventWithPageView = (
    event: string,
    pageType: string,
    pageData?: { [key: string]: string },
  ) => {
    window.dataLayer.push({ page: null });
    window.dataLayer.push({
      event,
      page: {
        page_type: pageType,
        ...pageData,
      },
    });
  };

  const addDataLayer = (dataLayer: { [key: string]: string }) => {
    TagManager.dataLayer({ dataLayer });
  };

  return {
    getCategoriesForEventTracking,
    trackEvent,
    addPageView,
    trackEventWithPageView,
    addDataLayer,
  };
};

export default useGoogleTags;
