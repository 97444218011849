import { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';

import { LabelCheckbox } from 'components';
import PaymentCardSelect from 'components/Dropdowns/Selects/PaymentCardSelect';
import PaymentForm from 'components/Forms/PaymentForm/PaymentForm';
import { PaymentCardType } from 'types/CheckoutTypes';
import { PaymentValuesType } from 'types/AccountTypes';
import { CheckboxWrapper } from './styles';

const CheckoutPaymentCardForm = ({
  customerPaymentCards,
  isSavedPaymentCardUsed,
  setIsSavedPaymentCardUsed,
  isLoggedIn,
  isPaymentCardSaved,
  setIsPaymentCardSaved,
  selectedPaymentCard,
  setSelectedPaymentCard,
  paymentValues,
  methods,
  onSubmit,
}: {
  customerPaymentCards: any[];
  isSavedPaymentCardUsed: boolean;
  setIsSavedPaymentCardUsed: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
  isPaymentCardSaved: boolean;
  setIsPaymentCardSaved: Dispatch<SetStateAction<boolean>>;
  selectedPaymentCard: PaymentCardType;
  setSelectedPaymentCard: Dispatch<SetStateAction<PaymentCardType>>;
  paymentValues: PaymentValuesType;
  methods: any;
  onSubmit: (data: any) => void;
}) => {
  return (
    <>
      {!isEmpty(customerPaymentCards) ? (
        <CheckboxWrapper>
          <LabelCheckbox
            label="Use saved card"
            id="useSavedCard"
            checked={isSavedPaymentCardUsed}
            handleChange={() =>
              setIsSavedPaymentCardUsed(!isSavedPaymentCardUsed)
            }
          />
        </CheckboxWrapper>
      ) : null}
      {isSavedPaymentCardUsed ? (
        <PaymentCardSelect
          items={customerPaymentCards}
          currentMenuItem={selectedPaymentCard}
          setCurrentMenuItem={setSelectedPaymentCard}
        />
      ) : (
        <>
          {isLoggedIn ? (
            <CheckboxWrapper>
              <LabelCheckbox
                id="saveCardToAccount"
                label="Save card to account"
                checked={isPaymentCardSaved}
                handleChange={() => setIsPaymentCardSaved(!isPaymentCardSaved)}
              />
            </CheckboxWrapper>
          ) : null}
          <PaymentForm
            values={paymentValues}
            onSubmit={onSubmit}
            methods={methods}
          />
        </>
      )}
    </>
  );
};

export default CheckoutPaymentCardForm;
