import styled from 'styled-components';
import { AnimatedLinkUpdated, Typography } from 'components';
import { BREAKPOINTS, FONTS } from 'styles';

export const NotFoundPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 136px 25px 80px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 55px 25px 40px;
  }
`;

export const Title = styled(Typography)`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Subtitle = styled(Typography).attrs({
  type: 'title1Helv',
})`
  width: 70%;
  max-width: 1100px;
  text-align: center;
  text-transform: uppercase;
  margin: 111px 0 168px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 90%;
    margin: 46px 0 38px;
  }
`;

export const LinksWrapper = styled.div`
  width: 75%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 90%;
  }
`;

export const StyledAnimatedLink = styled(AnimatedLinkUpdated)`
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;
