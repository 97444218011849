import { CSSProperties, Dispatch, SetStateAction } from 'react';

import PageSelect from 'components/Dropdowns/Selects/PageSelect';
import { Wrapper, Text, Rotated180Icon, StyledArrowRightIcon } from './styles';

const DropdownPagination = ({
  pageSize,
  totalCount,
  setCurrentPage,
  currentPage,
  style,
}: {
  pageSize: number;
  totalCount: number;
  setCurrentPage: Dispatch<SetStateAction<number>> | ((page: number) => void);
  currentPage: number;
  style?: CSSProperties;
}) => {
  const pagesNumber = Math.ceil(totalCount / pageSize);

  return (
    <Wrapper style={style}>
      <Text>
        Showing results{' '}
        {totalCount === 0 ? 0 : (currentPage - 1) * pageSize + 1}
        {' - '}
        {Math.min(currentPage * pageSize, totalCount)} of {totalCount}
      </Text>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Rotated180Icon
          style={{ marginRight: 10 }}
          onClick={() =>
            currentPage === 1 ? () => {} : setCurrentPage(currentPage - 1)
          }
          disabled={currentPage === 1}
        />
        <PageSelect
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesNumber={pagesNumber}
        />
        <StyledArrowRightIcon
          style={{ marginLeft: 10 }}
          onClick={() =>
            currentPage === pagesNumber
              ? () => {}
              : setCurrentPage(currentPage + 1)
          }
          disabled={currentPage === pagesNumber}
        />
      </div>
    </Wrapper>
  );
};

export default DropdownPagination;
