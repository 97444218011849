import { MainTitle, Paragraph } from '../styles';

const ContactUs = () => (
  <>
    <MainTitle>CONTACT US</MainTitle>
    <Paragraph>
      If you have any questions about our privacy practices or this Privacy
      Notice, the ways in which we collect and use your Personal Information, or
      to exercise your choices and rights as detailed in this Privacy Notice,
      please contact us at:
    </Paragraph>
    <table
      style={{ maxWidth: 700, minWidth: 300, marginLeft: 20, marginTop: 10 }}
    >
      <thead>
        <tr>
          <td style={{ paddingRight: 10 }}>
            <Paragraph style={{ fontWeight: 700 }}>Address:</Paragraph>
          </td>
          <td style={{ padding: 5 }}>
            <Paragraph>
              CE Marketplace c/o Legal Department General Counsel Empower Global
              384 Northyards Blvd NW, #67 Atlanta, GA 30313
            </Paragraph>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td style={{ paddingRight: 10 }}>
            <Paragraph style={{ fontWeight: 700 }}>Email:</Paragraph>
          </td>
          <td style={{ padding: 5 }}>
            <Paragraph>
              <a href="mailto:legal@EmpowerGlobal.shop">
                legal@EmpowerGlobal.shop
              </a>
            </Paragraph>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default ContactUs;
