import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const ContactInfoWrapper = styled.div`
  max-width: 280px;
  margin-left: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;
