import { useSelector } from 'react-redux';

import BasicTable from 'components/Table/BasicTable';
import { getCustomerData } from 'eg_SFCC_FE_core/store/selectors';

type TransactionType = {
  createdDate: string;
  amount: number;
  orderNumber: string;
  eventType: string;
};

const headerCells = ['Date', 'Description', 'Points Earned'];

const formatDate = (date: string) => {
  const dateArr = date.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0].slice(-2)}`;
};

const LoyaltyPointsHistory = () => {
  const customerData = useSelector(getCustomerData);
  const loyaltyInfo = customerData?.c_loyaltyInfo;
  const membersInfo = loyaltyInfo?.consumersInfo?.[0].membersInfo;
  const pointsHistory = membersInfo ? membersInfo[0].latestTransactions : [];

  const data = pointsHistory.map((point: TransactionType) => {
    const differenceSign =
      point.amount === 0 ? '' : point.amount < 0 ? '-' : '+';

    return {
      date: formatDate(point.createdDate),
      description:
        point.eventType.toLowerCase() === 'transfer'
          ? 'Redeemed'
          : `Order No. ${point.orderNumber || '-'}`,
      points: `${differenceSign}${Math.abs(point.amount)} Points`,
      orderNumber: point.orderNumber,
    };
  });

  return (
    <div style={{ padding: '40px 0' }}>
      <BasicTable headerCells={headerCells} data={data} />
    </div>
  );
};

export default LoyaltyPointsHistory;
