import { useState, MouseEvent, ReactNode } from 'react';
import { IconButton, Menu } from '@mui/material';

import { ColoredButton } from 'components';
import useDimensions from 'hooks/useDimensions';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import { MenuTitle, RotatedMinus, RotatedPlus } from './styles';

const BasicMenu = ({
  title,
  content,
  handleDoneButtonClick,
  handleResetButtonClick,
  buttonPlace = 'bottom',
}: {
  title: string;
  content: ReactNode;
  handleDoneButtonClick: () => void;
  handleResetButtonClick: () => void;
  buttonPlace?: 'top' | 'bottom';
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { height } = useDimensions();
  const open = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    handleClose();
    handleDoneButtonClick();
  };

  const handleResetClick = () => {
    handleClose();
    handleResetButtonClick();
  };

  return (
    <div style={{ paddingRight: 17 }}>
      <IconButton
        onClick={handleOpen}
        size="small"
        sx={{ p: 0 }}
        aria-label="open filter menu"
        aria-controls={open ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableRipple
        id="filter-menu"
      >
        <MenuTitle>{title}</MenuTitle>
        {open ? <RotatedMinus /> : <RotatedPlus />}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiButtonBase-root.MuiMenuItem-root': {
            minWidth: 200,
            maxHeight: 300,
          },
          '& .MuiPaper-root': {
            maxHeight: height - HEADER_NAV_HEIGHT - 100,
            overflow: 'auto',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: '18px 28px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {buttonPlace === 'top' ? (
          <ColoredButton
            onClick={handleDoneClick}
            style={{ marginBottom: 20, width: '100%' }}
          >
            Done
          </ColoredButton>
        ) : null}
        {content}
        {buttonPlace === 'bottom' ? (
          <div>
            <ColoredButton
              onClick={handleDoneClick}
              style={{ marginTop: 36, width: '100%' }}
            >
              Done
            </ColoredButton>
            <ColoredButton
              onClick={handleResetClick}
              style={{ marginTop: 10, width: '100%' }}
            >
              Reset
            </ColoredButton>
          </div>
        ) : null}
      </Menu>
    </div>
  );
};

export default BasicMenu;
