import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { COLORS, FONTS } from 'styles';
import BasicCheckbox from './BasicCheckbox';

const LabelCheckbox = ({
  id,
  label,
  checked,
  handleChange,
}: {
  id?: string;
  label: string | any;
  checked: boolean;
  handleChange: any;
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <BasicCheckbox
            id={id}
            checked={checked}
            handleChange={handleChange}
          />
        }
        label={label}
        sx={{
          '& .MuiTypography-root': {
            fontFamily: FONTS.Helvetica.regular,
            fontSize: 12,
            color: COLORS.black,
            opacity: 0.6,
            transition: '0.3s',
            '&:hover': {
              opacity: 1,
            },
            '@media (max-width: 1024px)': {
              fontSize: 10,
            },
          },
        }}
      />
    </FormGroup>
  );
};

export default LabelCheckbox;
