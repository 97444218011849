import { useState } from 'react';
import { CustomNavLink, Typography } from 'components';
import { AfterpayClearpayMessageElement } from '@stripe/react-stripe-js';

import { formatHtmlToText, formatPrice } from 'helpers/formatters';
import { ProductItemType, ProductVariantType } from 'types/ProductsTypes';
import { BrandType } from 'types/BrandsTypes';
import { useProductWishList } from 'hooks/useWishList';
import { RotatedMinus, RotatedPlus } from 'components/Accordion/styles';
import ROUTES from 'router/Routes';
import ProductDetailOptions from './ProductDetailOptions';
import {
  RatingWrapper,
  StyledColoredButton,
  TagsWrapper,
  AfterpayMessageWrapper,
  Tag,
  ProductBrandName,
  ProductOverviewText,
  ProductDescriptionTitle,
  ProductDescriptionText,
  ProductDescriptionWrapper,
} from './styles';
import ProductBrandCard from './ProductBrandCard';

const MAX_DESCRIPTION_LENGTH = 50;

const ProductOverview = ({
  product,
  addToCartHandler,
  selectedProductQuantity,
  selectProductQuantity,
  selectedProductVariant,
  selectProductVariant,
  errorMessage,
  setErrorMessage,
  brandDetails,
  isBrandLoading,
  brandError,
}: {
  product: ProductItemType;
  addToCartHandler: () => void;
  selectedProductQuantity: number;
  selectProductQuantity: (quantity: number) => void;
  selectedProductVariant?: ProductVariantType;
  selectProductVariant: (data: any) => void;
  errorMessage: string | undefined;
  setErrorMessage: (data: any) => void;
  brandDetails: BrandType | null;
  isBrandLoading: boolean;
  brandError: any;
}) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const {
    name,
    brand,
    price,
    shortDescription,
    id,
    inventory,
    c_marketPlacerTags: marketPlacerTags,
    productPromotions,
    c_prices: prices,
  } = product;

  const { addToWishList, isInWishList } = useProductWishList();
  const inWishList = isInWishList(id);
  const isItemOrderable =
    !!inventory?.orderable &&
    (selectedProductVariant ? selectedProductVariant?.orderable : true);
  const originalPrice = prices.list?.value;
  const itemPrice = selectedProductVariant
    ? selectedProductVariant.price
    : price;
  const description = formatHtmlToText(shortDescription || '').trim();

  const navigateTo = brandDetails?.isDisabled
    ? `${ROUTES.BRANDS}/${brandDetails?.id}/products`
    : `${ROUTES.BRANDS}/${brandDetails?.id}`;

  return (
    <div style={{ position: 'relative' }}>
      {brandDetails ? (
        <CustomNavLink to={navigateTo}>
          <ProductBrandName>{brand}</ProductBrandName>
        </CustomNavLink>
      ) : (
        <ProductBrandName>{brand}</ProductBrandName>
      )}
      <ProductOverviewText>{name.toLocaleLowerCase()}</ProductOverviewText>
      {originalPrice ? (
        <>
          <ProductOverviewText style={{ textDecoration: 'line-through' }}>
            {originalPrice && formatPrice(originalPrice)}
          </ProductOverviewText>
          <ProductOverviewText>
            {itemPrice && formatPrice(itemPrice)}
          </ProductOverviewText>
        </>
      ) : (
        <ProductOverviewText>
          {itemPrice && formatPrice(itemPrice)}
        </ProductOverviewText>
      )}

      {itemPrice && (
        <AfterpayMessageWrapper>
          <AfterpayClearpayMessageElement
            options={{
              amount: itemPrice * 100,
              currency: 'USD',
              lockupTheme: 'black',
              logoType: 'lockup',
            }}
          />
        </AfterpayMessageWrapper>
      )}
      {productPromotions?.length ? (
        <div style={{ marginTop: 20 }}>
          {productPromotions.map(
            (promotion: { calloutMsg: string; promotionId: string }) => (
              <Typography key={promotion.promotionId} color="accent">
                {promotion.calloutMsg}
              </Typography>
            ),
          )}
        </div>
      ) : null}

      <RatingWrapper>
        <div
          data-bv-show="rating_summary"
          data-bv-product-id={id}
          id="data-bv-show"
        />
      </RatingWrapper>

      <ProductDetailOptions
        product={product}
        selectedProductQuantity={selectedProductQuantity}
        selectProductQuantity={selectProductQuantity}
        selectProductVariant={selectProductVariant}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isItemOrderable={isItemOrderable}
      />

      <StyledColoredButton
        aria-label="add to cart"
        onClick={addToCartHandler}
        disabled={!isItemOrderable || !!errorMessage}
      >
        add to cart
      </StyledColoredButton>
      <StyledColoredButton
        aria-label="save to wishlist"
        onClick={() => addToWishList(id, selectedProductQuantity)}
        disabled={inWishList}
      >
        save to wishlist
      </StyledColoredButton>

      {marketPlacerTags?.length && (
        <TagsWrapper>
          {marketPlacerTags?.map((tag: string, index: number) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </TagsWrapper>
      )}

      <ProductDescriptionTitle>PRODUCT DETAILS</ProductDescriptionTitle>
      <ProductDescriptionWrapper>
        <pre style={{ whiteSpace: 'break-spaces', paddingRight: 10 }}>
          <ProductDescriptionText>
            {seeMore
              ? description
              : `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`}
          </ProductDescriptionText>
        </pre>

        <button type="button" onClick={() => setSeeMore((prev) => !prev)}>
          {seeMore ? <RotatedMinus /> : <RotatedPlus />}
        </button>
      </ProductDescriptionWrapper>

      {!isBrandLoading && brandDetails && (
        <ProductBrandCard
          name={brandDetails?.businessName}
          businessOwnerImage={brandDetails?.businessOwnerImage}
          businessDescription={brandDetails?.businessDescription}
          brand={{
            brandId: brandDetails?.id,
            isBrandDisabled: brandDetails?.isDisabled,
          }}
        />
      )}
    </div>
  );
};

export default ProductOverview;
