import { notFoundPageLinks } from 'data/homeData';
import { formatNameForUrl } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import {
  LinksWrapper,
  NotFoundPageWrapper,
  StyledAnimatedLink,
  Subtitle,
  Title,
} from './styles';

const NotFoundPage = () => {
  return (
    <NotFoundPageWrapper>
      <Title>ERROR 404</Title>
      <Subtitle>
        We&apos;re sorry, but the page you&apos;re looking for doesn&apos;t
        exist. Try one of the links below
      </Subtitle>
      <LinksWrapper>
        {notFoundPageLinks.map(({ id, name, routeName }) => (
          <StyledAnimatedLink
            key={id}
            fontSize="24px"
            to={`${ROUTES.PRODUCTS}/${formatNameForUrl(routeName)}/${id}`}
          >
            {name}
          </StyledAnimatedLink>
        ))}
      </LinksWrapper>
    </NotFoundPageWrapper>
  );
};

export default NotFoundPage;
