import { SingleQuoteIcon } from 'assets/svg';
import { ColoredButton, Typography } from 'components';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const HeroBackgroundImage = styled.div<{
  imageUrl?: string;
}>`
  width: 100%;
  height: calc(100vh - ${HEADER_NAV_HEIGHT}px);
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  position: relative;
  background-size: cover;
  background-position: center;

  @media (max-width: 1150px) {
    background-position: center;
    height: 60vh;
  } ;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 60px 80px;
  gap: 60px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1150px) {
    padding: 40px 20px;
    flex-direction: column;
  }
`;

export const LeftBlock = styled.div`
  width: 70%;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const RightBlock = styled.div`
  width: 30%;
  padding-top: 100px;

  @media (max-width: 1150px) {
    padding-top: 0;
    width: 100%;
  }
`;

export const Title = styled(Typography)`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 36px;
  line-height: 46px;
  text-transform: uppercase;
  margin-bottom: 9px;

  @media (max-width: 1000px) {
    font-size: 30px;
  }
`;

export const Location = styled(Typography)`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 9px;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
`;

export const BoldTitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 20px;
  color: ${COLORS.black};
  text-transform: capitalize;
  margin-top: 20px;
`;

export const Text = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.black};
`;

export const List = styled.ul`
  list-style-type: disc;
  margin: 10px 0 28px 20px;
`;

export const QuoteWrapper = styled.div`
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${COLORS.border};
`;

export const QuoteText = styled.div`
  font-family: ${FONTS.Bison.bold};
  font-weight: 900;
  font-size: 63px;
  line-height: 60px;
  -webkit-text-stroke: 1px ${COLORS.black};
  -webkit-text-fill-color: transparent;
  text-shadow: -3px -1px 0px ${COLORS.accent};
  max-width: 600px;
  position: relative;

  @media (max-width: 1150px) {
    margin-left: 60px;
  }

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 48px;
    text-shadow: -2px -1px 0px ${COLORS.accent};
  }
`;

export const Quote = styled(SingleQuoteIcon)`
  width: 18px;
  margin-right: 6px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.25));
`;

export const RotatedQuote = styled(SingleQuoteIcon)`
  width: 18px;
  transform: rotate(180deg);
  margin-left: 6px;
  filter: drop-shadow(0px -3px 2px rgba(0, 0, 0, 0.25));
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 250px;
  height: 60px;
  background-color: ${COLORS.accent};
  & p {
    color: ${COLORS.black};
    font-family: ${FONTS.TTNorms.medium};
  }
  &:hover {
    background: ${COLORS.primary};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;

  & svg {
    cursor: pointer;
  }
`;
