import { FunctionComponent, SVGProps } from 'react';
import ConfirmationContent from 'components/Modals/ModalContent/ConfirmationContent';
import AuthContent from 'components/Modals/ModalContent/AuthContent';
import LoyaltyRegistrationContent from 'components/Modals/ModalContent/LoyaltyRegistrationContent';
import AddToCartConfirmationContent from 'components/Modals/ModalContent/AddToCartConfirmationContent';
import AddToWishlistConfirmationContent from 'components/Modals/ModalContent/AddToWishlistConfirmationContent';
import InfoContent from 'components/Modals/ModalContent/InfoContent';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { ProductItemType } from 'types/ProductsTypes';
import { BREAKPOINTS, COLORS } from 'styles';
import useDimensions from './useDimensions';

export const useConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const closeModal = () =>
    dispatch(modalActions.hideModal('CONFIRMATION_MODAL'));

  const openConfirmationModal = ({
    Icon,
    title,
    text,
    cancelButtonText,
    confirmButtonText,
    confirmButtonAction,
    cancelButtonAction,
  }: {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    text: string;
    cancelButtonText: string;
    confirmButtonText: string;
    confirmButtonAction: (prop?: any) => any;
    cancelButtonAction?: () => void;
  }) =>
    dispatch(
      modalActions.showModal({
        id: 'CONFIRMATION_MODAL',
        modalContent: (
          <ConfirmationContent
            unSetModal={closeModal}
            Icon={Icon}
            title={title}
            text={text}
            cancelButtonText={cancelButtonText}
            confirmButtonAction={confirmButtonAction}
            confirmButtonText={confirmButtonText}
            cancelButtonAction={cancelButtonAction}
          />
        ),
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 600,
          bgcolor: COLORS.white,
        },
      }),
    );

  return openConfirmationModal;
};

export const useInfoModal = () => {
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(modalActions.hideModal('INFO_MODAL'));

  const openInfoModal = (text: string, cb?: () => void) =>
    dispatch(
      modalActions.showModal({
        id: 'INFO_MODAL',
        modalContent: (
          <InfoContent unSetModal={closeModal} text={text} cb={cb} />
        ),
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: COLORS.white,
        },
        actionOnClose: cb,
      }),
    );

  return openInfoModal;
};

const authContentBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  backgroundColor: `${COLORS.white}`,
};

export const useAuthModals = () => {
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(modalActions.hideModal('AUTH_MODAL'));

  const showLoginModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'AUTH_MODAL',
        modalContent: <AuthContent isLogin closeModal={closeModal} />,
        boxStyle: authContentBoxStyle,
      }),
    );
  };

  const showRegisterModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'AUTH_MODAL',
        modalContent: <AuthContent closeModal={closeModal} />,
        boxStyle: authContentBoxStyle,
      }),
    );
  };

  return { showLoginModal, showRegisterModal };
};

export const useLoyaltyModals = () => {
  const dispatch = useAppDispatch();
  const { width } = useDimensions();
  const closeModal = () =>
    dispatch(modalActions.hideModal('LOYALTY_REGISTRATION_MODAL'));

  const showLoyaltyRegistrationModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'LOYALTY_REGISTRATION_MODAL',
        modalContent: <LoyaltyRegistrationContent closeModal={closeModal} />,
        boxStyle: {
          position: 'absolute',
          left: '50%',
          top: '0',
          bottom: '0',
          transform: 'translateX(-50%)',
          width: width < 800 ? '100%' : 800,
          backgroundColor: `${COLORS.white}`,
        },
      }),
    );
  };

  return { showLoyaltyRegistrationModal };
};

export const useAddToCartConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const { width } = useDimensions();

  const closeModal = () => {
    dispatch(modalActions.hideAllModals());
  };

  const showAddToCartConfirmationModal = (productIdForBasket: string) => {
    dispatch(
      modalActions.showModal({
        id: 'ADD_TO_CART_CONFIRMATION_MODAL',
        modalContent: (
          <AddToCartConfirmationContent
            title="Item added to cart"
            productIdForBasket={productIdForBasket}
            unSetModal={closeModal}
          />
        ),
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: width > BREAKPOINTS.xs ? 600 : 350,
          bgcolor: COLORS.white,
        },
      }),
    );
  };

  return { showAddToCartConfirmationModal };
};

export const useAddToWishlistConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const { width } = useDimensions();

  const closeModal = () => {
    dispatch(modalActions.hideAllModals());
  };

  const showAddToWishlistConfirmationModal = (product: ProductItemType) => {
    dispatch(
      modalActions.showModal({
        id: 'ADD_TO_WISHLIST_CONFIRMATION_MODAL',
        modalContent: (
          <AddToWishlistConfirmationContent
            product={product}
            unSetModal={closeModal}
          />
        ),
        boxStyle: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: width > BREAKPOINTS.xs ? 600 : 350,
          bgcolor: COLORS.white,
        },
      }),
    );
  };

  return { showAddToWishlistConfirmationModal };
};
