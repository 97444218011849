import styled, { css } from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const SectionWrapper = styled.div`
  display: flex;
  padding: 70px 0;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 25px 0;
  }
`;

export const MenuItemsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(5, 40px);
  grid-gap: 8px;
  margin-right: 10vw;

  @media (max-width: ${BREAKPOINTS.s}px) {
    grid-gap: 5px;
    grid-template-columns: repeat(5, 20px);
    grid-template-rows: repeat(5, 20px);
  }
`;

export const Letter = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.Helvetica.bold};
  background-color: ${COLORS.headerBackground}CC;
  text-transform: uppercase;
  font-size: 32px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
`;

export const MainLetter = styled(Letter)`
  margin-left: -10px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-left: -6px;
  }
`;

export const MenuItem = styled(Letter)<{
  active: boolean;
  isClickable: boolean;
}>`
  font-size: 14px;
  font-family: ${FONTS.Helvetica.medium};
  cursor: pointer;
  color: ${COLORS.textGrey};

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${COLORS.black};
    `}

  ${({ isClickable }) =>
    !isClickable &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  &:hover {
    color: ${COLORS.black};
    transition: 0.3s;
  }
`;

export const BrandsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinksContainer = styled.div`
  margin-top: 15px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: 5px;
  }

  display: grid;
  grid-gap: 10px;

  & a {
    width: fit-content;
  }
`;

export const LinkTitle = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.textGrey};
  text-transform: uppercase;
  transition: all 0.3s;

  &:hover {
    color: ${COLORS.black};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;
