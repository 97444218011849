import { useState } from 'react';

import { ColoredButton, Typography } from 'components';
import ResetPasswordForm from 'components/Forms/ResetPasswordForms/ResetPasswordForm';
import { COLORS } from 'styles';
import { ResetPasswordFormFieldsType } from 'types/AccountTypes';
import { ContentWrapper } from 'styles/common';
import { generateCSRFToken, resetPassword } from 'eg_SFCC_FE_core/axios/api';
import { useAuthModals } from 'hooks/useModals';

export const statusData: any = {
  invalid_token: 'You used invalid link',
  invalid_password: "New password don't match requirements",
  password_reset_successfully: 'Password reset successfully',
};

const ResetPasswordPage = () => {
  const [sendStatus, setSendStatus] = useState<string>('');
  const [isLoading, setLoader] = useState<boolean>(false);
  const { showLoginModal } = useAuthModals();
  const token = window.location.href.split('=')[1];

  const changePassword = async (passwordData: ResetPasswordFormFieldsType) => {
    setLoader(true);
    try {
      const { token: CSRFToken } = await generateCSRFToken();
      const response = await resetPassword({
        token,
        password: passwordData.newPassword,
        csrf_token: CSRFToken,
      });
      setSendStatus(response.tokenResult.status);
      setLoader(false);
    } catch (e) {
      // @ts-ignore
      setSendStatus(e.message);
      setLoader(false);
    }
  };

  return (
    <ContentWrapper>
      <div style={{ maxWidth: 500, width: '100%' }}>
        <Typography type="subtitle2">Enter new password</Typography>
        <div style={{ height: 26, margin: '15px 0' }}>
          <Typography style={{ color: COLORS.accent }}>
            {statusData[sendStatus]}
          </Typography>
        </div>
        {sendStatus === 'password_reset_successfully' ? (
          <ColoredButton
            aria-label="continue"
            width="250px"
            onClick={showLoginModal}
          >
            Continue
          </ColoredButton>
        ) : (
          <ResetPasswordForm
            isLoading={isLoading}
            changePassword={changePassword}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default ResetPasswordPage;
