import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { includes, values, assign } from 'lodash';

import { AnimatedWrapper } from 'components';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import {
  getBasket,
  getOrderDetails,
  getCheckoutLoader,
  getIsLoggedIn,
} from 'eg_SFCC_FE_core/store/selectors';
import ROUTES from 'router/Routes';

import useGoogleTags from 'hooks/useGoogleTags';
import { BasketProductItemType } from 'types/ProductsTypes';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { EventType } from 'types/GoogleTagTypes';
import { googleTagsAffiliation } from 'appConstants';
import { formatShippingAddress } from 'helpers/formatters';

import {
  CheckoutPageWrapper,
  MainTitle,
  PagesWrapper,
  CheckoutButton,
  Text,
} from './styles';

type ContextType = {
  setIsNextStepTriggered: (param: boolean) => void;
  trackCheckoutEvent: (event: EventType) => void;
  isSubmitEmail: boolean;
  setIsSubmitEmail: Dispatch<SetStateAction<boolean>>;
  setIsSubmitEmailReady: Dispatch<SetStateAction<boolean>>;
  isSubmitEmailReady: boolean;
  isSubmitShipping: boolean;
  setIsSubmitShipping: Dispatch<SetStateAction<boolean>>;
  setIsShippingStepReady: Dispatch<SetStateAction<boolean>>;
  isSubmitPayment: boolean;
  setIsSubmitPayment: Dispatch<SetStateAction<boolean>>;
  onFormSubmitError: () => void;
};

export const useCheckoutNextSteps = () => {
  return useOutletContext<ContextType>();
};

export const checkoutLinkList = [
  {
    linkTo: `${ROUTES.CHECKOUT_ACCOUNT_SETUP}`,
  },
  {
    linkTo: `${ROUTES.CHECKOUT_DETAILS}`,
  },
  {
    linkTo: `${ROUTES.CHECKOUT_CONFIRMATION}`,
  },
];

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(getIsLoggedIn);

  const {
    productItems,
    subtotal,
    shippingFee,
    estimatedTotal,
    taxAmount,
    productTotal,
    discountTotal,
    result,
  } = useSelector(getBasket);
  const orderDetails = useSelector(getOrderDetails);
  const isLoading = useSelector(getCheckoutLoader);
  const { trackEvent } = useGoogleTags();
  const dispatch = useAppDispatch();
  const shippingAddress = result?.shipments[0].shippingAddress;
  const [isNextStepTriggered, setIsNextStepTriggered] =
    useState<boolean>(false);

  const [isSubmitEmail, setIsSubmitEmail] = useState<boolean>(false);
  const [isSubmitEmailReady, setIsSubmitEmailReady] = useState<boolean>(false);
  const [isSubmitPayment, setIsSubmitPayment] = useState<boolean>(false);
  const [isSubmitShipping, setIsSubmitShipping] = useState<boolean>(false);
  const [isShippingStepReady, setIsShippingStepReady] =
    useState<boolean>(false);

  const trackCheckoutEvent = async (event: EventType) => {
    const items = await Promise.all(
      productItems.map(async (item: BasketProductItemType) => {
        let products = await getProductsByIds(item.productId);
        let product = products[0];

        if (product.master) {
          products = await getProductsByIds(product.master.masterId);
          [product] = products;
        }

        return {
          item_id: product.id,
          item_name: product.name,
          currency: 'USD',
          item_brand: product.brand,
          item_variant: values(item?.variationValues).join(',') || '',
          price: item.price || 0,
          quantity: item.quantity,
          primaryCategoryId: product.primaryCategoryId,
        };
      }),
    );

    const ecommerce = {
      value: subtotal,
      coupon: '', // coupon strategy isn't implemented yet
      items,
    };

    if (event === 'purchase') {
      assign(ecommerce, {
        transaction_id: orderDetails?.orderNo,
        affiliation: googleTagsAffiliation,
        currency: 'USD',
        tax: taxAmount,
        shipping: shippingFee,
      });
    }

    trackEvent({
      event,
      ecommerce,
    });
  };

  const areProductsFromBasket = includes(
    [ROUTES.CHECKOUT, ROUTES.CHECKOUT_ACCOUNT_SETUP, ROUTES.CHECKOUT_DETAILS],
    pathname,
  );

  const isCheckoutPage = pathname === ROUTES.CHECKOUT;

  const onPromoCodeSubmit = useCallback(
    async (promoCode: string, cb: () => void) => {
      await dispatch(AsyncThunks.addCoupon(promoCode));
      cb();
    },
    [],
  );

  const handleCouponDelete = useCallback(async (id: string) => {
    await dispatch(AsyncThunks.removeCoupon(id));
  }, []);

  useEffect(() => {
    if (isCheckoutPage && isLoggedIn) {
      navigate(ROUTES.CHECKOUT_DETAILS);
    }
    if (isCheckoutPage && !isLoggedIn) {
      navigate(ROUTES.CHECKOUT_ACCOUNT_SETUP);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isShippingStepReady) {
      setIsSubmitPayment(true);
    }
  }, [isShippingStepReady]);

  useEffect(() => {
    if (isSubmitEmailReady) {
      setIsSubmitShipping(true);
    }
  }, [isSubmitEmailReady]);

  const onFormSubmitError = () => {
    setIsSubmitEmail(false);
    setIsSubmitEmailReady(false);
    setIsShippingStepReady(false);
    setIsSubmitShipping(false);
    setIsSubmitPayment(false);
  };

  return (
    <CheckoutPageWrapper>
      <MainTitle>CHECKOUT</MainTitle>
      <PagesWrapper>
        <div>
          {checkoutLinkList.map(({ linkTo }) => {
            return (
              <div key={linkTo}>
                <AnimatedWrapper animationKey={pathname}>
                  {pathname.includes(linkTo) ? (
                    <Outlet
                      context={{
                        isNextStepTriggered,
                        setIsNextStepTriggered,
                        trackCheckoutEvent,
                        isSubmitEmail,
                        setIsSubmitEmail,
                        isSubmitEmailReady,
                        setIsSubmitEmailReady,
                        isSubmitShipping,
                        setIsSubmitShipping,
                        setIsShippingStepReady,
                        isSubmitPayment,
                        setIsSubmitPayment,
                        onFormSubmitError,
                      }}
                    />
                  ) : linkTo === ROUTES.CHECKOUT_SHIPPING && shippingAddress ? (
                    <div style={{ marginBottom: 20 }}>
                      {formatShippingAddress(shippingAddress).map(
                        (value: string, i: number) => (
                          <Text key={i}>{value}</Text>
                        ),
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </AnimatedWrapper>
              </div>
            );
          })}
        </div>
        <div>
          <OrderSummary
            orderDetails={orderDetails}
            products={
              areProductsFromBasket ? productItems : orderDetails?.productItems
            }
            totalAmount={
              areProductsFromBasket
                ? estimatedTotal
                : orderDetails?.orderTotal || 0
            }
            subTotalAmount={
              areProductsFromBasket ? subtotal : orderDetails?.productTotal || 0
            }
            taxAmount={
              areProductsFromBasket ? taxAmount : orderDetails?.taxTotal || 0
            }
            isLoading={isLoading}
            shippingTotal={
              areProductsFromBasket
                ? shippingFee
                : orderDetails?.shippingTotal || 0
            }
            productTotal={
              areProductsFromBasket
                ? productTotal
                : orderDetails?.productSubTotal || 0
            }
            discountTotal={
              areProductsFromBasket
                ? discountTotal
                : (orderDetails?.productSubTotal || 0) -
                  (orderDetails?.productTotal || 0)
            }
            onPromoCodeSubmit={onPromoCodeSubmit}
            handleCouponDelete={handleCouponDelete}
          />

          <CheckoutButton
            style={{
              marginTop: 20,
              height: '40px',
              display:
                [
                  ROUTES.CHECKOUT_ACCOUNT_SETUP,
                  ROUTES.CHECKOUT_CONFIRMATION,
                ].indexOf(pathname) !== -1
                  ? 'none'
                  : 'block',
            }}
            onClick={() => {
              if (isLoggedIn) {
                setIsSubmitShipping(true);

                return;
              }

              setIsSubmitEmail(true);
            }}
            isLoading={isLoading}
          >
            place order
          </CheckoutButton>
        </div>
      </PagesWrapper>
    </CheckoutPageWrapper>
  );
};

export default CheckoutPage;
