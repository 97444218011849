import { MouseEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerFormSchema } from 'helpers/validationSchemas';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Typography } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import BasicDatePicker from 'components/Dropdowns/Selects/BasicDatePicker';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
import { RegisterParamsType } from 'eg_SFCC_FE_core/types';
import ROUTES from 'router/Routes';
import { Button, ByCreatingText, CheckoutAuthFormWrapper } from './styles';
import { AccentLink } from '../styles';
import ErrorTooltip from '../ErrorTooltip';

interface State {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
}

const CheckoutRegistrationForm = ({
  registerCustomer,
  ageRestriction,
  authError,
  isLoading,
}: {
  registerCustomer: (params: RegisterParamsType) => void;
  ageRestriction: boolean;
  authError: any;
  isLoading: boolean;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const values = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    email: '',
    birthday: '',
  };

  const methods = useForm<State>({
    resolver: yupResolver(registerFormSchema),
    defaultValues: values,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<State> = (data: State, e: any) => {
    data &&
      registerCustomer({
        customer: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          login: data.email,
          birthday: data.birthday,
        },
        password: data.password,
      });
    e.target.reset();
  };

  const onError = () => {};

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <CheckoutAuthFormWrapper
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      <FormInputController
        control={control}
        name="firstName"
        label="First Name"
        error={!!errors.firstName}
        errorMessage={errors?.firstName?.message}
      />
      <FormInputController
        control={control}
        name="lastName"
        label="Last Name"
        error={!!errors.lastName}
        errorMessage={errors?.lastName?.message}
      />
      <FormInputController
        control={control}
        name="email"
        label="Email Address"
        error={!!errors.email}
        errorMessage={errors?.email?.message}
      />
      <BasicDatePicker
        control={control}
        name="birthday"
        label="Birthday"
        defaultValue={values.birthday}
        error={!!errors.birthday}
        errorMessage={errors?.birthday?.message}
      />
      <FormInputController
        control={control}
        name="password"
        type={showPassword ? 'text' : 'password'}
        label="Password"
        error={!!errors.password}
        errorMessage={errors?.password?.message}
        InputProps={{
          endAdornment: (
            <>
              {!!errors.password && (
                <ErrorTooltip
                  title={errors.password.message}
                  placement="right-start"
                />
              )}
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <FormInputController
        control={control}
        name="confirmPassword"
        type={showPassword ? 'text' : 'password'}
        label="Confirm Password"
        error={!!errors.confirmPassword}
        errorMessage={errors?.confirmPassword?.message}
        InputProps={{
          endAdornment: (
            <>
              {!!errors.confirmPassword && (
                <ErrorTooltip
                  title={errors.confirmPassword.message}
                  placement="right-start"
                />
              )}
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />

      {ageRestriction && (
        <Typography color="accent" style={{ fontSize: 12 }}>
          Due to age restrictions, you are not eligible to sign up.
        </Typography>
      )}

      {authError && (
        <Typography color="accent" style={{ fontSize: 12 }}>
          {authError.status_code === 502 ||
          (authError.status_code === 400 &&
            authError.statusCode === 'is_not_vip_customer')
            ? 'You are not allowed to register.'
            : authError.title}
        </Typography>
      )}

      <Button
        type="submit"
        aria-label="create account"
        isLoading={isLoading}
        style={{ margin: '20px 0 30px' }}
      >
        create your account
      </Button>

      <ByCreatingText>
        By creating an account, you agree to our{' '}
        <AccentLink to={ROUTES.TERMS_CONDITIONS}>Terms of Service</AccentLink>{' '}
        and have read and understood the{' '}
        <AccentLink to={ROUTES.PRIVACY_POLICY}>Privacy Policy</AccentLink>
      </ByCreatingText>
    </CheckoutAuthFormWrapper>
  );
};

export default CheckoutRegistrationForm;
