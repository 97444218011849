/* eslint-disable import/no-dynamic-require */
import {
  NextArrow,
  PrevArrow,
} from 'components/CarouselArrows/CarouselCustomArrows';
import { popularCategories } from 'data/exploreData';
import { formatNameForUrl } from 'helpers/formatters';
import useDimensions from 'hooks/useDimensions';
import Slider from 'react-slick';
import ROUTES from 'router/Routes';

import {
  BackgroundImage,
  HoverableCardWrapper,
  HoverableTitle,
  HoverContent,
  HoverOverlay,
  MobileContainer,
  StyledAnimatedLink,
  Wrapper,
} from './styles';

const PopularCategories = () => {
  const { width } = useDimensions();

  const desktopSliderSettings = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const mobileSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrow color="white" />,
    nextArrow: <NextArrow color="white" />,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
  };

  const navigateTo = (name: string, id: string) => {
    return `${ROUTES.PRODUCTS}/${formatNameForUrl(name)}/${id}`;
  };

  return (
    <Wrapper>
      {width > 1000 ? (
        <Slider {...desktopSliderSettings}>
          {popularCategories.map(({ id, name, image, alt }) => (
            <HoverableCardWrapper key={id}>
              <BackgroundImage src={require(`../../../${image}`)} alt={alt} />
              <HoverContent>
                <HoverableTitle>{name}</HoverableTitle>
                <StyledAnimatedLink to={navigateTo(name, id)}>
                  shop now
                </StyledAnimatedLink>
              </HoverContent>
              <HoverOverlay />
            </HoverableCardWrapper>
          ))}
        </Slider>
      ) : (
        <MobileContainer>
          <Slider {...mobileSliderSettings}>
            {popularCategories.map(({ id, image, name, alt }) => (
              <HoverableCardWrapper key={id}>
                <BackgroundImage src={require(`../../../${image}`)} alt={alt} />
                <HoverContent>
                  <HoverableTitle>{name}</HoverableTitle>
                  <StyledAnimatedLink to={navigateTo(name, id)}>
                    shop now
                  </StyledAnimatedLink>
                </HoverContent>
                <HoverOverlay />
              </HoverableCardWrapper>
            ))}
          </Slider>
        </MobileContainer>
      )}
    </Wrapper>
  );
};

export default PopularCategories;
