import { CSSProperties, ReactNode } from 'react';
import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  InputAdornment,
} from '@mui/material';
import { InfoIcon } from 'assets/svg';
import styled from 'styled-components';
import { COLORS } from 'styles';

const ErrTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.black,
  },
}));

const ErrorTooltip = ({
  title,
  placement = 'right-end',
  style,
  children,
}: {
  title: any;
  placement:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  style?: CSSProperties;
  children?: ReactNode;
}) => {
  return (
    <ErrTooltip title={title} placement={placement} style={style}>
      <InputAdornment position="end">
        <InfoIcon />
        {children}
      </InputAdornment>
    </ErrTooltip>
  );
};

export default ErrorTooltip;
