import { Paragraph, UppercaseTitle } from '../styles';

const DisputesOtherUsers = () => (
  <>
    <UppercaseTitle>10. Disputes with Other Users</UppercaseTitle>
    <Paragraph>
      If you find yourself in a dispute with another user of Empower Global’s
      Services or a third party, we encourage you to contact the other party and
      try to resolve the dispute amicably.
    </Paragraph>
    <Paragraph>
      You release Empower Global from any claims, demands, and damages arising
      out of disputes with other users or parties.
    </Paragraph>
  </>
);

export default DisputesOtherUsers;
