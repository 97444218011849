import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

import WebToCaseForm from 'components/Forms/SFForms/WebToCaseForm';
import ContactInfoBlock from 'components/InfoPagesComponents/ContactInfoBlock/ContactInfoBlock';
import {
  PageWrapper,
  HeaderTitle,
  InfoTitle,
  FormBlockWrapper,
} from './styles';

const ContactUsHelpPage = () => {
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const recaptchaRef = useRef<any>();

  const getRecaptchaToken = async () => {
    const token = await recaptchaRef.current.executeAsync();
    setRecaptchaToken(token);
  };

  useEffect(() => {
    getRecaptchaToken();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help Request</title>
        <meta name="description" content="Help Request" />
      </Helmet>
      <PageWrapper>
        <FormBlockWrapper>
          <HeaderTitle>SUBMIT HELP REQUEST</HeaderTitle>
          <InfoTitle>
            Need help? Fill out the form below and our customer service team
            will get back to you as soon as possible.
          </InfoTitle>
          <WebToCaseForm recaptchaToken={recaptchaToken} />
        </FormBlockWrapper>

        <ContactInfoBlock />
      </PageWrapper>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        size="invisible"
      />
    </>
  );
};

export default ContactUsHelpPage;
