import styled from 'styled-components';
import { COLORS, FONTS, BREAKPOINTS } from 'styles';

export const StyledContainer = styled.div`
  background: #eeeae6;
  text-align: center;
  padding: 20px 0;
`;

export const StyledCustomNavLink = styled.span`
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  color: ${COLORS.black};
  cursor: pointer;
  font-family: ${FONTS.Helvetica.regular};

  @media (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }

  @media (min-width: ${BREAKPOINTS.l}px) {
    font-size: 24px;
  }
`;
