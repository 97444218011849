import { useForm } from 'react-hook-form';
import FormInputController from 'components/Inputs/FormInputController';
import { FormWrapper } from './styles';

const SavedSearchUpdateForm = ({
  onSubmit,
  onError,
  savedSearch,
}: {
  onSubmit: (value: any) => void;
  onError: (e: any, errors: any) => void;
  savedSearch: string;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ savedSearch: string }>({
    defaultValues: { savedSearch },
  });

  return (
    <FormWrapper
      component="form"
      id="saved-search-form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      <FormInputController
        control={control}
        name="savedSearch"
        label=""
        error={!!errors.savedSearch}
        errorMessage={
          errors?.savedSearch
            ? errors.savedSearch.message
            : 'Incorrect or empty entry'
        }
      />
    </FormWrapper>
  );
};

export default SavedSearchUpdateForm;
