import { useState } from 'react';
import { AnimatedLinkUpdated } from 'components';
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon';
import {
  CardBottom,
  CardTop,
  ViewDetailsButton,
} from 'components/ProductCard/styles';
import ROUTES from 'router/Routes';
import { BrandType } from 'types/BrandsTypes';
import { CardWrapper, BrandName, ShopNow } from './styles';

const BrandCard = ({
  brand,
  handleLikeIconClick,
}: {
  brand: BrandType;
  handleLikeIconClick?: (brandId: string) => Promise<boolean>;
}) => {
  const [hover, setHover] = useState(false);
  const { businessName, image, isFavorite, id } = brand;

  const toggleFavoriteIcon = async () => {
    handleLikeIconClick && (await handleLikeIconClick(id));
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <CardWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardTop
        backgroundImage={image}
        hover={hover}
        backgroundSize="cover"
        height="90%"
      />
      <CardBottom minHeight="10%">
        <BrandName>{businessName}</BrandName>
        <FavoriteIcon
          onClick={toggleFavoriteIcon}
          isFavorite={isFavorite}
          iconPosition={{ top: '10%', right: '0' }}
        />
      </CardBottom>
      <ViewDetailsButton show={hover}>
        <AnimatedLinkUpdated
          to={`${ROUTES.PRODUCTS}/shop/seller?c_marketplacerSellerName=${brand.businessName}`}
          type="white"
          fontSize="20px"
        >
          <ShopNow>shop now</ShopNow>
        </AnimatedLinkUpdated>
      </ViewDetailsButton>
    </CardWrapper>
  );
};

export default BrandCard;
