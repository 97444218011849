import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';

export const getShopperCategoriesByIdsThunk: AsyncThunkPayloadCreator<
  any,
  string | undefined,
  { rejectValue: ErrorResponse }
> = async (ids, { rejectWithValue }) => {
  try {
    const params = {
      ids: ids || 'root',
      siteId: process.env.REACT_APP_SITE_ID,
      levels: 2,
    };

    const response = await axiosInstance.get(
      endpoints.shopperProducts.categories,
      { params },
    );

    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getShopperCategoryByIdThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (id, { rejectWithValue }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID, levels: 2 };

    const response = await axiosInstance.get(
      endpoints.shopperProducts.category(id),
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
