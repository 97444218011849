import { Loader } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import { ProductType } from 'types/ProductsTypes';
import { CardsWrapper, ProductCardWrapper } from './styles';

const ProductCards = ({
  total,
  productList,
  isLoading,
  emptyListTitle,
}: {
  total: number;
  productList: ProductType[];
  isLoading: boolean;
  emptyListTitle: string;
}) => {
  return (
    <CardsWrapper container rowSpacing={2} columnSpacing={{ l: 2, xxxs: 1 }}>
      {total === 0 && !isLoading && emptyListTitle}
      {!isLoading && productList?.length
        ? productList.map((product: ProductType) => (
            <ProductCardWrapper
              item
              key={product.productId}
              // @ts-ignore
              xxxs={6}
              l={3}
            >
              <ProductCard product={product} />
            </ProductCardWrapper>
          ))
        : null}
      {isLoading && <Loader />}
    </CardsWrapper>
  );
};

export default ProductCards;
