import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import ProductSearchContent from 'components/Modals/ModalContent/ProductSearchContent';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { COLORS } from 'styles';

const SearchPage = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const closeModal = useCallback(
    () => dispatch(modalActions.hideAllModals()),
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      modalActions.showModal({
        id: 'PRODUCT_SEARCH_MODAL',
        modalContent: (
          <ProductSearchContent
            closeModal={closeModal}
            searchQuery={search.substring(1)}
          />
        ),
        boxStyle: {
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          backgroundColor: `${COLORS.white}`,
          overflow: 'auto',
        },
      }),
    );
  }, []);

  return <div />;
};

export default SearchPage;
