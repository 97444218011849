import { ChangeEvent } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { SearchIcon } from 'assets/svg';
import { COLORS, FONTS } from 'styles';
import { ClearButton } from './styles';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-root': {},
  '& .Mui-focused svg': { color: COLORS.accent },
  '& .MuiInput-underline:after': {
    borderBottom: `1px solid ${COLORS.accent}`,
  },
  '& .MuiInputBase-root.MuiInput-root:hover': {
    '&:before': {
      borderBottom: `1px solid ${COLORS.accent}`,
    },
    '& svg': { color: COLORS.accent },
  },
  '& input': {
    padding: '10px 0 !important', // override inline-style
    '&::placeholder': {
      fontStyle: `${FONTS.TTNorms.regular}`,
      fontSize: '14px',
    },
  },
});

const SearchInputWithIcon = ({
  searchValue,
  handleSubmit,
  handleChange,
  clearInput,
  placeholder,
}: {
  searchValue: string;
  handleSubmit: (e: { preventDefault: () => void }) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clearInput: () => void;
  placeholder?: string;
}) => {
  return (
    <CssTextField
      fullWidth
      id="input-with-icon-textfield"
      placeholder={placeholder || 'Search Order'}
      InputProps={{
        startAdornment: <InputAdornment position="start" />,
        endAdornment: (
          <InputAdornment position="end">
            {searchValue ? (
              <ClearButton aria-label="clear" onClick={clearInput}>
                CLEAR
              </ClearButton>
            ) : (
              <SearchIcon
                style={{
                  transition: '0.3s',
                  cursor: 'pointer',
                  width: '24px',
                  height: '24px',
                }}
                onClick={handleSubmit}
              />
            )}
          </InputAdornment>
        ),
      }}
      value={searchValue}
      onChange={handleChange}
      variant="outlined"
      autoComplete="off"
    />
  );
};

export default SearchInputWithIcon;
