import ImageListItem from '@mui/material/ImageListItem';

import { ImageType } from 'types/ProductsTypes';

const ImageListItemComponent = ({ item }: { item: ImageType }) => {
  return (
    <ImageListItem key={item.link}>
      <img src={`${item.link}`} srcSet={`${item.link}`} alt={item.alt} />
    </ImageListItem>
  );
};

export default ImageListItemComponent;
