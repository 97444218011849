import styled from 'styled-components';
import Box from '@mui/material/Box';

import CustomNavLink from 'components/Links/CustomNavLink';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 46px;

  @media (max-width: 1000px) {
    font-size: 28px;
  }
`;

export const StyledBox = styled(Box)`
  flex-grow: 1;
  padding: 0 80px;

  @media (max-width: 1150px) {
    padding: 0 35px;
  }
`;

export const NavLink = styled(CustomNavLink)`
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  color: ${COLORS.black};
  transition: 0.3s;

  &:hover {
    color: ${COLORS.accent};
  }

  /* @media (max-width: 1000px) {
    font-size: 28px;
  } */
`;
