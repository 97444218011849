import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material';
import { COLORS } from 'styles';

const BpIcon = styled('span')(({ theme }) => ({
  width: 15,
  height: 15,
  outline: `1px solid ${COLORS.semiDark}70`,
  '.Mui-focusVisible &': {
    outline: `2px auto ${COLORS.accent}`,
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    width: 13,
    height: 13,
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none'" +
      " xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.04281 4.00018L9.5317 1.72859L8.12822" +
      " 0.819937L6.68249 3.02568L8.04281 4.00018Z' fill='black'/%3E%3Cpath d='M7.40854 4.96789L6.04821 " +
      " 3.99339L3.7644 7.47779L1.27544 5.6458L0.280041 6.98103L4.1986 9.86528L7.40854 4.96789Z' fill='black'/%3E%3C/svg%3E%0A\")",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    content: '""',
  },
});

const CartItemCheckbox = ({
  id,
  checked,
  handleChange,
}: {
  id?: string;
  checked: boolean;
  handleChange: any;
}) => {
  return (
    <Checkbox
      checked={checked}
      id={id}
      disableRipple
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      sx={{
        color: COLORS.border,
        borderWidth: 1,
        '&.Mui-checked': {
          color: COLORS.accent,
        },
      }}
    />
  );
};

export default CartItemCheckbox;
