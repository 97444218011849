import { ArrowRightIcon } from 'assets/svg';

interface IArrows {
  className?: string;
  onClick?: (prop: any) => any;
  style?: any;
  color: string;
}

export const NextArrow = ({ className, style, color, onClick }: IArrows) => {
  return (
    <ArrowRightIcon
      className={className}
      style={{
        ...style,
        color: `${color}`,
        display: 'block',
      }}
      onClick={onClick}
    />
  );
};

export const PrevArrow = ({ className, style, color, onClick }: IArrows) => {
  return (
    <ArrowRightIcon
      className={className}
      style={{
        ...style,
        color: `${color}`,
        transform: 'rotate(180deg)',
      }}
      onClick={onClick}
    />
  );
};
