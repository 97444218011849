import { useState, ChangeEvent } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { StyledFormControlLabel } from './styles';

interface IRadioButtonItem {
  value: string;
  label: string;
}

const RadioButtonsGroup = ({
  items,
  selectValue,
}: {
  items: IRadioButtonItem[];
  selectValue: (value: string) => void;
}) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    selectValue(val);
    setValue(val);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="radio-buttons-group"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {items.map((item: IRadioButtonItem) => (
          <StyledFormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
