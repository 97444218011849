import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  InputAdornment,
} from '@mui/material';
import styled from 'styled-components';
import { Typography } from 'components';

import { COLORS } from 'styles';
import { CVCTooltipIcon } from 'assets/svg';
import { TooltipCardBlock } from './styles';
import CVCVisa from '../../assets/png/cvc-visa.png';
import CVCAmex from '../../assets/png/cvc-amex.png';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.black,
    maxWidth: 260,
    padding: 10,
  },
}));

const CardCvcTooltip = () => {
  return (
    <StyledTooltip
      sx={{ zIndex: 5 }}
      title={
        <>
          <TooltipCardBlock>
            <img src={CVCVisa} alt="cvc-visa" />
            <Typography color="primaryInverted">
              VISA, MasterCard, Discover: The CVC code is the 3-digit after the
              credit card number on the back of the card in the signature area.
            </Typography>
          </TooltipCardBlock>
          <TooltipCardBlock style={{ marginTop: 10 }}>
            <img src={CVCAmex} alt="cvc-amex" />
            <Typography color="primaryInverted">
              If you have an American Express® Card, you will find the 4-digit
              credit card CVC on the front.
            </Typography>
          </TooltipCardBlock>
        </>
      }
      placement="right-end"
      enterTouchDelay={0}
      leaveTouchDelay={9000}
    >
      <InputAdornment position="end">
        <CVCTooltipIcon />
      </InputAdornment>
    </StyledTooltip>
  );
};

export default CardCvcTooltip;
