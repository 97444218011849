export const getSubstring = (string: string, char1: string, char2: string) => {
  const char1Index = string?.indexOf(char1);
  const char2Index = string?.lastIndexOf(char2);

  if (char1Index === -1 || char2Index === -1) {
    return '';
  }

  return string?.slice(char1Index + char1.length, char2Index);
};

export const parseMarketplacerData = (data: string | any[] | Object) => {
  if (data && typeof data === 'string') {
    return JSON.parse(data);
  }

  return data;
};

export const parseStatus = (status: string) => {
  return status.split('_').join(' ');
};
