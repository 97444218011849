import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const CartItemWrapper = styled.div<{
  orderable?: boolean;
}>`
  display: flex;
  padding: 16px 0 10px 0;
  opacity: ${({ orderable }) => (orderable ? 1 : 0.3)};
  border-bottom: 1px solid ${COLORS.black};
`;

export const CartItemInfoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CartItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CartItemText = styled(Typography).attrs({
  type: 'title6Helv',
})`
  text-transform: uppercase;
`;

export const CartProductNameText = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  margin-top: 5px;
`;

export const CartItemVariationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuantityCounter = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 5px;
  height: 7px;
`;

export const OptionChangeIcon = styled.button`
  cursor: pointer;
  font-family: ${FONTS.Helvetica.medium};
`;

export const CartActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.6;
  margin-bottom: 5px;

  & > button {
    cursor: pointer;
    background: none;

    @media (max-width: ${BREAKPOINTS.s}px) {
      font-size: 8px;
      line-height: 10px;
    }

    &:first-child {
      margin-bottom: 10px;

      @media (max-width: ${BREAKPOINTS.s}px) {
        margin-bottom: 5px;
      }
    }
  }
`;
