import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share';
import ROUTES from 'router/Routes';
import { FacebookIcon, PinterestIcon, TwitterIcon } from 'assets/svg';
import {
  getAuthError,
  getCustomerData,
  getCustomerLoader,
  getIsLoggedIn,
  getOrderDetails,
} from 'eg_SFCC_FE_core/store/selectors';
import useAuth from 'hooks/useAuth';
import CheckoutRegistrationForm from 'components/Forms/RegistrationForm/CheckoutRegistrationForm';
import { formatNameForUrl } from 'helpers/formatters';
import { useCheckoutNextSteps } from '../CheckoutPage';
import {
  ShareText,
  ShareIconsWrapper,
  PageWrapper,
  Title,
  ConfirmationText,
  FormWrapper,
} from './styles';
import { OutletPage } from '../styles';

const sharedTitle =
  'I supported Empower Global Community by purchasing the product';
const getSharedUrl = (productId: string, productName: string) =>
  `${window.location.origin}${ROUTES.PRODUCTS}/${formatNameForUrl(
    productName,
  )}-${productId}`;

const Confirmation = () => {
  const { pathname } = useLocation();
  const { hasAgeRestriction, registerCustomer } = useAuth();
  const { trackCheckoutEvent } = useCheckoutNextSteps();
  const { firstName } = useSelector(getCustomerData);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const authError = useSelector(getAuthError);
  const isLoading = useSelector(getCustomerLoader);
  const orderDetails = useSelector(getOrderDetails);
  const sharedUrl = getSharedUrl(
    orderDetails?.productItems[0].productId || '',
    orderDetails?.productItems[0].productName || '',
  );
  const sharedImage = orderDetails?.productItems[0].image?.link;

  useEffect(() => {
    trackCheckoutEvent('purchase');

    if (pathname === ROUTES.CHECKOUT_CONFIRMATION) {
      const handlePopState = () => window.history.go(1);

      window.history.pushState(null, window.location.href);
      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [pathname]);

  return (
    <OutletPage>
      <PageWrapper>
        <Title>
          Thank you for your order{firstName ? `, ${firstName}` : ''}!
        </Title>
        <ConfirmationText>
          Your order has been confirmed. Thank you for supporting the Empower
          Global mission.
        </ConfirmationText>
        <div style={{ maxWidth: 391, width: '100%' }}>
          {sharedUrl && sharedImage ? (
            <div>
              <ShareText>share</ShareText>
              <ShareIconsWrapper>
                <FacebookShareButton
                  aria-label="share on facebook"
                  url={sharedUrl}
                >
                  <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton
                  aria-label="share on twitter"
                  url={sharedUrl}
                  title={sharedTitle}
                >
                  <TwitterIcon />
                </TwitterShareButton>
                <PinterestShareButton
                  aria-label="share on pinterest"
                  media={sharedImage}
                  url={sharedUrl}
                  description="12345"
                >
                  <PinterestIcon />
                </PinterestShareButton>
              </ShareIconsWrapper>
            </div>
          ) : null}
        </div>
      </PageWrapper>

      {!isLoggedIn && (
        <FormWrapper>
          <Title style={{ marginBottom: 15 }}>create account</Title>
          <CheckoutRegistrationForm
            ageRestriction={hasAgeRestriction}
            authError={authError}
            isLoading={isLoading}
            registerCustomer={registerCustomer}
          />
        </FormWrapper>
      )}
    </OutletPage>
  );
};

export default Confirmation;
