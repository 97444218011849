import styled from 'styled-components';
import { TextButton, Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { HamburgerIcon, UpdatedLogoIcon } from 'assets/svg';

export const MenuTitle = styled(Typography).attrs({
  type: 'title4Helv',
})`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
`;

export const RotatedMinus = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  cursor: pointer;
  right: -15px;

  &:after {
    content: '';
    position: absolute;
    background: ${COLORS.black};
  }

  &:after {
    top: 50%;
    left: 4px;
    right: 4px;
    height: 1px;
    transform: translateY(-50%);
  }
`;

export const RotatedPlus = styled(RotatedMinus)<{
  white?: boolean;
}>`
  &:before,
  :after {
    content: '';
    position: absolute;
    background: ${COLORS.black};
  }

  &:before {
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 1px;
    transform: translateX(-50%);
  }
`;

export const HamburgerTitle = styled.div`
  border-bottom: 1px solid ${COLORS.lightGrey};
  padding: 20px 20px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 16px 15px;
  }
`;

export const CatalogWrapper = styled.div`
  padding: 30px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 15px;
  }
`;

export const StyledHamburgerIcon = styled(HamburgerIcon)`
  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 20px;
  }
`;

export const StyledTextButton = styled(TextButton).attrs({
  fontStyle: {
    fontSize: 12,
    fontFamily: FONTS.Helvetica.medium,
    color: COLORS.grey,
  },
})``;

export const BottomWrapper = styled.div`
  padding: 20px 20px 20px 25px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 16px 15px;
  }
`;

export const LoginButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.lightGrey};
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const Divider = styled.div`
  margin: 0 10px;
`;

export const Trademark = styled(Typography).attrs({
  type: 'smallHelv',
})`
  font-size: 10px;
  margin-top: 12px;
  color: ${COLORS.pale};
  text-transform: uppercase;
  text-align: center;
`;

export const Logo = styled(UpdatedLogoIcon)`
  width: 200px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 135px;
  }
`;

export const StyledCategoryTextButton = styled(TextButton).attrs({
  style: {
    marginBottom: 19,
  },
  fontStyle: {
    fontSize: 14,
    color: COLORS.black,
    fontFamily: FONTS.Helvetica.medium,
  },
})``;

export const StyledSubcategoryTextButton = styled(TextButton).attrs({
  style: {
    marginBottom: 19,
  },
  fontStyle: {
    fontSize: 12,
    color: COLORS.black,
    opacity: 0.6,
    fontFamily: FONTS.Helvetica.regular,
  },
})``;

export const StyledViewAllTextButton = styled(TextButton).attrs({
  fontStyle: {
    textDecoration: 'underline',
    fontSize: 12,
    color: COLORS.black,
    opacity: 0.6,
    fontFamily: FONTS.Helvetica.regular,
  },
})``;
