import { createSlice } from '@reduxjs/toolkit';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType, WishListItemType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { ProductType } from 'types/ProductsTypes';

export const CUSTOMER_PRODUCT_LISTS_SLICE_NAME = 'customerProductLists';

type CustomerProductListsType = StateType<{
  customerProductListItems: WishListItemType[];
  id: string;
}> & {
  wishlistProducts: ProductType[];
};

const initialState: CustomerProductListsType = {
  pending: false,
  error: null,
  result: {
    customerProductListItems: [],
    id: '',
  },
  wishlistProducts: [],
};

const customerProductListsSlice = createSlice({
  name: CUSTOMER_PRODUCT_LISTS_SLICE_NAME,
  initialState,
  reducers: {
    reset: (state: StateType) => {
      state.result.customerProductListItems = [];
      state.pending = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getCustomerProductLists.pending, onPending);
    builder.addCase(
      AsyncThunks.getCustomerProductLists.fulfilled,
      (state, action) => {
        if (action.payload.customerProductListItems) {
          state.result = action.payload;
        } else {
          state.result = {
            ...action.payload,
            customerProductListItems: [],
          };
        }
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getCustomerProductLists.rejected, onError);

    builder.addCase(AsyncThunks.getWishlistProducts.pending, onPending);
    builder.addCase(
      AsyncThunks.getWishlistProducts.fulfilled,
      (state, action) => {
        state.wishlistProducts = action.payload?.map((item: any) => ({
          productId: item.id,
          productName: item.name,
          price: item.price,
          image: item.imageGroups[0]?.images[0],
          c_prices: item.c_prices,
          c_brandName: item.brand,
          variationAttributes: item.variationAttributes,
        }));
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getWishlistProducts.rejected, onError);

    builder.addCase(
      AsyncThunks.createCustomerProductListItem.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.createCustomerProductListItem.fulfilled,
      (state, action) => {
        if (state.result.customerProductListItems) {
          state.result.customerProductListItems = [
            ...state.result.customerProductListItems,
            action.payload,
          ];
        } else {
          state.result.customerProductListItems = [action.payload];
        }

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(
      AsyncThunks.createCustomerProductListItem.rejected,
      onError,
    );

    builder.addCase(
      AsyncThunks.deleteCustomerProductListItem.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.deleteCustomerProductListItem.fulfilled,
      (state, action) => {
        state.result.customerProductListItems =
          state.result.customerProductListItems.filter(
            (item: WishListItemType) => {
              return item.id !== action.payload;
            },
          );

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(
      AsyncThunks.deleteCustomerProductListItem.rejected,
      onError,
    );
  },
});

export const customerProductListsActions = customerProductListsSlice.actions;
export const customerProductListsReducer = customerProductListsSlice.reducer;
