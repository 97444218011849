import styled from 'styled-components';
import { CustomNavLink, Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    flex-direction: column;
  }
`;

export const OrderStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 7px 0 14px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-wrap: wrap;
    gap: 7px;
  }
`;

export const OrderStatusTitle = styled(Typography).attrs({
  type: 'subtitle2Helv',
})`
  flex-basis: 40%;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-basis: 100%;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const OrderTitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OrderHeaderTitle = styled(Typography).attrs({
  type: 'smallHelv',
})`
  text-transform: capitalize;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const OrderHelperTitle = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-transform: capitalize;
  padding-right: 25px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const ItemStatus = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  margin-bottom: 14px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-bottom: 8px;
  }
`;

export const ProductBrandName = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const Title = styled(Typography)`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 10px;
  line-height: 12px;
  color: ${COLORS.grey};
  text-transform: capitalize;
  padding-right: 4px;
`;

export const DetailsTitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: ${COLORS.black};
  opacity: 0.6;
  padding-left: 20px;
  padding-right: 4px;
  width: 50%;

  &:first-child {
    border-right: 1px solid ${COLORS.buttonBorder};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const CardBodyWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const CardImage = styled.img`
  width: 115px;
  height: 105px;
  object-fit: cover;
  margin-right: 20px;
`;

export const ProductTitle = styled(Typography)`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 11px;
  color: ${COLORS.black};
  text-transform: capitalize;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;

export const ProductLink = styled.a`
  color: ${COLORS.black};
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  div {
    display: flex;
  }
`;

export const MobileBottomWrapper = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.s}px) {
    display: flex;
    justify-content: flex-end;
    padding: 15px 25px;
    border-top: 1px solid ${COLORS.border};
  }
`;

// order details styles

export const StyledLink = styled(CustomNavLink)`
  width: fit-content;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  color: ${COLORS.black};
  font-size: 12px;
  transition: 0.4s ease;
  opacity: 0.6;
  border: none;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 8px;
  }
`;

export const PriceWrapper = styled.div`
  & p {
    font-size: 11px !important;
    @media (max-width: ${BREAKPOINTS.s}px) {
      font-size: 10px !important;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 6px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
  }
`;

export const ReturnInfoMessage = styled(Typography)`
  align-self: end;
  margin: 0 0 0 auto;
  padding-left: 15px;
  color: #949494;
  text-align: right;

  @media (max-width: ${BREAKPOINTS.s}px) {
    align-self: start;
    text-align: left;
    padding-left: 0;
    margin: 0;
  }
`;

export const OrderTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
`;

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const OrderAccordionContentWrapper = styled.div`
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 20px 0 10px;
  }
`;

export const OrderCardsBlock = styled.div`
  width: 48%;
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 100%;
  }
`;

export const OrderCardContentTitle = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-decoration: underline;
  padding-bottom: 24px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    display: none;
  }
`;

export const OrderInfoBlocks = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  text-align: end;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 15px;
    text-align: start;
  }
`;

export const PaymentSummaryWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    justify-content: space-between;
  }
`;

export const OrderInfoTitle = styled(Typography)`
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0.6;
  padding-bottom: 6px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 8px;
    padding-bottom: 4px;
  }
`;

export const OrderInfoText = styled(Typography)`
  font-size: 12px;
  opacity: 0.6;
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 8px;
  }
`;

export const OrderInfoAddress = styled.div`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 12px;
  opacity: 0.6;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 8px;
  }
`;

export const OrderTotalText = styled(Typography).attrs({
  type: 'nav',
})`
  opacity: 0.6;
  font-family: ${FONTS.Helvetica.regular};
`;

export const OrderPriceRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  p:nth-child(2) {
    width: 60px;
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    justify-content: flex-start;

    p:nth-child(1) {
      width: 70px;
    }
    p:nth-child(2) {
      width: fit-content;
    }
  }
`;

export const ReturnStatusesWrapper = styled.div`
  position: absolute;
  right: 0;
  max-width: 200px;
  max-height: 200px;
  overflow: auto;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    position: static;
  }
`;

export const ReturnStatus = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  font-family: ${FONTS.Helvetica.medium};
  opacity: 0.6;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
