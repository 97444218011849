import { NavLink } from 'react-router-dom';
import ROUTES from 'router/Routes';
import { List, MainTitle, NestedList, Paragraph, Title } from '../styles';

const TrackingTools = () => {
  return (
    <>
      <MainTitle>TRACKING TOOLS, ADVERTISING, AND OPT-OUT</MainTitle>
      <Paragraph>
        The Services may use cookies and similar technologies such as pixel
        tags, web beacons, clear GIFs, Javascript, local storage, and other
        technologies (collectively “Cookies”) to enable our servers to recognize
        your web browser, tell us how and when you visit and use our Services,
        analyze trends, learn about our user base, and operate and improve our
        Services. Cookies are small pieces of data, usually text files. We may
        also supplement the information we collect from you with information
        received from third parties, including third parties that have placed
        their own Cookies on your device(s). Please note that because of our use
        of Cookies, the Services do not support “Do Not Track” requests sent
        from a browser at this time.
      </Paragraph>
      <Paragraph>We use the following types of Cookies:</Paragraph>
      <List>
        <li>
          <Title>Essential Cookies</Title>
          <NestedList>
            <li>
              This includes Cookies that are required for providing you with
              features or services that you have requested. For example, certain
              Cookies provide you access to our Services. Disabling these
              Cookies may make certain features and services unavailable.
              Essential Cookies also work with applications and tools that are
              designed to identify irregular website behavior, prevent
              fraudulent activity, and improve security, or that allow you to
              make use of our functionality such as utilizing our shopping cart
              features.
            </li>
          </NestedList>
        </li>
        <li>
          <Title>Performance/Analytical Cookies</Title>
          <NestedList>
            <li>
              Performance/Analytical Cookies allow us to understand how visitors
              use our Services. They do this by collecting information about the
              number of visitors to the Services, what pages visitors view on
              our Services, and how long visitors are viewing pages on the
              Services. Performance/Analytical Cookies also help us measure the
              performance of our advertising campaigns in order to help us
              improve our campaigns and the Services’ content for those who
              engage with our advertising. For example, Google LLC (“Google”)
              uses cookies in connection with its Google Analytics services.
              Google’s ability to use and share information collected by Google
              Analytics about your visits to the Services is subject to the
              Google Analytics Terms of Use and the Google Privacy Policy. You
              have the option to opt out of Google’s use of Cookies by visiting
              the Google Advertising opt-out page at{' '}
              <a href="www.google.com/privacy_ads.html">
                www.google.com/privacy_ads.html
              </a>{' '}
              or the Google Analytics opt-out browser add-on at{' '}
              <a href="https://tools.google.com/dlpage/gaoptout/">
                https://tools.google.com/dlpage/gaoptout/
              </a>
              .
            </li>
          </NestedList>
        </li>
        <li>
          <Title>Functional Cookies</Title>
          <NestedList>
            <li>
              Functional Cookies are used to record your choices and settings,
              including recognizing you when you return to our Services, and
              remembering your specific preferences, interests, and past items
              viewed. These Cookies help us to personalize our content for you,
              greet you by name, and remember your preferences (ex: your choice
              of language or region).
            </li>
          </NestedList>
        </li>
        <li>
          <Title>Retargeting/Advertising Cookies</Title>
          <NestedList>
            <li>
              Retargeting/Advertising Cookies collect data about your online
              activity and identify your interest so that we can provide
              advertising that we believe are relevant to you. For more
              information about this, please see the section below titled
              “Information about Interest-Based Advertisements.”
            </li>
          </NestedList>
        </li>
      </List>

      <Paragraph space>
        You may stop or restrict the placement of Cookies on your device by
        adjusting your preferences on your browser, device, or via our website{' '}
        <NavLink to={ROUTES.COOKIES_PREFERENCES}>Cookie Preferences</NavLink>.
        You can also delete cookies placed on your browser or device by its
        applicable settings. Most browsers have an option for turning off the
        Cookie feature, which will prevent your browser from accepting new
        Cookies, as well as (depending on the sophistication of your browser
        software) allow you to decide on acceptance of each new Cookie in a
        variety of ways. However, if you adjust your preferences, or delete the
        Cookies that are already on your device, our Services may not work
        properly. Please note that cookie-based opt-outs are not effective on
        mobile applications. However, you may opt out of personalized
        advertisements on some mobile applications by following the instructions
        for{' '}
        <a
          href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>
        ,{' '}
        <a
          href="https://support.apple.com/en-us/HT202074"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>{' '}
        and{' '}
        <a
          href="https://thenai.org/opt-out/mobile-opt-out/"
          target="_blank"
          rel="noreferrer"
        >
          others
        </a>
        .
      </Paragraph>
      <Paragraph space>
        Please note you must separately opt out within each browser, and on each
        device.{' '}
      </Paragraph>

      <Title space>A. INFORMATION ABOUT INTEREST-BASED ADVERTISEMENTS</Title>
      <Paragraph space>
        We may serve advertisements, and also allow third-party ad networks,
        including third-party ad servers, ad agencies, ad technology vendors and
        research firms, to serve advertisements through the Services. These
        advertisements may be targeted to users who fit certain general profile
        categories or display certain preferences or behaviors (“Interest-Based
        Ads”). Information for Interest-Based Ads (including Personal
        Information) may be provided to us by you, or derived from the usage
        patterns of particular users on the Services and/or services of third
        parties. Such information may be gathered through tracking users’
        activities across time and unaffiliated properties, including when you
        leave the Services. To accomplish this, we, or our service providers,
        may deliver Cookies, including a file (known as a “web beacon”) from an
        ad network to you through the Services. Web beacons allow ad networks to
        provide anonymized, aggregated auditing, research, and reporting for us
        and for advertisers. Web beacons also enable ad networks to serve
        targeted advertisements to you when you visit other websites. Web
        beacons allow ad networks to view, edit or set their own Cookies on your
        browser, just as if you had requested a web page from their site.
      </Paragraph>
    </>
  );
};

export default TrackingTools;
