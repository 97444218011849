import { memo, ReactNode } from 'react';
import { CustomNavLink } from 'components';

import { AnimatedLinkWrapper, LinkType } from './styles';

interface IAnimatedLink {
  children: ReactNode;
  type: LinkType;
  to?: string;
  fontSize?: string;
  onClick?: () => void;
  className?: string;
}

const AnimatedLink = ({
  children,
  type,
  to,
  fontSize,
  className,
  onClick,
}: IAnimatedLink) => {
  if (to) {
    return (
      <CustomNavLink to={to}>
        <AnimatedLinkWrapper
          className={className}
          type={type}
          fontSize={fontSize}
          onClick={onClick}
        >
          {children}
        </AnimatedLinkWrapper>
      </CustomNavLink>
    );
  }
  return (
    <AnimatedLinkWrapper
      className={className}
      type={type}
      fontSize={fontSize}
      onClick={onClick}
    >
      {children}
    </AnimatedLinkWrapper>
  );
};

export default memo(AnimatedLink);
