import {
  PaymentPage,
  ShippingDetailsPage,
  CheckoutPersonalInfoPage,
} from 'pages';

const CheckoutDetails = () => {
  return (
    <div>
      <CheckoutPersonalInfoPage />
      <ShippingDetailsPage />
      <PaymentPage />
    </div>
  );
};

export default CheckoutDetails;
