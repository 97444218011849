import styled from 'styled-components';
import { ColoredButton, Typography } from 'components';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  height: calc(100vh - ${HEADER_NAV_HEIGHT}px);
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.44);
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1000px) {
    height: 400px;
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
`;

export const ContentsWrapper = styled.div`
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media (max-width: 1000px) {
    width: 200px;
  }
`;

export const WhiteLogo = styled.img`
  width: 190px;

  @media (max-width: 1000px) {
    width: 60px;
  }
`;

export const Title = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.Bison.bold};
  text-transform: uppercase;
  font-size: 100px;
  text-align: center;
  letter-spacing: 0.2em;
  line-height: 120px;

  @media (max-width: 1000px) {
    font-family: ${FONTS.TTNorms.bold};
    font-size: 30px;
    line-height: 40px;
  } ;
`;

export const StyledColoredButton = styled(ColoredButton)`
  background-color: ${COLORS.accent};
  width: 210px;

  p {
    color: ${COLORS.black};
    font-size: 20px;
    font-family: ${FONTS.TTNorms.medium};
  }
`;
