import { LineWrapper, StrokeText } from './styles';

const LineTitle = ({
  text,
  marginLeft,
  accentTextPosition = 2,
}: {
  text: string;
  marginLeft?: number;
  accentTextPosition?: number;
}) => {
  return (
    <LineWrapper>
      <StrokeText marginLeft={marginLeft}>
        {`${text} `.repeat(accentTextPosition - 1)}
      </StrokeText>
      <StrokeText accent>{text}</StrokeText>
      <StrokeText>{`${text} `.repeat(20)}</StrokeText>
    </LineWrapper>
  );
};

export default LineTitle;
