import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useAuth from 'hooks/useAuth';
import { CrossIcon, HalfWBackground } from 'assets/svg';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getCustomerData,
  getCustomerLoader,
  getIsLoggedIn,
} from 'eg_SFCC_FE_core/store/selectors';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import LoyaltyRegistrationForm from 'components/Forms/LoyaltyRegistrationForm/LoyaltyRegistrationForm';
import { Typography } from 'components/Typography/Typography';
import { formatSuggestedAddress } from 'helpers/formatters';
import { AddressFormFieldsType } from 'types/AccountTypes';
import {
  CrossIconWrapper,
  LoyaltyFormTitle,
  LoyaltyFormWrapper,
  LoyaltyLineTitle,
  LoyaltyRegistrationContentWrapper,
} from './styles';

const LoyaltyRegistrationContent = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { hasAgeRestriction, registerCustomer } = useAuth();
  const isLoading = useSelector(getCustomerLoader);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const customerData = useSelector(getCustomerData);
  const [addressValues, setAddressValues] =
    useState<CustomerAddressType | null>(null);
  const [suggestedAddress, setSuggestedAddress] =
    useState<AddressFormFieldsType>();

  const updatePersonalInfo = useCallback(async (customerParams: any) => {
    const response = await dispatch(AsyncThunks.updateCustomer(customerParams));

    // @ts-ignore
    if (!response.error) {
      closeModal();
    }
  }, []);

  const addAddress = useCallback(
    async (address: CustomerAddressType) => {
      const response = await dispatch(
        AsyncThunks.createCustomerAddress(address),
      );

      if (response?.payload.statusCode === 'has_suggested_address') {
        try {
          const formattedSuggestedAddress = formatSuggestedAddress(
            response.payload.statusMessage,
          );
          setSuggestedAddress(formattedSuggestedAddress);
        } catch (e) {
          // console.log(e)
        }
      }

      // @ts-ignore
      if (!response.error) {
        closeModal();
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (addressValues && isLoggedIn) {
      addAddress(addressValues);
    }
  }, [addressValues, isLoggedIn]);

  return (
    <LoyaltyRegistrationContentWrapper>
      <LoyaltyFormWrapper>
        <CrossIconWrapper onClick={closeModal}>
          <CrossIcon />
        </CrossIconWrapper>
        <HalfWBackground
          style={{
            position: 'absolute',
            right: '-114%',
            top: '-2%',
            height: 'inherit',
            zIndex: -1,
          }}
        />

        <LoyaltyFormTitle>Get with the program!</LoyaltyFormTitle>
        <Typography style={{ fontSize: 12, marginBottom: 20 }}>
          Join the exclusive EG Elite community and start earning rewards today!
          The more you shop, the more points you earn toward discounts on future
          purchases, invites to special events, and much more.
        </Typography>

        <LoyaltyRegistrationForm
          isLoading={isLoading}
          registerCustomer={registerCustomer}
          isLoggedIn={isLoggedIn}
          ageRestriction={hasAgeRestriction}
          customerData={customerData}
          setAddressValues={setAddressValues}
          suggestedAddress={suggestedAddress}
          setSuggestedAddress={setSuggestedAddress}
          updatePersonalInfo={updatePersonalInfo}
        />
      </LoyaltyFormWrapper>
      <LoyaltyLineTitle>
        <img
          src={require('../../../assets/png/loyalty-modal-line.png')}
          alt="join-the-community"
          style={{ width: 36, objectFit: 'cover' }}
        />
      </LoyaltyLineTitle>
    </LoyaltyRegistrationContentWrapper>
  );
};

export default LoyaltyRegistrationContent;
