import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { FONT_STYLES, TextColors, TextColorType } from 'styles';

export type TypographyProps = {
  color?: TextColorType;
  type?: keyof typeof FONT_STYLES;
  tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  style?: any;
  onClick?: (prop: any) => any;
  id?: string;
  dangerouslySetInnerHTML?: any;
  children?: ReactNode;
};
export const getCssFontStyles = (type?: keyof typeof FONT_STYLES) => {
  if (type) {
    const fontStyles = FONT_STYLES[type];
    return css`
      font-size: ${fontStyles.fontSize}px;
      line-height: ${fontStyles.lineHeight}px;
      font-family: ${fontStyles.fontFamily};
    `;
  }

  return '';
};

export const StyledP = styled.p<TypographyProps>`
  ${(p) => getCssFontStyles(p.type)}
  color: ${(p) => TextColors[p.color || 'primary']}
`;

export const StyledH1 = styled.h1<TypographyProps>`
  ${(h1) => getCssFontStyles(h1.type)}
  color: ${(h1) => TextColors[h1.color || 'primary']}
`;

export const StyledH2 = styled.h2<TypographyProps>`
  ${(h2) => getCssFontStyles(h2.type)}
  color: ${(h2) => TextColors[h2.color || 'primary']}
`;

export const StyledH3 = styled.h3<TypographyProps>`
  ${(h3) => getCssFontStyles(h3.type)}
  color: ${(h3) => TextColors[h3.color || 'primary']}
`;

export const StyledH4 = styled.h4<TypographyProps>`
  ${(h4) => getCssFontStyles(h4.type)}
  color: ${(h4) => TextColors[h4.color || 'primary']}
`;

export const StyledH5 = styled.h5<TypographyProps>`
  ${(h5) => getCssFontStyles(h5.type)}
  color: ${(h5) => TextColors[h5.color || 'primary']}
`;

export const StyledH6 = styled.h6<TypographyProps>`
  ${(h6) => getCssFontStyles(h6.type)}
  color: ${(h6) => TextColors[h6.color || 'primary']}
`;

export const Typography = ({ tag, children, ...rest }: TypographyProps) => {
  switch (tag) {
    case 'p':
      return <StyledP {...rest}>{children}</StyledP>;
    case 'h1':
      return <StyledH1 {...rest}>{children}</StyledH1>;
    case 'h2':
      return <StyledH2 {...rest}>{children}</StyledH2>;
    case 'h3':
      return <StyledH3 {...rest}>{children}</StyledH3>;
    case 'h4':
      return <StyledH4 {...rest}>{children}</StyledH4>;
    case 'h5':
      return <StyledH5 {...rest}>{children}</StyledH5>;
    case 'h6':
      return <StyledH6 {...rest}>{children}</StyledH6>;
    default:
      return <StyledP {...rest}>{children}</StyledP>;
  }
};
