import { Typography, ColoredButton, CustomNavLink } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const ProductOverviewText = styled(Typography).attrs({
  type: 'title3Helv',
})`
  text-transform: capitalize;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const AfterpayMessageWrapper = styled.div`
  .AfterpayMessage {
    margin-bottom: 0 !important;
    margin-top: 2px !important;
    font-size: 14px;
  }

  .AfterpayMessage-logoSvg {
    width: 83px;
    opacity: 0.6;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    .AfterpayMessage {
      font-size: 10px;
    }

    .AfterpayMessage-logoSvg {
      width: 55px;
    }
  }
`;

export const ProductBrandName = styled(Typography).attrs({
  type: 'title3Helv',
})`
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const RatingWrapper = styled.div`
  display: flex;
  margin: 5px 0 55px;
  height: 15px;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 5px 0 26px;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 7px;
  padding-top: 40px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 12px 33px 0;
    column-gap: 7px;
    row-gap: 5px;
    padding-top: 12px;
  }
`;

export const Tag = styled(Typography)`
  font-size: 12px;
  font-family: ${FONTS.Helvetica.medium};
  text-align: center;
  background-color: #f8f7f77d;
  padding: 5px 0;
  flex-basis: calc(33.33% - 8px);

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-basis: calc(50% - 5px);
  }
`;

export const ProductAttributeName = styled(Typography)`
  font-family: ${FONTS.Helvetica.bold};
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${COLORS.black};
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  & p {
    font-size: 20px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    height: 28px;
    & p {
      font-size: 12px;
    }
  }
`;

export const ProductDescriptionWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const ProductDescriptionTitle = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 57px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    margin-top: 27px;
  }
`;

export const ProductDescriptionText = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const ProductBrandCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 70px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: 33px;
  }
`;

export const BrandImage = styled.img`
  margin-right: 10px;
  width: auto;
  max-width: 170px;
  max-height: 180px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.s}px) {
    max-height: 110px;
    max-width: 120px;
  }
`;

export const BrandTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const BrandCardTitle = styled(Typography).attrs({
  type: 'title3Helv',
})`
  text-transform: uppercase;
  display: none;
  @media (max-width: ${BREAKPOINTS.s}px) {
    display: block;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const BrandName = styled(Typography).attrs({
  type: 'subtitle1Helv',
})`
  text-transform: capitalize;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const BrandCardText = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const StyledBrandLink = styled(CustomNavLink)`
  width: fit-content;
  text-decoration: underline;
  color: ${COLORS.black};
  font-size: 14px;
  line-height: 19px;
  opacity: 0.8;
  transition: 0.4s;
  &:hover {
    opacity: 1;
  }
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 15px;
  }
`;
