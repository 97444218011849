import { useCallback, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { BasicCheckbox } from 'components';
import PriceSlider from 'components/Sliders/PriceSlider';
import {
  IFilterProps,
  RefinementType,
  RefinementValueType,
} from 'types/ProductsTypes';
import {
  FilterTitle,
  RotatedMinus,
  RotatedPlus,
  StyledAccordion,
  StyledFormControlLabel,
} from './styles';

const AccordionComponent = ({
  refinement,
  selectFilterValues,
  filters,
  activeCategoryId,
}: {
  refinement: RefinementType;
  filters: IFilterProps | undefined;
  selectFilterValues: (filter: any) => void;
  activeCategoryId?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [categoryValues, setCategoryValues] = useState<any[]>([]);
  const isValueSelected = (value: RefinementValueType) => {
    if (!filters) return false;

    return !!filters.values.find((val: string) => {
      return val === value.value;
    });
  };

  const handlePriceRangeChange = useCallback(
    (priceRange: [number, number]) => {
      const priceValues = {
        attributeId: refinement.attributeId,
        values: [`(${priceRange[0]}..${priceRange[1]})`],
        labels: [`$${priceRange[0]} - $${priceRange[1]}`],
      };

      selectFilterValues(priceValues);
    },
    [filters],
  );

  const handleCheckboxCheck = useCallback(
    (value: RefinementValueType) => {
      const isSelected = isValueSelected(value);
      let updatedValues: IFilterProps;

      if (!filters) {
        updatedValues = {
          attributeId: refinement.attributeId,
          values: [value.value],
          labels: [value.label],
        };
      } else if (isSelected) {
        updatedValues = {
          ...filters,
          values: filters?.values?.filter((prevVal: string) => {
            return prevVal !== value.value;
          }),
          labels: filters?.labels?.filter((prevVal: string) => {
            return prevVal !== value.label;
          }),
        };
      } else {
        updatedValues = cloneDeep(filters);
        updatedValues?.values.push(value.value);
        updatedValues?.labels.push(value.label);
      }

      selectFilterValues(updatedValues);
    },
    [filters],
  );

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const flatten = (array: any) => {
    let result: any[] = [];

    array.forEach((a: any) => {
      result.push(a);
      if (Array.isArray(a.values)) {
        result = result.concat(flatten(a.values));
      }
    });

    return result;
  };

  const findById = (array: any, nodeId: string | undefined) => {
    for (const node of array) {
      if (node.value === nodeId) return node;

      if (node.values) {
        const desiredNode: any = findById(node.values, nodeId);
        if (desiredNode) return desiredNode;
      }
    }

    return false;
  };

  const isCategoryRefinement = refinement?.attributeId === 'cgid';
  useEffect(() => {
    if (isCategoryRefinement) {
      const sellerRefinement = refinement?.values?.find(
        (refVal: any) => refVal.value === 'seller',
      );

      if (sellerRefinement?.values) {
        const refinementsAfterCurrentCategory =
          findById(sellerRefinement?.values, activeCategoryId) ||
          sellerRefinement;

        const catValues = flatten(refinementsAfterCurrentCategory?.values);
        setCategoryValues(catValues);
      }
    }
  }, [refinement?.values]);

  const refValues = isCategoryRefinement ? categoryValues : refinement?.values;
  return (
    <StyledAccordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={
        { unmountOnExit: true, mountOnEnter: true } as TransitionProps
      }
      style={{
        display: refValues?.length ? 'block' : 'none',
      }}
    >
      <AccordionSummary
        aria-controls={`${refinement.attributeId}a-content`}
        id={`${refinement.attributeId}a-header`}
        sx={{ padding: 0 }}
      >
        <FilterTitle>{refinement.label}</FilterTitle>
        <div style={{ paddingRight: 10 }}>
          {expanded ? <RotatedMinus /> : <RotatedPlus />}
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 300, overflow: 'auto' }}>
        {refinement.attributeId === 'price' ? (
          <div style={{ padding: '0 20px', overflow: 'clip' }}>
            <PriceSlider
              refinement={refinement}
              handleChange={handlePriceRangeChange}
              value={filters?.values[0]}
            />
          </div>
        ) : (
          <Grid container spacing={1}>
            {refValues?.length
              ? refValues?.map((value: any, index: any) => {
                  const valueLabelMap = {
                    FEMALE: "WOMEN'S",
                    MALE: "MEN'S",
                  };
                  const filterLabel =
                    valueLabelMap[value.label as keyof typeof valueLabelMap] ||
                    value.label;

                  return (
                    <Grid
                      item
                      xs={6}
                      key={index}
                      style={{ overflow: 'hidden', width: '50%' }}
                    >
                      <StyledFormControlLabel
                        control={
                          <BasicCheckbox
                            checked={isValueSelected(value)}
                            handleChange={handleCheckboxCheck}
                            value={value}
                          />
                        }
                        label={filterLabel}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionComponent;
