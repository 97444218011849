import { MainTitle, Paragraph } from '../styles';

const Scope = () => {
  return (
    <>
      <MainTitle>SCOPE</MainTitle>
      <Paragraph>
        This Privacy Notice applies to all of our Services.{' '}
        <b>“Personal Information”</b> means any information that identifies or
        relates to a particular individual and also includes information
        referred to as “personally identifiable information” or “personal data”
        under applicable data privacy laws, rules, or regulations. This Privacy
        Notice does not cover the practices of companies we don&apos;t own or
        control, or people we don&apos;t manage.
      </Paragraph>
    </>
  );
};

export default Scope;
