import { CustomLink } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import ROUTES from 'router/Routes';
import { SavedSearchResultType } from 'types/SearchTypes';
import { SavedSearchQueryType } from 'types/AccountTypes';
import {
  SavedSearchHeaderWrapper,
  SavedSearchActionsWrapper,
  SavedSearchName,
  ProductCardsWrapper,
  ProductCardWrapper,
  SavedSearchWrapper,
} from './styles';

const SavedSearch = ({
  query,
  savedSearchResult,
  deleteSavedSearchQuery,
}: {
  query: SavedSearchQueryType;
  savedSearchResult: SavedSearchResultType;
  deleteSavedSearchQuery: (id: string) => void;
}) => {
  const linkProp = {
    to: {
      pathname: ROUTES.SEARCH,
      search: query.c_q,
    },
  };

  return (
    <SavedSearchWrapper>
      <SavedSearchHeaderWrapper>
        <CustomLink linkProps={linkProp}>
          <SavedSearchName>{query.c_name}</SavedSearchName>
        </CustomLink>
        <SavedSearchActionsWrapper>
          <CustomLink linkProps={linkProp}>View all</CustomLink>
          <button
            type="button"
            aria-label="remove saved search"
            onClick={deleteSavedSearchQuery.bind(null, query.c_id)}
          >
            Remove
          </button>
        </SavedSearchActionsWrapper>
      </SavedSearchHeaderWrapper>
      <ProductCardsWrapper>
        {savedSearchResult?.hits?.length
          ? savedSearchResult?.hits?.map((product: any) => {
              return (
                <ProductCardWrapper key={product.productId}>
                  <ProductCard product={product} />
                </ProductCardWrapper>
              );
            })
          : null}
      </ProductCardsWrapper>
    </SavedSearchWrapper>
  );
};

export default SavedSearch;
