import styled, { css } from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { Typography } from 'components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 80px 0;
  border-bottom: 1px solid ${COLORS.border};

  @media (max-width: 1150px) {
    padding: 36px 15px 0;
  }
`;

export const CardsWrapper = muiStyled(Grid)`
  min-height: 390px;

  &.MuiGrid-root {
    height: fit-content;
  }
`;

export const ProductCardWrapper = muiStyled(Grid)``;

export const TotalProductsText = styled(Typography).attrs({
  type: 'nav',
})`
  opacity: 0.6;
  font-family: ${FONTS.TTNorms.regular};
`;

export const CategoryTitle = styled(Typography).attrs({
  type: 'body2',
})`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 21px;

  @media (max-width: 1150px) {
    font-size: 16px;
  }
`;

export const SubcategoryTitle = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 54px;
  text-transform: uppercase;
  line-height: 64px;

  @media (max-width: 1150px) {
    font-size: 26px;
    line-height: 31px;
  }
`;

export const SortingOptionWrapper = styled.div<{
  selected: boolean;
}>`
  border: 1px solid #dedfe2;
  width: 300px;
  padding: 10px 14px 10px 18px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 210px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.pale};
      border: 1px solid ${COLORS.updatedAccentBackground};
    `}
`;

export const SortingOptionTitle = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-transform: capitalize;
`;

// mobile filters

export const FilterWrapper = styled.div`
  width: 300px;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    width: 210px;
  }
`;

export const FilterLabelsWrapper = styled.div`
  border-right: 1px solid ${COLORS.border};
`;

export const FilterBlockWrapper = styled.div`
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FilterLabelWrapper = styled(FilterBlockWrapper)`
  border-bottom: 1px solid ${COLORS.border};
  width: 120px;
  cursor: pointer;
`;

export const FilterItemsWrapper = styled.div`
  overflow: auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FilterItem = styled(Typography).attrs({
  type: 'subtitle1',
})`
  margin-bottom: 10px;
  cursor: pointer;
`;

export const ResetBlockWrapper = styled.div`
  border-top: 1px solid ${COLORS.border};
  height: 63px;
  padding: 15px 20px;
`;

export const FilteredBy = styled(Typography).attrs({
  type: 'subtitle3Helv',
})`
  text-transform: capitalize;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const SelectedFiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0 25px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 5px 0 15px;
  }
`;

export const SelectedFilterLabel = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-transform: capitalize;
  color: ${COLORS.grey};
  position: relative;
  margin-right: 30px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const Cross = styled.span`
  position: absolute;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 4px;
    right: -7px;
    content: ' ';
    height: 5px;
    width: 1px;
    background-color: ${COLORS.grey};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;
