import { Paragraph, UppercaseTitle } from '../styles';

const ChangesToTerms = () => (
  <>
    <UppercaseTitle>12. Changes to the Terms</UppercaseTitle>
    <Paragraph>
      We may update these Terms from time to time. If we believe that the
      changes are material, we’ll let you know by posting the changes through
      the Services and/or sending you an email or message about the changes.
      That way you can decide whether you want to continue using the Services.
      Changes will be effective upon the posting of the changes unless otherwise
      specified. You are responsible for reviewing and becoming familiar with
      any changes. Your use of the Services following the changes constitutes
      your acceptance of the updated Terms.
    </Paragraph>
  </>
);

export default ChangesToTerms;
