import AnimatedLink from 'components/Links/AnimatedLink';
import { Typography } from 'components/Typography/Typography';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';
import styled, { keyframes } from 'styled-components';
import { FONTS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 40px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
`;

export const HoverContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledAnimatedLink = styled(AnimatedLink).attrs({
  type: 'white',
  fontSize: '28px',
})`
  opacity: 0;
  margin-top: 60px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const HoverOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.4s;
`;

const floatTextOut = keyframes`
    from {
      top: 40%;
      left: 50%;
    }

    to {
      top: 0;
      left: 0;
    }
`;

const floatTextIn = keyframes`
    from {
      top: 0;
      left: 0;
    }
    to {
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
    }
`;

export const HoverableTitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 24px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${floatTextOut} 0.4s alternate ease-in-out;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const HoverableCardWrapper = styled.div`
  min-width: 30%;
  height: calc(100vh - ${HEADER_NAV_HEIGHT}px);
  min-height: 400px;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  padding: 20px;

  @media (max-width: 1000px) {
    height: 610px;
  }

  @media (max-width: 600px) {
    height: 400px;
  }

  &:hover {
    ${HoverOverlay} {
      opacity: 1;
    }

    ${HoverContent} {
      z-index: 5;
    }

    ${HoverableTitle} {
      font-size: 40px;
      text-align: center;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      animation: ${floatTextIn} 0.4s ease-in-out;

      @media (max-width: 1000px) {
        font-size: 24px;
      }
    }

    ${StyledAnimatedLink} {
      opacity: 1;
    }
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  position: relative;
  .slick-next {
    right: 14px;
  }
  .slick-prev {
    left: 14px;
    z-index: 1;
  }
`;
