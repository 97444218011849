import { useEffect, useState } from 'react';
import {
  isMatch,
  isEqual,
  forEach,
  omit,
  values,
  includes,
  chain,
  forOwn,
} from 'lodash';
import { Typography } from 'components';
import {
  ProductAttributeType,
  ProductAttributeValueType,
  ProductItemType,
} from 'types/ProductsTypes';
import ProductDetailOption from './ProductDetailOption';

const ProductDetailOptions = ({
  product,
  selectedProductQuantity,
  selectProductQuantity,
  selectProductVariant,
  errorMessage,
  setErrorMessage,
  isItemOrderable,
}: {
  product: ProductItemType;
  selectedProductQuantity: number;
  selectProductQuantity: (quantity: number) => void;
  selectProductVariant: (data: any) => void;
  errorMessage: string | undefined;
  setErrorMessage: (data: any) => void;
  isItemOrderable: boolean;
}) => {
  const [productAttributes, setProductAttributes] =
    useState<ProductAttributeType[]>();
  const [selectedAttributes, setSelectedAttributes] = useState<{}>();

  const selectAttributeValue = (value: ProductAttributeValueType) => {
    setSelectedAttributes((prev: any) => {
      if (!value.productAttributeId) return prev;

      // unselect value
      let updatedAttributes;
      forOwn(prev, (val: string, key: string) => {
        if (key === value.productAttributeId && val === value.value) {
          updatedAttributes = omit(prev, [key]);
        }
      });

      if (updatedAttributes) return updatedAttributes;

      return {
        ...prev,
        [value.productAttributeId]: value.value,
      };
    });
  };

  const getUpdatedSingleVariationAttribute = () => {
    const valuesToEnable: any = {};

    product.variationAttributes?.forEach((item: ProductAttributeType) => {
      const enabledValues = chain(item.values)
        .map((value: ProductAttributeValueType) => {
          if (value?.orderable) {
            return value.value;
          }
        })
        .compact()
        .value();

      valuesToEnable[item.id] = enabledValues;
    });

    return product.variationAttributes?.map((item: ProductAttributeType) => {
      const valuesToEnableArray = chain(valuesToEnable).map().value()[0];

      if (!valuesToEnableArray) return item;

      return {
        ...item,
        values: item.values?.map((value: any) => {
          // @ts-ignore
          if (includes(valuesToEnableArray, value.value)) {
            return { ...value, isDisabled: false };
          }
          return { ...value, isDisabled: true };
        }),
      };
    });
  };

  const getUpdatedMultipleVariationAttributes = () => {
    const valuesToEnable: any = {};

    forEach(selectedAttributes, (value: string, productAttributeId: string) => {
      const enabledValues = chain(product?.variants)
        .filter((variant: any) => {
          return (
            isMatch(variant.variationValues, {
              [productAttributeId]: value,
            }) && variant.orderable
          );
        })
        .map((variant: any) => {
          const enabledValue = omit(
            variant.variationValues,
            productAttributeId,
          );
          return values(enabledValue)[0];
        })
        .value();

      valuesToEnable[productAttributeId] = enabledValues;
    });

    return product.variationAttributes?.map((item: ProductAttributeType) => {
      const valuesToEnableArray = chain(valuesToEnable)
        .omit(item.id)
        .map()
        .value()[0];

      if (!product?.inventory?.orderable) {
        return {
          ...item,
          values: item.values?.map((value: ProductAttributeValueType) => {
            return { ...value, isDisabled: true };
          }),
        };
      }
      if (!valuesToEnableArray) return item;

      return {
        ...item,
        values: item.values.map((value: ProductAttributeValueType) => {
          // @ts-ignore
          if (includes(valuesToEnableArray, value.value)) {
            return { ...value, isDisabled: false };
          }
          return { ...value, isDisabled: true };
        }),
      };
    });
  };

  const updateProductAttributes = () => {
    let updatedAttributes;

    if (!product) return;

    if (product.variationAttributes?.length === 1) {
      updatedAttributes = getUpdatedSingleVariationAttribute();
    } else {
      updatedAttributes = getUpdatedMultipleVariationAttributes();
    }

    updatedAttributes?.sort((a: ProductAttributeType) => {
      return a.id === 'color' ? 1 : -1;
    });

    setProductAttributes(updatedAttributes);
  };

  useEffect(() => {
    updateProductAttributes();

    if (!product?.price) {
      setErrorMessage('The product is not eligible to add to basket');
    } else {
      setErrorMessage(undefined);
    }

    const productVariant = product?.variants?.find((variant: any) => {
      return isEqual(variant.variationValues, selectedAttributes);
    });

    if (productVariant) {
      selectProductVariant(productVariant);
    } else {
      selectProductVariant(undefined);
    }

    return () => {
      setProductAttributes(undefined);
    };
  }, [product, selectedAttributes, selectedProductQuantity]);

  return (
    <>
      {productAttributes && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '18px 0',
          }}
        >
          {productAttributes?.map((productAttribute: ProductAttributeType) => (
            <ProductDetailOption
              productAttribute={productAttribute}
              key={productAttribute.id}
              selectAttributeValue={selectAttributeValue}
              disabled={!isItemOrderable}
            />
          ))}
        </div>
      )}
      {!isItemOrderable ? (
        <Typography color="accent" type="xsmallHelv">
          Item is not available for order
        </Typography>
      ) : null}
      {errorMessage && (
        <Typography color="accent" type="xsmallHelv">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default ProductDetailOptions;
