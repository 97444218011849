import { externalUrls } from 'appConstants';
import { COLORS } from 'styles';
import { Paragraph, UppercaseTitle, Title } from '../styles';

const DisputesEG = () => (
  <>
    <UppercaseTitle>11. Disputes with Empower Global</UppercaseTitle>
    <Paragraph>
      If you’re upset with us, let us know, and hopefully we can resolve your
      issue. Please contact us at{' '}
      <a
        href="mailto:wegotyou@empowerglobal.shop"
        style={{ color: COLORS.accent }}
      >
        wegotyou@empowerglobal.shop
      </a>
      . But if we can’t, then these rules will govern any legal dispute
      involving our Services:
    </Paragraph>
    <Title>A. Governing Law</Title>
    <Paragraph>
      The Terms are governed by the laws of the State of Georgia, without regard
      to its conflict of laws rules, and the laws of the United States of
      America. These laws will apply no matter where in the world you live, but
      if you live outside of the United States, you may be entitled to the
      protection of the mandatory consumer protection provisions of your local
      consumer protection law.
    </Paragraph>
    <Title>B. Arbitration</Title>
    <Paragraph>
      You and Empower Global agree that any dispute or claim arising from or
      relating to the Terms shall be finally settled by final and binding
      arbitration, using the English language, administered by the American
      Arbitration Association (the “AAA”) under its Consumer Arbitration Rules
      (the &quot;AAA Rules&quot;) then in effect (those rules are deemed to be
      incorporated by reference into this section, and as of the date of the
      Terms you can find the AAA Rules{' '}
      <a
        href={externalUrls.aaaRules}
        target="blank"
        style={{ color: COLORS.accent }}
      >
        here
      </a>
      ), unless otherwise required by law.
      <br />
      **Arbitration, including threshold questions of arbitrability of the
      dispute, will be handled by a sole arbitrator in accordance with those
      rules. Judgment on the arbitration award may be entered in any court that
      has jurisdiction.
    </Paragraph>
    <Paragraph>
      Any arbitration or mediation under the Terms will take place on an
      individual basis. You understand that by agreeing to the Terms, you and
      Empower Global are each waiving the right to trial by jury or to
      participate in a class action lawsuit. Class arbitrations shall only be
      available if requested by either party under its Class Action Arbitration
      Rules and approved by the arbitration entity. Notwithstanding the
      foregoing, each party shall have the right to bring an action in a court
      of proper jurisdiction for injunctive or other equitable or conservatory
      relief, pending a final decision by the arbitrator or mediator. You may
      instead assert your claim in “small claims” court, but only if your claim
      qualifies, your claim remains in such court, and your claim remains on an
      individual, non-representative, and non-class basis.
    </Paragraph>
    <Title>C. Forum</Title>
    <Paragraph>
      We’re based in Georgia, so any legal action against Empower Global related
      to our Services must be filed and take place in Atlanta, Georgia.
    </Paragraph>
    <Title>D. Modifications</Title>
    <Paragraph>
      If we make any changes to this “Disputes with Empower Global” section
      after the date you last accepted the Terms, those changes will not apply
      to any claims filed in a legal proceeding against Empower Global prior to
      the date the changes became effective. Empower Global will notify you of
      substantive changes to the “Disputes with Empower Global” section at least
      30 days prior to the date the change will become effective. If you do not
      agree to the modified terms, you may send Empower Global a written
      notification (including email) or close your account within those 30 days.
      By rejecting a modified term or permanently closing your account, you
      agree to arbitrate any disputes between you and Empower Global in
      accordance with the provisions of this “Disputes with Empower Global”
      section as of the date you last accepted the Terms, including any changes
      made prior to your rejection. If you reopen your closed account or create
      a new account, you agree to be bound by the current version of the Terms.
    </Paragraph>
  </>
);

export default DisputesEG;
