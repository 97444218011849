import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getCustomerProductLists,
  getCustomerProductListsLoader,
  getCustomerId,
  getIsLoggedIn,
  getFavoriteBrandsIds,
  getProduct,
} from 'eg_SFCC_FE_core/store/selectors';
import { WishListItemType } from 'eg_SFCC_FE_core/types';
import { ProductItemType } from 'types/ProductsTypes';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { useAddToWishlistConfirmationModal, useAuthModals } from './useModals';
import useGoogleTags from './useGoogleTags';

export const useProductWishList = () => {
  const dispatch = useAppDispatch();
  const wishList = useSelector(getCustomerProductLists);
  const customerId = useSelector(getCustomerId);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getCustomerProductListsLoader);
  const { showLoginModal } = useAuthModals();
  const { trackEvent } = useGoogleTags();
  const { showAddToWishlistConfirmationModal } =
    useAddToWishlistConfirmationModal();
  const product = useSelector<any, ProductItemType>(getProduct);

  const trackWishlistEvent = async (
    productForTracking: ProductItemType,
    quantity?: number,
  ) => {
    trackEvent({
      event: 'add_to_wishlist',
      ecommerce: {
        items: [
          {
            item_id: productForTracking.id,
            item_name: productForTracking.name,
            currency: productForTracking.currency,
            item_brand: productForTracking.brand,
            price: productForTracking.price || 0,
            primaryCategoryId: productForTracking.primaryCategoryId,
            quantity: quantity || 1,
          },
        ],
      },
    });
  };

  const removeFromWishList = useCallback(
    async (productId: string) => {
      const itemToRemove = wishList.customerProductListItems.find(
        (item: WishListItemType) => {
          return item.productId === productId;
        },
      );

      itemToRemove &&
        !isLoading &&
        (await dispatch(
          AsyncThunks.deleteCustomerProductListItem({
            uriParams: {
              customerId,
              listId: wishList.id,
              itemId: itemToRemove.id,
            },
          }),
        ));
    },
    [wishList, isLoading, customerId, isLoggedIn],
  );

  const isInWishList = (productId: string) =>
    !!wishList &&
    wishList.customerProductListItems?.some((item: WishListItemType) => {
      return item.productId === productId;
    });

  const addToWishList = useCallback(
    async (productId: string, quantity?: number) => {
      if (!isLoggedIn) {
        showLoginModal();
        return false;
      }

      let productToAddToWishlist = product;

      if (!product || productId !== product?.id) {
        const productsToAddToWishlist = await getProductsByIds(productId);
        [productToAddToWishlist] = productsToAddToWishlist;
      }

      // if product has master product - add to wishlist master product id
      const productIdToAddToWishlist =
        productToAddToWishlist.master?.masterId || productToAddToWishlist.id;

      const isFavorite = isInWishList(productIdToAddToWishlist);

      !isLoading &&
        !isFavorite &&
        (await dispatch(
          AsyncThunks.createCustomerProductListItem({
            uriParams: {
              customerId,
              listId: wishList.id,
            },
            productId: productIdToAddToWishlist,
          }),
        ));

      trackWishlistEvent(productToAddToWishlist, quantity);
      showAddToWishlistConfirmationModal(productToAddToWishlist);
    },
    [wishList, isLoading, customerId, isLoggedIn, product],
  );

  const updateCustomerProductWishList = (
    productId: string,
    quantity?: number,
  ): boolean => {
    if (!isLoggedIn) {
      showLoginModal();
      return false;
    }

    const isFavorite = isInWishList(productId);

    if (isFavorite) {
      removeFromWishList(productId);
    } else {
      addToWishList(productId, quantity);
    }
    return true;
  };

  return {
    isInWishList,
    removeFromWishList,
    addToWishList,
    updateCustomerProductWishList,
  };
};

export const useBrandsFavoritesList = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const favoriteBrandsIds = useSelector(getFavoriteBrandsIds);
  const { showLoginModal } = useAuthModals();

  const isBrandFavorite = useCallback(
    (brandId: string) => {
      return favoriteBrandsIds?.some((id: string) => {
        return id === brandId;
      });
    },
    [favoriteBrandsIds],
  );

  const addBrandToFavorite = async (brandId: string) => {
    await dispatch(
      AsyncThunks.updateFavoriteBrands({
        updatesType: 'c_followSeller',
        brandId,
      }),
    );
  };

  const removeBrandFromFavorite = async (brandId: string) => {
    await dispatch(
      AsyncThunks.updateFavoriteBrands({
        updatesType: 'c_unfollowSeller',
        brandId,
      }),
    );
  };

  const updateFavoriteBrands = useCallback(
    (brandId: string): boolean => {
      if (!isLoggedIn) {
        showLoginModal();
        return false;
      }

      const isFavorite = isBrandFavorite(brandId);

      if (isFavorite) {
        removeBrandFromFavorite(brandId);
      } else {
        addBrandToFavorite(brandId);
      }
      return true;
    },
    [favoriteBrandsIds, isLoggedIn],
  );

  return {
    isBrandFavorite,
    updateFavoriteBrands,
  };
};
