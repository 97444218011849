import { COLORS } from 'styles';
import { Paragraph, UppercaseTitle } from '../styles';

const ContactInfo = () => (
  <>
    <UppercaseTitle>14. Contact Information</UppercaseTitle>
    <Paragraph>
      If you have any questions about the Terms, please email us at{' '}
      <a href="legal@EmpowerGlobal.com" style={{ color: COLORS.accent }}>
        legal@EmpowerGlobal.com
      </a>
      .
    </Paragraph>
  </>
);

export default ContactInfo;
