/* eslint-disable import/no-dynamic-require */
import ROUTES from 'router/Routes';
import {
  BackgroundImage,
  CardWrapper,
  HoverContent,
  StyledAnimatedLink,
  Title,
  Subtitle,
  LiveTitle,
} from './styles';

export type EventType = {
  id: string;
  title: string;
  day: string;
  time: string;
  hostedBy: string;
  image: string;
  isLive: boolean;
};

const EventCard = ({ event }: { event: EventType }) => {
  return (
    <CardWrapper>
      <BackgroundImage imageUrl={require(`../../${event.image}`)}>
        {event.isLive && <LiveTitle>live now</LiveTitle>}
        <HoverContent>
          <Subtitle>hosted by: {event.hostedBy}</Subtitle>
          <Title>{event.title}</Title>
          <Subtitle>
            <span style={{ textTransform: 'capitalize' }}>{event.day}</span>:{' '}
            {event.time}
          </Subtitle>
          <StyledAnimatedLink to={ROUTES.EVENTS_CURRENT} type="white">
            view event
          </StyledAnimatedLink>
        </HoverContent>
      </BackgroundImage>
    </CardWrapper>
  );
};

export default EventCard;
