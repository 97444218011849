import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 20px;
  display: grid;
  place-items: center;

  @media (max-width: 1000px) {
    padding: 40px 20px;
  }
`;

export const ProductCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ProductCardWrapper = styled.div`
  width: 31%;

  @media (max-width: 1000px) {
    width: 46%;
  }

  @media (max-width: 640px) {
    height: 300px;
  }
`;
