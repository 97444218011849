import { ReactNode } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ContentWrapper } from './styles';

const AnimatedWrapper = ({
  animationKey,
  children,
}: {
  animationKey: any;
  children: ReactNode;
}) => {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={animationKey} classNames="fade" timeout={300}>
        <ContentWrapper>{children}</ContentWrapper>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default AnimatedWrapper;
