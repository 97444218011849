import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';

export const StyledHeading = styled(Typography).attrs({
  type: 'title1Helv',
})`
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.s}px) {
    font-size: 48px;
  }
`;
