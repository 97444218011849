import Grid from '@mui/material/Grid';

import { exploreFeaturesBrands } from 'data/exploreData';
import ROUTES from 'router/Routes';
import { Wrapper, Title, StyledBox, NavLink } from './styles';

const ExploreFeaturedBrands = () => {
  return (
    <Wrapper>
      <Title>featured brands</Title>
      <StyledBox>
        <Grid container spacing={2} columns={{ lg: 6, md: 4, sm: 2, xs: 1 }}>
          {exploreFeaturesBrands.map(
            (brand: { id: string; businessName: string }) => {
              return (
                <Grid
                  item
                  key={brand.id}
                  id={brand.id}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                >
                  <NavLink to={`${ROUTES.BRANDS}/${brand.id}/products`}>
                    {brand.businessName}
                  </NavLink>
                </Grid>
              );
            },
          )}
        </Grid>
      </StyledBox>
    </Wrapper>
  );
};

export default ExploreFeaturedBrands;
