import styled from 'styled-components';
import { ColoredButton } from 'components';
import { BREAKPOINTS } from 'styles';

export const StyledButton = styled(ColoredButton)`
  margin: 30px auto 0;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 0 0 10px;
  }
`;
