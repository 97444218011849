import { MainTitle, Paragraph, Title } from '../styles';

const PrivacyChoices = () => {
  return (
    <>
      <MainTitle>YOUR PRIVACY CHOICES</MainTitle>
      <Paragraph>
        The privacy choices you may have about your personal information are
        determined by applicable law and are described below. We process
        requests to be placed on do-not-mail, do-not-phone, and do-not-contact
        lists as required by applicable law. In addition, you may have
        additional rights depending on where you are located. Please see the
        “European Union and United Kingdom Data Subject Rights,” “California
        Resident Rights”, “Virginia Resident Rights,” or “Other State Law
        Privacy Rights” sections, as applicable, for more information.
      </Paragraph>

      <Title space>A. EMAIL COMMUNICATIONS</Title>
      <Paragraph space>
        If you receive an unwanted marketing email from us, you can use the
        unsubscribe link found at the bottom of the email to opt out of
        receiving future marketing emails. Note that you will continue to
        receive transaction-related emails regarding products or Services you
        have requested. We may also send you certain non-promotional
        communications regarding us and our Services, and you will not be able
        to opt out of those communications (e.g., communications regarding our
        Services or updates to our Terms or this Privacy Notice).
      </Paragraph>

      <Title space>B. TEXT MESSAGES</Title>
      <Paragraph space>
        You may opt out of receiving text messages from us by following the
        instructions in the text message you have received from us or by
        otherwise contacting us.
      </Paragraph>
    </>
  );
};

export default PrivacyChoices;
