import { CSSProperties } from 'react';
import { PasswordCriteriaText } from './styles';

const PasswordCriteria = ({ style }: { style?: CSSProperties }) => (
  <PasswordCriteriaText style={style}>
    Password must include at least 8 characters, one lowercase letter, one
    uppercase letter, one number and one special character
  </PasswordCriteriaText>
);

export default PasswordCriteria;
