import { useAuthModals } from 'hooks/useModals';

import { StyledContainer, StyledCustomNavLink } from './styles';

const HomepageNewsletterSection = () => {
  const { showLoginModal } = useAuthModals();

  return (
    <StyledContainer>
      <StyledCustomNavLink onClick={showLoginModal}>
        Sign up for email & text alerts to stay in the know.
      </StyledCustomNavLink>
    </StyledContainer>
  );
};

export default HomepageNewsletterSection;
