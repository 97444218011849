import { ChangeEvent, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import FaqSearchInput from 'components/Inputs/FaqSearchInput';

const FaqSearchForm = ({
  searchValue,
  handleSearchValueChange,
  handleSubmit,
}: {
  searchValue: string;
  handleSearchValueChange: (val: string) => void;
  handleSubmit: (e: { preventDefault: () => void }) => void;
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    handleSearchValueChange(event.target.value);
  }, []);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 730, width: '100%' }}
    >
      <FormControl fullWidth>
        <FaqSearchInput
          searchValue={searchValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      </FormControl>
    </Box>
  );
};

export default FaqSearchForm;
