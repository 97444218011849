import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const LineWrapper = styled.div`
  background-color: ${COLORS.black};
  display: flex;
  padding: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-start;
`;

export const StrokeText = styled.h2<{
  marginLeft?: number;
  accent?: boolean;
}>`
  font-family: ${FONTS.Bison.regular};
  font-size: 80px;
  line-height: 70px;
  text-transform: uppercase;
  text-align: center;
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  -webkit-text-stroke: 1px ${COLORS.white};
  color: ${({ accent }) => {
    return accent ? COLORS.white : COLORS.black;
  }};
  padding: ${({ accent }) => {
    return accent ? '0 5' : 0;
  }}px;
`;
