import { FC, ReactNode } from 'react';
import CustomNavLink from 'components/Links/CustomNavLink';

const ConditionalLink: FC<any> = ({
  children,
  to,
  condition,
}: {
  children: ReactNode;
  to: string;
  condition: boolean;
}) =>
  !!condition && to ? (
    <CustomNavLink to={to} style={{ color: 'white' }}>
      {children}
    </CustomNavLink>
  ) : (
    <div>{children}</div>
  );

export default ConditionalLink;
