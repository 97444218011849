import styled, { css } from 'styled-components';

import { Typography, ColoredButton } from 'components';
import { Btn } from 'components/AuthComponents/styles';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { ArrowRightIcon, CrossIcon, MainLogoIcon } from 'assets/svg';
import { PageContentWrapper } from 'styles/common';

export const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: ${COLORS.white};
  height: 420px;
`;

export const NavMenuWrapper = styled.div<{
  columns: number;
}>`
  width: 100%;
  padding: 0 80px;
  overflow: auto;
  display: grid;
  grid-template-columns: ${({ columns }) => {
    return `repeat(auto-fill, ${100 / columns}%)`;
  }};
  justify-content: space-between;

  @media (max-width: 1150px) {
    padding: 0 15px;
  }
`;

export const ColumnWrapper = styled.div`
  min-width: 105px;
  margin-top: 40px;
  padding-right: 20px;
`;

export const Category = styled(Typography).attrs({
  type: 'subtitle3',
})`
  text-transform: uppercase;
`;

export const SubCategory = styled(Typography).attrs({
  type: 'body2',
})`
  text-transform: capitalize;
  opacity: 0.6;
  margin: 20px 0;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${COLORS.accent};
    text-decoration: underline;
    text-decoration-color: ${COLORS.accent};
    opacity: 1;
  }
`;

export const SubcatCategory = styled.span`
  display: block;
  margin-left: 10px;
  text-transform: capitalize;
  opacity: 0.6;
  cursor: pointer;
  transition: 0.3s;
  color: ${COLORS.primary};

  &:hover {
    color: ${COLORS.accent};
    text-decoration: underline;
    text-decoration-color: ${COLORS.accent};
    opacity: 1;
  }
`;

export const VerticalContentWrapper = styled(ModalContentWrapper)`
  flex-direction: column;
`;

// cart content styles

export const CartContentWrapper = styled.div`
  padding: 16px 20px 40px 16px;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.div`
  border-bottom: 1px solid ${COLORS.middleGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  p {
    font-family: ${FONTS.Helvetica.bold};
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    flex: 1;
  }
`;

export const CartCrossIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const CartOverflowContent = styled.div<{
  height: number;
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export const CartBottomWrapper = styled.div``;

export const SubtotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 38px;
`;

export const ShippingTaxDesc = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  opacity: 0.6;
  margin-bottom: 14px;
  text-align: center;
`;

export const CartButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const CartButton = styled(ColoredButton)`
  width: 100%;

  & p {
    font-size: 12px;
    font-family: ${FONTS.Helvetica.bold};
  }
`;

// search content styles

export const SearchContentWrapper = styled(PageContentWrapper)`
  flex-direction: column;
  padding: 0 40px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 0 15px;
  }
`;

export const ProductSearchTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 50px 0;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 15px 0;
  }
`;

export const ProductSearchResultWrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
  }
`;

export const SearchFormWrapper = styled.div`
  margin-left: 54px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-left: 0;
  }
`;

export const SearchInfo = styled.div`
  display: flex;
  margin: 18px 0 20px 54px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column-reverse;
    margin-left: 0;
  }
`;

export const SearchSuggestionsWrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-bottom: 10px;
  }
`;

export const SearchSuggestion = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-decoration: underline;
  font-style: italic;
  margin-left: 5px;
  cursor: pointer;
`;

export const DropDownWrapper = styled.nav`
  background: white;
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const DropdownOption = styled.div<{
  icon?: boolean;
}>`
  cursor: pointer;
  height: 68px;
  background-color: ${({ icon }) => (icon ? COLORS.white : COLORS.black)};
  margin: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: ${({ icon }) =>
    icon ? `1px solid ${COLORS.border}` : `1px solid ${COLORS.primary}`};
  color: ${({ icon }) => (icon ? COLORS.black : COLORS.white)};
`;

export const LinkText = styled.div`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 15px;
  text-transform: uppercase;
`;

export const Rotated180Icon = styled(ArrowRightIcon)`
  transform: rotate(180deg);
  margin-right: 10px;
`;

export const Rotated90Icon = styled(ArrowRightIcon)`
  transform: rotate(90deg);
  margin-right: 10px;
`;

export const ConfirmationWrapper = styled.div`
  padding: 40px 50px 60px 50px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AddToCartConfirmationWrapper = styled(ConfirmationWrapper)`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 30px;
  }
`;

export const ConfirmationContentCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    top: 9px;
    right: 9px;
    width: 14px;
  }
`;

export const ConfirmationTitle = styled(Typography).attrs({
  type: 'title3Helv',
})`
  text-transform: uppercase;
  text-align: start;
  margin-bottom: 23px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const ConfirmationDetailsWrapper = styled.div`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const ConfirmationItemImage = styled.img`
  width: 50%;
  max-height: 300px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    object-fit: contain;
    max-height: 260px;
    width: 80%;
  }
`;

export const ConfirmationItemBrandName = styled(Typography)`
  font-family: ${FONTS.Helvetica.medium};
  font-size: 14px;
  text-transform: uppercase;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
  }
`;

export const ConfirmationItemProductName = styled(Typography)`
  font-size: 14px;
  margin-bottom: 17px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    margin-bottom: 4px;
  }
`;

export const ConfirmationItemBody = styled(Typography)`
  font-size: 12px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
  }
`;

export const AddToCartConfirmationContentWrapper = styled.div`
  display: flex;
  gap: 17px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ConfirmationButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  & > a {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: row;
    & > button,
    a {
      width: 50%;
    }
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 100%;
  & p {
    font-size: 12px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 23px !important;
    & p {
      font-size: 8px;
    }
  }
`;

export const QuickViewWrapper = styled.div<{
  width: number;
  height: number;
}>`
  display: flex;
  min-height: 300px;
  max-height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width * 0.7}px`};

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
    overflow: auto;
  }
`;

export const QuickViewCarouselWrapper = styled.div`
  width: 50%;
  position: relative;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 100%;
  }
`;

export const QuickViewContentWrapper = styled.div<{
  height: number;
}>`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: ${({ height }) => `${height}px`};
  padding: 50px 33px 17px 0;
  overflow: auto;
  position: relative;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 100%;
    overflow: visible;
    padding: 30px 25px;
  }
`;

export const QuickViewCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 18px;
  right: 15px;
  cursor: pointer;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.s}px) {
    right: 15px;
  }
`;

export const QuickViewFavouriteIconWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 50px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    right: 35px;
  }
`;

export const QuickViewOpenLinkIconWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 34px;
  height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.s}px) {
    right: 20px;
  }
`;

export const QuickViewAfterpayMessageWrapper = styled.div`
  .AfterpayMessage {
    margin: 0 !important;
    font-family: ${FONTS.Helvetica.regular};
    font-size: 12px;
  }

  .AfterpayMessage-logoSvg {
    width: 62px;
    height: auto;
  }
`;

export const QuickViewButtonsWrapper = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin: 4px 0 13px;
`;

export const ReadMoreBtn = styled(Typography)`
  font-size: 11px;
  font-style: italic;
  cursor: pointer;
`;

export const ArrowRight = styled(ArrowRightIcon).attrs({
  color: COLORS.black,
})`
  height: 18px;
  width: 11px;
`;

export const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
`;

export const SMainLogoIcon = styled(MainLogoIcon)`
  width: 287px;
  @media (max-width: 1000px) {
    width: 220px;
  }
`;

export const ProfileDropdownOption = styled.div<{
  isExpanded?: any;
}>`
  cursor: pointer;
  height: 68px;
  background-color: ${COLORS.white};
  margin: 0 20px;
  display: flex;
  align-items: center;
  color: ${COLORS.black};
  border-bottom: ${({ isExpanded }) =>
    isExpanded || `1px solid ${COLORS.border}`};
`;

export const ProfileAccordion = styled.div`
  border-bottom: 1px solid ${COLORS.border};
  margin: 0 20px;
`;

export const ProfileAccordionText = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 14px;
  text-transform: capitalize;
  padding-left: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;

export const CategoriesWrapper = styled.div`
  background-color: ${COLORS.black};
`;

export const SplashPageTitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 64px;
  line-height: 77px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 750px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const SplashPageText = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 100px;

  @media (max-width: 750px) {
    font-size: 16px;
    margin-bottom: 50px;
  }
`;

export const SplashPageBackground = styled.div<{
  height: number;
}>`
  background-color: black;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SplashPageSkipBtn = styled(Btn)`
  color: ${COLORS.white};
  text-transform: uppercase;
  background: none;
  position: absolute;
  top: 5%;
  right: 3%;

  @media (max-width: 650px) {
    top: 4%;
    right: 7%;
  }
`;

export const LogoIconWrapper = styled.div`
  @media (max-width: 850px) {
    svg {
      width: 500px;
      height: 72px;
    }
  }

  @media (max-width: 500px) {
    svg {
      width: 310px;
      height: 50px;
    }
  }
`;

// navigation styles
export const Wrapper = styled.nav`
  background: ${COLORS.black};
  max-height: 60px;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: hidden;
  padding: 0 5px;
  position: fixed;
  top: 126px;
  width: 100%;
  z-index: 10;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Links = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
`;

export const NavigationLink = styled.div<{
  active?: boolean;
}>`
  color: ${COLORS.white};
  cursor: pointer;
  position: relative;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid ${COLORS.black} 4px;

  &:hover,
  &:focus {
    border-bottom: solid ${COLORS.accent} 4px;
    transition: 0.3s ease-out;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: solid ${COLORS.accent} 4px;
      transition: 0.3s ease-out;
    `}
`;

export const ForgotPasswordContentWrapper = styled.div`
  padding: 30px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${COLORS.white};

  @media (max-width: 1000px) {
    padding: 30px 20px;
  }
`;

export const LoyaltyRegistrationContentWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const LoyaltyFormWrapper = styled.div`
  height: 100%;
  padding: 70px 148px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 1000px) {
    padding: 30px 20px;
  }
`;

export const CrossIconWrapper = styled.div`
  position: absolute;
  top: 2%;
  right: 2%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 11;
`;

export const LoyaltyFormTitle = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 14px;
  z-index: 1;
`;

export const LoyaltyLineTitle = styled.div`
  height: 100%;
  background: ${COLORS.black};
  display: flex;
  padding: 0 7px;
  overflow: hidden;
`;

export const AddToCartItemValues = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
