import { useState } from 'react';
import { filter } from 'lodash';

import {
  GroupedByBrandProductItems,
  ProductItemsType,
  ReturnPlisInfo,
} from 'types/OrdersTypes';
import ReturnItem from './ReturnItem';
import {
  BrandName,
  Message,
  ReturnCardWrapper,
  StyledColoredButton,
} from './styles';

const ReturnCard = ({
  group,
  handleReturnButtonClick,
}: {
  group: GroupedByBrandProductItems;
  handleReturnButtonClick: (checkedItems: ReturnPlisInfo[]) => void;
}) => {
  const [message, setMessage] = useState<string>('');
  const [groupItems, setGroupItems] = useState<ReturnPlisInfo[]>(
    group.items.map((item: ProductItemsType) => {
      return {
        id: item.itemId,
        qty: item.quantity - item.c_cancelledQuantity > 0 ? 1 : 0,
        isChecked: false,
      };
    }),
  );
  const isReturnButtonAvailable = group.items.find((item: ProductItemsType) => {
    let returnStatus = item.c_returnStatus;
    if (typeof returnStatus === 'string') {
      returnStatus = JSON.parse(returnStatus);
    }
    return returnStatus?.eligible;
  });

  const handleChange = (item: ReturnPlisInfo) => {
    setGroupItems((prevItems: ReturnPlisInfo[]) => {
      return prevItems.map((prevItem: ReturnPlisInfo) => {
        if (prevItem.id === item.id) {
          return item;
        }
        return prevItem;
      });
    });
  };

  const handleReturn = () => {
    const checkedItems = filter(groupItems, { isChecked: true });
    if (!checkedItems || !checkedItems.length) {
      return setMessage('Items were not chosen');
    }

    handleReturnButtonClick(checkedItems);
  };

  return (
    <ReturnCardWrapper>
      <BrandName>{group.c_marketplacerSellerName}</BrandName>
      {group.items.map((item: ProductItemsType) => {
        return (
          <ReturnItem
            item={item}
            key={item.itemId}
            handleChange={handleChange}
          />
        );
      })}
      {message && <Message>{message}</Message>}
      {isReturnButtonAvailable ? (
        <StyledColoredButton aria-label="return" onClick={handleReturn}>
          RETURN
        </StyledColoredButton>
      ) : null}
    </ReturnCardWrapper>
  );
};

export default ReturnCard;
