import { useEffect, useState } from 'react';

import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import FeaturedProducts from 'components/BrandsComponents/FeaturedProducts/FeaturedProducts';

import { StyledHeading } from './styles';

const HomepageFeaturedProducts = () => {
  const productIds = ['100013300', '52879', '53610', '100016656'].join(',');
  // const productIds = ['1228', '1228', '1228', '1228'].join(',');
  const [productItems, setProductItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await getProductsByIds(productIds);

      setProductItems(items);
    };

    fetchData();
  }, []);

  return (
    <>
      <StyledHeading>Featured products</StyledHeading>
      <FeaturedProducts
        productList={productItems}
        brand={{ brandId: '', brandName: '' }}
      />
    </>
  );
};

export default HomepageFeaturedProducts;
