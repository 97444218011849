import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'lodash';

import { Typography, CustomNavLink, Loader } from 'components';
import CartModalItem from 'components/OrderSummary/CartModalItem';
import { CrossIcon } from 'assets/svg';
import ROUTES from 'router/Routes';
import { formatPrice } from 'helpers/formatters';
import { BasketProductItemType } from 'types/ProductsTypes';
import { getBasket } from 'eg_SFCC_FE_core/store/selectors';
import useGoogleTags from 'hooks/useGoogleTags';
import { getProductsByIds } from 'eg_SFCC_FE_core/axios/api';
import { EcommerceItemType } from 'types/GoogleTagTypes';
import { areAllItemsInBasketAvailable } from 'helpers/basketUtils';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  Title,
  SubtotalWrapper,
  CartBottomWrapper,
  CartButtonsWrapper,
  CartButton,
  CartOverflowContent,
  CartCrossIconWrapper,
  CartContentWrapper,
  ShippingTaxDesc,
} from './styles';

const CartContent = ({
  closeModal,
  removeFromCart,
  addToWishList,
}: {
  closeModal: () => void;
  removeFromCart: (cartItem: BasketProductItemType) => void;
  addToWishList: (itemId: string, productId: string, quantity: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const { productItems, subtotal, totalQuantity, pending } =
    useSelector(getBasket);
  const [itemsForTracking, setItemsForTracking] =
    useState<EcommerceItemType[]>();
  const { trackEvent } = useGoogleTags();
  const isOrderPossible = areAllItemsInBasketAvailable(productItems);

  const getItemsForTracking = async () => {
    const items = await Promise.all(
      productItems.map(async (item: BasketProductItemType) => {
        let products = await getProductsByIds(item.productId);
        let product = products[0];

        if (product.master) {
          products = await getProductsByIds(product.master.masterId);
          [product] = products;
        }

        return {
          item_id: product.id,
          item_name: product.name,
          currency: 'USD',
          item_brand: product.brand,
          item_variant: values(item?.variationValues).join(',') || '',
          price: item.price || 0,
          quantity: item.quantity,
          primaryCategoryId: product.primaryCategoryId,
        };
      }),
    );

    setItemsForTracking(items);
    return items;
  };

  const trackViewCartEvent = async (items: EcommerceItemType[]) => {
    trackEvent({
      event: 'view_cart',
      ecommerce: {
        value: subtotal,
        items: itemsForTracking || items,
      },
    });
  };

  const trackBeginCheckoutEvent = async () => {
    itemsForTracking &&
      trackEvent({
        event: 'begin_checkout',
        ecommerce: { items: itemsForTracking },
      });
  };

  const handleCheckoutClick = () => {
    trackBeginCheckoutEvent();
    closeModal();
  };

  const updateItem = async (
    itemId: string,
    productId: string,
    updatedQuantity: number,
  ) => {
    try {
      await dispatch(
        AsyncThunks.updateItemInBasket({
          itemId,
          valuesToUpdate: {
            productId,
            quantity: updatedQuantity,
          },
        }),
      ).unwrap();
    } catch (e: any) {
      return e.detail;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const items = await getItemsForTracking();
      await trackViewCartEvent(items);
    };

    fetchData();
  }, []);

  return (
    <CartContentWrapper>
      <Title id="transition-modal-description">
        <Typography>shopping cart</Typography>
        <CartCrossIconWrapper id="close">
          <CrossIcon onClick={closeModal} />
        </CartCrossIconWrapper>
      </Title>
      <CartOverflowContent height={window.innerHeight}>
        {pending && <Loader fullscreen />}
        {!totalQuantity && (
          <Typography type="subtitle2">Your cart is empty</Typography>
        )}
        {!!totalQuantity && productItems.length
          ? productItems.map((item: BasketProductItemType, index: number) => {
              return (
                <CartModalItem
                  cartItem={item}
                  key={index}
                  removeFromCart={removeFromCart}
                  closeModal={closeModal}
                  addToWishList={addToWishList}
                  updateItem={updateItem}
                />
              );
            })
          : null}
      </CartOverflowContent>
      <CartBottomWrapper>
        <SubtotalWrapper>
          <Typography type="xsmallHelv">SUBTOTAL</Typography>
          <Typography type="xsmallHelv" id="subTotal">
            {formatPrice(subtotal)}
          </Typography>
        </SubtotalWrapper>
        <ShippingTaxDesc>
          Shipping & taxes calculated at checkout
        </ShippingTaxDesc>
        {totalQuantity ? (
          <CartButtonsWrapper>
            <CustomNavLink to={ROUTES.CART} onClick={closeModal}>
              <CartButton id="viewCart" aria-label="view cart">
                view cart
              </CartButton>
            </CustomNavLink>
            <CustomNavLink
              to={isOrderPossible ? ROUTES.CHECKOUT : ''}
              onClick={isOrderPossible ? handleCheckoutClick : () => {}}
            >
              <CartButton
                disabled={!isOrderPossible}
                id="checkout"
                aria-label="checkout"
              >
                checkout
              </CartButton>
            </CustomNavLink>
          </CartButtonsWrapper>
        ) : null}
      </CartBottomWrapper>
    </CartContentWrapper>
  );
};

export default CartContent;
