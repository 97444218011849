import { Typography } from 'components';
import { formatPrice } from 'helpers/formatters';

const ProductItemPrice = ({
  price,
  priceAfterOrderDiscount,
  priceAfterItemDiscount,
  discountedPrice,
  originalPrice,
}: {
  price: number;
  priceAfterOrderDiscount: number;
  priceAfterItemDiscount: number;
  discountedPrice: number;
  originalPrice: number;
}) => {
  const hasOrderLevelDiscount =
    !!priceAfterOrderDiscount && priceAfterOrderDiscount !== price;
  const hasProductLevelDiscount = discountedPrice !== originalPrice;

  const itemOriginalPrice = hasOrderLevelDiscount
    ? price
    : hasProductLevelDiscount
    ? originalPrice
    : price;
  const itemDiscountedPrice = hasOrderLevelDiscount
    ? Math.min(priceAfterItemDiscount, priceAfterOrderDiscount)
    : hasProductLevelDiscount
    ? discountedPrice
    : 0;

  if (itemDiscountedPrice) {
    return (
      <>
        <span
          style={{
            textDecoration: 'line-through',
            fontSize: 12,
          }}
        >
          {formatPrice(itemOriginalPrice)}
        </span>
        <Typography type="xsmallHelv">
          {formatPrice(itemDiscountedPrice)}
        </Typography>
      </>
    );
  }

  return (
    <Typography type="xsmallHelv">{formatPrice(itemOriginalPrice)}</Typography>
  );
};

export default ProductItemPrice;
