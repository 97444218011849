import { useState } from 'react';
import { Typography } from 'components';
import { IconWrapper } from 'components/AccountComponents/styles';
import { COLORS } from 'styles';
import { StyledColoredButton, ConfirmationWrapper } from './styles';

const ConfirmationContent = ({
  unSetModal,
  text,
  title,
  Icon,
  confirmButtonAction,
  confirmButtonText,
  cancelButtonAction,
  cancelButtonText,
}: {
  unSetModal: () => void;
  title: string;
  text: string;
  Icon: any;
  confirmButtonAction: (prop?: any) => any;
  confirmButtonText: string;
  cancelButtonAction?: () => void;
  cancelButtonText: string;
}) => {
  const [message, setMessage] = useState<string>();

  const handleClick = async () => {
    const response = await confirmButtonAction();

    if (response?.error) {
      setMessage('Something went wrong');
    } else {
      unSetModal();
    }
  };

  return (
    <>
      <ConfirmationWrapper>
        <IconWrapper style={{ marginRight: 0 }}>
          <Icon style={{ color: COLORS.accent, width: 20, height: 20 }} />
        </IconWrapper>
        <Typography type="title4" style={{ color: COLORS.accent }}>
          {title}
        </Typography>
      </ConfirmationWrapper>
      <ConfirmationWrapper style={{ backgroundColor: '#F6F6F6' }}>
        <Typography type="subtitle1">{text}</Typography>
        {message ? (
          <Typography style={{ color: COLORS.accent }}>{message}</Typography>
        ) : null}
        <div>
          <StyledColoredButton aria-label="confirm" onClick={handleClick}>
            {confirmButtonText}
          </StyledColoredButton>
          <StyledColoredButton
            inverted
            aria-label="cancel"
            onClick={cancelButtonAction || unSetModal}
          >
            {cancelButtonText}
          </StyledColoredButton>
        </div>
      </ConfirmationWrapper>
    </>
  );
};

export default ConfirmationContent;
