import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { useState } from 'react';
import ROUTES from 'router/Routes';
import { FacebookIcon, ShareIcon, TwitterIcon, MailIcon } from 'assets/svg';
import { Typography } from 'components';
import { VacancyType } from 'types/VacancyTypes';
import { IconContainer, IconsWrapper } from './styles';

const ShareIcons = ({ vacancy }: { vacancy: VacancyType | undefined }) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const [copiedId, setCopiedId] = useState<string | undefined>('');
  const copyJobUrl = (id: string | undefined) => {
    setShowCopied(true);
    setCopiedId(id);
    setTimeout(() => {
      setShowCopied(false);
      setCopiedId('');
    }, 3000);

    return navigator.clipboard.writeText(
      `${process.env.REACT_APP_SFRA_HOST}${ROUTES.CAREERS}/${id}`,
    );
  };

  return (
    <IconsWrapper>
      <IconContainer>
        <TwitterShareButton
          aria-label="share on twitter"
          title="Check out this open position at Empower Global: "
          via="ShopEmpower"
          hashtags={['empowerglobal', 'jobopening']}
          related={['empowerglobal', 'diddy']}
          url={`${process.env.REACT_APP_SFRA_HOST}${ROUTES.CAREERS}/${vacancy?.id}`}
        >
          <TwitterIcon />
        </TwitterShareButton>
      </IconContainer>
      <IconContainer>
        <FacebookShareButton
          aria-label="share on facebook"
          url={`${process.env.REACT_APP_SFRA_HOST}${ROUTES.CAREERS}/${vacancy?.id}`}
        >
          <FacebookIcon />
        </FacebookShareButton>
      </IconContainer>
      <IconContainer>
        <EmailShareButton
          aria-label="share on email"
          url={`${process.env.REACT_APP_SFRA_HOST}${ROUTES.CAREERS}/${vacancy?.id}`}
        >
          <MailIcon />
        </EmailShareButton>
      </IconContainer>
      <IconContainer>
        <div className="copy">
          {/* Passing vacancy id to copyUrl let's UI know which job post to show copy message  */}
          <ShareIcon onClick={() => copyJobUrl(vacancy?.id)} />
          {showCopied && copiedId === vacancy?.id ? (
            // Show Copied confirmation message based on state
            <Typography
              style={{
                position: 'absolute',
                background: '#F0943E',
                color: '#FFF',
                padding: '.25rem .5rem',
                borderRadius: '5px',
              }}
            >
              Copied
            </Typography>
          ) : null}
        </div>
      </IconContainer>
    </IconsWrapper>
  );
};

export default ShareIcons;
