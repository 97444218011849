import { Dispatch, SetStateAction } from 'react';
import { AnimatedWrapper } from 'components';

import useDimensions from 'hooks/useDimensions';
import { BREAKPOINTS } from 'styles';
import {
  MenuContainer,
  ClickableMenuItem,
  MobileContentWrapper,
} from './styles';

type MenuItemType = { title: string; content: any };

const InfoPageMenu = ({
  menu,
  currentMenuItem,
  setCurrentMenuItem,
}: {
  menu: MenuItemType[];
  currentMenuItem: MenuItemType;
  setCurrentMenuItem: Dispatch<SetStateAction<MenuItemType>>;
}) => {
  const { width } = useDimensions();
  const isMobile = width < BREAKPOINTS.s;

  return (
    <MenuContainer>
      {menu.map((menuItem: MenuItemType, index: number) => (
        <div key={index}>
          <ClickableMenuItem
            isActive={currentMenuItem.title === menuItem.title}
            onClick={() => setCurrentMenuItem(menuItem)}
          >
            {menuItem.title}
          </ClickableMenuItem>

          {isMobile && (
            <AnimatedWrapper animationKey={currentMenuItem.title}>
              {currentMenuItem.title === menuItem.title && (
                <MobileContentWrapper>
                  {currentMenuItem.content}
                </MobileContentWrapper>
              )}
            </AnimatedWrapper>
          )}
        </div>
      ))}
    </MenuContainer>
  );
};

export default InfoPageMenu;
