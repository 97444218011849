import { MainTitle, Paragraph } from '../styles';

const Retention = () => {
  return (
    <>
      <MainTitle>RETENTION OF PERSONAL INFORMATION</MainTitle>
      <Paragraph>
        We retain the Personal Information we collect as described in this
        Privacy Notice for as long as you have an open account with us, or as
        otherwise necessary to provide you with our Services. In some cases, we
        retain Personal Information for longer, if doing so is necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws. We may further retain information anonymously, or
        aggregated, from where that information would not identify you
        personally.
      </Paragraph>
    </>
  );
};

export default Retention;
