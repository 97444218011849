import styled from 'styled-components';
import { ColoredButton } from 'components';
import { BREAKPOINTS } from 'styles';

export const StyledColoredButton = styled(ColoredButton)`
  width: 210px;
  margin-top: 25px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 110px;
    margin-top: 15px;
  }
`;
