import { Dispatch, SetStateAction } from 'react';

import EnterPasswordForm from 'components/Forms/EnterPasswordForm/EnterPasswordForm';

const EnterPasswordContent = ({
  setPassword,
}: {
  setPassword: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div style={{ padding: 50 }}>
      <EnterPasswordForm isLoading={false} setPassword={setPassword} />
    </div>
  );
};

export default EnterPasswordContent;
