import { MainTitle, Paragraph } from '../styles';

const Security = () => {
  return (
    <>
      <MainTitle>SECURITY OF PERSONAL INFORMATION</MainTitle>
      <Paragraph>
        We take steps to ensure that your Personal Information is treated
        securely and in accordance with this Privacy Notice. We seek to protect
        your Personal Information from unauthorized access, use and disclosure
        using appropriate physical, technical, organizational, and
        administrative security measures based on the type of Personal
        Information and how we are processing that information. You should also
        help protect your information by appropriately selecting and protecting
        your password and/or other sign-on mechanism; limiting access to your
        computer or device and browser; and signing off after you have finished
        accessing your account. Although we work to protect the security of your
        account and other information that we hold in our records, please be
        aware that no information storage system, or method of transmitting
        information over the internet is 100% secure, and we cannot ensure or
        warrant the security of any information you provide to us. The files and
        records containing your personal information will be kept in our offices
        and/or on our servers or those of our service providers and only those
        employees that require it for the purposes of their duties will have
        access to this file. To the fullest extent permitted by applicable law,
        we do not accept liability for unauthorized disclosure.
      </Paragraph>
      <Paragraph space>
        By using our Services or providing personal information to us, you agree
        that we may communicate with you electronically regarding security,
        privacy, and administrative issues relating to your use of our Services.
        If we learn of a security system’s breach, we may attempt to notify you
        electronically by posting a notice on our Services, by mail, or by
        sending an email to you.
      </Paragraph>
    </>
  );
};

export default Security;
