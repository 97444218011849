import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { CustomNavLink, RadioButtonsGroup } from 'components';
import ContactInfoBlock from 'components/InfoPagesComponents/ContactInfoBlock/ContactInfoBlock';
import ROUTES from 'router/Routes';
import {
  PageWrapper,
  HeaderTitle,
  InfoTitle,
  StyledColoredButton,
} from './styles';

const CONTACT_US_FORMS = [
  {
    label: 'Submit Help Request',
    value: ROUTES.CONTACT_US_HELP,
  },
  {
    label: 'Join Seller Community',
    value: ROUTES.CONTACT_US_COMMUNITY,
  },
];

const ContactUsPage = () => {
  const [routeToNavigate, setRouteToNavigate] = useState<string>('');
  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <PageWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <HeaderTitle>Contact Us</HeaderTitle>
          <InfoTitle>
            Looking to learn more about who we are and what we do? Need some
            assistance with your account or our site? Wanna join the team?
            We&apos;re here for it all!
          </InfoTitle>
          <RadioButtonsGroup
            items={CONTACT_US_FORMS}
            selectValue={setRouteToNavigate}
          />
          <CustomNavLink to={routeToNavigate || pathname}>
            <StyledColoredButton>submit</StyledColoredButton>
          </CustomNavLink>
        </div>

        <ContactInfoBlock />
      </PageWrapper>
    </>
  );
};

export default ContactUsPage;
