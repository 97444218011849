import { Dispatch, SetStateAction } from 'react';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { formatSuggestedAddress } from './formatters';

export const handleSuggestedAddress = (
  error: any,
  setSuggestedAddress: Dispatch<
    SetStateAction<AddressFormFieldsType | undefined>
  >,
) => {
  if (error?.statusCode === 'has_suggested_address') {
    const formattedSuggestedAddress = formatSuggestedAddress(
      error?.statusMessage,
    ) as AddressFormFieldsType;

    setSuggestedAddress(formattedSuggestedAddress);
  }
};
