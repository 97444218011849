import styled from 'styled-components';
import { ColoredButton, Typography } from 'components';
import { BREAKPOINTS } from 'styles';
import { PageContentWrapper } from 'styles/common';

export const PageWrapper = styled(PageContentWrapper)`
  margin: 100px 0;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 30px 0 0;
    padding: 0 25px 22px;
    flex-direction: column;
  }
`;

export const FormBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin-left: 20%;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-left: 0;
  }
`;

export const HeaderTitle = styled(Typography).attrs({
  type: 'title2Helv',
  tag: 'h1',
})`
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const InfoTitle = styled(Typography).attrs({
  type: 'body2Helv',
})`
  margin-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 210px;
  margin-top: 65px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 110px;
    margin-top: 31px;
  }
`;
