import ROUTES from 'router/Routes';
import { List, ListItem, Paragraph, StyledLink, Title } from '../styles';

const ReturnsExchanges = () => {
  return (
    <div>
      <Title>Returns Policy</Title>
      <Paragraph>We do not accept returns on on the following:</Paragraph>

      <List>
        <ListItem>
          Beauty Products (included but not limited to skincare, makeup, and/or
          grooming items)
        </ListItem>
        <ListItem>Art</ListItem>
        <ListItem>Custom or Personalized Items</ListItem>
      </List>
      <Paragraph space>Limited acceptance</Paragraph>
      <List>
        <ListItem>Lingerie</ListItem>
        <ListItem>Swimwear</ListItem>
      </List>

      <Paragraph space>
        These items will not display as eligible for return in your user
        account.
      </Paragraph>

      <Paragraph space>
        We accept returns within 14 Days of receipt of purchase. Items must have
        original tags, packaging, be unworn, clean, and in original condition.
        Your return label will be addressed directly to the business owner for
        review and acceptance. Please allow 14-21 days for processing.
      </Paragraph>
      <Paragraph space>Starting a return is fast and easy:</Paragraph>
      <List>
        <ListItem>
          Login to your Empower Global account and visit your Account homepage
          (in the top right corner).
        </ListItem>
        <ListItem>Go to your Order History page.</ListItem>
        <ListItem>
          Find the item you would like to return. If it is within the return
          window, you can follow the steps to initiate the return process. If
          you do not see the option to “return” next to the item in your order
          history, that means you cannot return the item because it is either
          past the 14-day return window, or the item is not eligible for return.
        </ListItem>
        <ListItem>
          Package and label your items. An $8.99 return shipping fee will be
          deducted from your refunded amount
        </ListItem>
      </List>

      <Title space>Holiday & Heavy Peak Returns & Refunds</Title>
      <Paragraph>
        We offer an extended holiday return period to make gift purchasing even
        easier! Eligible full priced merchandise ordered between November 15,
        2023 - December 11, 2023 can be returned until January 9, 2024.
      </Paragraph>
      <Paragraph space>
        Orders placed after December 11, 2023 are subject to our standard return
        policy.
      </Paragraph>

      <Paragraph space>
        If you do not have an EG Account, please use the form on the{' '}
        <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink> page to
        assist with processing your return
      </Paragraph>

      <Title space>
        How to contact us if an order is lost by carrier or stolen
      </Title>
      <Paragraph>
        Empower Global is not responsible for orders that are lost by the
        carrier or stolen. However, we will gladly provide all the information
        you need to file a claim with the carrier.
      </Paragraph>
      <Paragraph space>
        Empower Global is not responsible for orders that are lost by the
        carrier or stolen. However, we will gladly provide all the information
        you need to file a claim with the carrier.
      </Paragraph>
      <Paragraph space>
        Please use the form on the{' '}
        <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink> page for
        assistance with lost packages and any other questions.
      </Paragraph>

      <Title space>Exchange Policy</Title>
      <Paragraph>
        Due to the nature of our marketplace, we do not offer exchanges. You
        must process a return.
      </Paragraph>
      <Paragraph space>
        If you received the wrong item other than what was purchased, please use
        the form on the{' '}
        <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink> page for
        assistance.
      </Paragraph>

      <Paragraph space>
        Once your return is processed, a refund will be issued to the original
        payment method to your Empower Global account. You should see the refund
        on your bank statement within 7-10 business days, depending on your
        financial institution.
      </Paragraph>
    </div>
  );
};

export default ReturnsExchanges;
