import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const LineTitle = styled.div`
  background-color: ${COLORS.black};
  display: flex;
  padding: 6px 0;
  overflow: hidden;
  white-space: nowrap;
  justify-content: center;
`;

export const Title = styled.h2`
  background: ${COLORS.black};
  color: ${COLORS.white};
  text-align: center;
  font-family: ${FONTS.TTNorms.bold};
  font-size: 40px;
  text-transform: uppercase;
  padding: 8px 0;

  @media (max-width: 750px) {
    font-size: 28px;
  }
`;

export const TeamWrapper = styled.div`
  background-color: ${COLORS.black};
`;

export const SliderWrapper = styled.div`
  width: 100%;
  max-height: 580px;
  position: relative;

  .slick-next {
    right: 14px;
  }

  .slick-prev {
    left: 14px;
    z-index: 1;
  }
`;

export const TeamMemberCard = styled.div`
  width: 100%;
  height: 402px;
  object-fit: cover;
  padding: 0 2px;

  @media (min-width: 1250px) {
    height: 580px;
  }
`;

export const CardDetails = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  width: 100%;
  padding: 18px 30px;
`;

export const CardTitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const CardSubtitle = styled(Typography).attrs({
  color: 'primaryInverted',
})`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const BackgroundImage = styled.div<{
  imageUrl?: string;
}>`
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s ease;
  position: relative;
`;

export const StrokeText = styled(Typography)`
  font-family: ${FONTS.Bison.bold};
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  overflow: hidden;
  text-align: center;
  color: ${COLORS.white}50;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 18px;
  }
`;
