import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import {
  getIsLoggedIn,
  getAuthError,
  getCustomerLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import useAuth from 'hooks/useAuth';
import LogInForm from 'components/Forms/LogInForm/LogInForm';
import AuthContainer from 'components/AuthComponents/AuthContainer';
import RegistrationForm from 'components/Forms/RegistrationForm/RegistrationForm';
import ForgotPasswordContent from './ForgotPasswordContent';

const AuthContent = ({
  closeModal,
  isLogin,
}: {
  closeModal: () => void;
  isLogin?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    hasAgeRestriction,
    loginCustomer,
    registerCustomer,
    authCustomerViaSocials,
  } = useAuth();
  const [isLoginForm, setIsLoginForm] = useState<boolean>(isLogin || false);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const authError = useSelector(getAuthError);
  const isLoading = useSelector(getCustomerLoader);

  const showForgotPasswordModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'FORGOT_PASSWORD_MODAL',
        modalContent: (
          <ForgotPasswordContent
            closeModal={() =>
              dispatch(modalActions.hideModal('FORGOT_PASSWORD_MODAL'))
            }
          />
        ),
        boxStyle: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }),
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      closeModal();
    }
  }, [isLoggedIn]);

  return (
    <AuthContainer
      unSetModal={closeModal}
      isLogin={isLoginForm}
      setIsLogin={setIsLoginForm}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          // @ts-ignore
          key={isLoginForm}
          classNames="fade"
          timeout={300}
        >
          {isLoginForm ? (
            <LogInForm
              loginCustomer={loginCustomer}
              loginCustomerViaSocials={authCustomerViaSocials}
              authError={authError}
              isLoading={isLoading}
              showForgotPasswordModal={showForgotPasswordModal}
            />
          ) : (
            <RegistrationForm
              ageRestriction={hasAgeRestriction}
              registerCustomer={registerCustomer}
              registerCustomerViaSocials={authCustomerViaSocials}
              authError={authError}
              isLoading={isLoading}
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </AuthContainer>
  );
};

export default AuthContent;
