import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { RefinementType } from 'types/ProductsTypes';
import { getSubstring } from 'helpers/utils';
import { StyledTextField } from 'components/Forms/styles';
import { StyledSlider } from './styles';

const PriceSlider = ({
  refinement,
  handleChange,
  value,
}: {
  refinement: RefinementType;
  handleChange: (prop: any) => void;
  value: string | undefined;
}) => {
  const priceValues = refinement?.values || [];
  const minValue = +getSubstring(priceValues[0]?.value, '(', '..') || 0;
  const maxValue = +getSubstring(
    priceValues[priceValues.length - 1]?.value,
    '..',
    ')',
  );
  const [priceRangeValue, setPriceRangeValue] = useState([minValue, maxValue]);

  const handlePriceRangeChange = (_: any, newValue: any) => {
    setPriceRangeValue(newValue);
    handleChange([+newValue[0], +newValue[1]]);
  };

  useEffect(() => {
    if (!value) {
      setPriceRangeValue([minValue, maxValue]);
    }
  }, [value]);

  return (
    <>
      <StyledSlider
        getAriaLabel={() => 'Price range'}
        value={[
          Number(priceRangeValue[0]) || 0,
          Number(priceRangeValue[1]) || 0,
        ]}
        onChange={handlePriceRangeChange}
        valueLabelDisplay="auto"
        min={minValue}
        max={maxValue}
      />
      <Stack direction="row" justifyContent="space-evenly" alignItems="center">
        <StyledTextField
          label="min"
          type="number"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          sx={{ width: '90px' }}
          value={priceRangeValue[0]}
          onChange={(e) => {
            handlePriceRangeChange(e, [e.target.value, priceRangeValue[1]]);
          }}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
        <Typography>-</Typography>
        <StyledTextField
          label="max"
          type="number"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          sx={{ width: '90px' }}
          value={priceRangeValue[1]}
          onChange={(e) => {
            handlePriceRangeChange(e, [priceRangeValue[0], e.target.value]);
          }}
          InputProps={{
            inputProps: { max: maxValue },
          }}
        />
      </Stack>
    </>
  );
};

export default PriceSlider;
