import { useState, useEffect, useRef } from 'react';

const useClickOutside = (initialIsVisible: boolean) => {
  const [isOpen, setIsOpen] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  return { ref, isOpen, setIsOpen };
};

export default useClickOutside;
