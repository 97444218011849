import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import {
  getPublicGuestLoginCode,
  getLoginTokens,
} from 'eg_SFCC_FE_core/axios/api';
import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import {
  generateCodeVerifier,
  updateLocalTokens,
} from 'eg_SFCC_FE_core/axios/helpers';

export const getPublicGuestTokenThunk: AsyncThunkPayloadCreator<{
  rejectValue: ErrorResponse;
}> = async (props, { rejectWithValue }) => {
  let code;
  const generatedVerifier = generateCodeVerifier();

  try {
    await getPublicGuestLoginCode(generatedVerifier);
  } catch (error: any) {
    const urlParams = new URLSearchParams(error.response.data.queryString);
    const isError = !!urlParams.get('error');

    if (error.response.status === 303 && !isError) {
      code = urlParams.get('code');
    } else {
      return rejectWithValue(error.response.data);
    }
  }

  try {
    const response = code && (await getLoginTokens(code, generatedVerifier));
    updateLocalTokens(response);

    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
