/* eslint-disable import/no-cycle */
import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { chain } from 'lodash';

import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import { ErrorResponse, PaymentInstrumentType } from 'eg_SFCC_FE_core/types';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { BasketProductItemType } from 'types/ProductsTypes';
import ROUTES from 'router/Routes';
import { AsyncThunks } from '..';

const createBasket = async () => {
  const params = { siteId: process.env.REACT_APP_SITE_ID };

  const response = await axiosInstance.post(
    endpoints.basket.createBasket,
    {},
    { params },
  );

  return response;
};

export const getBasketsThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  { rejectValue: ErrorResponse; state: RootState }
> = async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.get(
      endpoints.customer.customerBaskets(customerId),
      { params },
    );

    if (!response.data.total) {
      return null;
    }

    return response.data?.baskets[0];
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const createBasketThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  { rejectValue: ErrorResponse }
> = async (_, { rejectWithValue }) => {
  try {
    const response = await createBasket();

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const syncBasketThunk: AsyncThunkPayloadCreator<
  unknown,
  | {
      basketProductItems?: BasketProductItemType[];
      withShippingAddress?: boolean;
    }
  | undefined,
  {
    rejectValue: ErrorResponse;
    state: RootState;
  }
> = async (data, { rejectWithValue, getState, dispatch }) => {
  try {
    // user returns back from redirection after Afterpay
    // basket is created after order placement
    // there is no need to add item in the card
    const newlyCreatedBasket =
      window.location.pathname === ROUTES.CHECKOUT_CONFIRMATION;

    const state: RootState = getState();

    const { isLoggedIn } = state.customerReducer;
    const cartItems = newlyCreatedBasket
      ? []
      : data?.basketProductItems?.length
      ? data?.basketProductItems
      : state.basketReducer.productItems?.map((productItem: any) => {
          return {
            productId: productItem.productId,
            quantity: productItem.quantity,
          };
        });
    const shippingAddress =
      state.basketReducer.result.shipments[0]?.shippingAddress;

    const response = isLoggedIn
      ? await dispatch(AsyncThunks.getBaskets())
      : { payload: null };

    if (!response?.payload) {
      await dispatch(AsyncThunks.createBasket());
      cartItems.length &&
        (await dispatch(AsyncThunks.addItemToBasket(cartItems)));
      shippingAddress &&
        data?.withShippingAddress &&
        (await dispatch(
          AsyncThunks.addShippingAddressToBasketShipment(shippingAddress),
        ));
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateBasketThunk: AsyncThunkPayloadCreator<
  any,
  { customerInfo: { email: string } },
  { rejectValue: ErrorResponse; state: RootState }
> = async ({ customerInfo }, { rejectWithValue, getState, dispatch }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const state = getState();
    const { basketId } = state.basketReducer.result;

    const response = await axiosInstance.patch(
      `${endpoints.basket.createBasket}/${basketId}`,
      { customerInfo },
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.updateBasket({ customerInfo }));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const getOrCreateCustomerBasketsThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (customerId, { rejectWithValue }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    let response = await axiosInstance.get(
      endpoints.customer.customerBaskets(customerId),
      { params },
    );

    if (!response.data.total) {
      response = await createBasket();
      return response.data;
    }

    return response.data.baskets[0];
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const addItemToBasketThunk: AsyncThunkPayloadCreator<
  any,
  { productId: string; quantity: number }[],
  { rejectValue: ErrorResponse; state: RootState }
> = async (cartItems, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.post(
      endpoints.basket.basketItems(basketId),
      cartItems,
      { params },
    );

    return response?.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.addItemToBasket(cartItems));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const updateItemInBasketThunk: AsyncThunkPayloadCreator<
  any,
  {
    itemId: string;
    valuesToUpdate: {
      productId: string;
      quantity?: number;
      optionItems?: {
        optionId: string;
        optionValueId: string;
      }[];
    };
  },
  { rejectValue: ErrorResponse; getState: () => any }
> = async (
  { itemId, valuesToUpdate },
  { rejectWithValue, getState, dispatch },
) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const state: any = getState();
    const { basketId } = state.basketReducer.result;
    const { productItems } = state.basketReducer;
    let itemIdUpdate = itemId;

    const itemToUpdate = productItems.find(
      (productItem: BasketProductItemType) => {
        return productItem.productId === valuesToUpdate.productId;
      },
    );

    if (itemToUpdate) {
      itemIdUpdate = itemToUpdate.itemId;
    }

    const response = await axiosInstance.patch(
      `${endpoints.basket.basketItems(basketId)}/${itemIdUpdate}`,
      valuesToUpdate,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(
        AsyncThunks.updateItemInBasket({ itemId, valuesToUpdate }),
      );
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const removeItemFromBasketThunk: AsyncThunkPayloadCreator<
  any,
  { itemId: string; productId: string },
  { rejectValue: ErrorResponse; getState: () => any }
> = async ({ itemId, productId }, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.delete(
      `${endpoints.basket.basketItems(basketId)}/${itemId}`,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      const state: any = getState();
      const { productItems } = state.basketReducer.result;

      const itemsToAdd = chain(productItems)
        .filter((productItem: BasketProductItemType) => {
          return productItem.productId !== productId;
        })
        .map((productItem: BasketProductItemType) => {
          return {
            productId: productItem.productId,
            quantity: productItem.quantity,
          };
        })
        .value();

      // @ts-ignore
      await dispatch(AsyncThunks.syncBasket(itemsToAdd));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const addBillingAddressToBasketThunk: AsyncThunkPayloadCreator<
  any,
  AddressFormFieldsType,
  { rejectValue: ErrorResponse; state: RootState }
> = async (address, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.put(
      endpoints.basket.billingAddress(basketId),
      address,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.addBillingAddressToBasket(address));
    } else {
      return rejectWithValue({ ...error.response.data, addressError: true });
    }
  }
};

export const addShippingAddressToBasketShipmentThunk: AsyncThunkPayloadCreator<
  any,
  AddressFormFieldsType,
  { rejectValue: ErrorResponse; state: RootState }
> = async (address, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const { basketId, shipments } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.put(
      // TODO: temporary hardcoded as first shipment
      endpoints.basket.shippingAddress(basketId, shipments[0].shipmentId),
      address,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.addShippingAddressToBasketShipment(address));
    } else {
      return rejectWithValue({ ...error.response.data, addressError: true });
    }
  }
};

export const getShippingMethodsThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId, shipments } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.get(
      // TODO: temporary hardcoded as first shipment
      endpoints.basket.shippingMethods(basketId, shipments[0].shipmentId),
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.getShippingMethods());
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const addShippingMethodToBasketShipmentThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse; state: RootState }
> = async (shippingMethodId, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const { basketId, shipments } = state.basketReducer.result;

    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
    };

    const response = await axiosInstance.put(
      // TODO: temporary hardcoded as first shipment
      endpoints.basket.shippingMethod(basketId, shipments[0].shipmentId),
      { id: shippingMethodId },
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(
        AsyncThunks.addShippingMethodToBasketShipment(shippingMethodId),
      );
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const getPaymentMethodsThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.get(
      endpoints.basket.paymentMethods(basketId),
      { params },
    );

    return response.data.applicablePaymentMethods;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.getPaymentMethods());
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const addPaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  PaymentInstrumentType,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (paymentInstrument, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.post(
      endpoints.basket.paymentInstrument(basketId),
      paymentInstrument,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.addPaymentInstrument(paymentInstrument));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const removePaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  string,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (paymentInstrumentId, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.delete(
      `${endpoints.basket.paymentInstrument(basketId)}/${paymentInstrumentId}`,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.removePaymentInstrument(paymentInstrumentId));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const addCouponThunk: AsyncThunkPayloadCreator<
  any,
  string,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (couponCode, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
    };

    const response = await axiosInstance.post(
      endpoints.basket.coupons(basketId),
      { code: couponCode },
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.addCoupon(couponCode));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};

export const removeCouponThunk: AsyncThunkPayloadCreator<
  any,
  string,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (couponItemId, { rejectWithValue, getState, dispatch }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
    };

    const response = await axiosInstance.delete(
      `${endpoints.basket.coupons(basketId)}/${couponItemId}`,
      { params },
    );

    return response.data;
  } catch (error: any) {
    if (error.response.data.title === 'Basket Not Found') {
      await dispatch(AsyncThunks.syncBasket());
      await dispatch(AsyncThunks.removeCoupon(couponItemId));
    } else {
      return rejectWithValue(error.response.data);
    }
  }
};
