import { Dispatch, SetStateAction } from 'react';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import AddressItem from './AddressItem';
import { AddressBlockTitle, AddressContainer } from './styles';

const AddressBlock = ({
  addressData,
  selectedAddress,
  selectAddress,
  useAddressActions,
  handleEdit,
  handleAddressCheck,
  checkedAddress,
}: {
  addressData: CustomerAddressType[];
  selectedAddress?: CustomerAddressType | null;
  selectAddress?: (address: CustomerAddressType | null) => Promise<void> | void;
  useAddressActions?: boolean;
  handleEdit?: (param: any) => void;
  handleAddressCheck?: Dispatch<
    SetStateAction<CustomerAddressType | undefined>
  >;
  checkedAddress?: CustomerAddressType | null;
}) => {
  return (
    <AddressContainer>
      {!addressData?.length ? (
        <AddressBlockTitle>No saved addresses</AddressBlockTitle>
      ) : (
        <AddressBlockTitle>
          {addressData.length} saved address
          {addressData.length === 1 ? '' : 'es'}
        </AddressBlockTitle>
      )}

      {addressData?.length
        ? addressData.map((address: CustomerAddressType, index: number) => {
            return (
              <AddressItem
                key={Math.random()}
                address={address}
                selectAddress={selectAddress}
                index={index}
                handleEdit={handleEdit}
                useAddressActions={useAddressActions}
                useAddressCheckbox={!!handleAddressCheck}
                handleAddressCheck={handleAddressCheck}
                checkedAddress={checkedAddress}
              />
            );
          })
        : null}
    </AddressContainer>
  );
};

export default AddressBlock;
