import { MouseEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerFormSchema } from 'helpers/validationSchemas';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormInputController from 'components/Inputs/FormInputController';
import BasicDatePicker from 'components/Dropdowns/Selects/BasicDatePicker';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
// import SocialMediaBtns from 'components/FormComponents/SocialMediaBtns';
// import AuthFormDivider from 'components/FormComponents/AuthFormDivider';
import { RegisterParamsType } from 'eg_SFCC_FE_core/types';
import ROUTES from 'router/Routes';
import { SocialAuthType } from 'types/AccountTypes';
import { Text } from './styles';
import {
  AccentLink,
  AuthButton,
  AuthFormWrapper,
  ErrorMessage,
} from '../styles';

interface State {
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
}

const RegistrationForm = ({
  registerCustomer,
  registerCustomerViaSocials,
  ageRestriction,
  authError,
  isLoading,
}: {
  registerCustomer: (params: RegisterParamsType) => void;
  registerCustomerViaSocials: (type: SocialAuthType) => void;
  ageRestriction: boolean;
  authError: any;
  isLoading: boolean;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const values = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    email: '',
    birthday: '',
  };

  const methods = useForm<State>({
    resolver: yupResolver(registerFormSchema),
    defaultValues: values,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<State> = (data: State, e: any) => {
    data &&
      registerCustomer({
        customer: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          login: data.email,
          birthday: data.birthday,
        },
        password: data.password,
      });
    e.target.reset();
  };

  const onError = () => {};

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <AuthFormWrapper
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      {ageRestriction && (
        <ErrorMessage>
          Due to age restrictions, you are not eligible to sign up.
        </ErrorMessage>
      )}

      {authError && (
        <ErrorMessage>
          {authError.status_code === 502 ||
          (authError.status_code === 400 &&
            authError.statusCode === 'is_not_vip_customer')
            ? 'You are not allowed to register.'
            : authError.title}
        </ErrorMessage>
      )}

      <FormInputController
        control={control}
        name="firstName"
        label="First Name*"
        error={!!errors.firstName}
        errorMessage={errors?.firstName?.message}
      />
      <FormInputController
        control={control}
        name="lastName"
        label="Last Name*"
        error={!!errors.lastName}
        errorMessage={errors?.lastName?.message}
      />
      <FormInputController
        control={control}
        name="email"
        label="Email Address*"
        error={!!errors.email}
        errorMessage={errors?.email?.message}
      />
      <FormInputController
        control={control}
        name="password"
        type={showPassword ? 'text' : 'password'}
        label="Password*"
        error={!!errors.password}
        errorMessage={errors?.password?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormInputController
        control={control}
        name="confirmPassword"
        type={showPassword ? 'text' : 'password'}
        label="Confirm Password*"
        error={!!errors.confirmPassword}
        errorMessage={errors?.confirmPassword?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <BasicDatePicker
        control={control}
        name="birthday"
        label="Birthday (MM/DD/YYYY)*"
        defaultValue={values.birthday}
        error={!!errors.birthday}
        errorMessage={errors?.birthday?.message}
      />

      <Text>
        By creating an account, you agree to our{' '}
        <AccentLink to={ROUTES.TERMS_CONDITIONS}>Terms of Service</AccentLink>{' '}
        and have read and understood the{' '}
        <AccentLink to={ROUTES.PRIVACY_POLICY}>Privacy Policy</AccentLink>*
      </Text>

      <AuthButton aria-label="create account" isLoading={isLoading}>
        create account
      </AuthButton>

      {/* <AuthFormDivider /> */}
      {/* <SocialMediaBtns handleClick={registerCustomerViaSocials} /> */}
    </AuthFormWrapper>
  );
};

export default RegistrationForm;
