import { isDev } from 'appConstants';

export const catalog = [
  {
    id: isDev ? '3' : '224',
    name: 'Clothing',
    display: false,
  },
  {
    id: isDev ? '67' : '315',
    name: 'Shoes & Accessories',
    display: false,
  },
  {
    id: '4s',
    name: 'Brands',
    display: true,
    expands: false,
    route: '/brands',
  },
  {
    id: isDev ? '4' : '225',
    name: 'mens',
    expands: true,
    display: true,
  },
  {
    id: isDev ? '48' : '302',
    name: 'fashion for all',
    expands: true,
    display: true,
  },
  {
    id: isDev ? '24' : '256',
    name: 'womens',
    expands: true,
    display: true,
  },
  {
    id: isDev ? '141' : '176',
    name: 'Beauty',
    expands: true,
    display: true,
  },
  {
    id: '459',
    name: 'Home',
    expands: true,
    display: true,
  },
];

export const meganavCatalog = catalog;

export const gallery = [
  {
    id: '1',
    image: 'assets/jpg/home/image11.jpg',
  },
  {
    id: '2',
    image: 'assets/jpg/home/image12.jpg',
  },
  {
    id: '3',
    image: 'assets/jpg/home/image13.jpg',
  },
  {
    id: '4',
    image: 'assets/jpg/home/image14.jpg',
  },
  {
    id: '5',
    image: 'assets/jpg/home/image15.jpg',
  },
  {
    id: '6',
    image: 'assets/jpg/home/image16.jpg',
  },
  {
    id: '7',
    image: 'assets/jpg/home/image13.jpg',
  },
  {
    id: '8',
    image: 'assets/jpg/home/image6.jpg',
  },
  {
    id: '9',
    image: 'assets/jpg/home/image13.jpg',
  },
  {
    id: '10',
    image: 'assets/jpg/home/image11.jpg',
  },
];
