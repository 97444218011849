import { styled as materialStyled, Slider } from '@mui/material';
import { COLORS } from 'styles';

export const StyledSlider = materialStyled(Slider)(() => ({
  height: 2,
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: 'transparent',
    border: `1px solid ${COLORS.grey}`,
    height: 2,
  },
  '& .MuiSlider-thumb': {
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
}));
