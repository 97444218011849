import styled from 'styled-components';

import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const ColoredButtonWrapper = styled.button<{
  width?: string;
  inverted?: boolean;
  disabled?: boolean;
  height?: string;
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '33px'};
  background: ${({ disabled, inverted }) =>
    disabled
      ? COLORS.pale
      : inverted
      ? COLORS.updatedAccentBackground
      : 'transparent'};
  border: ${({ disabled, inverted }) =>
    disabled ? COLORS.pale : `1px solid ${COLORS.updatedAccentBackground}`};
  transition: 0.3s ease-out;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  & p {
    color: ${({ disabled, inverted }) =>
      disabled
        ? COLORS.lightGrey
        : inverted
        ? COLORS.white
        : COLORS.updatedAccentBackground};
  }

  &:hover {
    background: ${({ disabled, inverted }) =>
      disabled
        ? COLORS.pale
        : inverted
        ? COLORS.white
        : COLORS.updatedAccentBackground};

    & p {
      color: ${({ disabled, inverted }) =>
        disabled
          ? COLORS.lightGrey
          : inverted
          ? COLORS.updatedAccentBackground
          : COLORS.white};
    }
  }
`;

export const ColoredButtonText = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  text-transform: uppercase;
  transition: 0.3s;
  font-family: ${FONTS.Helvetica.medium};

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 11px;
    line-height: 13px;
  }
`;

export const IconButtonWrapper = styled.button<{
  width?: string;
}>`
  width: ${({ width }) => width || '141px'};
  height: 32px;
  border: 1px solid ${COLORS.buttonBorder};
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButtonText = styled(Typography).attrs({
  type: 'button',
})`
  font-size: 14px;
  line-height: 16.52px;
  margin-left: 6.5px;
`;

export const TextButtonWrapper = styled.button<{
  width?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextButtonText = styled(Typography).attrs({
  type: 'subtitle2Helv',
})`
  text-transform: uppercase;

  &:hover {
    color: ${COLORS.accent};
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
