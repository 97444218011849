import { useState } from 'react';
import { Helmet } from 'react-helmet';

import { AnimatedWrapper } from 'components';
import InfoPageMenu from 'components/InfoPagesComponents/InfoPageMenu';
import InfoPageText from 'components/InfoPagesComponents/InfoPageText';
import InfoPageWrapper from 'components/InfoPagesComponents/InfoPageWrapper';
import {
  AcceptingTerms,
  OtherDocuments,
  YourPrivacy,
  YourAccount,
  YourContent,
  YourUseOfServices,
  Termination,
  YourInteractions,
  Indemnification,
  DisputesOtherUsers,
  DisputesEG,
  ChangesToTerms,
  FinerLegalPoints,
  ContactInfo,
} from 'components/InfoPagesComponents/Templates';

const privacyPolicyMenu = [
  {
    title: 'Accepting These Terms',
    content: <AcceptingTerms />,
  },
  {
    title: 'Those Other Documents We Mentioned',
    content: <OtherDocuments />,
  },
  {
    title: 'Your Privacy',
    content: <YourPrivacy />,
  },
  {
    title: 'Your Account with Empower Global',
    content: <YourAccount />,
  },
  {
    title: 'Your Content',
    content: <YourContent />,
  },
  {
    title: 'Your Use of Our Services',
    content: <YourUseOfServices />,
  },
  {
    title: 'Termination',
    content: <Termination />,
  },
  {
    title:
      'Your Interactions on the Services, Warranties, and Liability Limits',
    content: <YourInteractions />,
  },
  {
    title: 'Indemnification (or What Happens If You Get Us Sued)',
    content: <Indemnification />,
  },
  {
    title: 'Disputes with Other Users',
    content: <DisputesOtherUsers />,
  },
  {
    title: 'Disputes with Empower Global',
    content: <DisputesEG />,
  },
  {
    title: 'Changes to the Terms',
    content: <ChangesToTerms />,
  },
  {
    title: 'Some Finer Legal Points',
    content: <FinerLegalPoints />,
  },
  {
    title: 'Contact Information',
    content: <ContactInfo />,
  },
];

const TermsConditionsPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(privacyPolicyMenu[0]);

  return (
    <InfoPageWrapper title="terms & conditions">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions</title>
        <meta name="description" content="Terms & Conditions" />
      </Helmet>
      <InfoPageMenu
        menu={privacyPolicyMenu}
        currentMenuItem={currentMenuItem}
        setCurrentMenuItem={setCurrentMenuItem}
      />
      <AnimatedWrapper animationKey={currentMenuItem.title}>
        <InfoPageText title="terms & conditions">
          {currentMenuItem.content}
        </InfoPageText>
      </AnimatedWrapper>
    </InfoPageWrapper>
  );
};

export default TermsConditionsPage;
