import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';
import { BREAKPOINTS, FONTS } from 'styles';

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiRadio-root {
      padding: 5px 9px;

      @media (max-width: ${BREAKPOINTS.s}px) {
        padding: 0 9px;
      }
    }

    .MuiTypography-root {
      font-size: 16px;
      line-height: 19px;
      font-family: ${FONTS.Helvetica.regular};

      @media (max-width: ${BREAKPOINTS.s}px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
`;
