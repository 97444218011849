import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, uniqBy } from 'lodash';

import { Typography, CustomNavLink, ColoredButton } from 'components';
import SearchForm from 'components/Forms/SearchForm/SearchForm';
import SearchProductCards from 'components/ProductSearchComponents/SearchProductCards';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import {
  getProductSearchResult,
  getSearchSuggestionsResult,
  getSearchFilterValues,
  getSearchSortingId,
  getSavedSearchQueries,
  getIsLoggedIn,
  getCustomerLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { productSearchResultActions } from 'eg_SFCC_FE_core/store/reducers/slices/productSearchResultSlice';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import ROUTES from 'router/Routes';
import useGoogleTags from 'hooks/useGoogleTags';
import { useInfoModal } from 'hooks/useModals';
import { SavedSearchQueryType } from 'types/AccountTypes';
import { BREAKPOINTS, COLORS } from 'styles';
import { CrossIcon, EGLogoIcon, UpdatedLogoIcon } from 'assets/svg';
import useDimensions from 'hooks/useDimensions';
import {
  DesktopFiltersWrapper,
  MobileFiltersWrapper,
} from 'pages/ProductListingPage/styles';
import BasicMenu from 'components/Dropdowns/Menus/BasicMenu';
import Sorting from 'components/ProductListingComponents/Sorting';
import Filters from 'components/ProductListingComponents/Filters';
import Pagination from 'components/Pagination/Pagination';
import { IFilterProps } from 'types/ProductsTypes';
import SavedSearchUpdateContent from './SavedSearchUpdateContent';
import {
  ProductSearchTitle,
  SearchSuggestionsWrapper,
  SearchSuggestion,
  SearchContentWrapper,
  ProductSearchResultWrapper,
  SearchInfo,
  SearchFormWrapper,
} from './styles';

const PAGE_SIZE = 12;

const ProductSearchContent = ({
  closeModal,
  searchQuery,
}: {
  closeModal: () => void;
  searchQuery: string;
}) => {
  const [currFilterValues, setCurrFilterValues] = useState<IFilterProps[]>([]);
  const [currSortingId, setCurrSortingId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>(searchQuery);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productList, pending, total, result, currentPage } = useSelector(
    getProductSearchResult,
  );
  const searchSuggestionsResult = useSelector(getSearchSuggestionsResult);
  const filterValues = useSelector(getSearchFilterValues);
  const sortingId = useSelector(getSearchSortingId);
  const savedSearchQueries = useSelector(getSavedSearchQueries);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const customerLoader = useSelector(getCustomerLoader);
  const { trackEventWithPageView } = useGoogleTags();
  const openInfoModal = useInfoModal();
  const { width } = useDimensions();
  const appliedSearchValue = result?.query;
  // check if current search was saved before
  const currentSavedSearchQuery = savedSearchQueries.find(
    (savedSearch: SavedSearchQueryType) => {
      return savedSearch.c_q === appliedSearchValue?.toLowerCase();
    },
  );

  const suggestedCategories = uniqBy(
    searchSuggestionsResult.categorySuggestions?.categories,
    'name',
  );

  const closeSearch = () => {
    navigate(-1);
    closeModal();
  };

  const setCurrentPage = (page: number) => {
    dispatch(productSearchResultActions.setCurrentPage(page));
  };

  const searchProducts = async (
    value?: string,
    useFilters: boolean = true,
    useSorting: boolean = true,
  ) => {
    const query = (value || searchValue).trim();

    if (query) {
      const params = {
        q: query,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        refine: {},
        sort: '',
      };

      if (currSortingId && useSorting) {
        params.sort = currSortingId;
        dispatch(productSearchResultActions.setSortingId(currSortingId));
      }

      if (!isEmpty(currFilterValues) && useFilters) {
        currFilterValues.forEach((val: IFilterProps) => {
          params.refine = {
            ...params.refine,
            [val.attributeId]: `${val.attributeId}=${val.values.join('|')}`,
          };
        });

        dispatch(productSearchResultActions.setFilterValues(currFilterValues));
      }

      await dispatch(AsyncThunks.getProductSearchResult(params));

      trackEventWithPageView('search', 'search', {
        site_search_query: query,
      });
    }
  };

  const showSavedSearchUpdateModal = () => {
    dispatch(
      modalActions.showModal({
        id: 'SAVED_SEARCH_UPDATE_MODAL',
        modalContent: (
          <SavedSearchUpdateContent
            savedSearchQuery={currentSavedSearchQuery}
          />
        ),
        boxStyle: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 600,
          bgcolor: COLORS.white,
        },
      }),
    );
  };

  const processSearchResult = useCallback(async () => {
    if (currentSavedSearchQuery) {
      showSavedSearchUpdateModal();
    } else {
      try {
        await dispatch(
          AsyncThunks.saveSearchResult(searchValue.trim().toLowerCase()),
        ).unwrap();

        openInfoModal(`Search for ${searchValue} was saved`);
      } catch (e) {
        openInfoModal('Something went wrong. Try again later');
      }
    }
  }, [currentSavedSearchQuery, searchValue]);

  const fetchSearchSuggestions = async (search: string) => {
    await dispatch(AsyncThunks.getSearchSuggestions(search));
  };

  const searchProductsBySearchSuggestion = async (value: string) => {
    await setSearchValue(value);

    if (currentPage !== 1) {
      // in this case, products search is called by page changing, runs in useEffect
      setCurrentPage(1);
    } else {
      dispatch(productSearchResultActions.resetFilterSorting());
      searchProducts(value);
    }

    await dispatch(productSearchResultActions.resetSearchSuggestions());
  };

  const handleSubmit = useCallback(
    (e?: { preventDefault: () => void }) => {
      e?.preventDefault();
      setCurrentPage(1);
      searchProducts();
      setCurrSortingId('');
      setCurrFilterValues([]);
      dispatch(productSearchResultActions.resetFilterSorting());
      dispatch(productSearchResultActions.resetSearchSuggestions());
    },
    [searchValue, currentPage],
  );

  const handleSearchValueChange = useCallback((value: string) => {
    setSearchValue(value);

    if (value?.length > 2) {
      fetchSearchSuggestions(value);
    } else {
      dispatch(productSearchResultActions.resetSearchSuggestions());
    }

    if (!value?.length) {
      dispatch(productSearchResultActions.clearProductList());
      dispatch(productSearchResultActions.resetFilterSorting());
    }
  }, []);

  const handleDoneButtonClick = async () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    await searchProducts();
  };

  const handleResetButtonClick = (target?: 'filters' | 'sorting') => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    if (target === 'filters') {
      setCurrFilterValues([]);
      dispatch(productSearchResultActions.setFilterValues(currFilterValues));
      searchProducts('', false);
      return;
    }

    if (target === 'sorting') {
      setCurrSortingId('');
      dispatch(productSearchResultActions.setSortingId(''));
      searchProducts('', true, false);
      return;
    }

    setCurrFilterValues([]);
    setCurrSortingId('');
    dispatch(productSearchResultActions.resetFilterSorting());
    searchProducts('', false, false);
  };

  useEffect(() => {
    searchProducts();
  }, [currentPage]);

  useEffect(() => {
    !isEmpty(result) && setSearchValue(appliedSearchValue);
  }, [result]);

  useEffect(() => {
    filterValues && setCurrFilterValues(filterValues);
    sortingId && setCurrSortingId(sortingId);
  }, [filterValues, sortingId]);

  return (
    <SearchContentWrapper>
      <ProductSearchTitle id="transition-modal-description">
        <CustomNavLink to={ROUTES.HOME}>
          {width > BREAKPOINTS.xs ? (
            <UpdatedLogoIcon title="Empower Global Logo" />
          ) : (
            <EGLogoIcon style={{ height: 25 }} title="Empower Global Logo" />
          )}
        </CustomNavLink>
        <CrossIcon onClick={closeSearch} style={{ cursor: 'pointer' }} />
      </ProductSearchTitle>
      <ProductSearchResultWrapper>
        <DesktopFiltersWrapper>
          <Filters
            refinements={result?.refinements}
            filterValues={currFilterValues}
            setFilterValues={setCurrFilterValues}
            isExpanded
          />
          <Sorting
            setSortingId={setCurrSortingId}
            sortingId={currSortingId}
            sortingOptions={result?.sortingOptions}
            isExpanded
          />
          <ColoredButton
            onClick={handleDoneButtonClick}
            style={{ marginTop: 36 }}
          >
            Done
          </ColoredButton>
          <ColoredButton
            onClick={handleResetButtonClick}
            style={{ marginTop: 10 }}
          >
            Reset
          </ColoredButton>
        </DesktopFiltersWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <SearchFormWrapper>
            <SearchForm
              searchValue={searchValue}
              handleSearchValueChange={handleSearchValueChange}
              handleSubmit={handleSubmit}
            />
          </SearchFormWrapper>
          <SearchInfo>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                type="title6Helv"
                style={{ textTransform: 'uppercase', marginRight: 20 }}
              >
                {appliedSearchValue
                  ? `${total} search results for "${appliedSearchValue}"`
                  : ''}
                {!!currentSavedSearchQuery && isLoggedIn
                  ? ' were already saved'
                  : ''}
              </Typography>
              <MobileFiltersWrapper hidden={!appliedSearchValue}>
                <BasicMenu
                  title="sort by"
                  handleDoneButtonClick={handleDoneButtonClick}
                  handleResetButtonClick={() =>
                    handleResetButtonClick('sorting')
                  }
                  content={
                    <Sorting
                      setSortingId={setCurrSortingId}
                      sortingId={currSortingId}
                      sortingOptions={result?.sortingOptions}
                    />
                  }
                />
                <BasicMenu
                  title="filters"
                  handleDoneButtonClick={handleDoneButtonClick}
                  handleResetButtonClick={() =>
                    handleResetButtonClick('filters')
                  }
                  content={
                    <Filters
                      refinements={result?.refinements}
                      filterValues={currFilterValues}
                      setFilterValues={setCurrFilterValues}
                    />
                  }
                />
              </MobileFiltersWrapper>
            </div>
            {!isEmpty(suggestedCategories) && searchValue ? (
              <SearchSuggestionsWrapper>
                <Typography type="xsmallHelv" style={{ fontStyle: 'italic' }}>
                  Did you mean?
                </Typography>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {suggestedCategories?.map((cat) => {
                    return (
                      <SearchSuggestion
                        key={cat.id}
                        onClick={() =>
                          searchProductsBySearchSuggestion(cat.name)
                        }
                      >
                        {cat.name}
                      </SearchSuggestion>
                    );
                  })}
                </div>
              </SearchSuggestionsWrapper>
            ) : null}
          </SearchInfo>
          <SearchProductCards
            total={total}
            productList={productList}
            isLoading={pending}
            searchValue={appliedSearchValue}
            isLoggedIn={isLoggedIn}
            processSearchResult={processSearchResult}
            isSearchQuerySaved={!!currentSavedSearchQuery}
            savedSearchLoading={customerLoader}
          />
        </div>
      </ProductSearchResultWrapper>
      {appliedSearchValue ? (
        <Pagination
          size={width < BREAKPOINTS.s ? 'small' : undefined}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          totalCount={total}
          setCurrentPage={setCurrentPage}
          style={{ justifyContent: 'center', marginTop: 60 }}
        />
      ) : null}
    </SearchContentWrapper>
  );
};

export default ProductSearchContent;
