import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material';
import { COLORS } from 'styles';

const BpIcon = styled('span')(({ theme }) => ({
  width: 16,
  height: 16,
  outline: `1px solid ${COLORS.black}`,
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    width: 9,
    height: 9,
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.04184 4.00018L9.53072 1.72859L8.12724 0.819937L6.68151 3.02568L8.04184 4.00018Z' fill='black'/%3E%3Cpath d='M8.04184 4.00018L6.68151 3.02568L3.76342 7.47779L1.27446 5.6458L0.279064 6.98103L4.19762 9.86528L8.04184 4.00018Z' fill='black'/%3E%3C/svg%3E%0A\")",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    content: '""',
  },
});

const BasicCheckbox = ({
  id,
  checked,
  handleChange,
  value,
}: {
  id?: string;
  checked: boolean;
  handleChange: any;
  value?: any;
}) => {
  return (
    <Checkbox
      checked={checked}
      disableRipple
      onChange={value ? () => handleChange(value) : handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      id={id}
      sx={{
        color: COLORS.border,
        borderWidth: 1,
        paddingLeft: '11px',

        '&.Mui-checked': {
          color: COLORS.accent,
        },
      }}
    />
  );
};

export default BasicCheckbox;
