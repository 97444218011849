import { GoogleIcon, AppleIcon, FacebookColoredIcon } from 'assets/svg';
import { ButtonWithIcon } from 'components/Buttons/ButtonWithIcon';
import { SocialAuthType } from 'types/AccountTypes';

const buttonsData: {
  text: string;
  Icon: any;
  type: SocialAuthType;
  enabled: boolean;
}[] = [
  {
    text: 'Sign-In with Google',
    Icon: GoogleIcon,
    type: 'google',
    enabled: true,
  },
  {
    text: 'Sign-In with Apple',
    Icon: AppleIcon,
    type: 'apple',
    enabled: false,
  },
  {
    text: 'Sign-In with Facebook',
    Icon: FacebookColoredIcon,
    type: 'facebook',
    enabled: false,
  },
];

const SocialMediaBtns = ({
  handleClick,
}: {
  handleClick: (type: SocialAuthType) => void;
}) => {
  return (
    <>
      {buttonsData
        .filter(({ enabled }) => enabled)
        .map(({ text, Icon, type }) => {
          return (
            <ButtonWithIcon
              key={Math.random()}
              Icon={Icon}
              style={{
                width: '100%',
                height: 44,
                fontSize: 16,
                cursor: 'pointer',
                marginBottom: 16,
              }}
              onClick={() => handleClick(type)}
            >
              {text}
            </ButtonWithIcon>
          );
        })}
    </>
  );
};

export default SocialMediaBtns;
