import ImageList from '@mui/material/ImageList';
import { ImageType } from 'types/ProductsTypes';
import { BREAKPOINTS } from 'styles';
import { FullImagesWrapper } from './styles';
import ImageListItemComponent from './ImageListItem';
import DoubleImageListItemComponent from './DoubleImageListItem';

const ImageListComponent = ({ imageGroup }: { imageGroup: ImageType[] }) => {
  const desktopImageGroups = imageGroup.reduce(
    (acc: any[], curr: ImageType, i: number) => {
      if (i % 2 === 0) acc.push([curr]);
      else acc[acc.length - 1].push(curr);
      return acc;
    },
    [],
  );

  return (
    <FullImagesWrapper>
      <ImageList
        variant="quilted"
        gap={5}
        sx={{
          gridAutoFlow: 'column',
          gridTemplateColumns: 'repeat(auto-fill,minmax(50%,1fr)) !important',
          gridAutoColumns: 'minmax(49%, 1fr)',
          overflowY: 'hidden',
          overflowX: 'auto',
          width: imageGroup.length === 1 ? '50%' : 'auto',
        }}
      >
        {window.innerWidth > BREAKPOINTS.xs && imageGroup.length > 2
          ? desktopImageGroups.map((group: ImageType[]) => (
              <DoubleImageListItemComponent group={group} key={group[0].link} />
            ))
          : imageGroup.map((item: ImageType) => (
              <ImageListItemComponent item={item} key={item.link} />
            ))}
      </ImageList>
    </FullImagesWrapper>
  );
};

export default ImageListComponent;
