import { useState } from 'react';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup';

import FormInputController from 'components/Inputs/FormInputController';
import { accountResetPasswordFormSchema } from 'helpers/validationSchemas';
import { VisibilityOffIcon, VisibilityOnIcon } from 'assets/svg';
import { AccountResetPasswordFormFieldsType } from 'types/AccountTypes';
import { AccountResetPasswordType } from 'eg_SFCC_FE_core/types';
import { StyledButton } from '../PersonalInfoForm/styles';
import { FormWrapper } from '../styles';
import ErrorTooltip from '../ErrorTooltip';

const AccountResetPasswordForm = ({
  isLoading,
  changePassword,
}: {
  isLoading: boolean;
  changePassword: (passwordData: AccountResetPasswordType) => void;
}) => {
  const [showOldPass, setShowOldPass] = useState<boolean>(false);
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [showNewPassConf, setNewShowPassConf] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountResetPasswordFormFieldsType>({
    resolver: yupResolver(accountResetPasswordFormSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const onSubmit = (data: AccountResetPasswordFormFieldsType) => {
    changePassword({
      currentPassword: data.oldPassword,
      password: data.newPassword,
    });
  };

  return (
    <FormWrapper
      id="update-password-form"
      style={{ maxWidth: 500 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInputController
        control={control}
        type={showOldPass ? 'text' : 'password'}
        name="oldPassword"
        label="Old Password*"
        error={!!errors.oldPassword}
        errorMessage={errors?.oldPassword?.message}
        InputProps={{
          endAdornment: errors?.oldPassword?.message ? (
            <ErrorTooltip
              title={errors?.oldPassword?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowOldPass(!showOldPass)}
              >
                {showOldPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowOldPass(!showOldPass)}
              >
                {showOldPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormInputController
        control={control}
        type={showNewPass ? 'text' : 'password'}
        name="newPassword"
        label="New Password*"
        error={!!errors.newPassword}
        errorMessage={errors?.newPassword?.message}
        InputProps={{
          endAdornment: errors?.newPassword?.message ? (
            <ErrorTooltip
              title={errors?.newPassword?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPass(!showNewPass)}
              >
                {showNewPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPass(!showNewPass)}
              >
                {showNewPass ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormInputController
        control={control}
        type={showNewPassConf ? 'text' : 'password'}
        name="confirmNewPassword"
        label="Confirm New Password*"
        error={!!errors.confirmNewPassword}
        errorMessage={errors?.confirmNewPassword?.message}
        InputProps={{
          endAdornment: errors?.confirmNewPassword?.message ? (
            <ErrorTooltip
              title={errors?.confirmNewPassword?.message}
              placement="right-end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setNewShowPassConf(!showNewPassConf)}
              >
                {showNewPassConf ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </ErrorTooltip>
          ) : (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setNewShowPassConf(!showNewPassConf)}
              >
                {showNewPassConf ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledButton
        width="289px"
        aria-label="save changes"
        isLoading={isLoading}
      >
        save changes
      </StyledButton>
    </FormWrapper>
  );
};

export default AccountResetPasswordForm;
