import { act } from 'react-dom/test-utils';
import { fireEvent } from '@testing-library/react';

import { renderWithRouter } from 'tests/utils';
import RegistrationForm from 'components/Forms/RegistrationForm/RegistrationForm';

const mockRegister = jest.fn();

const renderForm = () => {
  return renderWithRouter(
    <RegistrationForm
      registerCustomer={mockRegister}
      registerCustomerViaSocials={() => {}}
      authError={false}
      isLoading={false}
      ageRestriction
    />,
  );
};

describe('Registration form', () => {
  it('with empty fields', async () => {
    const { getByLabelText } = renderForm();

    await act(async () => {
      fireEvent.submit(getByLabelText('register'));
    });

    expect(mockRegister).not.toBeCalled();
  });

  it('with invalid email', async () => {
    const { getByLabelText, container } = renderForm();

    await act(async () => {
      fireEvent.change(getByLabelText('Email Address*'), {
        target: { value: 'invalid email' },
      });
      fireEvent.submit(getByLabelText('register'));
    });

    expect(container.innerHTML).toMatch('Not a valid e-mail address');
  });

  it('with invalid password', async () => {
    const { getByLabelText, container } = renderForm();

    await act(async () => {
      fireEvent.change(getByLabelText('Password*'), {
        target: { value: 'pass1' },
      });
      fireEvent.submit(getByLabelText('register'));
    });

    expect(container.innerHTML).toMatch(
      'Password must be 8 characters or more',
    );
  });
});
