import { ReactNode, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow } from '@mui/material';

import { Typography } from 'components';
import useDimensions from 'hooks/useDimensions';
import LoyaltyPointDetails from 'components/LoyaltyComponents/LoyaltyPointDetails';
import {
  IconWrapper,
  StyledArrowDownIcon,
  StyledArrowRightIcon,
  StyledTableCell,
} from './styles';

const ExpandableTableRow = ({
  children,
  orderNumber,
}: {
  children: ReactNode;
  orderNumber: string;
}) => {
  const { width } = useDimensions();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow>
        {children}
        <StyledTableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
          }}
        >
          {width > 650 && (
            <Typography color="accent" style={{ fontSize: 12 }}>
              Order Details
            </Typography>
          )}
          <IconWrapper onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <StyledArrowDownIcon /> : <StyledArrowRightIcon />}
          </IconWrapper>
        </StyledTableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <StyledTableCell colSpan={3}>
            <LoyaltyPointDetails orderNumber={orderNumber} />
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};

const BasicTable = ({
  headerCells,
  data,
}: {
  headerCells: string[];
  data: {
    date: string;
    description: string;
    points: string;
    orderDetails: any[];
    orderNumber: string;
  }[];
}) => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headerCells.map((header: string, index: number) => (
              <StyledTableCell key={index}>{header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <ExpandableTableRow key={index} orderNumber={row.orderNumber}>
              <StyledTableCell component="td" scope="row">
                {row.date}
              </StyledTableCell>
              <StyledTableCell component="td" scope="row">
                {row.description}
              </StyledTableCell>
              <StyledTableCell component="td" scope="row">
                {row.points}
              </StyledTableCell>
            </ExpandableTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
