import { memo, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import ROUTES from 'router/Routes';
import { CustomNavLink, AnimatedWrapper } from 'components';
import useDimensions from 'hooks/useDimensions';
import { BREAKPOINTS } from 'styles';
import { MenuWrapper, MenuTitle, MenuLink } from './styles';

export const profileLinkList = [
  {
    link: 'Personal Information',
    linkTo: `${ROUTES.ACCOUNT_PERSONAL_INFO}`,
  },
  {
    link: 'Reset Password',
    linkTo: `${ROUTES.ACCOUNT_RESET_PASSWORD}`,
  },
  {
    link: 'Orders',
    linkTo: `${ROUTES.ACCOUNT_MY_ORDERS}`,
  },
  {
    link: 'Saved Addresses',
    linkTo: `${ROUTES.ACCOUNT_ADDRESSES}`,
  },
  {
    link: 'Saved Payments',
    linkTo: `${ROUTES.ACCOUNT_PAYMENTS}`,
  },
  {
    link: 'Saved Searches',
    linkTo: `${ROUTES.ACCOUNT_SAVED_SEARCHES}`,
  },
];

function AccountMenu({ firstName }: { firstName: string }) {
  const { pathname } = useLocation();
  const { width } = useDimensions();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 0);
  }, [pathname]);

  return (
    <MenuWrapper>
      <MenuTitle>{firstName ? ` ${firstName}` : ''}</MenuTitle>
      <div>
        {profileLinkList.map(({ link, linkTo }) => {
          return (
            <div key={link}>
              <MenuLink active={pathname.includes(linkTo)}>
                <CustomNavLink to={linkTo}>{link}</CustomNavLink>
              </MenuLink>

              {width < BREAKPOINTS.s && (
                <AnimatedWrapper animationKey={visible.toString()}>
                  {visible && pathname.includes(linkTo) ? <Outlet /> : <div />}
                </AnimatedWrapper>
              )}
            </div>
          );
        })}
      </div>
    </MenuWrapper>
  );
}

export default memo(AccountMenu);
