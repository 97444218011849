import { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { find, pick } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Typography } from 'components';
import AddressSelect from 'components/Dropdowns/Selects/AddressSelect';
import CheckoutAddressForm from 'components/CheckoutComponents/CheckoutAddressForm';
import { AddressFormFieldsType } from 'types/AccountTypes';
import {
  getBasket,
  getCustomerData,
  getIsLoggedIn,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { addressFormSchema } from 'helpers/validationSchemas';
import { setDefaultAddressFirst } from 'helpers/formatters';
import ShippingAddressAccordion from 'components/Accordion/ShippingAddressAccordion';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { handleSuggestedAddress } from 'helpers/customerDataUtils';
import { OutletPage } from '../styles';
import { useCheckoutNextSteps } from '../CheckoutPage';

const ShippingDetails = () => {
  const dispatch = useAppDispatch();
  const { addresses } = useSelector(getCustomerData);
  const { result } = useSelector(getBasket);
  const { isSubmitShipping, setIsShippingStepReady, onFormSubmitError } =
    useCheckoutNextSteps();
  const [selectedAddress, setSelectedAddress] =
    useState<CustomerAddressType | null>(find(addresses, { preferred: true }));
  const [isErrorCleared, setIsErrorCleared] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isAddressSaved, setIsAddressSaved] = useState<boolean>(false);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [isDiffAddressUsed, setIsDiffAddressUsed] = useState<boolean>(
    !isLoggedIn || !addresses?.length,
  );
  const shippingAddress = result?.shipments[0]?.shippingAddress;
  const [suggestedAddress, setSuggestedAddress] =
    useState<AddressFormFieldsType>();
  const [addressValues] = useState<AddressFormFieldsType>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    countryCode: '',
    stateCode: '',
    city: '',
    phone: '',
    postalCode: '',
    ...shippingAddress,
  });
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const addressMethods = useForm<AddressFormFieldsType>({
    resolver: isDiffAddressUsed ? yupResolver(addressFormSchema) : undefined,
    defaultValues: addressValues,
  });

  const onError = useCallback(() => {
    onFormSubmitError();
    setIsErrorCleared(false);
  }, []);

  const onSuccess = () => {
    // trackCheckoutEvent('add_shipping_info');
    setIsShippingStepReady(true);
  };

  const addNewAddressAsShippingAddress: SubmitHandler<
    AddressFormFieldsType
  > = async (data: AddressFormFieldsType) => {
    if (isAddressSaved) {
      try {
        await dispatch(
          AsyncThunks.createCustomerAddress({
            addressId: uuid(),
            ...data,
          }),
        ).unwrap();
      } catch (error: any) {
        onFormSubmitError();
        handleSuggestedAddress(error, setSuggestedAddress);
        throw new Error(error?.title);
      }
    }

    try {
      await dispatch(
        AsyncThunks.addShippingAddressToBasketShipment(data),
      ).unwrap();
      onSuccess();
    } catch (error: any) {
      onFormSubmitError();
      handleSuggestedAddress(error, setSuggestedAddress);
    }
  };

  const addExistingAddressAsShippingAddress = async () => {
    const addressToSend: AddressFormFieldsType = pick(selectedAddress, [
      'address1',
      'address2',
      'countryCode',
      'lastName',
      'firstName',
      'stateCode',
      'city',
      'postalCode',
      'phone',
    ]);

    const { payload } = await dispatch(
      AsyncThunks.addShippingAddressToBasketShipment(addressToSend),
    );

    if (payload?.addressError) {
      setErrorMessage(payload.detail);
      throw new Error('Something went wrong');
    }
  };

  const triggerShippingActions = async () => {
    try {
      if (!isDiffAddressUsed) {
        await addExistingAddressAsShippingAddress();
        onSuccess();
      } else {
        submitButtonRef?.current?.click();
      }
    } catch (e: any) {
      // console.log(e);
    }
  };

  useEffect(() => {
    if (isSubmitShipping) {
      if (isLoggedIn) {
        triggerShippingActions();
      } else {
        submitButtonRef?.current?.click();
      }
    }
  }, [isSubmitShipping]);

  return (
    <OutletPage>
      {addresses?.length > 0 && (
        <>
          {errorMessage && (
            <Typography color="accent">{errorMessage}</Typography>
          )}
          <AddressSelect
            addresses={
              addresses ? setDefaultAddressFirst(addresses) || addresses : []
            }
            currentMenuItem={selectedAddress}
            setCurrentMenuItem={setSelectedAddress}
          />
        </>
      )}
      {isLoggedIn && addresses?.length ? (
        <ShippingAddressAccordion setIsDiffAddressUsed={setIsDiffAddressUsed}>
          <CheckoutAddressForm
            addressValues={addressValues}
            handleError={onError}
            isErrorCleared={isErrorCleared}
            addressMethods={addressMethods}
            onAddressSubmit={addNewAddressAsShippingAddress}
            suggestedAddress={suggestedAddress}
            setSuggestedAddress={setSuggestedAddress}
            isAddressSaved={isAddressSaved}
            setIsAddressSaved={setIsAddressSaved}
          />
        </ShippingAddressAccordion>
      ) : (
        <CheckoutAddressForm
          addressValues={addressValues}
          handleError={onError}
          isErrorCleared={isErrorCleared}
          addressMethods={addressMethods}
          onAddressSubmit={addNewAddressAsShippingAddress}
          suggestedAddress={suggestedAddress}
          setSuggestedAddress={setSuggestedAddress}
          isAddressSaved={isAddressSaved}
          setIsAddressSaved={setIsAddressSaved}
        />
      )}
      <button
        style={{ display: 'none' }}
        type="submit"
        form="address-form"
        ref={submitButtonRef}
      />
    </OutletPage>
  );
};

export default ShippingDetails;
