import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { pdfjs } from 'react-pdf';

import { CustomNavLink, Typography } from 'components';
import ROUTES from 'router/Routes';
import {
  getOrderDetails,
  getOrderError,
  getOrderLastReturnRequestResponse,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  ReturnSummary,
  ReturnToAddress,
  ReturnToCard,
} from 'components/OrdersComponents/ReturnsComponents/ReturnInfoBlocks';
import { ProductItemsType, ReturnPlisInfo } from 'types/OrdersTypes';
import OrderCardItem from 'components/OrdersComponents/OrderCardItem';
import useDimensions from 'hooks/useDimensions';
import {
  AddInstructionsList,
  AddInstructionsTitle,
  BlockTitle,
  ConfirmReturnInstructionsSubtitle,
  ConfirmReturnSubtitle,
  InstructionTitlesWrapper,
  PdfWrapper,
  ReturnConfirmInfoBlocks,
  ReturnConfirmSummaryWrapper,
  ReturnConfirmToAddressWrapper,
  ReturnConfirmToCardWrapper,
  ReturnExperience,
  RightBlockWrapper,
  StyledColoredButton,
} from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const errorStatus: { [key: string]: string } = {
  no_pli_input: 'No item',
  no_input_reason: 'No reason',
  no_customer: 'No customer',
  not_same_customer: 'Wrong customer',
  order_has_no_linked_pli: 'Order has no linked item',
  zencraft_service_error: 'Service error',
  mkp_service_error: 'Service error',
  unexpected_error: 'Unexpected error',
  invalid_requested_quantity: 'Invalid quantity',
  order_line_item_already_refunded: 'The item has already been refunded',
  order_line_item_already_returned: 'The item has already been returned',
  item_returned_once: 'The item has already been returned',
};

const OrderReturnConfirmPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useAppDispatch();
  const order = useSelector(getOrderDetails);
  const error = useSelector(getOrderError);
  const lastReturnRequestResponse = useSelector(
    getOrderLastReturnRequestResponse,
  );
  const { orderNo } = useParams();
  const { width } = useDimensions();
  const shippingAddress = order?.shipments[0].shippingAddress;
  const paymentInstrument =
    order?.paymentInstruments[(order?.paymentInstruments.length || 1) - 1];
  const zencraftLabel =
    lastReturnRequestResponse.zencraft?.result.shipment.packages[0].label;

  const fetchOrder = async () => {
    if (!orderNo) return;
    await dispatch(AsyncThunks.getCustomerOrderById(orderNo));
  };

  useEffect(() => {
    // @ts-ignore
    const statusCode = error?.statusCode;

    if (statusCode) {
      setErrorMessage(errorStatus[statusCode]);
    } else {
      fetchOrder();
    }
  }, [error]);

  useEffect(() => {
    const handlePopState = () => window.history.go(1);

    window.history.pushState(null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <RightBlockWrapper id="section-to-print">
      <BlockTitle>Returns</BlockTitle>
      {!errorMessage && (
        <div>
          <ConfirmReturnSubtitle>RETURN SUMMARY</ConfirmReturnSubtitle>
          <ReturnConfirmToAddressWrapper>
            <ReturnToAddress shippingAddress={shippingAddress} />
          </ReturnConfirmToAddressWrapper>
          {lastReturnRequestResponse.c_plis_info?.map((pli: ReturnPlisInfo) => {
            const item = order?.productItems.find(
              (productItem: ProductItemsType) => productItem.itemId === pli.id,
            );

            if (item) {
              return (
                <OrderCardItem
                  productItem={item}
                  key={item.itemId}
                  withStatus={false}
                />
              );
            }
          })}
          <ReturnConfirmInfoBlocks>
            <ReturnConfirmToCardWrapper>
              <ReturnToCard
                cardType={paymentInstrument?.paymentCard.cardType || ''}
                numberLastDigits={
                  paymentInstrument?.paymentCard.numberLastDigits || ''
                }
              />
            </ReturnConfirmToCardWrapper>
            <ReturnConfirmSummaryWrapper>
              <ReturnSummary order={order} onlyTotal />
            </ReturnConfirmSummaryWrapper>
          </ReturnConfirmInfoBlocks>

          <InstructionTitlesWrapper>
            <ConfirmReturnInstructionsSubtitle>
              YOUR RETURN INSTRUCTIONS
            </ConfirmReturnInstructionsSubtitle>

            <ReturnExperience>
              Tell us about your return experience.
            </ReturnExperience>
          </InstructionTitlesWrapper>

          <Typography type="bodyHelv">
            Securely pack and return your items to Empower Global. We&apos;ve
            emailed these details to you. If you would like to change your
            return option, please cancel and start a new return.
          </Typography>

          <AddInstructionsTitle>
            Additional instructions for mailing your package
          </AddInstructionsTitle>
          <AddInstructionsList>
            <li>
              Ensure that there are no other tracking labels attached to your
              package. If you are shipping an non-hazardous item, completely
              remove or cover any hazardous materials markings.
            </li>
            <li>
              Affix the mailing label squarely onto the address side of the
              parcel, covering up any previous delivery address and barcode
              without overlapping any adjacent side.
            </li>
            <li>
              Take this package to a UPS location. Store hours are subject to
              change due to COVID-19 precautions, please confirm opening hours
              before proceeding. To find your closest UPS location, visit the
              UPS Drop Off Locator or go to www.ups.com.
            </li>
            <li>
              Package and label your items. An $8.99 return shipping fee will be
              deducted from your refunded amount.
            </li>
          </AddInstructionsList>
          <StyledColoredButton
            aria-label="print label and instructions"
            onClick={() => window.print()}
          >
            print label & Instructions
          </StyledColoredButton>

          <PdfWrapper>
            <Document
              file={`data:application/pdf;base64,${zencraftLabel}`}
              rotate={width > 600 ? 0 : 90}
            >
              <Page pageNumber={1} renderAnnotationLayer={false} />
            </Document>
          </PdfWrapper>
        </div>
      )}

      {errorMessage && (
        <>
          <Typography
            color="accent"
            type="subtitle2Helv"
            style={{ marginTop: 20 }}
          >
            {errorMessage}
          </Typography>
          <Typography color="secondary" style={{ margin: '10px 0' }}>
            Something unexpected happened, please try again later.
          </Typography>
          <CustomNavLink to={ROUTES.ACCOUNT_MY_ORDERS}>
            <StyledColoredButton aria-label="go back">
              go back
            </StyledColoredButton>
          </CustomNavLink>
        </>
      )}
    </RightBlockWrapper>
  );
};

export default OrderReturnConfirmPage;
