import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Typography } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { generateCSRFToken, postSFForm } from 'eg_SFCC_FE_core/axios/api';
import { talkToSupportFormSchema } from 'helpers/validationSchemas';
import { isProd } from 'appConstants';
import { COLORS, FONTS } from 'styles';
import { FormWrapper } from '../styles';
import { StyledColoredButton } from './styles';

const webToCaseStgVars = {
  orgId: '00D530000008gf1',
  retURLValue: 'https://stg.shopwitheg.com/%22',
};

const webToCaseProdVars = {
  orgId: '00D5Y00000264i7',
  retURLValue: 'http://www.empowerglobal.shop',
};

type FormValues = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  description: string;
};

const WebToCaseForm = ({
  recaptchaToken,
}: {
  recaptchaToken: string | undefined;
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [recaptchaMessage, setRecaptchaMessageMessage] = useState<string>();
  const [isLoading, setLoader] = useState<boolean>(false);
  const webToCaseVars = isProd ? webToCaseProdVars : webToCaseStgVars;

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    description: '',
  };
  const methods = useForm<FormValues>({
    resolver: yupResolver(talkToSupportFormSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (
    data: FormValues,
    e: any,
  ) => {
    setLoader(true);

    try {
      if (!recaptchaToken) {
        setRecaptchaMessageMessage('Verify recaptcha');
        return;
      }
      setRecaptchaMessageMessage('');

      const { token } = await generateCSRFToken();

      const response = await postSFForm(
        'Case',
        {
          Description: data.description,
          Subject: data.subject,
          SuppliedEmail: data.email,
          SuppliedName: data.name,
          Phone: data.phone,
        },
        recaptchaToken,
        token,
      );

      if (
        response.sendForm.statusCode === 200 &&
        response.sendForm.status === 'success'
      ) {
        setErrorMessage('The form was submitted');
        methods.reset();
      }
    } catch (err: any) {
      setErrorMessage(
        'For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience.',
      );
    } finally {
      setLoader(false);
      window.googleRecaptchaToken = '';
    }

    e.target.reset();
  };

  const onError = () => {};

  return (
    <FormWrapper
      id="web-to-case-form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
      style={{ gap: 15 }}
    >
      <input
        type="hidden"
        name="captcha_settings"
        value={`{"keyname":"Web2CaseForm","fallback":"true","orgId":"${webToCaseVars.orgId}","ts":""}`}
      />
      <input type="hidden" name="orgid" value={webToCaseVars.orgId} />
      <input type="hidden" name="retURL" value={webToCaseVars.retURLValue} />
      <FormInputController
        control={control}
        name="name"
        label="Contact Name*"
        error={!!errors.name}
        errorMessage={errors?.name && errors.name.message}
      />
      <FormInputController
        control={control}
        name="email"
        label="Email*"
        error={!!errors.email}
        errorMessage={errors?.email && errors.email.message}
      />
      <FormInputController
        control={control}
        name="phone"
        label="Phone Number*"
        error={!!errors.phone}
        errorMessage={errors?.phone && errors.phone.message}
      />
      <FormInputController
        control={control}
        name="subject"
        label="Subject*"
        error={!!errors.subject}
        errorMessage={errors?.subject && errors.subject.message}
      />
      <FormInputController
        control={control}
        name="description"
        label="Description*"
        error={!!errors.description}
        errorMessage={errors?.description && errors.description.message}
      />
      {recaptchaMessage ? (
        <Typography style={{ fontFamily: FONTS.TTNorms.medium, fontSize: 13 }}>
          {recaptchaMessage}
        </Typography>
      ) : null}
      <StyledColoredButton
        type="submit"
        form="web-to-case-form"
        aria-label="submit"
        isLoading={isLoading}
      >
        Submit
      </StyledColoredButton>
      {errorMessage ? (
        <Typography style={{ color: COLORS.accent }}>{errorMessage}</Typography>
      ) : null}
    </FormWrapper>
  );
};
export default WebToCaseForm;
