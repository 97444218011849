import { useSelector } from 'react-redux';
import { isEmpty, sortBy, chain } from 'lodash';

import { Typography } from 'components';
import CircularProgress from 'components/CircularProgress/CircularProgress';
import BasicTabs from 'components/Tabs/BasicTabs';
import useDimensions from 'hooks/useDimensions';
import { getCustomerData } from 'eg_SFCC_FE_core/store/selectors';
import { LoyaltyTierGroupType, LoyaltyTierType } from 'types/AccountTypes';
import { formatTierName } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { COLORS, FONTS } from 'styles';
import {
  LoyaltyPoints,
  TierCardWrapper,
  UserLoyaltyInfoTabsWrapper,
  UserLoyaltyTiersWrapper,
  TierInfoWrapper,
  StyledLink,
} from './styles';

const tiersInfo: any = {
  'eg elite member': [
    'Access to the EG Elite Member Community',
    'EG Welcome Kit',
  ],
  'silver elite': [
    'Birthday Gift: 1 Free Shipping Credit',
    'Early access to exclusive drops',
    'Member-only event invitations',
    'Sweepstakes invitation for tickets to events sponsored by Ciroc/DeLeón',
  ],
  'gold elite': [
    'Early access to 12 Days of Deals',
    'A chance to win 2 passes to Afrochella’s “Fashion Night Out”',
    'Free shipping on 3 orders',
  ],
  'platinum elite': [
    '“New Year, New You” wellness gift box',
    'Special invitations to Revolt TV, Ciroc, and DeLeón events',
    'Free shipping credit on 5 orders',
  ],
};

const TierCard = ({
  title,
  accent,
  linkTitle,
}: {
  title: string;
  accent?: boolean;
  linkTitle: string;
}) => {
  return (
    <TierCardWrapper accent={accent}>
      <Typography
        type="subtitle2"
        style={{
          fontSize: 27,
          lineHeight: '32px',
          marginBottom: 15,
          color: `${accent ? COLORS.accent : COLORS.black}`,
          textTransform: 'capitalize',
        }}
      >
        {title}
      </Typography>
      <StyledLink to={ROUTES.LOYALTY_PROGRAM}>{linkTitle}</StyledLink>
    </TierCardWrapper>
  );
};

const TierInfo = ({ tierName }: { tierName: string }) => {
  const tierInfo = tiersInfo[tierName.toLowerCase()];

  return (
    <TierInfoWrapper>
      <Typography type="subtitle3" style={{ fontSize: 30, marginBottom: 15 }}>
        {tierName}
      </Typography>
      <ul style={{ listStyle: 'disc', marginLeft: 25 }}>
        {tierInfo?.map((tier: string, index: number) => (
          <li
            key={index}
            style={{ fontSize: 18, fontFamily: FONTS.TTNorms.regular }}
          >
            {tier}
          </li>
        ))}
      </ul>
    </TierInfoWrapper>
  );
};

const LoyaltyOverview = () => {
  const { width } = useDimensions();
  const customerData = useSelector(getCustomerData);
  const loyaltyInfo = customerData?.c_loyaltyInfo;
  const membersInfo = loyaltyInfo?.consumersInfo?.[0].membersInfo;
  const programsInfo = loyaltyInfo?.consumersInfo?.[0].programsInfo;
  const consumerPoints = membersInfo ? membersInfo[0]?.consumerPoints : 0;
  const currentTierName = membersInfo ? membersInfo[0]?.currentTierName : '';
  const loyaltyTiers = programsInfo
    ? chain(programsInfo)
        .find({ programId: membersInfo[0].programId })
        ?.value()
        .loyaltyTierGroups.find(
          (tier: LoyaltyTierGroupType) => tier.loyaltyGroupName === 'MVP',
        )?.loyaltyTiers
    : [];

  const sortedLoyaltyTiers = sortBy(loyaltyTiers, 'tierPoints');

  const tabs = sortedLoyaltyTiers.map((tier: LoyaltyTierType) => {
    const tierName = tier.tierName.toLowerCase().startsWith('eg')
      ? tier.tierName
      : `${tier.tierName} Elite`;
    return {
      id: tier.tierId,
      name: tierName,
      content: <TierInfo tierName={tierName} />,
    };
  });

  const currentTier: LoyaltyTierType = sortedLoyaltyTiers.find(
    (tier: LoyaltyTierType) => tier.tierName === currentTierName,
  );

  const currentTierIndex = sortedLoyaltyTiers.findIndex(
    (tier: LoyaltyTierType) => tier.tierId === currentTier.tierId,
  );

  const nextTier: LoyaltyTierType = sortedLoyaltyTiers[currentTierIndex + 1];

  const circularValue =
    isEmpty(sortedLoyaltyTiers) || !consumerPoints
      ? 0
      : (consumerPoints * 100) /
        sortedLoyaltyTiers[sortedLoyaltyTiers.length - 1].tierPoints;

  return (
    <>
      <UserLoyaltyInfoTabsWrapper>
        <div style={{ maxWidth: 285 }}>
          <CircularProgress value={circularValue}>
            <LoyaltyPoints>
              {consumerPoints ? Math.round(consumerPoints) : 0}
            </LoyaltyPoints>
            <Typography
              color="accent"
              style={{
                fontFamily: FONTS.TTNorms.bold,
                fontSize: 16,
              }}
            >
              {formatTierName(currentTierName, 'Elite')}
            </Typography>
          </CircularProgress>
        </div>
        <UserLoyaltyTiersWrapper>
          {currentTier ? (
            <TierCard
              title={`You are ${formatTierName(
                currentTier?.tierName || '',
                'Elite',
              )}`}
              linkTitle="See Your Benefits"
            />
          ) : null}
          {nextTier ? (
            <TierCard
              title={`Next Up: ${nextTier?.tierName || ''} Elite`}
              linkTitle="Previous Benefits"
              accent
            />
          ) : (
            <TierCardWrapper>
              <Typography type="subtitle2">
                Enjoy your {currentTierName} status.
              </Typography>
            </TierCardWrapper>
          )}
        </UserLoyaltyTiersWrapper>
      </UserLoyaltyInfoTabsWrapper>
      <BasicTabs
        tabs={tabs}
        activeTabIndex={currentTierIndex}
        orientation={width < 500 ? 'vertical' : 'horizontal'}
        variant="scrollable"
        tabStyles={{
          '& .MuiButtonBase-root': {
            border: '1px solid #CCCCCC',
            fontFamily: FONTS.TTNorms.medium,
            fontSize: 15,
            color: COLORS.black,
            maxWidth: width < 500 ? '100%' : 360,
          },
          '& .MuiButtonBase-root.Mui-selected': {
            border: `2px solid ${COLORS.accent}`,
            color: COLORS.accent,
          },
        }}
      />
    </>
  );
};

export default LoyaltyOverview;
