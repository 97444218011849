import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'components';
import AccountResetPasswordForm from 'components/Forms/ResetPasswordForms/AccountResetPasswordForm';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { AccountResetPasswordType } from 'eg_SFCC_FE_core/types';
import { customerActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerSlice';
import {
  getResetPasswordResult,
  getCustomerLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { COLORS } from 'styles';
import { BlockSubtitle, BlockTitle, RightBlockWrapper } from './styles';

const AccountResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const result = useSelector(getResetPasswordResult);
  const isLoading = useSelector(getCustomerLoader);

  const changePassword = async (passwordData: AccountResetPasswordType) => {
    await dispatch(AsyncThunks.resetPassword(passwordData));
  };

  useEffect(() => {
    dispatch(customerActions.clearResetPasswordResult());
  }, []);

  return (
    <RightBlockWrapper>
      <BlockTitle>Reset Password</BlockTitle>
      <BlockSubtitle>Edit your preferences below.</BlockSubtitle>
      {result && (
        <div style={{ height: 18 }}>
          <Typography style={{ color: COLORS.accent, fontSize: 12 }}>
            {result}
          </Typography>
        </div>
      )}
      <AccountResetPasswordForm
        isLoading={isLoading}
        changePassword={changePassword}
      />
    </RightBlockWrapper>
  );
};

export default AccountResetPasswordPage;
