import { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  ReturnsExchanges,
  ShippingDelivery,
} from 'components/InfoPagesComponents/Templates';
import InfoPageMenu from 'components/InfoPagesComponents/InfoPageMenu';
import InfoPageText from 'components/InfoPagesComponents/InfoPageText';
import InfoPageWrapper from 'components/InfoPagesComponents/InfoPageWrapper';
import { AnimatedWrapper } from 'components';

export type MenuItemType = {
  title: string;
  content: ReactNode;
};

const shippingMenu: MenuItemType[] = [
  {
    title: 'SHIPPING & DELIVERY',
    content: <ShippingDelivery />,
  },
  {
    title: 'RETURNS & EXCHANGES',
    content: <ReturnsExchanges />,
  },
];

const ShippingReturnsPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(shippingMenu[0]);

  return (
    <InfoPageWrapper title="Shipping & Returns">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shipping & Returns</title>
        <meta name="description" content="Shipping & Returns" />
      </Helmet>

      <InfoPageMenu
        menu={shippingMenu}
        currentMenuItem={currentMenuItem}
        setCurrentMenuItem={setCurrentMenuItem}
      />
      <AnimatedWrapper animationKey={currentMenuItem.title}>
        <InfoPageText title="shipping & returns">
          {currentMenuItem.content}
        </InfoPageText>
      </AnimatedWrapper>
    </InfoPageWrapper>
  );
};

export default ShippingReturnsPage;
