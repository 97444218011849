/* eslint-disable import/no-cycle */
import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import {
  ErrorResponse,
  RegisterParamsType,
  AuthenticationParamsType,
  AccountResetPasswordType,
} from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import {
  authenticateCustomer,
  getLoginTokens,
  silentTokenRefresh,
} from 'eg_SFCC_FE_core/axios/api';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  updateLocalTokens,
  generateCodeVerifier,
  isUserEligableToRegister,
} from 'eg_SFCC_FE_core/axios/helpers';
import { basketActions } from 'eg_SFCC_FE_core/store/reducers/slices/basketSlice';
import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import { CHANNEL_ID, COOKIES_KEYS } from 'eg_SFCC_FE_core/axios/apiConstants';
import { customerProductListsActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerProductLists';
import { orderActions } from 'eg_SFCC_FE_core/store/reducers/slices/orderSlice';
import { BasketProductItemType } from 'types/ProductsTypes';
import { GOOGLE_REDIRECT_URI, LOCAL_STORAGE_KEYS } from 'appConstants';

export const loginCustomerThunk: AsyncThunkPayloadCreator<
  any,
  { authParams: AuthenticationParamsType; afterRegistration?: boolean },
  { rejectValue: ErrorResponse }
> = async (
  { authParams, afterRegistration },
  { rejectWithValue, dispatch, getState },
) => {
  const verifier = generateCodeVerifier();
  let code;

  try {
    await authenticateCustomer(authParams, verifier);
  } catch (error: any) {
    const urlParams = new URLSearchParams(error.response.data.queryString);
    const isError = !!urlParams.get('error');

    if (error.response.status === 303 && !isError) {
      code = urlParams.get('code');
    } else {
      return rejectWithValue(error.response.data);
    }
  }

  try {
    const state: any = getState();
    const guestProductsToAddToBasket = state?.basketReducer.productItems.map(
      (productItem: BasketProductItemType) => {
        return {
          productId: productItem.productId,
          quantity: productItem.quantity,
        };
      },
    );

    const response = code && (await getLoginTokens(code, verifier));
    updateLocalTokens(response);

    await dispatch(AsyncThunks.getCustomerProductLists(response.customer_id));
    await dispatch(
      AsyncThunks.getOrCreateCustomerBaskets(response.customer_id),
    );
    await dispatch(AsyncThunks.getCustomer(response.customer_id));

    if (guestProductsToAddToBasket && !afterRegistration) {
      await dispatch(AsyncThunks.addItemToBasket(guestProductsToAddToBasket));
    }

    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const loginCustomerViaGoogleThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (code, { rejectWithValue, dispatch, getState }) => {
  const codeVerifier = localStorage.getItem(LOCAL_STORAGE_KEYS.codeVerifier);

  try {
    const state: any = getState();
    const guestProductsToAddToBasket = state?.basketReducer.productItems.map(
      (productItem: BasketProductItemType) => {
        return {
          productId: productItem.productId,
          quantity: productItem.quantity,
        };
      },
    );

    const response =
      codeVerifier &&
      (await getLoginTokens(code, codeVerifier, GOOGLE_REDIRECT_URI));

    updateLocalTokens(response);

    const params = {
      channel_id: CHANNEL_ID,
    };

    await axiosInstance.get(endpoints.shopper.userInfo, {
      params,
    });

    const productListResponse = await dispatch(
      AsyncThunks.getCustomerProductLists(response.customer_id),
    );

    // @ts-ignore
    if (productListResponse.error) {
      return rejectWithValue(productListResponse.payload);
    }

    const basketResponse = await dispatch(
      AsyncThunks.getOrCreateCustomerBaskets(response.customer_id),
    );

    // @ts-ignore
    if (basketResponse.error) {
      return rejectWithValue(basketResponse.payload);
    }

    const customerResponse = await dispatch(
      AsyncThunks.getCustomer(response.customer_id),
    );

    // @ts-ignore
    if (customerResponse.error) {
      return rejectWithValue(customerResponse.payload);
    }

    if (guestProductsToAddToBasket) {
      await dispatch(AsyncThunks.addItemToBasket(guestProductsToAddToBasket));
    }

    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  } finally {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.codeVerifier);
  }
};

export const registerCustomerThunk: AsyncThunkPayloadCreator<
  any,
  RegisterParamsType,
  { rejectValue: ErrorResponse }
> = async (registerParams, { rejectWithValue }) => {
  try {
    if (!isUserEligableToRegister(registerParams.customer.birthday)) {
      throw new Error();
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }

  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.post(
      endpoints.customer.customer,
      registerParams,
      { params },
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      ...error.response.data,
      status_code: error.response.status,
    });
  }
};

export const logoutCustomerThunk: AsyncThunkPayloadCreator<{
  rejectValue: ErrorResponse;
}> = async (_, { rejectWithValue, dispatch }) => {
  try {
    const accessToken = await Cookies.get(COOKIES_KEYS.accessToken);
    if (!accessToken) {
      await silentTokenRefresh();
    }
    const refreshToken = await Cookies.get(COOKIES_KEYS.refreshToken);

    const params = {
      client_id: process.env.REACT_APP_PUBLIC_CLIENT_ID,
      refresh_token: refreshToken,
      channel_id: CHANNEL_ID,
    };

    const response = await axiosInstance.get(endpoints.shopper.logoutCustomer, {
      params,
    });

    await dispatch(basketActions.reset());
    await dispatch(orderActions.reset());
    await dispatch(customerProductListsActions.reset());

    await Cookies.remove(COOKIES_KEYS.refreshToken);
    await Cookies.remove(COOKIES_KEYS.accessToken);
    await Cookies.remove(COOKIES_KEYS.tokenType);

    await dispatch(AsyncThunks.createBasket());

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getCustomerThunk: AsyncThunkPayloadCreator<
  any,
  string | null,
  {
    rejectValue: ErrorResponse;
    state: RootState;
  }
> = async (customerId = null, { rejectWithValue, getState }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    if (!customerId) {
      const state = getState();
      customerId = state.customerReducer.customerId;
    }

    const response = await axiosInstance.get(
      `${endpoints.customer.customer}/${customerId}`,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateCustomerThunk: AsyncThunkPayloadCreator<
  any,
  any,
  {
    rejectValue: ErrorResponse;
    state: RootState;
  }
> = async (customerData, { rejectWithValue, getState }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const state = getState();
    const { customerId } = state.customerReducer;

    await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      customerData,
      { params },
    );

    const response = await axiosInstance.get(
      `${endpoints.customer.customer}/${customerId}`,
      { params },
    );

    return { data: response.data, emailWasUpdated: !!customerData.email };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const resetPasswordThunk: AsyncThunkPayloadCreator<
  any,
  AccountResetPasswordType,
  { rejectValue: ErrorResponse; state: RootState }
> = async (passwordData, { rejectWithValue, getState }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const state = getState();
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.put(
      endpoints.customer.customerPassword(customerId),
      passwordData,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateFavoriteBrandsThunk: AsyncThunkPayloadCreator<
  any,
  {
    updatesType: 'c_followSeller' | 'c_unfollowSeller';
    brandId: string;
  },
  {
    rejectValue: ErrorResponse;
    state: RootState;
  }
> = async ({ updatesType, brandId }, { rejectWithValue, getState }) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const state = getState();
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      { [updatesType]: brandId },
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const addCustomerPaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  {
    c_action: 'save_card';
    c_card_info: string;
  },
  { rejectValue: ErrorResponse; state: RootState }
> = async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const removeCustomerPaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  {
    c_action: 'delete_card';
    c_card_info: string;
  },
  { rejectValue: ErrorResponse; state: RootState }
> = async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return data.c_card_info;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const setDefaultPaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  {
    c_action: 'set_default_card';
    c_card_info: string;
  },
  { rejectValue: ErrorResponse; state: RootState }
> = async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
