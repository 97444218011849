import Slider from 'react-slick';
import { heroSliderData } from 'data/homeData';

import {
  Image,
  SlideTextContainer,
  SlideContainer,
  SlideHeading,
  SlideCopy,
  SlideLink,
} from './styles';

const HomepageHeroSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...sliderSettings}>
      {heroSliderData.map((slide) => (
        <SlideContainer>
          <Image
            component="img"
            sx={{
              content: {
                xxxs: `url(${require(`../../../${slide.imageMobile}`)})`,
                xs: `url(${require(`../../../${slide.imageDesktop}`)})`,
              },
            }}
          />
          <SlideTextContainer isLeft={slide.isLeft}>
            {slide.heading && (
              <SlideHeading isLarge={slide.isHeadingLarge}>
                {slide.heading}
              </SlideHeading>
            )}
            <SlideCopy isDark={slide.isDark}>{slide.copy}</SlideCopy>
            <SlideLink to={slide.link} isDark={slide.isDark}>
              shop now
            </SlideLink>
          </SlideTextContainer>
        </SlideContainer>
      ))}
    </Slider>
  );
};

export default HomepageHeroSlider;
