import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'styles';
import { ContentWrapper } from 'styles/common';

export const Wrapper = styled(ContentWrapper)`
  flex-direction: column;
  padding: 48px 10% 70px;
  max-width: 1670px;
  border-bottom: 1px solid ${COLORS.border};

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 17px 14px;
  }
`;

export const OverviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 48px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: 15px;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImagesWrapper = styled.div`
  width: 60%;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 100%;
  }
`;

export const OverviewBlockWrapper = styled.div`
  width: 100%;
  max-width: 450px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-width: 100%;
  }
`;

export const ProductOverviewWrapper = styled.div<{
  position: 'fixed' | 'absolute' | 'static';
}>`
  position: ${({ position }) => position};
  bottom: ${({ position }) => (position === 'absolute' ? 0 : 'auto')};
  width: 100%;
  max-width: 450px;
  padding-left: 40px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-left: 0;
    max-width: 100%;
  }
`;

export const ReviewsSection = styled.div`
  padding: 60px 10% 140px;
  max-width: 1670px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 20px 0 60px;
  }
`;
