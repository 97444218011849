import { Typography, CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import { FONTS } from 'styles';
import { VacancyType } from 'types/VacancyTypes';
import ShareIcons from '../ShareIcons/ShareIcons';
import { JobTitle, Location, Title, VacancyCard } from './styles';

const OpenPositions = ({ vacancies }: { vacancies: VacancyType[] }) => {
  return (
    <div>
      <Title>open positions</Title>
      {vacancies.map((vacancy) => (
        <VacancyCard key={vacancy.id}>
          <CustomNavLink to={`${ROUTES.CAREERS}/${vacancy.id}`}>
            <JobTitle>{vacancy.position}</JobTitle>
            <Location>
              {vacancy.city
                ? `${vacancy.city} (${vacancy.type})`
                : vacancy.type}
            </Location>
            <Typography type="subtitle1" style={{ textTransform: 'uppercase' }}>
              {vacancy.category}
            </Typography>
          </CustomNavLink>

          <div>
            <Typography
              style={{
                textTransform: 'uppercase',
                fontFamily: FONTS.TTNorms.bold,
                marginBottom: 22,
                fontSize: 20,
              }}
            >
              share this listing
            </Typography>
            <ShareIcons vacancy={vacancy} />
          </div>
        </VacancyCard>
      ))}
    </div>
  );
};

export default OpenPositions;
