import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { SavedSearchResultType } from 'types/SearchTypes';

export const SAVED_SEARCHES_SLICE_NAME = 'savedSearches';

const initialState: StateType = {
  pending: false,
  error: null,
  result: [],
};

const savedSearchesSlice = createSlice({
  name: SAVED_SEARCHES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getSavedSearchResult.pending, onPending);
    builder.addCase(
      AsyncThunks.getSavedSearchResult.fulfilled,
      (state, action) => {
        const currentSearchResultIndex = state.result.findIndex(
          (searchResult: SavedSearchResultType) => {
            return searchResult.query === action.payload.query;
          },
        );

        if (currentSearchResultIndex > -1) {
          // replace search result data with updated info
          state.result[currentSearchResultIndex] = action.payload;
        } else {
          state.result.push(action.payload);
        }

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getSavedSearchResult.rejected, onError);

    builder.addCase(AsyncThunks.removeSavedSearch.pending, onPending);
    builder.addCase(
      AsyncThunks.removeSavedSearch.fulfilled,
      (state, action) => {
        const { removedSearchQueryId } = action.payload;

        state.result = state.result.filter(
          (savedSearchResult: SavedSearchResultType) => {
            return (
              savedSearchResult.savedSearchQueryId !== removedSearchQueryId
            );
          },
        );

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.removeSavedSearch.rejected, onError);
  },
});

export const savedSearchesActions = savedSearchesSlice.actions;
export const savedSearchesReducer = savedSearchesSlice.reducer;
