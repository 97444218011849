import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  InputAdornment,
} from '@mui/material';
import styled from 'styled-components';
import { InfoTooltipIcon } from 'assets/svg';
import { Typography } from 'components/Typography/Typography';
import { COLORS, FONTS } from 'styles';

const AddressInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.white,
    maxWidth: 220,
    padding: 10,
    borderRadius: 0,
    border: '1px solid black',
  },
}));

const AddressTooltip = () => {
  return (
    <AddressInfoTooltip
      title={
        <>
          <Typography style={{ fontFamily: FONTS.TTNorms.bold }}>
            Using An APO/FPO Address
          </Typography>
          <Typography>
            All Shipments to APO, FPO, and DPO addresses are sent via USPS
            Parcel Select. Due to military handling time, delivery to some
            destinations may take up to 45 days.{' '}
          </Typography>
          <Typography style={{ fontFamily: FONTS.TTNorms.bold, marginTop: 10 }}>
            Using A PO Box
          </Typography>
          <Typography>
            All orders are shipped via United Parcel Service (Pleases does not
            ship to P.O. Boxes).
          </Typography>
        </>
      }
      placement="right-end"
      enterTouchDelay={0}
      leaveTouchDelay={9000}
    >
      <InputAdornment position="end">
        <InfoTooltipIcon />
      </InputAdornment>
    </AddressInfoTooltip>
  );
};

export default AddressTooltip;
