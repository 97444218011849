import { Dispatch, SetStateAction } from 'react';
import { BrandType } from 'types/BrandsTypes';
import ROUTES from 'router/Routes';
import { MenuItem, MenuItemsBox } from './styles';

const LettersMenu = ({
  letters,
  currentMenuItem,
  isInline = false,
  setCurrentMenuItem,
  brandsList,
  onClickCallback = () => {},
}: {
  letters: string[];
  currentMenuItem: string;
  isInline?: boolean;
  setCurrentMenuItem: Dispatch<SetStateAction<string>>;
  brandsList: BrandType[];
  onClickCallback?: Function;
}) => {
  const doesLetterhaveBrands = (letter: string) => {
    return brandsList?.some((brand) => {
      if (letter === '#') {
        return /\d/.test(brand.businessName);
      }

      return brand.businessName.toUpperCase().startsWith(letter);
    });
  };

  const inlineStyles = {
    container: {
      display: 'block',
      width: '100%',
      margin: 0,
    },
    element: {
      display: 'inline-flex',
      marginRight: 12,
      width: '20px',
      height: '20px',
    },
  };

  return (
    <MenuItemsBox style={isInline ? inlineStyles.container : {}}>
      {letters.map((letter) => (
        <MenuItem
          key={letter}
          onClick={() =>
            isInline
              ? onClickCallback(`${ROUTES.BRANDS}?l=${letter}`)
              : setCurrentMenuItem(currentMenuItem === letter ? '' : letter)
          }
          active={letter === currentMenuItem}
          isClickable={doesLetterhaveBrands(letter)}
          style={isInline ? inlineStyles.element : {}}
        >
          {letter}
        </MenuItem>
      ))}
    </MenuItemsBox>
  );
};

export default LettersMenu;
