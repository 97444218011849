import { OrderListType } from 'types/OrdersTypes';
import {
  marketPlacerStatusData,
  zenkraftStatusData,
} from 'pages/AccountPage/MyOrdersPage';
import OrderCardItem from './OrderCardItem';
import { CardWrapper, ItemStatus } from './styles';

const OrderCard = ({ orderItem }: { orderItem: OrderListType }) => {
  return (
    <CardWrapper>
      {orderItem.productItems?.map((productItem) => (
        <div key={productItem.itemId}>
          <ItemStatus>
            {orderItem.c_marketplacerShippingStatus === 'SHIPPED' &&
            orderItem.c_zenkraftShippingStatus
              ? zenkraftStatusData[orderItem.c_zenkraftShippingStatus]('Order')
              : marketPlacerStatusData[orderItem.c_marketplacerShippingStatus]}
          </ItemStatus>
          <OrderCardItem
            productItem={productItem}
            key={productItem.itemId}
            withStatus={false}
          />
        </div>
      ))}
    </CardWrapper>
  );
};

export default OrderCard;
