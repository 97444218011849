import { createSlice } from '@reduxjs/toolkit';
import { find, includes } from 'lodash';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import {
  onFulfilled,
  onPending,
  onError,
} from 'eg_SFCC_FE_core/store/stateResults';
import { ExtendedCategoryType } from 'types/CatalogTypes';

export const SHOPPER_CATEGORIES_SLICE_NAME = 'shopperCategories';
const initialState: StateType & {
  selectedCategory: { id: string; expands: boolean };
} = {
  pending: false,
  selectedCategory: { id: '', expands: false },
  result: null,
  error: null,
};
const categoriesSlice = createSlice({
  name: SHOPPER_CATEGORIES_SLICE_NAME,
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getShopperCategoriesByIds.pending, onPending);
    builder.addCase(
      AsyncThunks.getShopperCategoriesByIds.fulfilled,
      (state, action) => {
        const categories = action.payload?.map(
          (category: ExtendedCategoryType) => {
            if (includes(['Men', 'Women', 'Unisex'], category.name)) {
              const clothingCategories = find(action.payload, {
                name: 'Clothing',
              });
              const shoesAndAccessoriesCategories = find(action.payload, {
                name: 'Shoes & Accessories',
              });
              const subCategories = [];
              if (clothingCategories) {
                subCategories.push({
                  id: clothingCategories.id,
                  name: clothingCategories.name,
                  categories: find(clothingCategories.categories, {
                    name: category.name,
                  }).categories,
                });
              }

              if (shoesAndAccessoriesCategories) {
                subCategories.push({
                  id: shoesAndAccessoriesCategories.id,
                  name: shoesAndAccessoriesCategories.name,
                  categories: find(shoesAndAccessoriesCategories.categories, {
                    name: category.name,
                  }).categories,
                });
              }
              return {
                ...category,
                categories: subCategories,
              };
            }
            return category;
          },
        );

        const allCategories = {
          hamburgerMenu: categories,
          navigationMenu: categories,
        };

        state.result = allCategories;
        state.error = null;
        state.pending = false;
      },
    );
    builder.addCase(AsyncThunks.getShopperCategoriesByIds.rejected, onError);
    builder.addCase(AsyncThunks.getShopperCategoryById.pending, onPending);
    builder.addCase(AsyncThunks.getShopperCategoryById.fulfilled, onFulfilled);
    builder.addCase(AsyncThunks.getShopperCategoryById.rejected, onError);
  },
});

export const categoriesActions = categoriesSlice.actions;
export const categoriesReducer = categoriesSlice.reducer;
