import { includes } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ROUTES_WITHOUT_STICKY_HEADER = [''];
const ROUTES_WITH_TRANSPARENT_HEADER = [''];
const ROUTES_WITH_WHITE_HEADER_MENU = [''];

const useHeader = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const { pathname } = useLocation();
  const isHeaderSticky = !includes(ROUTES_WITHOUT_STICKY_HEADER, pathname);
  const isHeaderTransparent = includes(
    ROUTES_WITH_TRANSPARENT_HEADER,
    pathname,
  );

  const hasHeaderWhiteMenuText = includes(
    ROUTES_WITH_WHITE_HEADER_MENU,
    pathname,
  );

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrollPosition,
    isHeaderSticky,
    isHeaderTransparent,
    hasHeaderWhiteMenuText,
  };
};

export default useHeader;
