import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import {
  AuthenticationParamsType,
  RegisterParamsType,
} from 'eg_SFCC_FE_core/types';
import {
  getIsLoggedIn,
  getRegistrationResult,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { redirectCustomerToGoogleLogin } from 'eg_SFCC_FE_core/axios/api';
import { COOKIES_KEYS } from 'eg_SFCC_FE_core/axios/apiConstants';
import { SocialAuthType } from 'types/AccountTypes';
import useGoogleTags from './useGoogleTags';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const registrationResult = useSelector(getRegistrationResult);
  const [credentials, setCredentials] = useState<AuthenticationParamsType>();
  const hasAgeRestriction = !!Cookies.get(COOKIES_KEYS.ageRestriction);
  const { trackEvent } = useGoogleTags();

  const loginCustomer = useCallback(
    async (
      authParams: AuthenticationParamsType,
      afterRegistration?: boolean,
    ) => {
      const response = await dispatch(
        AsyncThunks.loginCustomer({ authParams, afterRegistration }),
      );

      // @ts-ignore
      if (!response.error) {
        trackEvent({ event: 'login' });
      }
    },
    [dispatch],
  );

  const registerCustomer = useCallback(
    async (registerParams: RegisterParamsType) => {
      setCredentials({
        username: registerParams.customer.login,
        password: registerParams.password,
      });

      const response = await dispatch(
        AsyncThunks.registerCustomer(registerParams),
      );

      // @ts-ignore
      if (!response.error) {
        trackEvent({ event: 'sign_up' });
      }
    },
    [dispatch],
  );

  const authCustomerViaSocials = useCallback(
    async (type: SocialAuthType) => {
      await redirectCustomerToGoogleLogin(type);
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      credentials &&
      registrationResult?.authType === 'registered' &&
      !isLoggedIn
    ) {
      loginCustomer(credentials, true);
    }
  }, [registrationResult, loginCustomer, credentials]);

  return {
    hasAgeRestriction,
    loginCustomer,
    registerCustomer,
    authCustomerViaSocials,
  };
};

export default useAuth;
