import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { COLORS, FONTS } from 'styles';
import { ArrowDownIcon } from 'assets/svg';
import { AutocompleteDataType } from 'types/AccountTypes';
import { ErrorText, Label } from 'components/Inputs/styles';

const BasicAutocomplete = ({
  control,
  name,
  label,
  items,
  defaultValue,
  shrinkLabel,
  freeInput = true,
  error,
  errorMessage = '',
  helperText,
  disabled,
}: {
  control: any;
  name: string;
  label: string;
  items: AutocompleteDataType[];
  defaultValue: string | undefined;
  shrinkLabel?: boolean;
  freeInput?: boolean;
  error?: any;
  errorMessage: string | undefined;
  helperText?: string;
  disabled?: boolean;
}) => {
  const selectedValue =
    items.find((item: AutocompleteDataType) => {
      return item?.code === defaultValue;
    })?.label ||
    defaultValue ||
    null;

  const autocompleteParams = freeInput
    ? { freeSolo: true }
    : { freeSolo: true, autoSelect: true };

  const textInputParams = shrinkLabel
    ? { InputLabelProps: { shrink: shrinkLabel } }
    : null;

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            id={name}
            disablePortal
            {...autocompleteParams}
            value={selectedValue}
            forcePopupIcon
            popupIcon={<ArrowDownIcon style={{ marginTop: 8 }} />}
            clearIcon={null}
            disabled={!!disabled}
            sx={{
              width: '100%',
              margin: '8px 0 4px',
              position: 'relative',
              '& input': { padding: '0 !important', height: '19px' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.black,
              },
              '& .MuiOutlinedInput-root': {
                fontFamily: `${FONTS.Helvetica.regular}`,
                backgroundColor: disabled ? COLORS.background : COLORS.white,
                borderRadius: 0,
                fontSize: 14,
                padding: '6px 12px !important',

                '@media (max-width: 1024px)': {
                  fontSize: 12,
                },

                '&.Mui-disabled fieldset': {
                  border: 'none',
                },

                '&.Mui-focused fieldset': {
                  borderColor: COLORS.accent,
                  borderWidth: 1,
                },
              },
            }}
            size="small"
            options={items}
            selectOnFocus
            includeInputInList
            autoComplete
            onChange={(e: any, data: any) => {
              field.onChange(data?.code ? data.code : e.target.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                error={!!error}
                inputRef={params.InputProps.ref}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                value={selectedValue}
                onChange={(e: any) => {
                  const targetValue =
                    items.find((item: AutocompleteDataType) => {
                      return (
                        item?.label.toLowerCase() ===
                        e.target.value.toLowerCase()
                      );
                    })?.code || e.target.value;

                  field.onChange(targetValue);
                }}
                {...textInputParams}
                helperText={helperText}
              />
            )}
            isOptionEqualToValue={(option: any, val: any) =>
              option.label === val
            }
          />
        )}
      />

      {error && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default BasicAutocomplete;
