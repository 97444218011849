import { Paragraph, UppercaseTitle, Title, List } from '../styles';

const YourInteractions = () => (
  <>
    <UppercaseTitle>
      8. Your Interactions on the Services, Warranties, and Liability Limits
      (aka the Things and Amounts You Can’t Sue Us For)
    </UppercaseTitle>
    <Title>A. Items You Purchase</Title>
    <Paragraph>
      You, as a buyer, understand that Empower Global does not manufacture,
      store, or inspect any of the items sold through our Services. We provide
      the online venue, and the items in our marketplaces are produced, listed,
      and sold directly by independent sellers, so Empower Global cannot and
      does not make any warranties about their quality, safety, or even their
      legality. Any legal claim related to an item you purchase must be brought
      directly against the seller of the item. You release Empower Global from
      any claims related to items sold through our Services, including for
      defective items, misrepresentations by sellers, or items that caused
      physical injury (like product liability claims).
    </Paragraph>
    <Title>B. Content You Access</Title>
    <Paragraph>
      You may come across materials that you find offensive or inappropriate
      while using our Services. We make no representations concerning any
      content posted by any user through the Services. Empower Global does not
      own and is otherwise not responsible for the accuracy, copyright
      compliance, legality, or decency of content posted by users that you
      accessed through the Services. You release us from all liability relating
      to such content.
    </Paragraph>
    <Title>C. People You Interact With</Title>
    <Paragraph>
      You can use the Services to interact with other individuals, either online
      or in person. However, you understand that we do not screen users of our
      Services, and you release us from all liability relating to your
      interactions with other users. Please be careful and exercise caution and
      sound judgment in all interactions with others, especially if you are
      meeting someone in person.
    </Paragraph>
    <Title>D. Third-Party Services</Title>
    <Paragraph>
      Our Services may contain links to third-party websites or services that we
      don’t own or control (for example, links to Facebook, Twitter, and
      Pinterest). You may also need to use a third party’s product or service in
      order to use some of our Services (like a compatible mobile device to use
      our mobile apps). When you access these third-party services, you do so at
      your own risk. The third parties may require you to accept their own terms
      of use. Empower Global is not responsible for those agreements and is not
      a party to those agreements; they are solely between you and the third
      party.
    </Paragraph>
    <Title>E. Gift Cards and Promotions</Title>
    <Paragraph>
      You acknowledge that Empower Global does not make any warranties with
      respect to any gift card balance that you may have and is not responsible
      for any unauthorized access to, or alteration, theft, or destruction of a
      gift card or gift card code that results from any action by you or a third
      party. You also acknowledge that we may suspend or prohibit use of your
      gift card if your gift card or gift card code has been reported lost or
      stolen, or if we believe your gift card balance is being used
      suspiciously, fraudulently, or in an otherwise unauthorized manner. If
      your gift card code stops working, your only remedy is for us to issue you
      a replacement gift card code.
    </Paragraph>
    <Title>F. Warranties</Title>
    <Paragraph>
      Empower global is dedicated to making our services the best they can be,
      but we’re not perfect and sometimes things can go wrong. You understand
      that our services are provided “as is” and without any kind of
      representation or warranty (express or implied). We expressly disclaim all
      representations and warranties, including those of title,
      non-infringement, merchantability, and fitness for a particular purpose,
      as well as any warranties implied by a course of performance, course of
      dealing, or usage of trade.
    </Paragraph>
    <Paragraph>We do not guarantee that:</Paragraph>
    <List>
      <li>
        the services will be secure or available at any particular time or
        location;
      </li>
      <li>any defects or errors will be corrected;</li>
      <li>
        the services will be free of viruses or other harmful materials; or
      </li>
      <li>
        the results of using the services will meet your expectations. you use
        the services solely at your own risk. Some jurisdictions do not allow
        limitations on representations and warranties, so the above limitations
        may not apply to you.
      </li>
    </List>
    <Title>G. Liability limits</Title>
    <Paragraph>
      To the fullest extent permitted by law, neither empower global, nor our
      employees or Directors shall be liable to you for any lost profits or
      revenues, or for any consequential, incidental, indirect, special, or
      punitive damages arising out of or in connection with the services or the
      terms. In no event shall Empower Global’s aggregate liability for any
      damages arising under or related to the terms exceed the greater of one
      hundred ($100) US Dollars (USD) or the amount you paid Empower Global in
      the twelve months prior from the time the liability arose.
    </Paragraph>
  </>
);

export default YourInteractions;
