import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { indexOf, findIndex, clone, cloneDeep } from 'lodash';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import FilterAccordion from 'components/Accordion/FilterAccordion';
import { IFilterProps, RefinementType } from 'types/ProductsTypes';
import { RotatedMinus, RotatedPlus } from 'components/Accordion/styles';
import {
  Cross,
  FilterWrapper,
  FilteredBy,
  SelectedFilterLabel,
  SelectedFiltersWrapper,
} from './styles';

const refinementsIdsToApply = [
  'cgid',
  'price',
  'c_marketplacerSellerName',
  'c_marketPlacerTags',
  'c_bvAverageRating',
  'c_marketplacerGender',
  'c_size',
  'c_color',
];

const Filters = ({
  refinements,
  filterValues,
  setFilterValues,
  isExpanded,
  activeCategoryId,
}: {
  refinements: RefinementType[];
  filterValues: IFilterProps[];
  setFilterValues: Dispatch<SetStateAction<IFilterProps[]>>;
  isExpanded?: boolean;
  activeCategoryId?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const refinementsToApply = refinements?.filter(
    (refinement: RefinementType) => {
      return indexOf(refinementsIdsToApply, refinement.attributeId) > -1;
    },
  );

  const handleChange = () => setExpanded((prevExpanded) => !prevExpanded);

  const selectFilterValues = useCallback(
    (data: IFilterProps) => {
      setFilterValues((prevValues: IFilterProps[]) => {
        const currentFilterIndex = findIndex(
          prevValues,
          (prevVal: IFilterProps) => {
            return prevVal.attributeId === data.attributeId;
          },
        );
        const copiedValues = clone(prevValues);

        if (currentFilterIndex > -1) {
          copiedValues[currentFilterIndex] = data;
        } else {
          copiedValues.push(data);
        }

        return copiedValues;
      });
    },
    [filterValues],
  );

  const handleFilterUnselect = (attributeId: string, indexToRemove: number) => {
    const currentFilterIndex = findIndex(
      filterValues,
      (prevVal: IFilterProps) => {
        return prevVal.attributeId === attributeId;
      },
    );
    const prevValues = cloneDeep(filterValues);
    prevValues[currentFilterIndex].values.splice(indexToRemove, 1);
    prevValues[currentFilterIndex].labels.splice(indexToRemove, 1);

    setFilterValues(prevValues);
  };

  if (isExpanded) {
    return (
      <MuiAccordion
        disableGutters
        elevation={0}
        expanded={expanded}
        TransitionProps={
          { unmountOnExit: true, mountOnEnter: true } as TransitionProps
        }
        sx={{ width: 300, marginBottom: 2 }}
      >
        <AccordionSummary
          aria-controls="filter-content"
          id="filter-header"
          sx={{
            padding: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
              flexDirection: 'column',
            },
          }}
        >
          <div
            onClick={handleChange}
            aria-hidden="true"
            style={{ display: 'flex' }}
          >
            <FilteredBy>Filtered by:</FilteredBy>
            {expanded ? <RotatedMinus /> : <RotatedPlus />}
          </div>
          <SelectedFiltersWrapper>
            {filterValues.map((val: IFilterProps) => {
              return val.labels.map((label: string, index: number) => {
                return (
                  <SelectedFilterLabel key={label} onClick={() => {}}>
                    {label}
                    <Cross
                      onClick={() =>
                        handleFilterUnselect(val.attributeId, index)
                      }
                    />
                  </SelectedFilterLabel>
                );
              });
            })}
          </SelectedFiltersWrapper>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {refinementsToApply?.length
            ? refinementsToApply?.map((refinement: RefinementType) => {
                const filter = filterValues?.find(
                  (f: IFilterProps) => f.attributeId === refinement.attributeId,
                );

                return (
                  <div style={{ margin: '4px 0' }} key={refinement.attributeId}>
                    <FilterAccordion
                      refinement={refinement}
                      filters={filter}
                      selectFilterValues={selectFilterValues}
                      activeCategoryId={activeCategoryId}
                    />
                  </div>
                );
              })
            : null}
        </AccordionDetails>
      </MuiAccordion>
    );
  }

  return (
    <FilterWrapper>
      <FilteredBy>Filtered by:</FilteredBy>
      <SelectedFiltersWrapper>
        {filterValues.map((val: IFilterProps) => {
          return val.labels.map((label: string, index: number) => {
            return (
              <SelectedFilterLabel key={label}>
                {label}
                <Cross
                  onClick={() => handleFilterUnselect(val.attributeId, index)}
                />
              </SelectedFilterLabel>
            );
          });
        })}
      </SelectedFiltersWrapper>
      {refinementsToApply?.length
        ? refinementsToApply?.map((refinement: RefinementType) => {
            const filter = filterValues?.find(
              (f: IFilterProps) => f.attributeId === refinement.attributeId,
            );

            return (
              <div style={{ margin: '4px 0' }} key={refinement.attributeId}>
                <FilterAccordion
                  refinement={refinement}
                  filters={filter}
                  selectFilterValues={selectFilterValues}
                />
              </div>
            );
          })
        : null}
    </FilterWrapper>
  );
};

export default Filters;
