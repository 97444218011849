import { Dispatch, SetStateAction } from 'react';
import { COLORS } from 'styles';
import BasicSelect from './BasicSelect';
import { StyledMenuItem } from '../styles';

const ReturnQuantitySelect = ({
  maxQuantity,
  quantity,
  setQuantity,
}: {
  maxQuantity: number;
  quantity: number;
  setQuantity: Dispatch<SetStateAction<number>>;
}) => {
  const handleClick = (_: any, qty: number) => {
    setQuantity(qty);
  };
  const renderLabel = () => <div>{quantity}</div>;

  return (
    <BasicSelect
      renderLabel={renderLabel}
      optionValues={[]}
      formControlSx={{
        width: '100%',
        margin: 0,
      }}
      sxStyles={{
        height: 15,
        fontSize: 10,
        maxWidth: 35,
        borderBottom: `1px solid ${COLORS.black}`,
      }}
    >
      {Array.from({ length: maxQuantity }).map((_, index) => {
        return (
          <StyledMenuItem
            value={quantity}
            onClick={() => handleClick(_, index + 1)}
            key={index}
          >
            {index + 1}
          </StyledMenuItem>
        );
      })}
    </BasicSelect>
  );
};

export default ReturnQuantitySelect;
