import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import axios from 'axios';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';

export const getBrandListThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  { rejectValue: ErrorResponse }
> = async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(endpoints.sellers.sellers);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
