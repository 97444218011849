import FormInputController from 'components/Inputs/FormInputController';

import { FormWrapper } from './styles';

const EmailForm = ({
  onSubmit,
  onError,
  methods,
}: {
  onSubmit: (value: any) => void;
  onError: (e: any, errors: any) => void;
  methods: any;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <FormWrapper
      component="form"
      id="email-form"
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      <FormInputController
        control={control}
        name="email"
        label="Email*"
        error={!!errors.email}
        errorMessage={
          errors?.email ? errors.email.message : 'Incorrect or empty entry'
        }
      />
    </FormWrapper>
  );
};

export default EmailForm;
