import { Like, LikeWrapper } from './styles';

const FavoriteIcon = ({
  isFavorite = false,
  onClick,
  iconPosition,
  style,
}: {
  isFavorite?: boolean;
  onClick: () => void;
  iconPosition?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  style?: any;
}) => {
  return (
    <LikeWrapper
      iconPosition={iconPosition}
      isFavorite={isFavorite}
      onClick={onClick}
      style={style}
    >
      <Like />
    </LikeWrapper>
  );
};

export default FavoriteIcon;
