import MobileFilters from 'components/ProductListingComponents/MobileFIlters';
import { CrossIcon } from 'assets/svg';
import { VerticalContentWrapper, Title, SMainLogoIcon } from './styles';

const filters = [
  {
    label: 'color',
    items: [
      'white',
      'black',
      'grey',
      'beige',
      'green',
      'purple',
      'brown',
      'blue',
    ],
  },
  {
    label: 'material',
    items: ['Wool', 'Cashmere', 'Leather', 'Polyester', 'Nylon'],
  },
];

const MobileFiltersContent = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <VerticalContentWrapper>
      <Title id="transition-modal-description">
        <CrossIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
        <SMainLogoIcon
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
          }}
        />
      </Title>
      <MobileFilters filters={filters} />
    </VerticalContentWrapper>
  );
};

export default MobileFiltersContent;
