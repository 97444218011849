import { useState, ReactNode, SyntheticEvent, memo } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { COLORS } from 'styles';
import {
  AccordionContainer,
  AccordionTitle,
  RotatedMinus,
  RotatedPlus,
} from './styles';

interface IItem {
  title: ReactNode | string;
  content: ReactNode | string;
  id: string;
}

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  borderTop: `1px solid ${COLORS.border}`,
  background: 'transparent',
  '&:last-child': {
    borderBottom: `1px solid ${COLORS.border}`,
  },
  '&:before': {
    display: 'none',
  },
  padding: '0 40px',
}));

const AccordionItem = (item: IItem) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <StyledAccordion
      expanded={expanded === `${item.id}`}
      onChange={handleChange(`${item.id}`)}
    >
      <AccordionSummary
        aria-controls={`${item.id}a-content`}
        id={`${item.id}a-header`}
        sx={{ padding: 0 }}
      >
        <AccordionTitle>{item.title}</AccordionTitle>
        {expanded ? <RotatedMinus /> : <RotatedPlus />}
      </AccordionSummary>
      <AccordionDetails>
        <div>{item.content}</div>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const Accordion = ({ data }: { data: IItem[] }) => {
  return (
    <AccordionContainer>
      {data.map(({ title, content, id }: any) => {
        return (
          <AccordionItem
            key={Math.random()}
            title={title}
            content={content}
            id={id}
          />
        );
      })}
    </AccordionContainer>
  );
};

export default memo(Accordion);
