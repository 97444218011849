import { Controller } from 'react-hook-form';

import { Typography } from 'components';
import BasicSelect from 'components/Dropdowns/Selects/BasicSelect';
import ErrorTooltip from 'components/Forms/ErrorTooltip';
import { MenuItem } from '@mui/material';
import { Label } from './styles';

const FormSelectController = ({
  control,
  name,
  items,
  label,
  error,
  errorMessage,
}: {
  control: any;
  name: string;
  items: string[];
  label: string;
  error: any;
  errorMessage: string | undefined;
}) => {
  const renderLabel = (value: any) => {
    return (
      <Typography
        type="body"
        style={{
          opacity: value ? 1 : 0.6,
          lineHeight: 'normal',
          textTransform: 'capitalize',
        }}
      >
        {value}
      </Typography>
    );
  };
  return (
    <>
      <Label htmlFor={label}>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <BasicSelect
            optionValues={items}
            renderLabel={renderLabel}
            error={error}
            variant="outlined"
            endAdornment={
              error && (
                <ErrorTooltip
                  title={errorMessage}
                  placement="right-end"
                  style={{ position: 'absolute', right: 26, top: 18 }}
                />
              )
            }
            {...{ onChange, value }}
          >
            {items.map((item: string) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </BasicSelect>
        )}
      />
    </>
  );
};

export default FormSelectController;
