import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import {
  onPending,
  onError,
  onFulfilled,
  resetState,
} from 'eg_SFCC_FE_core/store/stateResults';
import { ProductCategoryType } from 'types/ProductsTypes';

export const PRODUCT_SLICE_NAME = 'product';

const initialState: StateType & {
  categories: ProductCategoryType[];
} = {
  pending: false,
  error: null,
  result: null,
  categories: [],
};

const productSlice = createSlice({
  name: PRODUCT_SLICE_NAME,
  initialState,
  reducers: {
    reset: resetState,
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getProductById.pending, onPending);
    builder.addCase(AsyncThunks.getProductById.fulfilled, onFulfilled);
    builder.addCase(AsyncThunks.getProductById.rejected, onError);

    builder.addCase(AsyncThunks.getProductCategories.pending, onPending);
    builder.addCase(
      AsyncThunks.getProductCategories.fulfilled,
      (state, action) => {
        const fetchedCategories = action.payload;
        state.categories = fetchedCategories.map(
          (category: { id: string; name: string }, index: number) => {
            return {
              ...category,
              name: category.id === 'seller' ? 'Shop' : category.name,
            };
          },
        );
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getProductCategories.rejected, onError);
  },
});

export const productActions = productSlice.actions;
export const productReducer = productSlice.reducer;
