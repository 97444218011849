/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getPublicGuestTokenThunk } from './thunks/getPublicGuestToken';
import {
  registerCustomerThunk,
  loginCustomerThunk,
  logoutCustomerThunk,
  getCustomerThunk,
  updateCustomerThunk,
  resetPasswordThunk,
  updateFavoriteBrandsThunk,
  addCustomerPaymentInstrumentThunk,
  removeCustomerPaymentInstrumentThunk,
  setDefaultPaymentInstrumentThunk,
  loginCustomerViaGoogleThunk,
} from './thunks/customerThunk';
import {
  getShopperCategoriesByIdsThunk,
  getShopperCategoryByIdThunk,
} from './thunks/getCategoriesThunk';
import {
  getProductListByCategoryIdThunk,
  getProductSearchResultThunk,
  getProductListCategoriesThunk,
} from './thunks/getProductListThunk';
import { getProductByIdThunk, getProductCategoriesThunk } from './thunks/getProductThunk';
import {
  getCustomerProductListsThunk,
  createCustomerProductListItemThunk,
  deleteCustomerProductListItemThunk,
} from './thunks/customerProductListsThunks';
import {
  getBasketsThunk,
  createBasketThunk,
  getOrCreateCustomerBasketsThunk,
  syncBasketThunk,
  addItemToBasketThunk,
  updateItemInBasketThunk,
  removeItemFromBasketThunk,
  getPaymentMethodsThunk,
  addPaymentInstrumentThunk,
  removePaymentInstrumentThunk,
  addBillingAddressToBasketThunk,
  addShippingAddressToBasketShipmentThunk,
  getShippingMethodsThunk,
  addShippingMethodToBasketShipmentThunk,
  updateBasketThunk,
  addCouponThunk,
  removeCouponThunk,
} from './thunks/basketThunk';
import {
  createCustomerAddressThunk,
  removeCustomerAddressThunk,
  updateCustomerAddressThunk,
  setDefaultAddressThunk,
} from './thunks/addressThunk';
import {
  createOrderThunk,
  getCustomerOrderListThunk,
  getCustomerOrderByIdThunk,
  returnProductLineItemThunk,
  updatePaymentInstrumentThunk,
} from './thunks/orderThunk';
import { getSearchSuggestionsThunk } from './thunks/getSearchSuggestionsThunk';
import { getWishlistProductsThunk } from './thunks/getWishlistProductsThunk';
import { getBrandListThunk } from './thunks/getBrandListThunk';
import {
  getBrandByIdThunk,
  getProductListByBrandThunk,
} from './thunks/getBrandThunk';
import {
  saveSearchResultThunk,
  getSavedSearchResultThunk,
  updateSavedSearchResultThunk,
  removeSavedSearchThunk,
} from './thunks/savedSearchThunk';

export const AsyncThunks = {
  getPublicGuestToken: createAsyncThunk(
    'getGuestToken',
    getPublicGuestTokenThunk,
  ),
  registerCustomer: createAsyncThunk('registerCustomer', registerCustomerThunk),
  loginCustomer: createAsyncThunk('loginCustomer', loginCustomerThunk),
  loginCustomerViaGoogle: createAsyncThunk('loginCustomerViaGoogle', loginCustomerViaGoogleThunk),
  logoutCustomer: createAsyncThunk('logoutCustomer', logoutCustomerThunk),
  getCustomer: createAsyncThunk('getCustomer', getCustomerThunk),
  updateCustomer: createAsyncThunk('updateCustomer', updateCustomerThunk),
  getShopperCategoriesByIds: createAsyncThunk(
    'getCategories',
    getShopperCategoriesByIdsThunk,
  ),
  getShopperCategoryById: createAsyncThunk(
    'getCategory',
    getShopperCategoryByIdThunk,
  ),
  resetPassword: createAsyncThunk('resetPassword', resetPasswordThunk),
  updateFavoriteBrands: createAsyncThunk(
    'updateFavoriteBrands',
    updateFavoriteBrandsThunk,
  ),

  // search suggestions
  getSearchSuggestions: createAsyncThunk(
    'getSearchSuggestions',
    getSearchSuggestionsThunk,
  ),

  // product search
  getProductListByCategoryId: createAsyncThunk(
    'getProductListByCategoryId',
    getProductListByCategoryIdThunk,
  ),
  getProductSearchResult: createAsyncThunk(
    'getProductSearchResult',
    getProductSearchResultThunk,
  ),
  getProductListCategories: createAsyncThunk(
    'getProductListCategories',
    getProductListCategoriesThunk,
  ),

  getProductById: createAsyncThunk('getProductById', getProductByIdThunk),
  getProductCategories: createAsyncThunk('getProductCategories', getProductCategoriesThunk),
  getCustomerProductLists: createAsyncThunk(
    'getCustomerProductLists',
    getCustomerProductListsThunk,
  ),
  getWishlistProducts: createAsyncThunk(
    'getWishlistProducts',
    getWishlistProductsThunk,
  ),

  getBrandList: createAsyncThunk('getBrandList', getBrandListThunk),
  getBrandById: createAsyncThunk('getBrandById', getBrandByIdThunk),
  getProductListByBrand: createAsyncThunk(
    'getProductListByBrandThunk',
    getProductListByBrandThunk,
  ),

  createCustomerProductListItem: createAsyncThunk(
    'createCustomerProductListItem',
    createCustomerProductListItemThunk,
  ),
  deleteCustomerProductListItem: createAsyncThunk(
    'deleteCustomerProductListItem',
    deleteCustomerProductListItemThunk,
  ),
  createCustomerAddress: createAsyncThunk(
    'createCustomerAddress',
    createCustomerAddressThunk,
  ),
  removeCustomerAddress: createAsyncThunk(
    'removeCustomerAddress',
    removeCustomerAddressThunk,
  ),
  updateCustomerAddress: createAsyncThunk(
    'updateCustomerAddress',
    updateCustomerAddressThunk,
  ),
  setDefaultAddress: createAsyncThunk(
    'setDefaultAddress',
    setDefaultAddressThunk,
  ),
  // customer basket
  getBaskets: createAsyncThunk('getBaskets', getBasketsThunk),
  getOrCreateCustomerBaskets: createAsyncThunk(
    'getOrCreateCustomerBaskets',
    getOrCreateCustomerBasketsThunk,
  ),
  createBasket: createAsyncThunk('createBasket', createBasketThunk),
  updateBasket: createAsyncThunk('updateBasket', updateBasketThunk),
  addItemToBasket: createAsyncThunk('addItemToBasket', addItemToBasketThunk),
  updateItemInBasket: createAsyncThunk(
    'updateItemInBasket',
    updateItemInBasketThunk,
  ),
  removeItemFromBasket: createAsyncThunk(
    'removeItemFromBasket',
    removeItemFromBasketThunk,
  ),
  getPaymentMethods: createAsyncThunk(
    'getPaymentMethods',
    getPaymentMethodsThunk,
  ),
  removeCustomerPaymentInstrument: createAsyncThunk(
    'removeCustomerPaymentInstrument',
    removeCustomerPaymentInstrumentThunk,
  ),
  setDefaultPaymentInstrument: createAsyncThunk(
    'setDefaultPaymentInstrument',
    setDefaultPaymentInstrumentThunk,
  ),
  addCustomerPaymentInstrument: createAsyncThunk(
    'addCustomerPaymentInstrument',
    addCustomerPaymentInstrumentThunk,
  ),
  addPaymentInstrument: createAsyncThunk(
    'addPaymentInstrument',
    addPaymentInstrumentThunk,
  ),
  removePaymentInstrument: createAsyncThunk(
    'removePaymentInstrument',
    removePaymentInstrumentThunk,
  ),
  updatePaymentInstrument: createAsyncThunk(
    'updatePaymentInstrument',
    updatePaymentInstrumentThunk,
  ),
  addBillingAddressToBasket: createAsyncThunk(
    'addBillingAddressToBasket',
    addBillingAddressToBasketThunk,
  ),
  addShippingAddressToBasketShipment: createAsyncThunk(
    'addShippingAddressToBasketShipment',
    addShippingAddressToBasketShipmentThunk,
  ),
  getShippingMethods: createAsyncThunk(
    'getShippingMethods',
    getShippingMethodsThunk,
  ),
  addShippingMethodToBasketShipment: createAsyncThunk(
    'addShippingMethodToBasketShipment',
    addShippingMethodToBasketShipmentThunk,
  ),
  syncBasket: createAsyncThunk('syncBasket', syncBasketThunk),
  addCoupon: createAsyncThunk('addCoupon', addCouponThunk),
  removeCoupon: createAsyncThunk('removeCoupon', removeCouponThunk),

  // customer orders
  createOrder: createAsyncThunk('createOrder', createOrderThunk),
  getCustomerOrderList: createAsyncThunk(
    'getCustomerOrderList',
    getCustomerOrderListThunk,
  ),
  getCustomerOrderById: createAsyncThunk(
    'getCustomerOrderById',
    getCustomerOrderByIdThunk,
  ),
  returnProductLineItem: createAsyncThunk(
    'returnProductLineItem',
    returnProductLineItemThunk,
  ),

  // search results
  saveSearchResult: createAsyncThunk(
    'saveSearchResult',
    saveSearchResultThunk,
  ),
  getSavedSearchResult: createAsyncThunk(
    'getSavedSearchResult',
    getSavedSearchResultThunk,
  ),
  updateSavedSearchResult: createAsyncThunk(
    'updateSavedSearchResult',
    updateSavedSearchResultThunk,
  ),
  removeSavedSearch: createAsyncThunk(
    'removeSavedSearch',
    removeSavedSearchThunk,
  ),
};
