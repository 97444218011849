import React, { useCallback, useEffect, useState } from 'react';
import { find } from 'lodash';

import { AnimatedWrapper, ColoredButton, Loader, Typography } from 'components';
import AccountAddressForm from 'components/AccountComponents/AccountAddressForm';
import AddressBlock from 'components/AddressBlock/AddressBlock';
import { AddressIcon, BackIcon, DeleteIcon } from 'assets/svg';
import {
  AddressFormFieldsType,
  OrderAddressPageMode,
} from 'types/AccountTypes';
import { useSelector } from 'react-redux';
import {
  getCustomerData,
  getCustomerError,
  getCustomerLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useConfirmationModal } from 'hooks/useModals';
import {
  formatSuggestedAddress,
  setDefaultAddressFirst,
} from 'helpers/formatters';
import { orderActions } from 'eg_SFCC_FE_core/store/reducers/slices/orderSlice';
import { useNavigate } from 'react-router-dom';
import { RightBlockWrapper, BlockTitle } from './styles';

type TitlesType = {
  [T in OrderAddressPageMode]: {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
  };
};

const titles: TitlesType = {
  addressList: {
    Icon: AddressIcon,
    title: 'Shipped from Address',
  },
  editAddress: {
    Icon: BackIcon,
    title: 'Edit Address',
  },
};

const OrderReturnAddressesPage = () => {
  const [pageMode, setPageMode] = useState<OrderAddressPageMode>('addressList');
  const [addressToEdit, setAddressToEdit] = useState<CustomerAddressType>();
  const [checkedAddress, setCheckedAddress] = useState<CustomerAddressType>();
  const [suggestedAddress, setSuggestedAddress] =
    useState<AddressFormFieldsType>();
  const { addresses } = useSelector(getCustomerData);
  const isLoading = useSelector(getCustomerLoader);
  const addressError = useSelector(getCustomerError);
  const dispatch = useAppDispatch();
  const openConfirmationModal = useConfirmationModal();
  const navigate = useNavigate();

  const handleEditAddress = useCallback((address: CustomerAddressType) => {
    setAddressToEdit(address);
    setPageMode('editAddress');
  }, []);

  const deleteAddress = async (id: string) => {
    await dispatch(AsyncThunks.removeCustomerAddress(id));
  };

  const handleAddress = async (address: CustomerAddressType) => {
    let response;
    setSuggestedAddress(undefined);

    if (pageMode === 'editAddress') {
      response = await dispatch(
        AsyncThunks.updateCustomerAddress({
          addressId: address.addressId,
          customerAddress: address,
        }),
      );
    }

    if (response?.payload.statusCode === 'has_suggested_address') {
      const formattedSuggestedAddress = formatSuggestedAddress(
        response.payload.statusMessage,
      );
      setSuggestedAddress(formattedSuggestedAddress);
    }
  };

  const handleDeleteAddress = useCallback(async (id: string) => {
    openConfirmationModal({
      Icon: DeleteIcon,
      title: 'delete address',
      text: 'Deleting an address would permanently remove it from here. Are you sure you wish to proceed?',
      cancelButtonText: 'cancel',
      confirmButtonText: 'delete',
      confirmButtonAction: () => deleteAddress(id),
    });
  }, []);

  let retry = false;
  const setAddressAsDefault = async (address: CustomerAddressType | null) => {
    if (!address || retry) return;

    retry = true;
    await dispatch(
      AsyncThunks.setDefaultAddress({
        addressId: address.addressId,
        customerAddress: address,
      }),
    );
    retry = false;
  };

  const handleDefaultAddress = useCallback(
    async (address: CustomerAddressType | null) => {
      if (!address) return;

      openConfirmationModal({
        Icon: DeleteIcon,
        title: 'set address as default address',
        text: `Set ${address.address1}, ${address.city}, ${address.stateCode} as default address?`,
        cancelButtonText: 'cancel',
        confirmButtonText: 'confirm',
        confirmButtonAction: () => setAddressAsDefault(address),
      });
    },
    [],
  );

  const handleSelect = () => {
    dispatch(
      orderActions.updatedReturnParams({
        c_addressId: checkedAddress?.addressId,
      }),
    );
    navigate(-1);
  };

  useEffect(() => {
    if (!addressError) {
      setPageMode('addressList');
    }
  }, [addresses]);

  return (
    <AnimatedWrapper animationKey={pageMode}>
      <RightBlockWrapper>
        {isLoading ? <Loader fullscreen /> : null}
        <BlockTitle>{titles[pageMode].title}</BlockTitle>

        {pageMode === 'addressList' ? (
          <>
            {addressError?.statusCode === 'has_suggested_address' ? (
              <Typography color="accent">
                The address is invalid. Edit it please
              </Typography>
            ) : addressError?.detail ? (
              <Typography color="accent">{addressError?.detail}</Typography>
            ) : null}
            <AddressBlock
              addressData={
                addresses ? setDefaultAddressFirst(addresses) || addresses : []
              }
              handleEdit={handleEditAddress}
              selectedAddress={find(addresses, { preferred: true })}
              selectAddress={handleDefaultAddress}
              useAddressActions
              handleAddressCheck={setCheckedAddress}
              checkedAddress={checkedAddress}
            />

            {pageMode === 'addressList' ? (
              <ColoredButton
                aria-label="add new address"
                width="214px"
                onClick={handleSelect}
              >
                SELECT
              </ColoredButton>
            ) : null}
          </>
        ) : (
          <AccountAddressForm
            address={pageMode === 'editAddress' ? addressToEdit : undefined}
            handleAddress={handleAddress}
            handleDelete={handleDeleteAddress}
            isLoading={isLoading}
            suggestedAddress={suggestedAddress}
            setSuggestedAddress={setSuggestedAddress}
          />
        )}
      </RightBlockWrapper>
    </AnimatedWrapper>
  );
};

export default OrderReturnAddressesPage;
