import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import {
  ErrorResponse,
  OrderListParamsType,
  PaymentInstrumentDataType,
} from 'eg_SFCC_FE_core/types';
import { ReturnPlisInfo } from 'types/OrdersTypes';

export const createOrderThunk: AsyncThunkPayloadCreator<
  any,
  undefined,
  {
    rejectValue: ErrorResponse;
    getState: () => any;
  }
> = async (_, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const { basketId } = state.basketReducer.result;

    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.post(
      endpoints.orders.orders,
      { basketId },
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getCustomerOrderListThunk: AsyncThunkPayloadCreator<
  any,
  OrderListParamsType,
  { rejectValue: ErrorResponse; state: RootState }
> = async (orderListParams, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const { customerId } = state.customerReducer;

    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
      limit: orderListParams.limit,
      offset: orderListParams.offset,
    };

    const response = await axiosInstance.get(
      `${endpoints.customer.customerOrders(customerId)}`,
      {
        params,
      },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getCustomerOrderByIdThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (orderNo, { rejectWithValue }) => {
  try {
    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
    };

    const response = await axiosInstance.get(
      `${endpoints.orders.orders}/${orderNo}`,
      {
        params,
      },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const returnProductLineItemThunk: AsyncThunkPayloadCreator<
  any,
  {
    c_action: 'returnProductLineItems';
    c_plis_info: ReturnPlisInfo[];
    c_orderNo: string;
    c_returnComment: string;
    c_addressId: string;
  },
  { rejectValue: ErrorResponse; state: RootState }
> = async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updatePaymentInstrumentThunk: AsyncThunkPayloadCreator<
  any,
  {
    orderNo: string;
    paymentInstrumentId: string;
    paymentInstrumentData: PaymentInstrumentDataType;
  },
  { rejectValue: ErrorResponse }
> = async (
  { orderNo, paymentInstrumentId, paymentInstrumentData },
  { rejectWithValue },
) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };

    const response = await axiosInstance.patch(
      endpoints.orders.ordersPaymentInstrument(orderNo, paymentInstrumentId),
      paymentInstrumentData,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
