import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact } from 'lodash';

import { Loader } from 'components';
import {
  getCustomerOrderListsLoader,
  getCustomerId,
} from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { syncOrderProductLineItem } from 'eg_SFCC_FE_core/axios/api';
import { formatPrice, formatShippingAddress } from 'helpers/formatters';
import { OrderListType, ProductItemsType } from 'types/OrdersTypes';
import OrderCard from './OrderCard';
import {
  OrderAccordionContentWrapper,
  OrderCardContentTitle,
  OrderCardsBlock,
  OrderDetailsWrapper,
  OrderInfoAddress,
  OrderInfoBlocks,
  OrderInfoText,
  OrderInfoTitle,
  OrderPriceRow,
  PaymentSummaryWrapper,
  StyledLink,
} from './styles';

let retry: boolean;

const OrderAccordionContent = ({ order }: { order: OrderListType }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getCustomerOrderListsLoader);
  const customerId = useSelector(getCustomerId);
  const [isSyncRequired, setIsSyncRequired] = useState<boolean>(true);

  const shippingAddress = order?.shipments[0].shippingAddress;
  const paymentInstrument =
    order?.paymentInstruments[(order?.paymentInstruments.length || 1) - 1];

  const fetchOrder = async () => {
    await dispatch(AsyncThunks.getCustomerOrderById(order.orderNo));
  };

  const syncOrderProductItems = async () => {
    let itemIds = '';
    order?.productItems.forEach((productItem: ProductItemsType) => {
      if (productItem.c_returnStatus?.actionRequired) {
        itemIds = itemIds
          ? `${itemIds},${productItem.itemId}`
          : productItem.itemId;
      }
    });

    if (itemIds && !retry) {
      retry = true;

      const response = await syncOrderProductLineItem(
        customerId,
        itemIds,
        order?.orderNo,
      );

      if (!response.error) {
        await fetchOrder();
      }
      retry = false;
    }

    setIsSyncRequired(false);
  };

  useEffect(() => {
    if (order?.productItems[0].image) {
      if (!order?.c_returnStatus?.eligible) {
        setIsSyncRequired(false);
        return;
      }

      syncOrderProductItems();
    }
    setIsSyncRequired(false);
  }, [order]);

  return (
    <OrderAccordionContentWrapper>
      {isLoading ||
      !order ||
      !shippingAddress ||
      !paymentInstrument ||
      isSyncRequired ? (
        <Loader />
      ) : (
        <OrderDetailsWrapper>
          <OrderCardsBlock>
            <OrderCardContentTitle>Order summary</OrderCardContentTitle>
            <OrderCard key={order.orderNo} orderItem={order} />

            <div
              style={{
                display: 'flex',
                gap: 30,
                width: ' 100%',
                paddingTop: 20,
              }}
            >
              <StyledLink to={`/account/my-orders/${order.orderNo}/returns`}>
                return
              </StyledLink>
            </div>
          </OrderCardsBlock>

          <OrderInfoBlocks>
            <div>
              <OrderInfoTitle>address</OrderInfoTitle>
              <OrderInfoAddress>
                {compact(formatShippingAddress(shippingAddress)).map(
                  (value: string, index: number) => (
                    <p key={index}>{value}</p>
                  ),
                )}
              </OrderInfoAddress>
            </div>

            <PaymentSummaryWrapper>
              <div>
                <OrderInfoTitle>payment</OrderInfoTitle>
                <OrderInfoText>
                  {paymentInstrument.paymentCard.numberLastDigits}{' '}
                  {paymentInstrument.paymentCard.cardType.toLocaleUpperCase()}
                </OrderInfoText>
              </div>

              <div>
                <OrderInfoTitle style={{ marginTop: 20 }}>
                  summary
                </OrderInfoTitle>

                <OrderPriceRow>
                  <OrderInfoText>SUBTOTAL</OrderInfoText>
                  <OrderInfoText>
                    {formatPrice(order.productSubTotal || 0)}
                  </OrderInfoText>
                </OrderPriceRow>
                <OrderPriceRow>
                  <OrderInfoText>SHIPPING</OrderInfoText>
                  <OrderInfoText>
                    {formatPrice(order.shippingTotal || 0)}
                  </OrderInfoText>
                </OrderPriceRow>
                <OrderPriceRow>
                  <OrderInfoText>ESTIMATED TAX</OrderInfoText>
                  <OrderInfoText>
                    {formatPrice(order.taxTotal || 0)}
                  </OrderInfoText>
                </OrderPriceRow>
                <OrderPriceRow>
                  <OrderInfoText>TOTAL</OrderInfoText>
                  <OrderInfoText>
                    {formatPrice(order.productTotal || 0)}
                  </OrderInfoText>
                </OrderPriceRow>
              </div>
            </PaymentSummaryWrapper>
          </OrderInfoBlocks>
        </OrderDetailsWrapper>
      )}
    </OrderAccordionContentWrapper>
  );
};

export default OrderAccordionContent;
