import { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { FaqSearchInputButton } from './styles';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0.5px solid #000000',
    borderRadius: 0,
    paddingRight: 0,
  },
  '& input': {
    padding: '10px !important', // override inline-style
  },
});

const FaqSearchInput = ({
  searchValue,
  handleSubmit,
  handleChange,
}: {
  searchValue: string;
  handleSubmit: (e: { preventDefault: () => void }) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <CssTextField
        fullWidth
        id="faq-input-with-icon-textfield"
        value={searchValue}
        onChange={handleChange}
        variant="outlined"
      />
      <FaqSearchInputButton aria-label="search" onClick={handleSubmit}>
        search
      </FaqSearchInputButton>
    </div>
  );
};

export default FaqSearchInput;
