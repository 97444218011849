import styled from 'styled-components';
import { ColoredButton, Typography } from 'components';
import { BREAKPOINTS, COLORS } from 'styles';
import { PageContentWrapper } from 'styles/common';

export const PageWrapper = styled(PageContentWrapper)`
  flex-direction: column;
  padding-top: 60px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-top: 25px;
  }
`;

export const Title = styled(Typography).attrs({
  tag: 'h1',
  type: 'title2Helv',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 40px 0;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 10px;
    margin: 25px 0;
    padding: 0 15px;
  }
`;

export const StyledButton = styled(ColoredButton)<{
  isActive: boolean;
}>`
  max-width: 250px;
  height: 48px;
  border: 1px solid;
  border-color: ${({ isActive }) =>
    isActive ? COLORS.black : COLORS.middleGrey};

  & p {
    color: ${({ isActive }) => (isActive ? COLORS.black : COLORS.middleGrey)};
    font-size: 15px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-width: 100%;
    height: 30px;

    & p {
      font-size: 10px;
    }
  }
`;
