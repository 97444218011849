import { Helmet } from 'react-helmet';

import {
  SellerAnnouncementIcon,
  SellerDiamondIcon,
  SellerGlobusIcon,
  SellerInnovatorsIcon,
  SellerNetworkDiagramIcon,
  SellerTrendUpIcon,
} from 'assets/svg';
import { CustomNavLink } from 'components';
import GetWithTheProgram from 'components/LoyaltyComponents/GetWithTheProgram/GetWithTheProgram';
import IconWithInfo from 'components/SellerComponents/InfoWithIcon';
import PressSlider from 'components/SellerComponents/PressSlider';
import ROUTES from 'router/Routes';
import {
  HeroColoredButton,
  HeroContents,
  HeroTitle,
  HeroSubtitle,
  BackgroundIcon,
  DarkLine,
  TopLineText,
  BottomLineText,
  InfoSection,
} from './styles';

const SellerPage = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Seller</title>
        <meta name="description" content="Seller" />
      </Helmet>
      <BackgroundIcon />
      <HeroContents>
        <HeroTitle>GET DISCOVERED ON EMPOWER GLOBAL</HeroTitle>
        <HeroSubtitle>
          Empower Global is the premiere Black-owned digital marketplace. We’re
          on a mission to connect our growing community with high quality Black
          owned businesses, and the dope sh*t they sell!
          <br />
          <br />
          You into that sort of thing? Great, because our community of brands is
          always expanding. So, if you create products, and are looking to
          continue to scale your business, we want to hear from you. Keep
          reading for more information before you apply for membership!
        </HeroSubtitle>
        <CustomNavLink to={ROUTES.CONTACT_US}>
          <HeroColoredButton aria-label="join eg">join eg</HeroColoredButton>
        </CustomNavLink>
      </HeroContents>

      <DarkLine>
        <TopLineText>
          All membership decisions are made by our curation team. Please allow
          2-3 weeks for an official decision.
        </TopLineText>
      </DarkLine>

      <InfoSection>
        <IconWithInfo
          title="PREMIERE PRODUCTS"
          subtitle="Our customers know us for the amazing variety, thoughtfully curated, quality items our brands create."
          Icon={SellerDiamondIcon}
        />
        <IconWithInfo
          title="OPEN TO EXCLUSIVITY"
          subtitle="We curate unique assortments including Empower Global exclusive items and drops."
          Icon={SellerGlobusIcon}
        />
        <IconWithInfo
          title="Increased Sales"
          subtitle="Our shopper community is actively expanding. As we grow, so does your business!"
          Icon={SellerTrendUpIcon}
        />
        <IconWithInfo
          title="Exposure to New Customers"
          subtitle="We drive marketing through digital and traditional mediums to consistently put your products in front of shoppers that want to buy Black."
          Icon={SellerAnnouncementIcon}
        />
        <IconWithInfo
          title="EMPOWER GLOBAL INNOVATORS"
          subtitle="Our businesses are leaders among their peers, and are always a notch above in quality and innovation."
          Icon={SellerInnovatorsIcon}
        />
        <IconWithInfo
          title="Access to Resources"
          subtitle="We offer a strong community with resources from partners, opportunities for capital, and educational support for our EG family of brands to grow and scale."
          Icon={SellerNetworkDiagramIcon}
        />
      </InfoSection>

      <DarkLine>
        <BottomLineText>seller stories</BottomLineText>
      </DarkLine>

      <PressSlider />
      <GetWithTheProgram />
    </div>
  );
};

export default SellerPage;
