import COLORS from './colors';
import FONTS from './fonts';

export type TextColorType =
  | 'primary'
  | 'primaryInverted'
  | 'secondary'
  | 'accent';

export const TextColors = {
  primary: COLORS.black,
  primaryInverted: COLORS.white,
  secondary: COLORS.black,
  accent: COLORS.accent,
};

export default {
  title1: {
    fontSize: 100,
    lineHeight: 120,
    fontFamily: FONTS.Bison.bold,
  },
  title2: {
    fontSize: 80,
    lineHeight: 96,
    fontFamily: FONTS.Bison.bold,
  },
  title3: {
    fontSize: 40,
    lineHeight: 51,
    fontFamily: FONTS.TTNorms.bold,
  },
  title4: {
    fontSize: 40,
    lineHeight: 48,
    fontFamily: FONTS.Bison.bold,
  },
  subtitle1: {
    fontSize: 18,
    lineHeight: 21.24,
    fontFamily: FONTS.TTNorms.regular,
  },
  subtitle2: {
    fontSize: 18,
    lineHeight: 21.24,
    fontFamily: FONTS.TTNorms.medium,
  },
  subtitle3: {
    fontSize: 16,
    lineHeight: 31,
    fontFamily: FONTS.TTNorms.medium,
  },
  body: {
    fontSize: 16,
    lineHeight: 31,
    fontFamily: FONTS.TTNorms.regular,
  },
  body2: {
    fontSize: 16,
    lineHeight: 18.8,
    fontFamily: FONTS.TTNorms.regular,
  },
  button: {
    fontSize: 20,
    lineHeight: 23.6,
    fontFamily: FONTS.TTNorms.regular,
  },
  nav: {
    fontSize: 15,
    lineHeight: 17.7,
    fontFamily: FONTS.TTNorms.medium,
  },

  title1Helv: {
    fontSize: 36,
    lineHeight: 44,
    fontFamily: FONTS.Helvetica.bold,
  },
  title2Helv: {
    fontSize: 24,
    lineHeight: 29,
    fontFamily: FONTS.Helvetica.bold,
  },
  title3Helv: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: FONTS.Helvetica.bold,
  },
  title4Helv: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: FONTS.Helvetica.bold,
  },
  title5Helv: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FONTS.Helvetica.bold,
  },
  title6Helv: {
    fontSize: 12,
    lineHeight: 15,
    fontFamily: FONTS.Helvetica.bold,
  },
  subtitle1Helv: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle2Helv: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle3Helv: {
    fontSize: 13,
    lineHeight: 16,
    fontFamily: FONTS.Helvetica.medium,
  },
  subtitle4Helv: {
    fontSize: 10,
    lineHeight: 12,
    fontFamily: FONTS.Helvetica.medium,
  },
  bodyHelv: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FONTS.Helvetica.regular,
  },
  body2Helv: {
    fontSize: 16,
    lineHeight: 19,
    fontFamily: FONTS.Helvetica.regular,
  },
  smallHelv: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: FONTS.Helvetica.regular,
  },
  buttonHelv: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: FONTS.Helvetica.bold,
  },
  xsmallHelv: {
    fontSize: 12,
    lineHeight: 14,
    fontFamily: FONTS.Helvetica.regular,
  },
  navHelv: {
    fontSize: 13,
    lineHeight: 16,
    fontFamily: FONTS.Helvetica.regular,
  },
  sliderCopyHelv: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: FONTS.Helvetica.regular,
  },
};
