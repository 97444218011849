import { useState, ReactNode, SyntheticEvent } from 'react';
import { CSSProperties } from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { AnimatedWrapper } from 'components';
import { COLORS, FONTS } from 'styles';

interface TabContentProps {
  index: number;
  value: number;
  children?: ReactNode;
}

interface BasicTabsProps {
  tabs: {
    id: string;
    name: string;
    content: ReactNode;
  }[];
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  tabStyles?: { [key: string]: CSSProperties };
  orientation?: 'horizontal' | 'vertical';
  activeTabIndex?: number;
}

const TabContent = (props: TabContentProps) => {
  const { children, value, index, ...other } = props;

  return (
    <AnimatedWrapper animationKey={value}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    </AnimatedWrapper>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const BasicTabs = ({
  tabs,
  tabStyles,
  variant = 'standard',
  orientation = 'horizontal',
  activeTabIndex,
}: BasicTabsProps) => {
  const [value, setValue] = useState(activeTabIndex || 0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
          indicatorColor="secondary"
          variant={variant}
          orientation={orientation}
          sx={{
            '& .MuiButtonBase-root': {
              alignItems: 'flex-start',
              padding: '12px 0',
              fontFamily: FONTS.TTNorms.medium,
              fontSize: 15,
              color: COLORS.black,
            },
            ...tabStyles,
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.id} label={tab.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabContent key={tab.id} value={value} index={index}>
          {tab.content}
        </TabContent>
      ))}
    </Box>
  );
};

export default BasicTabs;
