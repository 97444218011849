import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ErrorText } from 'components';
import CheckoutEmailForm from 'components/CheckoutComponents/CheckoutEmailForm';
import {
  getCustomerData,
  getIsLoggedIn,
  getBasket,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { OutletPage, MainTitle } from '../styles';
import { FormWrapper, PersonalData } from './styles';
import { useCheckoutNextSteps } from '../CheckoutPage';

const PersonalInfo = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { result } = useSelector(getBasket);
  const customerData = useSelector(getCustomerData);
  const { isSubmitEmail, setIsSubmitEmailReady, onFormSubmitError } =
    useCheckoutNextSteps();
  const dispatch = useAppDispatch();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const addEmail = async (email: string) => {
    try {
      await dispatch(
        AsyncThunks.updateBasket({ customerInfo: { email } }),
      ).unwrap();
      setIsSubmitEmailReady(true);
    } catch (e: any) {
      onFormSubmitError();
      setErrorMessage(e?.detail);
    }
  };

  useEffect(() => {
    if (isSubmitEmail) {
      submitButtonRef?.current?.click();
    }
  }, [isSubmitEmail]);

  return (
    <OutletPage>
      {!isLoggedIn ? (
        <FormWrapper>
          <MainTitle>Personal Information</MainTitle>
          <CheckoutEmailForm
            email={result?.customerInfo?.email}
            addEmail={addEmail}
            handleError={onFormSubmitError}
          />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          <button
            type="submit"
            form="email-form"
            style={{ display: 'none' }}
            ref={submitButtonRef}
          />
        </FormWrapper>
      ) : (
        <>
          <PersonalData>
            {customerData?.firstName} {customerData?.lastName}
          </PersonalData>
          <PersonalData>{customerData?.email}</PersonalData>
        </>
      )}
    </OutletPage>
  );
};

export default PersonalInfo;
