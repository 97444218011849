import { useCallback, useEffect, useState } from 'react';

import {
  ProductAttributeType,
  ProductAttributeValueType,
} from 'types/ProductsTypes';
import { USClothingSizesOrder } from 'data/sizes';
import ProductDetailOptionSelect from 'components/Dropdowns/Selects/ProductDetailOptionSelect';
import { formatProductAttributeName } from 'helpers/formatters';
import { ProductAttributeName } from './styles';

const ProductDetailOption = ({
  productAttribute,
  selectAttributeValue,
  disabled,
}: {
  productAttribute: ProductAttributeType;
  selectAttributeValue: (value: ProductAttributeValueType) => void;
  disabled: boolean;
}) => {
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<
    ProductAttributeValueType | undefined
  >();
  const sortedAttributeValues = productAttribute
    ? [...productAttribute.values]?.sort((a, b) => {
        return (
          USClothingSizesOrder.indexOf(a.value.toLowerCase()) -
          USClothingSizesOrder.indexOf(b.value.toLowerCase())
        );
      })
    : [];

  const selectValue = useCallback((value: ProductAttributeValueType) => {
    setSelectedAttributeValue((prev: ProductAttributeValueType | undefined) => {
      if (
        prev?.productAttributeId === value.productAttributeId &&
        prev?.value === value.value
      ) {
        // unselect value
        return undefined;
      }

      return value;
    });

    selectAttributeValue(value);
  }, []);

  const handleClick = (value: ProductAttributeValueType) => {
    if (value.isDisabled) return;
    selectValue({ ...value, productAttributeId: productAttribute.id });
  };

  useEffect(() => {
    if (productAttribute.values && productAttribute.values.length) {
      const availableValues = productAttribute.values.filter(
        (value: ProductAttributeValueType) => !value.isDisabled,
      );

      if (availableValues.length === 1) {
        handleClick(availableValues[0]);
      }
    }
  }, []);

  return (
    <div
      style={{
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ProductAttributeName>
        {formatProductAttributeName(productAttribute)}
      </ProductAttributeName>
      <ProductDetailOptionSelect
        items={sortedAttributeValues}
        selectItem={handleClick}
        selectedAttributeValue={selectedAttributeValue}
        disabled={disabled}
      />
    </div>
  );
};

export default ProductDetailOption;
