import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import { AfterpayClearpayMessageElement } from '@stripe/react-stripe-js';
import { values } from 'lodash';

import { Typography, Loader, ColoredButton } from 'components';
import FavoriteIcon from 'components/FavoriteIcon/FavoriteIcon';
import ProductDetailOptions from 'components/ProductDetailsComponents/ProductDetailOptions';
import { ProductItemType, ProductVariantType } from 'types/ProductsTypes';
import { formatNameForUrl, formatPrice } from 'helpers/formatters';
import { useProductWishList } from 'hooks/useWishList';
import useDimensions from 'hooks/useDimensions';
import useGoogleTags from 'hooks/useGoogleTags';
import { useAddToCartConfirmationModal } from 'hooks/useModals';
import ROUTES from 'router/Routes';
import { COLORS } from 'styles';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getBasketError,
  getBasketLoader,
  getProduct,
} from 'eg_SFCC_FE_core/store/selectors';
import { productActions } from 'eg_SFCC_FE_core/store/reducers/slices/productSlice';
import {
  ArrowLeft,
  ArrowRight,
  QuickViewAfterpayMessageWrapper,
  QuickViewButtonsWrapper,
  QuickViewCarouselWrapper,
  QuickViewContentWrapper,
  QuickViewCrossIcon,
  QuickViewFavouriteIconWrapper,
  QuickViewWrapper,
  ReadMoreBtn,
} from './styles';

const QuickViewContent = ({
  closeModal,
  productId,
}: {
  closeModal: () => void;
  productId: string;
}) => {
  const [like, setLike] = useState<boolean>(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const product = useSelector<any, ProductItemType>(getProduct);
  const { updateCustomerProductWishList, isInWishList } = useProductWishList();
  const inWishList = product && isInWishList(product.id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useDimensions();
  const modalHeight = 530;
  const [selectedProductQuantity, setSelectedProductQuantity] =
    useState<number>(1);
  const [selectedProductVariant, setSelectedProductVariant] = useState<
    ProductVariantType | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const basketError = useSelector<any, any>(getBasketError);
  const isBasketLoading = useSelector<any, boolean>(getBasketLoader);
  const { trackEvent } = useGoogleTags();
  const { showAddToCartConfirmationModal } = useAddToCartConfirmationModal();
  const isMasterProduct = !!product?.master;
  const isItemOrderable =
    !!product?.inventory?.orderable &&
    (selectedProductVariant ? selectedProductVariant?.orderable : true);
  const itemPrice = selectedProductVariant
    ? selectedProductVariant.price
    : product?.price;

  const MAX_DESCRIPTION_LENGTH = 200;

  const handleReadMoreClick = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleFavoriteIcon = () => {
    const isWishListUpdated = updateCustomerProductWishList(
      product.id,
      selectedProductQuantity,
    );
    isWishListUpdated && setLike(!inWishList);
  };

  const fetchProduct = async () => {
    if (!productId) return;

    await dispatch(productActions.reset());
    await dispatch(AsyncThunks.getProductById(productId));
  };

  const viewProductDetails = () => {
    closeModal();
    navigate(
      `${ROUTES.PRODUCTS}/${formatNameForUrl(product.name)}-${productId}`,
    );
  };

  const trackAddToCartEvent = () => {
    trackEvent({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            currency: product.currency,
            item_brand: product.brand,
            item_variant:
              values(selectedProductVariant?.variationValues).join(',') || '',
            price: product.price || 0,
            quantity: selectedProductQuantity,
            primaryCategoryId: product.primaryCategoryId,
          },
        ],
      },
    });
  };

  const addToCartHandler = async () => {
    if (!product) return null;

    if (!selectedProductVariant?.productId && isMasterProduct) {
      setErrorMessage('Select product variant');
      return;
    }

    const productIdForBasket = isMasterProduct
      ? selectedProductVariant?.productId
      : product.id;

    const response = await dispatch(
      AsyncThunks.addItemToBasket([
        {
          productId: productIdForBasket || '',
          quantity: selectedProductQuantity,
        },
      ]),
    );

    // @ts-ignore
    if (!response.error) {
      trackAddToCartEvent();
      showAddToCartConfirmationModal(productIdForBasket || '');
    }
  };

  useEffect(() => {
    fetchProduct();

    return () => {
      setSelectedProductVariant(undefined);
    };
  }, []);

  useEffect(() => {
    setLike(inWishList);
  }, [inWishList]);

  useEffect(() => {
    basketError && setErrorMessage(basketError.detail);
  }, [basketError]);

  if (!product) return <Loader />;

  return (
    <QuickViewWrapper width={width} height={modalHeight}>
      <QuickViewCarouselWrapper>
        {product?.imageGroups?.length && (
          <Carousel
            NextIcon={<ArrowRight />}
            PrevIcon={<ArrowLeft />}
            stopAutoPlayOnHover
            navButtonsAlwaysVisible
            fullHeightHover={false}
            navButtonsWrapperProps={{
              style: {
                height: 'auto',
                top: 'auto',
                bottom: 25,
              },
            }}
            navButtonsProps={{
              style: {
                backgroundColor: COLORS.white,
                borderRadius: 0,
                height: 30,
                width: 30,
                padding: 0,
                margin: '0 25px 0',
              },
            }}
            indicatorContainerProps={{
              style: {
                width: '80%',
                position: 'absolute',
                bottom: 25,
                zIndex: 2,
                left: '50%',
                transform: 'translateX(-50%)',
              },
            }}
            indicatorIconButtonProps={{
              style: { color: 'rgba(255, 255, 255, 0.2)' },
            }}
            activeIndicatorIconButtonProps={{ style: { color: COLORS.white } }}
            sx={{ height: '100%', padding: '50px 33px 17px' }}
            height={modalHeight}
          >
            {product.imageGroups[0].images.map((image, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(${image.link})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    width: '100%',
                    height: '100%',
                    minHeight: 300,
                    maxHeight: modalHeight * 0.8,
                  }}
                />
              );
            })}
          </Carousel>
        )}
      </QuickViewCarouselWrapper>
      <QuickViewContentWrapper height={modalHeight}>
        <QuickViewCrossIcon onClick={closeModal} />
        <QuickViewFavouriteIconWrapper>
          <FavoriteIcon onClick={toggleFavoriteIcon} isFavorite={like} />
        </QuickViewFavouriteIconWrapper>
        <Typography
          type="title6Helv"
          style={{ marginBottom: 11, textTransform: 'uppercase', fontSize: 13 }}
        >
          {product.brand}
        </Typography>
        <Typography type="title6Helv" style={{ marginBottom: 3 }}>
          {product.name}
        </Typography>
        {itemPrice && (
          <>
            <Typography type="title6Helv">{formatPrice(itemPrice)}</Typography>
            <QuickViewAfterpayMessageWrapper>
              <AfterpayClearpayMessageElement
                options={{
                  amount: itemPrice * 100,
                  currency: 'USD',
                  lockupTheme: 'black',
                  logoType: 'lockup',
                }}
              />
            </QuickViewAfterpayMessageWrapper>
          </>
        )}

        <div
          data-bv-show="rating_summary"
          data-bv-product-id={product.id}
          id="data-bv-show"
        />

        <ProductDetailOptions
          product={product}
          selectedProductQuantity={selectedProductQuantity}
          selectProductQuantity={setSelectedProductQuantity}
          selectProductVariant={setSelectedProductVariant}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isItemOrderable={isItemOrderable}
        />
        <QuickViewButtonsWrapper>
          <ColoredButton
            style={{ width: '100%', height: 33 }}
            fontStyle={{ fontSize: 12 }}
            aria-label="view details"
            onClick={viewProductDetails}
          >
            view details
          </ColoredButton>
          <ColoredButton
            style={{ width: '100%', height: 33 }}
            fontStyle={{ fontSize: 12 }}
            aria-label="add to cart"
            onClick={addToCartHandler}
            isLoading={isBasketLoading}
            disabled={!isItemOrderable || !!errorMessage}
          >
            add to cart
          </ColoredButton>
        </QuickViewButtonsWrapper>

        <div style={{ display: 'flex' }}>
          <Typography
            type="xsmallHelv"
            style={{
              fontSize: 11,
              height: showFullDescription ? 'auto' : 27,
              overflow: 'hidden',
            }}
            dangerouslySetInnerHTML={{ __html: product.shortDescription || '' }}
          />
        </div>
        {product?.shortDescription &&
          product.shortDescription.length > MAX_DESCRIPTION_LENGTH && (
            <ReadMoreBtn onClick={handleReadMoreClick}>
              {showFullDescription ? 'Read less...' : 'Read more...'}
            </ReadMoreBtn>
          )}
      </QuickViewContentWrapper>
    </QuickViewWrapper>
  );
};

export default QuickViewContent;
