/* eslint-disable import/no-dynamic-require */
import { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  General,
  Orders,
  ReturnsExchanges,
  ShippingDelivery,
} from 'components/InfoPagesComponents/Templates';
import { AnimatedWrapper } from 'components';
import InfoPageWrapper from 'components/InfoPagesComponents/InfoPageWrapper';
import InfoPageText from 'components/InfoPagesComponents/InfoPageText';
import InfoPageMenu from 'components/InfoPagesComponents/InfoPageMenu';
import ContactInfoBlock from 'components/InfoPagesComponents/ContactInfoBlock/ContactInfoBlock';

export type MenuItemType = {
  title: string;
  content: ReactNode;
};

export const faqMenu: MenuItemType[] = [
  {
    title: 'GENERAL',
    content: <General />,
  },
  {
    title: 'ORDERS',
    content: <Orders />,
  },
  {
    title: 'SHIPPING & DELIVERY',
    content: <ShippingDelivery />,
  },
  {
    title: 'RETURNS & EXCHANGES',
    content: <ReturnsExchanges />,
  },
];

const FaqPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(faqMenu[0]);

  return (
    <InfoPageWrapper title="FAQ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Frequently Asked Questions</title>
        <meta name="description" content="Frequently Asked Questions" />
      </Helmet>

      <InfoPageMenu
        menu={faqMenu}
        currentMenuItem={currentMenuItem}
        setCurrentMenuItem={setCurrentMenuItem}
      />
      <AnimatedWrapper animationKey={currentMenuItem.title}>
        <InfoPageText title="faq">{currentMenuItem.content}</InfoPageText>
      </AnimatedWrapper>

      <ContactInfoBlock />
    </InfoPageWrapper>
  );
};

export default FaqPage;
