import { useState, ChangeEvent, useEffect } from 'react';
import { Box } from '@mui/material';

import { BasicCheckbox } from 'components';
import { ProductItemsType } from 'types/OrdersTypes';
import OrderCardItem from '../OrderCardItem';

const ReturnItem = ({
  item,
  handleChange,
}: {
  item: ProductItemsType;
  handleChange: any;
}) => {
  const maxQuantity = item.quantity - item.c_cancelledQuantity;
  const [isChecked, setIsChecked] = useState(false);
  const [quantity, setQuantity] = useState<number>(maxQuantity > 0 ? 1 : 0);
  const isItemReturnable = maxQuantity && item.c_returnStatus?.eligible;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    handleChange({
      id: item.itemId,
      qty: quantity,
      isChecked,
    });
  }, [isChecked, quantity]);

  return (
    <Box display="flex" alignItems="center" sx={{ margin: '10px 0 14px' }}>
      {isItemReturnable ? (
        <BasicCheckbox
          checked={isChecked}
          handleChange={handleCheckboxChange}
        />
      ) : (
        <div style={{ width: 36 }} />
      )}
      <Box marginLeft="2vw" flexGrow={1}>
        <OrderCardItem
          productItem={item}
          maxQuantity={maxQuantity}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      </Box>
    </Box>
  );
};

export default ReturnItem;
