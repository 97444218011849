import { Typography } from 'components';
import { StyledColoredButton, ConfirmationWrapper } from './styles';

const InfoContent = ({
  unSetModal,
  text,
  cb,
}: {
  unSetModal: () => void;
  text: string;
  cb?: () => void;
}) => {
  const handleClick = () => {
    unSetModal();
    cb && cb();
  };

  return (
    <ConfirmationWrapper style={{ backgroundColor: '#F6F6F6' }}>
      <Typography type="subtitle1">{text}</Typography>
      <div>
        <StyledColoredButton onClick={handleClick}>Close</StyledColoredButton>
      </div>
    </ConfirmationWrapper>
  );
};

export default InfoContent;
