import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { ErrorResponse } from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import { RootState } from 'eg_SFCC_FE_core/store/reducers/rootReducer';
import { SavedSearchQueryType } from 'types/AccountTypes';

export const saveSearchResultThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse; state: RootState }
> = async (q, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const data = {
      c_action: 'save_search',
      c_search_info: {
        c_q: q,
        c_name: q,
      },
    };

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const updateSavedSearchResultThunk: AsyncThunkPayloadCreator<
  any,
  { id: string; q: string },
  { rejectValue: ErrorResponse; state: RootState }
> = async ({ id, q }, { rejectWithValue, getState }) => {
  if (!q) return;

  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const data = {
      c_action: 'update_search',
      c_search_info: {
        c_id: id,
        c_q: q,
        c_name: q,
      },
    };

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const removeSavedSearchThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse; state: RootState }
> = async (id, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const { customerId } = state.customerReducer;

    const data = {
      c_action: 'remove_saved_search',
      c_search_info: {
        c_id: id,
      },
    };

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return {
      customerData: response.data,
      removedSearchQueryId: id,
    };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getSavedSearchResultThunk: AsyncThunkPayloadCreator<
  any,
  SavedSearchQueryType,
  { rejectValue: ErrorResponse }
> = async (savedSearchQuery, { rejectWithValue }) => {
  try {
    const params = new URLSearchParams();

    params.append('q', savedSearchQuery.c_q);
    params.append('offset', '0');
    params.append('limit', '3');
    params.append('siteId', process.env.REACT_APP_SITE_ID || '');

    const response = await axiosInstance.get(endpoints.search.product, {
      params,
    });

    return {
      ...response.data,
      savedSearchQueryId: savedSearchQuery.c_id,
    };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
