import styled from 'styled-components';

import { ColoredButton, Typography } from 'components';
import { BREAKPOINTS, FONTS } from 'styles';

export const ReturnCardWrapper = styled.div`
  position: relative;
`;

export const BrandName = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  font-family: ${FONTS.Helvetica.medium};
  margin: 16px 0;
  text-transform: uppercase;
`;

export const Message = styled(Typography).attrs({
  color: 'accent',
  type: 'xsmallHelv',
})`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 100px;
  position: absolute;
  right: 0;
  bottom: -33px;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    position: static;
    margin: 20px 0;
  }
`;
