import { Dispatch, SetStateAction } from 'react';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import {
  Address,
  AddressBlockWrapper,
  AddressHeaderWrapper,
  ActionButton,
  AddressTitle,
} from './styles';

const AddressItem = ({
  address,
  selectAddress,
  index,
  useAddressActions,
  handleEdit,
  useAddressCheckbox,
  handleAddressCheck,
  checkedAddress,
}: {
  address: CustomerAddressType;
  selectAddress?: (address: CustomerAddressType | null) => void;
  index?: number;
  useAddressActions?: boolean;
  handleEdit?: (param: any) => void;
  useAddressCheckbox?: boolean;
  handleAddressCheck?: Dispatch<
    SetStateAction<CustomerAddressType | undefined>
  >;
  checkedAddress?: CustomerAddressType | null;
}) => {
  const isDefaultAddress = address.preferred;

  const handleMakeDefault = () => {
    if (isDefaultAddress) {
      selectAddress && selectAddress(null);
    } else {
      selectAddress && selectAddress(address);
    }
  };

  const handleEditIconClick = () => {
    if (handleEdit) {
      handleEdit(address);
    }
  };

  const handleCheck = () => {
    handleAddressCheck && handleAddressCheck(address);
  };

  return (
    <AddressBlockWrapper
      useAddressCheckbox={useAddressCheckbox}
      checkedAddress={checkedAddress?.addressId === address.addressId}
      onClick={handleCheck}
    >
      <AddressHeaderWrapper>
        {index?.toString() && <AddressTitle>{address.fullName}</AddressTitle>}
        {useAddressActions ? (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <ActionButton onClick={handleEditIconClick}>edit</ActionButton>
            <ActionButton
              disabled={isDefaultAddress}
              style={{ margin: 0 }}
              onClick={handleMakeDefault}
            >
              {isDefaultAddress ? 'Default address' : 'Set as default address'}
            </ActionButton>
          </div>
        ) : null}
      </AddressHeaderWrapper>
      <Address>
        {address.address1}
        {address.address2 ? (
          <>
            <br />
            {address.address2}
          </>
        ) : null}
        <br />
        {address.city}, {address.stateCode} {address.postalCode} <br />
        {address.phone}
      </Address>
    </AddressBlockWrapper>
  );
};

export default AddressItem;
