import { MainTitle, Paragraph } from '../styles';

const ChildrensInfo = () => {
  return (
    <>
      <MainTitle>CHILDREN’S INFORMATION</MainTitle>
      <Paragraph>
        The Services are not directed to children under 16 (or other age as
        required by local law), and we do not knowingly collect personal
        information from children. If you are a child under the age of 16,
        please do not attempt to register for or otherwise use the Services or
        send us any Personal Information. If you believe your child under 16
        years of age may have provided Personal Information to us, please
        contact us at legal@EmpowerGlobal.shop or as otherwise described in the
        “Contact Us” section below. If we become aware that a child under 16
        years of age has provided us with Personal Information, we will delete
        any Personal Information we have collected as quickly as possible,
        unless we have a legal obligation to keep it, and terminate the child’s
        account, if applicable.
      </Paragraph>
      <Paragraph>
        If you believe your child under 16 years of age may have provided
        Personal Information to us, please contact us at{' '}
        <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>{' '}
        or as otherwise described in the “Contact Us” section below. If we
        become aware that a child under 16 years of age has provided us with
        Personal Information, we will delete any Personal Information we have
        collected as quickly as possible, unless we have a legal obligation to
        keep it, and terminate the child’s account, if applicable.
      </Paragraph>
    </>
  );
};

export default ChildrensInfo;
