import { useEffect, useState } from 'react';

import { AnimatedLinkUpdated, Typography } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import ROUTES from 'router/Routes';
import useDimensions from 'hooks/useDimensions';
import { BREAKPOINTS } from 'styles';
import { BlockWrapper, ProductCardWrapper, ProductsWrapper } from './styles';

const FeaturedProducts = ({
  productList,
  brand: { brandId, brandName },
}: {
  productList: any[];
  brand: { brandId: string; brandName: string };
}) => {
  const [cardNum, setCardNum] = useState<number>(5);
  const { width } = useDimensions();
  const products = productList?.slice(0, cardNum);

  useEffect(() => {
    if (width < BREAKPOINTS.m) {
      setCardNum(4);
    } else if (width < BREAKPOINTS.s) {
      setCardNum(3);
    } else {
      setCardNum(5);
    }
  }, [width]);

  return (
    <BlockWrapper>
      <ProductsWrapper>
        {products?.length ? (
          products?.map((product: any) => {
            return (
              <ProductCardWrapper key={product.productId}>
                <ProductCard product={product} />
              </ProductCardWrapper>
            );
          })
        ) : (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography>This seller has no products for now</Typography>
          </div>
        )}
      </ProductsWrapper>
      {products?.length && brandName ? (
        <AnimatedLinkUpdated
          to={`${ROUTES.PRODUCTS}/shop/seller?c_marketplacerSellerName=${brandName}`}
        >
          SHOP MORE FROM BRAND
        </AnimatedLinkUpdated>
      ) : null}
    </BlockWrapper>
  );
};

export default FeaturedProducts;
