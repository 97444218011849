import ROUTES from 'router/Routes';

export const LOCAL_STORAGE_KEYS = {
  usid: 'usid',
  codeVerifier: 'codeVerifier',
};

export const SESSION_STORAGE_KEYS = {
  splashPageShown: 'splashPageShown',
};

export const environmentUrls = {
  production: 'https://empowerglobal.shop',
};

export const isProd = window.location.origin === environmentUrls.production;

export const googleTagsAffiliation = 'Empower Global';
export const isDev =
  window.location.origin === 'https://dev.shopwitheg.com' ||
  process.env.NODE_ENV === 'development';

export const externalUrls = {
  aaaRules:
    'https://www.adr.org/sites/default/files/document_repository/Consumer_Arbitration_Rules_Web.pdf',
};

export const CARD_ICONS = {
  mastercard: 'assets/jpg/card-master.jpg',
  amex: 'assets/jpg/card-amex.jpg',
  discover: 'assets/jpg/card-discover.jpg',
  visa: 'assets/jpg/card-visa.jpg',
};

export const APP_GENDER = ['men', 'women', 'unisex'];
export const GOOGLE_REDIRECT_URI = `${process.env.REACT_APP_SFRA_HOST}${ROUTES.ACCEPT_REDIRECT}`;
