import { useState } from 'react';
import ROUTES from 'router/Routes';
import { useLocation } from 'react-router-dom';
import { BottomBarContainer, StyledCrossIcon } from './styles';

const HeaderBottomBar = () => {
  const { pathname } = useLocation();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const isCheckoutPage = pathname.indexOf(ROUTES.CHECKOUT) !== -1;
  const handleClose = () => {
    setIsClosed(true);
  };

  return (
    <BottomBarContainer
      style={{ display: isClosed || isCheckoutPage ? 'none' : 'flex' }}
    >
      40% OFF ENTIRE SITE + FREE SHIPPING (NO CODE NEEDED - DISCOUNT APPLIED AT
      CHECKOUT)
      <StyledCrossIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
    </BottomBarContainer>
  );
};

export default HeaderBottomBar;
