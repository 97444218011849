import { Typography, ColoredButton } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const ProgramSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.6fr 1.2fr;
  gap: 50px;
  padding: 50px 90px;
  background: ${COLORS.white};

  @media (max-width: 1350px) {
    padding: 50px 40px;
    gap: 30px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const TextWithShadow = styled.h2`
  text-transform: uppercase;
  font-size: 6.5vw;
  line-height: 6.5vw;
  font-family: ${FONTS.Bison.bold};
  -webkit-text-stroke: 1px ${COLORS.accent};
  -webkit-text-fill-color: transparent;
  text-shadow: -2px -3px 0px ${COLORS.black};

  @media (max-width: 1000px) {
    font-size: 12vw;
    line-height: 12vw;
  }
`;

export const JoinText = styled.h3`
  text-transform: uppercase;
  font-size: 2vw;
  line-height: 2vw;
  font-family: ${FONTS.TTNorms.medium};

  @media (max-width: 1000px) {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
`;

export const JoinDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const JoinDescriptionText = styled(Typography).attrs({
  type: 'button',
})`
  font-size: 1.2vw;
  line-height: 1.4vw;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    font-size: 2.5vw;
    line-height: 3vw;
  }
`;

export const JoinColoredButton = styled(ColoredButton)`
  width: 250px;
  height: 60px;
  background-color: ${COLORS.accent};

  @media (max-width: 1350px) {
    width: 170px;
    height: 40px;
  }

  & p {
    color: ${COLORS.black};
    font-family: ${FONTS.TTNorms.medium};
  }

  &:hover {
    background: ${COLORS.primary};
  }
`;
