import styled from 'styled-components';
import {
  styled as materialStyled,
  ListItemText,
  TextField,
  MenuItem,
} from '@mui/material';

import { Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const StyledTextField = materialStyled(TextField)({
  '& .MuiInputBase-root': {
    padding: '15px 20px',
    borderBottom: '1px solid #E3E3E3',
    color: COLORS.black,
    fontFamily: FONTS.TTNorms.light,

    '&:before, &:after, &:hover:not(.Mui-disabled):before': { border: 'none' },
  },
});

export const ListItemsWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
  padding: 15px 4px;
`;

export const StyledMenuItem = materialStyled(MenuItem)({
  '&:hover': { backgroundColor: 'transparent' },
  '& .MuiTypography-root': {
    fontSize: 14,
  },
});

export const SelectOptionLabel = styled(Typography)`
  font-size: 14px;
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const SelectedAddressLabel = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  opacity: 0.6;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const StyledListItemText = materialStyled(ListItemText)(
  ({ selected }: { selected?: boolean }) => ({
    '& .MuiTypography-root': {
      fontFamily: FONTS.Helvetica.regular,
      fontSize: 14,
      textTransform: 'capitalize',
      color: selected ? COLORS.accent : 'auto',

      '&:hover': {
        color: COLORS.accent,
        transition: '0.3s',
      },
    },
  }),
);

export const ResetButton = styled.button`
  width: 100%;
  background: ${COLORS.white};
  text-align: left;
  cursor: pointer;

  &:hover {
    color: ${COLORS.accent};
    transition: '0.3s';
  }

  & p {
    margin: 15px 20px;
  }
`;

export const ResetButtonText = styled(Typography).attrs({
  type: 'button',
})`
  text-transform: uppercase;
  font-size: 15px;
  line-height: 18px;
  color: ${COLORS.black};

  &:hover {
    color: ${COLORS.accent};
  }
`;

export const AccountMail = styled(Typography).attrs({
  type: 'subtitle3',
})`
  font-weight: 500;
  padding-bottom: 19px;
`;

export const ProfileSubTitle = styled(Typography).attrs({
  type: 'body',
})`
  font-size: 14px;
  font-weight: 400;
  padding-right: 9px;
`;

export const LogoutWrapper = materialStyled(MenuItem)`
  &:hover * {
    color: ${COLORS.accent};
    transition: 0.3s;
  }
`;

export const EllipsisText = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const PaymentCardText = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.textGrey};
`;
