import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { RotatedMinus, RotatedPlus, ShippingAddressTitle } from './styles';

const ShippingAddressAccordion = ({
  children,
  setIsDiffAddressUsed,
}: {
  children: ReactNode;
  setIsDiffAddressUsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
    setIsDiffAddressUsed((prevExpanded) => !prevExpanded);
  };

  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={
        { unmountOnExit: true, mountOnEnter: true } as TransitionProps
      }
      sx={{
        '&.MuiAccordion-root:before': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <AccordionSummary
        aria-controls="shipping-address-content"
        id="shipping-address-header"
        sx={{
          padding: 0,
          flexDirection: 'row-reverse',
        }}
      >
        <div>
          {expanded ? (
            <RotatedMinus style={{ left: 0 }} />
          ) : (
            <RotatedPlus style={{ left: 0 }} />
          )}
        </div>
        <ShippingAddressTitle>Use a different address</ShippingAddressTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default ShippingAddressAccordion;
