import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from 'components';
import ReturnCard from 'components/OrdersComponents/ReturnsComponents/ReturnCard';
import { getOrderDetails } from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { orderActions } from 'eg_SFCC_FE_core/store/reducers/slices/orderSlice';
import { GroupedByBrandProductItems, ReturnPlisInfo } from 'types/OrdersTypes';
import ROUTES from 'router/Routes';
import {
  RightBlockWrapper,
  BlockTitle,
  OrderNumber,
  DescReturnsText,
} from './styles';

const OrderReturnsPage = () => {
  const dispatch = useDispatch();
  const { orderNo } = useParams();
  const order = useSelector(getOrderDetails);
  const navigate = useNavigate();

  const groupedItems: GroupedByBrandProductItems[] | undefined =
    order?.productItems?.reduce<GroupedByBrandProductItems[]>(
      (result, currentValue) => {
        const sellerId = currentValue.c_marketplacerSellerId;
        const existingSeller = result.find(
          (seller) => seller.c_marketplacerSellerId === sellerId,
        );

        if (existingSeller) {
          existingSeller.items.push(currentValue);
        } else {
          result.push({
            c_marketplacerSellerId: sellerId,
            c_marketplacerSellerName: currentValue.c_marketplacerSellerName,
            items: [currentValue],
          });
        }

        return result;
      },
      [],
    );

  const fetchOrder = async () => {
    orderNo && (await dispatch(AsyncThunks.getCustomerOrderById(orderNo)));
  };

  const handleReturnButtonClick = useCallback(
    (checkedItems: ReturnPlisInfo[]) => {
      dispatch(
        orderActions.updatedReturnParams({
          c_action: 'returnProductLineItems',
          c_plis_info: checkedItems.map(({ id, qty }: ReturnPlisInfo) => ({
            id,
            qty,
          })),
          c_orderNo: order?.orderNo,
        }),
      );
      navigate(`${ROUTES.ACCOUNT_MY_ORDERS}/${orderNo}/return-reason`);
    },
    [order],
  );

  useEffect(() => {
    fetchOrder();
  }, []);

  if (order?.orderNo !== orderNo) {
    return <Loader />;
  }

  return (
    <RightBlockWrapper style={{ maxWidth: 900 }}>
      <BlockTitle>Returns</BlockTitle>
      <OrderNumber>{orderNo}</OrderNumber>
      <DescReturnsText>CHOOSE ITEMS TO RETURN</DescReturnsText>
      {groupedItems?.map((group: GroupedByBrandProductItems) => {
        return (
          <ReturnCard
            key={group.c_marketplacerSellerId}
            group={group}
            handleReturnButtonClick={handleReturnButtonClick}
          />
        );
      })}
    </RightBlockWrapper>
  );
};

export default OrderReturnsPage;
