import { Typography } from 'components';
import { SortingOptionType } from 'types/ProductsTypes';
import { find } from 'lodash';
import BasicSelect from './BasicSelect';
import { StyledMenuItem, StyledListItemText } from '../styles';

const ProductSortSelect = ({
  label,
  items,
  setSortingId,
  sortingId,
}: {
  label: string;
  items: SortingOptionType[];
  setSortingId: (id: string) => void;
  sortingId: string;
}) => {
  const optionValues = [find(items, { id: sortingId })?.label || ''];

  const handleClick = (_: any, value: { id: string; label: string }) => {
    setSortingId(value.id);
  };

  const renderLabel = () => (
    <Typography
      type="body"
      style={{
        opacity: 0.7,
        lineHeight: 'normal',
        textTransform: 'capitalize',
      }}
    >
      {optionValues[0] || label}
    </Typography>
  );

  if (!items) return null;

  return (
    <BasicSelect
      renderLabel={renderLabel}
      optionValues={optionValues}
      variant="outlined"
      formControlSx={{ width: 300, alignSelf: 'flex-end' }}
    >
      {items.map((item: SortingOptionType) => (
        <StyledMenuItem
          value={item.label}
          onClick={(event: any) => handleClick(event, item)}
          key={item.id}
        >
          <StyledListItemText primary={item.label}>
            {item.label}
          </StyledListItemText>
        </StyledMenuItem>
      ))}
    </BasicSelect>
  );
};

export default ProductSortSelect;
