const endpoints = {
  shopper: {
    publicGuestLoginCode: `/shopper/auth/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/oauth2/authorize`,
    publicGuestLoginJwtToken: `/shopper/auth/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/oauth2/token`,
    authenticateCustomer: `/shopper/auth/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/oauth2/login`,
    logoutCustomer: `/shopper/auth/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/oauth2/logout`,
    userInfo: `/shopper/auth/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/oauth2/userinfo`,
  },
  customer: {
    customer: `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers`,
    customerProductLists: (customerId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/product-lists`,
    customerProductListItem: (customerId: string, listId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/product-lists/${listId}/items`,
    customerBaskets: (customerId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/baskets`,
    customerAddress: (customerId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/addresses`,
    customerOrders: (customerId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/orders`,
    customerPassword: (customerId: string) =>
      `/customer/shopper-customers/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/customers/${customerId}/password`,
  },
  shopperProducts: {
    categories: `/product/shopper-products/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/categories`,
    category: (id: string) =>
      `/product/shopper-products/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/categories/${id}`,
    products: `/product/shopper-products/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/products`,
    product: (id: string) =>
      `/product/shopper-products/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/products/${id}`,
  },
  basket: {
    createBasket: `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets`,
    basketItems: (basketId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/items`,
    billingAddress: (basketId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/billing-address`,
    shippingAddress: (basketId: string, shipmentId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/shipments/${shipmentId}/shipping-address`,
    shippingMethod: (basketId: string, shipmentId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/shipments/${shipmentId}/shipping-method`,
    shippingMethods: (basketId: string, shipmentId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/shipments/${shipmentId}/shipping-methods`,
    paymentMethods: (basketId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/payment-methods`,
    paymentInstrument: (basketId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/payment-instruments`,
    coupons: (basketId: string) =>
      `/checkout/shopper-baskets/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/baskets/${basketId}/coupons`,
  },
  orders: {
    orders: `/checkout/shopper-orders/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/orders`,
    ordersPaymentInstrument: (orderNo: string, paymentInstrumentId: string) =>
      `/checkout/shopper-orders/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/orders/${orderNo}/payment-instruments/${paymentInstrumentId}`,
  },
  search: {
    product: `/search/shopper-search/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/product-search`,
    searchSuggestions: `/search/shopper-search/v1/organizations/${process.env.REACT_APP_ORGANIZATION_ID}/search-suggestions`,
  },
  sellers: {
    sellers: `${process.env.REACT_APP_SFRA_HOST}/sfra/api/sellers`,
  },
  reset: {
    sendEmail: `${process.env.REACT_APP_SFRA_HOST}/sfra/api/send-reset-email`,
    resetPassword: `${process.env.REACT_APP_SFRA_HOST}/sfra/api/reset-password`,
  },
  contactUsForms: `${process.env.REACT_APP_SFRA_HOST}/sfra/api/send-form`,
  CSRFToken: `${process.env.REACT_APP_SFRA_HOST}/sfra/api/csrf/generate`,
};

export default endpoints;
