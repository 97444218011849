import AnimatedLink from 'components/Links/AnimatedLink';
import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';
import { FONTS } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  /* this removes space between slides */
  .slick-slide {
    margin: 0;
  }
`;

export const OverlayContentsWrapper = styled.div`
  width: 92%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  z-index: 1;

  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const OverlayContent = styled.div`
  max-height: 400px;
  height: 100%;
  padding: 30px;
  display: grid;
  place-items: center;
  background: #cfc7bf;
  backdrop-filter: blur(4px);

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const MobileImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: 2;
`;

export const MobileImage = styled.img`
  width: 100%;
  height: 320px;
  &:nth-of-type(1n + 5) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 240px;
  height: 360px;
  object-fit: cover;
`;

export const Title = styled(Typography)`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    font-size: 32px;
  }
  @media (max-width: 1000px) {
    font-size: 30px;
  }
  @media (max-width: 460px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled(Typography).attrs({
  type: 'subtitle3',
})`
  text-align: center;
  margin-bottom: 22px;

  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

export const StyledAnimatedLink = styled(AnimatedLink).attrs({
  type: 'black',
})`
  font-size: 28px;
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;
