export const team = [
  {
    id: '2',
    fullName: 'Brittany Bullock',
    jobTitle: 'Director of Strategic Program Management',
    image: 'assets/jpg/about/team/brittany-bullock.jpg',
  },
  {
    id: '3',
    fullName: 'Charese Woods',
    jobTitle: 'Director of Marketplace Operations',
    image: 'assets/jpg/about/team/charse-woods.jpg',
  },
  {
    id: '4',
    fullName: 'Amanda Stewart-White',
    jobTitle: 'Director of Brand Marketing',
    image: 'assets/jpg/about/team/amanda-stewart-white.jpg',
  },
  {
    id: '5',
    fullName: 'Emma Jackson',
    jobTitle: 'Production Manager',
    image: 'assets/jpg/about/team/emma-jackson.jpg',
  },
  {
    id: '6',
    fullName: 'Krystal Franklin',
    jobTitle: 'Editorial and Content Director',
    image: 'assets/jpg/about/team/krystal-franklin.jpg',
  },
  {
    id: '7',
    fullName: 'Basil Kuloba',
    jobTitle: 'Product Manager',
    image: 'assets/jpg/about/team/basil-kuloba.jpg',
  },
  {
    id: '8',
    fullName: 'Kimberly Hutton',
    jobTitle: 'Ecommerce Director',
    image: 'assets/jpg/about/team/kimberly-hutton.jpg',
  },
  {
    id: '9',
    fullName: 'Jesse Ngolab',
    jobTitle: 'DevOps Engineer',
    image: 'assets/jpg/about/team/jesse-ngolab.jpg',
  },
  {
    id: '10',
    fullName: 'Kaila Epps',
    jobTitle: 'Internal Operations Manager',
    image: 'assets/jpg/about/team/kaila-epps.jpeg',
  },
  {
    id: '11',
    fullName: 'Jazondre Gibbs',
    jobTitle: 'Director of Strategic Partnerships',
    image: 'assets/jpg/about/team/jazondre-gibbs.jpg',
  },
];
