import { createSlice } from '@reduxjs/toolkit';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { BrandType } from 'types/BrandsTypes';

export const BRAND_LIST_SLICE_NAME = 'brandList';

type BrandListStateType = StateType & {
  brandList: BrandType[];
};

const initialState: BrandListStateType = {
  pending: false,
  error: null,
  result: [],
  brandList: [],
};

const brandListSlice = createSlice({
  name: BRAND_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getBrandList.pending, onPending);
    builder.addCase(AsyncThunks.getBrandList.fulfilled, (state, action) => {
      state.brandList = action.payload?.sellers
        .reduce((unique: any, o: any) => {
          if (!unique.some((obj: any) => obj.businessName === o.businessName)) {
            unique.push(o);
          }
          return unique;
        }, [])
        .sort((a: any, b: any) => a.businessName.localeCompare(b.businessName));
      state.pending = false;
      state.error = null;
    });
    builder.addCase(AsyncThunks.getBrandList.rejected, onError);
  },
});

export const brandsListActions = brandListSlice.actions;
export const brandsListReducer = brandListSlice.reducer;
