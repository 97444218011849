const ROUTES = {
  HOME: '/',
  EXPLORE_ALL: '/explore-all',
  ABOUT: '/about',
  SELLER: '/seller',
  BRANDS: '/brands',
  BRAND_LANDING: '/brands/:brandId',
  PRODUCTS: '/products',
  PRODUCT_LISTING_BY_CATEGORY: '/products/:categoryName/:categoryId',
  PRODUCTS_DETAILS: '/products/:productId',
  FAVORITES: '/favorites',
  CART: '/cart',
  CHECKOUT: '/checkout',
  CHECKOUT_ACCOUNT_SETUP: '/checkout/account-setup',
  CHECKOUT_DETAILS: '/checkout/details',
  CHECKOUT_PERSONAL_INFO: '/checkout/personal-info',
  CHECKOUT_SHIPPING: '/checkout/shipping',
  CHECKOUT_PAYMENT: '/checkout/payment',
  CHECKOUT_CONFIRMATION: '/checkout/confirmation',
  WISHLIST: '/wishlist',
  SEARCH: '/search',
  ACCOUNT: '/account',
  ACCOUNT_PERSONAL_INFO: '/account/personal-information',
  ACCOUNT_RESET_PASSWORD: '/account/reset-password',
  ACCOUNT_MY_ORDERS: '/account/my-orders',
  ACCOUNT_ORDER_RETURNS: '/account/my-orders/:orderNo/returns',
  ACCOUNT_ORDER_RETURNS_REASON: '/account/my-orders/:orderNo/return-reason',
  ACCOUNT_ORDER_RETURNS_ADDRESSES:
    '/account/my-orders/:orderNo/return-addresses',
  ACCOUNT_ORDER_RETURNS_CONFIRM:
    '/account/my-orders/:orderNo/return-confirmation',
  ACCOUNT_ADDRESSES: '/account/addresses',
  ACCOUNT_PAYMENTS: 'account/payments',
  ACCOUNT_LOYALTY: '/account/loyalty',
  ACCOUNT_SAVED_SEARCHES: '/account/saved-searches',
  PRIVACY_POLICY: '/privacy-policy',
  SHIPPING_RETURNS: '/shipping-returns',
  TERMS_CONDITIONS: '/terms-conditions',
  CONTACT_US: '/contact-us',
  CONTACT_US_HELP: '/contact-us/help',
  CONTACT_US_COMMUNITY: '/contact-us/community',
  FAQ: '/faq',
  LOYALTY_PROGRAM: '/loyalty-program',
  EVENTS_CURRENT: '/empower-events',
  EVENTS_PAST: '/past-events',
  CAREERS: '/careers',
  CAREER_DETAILS: '/careers/:careerId',
  RESET_PASSWORD: '/reset-password',
  ACCEPT_REDIRECT: '/google-callback',
  COOKIES_PREFERENCES: '/cookies-preferences',
  NOT_FOUND: '/not-found',
};

export default ROUTES;
