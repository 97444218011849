import { ReactNode } from 'react';
import { CustomNavLink } from 'components';
import { LinkTo } from './styles';

interface IFooterLink {
  children: ReactNode;
  to: string;
  className?: string;
  id?: string;
}

const FooterLink = ({ children, to, className, id }: IFooterLink) => (
  <CustomNavLink to={to}>
    <LinkTo className={className} id={id}>
      {children}
    </LinkTo>
  </CustomNavLink>
);

export default FooterLink;
