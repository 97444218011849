import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { ProductCategoryType, ProductType } from 'types/ProductsTypes';

export const PRODUCT_LIST_SLICE_NAME = 'productList';

type ProductListStateType = StateType & {
  categoryId: string;
  productList: ProductType[];
  total: number;
  categories: ProductCategoryType[];
};

const initialState: ProductListStateType = {
  pending: false,
  error: null,
  result: [],
  categoryId: '',
  productList: [],
  total: 0,
  categories: [],
};

const productListSlice = createSlice({
  name: PRODUCT_LIST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getProductListByCategoryId.pending, onPending);
    builder.addCase(
      AsyncThunks.getProductListByCategoryId.fulfilled,
      (state, action) => {
        state.total = action.payload.total;
        state.result = omit(action.payload, ['hits', 'total']);
        state.productList = action.payload.hits;
        state.categoryId = action.payload.categoryId;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getProductListByCategoryId.rejected, onError);

    builder.addCase(AsyncThunks.getProductListCategories.pending, onPending);
    builder.addCase(
      AsyncThunks.getProductListCategories.fulfilled,
      (state, action) => {
        const fetchedCategories = action.payload;
        state.categories = fetchedCategories.map(
          (category: { id: string; name: string }, index: number) => {
            return {
              ...category,
              name: category.id === 'seller' ? 'Shop' : category.name,
            };
          },
        );
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getProductListCategories.rejected, onError);
  },
});

export const productListActions = productListSlice.actions;
export const productListReducer = productListSlice.reducer;
