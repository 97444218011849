import { CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import {
  BackgroundImage,
  ContentsWrapper,
  StyledColoredButton,
  Title,
  WhiteLogo,
  Wrapper,
} from './styles';

const CallToAction = () => {
  return (
    <Wrapper>
      <BackgroundImage
        src={require('../../../assets/png/explore/last-image.png')}
      />
      <ContentsWrapper>
        <Title>
          po
          <WhiteLogo src={require('../../../assets/png/white-logo.png')} />
          er the black economy
        </Title>
        <CustomNavLink to={ROUTES.CONTACT_US}>
          <StyledColoredButton aria-label="contact us">
            contact us
          </StyledColoredButton>
        </CustomNavLink>
      </ContentsWrapper>
    </Wrapper>
  );
};

export default CallToAction;
