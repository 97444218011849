import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { ColoredButton, CustomNavLink, Typography } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const AuthFormWrapper = styled.form<{
  isLogin?: boolean;
}>`
  max-width: ${({ isLogin }) => (isLogin ? 360 : 500)}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 292px;
  }

  &.fade-enter {
    opacity: 0;
    z-index: 1;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.fade-exit {
    opacity: 1;
    z-index: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const AuthButton = styled(ColoredButton)`
  width: 100%;

  & p {
    font-family: ${FONTS.Helvetica.bold} !important;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    height: 23px;

    & p {
      font-size: 8px;
    }
  }
`;

export const AccentLink = styled(CustomNavLink)`
  text-decoration: none;
  color: ${COLORS.updatedAccentBackground};
  font-size: 12px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;

export const StyledTextField = styled(TextField).attrs({
  variant: 'outlined',
  fullWidth: true,
  size: 'small',
  margin: 'dense',
})({
  '& input': {
    borderColor: COLORS.black,
    padding: '6px 12px',
    height: 19,
    fontSize: 14,

    '@media (max-width: 1024px)': {
      fontSize: 12,
    },
  },
  '& .MuiSelect-select': {
    padding: '4px 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.black,
  },

  '& .MuiOutlinedInput-input': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '& .MuiOutlinedInput-root': {
    fontFamily: `${FONTS.Helvetica.regular}`,
    borderRadius: 0,
    borderWidth: 1,
    background: COLORS.white,

    '&.Mui-focused fieldset': {
      borderColor: COLORS.accent,
      borderWidth: 1,
    },
  },
});

export const FieldsBox = styled(Box)<{
  flexible?: boolean;
}>`
  display: flex;
  max-width: 556px;
  width: 100%;
  gap: 10px;
  align-items: start;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${({ flexible }) =>
      flexible &&
      css`
        gap: 0;
        flex-direction: column;
      `}
  }
`;

export const TooltipCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & img {
    max-width: 160px;
  }

  & p {
    font-size: 12px;
  }
`;

export const ErrorMessage = styled(Typography).attrs({ type: 'smallHelv' })`
  width: 100%;
  color: ${COLORS.accent};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const Btn = styled.button`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 12px;
  color: ${COLORS.black};
  opacity: 0.6;
  transition: 0.3s;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;
