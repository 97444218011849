import { Typography } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const Title = styled.h1`
  background: ${COLORS.black};
  color: ${COLORS.white};
  text-align: center;
  font-family: ${FONTS.TTNorms.bold};
  font-size: 40px;
  text-transform: uppercase;
  padding: 8px 0;

  @media (max-width: 750px) {
    font-size: 28px;
  }
`;

export const VacancyCard = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.border};
  padding: 26px 70px;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
    padding: 26px 20px;
  }
`;

export const JobTitle = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 36px;
  line-height: 46px;
  text-transform: uppercase;
  margin-bottom: 9px;
  cursor: pointer;
  color: ${COLORS.black};

  @media (max-width: 1000px) {
    font-size: 30px;
  }
`;

export const Location = styled(Typography)`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  margin-bottom: 9px;

  @media (max-width: 1000px) {
    font-size: 26px;
  }
`;
