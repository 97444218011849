import { ProductItemsType } from 'types/OrdersTypes';
import { BasketProductItemType } from 'types/ProductsTypes';

export const areAllItemsInBasketAvailable = (
  items: Array<ProductItemsType | BasketProductItemType>,
) => {
  return !(items as BasketProductItemType[]).some(
    (item: BasketProductItemType) => item.inventory?.orderable === false,
  );
};
