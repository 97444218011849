import { isDev } from 'appConstants';

export const featuredBrands: { id: string; brandName: string }[] = [
  {
    id: isDev ? 'U2VsbGVyLTE5Ng==' : 'U2VsbGVyLTI3Ng==',
    brandName: 'Book of Timothy',
  },
  {
    id: isDev ? 'U2VsbGVyLTc3' : 'U2VsbGVyLTYx',
    brandName: 'Cool Creative',
  },
  {
    id: isDev ? 'U2VsbGVyLTQ2' : 'U2VsbGVyLTgx',
    brandName: 'Elo Vegan Lifestyle',
  },
  {
    id: isDev ? 'U2VsbGVyLTEyOA==' : 'U2VsbGVyLTg5',
    brandName: 'Fulaba',
  },
  {
    id: isDev ? 'U2VsbGVyLTU3' : 'U2VsbGVyLTI4MQ==',
    brandName: 'Goodwinxxii',
  },
];
