import { Helmet } from 'react-helmet';

import { CalendarIcon, ClockIcon, HalfWBackground, LiveIcon } from 'assets/svg';
import { CustomNavLink, Typography } from 'components';
import EventCard from 'components/EventCard/EventCard';
import { empowerEvents } from 'data/events';
import ROUTES from 'router/Routes';
import {
  BottomBannerBackground,
  BottomBannerTitle,
  BottomBannerSubtitle,
  StyledColoredButton,
  BannerContents,
  GridCardsWrapper,
  HeroBackgroundImage,
  EventBannerBlock,
  EventStatusButton,
  EventBannerTitle,
  EventBannerSubtitle,
  EventBannerTime,
  ButtonsWrapper,
  EventTab,
} from './styles';

const eventData = {
  id: '1',
  title: 'French Balayage summer',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  day: 'June 2022',
  time: '8 PM EST',
  image: 'assets/jpg/events/events-1.jpg',
  isLive: true,
};

const CurrentEvents = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Current Events</title>
        <meta name="description" content="Current Events" />
      </Helmet>

      <HeroBackgroundImage
        imageUrl={require('../../assets/jpg/events/hero-events.jpg')}
      >
        <EventBannerBlock>
          <EventStatusButton
            aria-label="post live"
            style={{
              color: '#EA3253',
              backgroundColor: '#EA325329',
            }}
          >
            <LiveIcon /> post live
          </EventStatusButton>
          <EventBannerTitle>{eventData.title}</EventBannerTitle>
          <EventBannerSubtitle>{eventData.text}</EventBannerSubtitle>
          <EventBannerTime>
            <span>
              <CalendarIcon />
              <Typography type="subtitle2">{eventData.day}</Typography>
            </span>
            <span>
              <ClockIcon />
              <Typography type="subtitle2">{eventData.time}</Typography>
            </span>
          </EventBannerTime>
          <StyledColoredButton
            aria-label="watch now"
            style={{ position: 'absolute' }}
          >
            watch now
          </StyledColoredButton>
        </EventBannerBlock>
      </HeroBackgroundImage>

      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <HalfWBackground
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '900px',
            width: '1200px',
            zIndex: -1,
          }}
        />

        <ButtonsWrapper>
          <EventTab isActive="true">eg this week</EventTab>
          <CustomNavLink to={ROUTES.EVENTS_PAST}>
            <EventTab>past events</EventTab>
          </CustomNavLink>
        </ButtonsWrapper>

        <GridCardsWrapper>
          {empowerEvents.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </GridCardsWrapper>
      </div>

      <BottomBannerBackground
        imageUrl={require('../../assets/jpg/events/banner-background.jpg')}
      >
        <BannerContents>
          <BottomBannerTitle>eg elite loyalty program</BottomBannerTitle>
          <BottomBannerSubtitle>
            You earn EG Elite points for all purchases made during live shopping
            events! Sign up today.
          </BottomBannerSubtitle>
          <CustomNavLink to={ROUTES.LOYALTY_PROGRAM}>
            <StyledColoredButton
              aria-label="join our community"
              style={{ minWidth: '295px', marginTop: 10 }}
            >
              join our community
            </StyledColoredButton>
          </CustomNavLink>
        </BannerContents>
      </BottomBannerBackground>
    </>
  );
};

export default CurrentEvents;
