import { xor } from 'lodash';
import { Divider } from '@mui/material';

import { CheckIcon } from 'assets/svg';
import { COLORS } from 'styles';
import { RefinementType } from 'types/ProductsTypes';
import BasicSelect from './BasicSelect';
import {
  ResetButton,
  ResetButtonText,
  ListItemsWrapper,
  StyledListItemText,
  StyledMenuItem,
} from '../styles';

const ProductsFilterDropdown = ({
  refinement: { label, attributeId, values },
  selectFilterValues,
  filterValue,
}: {
  refinement: RefinementType;
  selectFilterValues: (data: any) => void;
  filterValue?: string[];
}) => {
  const optionValues = filterValue || [];

  const handleClick = (_: any, value: string) => {
    if (attributeId === 'price') {
      selectFilterValues({
        [attributeId]: [value],
      });
    } else {
      selectFilterValues({
        [attributeId]: xor(optionValues, [value]),
      });
    }
  };

  const handleReset = () => {
    selectFilterValues({ [attributeId]: '' });
  };

  const renderLabel = () => {
    const isSelected = optionValues.length > 0;

    return (
      <p>
        {label}{' '}
        <span
          style={{
            display: 'inline-block',
            width: 10,
            color: isSelected ? COLORS.accent : '',
          }}
        >
          {isSelected ? `(${optionValues.length})` : ''}
        </span>
      </p>
    );
  };

  if (!values || !label) {
    return null;
  }

  return (
    <BasicSelect renderLabel={renderLabel} optionValues={optionValues} multiple>
      <ListItemsWrapper>
        {values &&
          values.map(
            (item: { hitCount: number; label: string; value: string }) => (
              <StyledMenuItem
                value={item.label}
                key={item.value}
                onClick={(event: any) => handleClick(event, item.value)}
              >
                <StyledListItemText
                  primary={item.label}
                  style={{
                    color:
                      optionValues.indexOf(item.value) > -1
                        ? COLORS.accent
                        : COLORS.black,
                  }}
                />
                {optionValues.indexOf(item.label) > -1 && <CheckIcon />}
              </StyledMenuItem>
            ),
          )}
      </ListItemsWrapper>

      <Divider />

      <ResetButton aria-label="reset" onClick={handleReset}>
        <ResetButtonText>reset</ResetButtonText>
      </ResetButton>
    </BasicSelect>
  );
};

export default ProductsFilterDropdown;
