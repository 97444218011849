import { Controller } from 'react-hook-form';

import { StyledTextField } from 'components/Forms/styles';
import { ErrorText, Label } from './styles';

const FormInputController = ({
  id,
  control,
  name,
  label,
  error,
  stripeInput = false,
  errorMessage = '',
  sxStyle,
  InputLabelProps,
  InputProps,
  rules,
  type,
  rows,
  disabled,
  defaultValue,
  handleAddressesOnBlur,
}: {
  id?: string;
  control: any;
  name: string;
  label: string;
  error: any;
  stripeInput?: boolean;
  errorMessage?: string | undefined;
  sxStyle?: any;
  InputLabelProps?: any;
  InputProps?: any;
  rules?: any;
  type?: string;
  rows?: number | string;
  disabled?: boolean;
  defaultValue?: string | undefined;
  handleAddressesOnBlur?: () => void;
}) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Label htmlFor={id}>{label}</Label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledTextField
            {...field}
            id={id}
            sx={sxStyle}
            error={error}
            inputRef={field.ref}
            type={type}
            rows={rows}
            multiline={!!rows}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            onChange={(event: any) => {
              // should handle validation in other way
              if (stripeInput) {
                const isFieldEmpty = event.empty;
                field.onChange(isFieldEmpty ? '' : event.elementType);
                return;
              }

              field.onChange(event.target.value);
            }}
            onBlur={(event: any) => {
              if (stripeInput) return;
              field.onChange(event.target.value.trim());
              !!handleAddressesOnBlur && handleAddressesOnBlur();
            }}
            disabled={!!disabled}
          />
        )}
      />
      {error && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default FormInputController;
