import { CustomNavLink } from 'components';
import { featuredBrands } from 'data/brands';
import ROUTES from 'router/Routes';
import { BrandName, BrandsWrapper, SectionWrapper, Title } from './styles';

const FeaturedBrands = () => {
  return (
    <SectionWrapper>
      <Title>Featured Brands</Title>
      <BrandsWrapper>
        {featuredBrands.map((brand: { id: string; brandName: string }) => (
          <CustomNavLink to={`${ROUTES.BRANDS}/${brand.id}`} key={brand.id}>
            <BrandName>{brand.brandName}</BrandName>
          </CustomNavLink>
        ))}
      </BrandsWrapper>
    </SectionWrapper>
  );
};

export default FeaturedBrands;
