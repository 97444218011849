import { useSelector } from 'react-redux';
import { chain, isEmpty, sortBy } from 'lodash';

import ROUTES from 'router/Routes';
import { LoyaltyTierGroupType } from 'types/AccountTypes';
import { getCustomerData } from 'eg_SFCC_FE_core/store/selectors';
import { formatTierName } from 'helpers/formatters';
import { CustomNavLink, Typography } from 'components';
import CircularProgress from 'components/CircularProgress/CircularProgress';
import BasicTabs from 'components/Tabs/BasicTabs';
import { FONTS } from 'styles';
import LoyaltyOverview from './LoyaltyOverview';
import LoyaltyPointsHistory from './LoyaltyPointsHistory';
import {
  BlockTitle,
  MiniCircularProgressWrapper,
  RightBlockWrapper,
  TierName,
} from './styles';

const TABS = [
  {
    id: 'overview',
    name: 'overview',
    content: <LoyaltyOverview />,
  },
  {
    id: 'pointsHistory',
    name: 'Points history',
    content: <LoyaltyPointsHistory />,
  },
];

const LoyaltyPage = () => {
  const customerData = useSelector(getCustomerData);
  const loyaltyInfo = customerData?.c_loyaltyInfo;
  const isLoyaltyMember = customerData?.c_LoyaltyMember;
  const membersInfo = loyaltyInfo?.consumersInfo?.[0].membersInfo;
  const programsInfo = loyaltyInfo?.consumersInfo?.[0].programsInfo;
  const consumerPoints = membersInfo ? membersInfo[0]?.consumerPoints : 0;
  const currentTierName = membersInfo ? membersInfo[0]?.currentTierName : '';
  const loyaltyTiers = programsInfo
    ? chain(programsInfo)
        .find({ programId: membersInfo[0].programId })
        ?.value()
        .loyaltyTierGroups.find(
          (tier: LoyaltyTierGroupType) => tier.loyaltyGroupName === 'MVP',
        )?.loyaltyTiers
    : [];

  const sortedLoyaltyTiers = sortBy(loyaltyTiers, 'tierPoints');

  const circularValue =
    isEmpty(sortedLoyaltyTiers) || !consumerPoints
      ? 0
      : (consumerPoints * 100) /
        sortedLoyaltyTiers[sortedLoyaltyTiers.length - 1].tierPoints;

  return (
    <RightBlockWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <BlockTitle>Loyalty Points</BlockTitle>
        <MiniCircularProgressWrapper>
          <CircularProgress value={circularValue}>
            <Typography
              style={{
                fontFamily: FONTS.TTNorms.medium,
                fontSize: 36,
                marginTop: -30,
              }}
            >
              {consumerPoints ? Math.round(consumerPoints) : 0}
            </Typography>
            <TierName color="accent">
              {formatTierName(currentTierName, 'Elite')}
            </TierName>
          </CircularProgress>
        </MiniCircularProgressWrapper>
      </div>
      {isLoyaltyMember ? (
        <BasicTabs variant="fullWidth" tabs={TABS} />
      ) : (
        <Typography>
          Join Loyalty Program
          <CustomNavLink to={ROUTES.ACCOUNT_PERSONAL_INFO}> here</CustomNavLink>
        </Typography>
      )}
    </RightBlockWrapper>
  );
};

export default LoyaltyPage;
