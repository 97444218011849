import { ReactNode } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { COLORS } from 'styles';
import RadialSeparators from './RadialSeparators';

const CircularProgress = ({
  value,
  children,
}: {
  value: number;
  children: ReactNode;
}) => {
  return (
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={3}
      circleRatio={0.6}
      styles={buildStyles({
        rotation: 1 / 2 + 1 / 5,
        strokeLinecap: 'butt',
        trailColor: '#E8E8E8',
        pathColor: COLORS.accent,
      })}
    >
      {children}
      <RadialSeparators
        count={5}
        style={{
          background: '#fff',
          width: '5px',
          height: `${3}%`,
        }}
      />
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgress;
