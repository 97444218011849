import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'styles';

export const InfoBlockWrapper = styled.div`
  min-width: 200px;
  width: 100%;
  background-color: ${COLORS.white};
`;

export const Title = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-transform: UPPERCASE;
  padding-bottom: 16px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    & {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const Data = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  width: 151px;

  & span {
    display: block;
    margin-bottom: 5px;

    @media (max-width: ${BREAKPOINTS.s}px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;
