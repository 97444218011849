import { compact } from 'lodash';
import { formatPrice, formatShippingAddress } from 'helpers/formatters';
import {
  OrderInfoAddress,
  OrderInfoText,
  OrderInfoTitle,
  OrderPriceRow,
} from '../styles';

export const ReturnToCard = ({
  numberLastDigits,
  cardType,
}: {
  numberLastDigits: string;
  cardType: string;
}) => {
  return (
    <>
      <OrderInfoTitle>RETURN TO CARD</OrderInfoTitle>
      <OrderInfoText>
        {numberLastDigits} {cardType.toLocaleUpperCase()}
      </OrderInfoText>
    </>
  );
};

export const ReturnToAddress = ({
  shippingAddress,
}: {
  shippingAddress: any;
}) => {
  return (
    <>
      <OrderInfoTitle>SHIP FROM ADDRESS</OrderInfoTitle>
      <OrderInfoAddress>
        {compact(formatShippingAddress(shippingAddress)).map(
          (value: string, index: number) => (
            <p key={index}>{value}</p>
          ),
        )}
      </OrderInfoAddress>
    </>
  );
};

export const ReturnSummary = ({
  order,
  onlyTotal = false,
}: {
  order: any;
  onlyTotal?: boolean;
}) => {
  return (
    <>
      <OrderInfoTitle>RETURN {onlyTotal ? 'TOTAL' : 'SUMMARY'}</OrderInfoTitle>

      {!onlyTotal ? (
        <>
          <OrderPriceRow>
            <OrderInfoText>SUBTOTAL</OrderInfoText>
            <OrderInfoText>
              {formatPrice(order.productSubTotal || 0)}
            </OrderInfoText>
          </OrderPriceRow>
          <OrderPriceRow>
            <OrderInfoText>SHIPPING</OrderInfoText>
            <OrderInfoText>
              {formatPrice(order.shippingTotal || 0)}
            </OrderInfoText>
          </OrderPriceRow>
          <OrderPriceRow>
            <OrderInfoText>ESTIMATED TAX</OrderInfoText>
            <OrderInfoText>{formatPrice(order.taxTotal || 0)}</OrderInfoText>
          </OrderPriceRow>
        </>
      ) : null}
      <OrderPriceRow>
        <OrderInfoText>TOTAL</OrderInfoText>
        <OrderInfoText>{formatPrice(order.productTotal || 0)}</OrderInfoText>
      </OrderPriceRow>
    </>
  );
};
