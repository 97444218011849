import { Wrapper, Title, Text } from './styles';

const AboutEmpower = () => {
  return (
    <Wrapper>
      <Title>About Empower Global</Title>
      <Text>
        Founded by Sean &quot;Diddy&quot; Combs in 2021 and under the umbrella
        of Combs Global, Empower Global is an immersive e-commerce experience
        offering best in class products - apparel, beauty, fashion, art, travel
        - from Black-owned businesses. With culture and community at its core,
        Empower Global’s digital marketplace elevates the Black economy by
        creating opportunities for Black entrepreneurs to build and scale
        successful businesses and for everyone to &quot;Shop Black&quot; daily
        with ease. Headquartered in Atlanta Empower Global is the premier
        destination to discover and explore Black-owned businesses worldwide.
        Join us and the culture.
      </Text>
    </Wrapper>
  );
};

export default AboutEmpower;
