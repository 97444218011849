import { MainTitle, Paragraph, Title } from '../styles';

const LawPrivacyRights = () => {
  return (
    <>
      <MainTitle>OTHER STATE LAW PRIVACY RIGHTS</MainTitle>
      <Title space>A. OTHER CALIFORNIA RESIDENT RIGHTS</Title>
      <Paragraph>
        Under California Civil Code Sections 1798.83-1798.84, California
        residents are entitled to contact us to prevent disclosures of Personal
        Information to third parties for such third parties’ direct marketing
        purposes. In order to submit such a request, please contact us at{' '}
        <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>.
      </Paragraph>

      <Title space>B. NEVADA RESIDENT RIGHTS</Title>
      <Paragraph>
        If you are a resident of Nevada, you have the right to opt out of the
        sale of certain Personal Information to third parties who intend to
        license or sell that Personal Information. Please note that we do not
        currently sell your personal information as sales are defined in Nevada
        Revised Statutes Chapter 603A. If you have any questions, please contact
        us as set forth in the “Contact Us” section below.
      </Paragraph>
    </>
  );
};

export default LawPrivacyRights;
