import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  PaymentIntentResult,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { useCallback } from 'react';

type PaymentMethodDataType = {
  type: string;
  card: StripeCardNumberElement;
  billing_details?: {
    address: {
      country?: string | undefined;
      postal_code?: string | undefined;
    };
  };
};

type ShippingDataType = {
  name: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
  };
};

function useStripePayment() {
  const stripe = useStripe();
  const elements = useElements();

  const createStripePaymentCard = async ({
    country,
    zipCode,
  }: {
    country: string;
    zipCode: string;
  }): Promise<{ error?: string; stripePaymentMethod?: any }> => {
    const cardElement = elements?.getElement(CardNumberElement);

    if (!stripe || !elements || !cardElement) {
      return { error: 'Something went wrong' };
    }

    const paymentMethodData: PaymentMethodDataType = {
      type: 'card',
      card: cardElement,
    };

    if (country || zipCode) {
      const address: {
        country?: string | undefined;
        postal_code?: string | undefined;
      } = {};

      if (zipCode) address.postal_code = zipCode;
      if (country) address.country = country;

      paymentMethodData.billing_details = { address };
    }

    // @ts-ignore
    const stripeResponse = await stripe.createPaymentMethod(paymentMethodData);

    const { error, paymentMethod } = stripeResponse;

    if (error || !paymentMethod) {
      return {
        error: error?.message,
      };
    }

    return { stripePaymentMethod: paymentMethod };
  };

  const confirmCardPayment = async (
    intentClientSecret: string,
  ): Promise<{ error?: { message: string }; result?: PaymentIntentResult }> => {
    if (!stripe || !elements) {
      return { error: { message: 'Something went wrong' } };
    }

    const result = await stripe.confirmCardPayment(intentClientSecret);
    return { result };
  };

  const fetchPaymentIntent = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        return { paymentIntentError: { message: 'Something went wrong' } };
      }

      const { error, paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret,
      );

      return {
        paymentIntentError: error,
        paymentIntent,
      };
    },
    [stripe],
  );

  const confirmAfterpayPayment = async (
    clientSecret: string,
    billingDetails: ShippingDataType | { email: string },
  ) => {
    await stripe?.confirmAfterpayClearpayPayment(clientSecret, {
      payment_method: {
        billing_details: billingDetails,
      },
      // user redirects back after payment
      return_url: `${window.location.origin}/checkout/payment?return=true`,
    });
  };

  return {
    createStripePaymentCard,
    confirmCardPayment,
    confirmAfterpayPayment,
    fetchPaymentIntent,
  };
}

export default useStripePayment;
