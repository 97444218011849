import { createSlice } from '@reduxjs/toolkit';
import { filter, findIndex, includes, isEmpty, sortBy } from 'lodash';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { CustomerAddressType, StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { BrandType } from 'types/BrandsTypes';
import { PaymentCardType } from 'types/CheckoutTypes';

export const CUSTOMER_SLICE_NAME = 'customer';

type CustomerStateType = StateType & {
  registrationResult: any;
  loginResult: any;
  customerData: {
    [key: string]: any;
  };
  isLoggedIn: boolean;
  customerId: string;
  defaultPaymentInstrumentId: string;
  customerPaymentInstruments: any[];
  resetPasswordResult: string;
  emailWasUpdated: boolean;
  favoriteBrandsIds: string[];
  error: any;
};

const initialState: CustomerStateType = {
  pending: false,
  result: null,
  error: null,
  registrationResult: null,
  loginResult: null,
  customerData: {
    addresses: [],
  },
  isLoggedIn: false,
  customerId: '',
  defaultPaymentInstrumentId: '',
  customerPaymentInstruments: [],
  resetPasswordResult: '',
  emailWasUpdated: false,
  favoriteBrandsIds: [],
};

const customerSlice = createSlice({
  name: CUSTOMER_SLICE_NAME,
  initialState,
  reducers: {
    reset: (state) => {
      state.result = null;
      state.error = null;
      state.registrationResult = null;
      state.loginResult = null;
      state.customerData = {
        addresses: [],
      };
      state.isLoggedIn = false;
      state.customerId = '';
      state.customerPaymentInstruments = [];
      state.resetPasswordResult = '';
      state.emailWasUpdated = false;
      state.favoriteBrandsIds = [];
    },
    clearResetPasswordResult: (state) => {
      state.resetPasswordResult = '';
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.loginCustomer.pending, onPending);
    builder.addCase(AsyncThunks.loginCustomer.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;

      if (action.payload.access_token) {
        state.loginResult = action.payload;
        state.customerId = action.payload.customer_id;
        state.isLoggedIn = true;
      }
    });
    builder.addCase(AsyncThunks.loginCustomer.rejected, onError);

    builder.addCase(AsyncThunks.loginCustomerViaGoogle.pending, onPending);
    builder.addCase(
      AsyncThunks.loginCustomerViaGoogle.fulfilled,
      (state, action) => {
        state.pending = false;
        state.error = null;

        if (action.payload.access_token) {
          state.loginResult = action.payload;
          state.customerId = action.payload.customer_id;
          state.isLoggedIn = true;
        }
      },
    );
    builder.addCase(AsyncThunks.loginCustomerViaGoogle.rejected, onError);

    builder.addCase(AsyncThunks.registerCustomer.pending, onPending);
    builder.addCase(AsyncThunks.registerCustomer.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.registrationResult = action.payload;
    });
    builder.addCase(AsyncThunks.registerCustomer.rejected, onError);

    builder.addCase(AsyncThunks.logoutCustomer.pending, onPending);
    builder.addCase(AsyncThunks.logoutCustomer.fulfilled, (state) => {
      state.pending = false;
      state.error = null;
      state.registrationResult = null;
      state.result = null;
      state.loginResult = null;
      state.customerData = {
        addresses: [],
      };
      state.isLoggedIn = false;
      state.customerId = '';
      state.customerPaymentInstruments = [];
      state.emailWasUpdated = false;
      state.favoriteBrandsIds = [];
    });
    builder.addCase(AsyncThunks.logoutCustomer.rejected, onError);

    builder.addCase(AsyncThunks.getCustomer.pending, onPending);
    builder.addCase(AsyncThunks.getCustomer.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.customerData = action.payload;

      if (action.payload.c_stripePaymentInstruments) {
        const stripePaymentInstruments =
          action.payload.c_stripePaymentInstruments;
        const stripeDefaultPaymentMethodId =
          action.payload.c_stripeDefaultPaymentMethodId;

        const defaultInstrumentId =
          findIndex(stripePaymentInstruments, {
            id: stripeDefaultPaymentMethodId,
          }) !== -1
            ? stripeDefaultPaymentMethodId
            : stripePaymentInstruments[0].id;

        state.customerPaymentInstruments = sortBy(
          stripePaymentInstruments,
          (item: any) => {
            return item.id === defaultInstrumentId;
          },
        ).reverse();

        state.defaultPaymentInstrumentId = defaultInstrumentId || '';
      }

      if (action.payload.c_marketplacerSellers) {
        state.favoriteBrandsIds = JSON.parse(
          action.payload.c_marketplacerSellers,
        );
      }
    });
    builder.addCase(AsyncThunks.getCustomer.rejected, onError);

    builder.addCase(AsyncThunks.updateCustomer.pending, onPending);
    builder.addCase(AsyncThunks.updateCustomer.fulfilled, (state, action) => {
      state.pending = false;
      state.error = null;
      state.customerData = {
        ...state.customerData,
        ...action.payload.data,
      };
      state.emailWasUpdated = action.payload.emailWasUpdated;
    });
    builder.addCase(AsyncThunks.updateCustomer.rejected, onError);

    // customer addresses
    builder.addCase(AsyncThunks.createCustomerAddress.pending, onPending);
    builder.addCase(
      AsyncThunks.createCustomerAddress.fulfilled,
      (state, action) => {
        if (state.customerData.addresses?.length) {
          state.customerData.addresses = [
            ...state.customerData.addresses,
            action.payload,
          ];
        } else {
          state.customerData.addresses = [action.payload];
        }
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.createCustomerAddress.rejected, onError);

    builder.addCase(AsyncThunks.removeCustomerAddress.pending, onPending);
    builder.addCase(
      AsyncThunks.removeCustomerAddress.fulfilled,
      (state, action) => {
        if (!state.customerData.addresses) return;

        state.customerData.addresses = state.customerData.addresses.filter(
          (address: CustomerAddressType) => {
            return address.addressId !== action.payload;
          },
        );

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.removeCustomerAddress.rejected, onError);

    builder.addCase(AsyncThunks.updateCustomerAddress.pending, onPending);
    builder.addCase(
      AsyncThunks.updateCustomerAddress.fulfilled,
      (state, action) => {
        if (!state.customerData.addresses) return;

        state.customerData.addresses = state.customerData.addresses.map(
          (address: CustomerAddressType) => {
            if (address.addressId === action.payload.addressId) {
              return action.payload;
            }
            return address;
          },
        );

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.updateCustomerAddress.rejected, onError);
    builder.addCase(AsyncThunks.setDefaultAddress.pending, onPending);
    builder.addCase(
      AsyncThunks.setDefaultAddress.fulfilled,
      (state, action) => {
        if (!state.customerData.addresses) return;

        state.customerData.addresses = state.customerData.addresses.map(
          (address: CustomerAddressType) => {
            const updatedAddress = filter(action.payload, {
              addressId: address.addressId,
            })[0];

            if (
              updatedAddress &&
              address.addressId === updatedAddress.addressId
            ) {
              return updatedAddress;
            }
            return address;
          },
        );

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.setDefaultAddress.rejected, onError);

    builder.addCase(AsyncThunks.resetPassword.pending, onPending);
    builder.addCase(AsyncThunks.resetPassword.fulfilled, (state) => {
      state.resetPasswordResult = 'Password was changed';
      state.pending = false;
      state.error = null;
    });
    builder.addCase(
      AsyncThunks.resetPassword.rejected,
      (state, action: { payload: any }) => {
        state.resetPasswordResult = action.payload.errorMessage;
        state.pending = false;
        state.error = action.payload;
      },
    );

    builder.addCase(AsyncThunks.updateFavoriteBrands.pending, onPending);
    builder.addCase(
      AsyncThunks.updateFavoriteBrands.fulfilled,
      (state, action) => {
        state.customerData = {
          ...state.customerData,
          c_followedSellers: state.customerData.c_followedSellers?.filter(
            (seller: BrandType) => {
              return includes(action.payload.c_marketplacerSellers, seller.id);
            },
          ),
        };

        if (action.payload.c_marketplacerSellers) {
          state.favoriteBrandsIds = JSON.parse(
            action.payload.c_marketplacerSellers,
          );
        }

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.updateFavoriteBrands.rejected, onError);

    builder.addCase(
      AsyncThunks.addCustomerPaymentInstrument.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.addCustomerPaymentInstrument.fulfilled,
      (state) => {
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.addCustomerPaymentInstrument.rejected, onError);
    builder.addCase(
      AsyncThunks.removeCustomerPaymentInstrument.pending,
      onPending,
    );
    builder.addCase(
      AsyncThunks.removeCustomerPaymentInstrument.fulfilled,
      (state, action) => {
        const updatedPaymentInstruments =
          state.customerPaymentInstruments.filter(
            (instrument: PaymentCardType) => {
              return instrument.id !== action.payload;
            },
          );

        if (isEmpty(updatedPaymentInstruments)) {
          state.defaultPaymentInstrumentId = '';
        }

        state.customerPaymentInstruments = updatedPaymentInstruments;

        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(
      AsyncThunks.removeCustomerPaymentInstrument.rejected,
      onError,
    );

    builder.addCase(AsyncThunks.setDefaultPaymentInstrument.pending, onPending);
    builder.addCase(
      AsyncThunks.setDefaultPaymentInstrument.fulfilled,
      (state, action) => {
        state.defaultPaymentInstrumentId =
          action.payload.c_stripeDefaultPaymentMethodId;
        state.customerPaymentInstruments = sortBy(
          state.customerPaymentInstruments,
          (item: any) => {
            return item.id === action.payload.c_stripeDefaultPaymentMethodId;
          },
        ).reverse();
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.setDefaultPaymentInstrument.rejected, onError);

    // saved searches
    builder.addCase(AsyncThunks.saveSearchResult.pending, onPending);
    builder.addCase(AsyncThunks.saveSearchResult.fulfilled, (state, action) => {
      let savedSearch;
      const savedSearchJson = action.payload.c_savedSearchJson;

      if (typeof savedSearchJson === 'string') {
        savedSearch = JSON.parse(savedSearchJson);
      } else {
        savedSearch = savedSearchJson;
      }

      state.customerData.c_savedSearchJson = savedSearch;
      state.pending = false;
      state.error = null;
    });
    builder.addCase(AsyncThunks.saveSearchResult.rejected, onError);

    builder.addCase(AsyncThunks.updateSavedSearchResult.pending, onPending);
    builder.addCase(
      AsyncThunks.updateSavedSearchResult.fulfilled,
      (state, action) => {
        let savedSearch;
        const savedSearchJson = action.payload.c_savedSearchJson;

        if (typeof savedSearchJson === 'string') {
          savedSearch = JSON.parse(savedSearchJson);
        } else {
          savedSearch = savedSearchJson;
        }

        state.customerData.c_savedSearchJson = savedSearch;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.updateSavedSearchResult.rejected, onError);

    builder.addCase(AsyncThunks.removeSavedSearch.pending, onPending);
    builder.addCase(
      AsyncThunks.removeSavedSearch.fulfilled,
      (state, action) => {
        let savedSearch;
        const savedSearchJson = action.payload.customerData.c_savedSearchJson;

        if (typeof savedSearchJson === 'string') {
          savedSearch = JSON.parse(savedSearchJson);
        } else {
          savedSearch = savedSearchJson;
        }

        state.customerData.c_savedSearchJson = savedSearch;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.removeSavedSearch.rejected, onError);
  },
});

export const customerActions = customerSlice.actions;
export const customerReducer = customerSlice.reducer;
