import { SubmitHandler, useForm } from 'react-hook-form';

import { ColoredButton, Typography } from 'components';
import PaymentForm from 'components/Forms/PaymentForm/PaymentForm';
import { paymentFormSchema } from 'helpers/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsWrapper } from './styles';

const AccountPaymentForm = ({
  handleSave,
  isLoading,
  paymentError,
}: {
  handleSave: (data: any) => void;
  isLoading: boolean;
  paymentError: string;
}) => {
  const values = {
    country: '',
    zipCode: '',
  };

  const methods = useForm({
    resolver: yupResolver(paymentFormSchema),
    defaultValues: values,
  });

  const onSubmit: SubmitHandler<any> = (data: any, e: any) => {
    handleSave(data);
    e.target.reset();
  };

  return (
    <div>
      {paymentError && <Typography color="accent">{paymentError}</Typography>}
      <PaymentForm
        values={values}
        onSubmit={onSubmit}
        methods={methods}
        handleChange={() => {}}
      />

      <ButtonsWrapper>
        <ColoredButton
          width="214px"
          form="payment-form"
          type="submit"
          aria-label="save"
          isLoading={isLoading}
        >
          save
        </ColoredButton>
      </ButtonsWrapper>
    </div>
  );
};

export default AccountPaymentForm;
