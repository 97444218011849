import styled from 'styled-components';
import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px 25px 0;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 20px 0 25px;
  }
`;

export const Title = styled(Typography).attrs({
  tag: 'h2',
  type: 'subtitle2Helv',
})`
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const BrandsWrapper = styled.div`
  display: grid;
  gap: 10px 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const BrandName = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.6;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }

  &:hover {
    opacity: 1;
    transition: 0.3s;
  }
`;
