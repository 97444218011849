import styled from 'styled-components';

import { Typography } from 'components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;

  & > div:nth-child(2) {
    margin: 0 auto;
  }

  @media (max-width: ${BREAKPOINTS.m}px) {
    flex-direction: column;

    & > div:nth-child(2) {
      margin: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    align-items: center;
    gap: 20px;
  }
`;

export const GuestCheckoutText = styled(Typography).attrs({
  type: 'smallHelv',
})`
  max-width: 270px;
  margin-bottom: 27px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-bottom: 17px;
    font-size: 10px;
    line-height: 12px;
  }
`;
