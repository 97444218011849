import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { StateType } from 'eg_SFCC_FE_core/types';
import { onPending, onError } from 'eg_SFCC_FE_core/store/stateResults';
import { IFilterProps, ProductType } from 'types/ProductsTypes';
import { SearchSuggestionsType } from 'types/SearchTypes';

export const PRODUCT_SEARCH_RESULT_SLICE_NAME = 'productSearchResult';

type ProductSearchResultStateType = StateType & {
  categoryId: string;
  productList: ProductType[];
  total: number;
  currentPage: number;
  searchSuggestions: SearchSuggestionsType;
  filterValues: IFilterProps[];
  sortingId: string;
};

const initialState: ProductSearchResultStateType = {
  pending: false,
  error: null,
  result: [],
  categoryId: '',
  productList: [],
  total: 0,
  currentPage: 1,
  searchSuggestions: {},
  filterValues: [],
  sortingId: '',
};

const productListSlice = createSlice({
  name: PRODUCT_SEARCH_RESULT_SLICE_NAME,
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetSearchSuggestions: (state) => {
      state.searchSuggestions = {};
    },
    setFilterValues: (state, action) => {
      state.filterValues = action.payload;
    },
    setSortingId: (state, action) => {
      state.sortingId = action.payload;
    },
    resetFilterSorting: (state) => {
      state.filterValues = [];
      state.sortingId = '';
    },
    clearProductList: (state) => {
      state.productList = [];
      state.result = {};
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AsyncThunks.getProductSearchResult.pending, onPending);
    builder.addCase(
      AsyncThunks.getProductSearchResult.fulfilled,
      (state, action) => {
        state.total = action.payload.total;
        state.result = omit(action.payload, ['hits', 'total']);
        state.productList = action.payload.hits;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getProductSearchResult.rejected, onError);

    builder.addCase(
      AsyncThunks.getSearchSuggestions.fulfilled,
      (state, action) => {
        state.searchSuggestions = action.payload;
        state.pending = false;
        state.error = null;
      },
    );
    builder.addCase(AsyncThunks.getSearchSuggestions.rejected, onError);
  },
});

export const productSearchResultActions = productListSlice.actions;
export const productSearchResultReducer = productListSlice.reducer;
