import styled, { css } from 'styled-components';
import MuiAccordion from '@mui/material/Accordion';
import { FormControlLabel } from '@mui/material';

import { Typography } from 'components/Typography/Typography';
import { ArrowDownIcon, ArrowRightIcon } from 'assets/svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s;
`;

export const AccordionTitle = styled(Typography)`
  text-transform: capitalize;
  font-size: 14px;
  font-family: ${FONTS.Helvetica.medium};
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const StyledAccordion = styled(MuiAccordion)`
  && {
    border: 1px solid #dedfe2;
    box-shadow: none;
    margin: 0;
    width: 300px;
    padding: 0 14px 0 18px;
    border-radius: 0 !important;

    @media (max-width: ${BREAKPOINTS.xxs}px) {
      width: 210px;
      padding: 0 7px;
    }

    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      min-height: 0;
    }

    .MuiAccordionSummary-content {
      margin: 8px 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 0 5px 7px;
    }
  }
`;

export const OrderStatusTitle = styled(Typography).attrs({
  type: 'subtitle2Helv',
})`
  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiTypography-root {
      font-size: 12px;
      font-family: ${FONTS.Helvetica.regular};

      @media (max-width: ${BREAKPOINTS.xs}px) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
`;

export const FilterTitle = styled(Typography).attrs({
  type: 'navHelv',
})`
  text-transform: capitalize;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const RotatedMinus = styled.span`
  position: absolute;
  width: 16px;
  height: 16px;
  cursor: pointer;
  right: 0;

  &:after {
    content: '';
    position: absolute;
    background: ${COLORS.black};
  }

  &:after {
    top: 50%;
    left: 4px;
    right: 4px;
    height: 1px;
    transform: translateY(-50%);
  }
`;

export const RotatedPlus = styled(RotatedMinus)<{
  white?: boolean;
}>`
  &:before,
  :after {
    content: '';
    position: absolute;
    background: ${COLORS.black};
  }

  &:before {
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 1px;
    transform: translateX(-50%);
  }

  ${({ white }) =>
    white &&
    css`
      &:before,
      :after {
        background: ${COLORS.white};
      }
    `}
`;

export const ArrowDown = styled(ArrowDownIcon)`
  width: 10px;
  height: 4px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 22px;
`;

export const ArrowUp = styled(ArrowDown)`
  transform: rotateZ(180deg);
`;

export const Subtitle = styled(Typography).attrs({
  type: 'subtitle1',
  color: 'primaryInverted',
})`
  text-transform: capitalize;
  cursor: pointer;
`;

export const LinksWrapper = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AccentAccordionTitle = styled(Typography).attrs({
  type: 'subtitle2',
})`
  cursor: pointer;
  max-width: 500px;
`;

export const NotExpandedIcon = styled(ArrowRightIcon)`
  color: ${COLORS.accent};
  position: absolute;
  right: 0;
`;

export const ExpandedIcon = styled(NotExpandedIcon)`
  transform: rotate(90deg);
`;

export const StyledCatalogAccordion = styled(MuiAccordion)`
  && {
    box-shadow: none;
    margin: 0;
    width: 375px;
    border-radius: 0 !important;

    @media (max-width: ${BREAKPOINTS.xxs}px) {
      width: 210px;
    }

    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      min-height: 0;
    }

    .MuiAccordionSummary-content {
      margin: 8px 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 0 5px 7px;
    }
  }
`;

export const StyledSubCatalogAccordion = styled(MuiAccordion)`
  && {
    box-shadow: none;
    margin: 0;
    border-radius: 0 !important;

    &:before {
      display: none;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const CatalogItemTitle = styled(Typography).attrs({
  type: 'title5Helv',
})`
  text-transform: uppercase;
  cursor: pointer;
`;

export const SubCatalogItemTitle = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  text-transform: capitalize;
  cursor: pointer;
  padding: 8px 14px 8px 18px;
`;

export const ShippingAddressTitle = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  margin-left: 20px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;
