import { Typography } from 'components';
import styled from 'styled-components';
import { FONTS } from 'styles';

export const LoyaltyPointDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  width: 120px;
  height: 140px;
  object-fit: cover;

  @media (max-width: 650px) {
    width: 90px;
    height: 110px;
  }
`;

export const Title = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 10px;

  @media (max-width: 650px) {
    font-size: 14px;
  }
`;
