import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getAuthError,
  getCustomerLoader,
  getIsLoggedIn,
} from 'eg_SFCC_FE_core/store/selectors';
import useAuth from 'hooks/useAuth';
import ROUTES from 'router/Routes';
import CheckoutRegistrationForm from 'components/Forms/RegistrationForm/CheckoutRegistrationForm';

import { CheckoutButton, Title } from '../styles';
import { GuestCheckoutText, Wrapper } from './styles';

const AccountSetup = () => {
  const navigate = useNavigate();
  const { hasAgeRestriction, registerCustomer } = useAuth();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const authError = useSelector(getAuthError);
  const isLoading = useSelector(getCustomerLoader);

  const navigateToCheckout = () => {
    navigate(ROUTES.CHECKOUT_DETAILS);
  };

  useEffect(() => {
    isLoggedIn && navigateToCheckout();
  }, [isLoggedIn]);

  return (
    <Wrapper>
      <div>
        <Title>account set-up</Title>
        <CheckoutRegistrationForm
          ageRestriction={hasAgeRestriction}
          authError={authError}
          isLoading={isLoading}
          registerCustomer={registerCustomer}
        />
      </div>
      <div style={{ maxWidth: 400, width: '100%' }}>
        <Title>New or Guest Customer</Title>
        <GuestCheckoutText>
          You will have a chance to create an account to track your orders, make
          a wish list, and more during checkout.
        </GuestCheckoutText>
        <CheckoutButton
          width="100%"
          type="button"
          aria-label="checkout as a guest"
          onClick={navigateToCheckout}
          inverted
        >
          checkout as a guest
        </CheckoutButton>
      </div>
    </Wrapper>
  );
};

export default AccountSetup;
