import { NavLink } from 'react-router-dom';
import ROUTES from 'router/Routes';
import {
  List,
  MainTitle,
  NestedList,
  Paragraph,
  Table,
  TableWrapper,
  Title,
} from '../styles';

const PersonalInfoCollect = () => (
  <>
    <MainTitle>PERSONAL INFORMATION WE COLLECT</MainTitle>
    <Paragraph>
      The categories of Personal Information we collect depend on how you
      interact with us, our Services, and the requirements of applicable law. We
      collect information that you provide to us, information we obtain
      automatically when you use our Services, and information from other
      sources such as third-party services and organizations, as described
      below.
    </Paragraph>

    <Title space>A. CATEGORIES OF PERSONAL INFORMATION WE COLLECT</Title>
    <Paragraph>
      This chart details the categories of Personal Information that we collect:
    </Paragraph>
    <TableWrapper style={{ margin: '10px 0 20px' }}>
      <Table>
        <thead>
          <tr>
            <th>Category of Personal Information</th>
            <th>Examples of Personal Information We Collect</th>
            <th>
              Categories of Third Parties With Whom We Share this Personal
              Information:
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Profile or Contact Data</td>
            <td>
              <ul>
                <li>First and last name</li>
                <li>Email</li>
                <li>Username</li>
                <li>Unique identifiers such as passwords</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Business Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Payment Data</td>
            <td>
              <ul>
                <li>Payment card type</li>
                <li>Last 4 digits of payment card</li>
                <li>Billing address, phone number, and email</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  Service Providers (specifically our payment processing
                  partner, currently Stripe, Inc.)
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Commercial Data</td>
            <td>
              <ul>
                <li>Purchase history</li>
                <li>Consumer profiles</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Analytics Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Device/IP Data</td>
            <td>
              <ul>
                <li>IP address</li>
                <li>Device ID</li>
                <li>Domain server</li>
                <li>
                  Type of device/operating system/browser used to access the
                  Services
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Advertising Partners</li>
                <li>Analytics Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Web Analytics</td>
            <td>
              <ul>
                <li>Web page interactions</li>
                <li>Browsing history</li>
                <li>Search history</li>
                <li>
                  Referring web page/source through which you accessed the
                  Services
                </li>
                <li>Non-identifiable request IDs</li>
                <li>
                  Statistics associated with the interaction between device or
                  browser and the Services
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Advertising Partners</li>
                <li>Analytics Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Social Network Data</td>
            <td>
              <ul>
                <li>Email</li>
                <li>Phone number</li>
                <li>Username</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Analytics Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Consumer Demographic Data</td>
            <td>
              <ul>
                <li>Age / date of birth if you create an account with us</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Affiliates</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Location Data</td>
            <td>
              <ul>
                <li>IP address-based location information</li>
                <li>Shipping address</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Advertising Partners</li>
                <li>Analytics Partners</li>
                <li>Affiliates</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>Photos, Videos, and Recordings</td>
            <td>
              <ul>
                <li>Photos or videos if you choose to submit a review </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers (specifically, through Bazaarvoice)</li>
                <li>Public (all reviews are made publically available)</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              Other Identifying Information that You Voluntarily Choose to
              Provide
            </td>
            <td>
              <ul>
                <li>
                  Identifying information in emails, letters or other
                  communication you send us
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Service Providers</li>
                <li>Parties You Authorize, Access, or Authenticate</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    </TableWrapper>

    <Title space>B. CATEGORIES OF SOURCES OF PERSONAL INFORMATION</Title>
    <Paragraph space>
      We collect personal information about you from the following categories of
      sources:
    </Paragraph>

    <List>
      <li>
        <Title>You provide information directly to us when you:</Title>
        <NestedList>
          <li>Create an account or use our interactive tools and Services</li>
          <li>
            Voluntarily provide information in free-form text boxes through the
            Services, through responses to surveys or questionnaires, or
            participate in sweepstakes or contests
          </li>
          <li>
            Send us an email, register for our newsletter or loyalty program,
            request customer or technical support, or otherwise contact us
          </li>
          <li>Make a purchase</li>
          <li>
            Interact with us at conferences, trade shows, and other events
          </li>
        </NestedList>
      </li>

      <li>
        <Title>
          When you use the Services, the following information is collected
          automatically:
        </Title>
        <NestedList>
          <li>
            Cookies (defined in the “Tracking Tools, Advertising and Opt-Out”
            section below)
          </li>
          <li>
            Information about your location and mobile device, as applicable, if
            you download our mobile application or use a location-enabled
            browser
          </li>
          <li>
            Information transmitted from your computing device for the purpose
            of providing you the relevant Services, such as information
            regarding when you are logged on and available to receive updates or
            alert notices, if you download and install certain applications and
            software we make available, we may receive and collect information
          </li>
          <li>
            Interact with us at conferences, trade shows, and other events
          </li>
        </NestedList>
      </li>

      <li>
        <Title>Third Parties</Title>
        <NestedList>
          <li>
            Vendors
            <NestedList listStyle="square">
              <li>
                We may use analytics providers to analyze how you interact and
                engage with the Services, or third parties may help us provide
                you with customer support.
              </li>
              <li>
                We may use vendors to obtain information to generate leads and
                create user profiles.
              </li>
            </NestedList>
          </li>
          <li>
            Advertising Partners
            <NestedList listStyle="square">
              <li>
                We receive information about you from some of our vendors who
                assist us with marketing or promotional services related to how
                you interact with our websites, applications, products,
                Services, advertisements or communications.
              </li>
            </NestedList>
          </li>
        </NestedList>
      </li>
    </List>

    <Title space>
      C. OUR COMMERCIAL OR BUSINESS PURPOSES FOR COLLECTING PERSONAL INFORMATION
    </Title>
    <Paragraph space>
      We use your Personal Information for a variety of business purposes,
      including to provide our Services, for administrative purposes, and to
      market our products and Services, as described below. We will not collect
      additional categories of Personal Information or use the Personal
      Information we collect for materially different, unrelated, or
      incompatible purposes without providing you notice.
    </Paragraph>
    <List>
      <li>
        <Title>Providing, Customizing, and Improving Our Services</Title>
        <NestedList>
          <li>Creating and managing your account or other users’ profiles</li>
          <li>
            Providing access to certain areas, functionalities, and features of
            our Services
          </li>
          <li>Answering requests for customer or technical support</li>
          <li>
            Communicating with you about your account, activities on our
            Services, and policy changes
          </li>
          <li>Processing orders or other transactions</li>
          <li>Billing</li>
          <li>
            Meeting or fulfilling the reason you provided the information to us
          </li>
          <li>Allowing you to register for events</li>
          <li>
            Detecting security incidents, protecting against malicious,
            deceptive, fraudulent, or illegal activity, and prosecuting those
            responsible for said activity
          </li>
          <li>
            Personalizing the Services, website content, and communications
            based on your preferences
          </li>
          <li>
            Improving, upgrading, or enhancing our Services, including testing,
            research, internal analytics, and product development
          </li>
        </NestedList>
      </li>

      <li>
        <Title>Marketing the Services</Title>
        <NestedList>
          <li>Marketing and selling the Services</li>
          <li>
            Showing you advertisements, including interest-based or online
            behavioral advertising
          </li>
        </NestedList>
      </li>

      <li>
        <Title>Meeting Legal Requirements and Enforcing Legal Terms</Title>
        <NestedList>
          <li>
            Complying with our legal obligations under applicable law,
            regulation, court order, or other legal processes, such as
            preventing, detecting, and investigating security incidents, and
            potentially illegal or prohibited activities
          </li>
          <li>Enforcing our agreements and policies with you</li>
          <li>
            Protecting the rights, property, or safety of you, Empower Global,
            or another party
          </li>
          <li>
            Responding to claims that any posting or other content violates
            third-party rights
          </li>
          <li>Resolving disputes</li>
        </NestedList>
      </li>

      <li>
        <Title>Corresponding with You</Title>
        <NestedList>
          <li>
            Responding to correspondence that we receive from you, contacting
            you when necessary or requested, and/or sending you information
            about Empower Global or the Services
          </li>
          <li>
            Sending emails and other communications according to your
            preferences, or that display content that we think will interest you
          </li>
        </NestedList>
      </li>
    </List>

    <Paragraph space>
      If you have any questions about our marketing practices, you may contact
      us at any time as set forth in “Contact Us”{' '}
      <NavLink to={ROUTES.CONTACT_US}>below</NavLink>.
    </Paragraph>
  </>
);

export default PersonalInfoCollect;
