import { FONTS } from 'styles';
import { List, ListItem, Paragraph, TableShipping, Title } from '../styles';

const ShippingDelivery = () => {
  return (
    <div>
      <Title>Shipping & Delivery</Title>
      <Paragraph>
        At this time, Empower Global ships domestically to US only
      </Paragraph>

      <Paragraph space>We currently offer the following:</Paragraph>
      <List>
        <ListItem>
          FREE Standard Shipping on all contiguous U.S. merchandise orders over
          $75 (please allow 7-10 business days for processing and shipping to
          receive your order)
        </ListItem>
        <ListItem>$8.99 flat fee for orders under $75</ListItem>
      </List>

      <Paragraph space>
        Processing time usually takes 2-3 business days. Delivery times are
        based on orders placed between Monday-Friday.
      </Paragraph>

      <TableShipping aria-label="simple table">
        <thead>
          <tr>
            <td>
              <Paragraph
                style={{
                  textDecoration: 'underline',
                  fontFamily: FONTS.Helvetica.medium,
                }}
              >
                Shipping Method
              </Paragraph>
            </td>
            <td>
              <Paragraph
                style={{
                  textDecoration: 'underline',
                  fontFamily: FONTS.Helvetica.medium,
                }}
              >
                Cost total
              </Paragraph>
            </td>
            <td>
              <Paragraph
                style={{
                  textDecoration: 'underline',
                  fontFamily: FONTS.Helvetica.medium,
                }}
              >
                Total Delivery Time (including processing time)
              </Paragraph>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Paragraph>Standard Shipping</Paragraph>
            </td>
            <td>
              <Paragraph>$8.99</Paragraph>
            </td>
            <td>
              <Paragraph>
                7-10 business days (up to 21 days for APO/FPO/DPO Military
                Addresses)
              </Paragraph>
            </td>
          </tr>
        </tbody>
      </TableShipping>
    </div>
  );
};

export default ShippingDelivery;
