import styled from 'styled-components';
import { Typography, AccentColoredButton } from 'components';
import { COLORS, FONTS } from 'styles';

export const LoyaltyRulesWrapper = styled.div`
  padding: 50px 90px 20px;
  margin-top: 60px;
  background: ${COLORS.accentBackground};
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: 35px 45px 20px;
    margin-top: 40px;
  }

  @media (max-width: 600px) {
    padding: 35px 20px 20px;
  }
`;

export const LoyaltyTermsWrapper = styled.div`
  background: ${COLORS.black};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const LoyaltyTermsText = styled(Typography).attrs({
  type: 'subtitle2',
})`
  font-style: italic;
  text-align: center;
  color: ${COLORS.white};
  font-size: 27px;
  padding: 0 5px;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 32px;
  line-height: 38px;
  color: ${COLORS.black};
  text-transform: uppercase;
  margin-bottom: 26px;

  @media (max-width: 1000px) {
    font-size: 24px;
    margin-bottom: 21px;
  }
`;

export const List = styled.ul`
  width: 100%;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: disc;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
`;

export const ListItem = styled.li`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 24px;
  line-height: 35px;
  margin-left: 20px;
  color: ${COLORS.black};

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 31px;
  }
`;

export const StyledColoredButton = styled(AccentColoredButton)`
  padding: 12px 28px;
  margin: 0 29px;

  & p {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;
