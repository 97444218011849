import { ProductAttributeValueType } from 'types/ProductsTypes';
import { COLORS } from 'styles';
import BasicSelect from './BasicSelect';
import { StyledMenuItem, StyledListItemText, EllipsisText } from '../styles';

const ProductDetailOptionSelect = ({
  items,
  selectItem,
  selectedAttributeValue,
  disabled,
}: {
  items: ProductAttributeValueType[];
  selectItem: (item: ProductAttributeValueType) => void;
  selectedAttributeValue: ProductAttributeValueType | undefined;
  disabled: boolean;
}) => {
  const handleClick = (_: any, item: ProductAttributeValueType) => {
    selectItem(item);
  };

  const renderLabel = () => (
    <EllipsisText>{selectedAttributeValue?.value}</EllipsisText>
  );

  return (
    <BasicSelect
      renderLabel={renderLabel}
      optionValues={[]}
      formControlSx={{ width: '100%' }}
      sxStyles={{
        height: 30,
        fontSize: 14,
        fontWeight: 500,
        borderBottom: `1px solid ${COLORS.black}`,
      }}
      disabled={disabled}
    >
      {items &&
        items.map((item: ProductAttributeValueType) => (
          <StyledMenuItem
            value={item.value}
            onClick={(event: any) => handleClick(event, item)}
            key={item.value}
            disabled={item.isDisabled}
          >
            <StyledListItemText
              selected={selectedAttributeValue?.value === item.value}
            >
              {item.name}
            </StyledListItemText>
          </StyledMenuItem>
        ))}
    </BasicSelect>
  );
};

export default ProductDetailOptionSelect;
