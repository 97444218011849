import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEqual, pick } from 'lodash';

import { ColoredButton, CustomNavLink, Typography } from 'components';
import OrderCardItem from 'components/OrdersComponents/OrderCardItem';
import ReturnReasonForm from 'components/Forms/ProductReturnForm/ReturnReasonForm';
import ReturnCommentForm from 'components/Forms/ProductReturnForm/ReturnCommentForm';
import {
  ReturnSummary,
  ReturnToAddress,
  ReturnToCard,
} from 'components/OrdersComponents/ReturnsComponents/ReturnInfoBlocks';
import ROUTES from 'router/Routes';
import { ProductItemsType, ReturnPlisInfo } from 'types/OrdersTypes';
import useDimensions from 'hooks/useDimensions';
import {
  getCustomerData,
  getCustomerOrderListsLoader,
  getOrderDetails,
  getReturnParamsOrder,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { CustomerAddressType } from 'eg_SFCC_FE_core/types';
import { BREAKPOINTS } from 'styles';
import {
  BlockTitle,
  OrderNumber,
  ReturnCard,
  ReturnCardsWrapper,
  ReturnInfoBlocks,
  ReturnReasonToCardWrapper,
  ReturnReasonSummaryWrapper,
  ReturnReasonToAddressWrapper,
  StyledRightBlockWrapper,
  EditButton,
} from './styles';

const OrderReturnReasonPage = () => {
  const [selectedReasons, setSelectedReasons] = useState<{
    [id: string]: string;
  }>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const order = useSelector(getOrderDetails);
  const customerData = useSelector(getCustomerData);
  const isLoading = useSelector(getCustomerOrderListsLoader);
  const returnParams = useSelector(getReturnParamsOrder);
  const { orderNo } = useParams();
  const { width } = useDimensions();
  const dispatch = useAppDispatch();
  const shippToAddress = customerData?.addresses.find(
    (address: CustomerAddressType) => {
      if (returnParams.c_addressId) {
        return address.addressId === returnParams.c_addressId;
      }

      const customerAddress = pick(address, [
        'address1',
        'countryCode',
        'city',
        'phone',
        'postalCode',
        'stateCode',
      ]);

      const shippingAddress = pick(order?.shipments[0].shippingAddress, [
        'address1',
        'countryCode',
        'city',
        'phone',
        'postalCode',
        'stateCode',
      ]);

      return isEqual(customerAddress, shippingAddress);
    },
  );

  const paymentInstrument =
    order?.paymentInstruments[(order?.paymentInstruments.length || 1) - 1];

  const reasons = order?.c_returnReasons.map(
    (reason: { reasonId: string; reasonText: string }) => {
      return {
        code: reason.reasonText,
        label: reason.reasonText,
      };
    },
  );

  const onSubmit = async (data: string, e: any) => {
    e.target.reset();
    setErrorMessage('');

    const updatedPli = returnParams.c_plis_info.map((pli: ReturnPlisInfo) => {
      if (selectedReasons[pli.id]) {
        return { ...pli, reason: selectedReasons[pli.id] };
      }

      return pli;
    });

    if (!updatedPli.every((pli: ReturnPlisInfo) => pli.reason)) {
      setErrorMessage('Choose all return reasons');
      return;
    }

    try {
      await dispatch(
        AsyncThunks.returnProductLineItem({
          ...returnParams,
          c_plis_info: updatedPli,
          c_returnComment: data,
          c_addressId: shippToAddress?.addressId || '',
        }),
      ).unwrap();

      navigate(
        `${ROUTES.ACCOUNT_MY_ORDERS}/${order?.orderNo}/return-confirmation`,
      );
    } catch (er: any) {
      setErrorMessage(er?.statusMessage?.split('_').join(' '));
    }
  };

  return (
    <StyledRightBlockWrapper>
      <ReturnCardsWrapper>
        <BlockTitle>Returns</BlockTitle>
        <OrderNumber>{orderNo}</OrderNumber>
        {errorMessage ? (
          <Typography
            color="accent"
            type="subtitle2Helv"
            style={{ marginTop: 20 }}
          >
            {errorMessage}
          </Typography>
        ) : null}
        {returnParams.c_plis_info?.map((pli: ReturnPlisInfo) => {
          const item = order?.productItems.find(
            (productItem: ProductItemsType) => productItem.itemId === pli.id,
          );

          const handleChange = (reason: string) => {
            setSelectedReasons((prevReasons: { [id: string]: string }) => {
              if (item) {
                return {
                  ...prevReasons,
                  [item?.itemId]: reason,
                };
              }

              return prevReasons;
            });
          };

          if (item && reasons) {
            return (
              <ReturnCard key={pli.id}>
                <OrderCardItem productItem={item} quantityToReturn={pli.qty} />
                <ReturnReasonForm items={reasons} onChange={handleChange} />
              </ReturnCard>
            );
          }
        })}
        <ReturnCommentForm onSubmit={onSubmit} />
        <div style={{ display: 'flex' }}>
          <ReturnReasonToAddressWrapper>
            <ReturnToAddress shippingAddress={shippToAddress} />
          </ReturnReasonToAddressWrapper>
          <CustomNavLink
            to={`${ROUTES.ACCOUNT_MY_ORDERS}/${orderNo}/return-addresses`}
          >
            <EditButton>edit</EditButton>
          </CustomNavLink>
        </div>
        {width >= BREAKPOINTS.m ? (
          <ColoredButton
            width="105px"
            type="submit"
            form="return-comment-form"
            aria-label="submit comment"
            style={{ marginTop: 20 }}
          >
            submit
          </ColoredButton>
        ) : null}
      </ReturnCardsWrapper>
      <ReturnInfoBlocks>
        <ReturnReasonToCardWrapper>
          <ReturnToCard
            cardType={paymentInstrument?.paymentCard.cardType || ''}
            numberLastDigits={
              paymentInstrument?.paymentCard.numberLastDigits || ''
            }
          />
        </ReturnReasonToCardWrapper>
        <ReturnReasonSummaryWrapper>
          <ReturnSummary order={order} />
        </ReturnReasonSummaryWrapper>
      </ReturnInfoBlocks>
      {width < BREAKPOINTS.m ? (
        <ColoredButton
          width="105px"
          type="submit"
          form="return-comment-form"
          aria-label="submit comment"
          style={{ marginTop: 20 }}
          isLoading={isLoading}
        >
          submit
        </ColoredButton>
      ) : null}
    </StyledRightBlockWrapper>
  );
};

export default OrderReturnReasonPage;
