import styled, { css } from 'styled-components';

import { Typography } from 'components';
import { BREAKPOINTS, COLORS } from 'styles';

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  overflow: hidden;
`;

export const CardTop = styled.div<{
  hover: boolean;
  backgroundImage: string;
  backgroundSize?: string;
  height?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height || '80%'};
  background-size: ${({ backgroundSize }) => backgroundSize || 'contain'};
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  transition: 0.4s ease;
  cursor: pointer;

  ${({ hover }) =>
    hover &&
    css`
      transform: scale(1.1);
      background-color: rgba(0, 0, 0, 0.3);
      filter: brightness(0.7);
    `}

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
    `}

  ${({ backgroundImage }) =>
    !backgroundImage &&
    css`
      &::before {
        content: 'No image';
        position: absolute;
        color: ${COLORS.searchInputBorder};
        top: 50%;
        left: 50%;
        margin-left: -35px;
        z-index: -1;
      }
    `}
`;

export const CardBottom = styled.div<{
  minHeight?: string;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${COLORS.white};
  min-height: ${({ minHeight }) => minHeight || '20%'};
  padding: 0.5rem 0 0;
`;

export const ProductInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  display: flex;
  flex-direction: column;
  font-size: 20px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
`;

export const ProductBrand = styled(Typography).attrs({
  type: 'title2Helv',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 18px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const ProductName = styled(Typography).attrs({
  type: 'body2Helv',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;

  @media (max-width: ${BREAKPOINTS.m}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const OpacityButton = styled.div<{ show: boolean }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s ease;

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

export const ViewDetailsButton = styled(OpacityButton)`
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const ViewDetailsText = styled(Typography).attrs({
  type: 'body2Helv',
  color: 'primaryInverted',
})`
  white-space: nowrap;
  font-size: 0.7em;
`;

export const BottomProductPrice = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  font-size: 0.7em;
  line-height: 1.2em;
`;

export const PaleText = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  color: ${COLORS.pale};
  font-size: 0.7em;
  line-height: 1.2em;
`;

export const QuickView = styled(Typography).attrs({
  type: 'bodyHelv',
  color: 'primaryInverted',
})`
  margin-left: 5px;
`;

export const QuickViewWrapper = styled(OpacityButton)`
  z-index: 2;
  right: 5%;
  top: 3%;
  display: flex;
  cursor: pointer;
`;
