import { memo, ReactNode } from 'react';
import { CustomNavLink } from 'components';

import { ArrowIcon } from 'assets/svg';
import { AnimatedArrowLinkWrapper } from './styles';

interface IAnimatedArrowLink {
  children: ReactNode;
  to?: string;
  onClick?: () => void;
  className?: string;
}

const AnimatedArrowLink = ({
  children,
  to,
  className,
  onClick,
}: IAnimatedArrowLink) => {
  if (to) {
    return (
      <CustomNavLink to={to}>
        <AnimatedArrowLinkWrapper className={className} onClick={onClick}>
          {children}
          <ArrowIcon />
        </AnimatedArrowLinkWrapper>
      </CustomNavLink>
    );
  }
  return (
    <AnimatedArrowLinkWrapper className={className} onClick={onClick}>
      {children}
      <ArrowIcon />
    </AnimatedArrowLinkWrapper>
  );
};

export default memo(AnimatedArrowLink);
