import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

import ContactInfoBlock from 'components/InfoPagesComponents/ContactInfoBlock/ContactInfoBlock';
import WebToLeadForm from 'components/Forms/SFForms/WebToLeadForm';
import {
  PageWrapper,
  HeaderTitle,
  InfoTitle,
  FormBlockWrapper,
} from './styles';

const ContactUsCommunityPage = () => {
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const recaptchaRef = useRef<any>();

  const getRecaptchaToken = async () => {
    const token = await recaptchaRef.current.executeAsync();
    setRecaptchaToken(token);
  };

  useEffect(() => {
    getRecaptchaToken();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Join Seller Community</title>
        <meta name="description" content="Join Seller Community" />
      </Helmet>
      <PageWrapper>
        <FormBlockWrapper>
          <HeaderTitle>JOIN SELLER COMMUNITY</HeaderTitle>
          <InfoTitle>
            Love what you see? We hope so! We&apos;re always looking to expand,
            so if you&apos;d like to join our seller community, complete this
            form and we&apos;ll be reaching out. Find out more here.
          </InfoTitle>
          <WebToLeadForm recaptchaToken={recaptchaToken} />
        </FormBlockWrapper>

        <ContactInfoBlock />
      </PageWrapper>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        size="invisible"
      />
    </>
  );
};

export default ContactUsCommunityPage;
