import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardContent, CardActions } from '@mui/material';

import {
  NextArrow,
  PrevArrow,
} from 'components/CarouselArrows/CarouselCustomArrows';
import { Loader } from 'components';
import {
  getBrandsList,
  getBrandsListLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';

import routes from 'router/Routes';

import {
  StyledContainer,
  StyledHeading,
  StyledCopy,
  StyledBrandsButton,
  SliderWrapper,
  StyledSlider,
  StyledBrandName,
  StyledBrandFounders,
  StyledBrandDescription,
  StyledDivider,
  StyledCard,
  StyledCardMedia,
  StyledContainerInner,
  StyledBrandLink,
  StyledAllBrandsLink,
} from './styles';

const HomepageFoundersSection = () => {
  const brandsList = useSelector(getBrandsList);
  const isLoading = useSelector(getBrandsListLoader);
  const dispatch = useDispatch();
  const fetchBrands = async () => {
    await dispatch(AsyncThunks.getBrandList());
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const brands: any = [
    'U2VsbGVyLTgz',
    'U2VsbGVyLTE0Mw==',
    'U2VsbGVyLTEwMA==',
    'U2VsbGVyLTYx',
    'U2VsbGVyLTI4MQ==',
    'U2VsbGVyLTI1Mw==',
  ]
    .map((brandId: string) => {
      return brandsList.find((brand: any) => brand?.id === brandId);
    })
    .filter((brand) => !!brand);

  const sliderSettings = {
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow color="black" />,
    nextArrow: <NextArrow color="black" />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledContainer>
      <StyledContainerInner>
        <StyledHeading>Meet the founders</StyledHeading>

        <StyledCopy>
          Meet the Founders on Empower Global – visionary artisans shaping the
          realms of fashion, beauty, art, and beyond, all rooted in the vibrant
          tapestry of the Black diaspora. Empower Global&apos;s mission is to
          magnify and expand Black entrepreneurship, ensuring that their
          exceptional creations transcend borders and are effortlessly
          accessible to the world.
        </StyledCopy>

        {!isLoading && (
          <SliderWrapper>
            <StyledSlider {...sliderSettings}>
              {brandsList?.length &&
                brands.map((brand: any) => (
                  <StyledCard>
                    <StyledCardMedia
                      sx={{ height: 380 }}
                      image={brand.businessOwnerImage}
                    />
                    <CardContent>
                      <StyledBrandName>{brand.businessName}</StyledBrandName>
                      <StyledBrandFounders>
                        {brand.firstName} {brand.lastNmae}
                      </StyledBrandFounders>
                      <StyledDivider
                        style={{ maxWidth: '90px', margin: '20px auto' }}
                      />
                      <StyledBrandDescription>
                        {brand.businessOwnerBio}
                      </StyledBrandDescription>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                      <StyledBrandLink to={`${routes.BRANDS}/${brand.id}`}>
                        Shop Brand
                      </StyledBrandLink>
                    </CardActions>
                  </StyledCard>
                ))}
            </StyledSlider>
          </SliderWrapper>
        )}

        {isLoading && <Loader />}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledAllBrandsLink to={routes.BRANDS}>
            <StyledBrandsButton>See all brands</StyledBrandsButton>
          </StyledAllBrandsLink>
        </div>
      </StyledContainerInner>
    </StyledContainer>
  );
};

export default HomepageFoundersSection;
