import { CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import { Subtitle, Title, Wrapper, StyledColoredButton } from './styles';

const JoinSection = () => {
  return (
    <Wrapper>
      <Title>Get with the program</Title>
      <Subtitle>Join EG Elite and earn while you shop</Subtitle>
      <CustomNavLink to={ROUTES.LOYALTY_PROGRAM}>
        <StyledColoredButton aria-label="join eg elite today">
          join today
        </StyledColoredButton>
      </CustomNavLink>
    </Wrapper>
  );
};

export default JoinSection;
