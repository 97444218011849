import { Loader } from 'components';
import ProductCard from 'components/ProductCard/ProductCard';
import { ProductType } from 'types/ProductsTypes';
import { ProductCardsWrapper, ProductCardWrapper, Wrapper } from './styles';

const TrendingProducts = ({
  trendingProducts,
  isLoading,
}: {
  trendingProducts?: ProductType[];
  isLoading: boolean;
}) => {
  const cardNum = 6;
  const shownProducts = trendingProducts?.slice(0, cardNum);

  return (
    <Wrapper>
      <ProductCardsWrapper>
        {isLoading ? <Loader /> : null}
        {!isLoading && shownProducts
          ? shownProducts.map((product: ProductType) => {
              return (
                <ProductCardWrapper key={product.productId}>
                  <ProductCard product={product} />
                </ProductCardWrapper>
              );
            })
          : 'There are no trending products'}
      </ProductCardsWrapper>
    </Wrapper>
  );
};

export default TrendingProducts;
