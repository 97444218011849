import { styled as muiStyled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const CardsWrapper = muiStyled(Grid)`
  min-height: 390px;

  &.MuiGrid-root {
    height: fit-content;
  }
`;

export const BrandCardWrapper = muiStyled(Grid)``;
