import { VacancyType } from 'types/VacancyTypes';

export const vacancies: VacancyType[] = [
  {
    id: '1',
    position: 'Creative Director',
    city: 'Atlanta',
    type: 'Hybrid',
    category: 'Creative',
    roleOverview:
      'As Empower Global’s Creative Director you will be responsible for developing global multi-platform creative campaigns. You will conceptualize and create promotional campaigns across Empower Global’s platform: Fashion, Beauty, and Art, and additional categories as introduced. The Creative Director will be responsible for leading and growing a fast-paced team and delivering projects ranging from strategic planning, visual identity, signage, photoshoots, video production, and branded content. The Director must demonstrate exceptional vision, oversee projects from conception to completion, and ensure successful project outcomes. As Creative Director, you’ll also interface with, marketing, partnerships, e-commerce & events as needed to provide successful flow of information and support for new and existing projects.',
    reportingStructure:
      'This works adjacently across the marketing organization and reports to the Chief Executive Officer, Empower Global, Khadijah Robinson. ',
    information: [
      {
        name: 'Responsibilities',
        details: [
          'Lead the creative direction for multiple projects for the brand, often simultaneously',
          'Develop and manage the brand look and feel across all media platforms and channels',
          'Partner with the Combs Enterprises creative team to ensure cohesive brand identity',
          'Research and predict trends relevant to current and new audiences; maintain a pulse on creative trends in digital marketing and culture, especially in fashion, beauty, art, and maintain creative sensibility that extends across content genres',
          'Utilize a diverse artistic toolkit to execute the creative direction on projects such as marketing campaigns, logo development, live experiential event activations, brand partners, and more',
          'Develop big, out-of-the- box ideas that get results and articulate / sell these big ideas to senior leadership',
          'Develop proposals for multi-media extensions that extend campaigns across our organization’s ecosystem and brands',
          'Drive cross-functional relationship building to ensure alignment on deliverable quality and creative direction',
          'Manage full lifecycle of all creative projects, including applicable team members, agencies contractors or consultants.',
          'Find, develop, and track working relationships with new creative and production vendors, as well as strengthening partnerships with current vendors, to conceive / execute creative',
          'Quickly learn the design aesthetic and artistic preferences of Empower Global as a brand',
        ],
      },
      {
        name: 'SKILLS & REQUIREMENTS',
        details: [
          'Bachelor’s degree in Graphic Design, Photography or related fields or equivalent work experience.',
          'A minimum of 8+ years relevant experience as a Creative Director, at least 2years as a Creative Director in a leadership role.',
          'Background in the visual arts discipline, e.g., art director, graphic designer, photographer, etc.',
          'A portfolio featuring a wide range of creative and integrated campaigns, a diversity of ideas, and approaches to problem solving required',
          'Experience in a quick moving and cross-functional focused environment',
          'Experience working directly with talent and creators to collaborate on campaigns',
          'Experience managing a high volume of creative and campaign types in parallel',
          'Ability to maintain strict confidentiality when working on projects with Celebrities, Influencers, or other Public Figures',
          'Maintain momentum around creative concepts, as a core part of the team that is responsible for bringing them into the world',
          'Collaborative and team-oriented approach with the ability to quickly revise creative direction based on feedback',
          'Proficient in platform content creative and / or Digital, Social, AV, Print, DOOH advertising formats and design benchmarks',
        ],
      },
      {
        name: 'Abilities',
        details: [
          'Scrappy and execution-focused in a fast-paced, matrixed, and entrepreneurial environment.',
          'Sense of urgency with strong aptitude for learning, problem-solving and making savvy decisions.',
          'Encourages and constructively delivers feedback.',
          'Demonstrates ownership and accountability.',
          'Deep awareness and ability to identify new and upcoming trends',
          'Strong emotional and cultural intelligence; experience working across geographies and cultural contexts.',
          'Cultivates and demonstrates trust, respect, compassion, and empathy for all stakeholders',
          'Creative, imaginative, and innovative',
        ],
      },
      {
        name: 'Looking for People who',
        details: [
          'Center Blackness',
          'Operate with integrity',
          'Listen and learn',
          'Are ambitious and embrace mistakes',
          'Get sh*t done',
          'Can’t stop, won’t stop!',
        ],
      },
      {
        name: 'Combs Core Values & Abilities',
        details: [
          'Goal-oriented',
          '24/7 Mentality',
          'Open-minded & Transparent',
          'Self-Starter/Entrepreneurial',
          'Driven to Excellence',
          'Holds High Standards',
          'Determination & Grit',
          'Ability to give and receive feedback',
        ],
      },
    ],
  },
  {
    id: '2',
    position: 'Solutions Architect & lead engineer',
    type: 'Remote',
    category: 'technology',
    roleOverview:
      'As Empower Global’s Solutions Architect & Lead Engineer, you will play a key role in building a vibrant communal atmosphere for brands and shoppers in the Empower Global ecosystem. You will create the continued overall technical vision for our business. You will build the bridge between the business problem and the technology solution, outlining each of the phases and requirements required to make that solution work. Ultimately, you will deliver critical initiatives and develop and implement ecommerce solutions in partnership with various business, teams, and stakeholders.',
    reportingStructure:
      'This role supports the Chief Technology Officer, who reports to the Chief Executive Officer, CE Marketplace Inc.',
    information: [
      {
        name: 'Responsibilities',
        details: [
          'Building and integrating information systems to meet the Company’s needs',
          'Assessing the systems architecture currently in place and working with technical staff to recommend solutions to improve it',
          'Resolving technical problems as they arise',
          'Providing supervision and guidance to development teams',
          'Continually researching current and emerging technologies and proposing changes where needed',
          'Informing various stakeholders about any problems with the current technical solutions being implemented',
          'Assessing the business impact that certain technical choices have',
          'Providing updates to stakeholders on product development processes, costs, and budgets',
          'Work closely with Product Owner and cross functional team to design and engineer solutions with',
          'B2C Commerce requirements',
          'Lead technical design efforts to develop and execute testing, training and documentation',
          'Design, code, unit test, and code review iterative improvements to platform',
          'Lead the estimation of development tasks',
          'Lead the integration of Salesforce with other systems (ERP, company website, social media, etc.)',
          'Create custom reports and dashboards',
          'Independently interface with client technical teams and business owners as needed during the designand implementation',
        ],
      },
      {
        name: 'SKILLS & REQUIREMENTS',
        details: [
          'Bachelor / Masters in CS/Information Technology/related field or the equivalent work experience',
          'Deep expertise of eCommerce architecture',
          'Must have experience mentoring technical contributors',
          'Must have DevOps Experience with Google Cloud, Kubernetes, and Github',
          'Experience working with APIs to build headless eCommerce applications',
          'Experience with modern applications/development, architectural patterns, and languages such as API first strategies/microservices, SOA, responsive design, JavaScript, JSON, REST, Demandware Script, Java, Node.js, etc.',
          'Application development experience in Omni-Channel (online, stores, and CRM), Mobile commerce solutions is highly desirable',
          'Salesforce Commerce Cloud Certification is preferred',
          'Strong written and verbal communication skills',
          'Excellent time-management skills',
          'Strong soft skills, including attention to detail, problem-solving',
          'Implementation in newer brands and markets',
          'SFRA experience is highly preferred',
          'Node.js experience is required',
          'React.js and mobile experience preferred',
          '5+ years as a Technical Architect or Technical Lead',
          '5+ years of E-commerce experience',
        ],
      },
      {
        name: 'Abilities',
        details: [
          'Excellent organizational, verbal and written communication skills',
          'Ability to translate technical information to non-technical stakeholders',
          'Driven to Results',
          'Process-Oriented',
          'Ability to adapt to a dynamic work environment and be creative within a team',
          'Systematic Thinking Abilities',
          'Ability to See Multiple Possibilities',
          'Creativity in designing unique technical solutions that scale',
        ],
      },
      {
        name: 'Looking for People who',
        details: [
          'Center Blackness',
          'Operate with integrity',
          'Listen and learn',
          'Are ambitious and embrace mistakes',
          'Get sh*t done',
          'Can’t stop, won’t stop!',
        ],
      },
      {
        name: 'Combs Core Values & Abilities',
        details: [
          'Goal-oriented',
          '24/7 Mentality',
          'Open-minded & Transparent',
          'Self-Starter/Entrepreneurial',
          'Driven to Excellence',
          'Holds High Standards',
          'Determination & Grit',
          'Ability to give and receive feedback',
        ],
      },
    ],
  },
  {
    id: '3',
    position: 'Custom server side developer',
    type: 'Remote',
    category: 'technology',
    roleOverview:
      'CE Marketplace LLC d.b.a. Empower Global, a Combs Enterprises Company, is seeking a Custom Server Side Developer, who will play a key role in building the platform that delivers extraordinarily high-quality Black-owned products and an innovative experience to consumers. In this role, you will be responsible for building out the backend infrastructure for our e-commerce platform and providing ongoing maintenance as we scale. ',
    reportingStructure:
      'This role reports to the Solutions Architect & Lead Engineer, who reports to the Chief Technical Officer, Empower Global.',
    information: [
      {
        name: 'Responsibilities',
        details: [
          'The Custom Server Side Developer will be responsible for implementing solutions for critical production issues across the platform (Google Cloud, Unix/Linux, Windows Server) and applications (Node, Python, etc.)',
          'Compile and analyze data, processes, and codes to troubleshoot problems and identify areas for improvement',
          'Provide Node.js, Kubernetes, and Java development for creation, debugging, and ongoing support of development, staging, and production environments',
          'Interacting directly with developers and support users to resolve technical issues as well as exposure to various technologies including (but not limited to) Google Cloud, AWS, Ci/CD platforms, Salesforce Clouds/Products, Node.js, Python and Java, as well as Linux, RDBMS, NoSQL Datastores, and Windows Server',
          'Optimize the infrastructure for integration with our data analytics platforms',
          'Understand Web application security optimization and content delivery networks (CDNs)',
          'Ability to convey technical concepts to individuals without prior technical knowledge or understanding of topics',
          'Record data and reporting to internal and external stakeholders',
        ],
      },
      {
        name: 'SKILLS & REQUIREMENTS',
        details: [
          "A Bachelor's degree in Computer Science or a related discipline is a strong advantage",
          '4+ years of hands-on experience in server side development',
          'Candidate must be able to code and develop custom coding solutions, as well as work with integrations into third-party software',
          'Experience with cloud environments such as Google Cloud and AWS',
          'They must possess exceptional verbal and written communications skills, an operations-oriented mindset, and a knack for technical firefighting, diagnostics, troubleshooting and problem solving.',
          'Knowledge of systems administration, software development, scripting and building automation tools, and a thorough understanding of the TCP/IP stack and the Unix environment are a must.',
          '2+ years experience with coding in Node.js, Java and other object-oriented languages',
          'Once conditions are safe, the ability to travel as business needs dictate',
        ],
      },
      {
        name: 'other qualifications',
        details: [
          '​​Familiarity with cloud-based collaboration designs',
          'Ability to interface effectively with customers and internal departments such as engineering and operations at the technical level to deliver a solution',
          'Experience in a startup or fast-paced environment',
          'Self-motivated with diligent work ethic',
          'Exhibits and drives a strong commitment to outstanding customer service',
          'Passion for providing positive customer experiences, dedication to follow-up',
          'Ability to hit the ground running with little oversight; ability to work independently',
          'Willingness to learn and be coached',
          'Ability to communicate confidently and concisely to both technologists and non-technologists',
        ],
      },
      {
        name: 'Looking for People who',
        details: [
          'Center Blackness',
          'Operate with integrity',
          'Listen and learn',
          'Are ambitious and embrace mistakes',
          'Get sh*t done',
          'Can’t stop, won’t stop!',
        ],
      },
      {
        name: 'Combs Core Values & Abilities',
        details: [
          'Goal-oriented',
          '24/7 Mentality',
          'Open-minded & Transparent',
          'Self-Starter/Entrepreneurial',
          'Driven to Excellence',
          'Holds High Standards',
          'Determination & Grit',
          'Ability to give and receive feedback',
        ],
      },
    ],
  },
  {
    id: '4',
    position: 'Social Media Manager',
    type: 'Remote',
    category: 'creative',
    roleOverview:
      'As Empower Global’s Social Media Manager you will be responsible for developing and managing global multi-platform social media campaigns to cultivate our social media following and promote the Empower Global’s brand and initiatives. You will be the source of truth for all social media trends across; video, reels, static posts, live video, plus more - living and breathing social media marketing and well-versed on all social media platforms, especially Twitter, Instagram, Meta (FB) & Pinterest. Experienced in creating vibrant, engaging posts with witty captions, monitoring comments, and replying to engage with our audience and develop a relatable consistent company voice. The Social Media Manager will study and have a pulse on which aspects of their campaigns result in the highest engagement and conversions. As Social Media Manager, you’ll also interface heavily with marketing, partnerships, e-commerce & events as needed to provide successful flow of information and support for new and existing projects.',
    reportingStructure:
      'This works adjacently across the marketing organization and reports to the Content & Editorial Director, Empower Global. This is a part-time, contract position.',
    information: [
      {
        name: 'Responsibilities ',
        details: [
          'Regularly craft and post (video/images) engaging content that engage Empower Global’s target market and increases social media following & conversions across sm all platforms',
          'Must be data-driven to optimize social media strategy based on analytics reports',
          'Be in the know of top influencers to partner with & trending influencer strategies',
          'Identify social media events such as an interesting hashtag or a sensitive topic and share appropriate content that aligns with the company’s social media strategy',
          'Monitor online posts that mention the company so they can mediate the impact of negative reviews and share positive testimonials with Empower Global’s marketing and PR team',
        ],
      },
      {
        name: 'SKILLS & REQUIREMENTS',
        details: [
          'Bachelor’s degree in Communications, Public Relations, Marketing or related fields or equivalent work experience.',
          'A minimum of 3 years relevant experience as a Social Media Manager',
          'Great verbal and written communication skills to interact with writers, designers, developers and customers and report to senior management personnel.',
          'Background in the visual arts discipline, e.g., art director, graphic designer, photographer, etc.',
          'A portfolio featuring a range of creative and integrated campaigns, a diversity of ideas, and approaches to problem solving required',
          'Experience in a quick moving and cross-functional focused environment',
          'Experience working directly with talent and creators to collaborate on campaigns',
          'Experience managing a high volume of creative and campaign types in parallel',
          'Ability to maintain strict confidentiality when working on projects with Celebrities, Influencers, or other Public Figures',
          'Maintain momentum around creative concepts, as a core part of the team that is responsible for bringing them into the world',
          'Collaborative and team-oriented approach with the ability to quickly revise creative direction based on feedback',
          'Proficient in all social media platforms and content creative dimensions to maximize post engagement',
        ],
      },
      {
        name: 'Abilities',
        details: [
          'Scrappy and execution-focused in a fast-paced, matrixed, and entrepreneurial environment.',
          'Sense of urgency with strong aptitude for learning, problem-solving and making savvy decisions.',
          'Encourages and constructively delivers feedback.',
          'Demonstrates ownership and accountability.',
          'Deep awareness and ability to identify new and upcoming trends',
          'Strong emotional and cultural intelligence; experience working across geographies and cultural contexts.',
          'Cultivates and demonstrates trust, respect, compassion, and empathy for all stakeholders Creative, imaginative, and innovative',
        ],
      },
      {
        name: 'Looking for People who',
        details: [
          'Center Blackness',
          'Operate with integrity',
          'Listen and learn',
          'Are ambitious and embrace mistakes',
          'Get sh*t done',
          'Can’t stop, won’t stop!',
        ],
      },
      {
        name: 'Combs Core Values & Abilities',
        details: [
          'Goal-oriented',
          '24/7 Mentality',
          'Open-minded & Transparent',
          'Self-Starter/Entrepreneurial',
          'Driven to Excellence',
          'Holds High Standards',
          'Determination & Grit',
          'Ability to give and receive feedback',
        ],
      },
    ],
  },
];
