import styled, { css } from 'styled-components';

import { LikeIcon } from 'assets/svg';
import { COLORS } from 'styles';

export const LikeWrapper = styled.div<{
  isFavorite?: boolean;
  iconPosition?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
}>`
  position: absolute;
  z-index: 2;
  right: ${({ iconPosition }) => {
    return iconPosition?.right || '2%';
  }};
  top: ${({ iconPosition }) => {
    return iconPosition?.top || '2%';
  }};
  left: ${({ iconPosition }) => {
    return iconPosition?.left;
  }};
  bottom: ${({ iconPosition }) => {
    return iconPosition?.bottom;
  }};
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ isFavorite }) =>
    isFavorite &&
    css`
      svg {
        color: ${COLORS.black};
        opacity: 1;
        transform: scale(1.2);
        stroke: ${COLORS.black};
      }
    `}
`;

export const Like = styled(LikeIcon)`
  color: white;
  opacity: 0.6;
  transition: 0.3s;
  stroke: ${COLORS.black};
`;
