/* eslint-disable import/no-cycle */
import Cookies from 'js-cookie';

import { LOCAL_STORAGE_KEYS } from 'appConstants';
import { SocialAuthType } from 'types/AccountTypes';
import axiosInstance from './axiosInstance';
import { CHANNEL_ID, COOKIES_KEYS, GRANT_TYPES } from './apiConstants';
import {
  generateCodeVerifier,
  verifier,
  adaptParamsToURLEncoded,
  updateLocalTokens,
  generateCodeChallenge,
  getRequestUriForGoogleLogin,
} from './helpers';
import endpoints from './endpoints';
import { AuthenticationParamsType, ResetPasswordType } from '../types';

const handleError = (e: any) => {
  // eslint-disable-next-line no-console
  // console.log(e.response);
  throw e;
};

export const getPublicGuestLoginCode = async (generatedVerifier: string) => {
  const codeChallenge = await generateCodeChallenge(generatedVerifier);

  const params = {
    client_id: process.env.REACT_APP_PUBLIC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    hint: 'guest',
    response_type: 'code',
    code_challenge: codeChallenge,
    channel_id: CHANNEL_ID,
  };

  try {
    const response = await axiosInstance.get(
      endpoints.shopper.publicGuestLoginCode,
      { params },
    );

    return response.data;
  } catch (e) {
    handleError(e);
  }
};

export const getLoginTokens = async (
  code: string,
  generatedVerifier?: string,
  redirectUri?: string,
) => {
  const params = {
    client_id: process.env.REACT_APP_PUBLIC_CLIENT_ID,
    channel_id: CHANNEL_ID,
    code_verifier: generatedVerifier || verifier,
    redirect_uri: redirectUri || process.env.REACT_APP_REDIRECT_URI,
    grant_type: GRANT_TYPES.authorization_code,
    code,
  };

  try {
    const response = await axiosInstance.post(
      endpoints.shopper.publicGuestLoginJwtToken,
      adaptParamsToURLEncoded(params),
    );

    return response.data;
  } catch (e) {
    handleError(e);
  }
};

export const getPublicGuestToken = async () => {
  let code;
  const generatedVerifier = generateCodeVerifier();

  try {
    await getPublicGuestLoginCode(generatedVerifier);
  } catch (error: any) {
    const urlParams = new URLSearchParams(error.response.data.queryString);
    const isError = !!urlParams.get('error');

    if (error.response.status === 303 && !isError) {
      code = urlParams.get('code');
    } else {
      return handleError(error);
    }
  }

  try {
    const response = code && (await getLoginTokens(code, generatedVerifier));
    updateLocalTokens(response);

    return response;
  } catch (error: any) {
    return handleError(error);
  }
};

export const silentTokenRefresh = async () => {
  const refreshToken = Cookies.get(COOKIES_KEYS.refreshToken);

  if (refreshToken) {
    const usid = localStorage.getItem(LOCAL_STORAGE_KEYS.usid);

    const params = {
      grant_type: GRANT_TYPES.refresh_token,
      organization_id: process.env.REACT_APP_ORGANIZATION_ID,
      client_id: process.env.REACT_APP_PUBLIC_CLIENT_ID,
      usid,
      refresh_token: refreshToken,
    };

    try {
      const { data } = await axiosInstance.post(
        endpoints.shopper.publicGuestLoginJwtToken,
        adaptParamsToURLEncoded(params),
      );

      updateLocalTokens(data);

      return data;
    } catch (e) {
      handleError(e);
    }
  }

  return null;
};

export const authenticateCustomer = async (
  authParams: AuthenticationParamsType,
  generatedVerifier: string,
) => {
  const codeChallenge = await generateCodeChallenge(generatedVerifier);

  const params = {
    client_id: process.env.REACT_APP_PUBLIC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    channel_id: CHANNEL_ID,
    code_challenge: codeChallenge,
  };

  try {
    const response = await axiosInstance.post(
      endpoints.shopper.authenticateCustomer,
      adaptParamsToURLEncoded(params),
      { auth: authParams },
    );
    return response.data;
  } catch (e) {
    handleError(e);
  }
};

export const redirectCustomerToGoogleLogin = async (hint: SocialAuthType) => {
  const generatedVerifier = generateCodeVerifier();
  localStorage.setItem(LOCAL_STORAGE_KEYS.codeVerifier, generatedVerifier);

  try {
    const requestUriForSocials = await getRequestUriForGoogleLogin(
      generatedVerifier,
      hint,
    );

    window.location.href = requestUriForSocials;
  } catch (error: any) {
    return handleError(error);
  }
};

export const getProductsByIds = async (ids: string) => {
  try {
    const params = {
      siteId: process.env.REACT_APP_SITE_ID,
      allImages: true,
      ids,
    };

    const response = await axiosInstance.get(
      endpoints.shopperProducts.products,
      { params },
    );

    return response.data.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const postSFForm = async (
  recordType: 'Case' | 'Lead',
  data: { [key: string]: string | boolean },
  recaptchaToken: string,
  csrfToken: string,
) => {
  try {
    const response = await axiosInstance.post(
      endpoints.contactUsForms,
      {
        fields: data,
        recordType,
        recaptchaToken,
      },
      { params: { csrf_token: csrfToken } },
    );

    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const syncOrderProductLineItem = async (
  customerId: string,
  productId: string,
  orderNo: string,
) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID };
    const data = {
      c_action: 'syncReturnStatus',
      c_plis: productId,
      c_orderNo: orderNo,
    };

    const response = await axiosInstance.patch(
      `${endpoints.customer.customer}/${customerId}`,
      data,
      { params },
    );

    return JSON.parse(
      response.data.c_lastSyncOrderLineItemsRequestResponses,
    )[0];
  } catch (error: any) {
    return handleError(error);
  }
};

export const sendResetEmail = async (email: string, CSRFToken: string) => {
  try {
    const response = await axiosInstance.post(
      endpoints.reset.sendEmail,
      {
        email,
      },
      {
        params: { csrf_token: CSRFToken },
      },
    );
    return response.data;
  } catch (e) {
    handleError(e);
  }
};

export const resetPassword = async (data: ResetPasswordType) => {
  try {
    const response = await axiosInstance.post(
      endpoints.reset.resetPassword,
      {
        token: data.token,
        password: data.password,
      },
      { params: { csrf_token: data.csrf_token } },
    );
    return response.data;
  } catch (e) {
    handleError(e);
  }
};

export const deleteBasket = async (basketId: string) => {
  const params = { siteId: process.env.REACT_APP_SITE_ID };

  try {
    await axiosInstance.delete(`${endpoints.basket.createBasket}/${basketId}`, {
      params,
    });
  } catch (e) {
    handleError(e);
  }
};

export const getCategoryById = async (categoryId: string) => {
  try {
    const params = { siteId: process.env.REACT_APP_SITE_ID, levels: 2 };

    const response = await axiosInstance.get(
      endpoints.shopperProducts.category(categoryId),
      { params },
    );

    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const generateCSRFToken = async () => {
  try {
    const response = await axiosInstance.post(endpoints.CSRFToken);

    return response.data.csrf;
  } catch (error: any) {
    handleError(error);
  }
};
