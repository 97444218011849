import { ColoredButton } from 'components/Buttons/ColoredButton';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const StyledColoredButton = styled(ColoredButton)`
  width: 143px;
  margin-top: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: ${({ disabled }) => (disabled ? '#CFD1D1' : COLORS.accent)};

  & p {
    font-size: 16px;
    font-family: ${FONTS.TTNorms.medium};
    color: ${({ disabled }) => (disabled ? COLORS.white : COLORS.black)};
  }
`;
