import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AnimatedWrapper } from 'components';
import AccountMenu from 'components/AccountComponents/AccountMenu';
import ROUTES from 'router/Routes';
import useDimensions from 'hooks/useDimensions';
import { getCustomerData } from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { BREAKPOINTS } from 'styles';
import { Wrapper } from './styles';

const AccountPage = () => {
  const location: any = useLocation();
  const customerData = useSelector(getCustomerData);
  const { pathname } = useLocation();
  const { width } = useDimensions();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 0);
  }, [pathname]);

  useEffect(() => {
    if (
      location.state?.prevPath &&
      !location.state?.prevPath.includes(`${ROUTES.ACCOUNT}/`)
    ) {
      dispatch(AsyncThunks.getCustomer(null));
    }
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Account Details</title>
        <meta name="description" content="Account Details" />
      </Helmet>
      <AccountMenu firstName={customerData.firstName} />

      {width > BREAKPOINTS.s && (
        <AnimatedWrapper animationKey={visible.toString()}>
          {visible ? <Outlet /> : <div />}
        </AnimatedWrapper>
      )}
    </Wrapper>
  );
};

export default AccountPage;
