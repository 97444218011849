import { Controller } from 'react-hook-form';
import { MenuItem } from '@mui/material';

import { ArrowDownIcon } from 'assets/svg';
import { StyledTextField } from 'components/Forms/styles';
import { ErrorText, Label } from 'components/Inputs/styles';
import { SelectOptionLabel } from '../styles';

const StatesSelect = ({
  control,
  name,
  label,
  items,
  defaultValue,
  error,
  errorMessage = '',
}: {
  control: any;
  name: string;
  label: string;
  items: { code: string; label: string }[];
  defaultValue: string | undefined;
  error?: any;
  errorMessage: string | undefined;
}) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledTextField
            {...field}
            error={error}
            inputRef={field.ref}
            select
            SelectProps={{
              IconComponent: ArrowDownIcon,
              MenuProps: { style: { maxHeight: 400 } },
            }}
            size="small"
            sx={{
              '& .MuiSelect-icon': { top: '50%', marginTop: '-3px' },
            }}
          >
            {items.map((item: { code: string; label: string }) => (
              <MenuItem value={item.code} key={item.code}>
                <SelectOptionLabel>{item.label}</SelectOptionLabel>
              </MenuItem>
            ))}
          </StyledTextField>
        )}
      />

      {error && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default StatesSelect;
