import { Paragraph, UppercaseTitle, Title } from '../styles';

const Termination = () => (
  <>
    <UppercaseTitle>7. Termination</UppercaseTitle>
    <Title>A. Termination By You</Title>
    <Paragraph>
      We&apos;d hate to see you go, but you may terminate your account with
      Empower Global at any time directly from your account settings.
      Terminating your account will not affect the availability of some of Your
      Content that you posted through the Services prior to termination. Oh, and
      you’ll still have to pay any outstanding bills, of course.
    </Paragraph>
    <Title>B. Termination By Empower Global</Title>
    <Paragraph>
      We may terminate or suspend your account (and any accounts Empower Global
      determines are related to your account) and your access to the Services
      should we have reason to believe you, Your Content, or your use of the
      Services violate our Terms. If we do so, it’s important to understand that
      you don’t have a contractual or legal right to continue to use our
      Services, for example, to sell or buy on our websites. Generally, Empower
      Global will notify you that your account has been terminated or suspended,
      unless you’ve repeatedly violated our Terms or we have legal or regulatory
      reasons preventing us from notifying you.
    </Paragraph>
    <Paragraph>
      If you or Empower Global terminate your account, you may lose any
      information associated with your account, including Your Content.
    </Paragraph>
    <Title>C. We May Discontinue the Services</Title>
    <Paragraph>
      Empower Global reserves the right to change, suspend, or discontinue any
      of the Services for you, any or all users, at any time, for any reason,
      including those laid out in Empower Global’s policies under these Terms of
      Use, or for no reason. We will not be liable to you for the effect that
      any changes to the Services may have on you, including your income or your
      ability to generate revenue through the Services.
    </Paragraph>
    <Title>D. Survival</Title>
    <Paragraph>
      The Terms will remain in effect even after your access to the Services is
      terminated, or your use of the Service ends.
    </Paragraph>
  </>
);

export default Termination;
