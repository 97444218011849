import CustomNavLink from 'components/Links/CustomNavLink';
import ROUTES from 'router/Routes';
import {
  JoinColoredButton,
  JoinDescriptionText,
  JoinDescriptionWrapper,
  JoinText,
  ProgramSectionWrapper,
  TextWithShadow,
} from './styles';

const GetWithTheProgram = () => {
  return (
    <ProgramSectionWrapper>
      <div>
        <TextWithShadow>Get with the program </TextWithShadow>
        <JoinText>Join EG Elite and earn while you shop</JoinText>
      </div>
      <JoinDescriptionWrapper>
        <JoinDescriptionText>
          EG Elite is a free rewards program offered by Empower Global. Account
          holders are eligible to receive tier benefits and rewards. You will
          receive 50 points just for joining! Once you&apos;re a member,
          you&apos;ll earn 1 point for every $1 spent!
        </JoinDescriptionText>
        <CustomNavLink to={ROUTES.LOYALTY_PROGRAM}>
          <JoinColoredButton aria-label="learn more">
            learn more
          </JoinColoredButton>
        </CustomNavLink>
      </JoinDescriptionWrapper>
    </ProgramSectionWrapper>
  );
};

export default GetWithTheProgram;
