/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useImperativeHandle, forwardRef } from 'react';

const StripeInput = forwardRef(
  ({ component: Component, inputRef, ...props }: any, ref: any) => {
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (ref && ref.current) {
          ref.current.focus;
        }
      },
    }));

    return (
      <Component
        onReady={(element: any) => {
          ref.current = element;
        }}
        {...props}
      />
    );
  },
);

export default StripeInput;
