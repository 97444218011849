import { memo, ReactNode } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';

interface IProfileLink {
  children: ReactNode;
  linkProps: LinkProps;
  onClick?: (prop: any) => any;
  style?: any;
  className?: string;
}

const CustomLink = ({
  children,
  linkProps,
  onClick,
  style,
  className,
}: IProfileLink) => {
  const location = useLocation();

  return (
    <Link
      state={{ prevPath: location.pathname }}
      onClick={onClick}
      style={style}
      className={className}
      {...linkProps}
    >
      {children}
    </Link>
  );
};

export default memo(CustomLink);
