import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { InfoBlock, Typography } from 'components';
import PromoCodeForm from 'components/Forms/PromoCodeForm/PromoCodeForm';
import PromoCode from 'components/CheckoutComponents/PromoCode';
import { BasketProductItemType } from 'types/ProductsTypes';
import { CouponItemType } from 'types/CheckoutTypes';
import { OrderListType, ProductItemsType } from 'types/OrdersTypes';
import {
  formatPaymentInstrument,
  formatPrice,
  formatShippingAddress,
} from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { FONTS } from 'styles';
import {
  getBasket,
  getCouponError,
  getCoupons,
} from 'eg_SFCC_FE_core/store/selectors';
import {
  OrderSummaryWrapper,
  OrderSummaryBox,
  TextWrapper,
  Title,
  Text,
  PrintLink,
  Link,
  InfoBlocksWrapper,
  Divider,
} from './styles';
import OrderItems from './OrderItems';

const OrderSummary = ({
  orderDetails,
  products,
  totalAmount,
  subTotalAmount,
  isLoading,
  shippingTotal,
  taxAmount,
  productTotal,
  discountTotal,
  onPromoCodeSubmit,
  handleCouponDelete,
}: {
  orderDetails: OrderListType | null;
  products?: Array<ProductItemsType | BasketProductItemType>;
  totalAmount: number;
  subTotalAmount: number;
  isLoading: boolean;
  shippingTotal?: number;
  taxAmount: number;
  productTotal: number;
  discountTotal: number;
  onPromoCodeSubmit: (promoCode: string, cb: () => void) => void;
  handleCouponDelete: (id: string) => void;
}) => {
  const { pathname } = useLocation();
  const { result } = useSelector(getBasket);
  const coupons = useSelector(getCoupons);
  const couponError = useSelector(getCouponError);
  const isConfirmationPage = pathname === ROUTES.CHECKOUT_CONFIRMATION;
  const shippingAddress = orderDetails?.shipments[0].shippingAddress;
  const paymentInstrument =
    orderDetails?.paymentInstruments[
      (orderDetails?.paymentInstruments.length || 1) - 1
    ];
  const basketShippingAddress = result?.shipments[0]?.shippingAddress;

  return (
    <OrderSummaryWrapper id="section-to-print">
      <OrderSummaryBox>
        <TextWrapper>
          <Title>order summary</Title>
          <div style={{ textAlign: 'right' }}>
            {isConfirmationPage ? (
              <PrintLink onClick={() => window.print()}>Print</PrintLink>
            ) : (
              <Link to={ROUTES.CART}>EDIT</Link>
            )}
          </div>
        </TextWrapper>
        {!isConfirmationPage ? (
          <PromoCodeForm
            onPromoCodeSubmit={onPromoCodeSubmit}
            couponError={couponError}
          />
        ) : null}
        {coupons.length ? (
          <TextWrapper>
            <Text>Applied promo codes:</Text>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {coupons.map((coupon: CouponItemType) => (
                <PromoCode
                  handleCouponDelete={
                    isConfirmationPage ? null : handleCouponDelete
                  }
                  coupon={coupon}
                  key={coupon.couponItemId}
                />
              ))}
            </div>
          </TextWrapper>
        ) : null}
        <TextWrapper>
          <Text>Product Total</Text>
          <Typography type="body2Helv" id="productTotal">
            {formatPrice(productTotal)}
          </Typography>
        </TextWrapper>
        <TextWrapper>
          <Text>Discount Total</Text>
          <Typography type="body2Helv" id="discountTotal">
            {formatPrice(discountTotal)}
          </Typography>
        </TextWrapper>
        <TextWrapper>
          <Text>Subtotal</Text>
          <Typography type="body2Helv" id="subTotal">
            {formatPrice(subTotalAmount)}
          </Typography>
        </TextWrapper>
        {basketShippingAddress ? (
          <TextWrapper>
            <Text>Tax Amount</Text>
            <Typography type="body2Helv" id="taxAmount">
              {formatPrice(taxAmount || 0)}
            </Typography>
          </TextWrapper>
        ) : null}
        <TextWrapper>
          <Text>Shipping Fee</Text>
          <Typography type="body2Helv" id="shippingFee">
            {formatPrice(shippingTotal || 0)}
          </Typography>
        </TextWrapper>
        <Divider />
        <TextWrapper style={{ paddingBottom: 0 }}>
          <Text style={{ fontFamily: FONTS.Helvetica.bold }}>Total</Text>
          <Typography
            type="body2Helv"
            style={{ fontFamily: FONTS.Helvetica.bold }}
            id="totalAmount"
          >
            {formatPrice(totalAmount)}
          </Typography>
        </TextWrapper>
        <div style={{ margin: '20px 0' }}>
          {result?.c_totals?.discounts?.length
            ? result?.c_totals?.discounts.map(
                (discount: { callOutMsg: string; UUID: string }) => (
                  <Typography key={discount.UUID} color="accent">
                    {discount.callOutMsg}
                  </Typography>
                ),
              )
            : null}
        </div>
      </OrderSummaryBox>
      <div>
        {!products || !products.length ? (
          <Text>Products can&apos;t be displayed</Text>
        ) : (
          <div className="checkout_component--has-background">
            <Title style={{ marginBottom: '20px' }}>
              Shopping Cart ({products.length})
            </Title>
            <OrderItems products={products} showAvailabilityMessage={false} />
          </div>
        )}
      </div>
      {isConfirmationPage && paymentInstrument && shippingAddress ? (
        <InfoBlocksWrapper>
          <InfoBlock
            id="paymentInformation"
            data={formatPaymentInstrument(paymentInstrument)}
            title="payment Information"
          />
          <InfoBlock
            id="shippingAddress"
            data={formatShippingAddress(shippingAddress)}
            title="shipping address"
          />
        </InfoBlocksWrapper>
      ) : null}
    </OrderSummaryWrapper>
  );
};

export default OrderSummary;
