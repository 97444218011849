import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useGoogleTags from 'hooks/useGoogleTags';
import { Wrapper, PageTitle, Subtitle, BodyText } from './styles';

const AboutPage = () => {
  const { addPageView } = useGoogleTags();

  useEffect(() => {
    addPageView('about us');
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Destination for Black-owned Brands | Empower Global</title>
        <meta
          name="description"
          content="Empower
          Global's digital marketplace elevates the Black economy by creating
          opportunities for Black entrepreneurs to build and scale successful
          businesses and for everyone to “Shop Black” daily with ease."
        />
      </Helmet>

      <PageTitle>ABOUT</PageTitle>
      <Subtitle>Empower Global</Subtitle>
      <BodyText>
        Founded by Sean “Diddy” Combs in 2021 and under the umbrella of Combs
        Global, Empower Global is an immersive e-commerce experience offering
        best in class products - apparel, beauty, fashion, art, travel - from
        Black-owned businesses. With culture and community at its core, Empower
        Global’s digital marketplace elevates the Black economy by creating
        opportunities for Black entrepreneurs to build and scale successful
        businesses and for everyone to “Shop Black” daily with ease.
        Headquartered in Atlanta Empower Global is the premier destination to
        discover and explore Black-owned businesses worldwide. Join us and the
        culture.
      </BodyText>
    </Wrapper>
  );
};

export default AboutPage;
