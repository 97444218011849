export const USClothingSizesOrder = [
  'xxs',
  '2xs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  '2x',
  '2xl',
  'xxl',
  '3x',
  '3xl',
  'xxxl',
  '4x',
  '4xl',
  'xxxxl',
  '5x',
  '5xl',
  'xxxxxl',
  '6x',
  '6xl',
  'xxxxxxl',
];
