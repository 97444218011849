enum ProductTypeEnum {
  'item',
  'master',
  'variation_group',
  'variant',
  'bundle',
  'set',
}

export type ImageType = {
  alt: string;
  link: string;
  title: string;
};

export type ProductVariantType = {
  orderable: boolean;
  price: number;
  productId: string;
  variationValues: { [key: string]: string };
};

export type ProductCategoryType = {
  id: string;
  name: string;
};

export type ProductAttributeValueType = {
  description: string;
  name: string;
  orderable: boolean;
  value: string;
  isDisabled?: boolean;
  productAttributeId?: string;
};

export type ProductAttributeType = {
  id: string;
  name: string;
  values: ProductAttributeValueType[];
};

export type ProductType = {
  id: string;
  currency: string;
  name: string;
  hitType: string;
  image: ImageType;
  orderable: boolean;
  price: number;
  productId: string;
  productName: string;
  productType: {};
  imageGroups: any[];
  representedProduct: {};
  representedProducts: {}[];
  variationAttributes: ProductAttributeType[];
  c_marketplacerSellerName: string;
  basePrice: number;
  quantity: number;
  itemId: string;
  variationValues: any;
  c_prices: {
    list: {
      currency: string;
      decimalPrice: string;
      formatted: string;
      value: number;
    } | null;
    sales: {
      currency: string;
      decimalPrice: string;
      formatted: string;
      value: number;
    } | null;
  };
  c_brandName: string;
};

export type ImageGroupType = {
  images: {
    alt: string;
    link: string;
    title: string;
  }[];
  viewType: string;
  variationAttributes?: {
    id: string;
    values: {
      value: string;
    }[];
  }[];
};

export type ProductItemType = {
  brand: string;
  currency: string;
  id: string;
  price?: number;
  imageGroups?: ImageGroupType[];
  inventory?: {
    ats: number;
    backorderable: boolean;
    id: string;
    orderable: boolean;
    preorderable: boolean;
    stockLevel: number;
  };
  master?: {
    masterId: string;
    orderable: boolean;
    price: number;
  };
  minOrderQuantity: number;
  name: string;
  primaryCategoryId: string;
  productPromotions: any[];
  longDescription?: string;
  shortDescription?: string;
  slugUrl: string;
  stepQuantity: number;
  type: { [T in ProductTypeEnum]: boolean };
  variants?: ProductVariantType[];
  variationAttributes?: ProductAttributeType[];
  c_bvData: {
    bvDisplay: {
      bvPid: string;
      bvScout: string;
      qa: { enabled: boolean; seo: { content: string } };
      rr: { enabled: boolean; seo: { content: string } };
    };
  };
  c_marketPlacerTags: string[];
  c_marketplacerSellerName: string;
  c_marketplacerSellerId: string;
  c_prices: {
    list: {
      currency: string;
      decimalPrice: string;
      formatted: string;
      value: number;
    } | null;
    sales: {
      currency: string;
      decimalPrice: string;
      formatted: string;
      value: number;
    } | null;
  };
};

export type BasketProductItemType = {
  priceAdjustments?: [];
  taxBasis: number;
  basePrice: number;
  bundledProductItems?: [];
  adjustedTax: number;
  taxRate: number;
  priceAfterOrderDiscount: number;
  priceAfterItemDiscount: number;
  optionItems?: [];
  itemText: string;
  taxClassId: string;
  productListItem?: {};
  gift: boolean;
  itemId: string;
  productId: string;
  shipmentId: string;
  inventoryId?: string;
  inventory?: {
    ats: number;
    backorderable: boolean;
    id: string;
    orderable: boolean;
    preorderable: boolean;
    stockLevel: number;
  };
  shippingItemId: string;
  giftMessage?: string;
  bonusProductLineItem: boolean;
  price: number;
  bonusDiscountLineItemId?: string;
  tax: number;
  productName: string;
  quantity: number;
  c_marketplacerSellerId: string;
  c_marketplacerSellerName: string;
  image?: ImageType;
  variationValues?: any;
  productPromotions?: {
    calloutMsg: string;
    promotionId: string;
  }[];
  discountedPrice: number;
  originalPrice: number;
};

export interface IFilterProps {
  attributeId: string;
  labels: string[];
  values: string[];
}

export interface RefinementValueType {
  hitCount: number;
  label: string;
  value: string;
}

export interface RefinementType {
  attributeId: string;
  label: string;
  values?: {
    hitCount: number;
    label: string;
    value: string;
    values?: [];
  }[];
}

export type SortingOptionType = {
  id: string;
  label: string;
};
