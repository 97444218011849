import { ColoredButton, Typography } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'styles';
import { PageContentWrapper } from 'styles/common';

export const BodyWrapper = styled(PageContentWrapper)`
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.s}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleWrapper = styled(PageContentWrapper)`
  margin: 68px 0 50px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 15px 0 25px;
  }
`;

export const PageTitle = styled(Typography).attrs({
  type: 'title2Helv',
  tag: 'h1',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CartWrapper = styled.div`
  width: 100%;
  max-width: 810px;
  background-color: ${COLORS.white};
  height: fit-content;
  overflow: hidden;
  margin-right: 150px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-right: 0;
    margin-bottom: 25px;
    max-width: 470px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 15px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-bottom: 8px;

    & p {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const Text = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  text-transform: uppercase;
`;

export const Amount = styled(Typography).attrs({
  type: 'body2Helv',
})``;

export const CartSummary = styled.div`
  width: 100%;
  max-width: 470px;
  background-color: ${COLORS.white};
  height: fit-content;
  position: relative;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.black};
  margin-bottom: 19px;
`;

export const CartTitle = styled(Typography).attrs({
  type: 'title3Helv',
  tag: 'h3',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 100%;
  margin-top: 50px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin-top: 20px;
  }
`;
