import { COLORS } from 'styles';
import { Paragraph, UppercaseTitle, Title } from '../styles';

const YourContent = () => (
  <>
    <UppercaseTitle>5. Your Content</UppercaseTitle>
    <Paragraph>
      Content that you post using our Services is your content and we don’t make
      any claim to it (so let’s refer to it as “Your Content”). Your Content
      includes anything you post using our Services (like shop names, profile
      pictures, listing photos, listing descriptions, reviews, comments, videos,
      usernames, etc.).
    </Paragraph>
    <Title>A. Responsibility for Your Content</Title>
    <Paragraph>
      You understand that you are solely responsible for Your Content. You agree
      that you have all necessary rights to Your Content and that you’re not
      infringing or violating any third party’s rights by posting it.
    </Paragraph>
    <Title>B. Permission to Use Your Content</Title>
    <Paragraph>
      By posting Your Content through our Services, you grant Empower Global a
      license to use it. We don’t claim any ownership of Your Content, but we
      have your permission to use it in connection with our provision of the
      Services, such as to help Empower Global function and grow. With that
      said, we won’t infringe any rights you have in Your Content and we can
      help promote it.
    </Paragraph>
    <Title>C. Rights You Grant Empower Global</Title>
    <Paragraph>
      By posting Your Content, you grant Empower Global a non-exclusive,
      worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to
      use, display, edit, modify, reproduce, distribute, store, and prepare
      derivative works of Your Content. This allows us to provide the Services
      and to promote Empower Global, your Empower Global shop, or the Services
      in general, in any formats and through any channels, including across any
      Empower Global Service our partners, or third-party websites and other
      advertising mediums. You agree not to assert any moral rights or rights of
      publicity against us for using Your Content. You also recognize our
      legitimate interest in using it, in accordance with the scope of this
      license, to the extent Your Content contains any personal information.
    </Paragraph>
    <Paragraph>
      That sounds like a lot, but it’s necessary for us to keep Empower Global
      going. Consider these examples: if you upload a photo or video of a
      listing on your Empower Global shop, we have permission to display it to
      buyers, and we can resize or enhance it so it looks good to a buyer using
      a mobile device; if you post a description, we can suggest edits so it is
      SEO optimized; and if you post a beautiful photo or video of your latest
      product drop, we can feature it– often along with your shop name and high
      resolution shop logo – on our homepage, in one of our blogs or even on a
      billboard to help promote your business and Empower Global’s.
    </Paragraph>
    <Title>D. Reporting Unauthorized Content</Title>
    <Paragraph>
      Empower Global has great respect for intellectual property rights, and is
      committed to following appropriate legal procedures to remove infringing
      content from the Services. If content that you own or have rights to has
      been posted to the Services without your permission and you want it
      removed, please contact us at{' '}
      <a
        href="mailto:legal@EmpowerGlobal.shop"
        style={{ color: COLORS.accent }}
      >
        legal@EmpowerGlobal.shop
      </a>
      . If Your Content is alleged to infringe another person’s intellectual
      property, we will take appropriate action, such as disabling it if we
      receive proper notice or terminating your account if you are found to be a
      repeat infringer. We’ll notify you if any of that happens.
    </Paragraph>
    <Title>E. Inappropriate, False, or Misleading Content</Title>
    <Paragraph>
      This should be common sense, but there are certain types of content we
      don’t want posted on Empower Global’s Services (for legal reasons or
      otherwise). You agree that you will not post any content that is abusive,
      threatening, defamatory, obscene, vulgar, or otherwise offensive or in
      violation of our Empower Global Guidelines, or any part of our Terms. You
      also agree not to post any content that is false or misleading or uses the
      Services in a manner that is fraudulent or deceptive.
    </Paragraph>
  </>
);

export default YourContent;
