import { ColoredButton } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 60px 17px;
  gap: 10px;
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 54px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  font-family: ${FONTS.TTNorms.regular};

  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  background-color: ${COLORS.accent};
  pointer-events: auto;
  width: 210px;
  z-index: 5;

  p {
    color: ${COLORS.black};
    font-size: 20px;
    font-family: ${FONTS.TTNorms.medium};
  }
`;
