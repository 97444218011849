import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ValidatedSelect from 'components/Dropdowns/Selects/ValidatedSelect';
import { productReturnFormSchema } from 'helpers/validationSchemas';
import { ProductReturnFormFieldType } from 'types/OrdersTypes';
import { FormWrapper, Title } from './styles';

const ReturnReasonForm = ({
  items,
  onSubmit,
  onChange,
  disabled,
  formValues,
}: {
  items: { code: string; label: string }[];
  onSubmit?: (data: ProductReturnFormFieldType, e: any) => void;
  onChange?: (reason: string) => void;
  disabled?: boolean;
  formValues?: {
    reason: string;
  };
}) => {
  const values = formValues || {
    reason: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ProductReturnFormFieldType>({
    resolver: yupResolver(productReturnFormSchema),
    defaultValues: values,
  });

  useEffect(() => {
    const subscription = watch(
      ({ reason }) => onChange && reason && onChange(reason),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormWrapper
      component="form"
      id="return-reason-form"
      style={{ maxWidth: 200 }}
      onSubmit={(e) => {
        onSubmit && handleSubmit(onSubmit)(e);
      }}
    >
      <Title>Why are you returning this?</Title>
      <ValidatedSelect
        name="reason"
        control={control}
        label={formValues?.reason || ''}
        defaultValue={values.reason}
        error={!!errors.reason}
        errorMessage={
          errors?.reason ? errors.reason.message : 'Incorrect or empty entry'
        }
        items={items}
        disabled={disabled}
      />
    </FormWrapper>
  );
};

export default ReturnReasonForm;
