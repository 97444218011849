import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const ContentWrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0 80px;

  @media (max-width: 1150px) {
    padding: 0 15px;
  }
`;

export const PageContentWrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0 90px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 0 15px;
  }
`;
