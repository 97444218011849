/* eslint-disable import/no-dynamic-require */
import Slider from 'react-slick';
import {
  NextArrow,
  PrevArrow,
} from 'components/CarouselArrows/CarouselCustomArrows';
import { pressSlides } from 'data/exploreData';
import {
  Wrapper,
  PressItemCard,
  BackgroundImage,
  CardText,
  SliderWrapper,
  CardDetails,
  Title,
} from './styles';

const PressSection = () => {
  const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow color="white" />,
    nextArrow: <NextArrow color="white" />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <Title>Press</Title>
      <SliderWrapper>
        <Slider {...settings}>
          {pressSlides.map((pressItem) => (
            <a
              key={pressItem.id}
              href={pressItem.link}
              target="_blank"
              rel="noreferrer"
            >
              <PressItemCard>
                <BackgroundImage
                  imageUrl={require(`../../../${pressItem.background}`)}
                  aria-label={`${pressItem.shortenedAuth} quote from ${pressItem.pub}`}
                  role="img"
                >
                  <CardDetails>
                    <CardText>{pressItem.quote}</CardText>
                    <CardText>{pressItem.author}</CardText>
                    <img
                      src={require(`../../../${pressItem.image}`)}
                      alt="press-logo"
                    />
                  </CardDetails>
                </BackgroundImage>
              </PressItemCard>
            </a>
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};

export default PressSection;
