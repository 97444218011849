import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isEqual } from 'lodash';

import ProductCards from 'components/ProductListingComponents/ProductCards';
import Pagination from 'components/Pagination/Pagination';
import {
  getCustomerProductLists,
  getCustomerProductListsLoader,
  getWishlistProducts,
} from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import useGoogleTags from 'hooks/useGoogleTags';

const PAGE_SIZE = 16;

const FavoriteProducts = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { customerProductListItems } = useSelector(
    getCustomerProductLists,
    isEqual,
  );
  const wishlistProducts = useSelector(getWishlistProducts);
  const isLoading = useSelector(getCustomerProductListsLoader);
  const { addPageView } = useGoogleTags();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
    const lastPageIndex = firstPageIndex + PAGE_SIZE;
    return customerProductListItems?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, customerProductListItems]);

  const fetchProductsByIds = async () => {
    const ids = currentTableData?.map((item: any) => item.productId).join(',');
    await dispatch(AsyncThunks.getWishlistProducts(ids));
  };

  const filteredWishlistProducts = customerProductListItems?.filter((prod) => {
    return wishlistProducts?.some((item) => item.productId === prod.productId);
  });

  useEffect(() => {
    fetchProductsByIds();
    addPageView('wish list');
  }, [currentPage, customerProductListItems]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Favorite Products</title>
        <meta name="description" content="Favorite Products" />
      </Helmet>
      <ProductCards
        total={filteredWishlistProducts?.length}
        productList={wishlistProducts}
        isLoading={isLoading}
        emptyListTitle="No favorite products"
      />
      <Pagination
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        totalCount={filteredWishlistProducts?.length}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default FavoriteProducts;
