import styled from 'styled-components';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import { CustomNavLink } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const Image = styled(Box)`
  width: 200px;
  max-width: 100%;
  max-height: 865px;
  height: 100%;
  object-fit: cover;

  @media (min-width: ${BREAKPOINTS.s}px) {
    width: 100%;
  }
`;

export const SlideContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledCustomNavLink = styled(CustomNavLink)`
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 8px;
  color: ${COLORS.black};
  font-family: ${FONTS.Helvetica.regular};

  @media (min-width: ${BREAKPOINTS.xxs}px) {
    font-size: 12px;
  }

  @media (min-width: ${BREAKPOINTS.s}px) {
    font-size: 20px;
  }

  @media (min-width: ${BREAKPOINTS.l}px) {
    font-size: 24px;
  }
`;

export const StyledSlider = styled(Slider)`
  .slick-slide > div {
    margin: 0 5px;
  }

  /* the parent */
  .slick-list {
    margin: 0 -5px;
  }
`;
