import { Dispatch, SetStateAction } from 'react';
import { PaymentOptionsType } from 'types/CheckoutTypes';
import { OptionsWrapper, StyledButton } from './styles';

const PaymentOptions = ({
  paymentOption,
  setPaymentOption,
}: {
  paymentOption: PaymentOptionsType;
  setPaymentOption: Dispatch<SetStateAction<PaymentOptionsType>>;
}) => {
  return (
    <OptionsWrapper>
      <StyledButton
        isActive={paymentOption === 'card'}
        onClick={() => setPaymentOption('card')}
        aria-label="choose payment card"
        id="paymentByCard"
        label="Card"
      />
      <StyledButton
        isActive={paymentOption === 'afterpay'}
        onClick={() => setPaymentOption('afterpay')}
        aria-label="choose afterpay"
        id="paymentByAfterpay"
        label="Afterpay"
      />
    </OptionsWrapper>
  );
};

export default PaymentOptions;
