import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import EmailForm from 'components/Forms/EmailForm/EmailForm';
import { emailFormSchema } from 'helpers/validationSchemas';
import { EmailFormFieldsType } from 'types/AccountTypes';

const CheckoutEmailForm = ({
  email,
  addEmail,
  handleError,
}: {
  email: string;
  addEmail: (email: string) => void;
  handleError: () => void;
}) => {
  const values = { email: email || '' };

  const methods = useForm<EmailFormFieldsType>({
    resolver: yupResolver(emailFormSchema),
    defaultValues: values,
  });

  const onSubmit: SubmitHandler<EmailFormFieldsType> = (
    data: EmailFormFieldsType,
    e: any,
  ) => {
    data && addEmail(data.email);
    e.target.reset();
  };

  return (
    <EmailForm onSubmit={onSubmit} onError={handleError} methods={methods} />
  );
};

export default CheckoutEmailForm;
