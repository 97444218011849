import styled, { css } from 'styled-components';

import { Typography, ColoredButton } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const Input = styled.input.attrs({
  type: 'text',
})<{
  half?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '50px'};
  border: 1px solid ${COLORS.border};
  padding: 4px 14px;

  ${({ half }) =>
    half &&
    css`
      width: calc(50% - 5px);

      @media (max-width: 895px) {
        width: 100%;
      }
    `}
`;

export const Label = styled.label`
  display: block;
  font-family: ${FONTS.Helvetica.regular};
  font-size: 14px;
  line-height: 17px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const ErrorText = styled(Typography)`
  font-family: ${FONTS.Helvetica.regular};
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.accent};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const SubscriptionInput = styled(Input)`
  background: ${COLORS.black};
  border: 1px solid rgba(227, 227, 227, 0.3);
  padding-left: 13px;
  margin-bottom: 28px;
  color: ${COLORS.white};
  opacity: 0.8;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${COLORS.accent};
  }
`;

export const InputContainer = styled.div<{
  half?: boolean;
}>`
  display: flex;
  position: relative;

  ${({ half }) =>
    half &&
    css`
      width: calc(50% - 5px);

      @media (max-width: 895px) {
        width: 100%;
      }
    `}
`;

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  z-index: 6;
  top: 100%;
  width: 100%;
`;

export const DropdownList = styled.ul`
  background-color: white;
  padding: 0.8em 0;
  border: 1px solid ${COLORS.border};
`;

export const ListItem = styled.li`
  padding: 0.4em 1em;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #efecec;
  }
`;

export const MobileSubscriptionInput = styled(SubscriptionInput)`
  display: none;
  @media (max-width: 430px) {
    display: block;
  }
`;

export const ClearButton = styled(Typography).attrs({
  type: 'title6Helv',
})`
  text-transform: uppercase;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    color: ${COLORS.accent};
    opacity: 1;
    transition: 0.3s;
  }
`;

export const FaqSearchInputButton = styled(ColoredButton)`
  height: 44px;
  background: ${COLORS.accent};
  max-width: 170px;
  border-radius: 0;
  padding: 0 10px;

  & p {
    font-family: ${FONTS.TTNorms.medium};
    color: ${COLORS.black};
    font-size: 16px;
  }

  @media (max-width: 550px) {
    flex: 1;
  }
`;
