import styled from 'styled-components';
import { ColoredButton, Typography } from 'components';
import { COLORS, FONTS } from 'styles';

export const HeroBackgroundImage = styled.div<{
  imageUrl: string;
}>`
  position: relative;
  height: 100vh;
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -126px;

  @media (max-width: 1000px) {
    background-position: right;
  }
`;

export const EventBannerBlock = styled.div`
  padding: 106px 80px;
  max-width: 840px;
  position: relative;

  @media (max-width: 1000px) {
    padding: 40px 20px;
    width: 100%;
  }
`;

export const EventStatusButton = styled.button`
  border: none;
  font-family: ${FONTS.Bison.bold};
  font-size: 20px;
  letter-spacing: 0.26em;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EventBannerTitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.bold};
  font-size: 34px;
  text-transform: uppercase;
  margin: 32px 0;
`;

export const EventBannerSubtitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.regular};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 31px;
`;

export const EventBannerTime = styled.div`
  display: flex;
  gap: 60px;

  & span {
    display: flex;
    gap: 12px;
  }

  & p {
    opacity: 0.6;
  }
`;

export const StyledColoredButton = styled(ColoredButton)`
  width: 250px;
  height: 66px;
  margin-top: 84px;
  background: ${COLORS.accent};
  right: 80px;

  & p {
    color: black;
    font-size: 20px;
    font-family: ${FONTS.TTNorms.medium};
  }

  @media (max-width: 1000px) {
    width: 200px;
    margin-top: 30px;
    right: 20px;
    & p {
      font-size: 18px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 100px 80px 0;

  @media (max-width: 1000px) {
    padding: 40px 20px 0;
  }
`;

export const EventTab = styled(ColoredButton).attrs({
  inverted: true,
})<{
  isActive: boolean;
}>`
  width: 100%;
  max-width: 265px;
  border: 2px solid;
  border-color: ${({ isActive }) =>
    isActive ? COLORS.accent : `${COLORS.accent}51`};
  color: ${COLORS.black};
  padding: 34px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    font-family: ${FONTS.TTNorms.bold};
    font-size: 18px;
  }

  &:hover {
    background: none;
  }

  @media (max-width: 650px) {
    padding: 34px 0;
    & p {
      font-size: 16px;
    }
  }
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  padding: 108px 80px 120px;

  @media (max-width: 1150px) {
    padding: 40px 20px;
  }

  @media (max-width: 650px) {
    padding: 40px 0 0;
  }
`;

export const GridCardsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding: 108px 80px 120px;

  & div:first-child {
    grid-column: span 2;
  }

  @media (max-width: 1150px) {
    padding: 40px 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 650px) {
    padding: 40px 0 0;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const EventCardWrapper = styled.div`
  width: 31%;

  @media (max-width: 1200px) {
    width: 48%;
  }

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const PastEventCardWrapper = styled.div`
  width: 24%;

  @media (max-width: 1485px) {
    width: 31%;
  }

  @media (max-width: 967px) {
    width: 48%;
  }

  @media (max-width: 607px) {
    width: 100%;
  }
`;

export const BottomBannerBackground = styled.div<{
  imageUrl?: string;
}>`
  width: 100%;
  height: 410px;
  background-image: ${({ imageUrl }) => {
    return imageUrl ? `url(${imageUrl})` : '';
  }};
  position: relative;
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;

  @media (max-width: 1000px) {
    margin-top: 40px;
    height: 65vh;
  }
`;

export const BannerContents = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 20px;
`;

export const BottomBannerTitle = styled(Typography)`
  font-family: ${FONTS.Bison.bold};
  font-size: 64px;
  line-height: 77px;
  text-align: center;
  color: ${COLORS.black};

  @media (max-width: 1000px) {
    font-size: 48px;
    line-height: 54px;
  }
`;

export const BottomBannerSubtitle = styled(Typography)`
  font-family: ${FONTS.TTNorms.medium};
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${COLORS.black};
`;
