import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { find, forEach, isEqual, indexOf, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { ColoredButton } from 'components';
import ProductCards from 'components/ProductListingComponents/ProductCards';
import Pagination from 'components/Pagination/Pagination';
import BreadcrumbMenu from 'components/BreadcrumbMenu/BreadcrumbMenu';
import Filters from 'components/ProductListingComponents/Filters';
import Sorting from 'components/ProductListingComponents/Sorting';
import BasicMenu from 'components/Dropdowns/Menus/BasicMenu';
import usePagination from 'hooks/usePagination';
import useGoogleTags from 'hooks/useGoogleTags';
import useDimensions from 'hooks/useDimensions';
import { ExtendedCategoryType } from 'types/CatalogTypes';
import { APP_GENDER } from 'appConstants';
import { IFilterProps } from 'types/ProductsTypes';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getProductsData,
  getProductsLoader,
  getCategories,
  getProductsListCategories,
} from 'eg_SFCC_FE_core/store/selectors';
import { BREAKPOINTS } from 'styles';
import {
  DesktopFiltersWrapper,
  MobileFiltersWrapper,
  PLPWrapper,
} from './styles';

const PAGE_SIZE = 16;

type PageViewDataType = {
  category: string;
  gender?: string;
};

// google tags helper
const getCategoryNameGender = (
  categories: ExtendedCategoryType[],
  categoryId: string,
) => {
  let name;
  let gender = '';

  forEach(categories, (category: ExtendedCategoryType) => {
    if (category.id === categoryId) {
      name = category.name;
      gender =
        indexOf(APP_GENDER, category.name.toLowerCase()) !== -1
          ? category.name
          : '';
      return name;
    }

    const selectedCategory = find(category.categories, { id: categoryId });

    if (selectedCategory) {
      name = selectedCategory.name;
      gender =
        indexOf(APP_GENDER, selectedCategory.name.toLowerCase()) !== -1
          ? selectedCategory.name
          : '';
      return;
    }

    forEach(category.categories, (cat: any) => {
      const selectedSubcategory = find(cat.categories, { id: categoryId });

      if (selectedSubcategory) {
        name = selectedSubcategory.name;
        gender =
          indexOf(APP_GENDER, cat.name.toLowerCase()) !== -1 ? cat.name : '';
      }
    });
  });

  return {
    categoryName: name,
    categoryGender: gender,
  };
};

const ProductListingPage = () => {
  const [searchParams, setSearchParam] = useSearchParams();
  const brandFilter = searchParams.get('c_marketplacerSellerName');
  const [filterValues, setFilterValues] = useState<IFilterProps[]>(
    brandFilter
      ? [
          {
            attributeId: 'c_marketplacerSellerName',
            labels: [brandFilter],
            values: [brandFilter],
          },
        ]
      : [],
  );
  const [sortingId, setSortingId] = useState<string>('');
  const productListCategories = useSelector(getProductsListCategories);
  const { categoryId } = useParams();
  const dispatch = useAppDispatch();
  const {
    productList,
    total,
    result: { refinements, sortingOptions },
  } = useSelector(getProductsData, isEqual);
  const isLoading = useSelector(getProductsLoader);
  const categories = useSelector(getCategories, isEqual);
  const { currentPage, setPage } = usePagination();
  const categoryIdFromPathname = window.location.pathname.split('/').pop();
  const { addPageView } = useGoogleTags();
  const { width } = useDimensions();

  const fetchProducts = async (
    useFilters: boolean = true,
    useSorting: boolean = true,
  ) => {
    if (!categoryId || categoryId !== categoryIdFromPathname) return;

    const params = {
      limit: PAGE_SIZE,
      offset: (currentPage - 1) * PAGE_SIZE,
      refine: { cgid: `cgid=${categoryId}` },
      sort: useSorting ? sortingId : '',
    };

    if (!isEmpty(filterValues) && useFilters) {
      filterValues.forEach((val: IFilterProps) => {
        params.refine = {
          ...params.refine,
          [val.attributeId]: `${val.attributeId}=${val.values.join('|')}`,
        };
      });
    }

    await dispatch(AsyncThunks.getProductListByCategoryId(params));
  };

  const handleDoneButtonClick = async () => {
    if (currentPage !== 1) {
      setPage(1);
    }
    await fetchProducts();
  };

  const handleResetButtonClick = (target?: 'filters' | 'sorting') => {
    if (currentPage !== 1) {
      setPage(1);
    }

    if (target === 'filters') {
      setFilterValues([]);
      fetchProducts(false);
      return;
    }

    if (target === 'sorting') {
      setSortingId('');
      fetchProducts(true, false);
      return;
    }

    setFilterValues([]);
    setSortingId('');
    fetchProducts(false, false);
  };

  useEffect(() => {
    const { categoryName, categoryGender } = getCategoryNameGender(
      categories,
      categoryId || '',
    );

    const pageViewData: PageViewDataType = {
      category: categoryName || '',
    };

    if (categoryGender) {
      pageViewData.gender = categoryGender;
    }

    addPageView('category', pageViewData);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (brandFilter) {
      searchParams.delete('c_marketplacerSellerName');
      setSearchParam(searchParams);
    }

    fetchProducts();
  }, [currentPage]);

  useEffect(() => {
    if (categoryId) {
      dispatch(AsyncThunks.getProductListCategories(categoryId));
    }
  }, [categories]);

  const activeCategoryId =
    productListCategories &&
    productListCategories[productListCategories.length - 1]?.id;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products Listing</title>
        <meta name="description" content="Products Listing" />
      </Helmet>
      <PLPWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BreadcrumbMenu productCategories={productListCategories} />
          <MobileFiltersWrapper>
            <BasicMenu
              title="sort by"
              handleDoneButtonClick={handleDoneButtonClick}
              handleResetButtonClick={() => handleResetButtonClick('sorting')}
              content={
                <Sorting
                  setSortingId={setSortingId}
                  sortingId={sortingId}
                  sortingOptions={sortingOptions}
                />
              }
            />
            <BasicMenu
              title="filters"
              handleDoneButtonClick={handleDoneButtonClick}
              handleResetButtonClick={() => handleResetButtonClick('filters')}
              content={
                <Filters
                  refinements={refinements}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  activeCategoryId={activeCategoryId}
                />
              }
            />
          </MobileFiltersWrapper>
        </div>
        <div style={{ display: 'flex', marginTop: 50 }}>
          <DesktopFiltersWrapper>
            <Filters
              refinements={refinements}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              activeCategoryId={activeCategoryId}
              isExpanded
            />
            <Sorting
              setSortingId={setSortingId}
              sortingId={sortingId}
              sortingOptions={sortingOptions}
              isExpanded
            />
            <ColoredButton
              onClick={handleDoneButtonClick}
              style={{ marginTop: 36 }}
            >
              Done
            </ColoredButton>
            <ColoredButton
              onClick={handleResetButtonClick}
              style={{ marginTop: 10 }}
            >
              Reset
            </ColoredButton>
          </DesktopFiltersWrapper>
          <ProductCards
            total={total}
            productList={productList}
            isLoading={isLoading}
            emptyListTitle="There are no products in the selected category"
          />
        </div>
        <Pagination
          size={width < BREAKPOINTS.s ? 'small' : undefined}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          totalCount={total}
          setCurrentPage={setPage}
          style={{ justifyContent: 'center', marginTop: 60 }}
        />
      </PLPWrapper>
    </>
  );
};

export default ProductListingPage;
