/* eslint-disable import/no-dynamic-require */
import Slider from 'react-slick';
import {
  NextArrow,
  PrevArrow,
} from 'components/CarouselArrows/CarouselCustomArrows';
import { team } from 'data/team';
import {
  TeamWrapper,
  Title,
  TeamMemberCard,
  SliderWrapper,
  BackgroundImage,
  CardTitle,
  CardSubtitle,
  CardDetails,
  LineTitle,
  StrokeText,
} from './styles';

const TeamMembersSlide = () => {
  const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow color="white" />,
    nextArrow: <NextArrow color="white" />,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Title>Meet the team</Title>
      <TeamWrapper>
        <SliderWrapper>
          <Slider {...settings}>
            {team.map((member) => (
              <TeamMemberCard key={member.id}>
                <BackgroundImage
                  imageUrl={require(`../../../${member.image}`)}
                  aria-label={`${member.fullName} ${member.jobTitle}`}
                  role="img"
                >
                  <CardDetails>
                    <CardTitle>{member.fullName}</CardTitle>
                    <CardSubtitle>{member.jobTitle}</CardSubtitle>
                  </CardDetails>
                </BackgroundImage>
              </TeamMemberCard>
            ))}
          </Slider>
        </SliderWrapper>
      </TeamWrapper>
      <LineTitle>
        <StrokeText>{'empower global '.repeat(20)}</StrokeText>
      </LineTitle>
    </div>
  );
};

export default TeamMembersSlide;
