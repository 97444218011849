import styled from 'styled-components';

import { Typography } from 'components';
import { PageContentWrapper } from 'styles/common';
import { BREAKPOINTS } from 'styles';

export const MainSectionWrapper = styled(PageContentWrapper)`
  display: inline-block;
  padding-top: 60px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-top: 30px;
  }
`;

export const BusinessOwnerImage = styled.img`
  height: auto;
  max-width: 30%;
  max-height: 400px;
  float: right;
  margin-left: 100px;
  margin-bottom: 50px;
  min-width: 100px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-width: 40%;
    margin-left: 50px;
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    max-width: 50%;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

export const Title = styled(Typography).attrs({
  type: 'title1Helv',
  tag: 'h1',
})`
  margin-bottom: 25px;
  line-height: 25px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 3vw;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
  }
`;

export const FavoriteIconWrapper = styled.div`
  position: relative;
  margin-top: 30px;
`;

export const FavoriteText = styled(Typography).attrs({
  type: 'subtitle2Helv',
})`
  font-size: 10px;
  line-height: 22px;
  margin-left: 25px;
  text-transform: uppercase;
`;
