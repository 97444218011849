import { Loader } from 'components';
import { PaymentCardType } from 'types/CheckoutTypes';
import PaymentItem from './PaymentItem';
import { PaymentBlockTitle, PaymentContainer } from './styles';

const PaymentBlock = ({
  paymentsData,
  handleDelete,
  isLoading,
  selectPaymentInstrument,
  defaultPaymentInstrumentId,
}: {
  paymentsData: PaymentCardType[];
  handleDelete?: (id: string) => void;
  isLoading: boolean;
  selectPaymentInstrument?: (id: string | null, cardData?: string) => void;
  defaultPaymentInstrumentId: string;
}) => {
  return (
    <PaymentContainer>
      {isLoading && <Loader fullscreen />}
      {!paymentsData?.length ? (
        <PaymentBlockTitle>No saved payments</PaymentBlockTitle>
      ) : (
        <PaymentBlockTitle>
          {paymentsData.length} saved payment
          {paymentsData.length === 1 ? '' : 's'}
        </PaymentBlockTitle>
      )}

      {paymentsData?.length
        ? paymentsData.map(
            (paymentInstrument: PaymentCardType, index: number) => {
              return (
                <PaymentItem
                  key={index}
                  data={paymentInstrument}
                  handleDelete={handleDelete}
                  selectPaymentInstrument={selectPaymentInstrument}
                  defaultPaymentInstrumentId={defaultPaymentInstrumentId}
                />
              );
            },
          )
        : null}
    </PaymentContainer>
  );
};

export default PaymentBlock;
