import { memo } from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  PinterestIcon,
  MailIcon,
  TikTokIcon,
} from 'assets/svg';
import ROUTES from 'router/Routes';
import { SOCIAL_LINKS } from 'data/links';
import {
  FooterWrapper,
  IconsWrapper,
  Logo,
  StyledLink,
  LinksWrapper,
  Trademark,
  Icon,
} from './styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <Logo title="Empower Global Logo" />

      <LinksWrapper>
        <StyledLink to={ROUTES.CONTACT_US} id="contactUs">
          contact us
        </StyledLink>
        <StyledLink to={ROUTES.ABOUT} id="aboutUs">
          about us
        </StyledLink>
        <StyledLink to={ROUTES.FAQ} id="faq">
          faq
        </StyledLink>
        <StyledLink to={ROUTES.SHIPPING_RETURNS} id="shippingReturns">
          shipping & returns
        </StyledLink>
        <StyledLink to={ROUTES.TERMS_CONDITIONS} id="termsConditions">
          terms & conditions
        </StyledLink>
        <StyledLink to={ROUTES.PRIVACY_POLICY} id="privacyPolicy">
          privacy
        </StyledLink>
        <StyledLink to={ROUTES.COOKIES_PREFERENCES} id="cookiesPreferences">
          Cookies Preferences
        </StyledLink>
      </LinksWrapper>

      <IconsWrapper>
        <Icon href={SOCIAL_LINKS.INSTAGRAM}>
          <InstagramIcon />
        </Icon>
        <Icon href={SOCIAL_LINKS.TWITTER}>
          <TwitterIcon />
        </Icon>
        <Icon href={SOCIAL_LINKS.FACEBOOK}>
          <FacebookIcon />
        </Icon>
        <Icon href={SOCIAL_LINKS.PINTEREST}>
          <PinterestIcon />
        </Icon>
        <Icon href={SOCIAL_LINKS.TIKTOK}>
          <TikTokIcon />
        </Icon>
        <Icon href={SOCIAL_LINKS.EMAIL}>
          <MailIcon />
        </Icon>
      </IconsWrapper>

      <Trademark>
        2023 empower global
        <span style={{ verticalAlign: 'super', fontSize: 6 }}>TM</span>
      </Trademark>
    </FooterWrapper>
  );
};

export default memo(Footer);
