/* eslint-disable import/no-dynamic-require */
import Slider from 'react-slick';
import {
  NextArrow,
  PrevArrow,
} from 'components/CarouselArrows/CarouselCustomArrows';
import { CustomNavLink } from 'components';
import { pressSlides } from 'data/seller';
import ROUTES from 'router/Routes';
import {
  CardBackgroundImage,
  CardDetails,
  CardText,
  PressItemCard,
  PressSection,
} from './styles';

const PressSlider = () => {
  const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow color="white" />,
    nextArrow: <NextArrow color="white" />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <PressSection>
      <Slider {...settings}>
        {pressSlides.map((pressItem) => (
          <CustomNavLink
            key={pressItem.id}
            to={`${ROUTES.BRANDS}/${pressItem.brandId}`}
          >
            <PressItemCard>
              <CardBackgroundImage
                imageUrl={require(`../../${pressItem.background}`)}
              >
                <CardDetails>
                  <CardText>{pressItem.quote}</CardText>
                  <CardText>{pressItem.author}</CardText>
                </CardDetails>
              </CardBackgroundImage>
            </PressItemCard>
          </CustomNavLink>
        ))}
      </Slider>
    </PressSection>
  );
};

export default PressSlider;
