import { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';

import {
  Scope,
  PersonalInfoCollect,
  PersonalInfoShare,
  TrackingTools,
  PrivacyChoices,
  OtherProvisions,
  Retention,
  LawPrivacyRights,
  ChildrensInfo,
  ContactUs,
  CaliforniaResidentRights,
  VirginiaResidentRights,
  ExercisingRights,
  Security,
} from 'components/InfoPagesComponents/Templates';
import { AnimatedWrapper } from 'components';
import InfoPageWrapper from 'components/InfoPagesComponents/InfoPageWrapper';
import InfoPageMenu from 'components/InfoPagesComponents/InfoPageMenu';
import InfoPageText from 'components/InfoPagesComponents/InfoPageText';

export type MenuItemType = {
  title: string;
  content: ReactNode;
};

const privacyPolicyMenu: MenuItemType[] = [
  {
    title: 'Scope',
    content: <Scope />,
  },
  {
    title: 'Personal Information We Collect',
    content: <PersonalInfoCollect />,
  },
  {
    title: 'HOW WE DISCLOSE YOUR PERSONAL INFORMATION',
    content: <PersonalInfoShare />,
  },
  {
    title: 'Tracking Tools, Advertising and Opt-Out',
    content: <TrackingTools />,
  },
  {
    title: 'Your Privacy Choices',
    content: <PrivacyChoices />,
  },
  {
    title: 'CALIFORNIA RESIDENTS RIGHTS',
    content: <CaliforniaResidentRights />,
  },
  {
    title: 'VIRGINIA RESIDENT RIGHTS',
    content: <VirginiaResidentRights />,
  },
  {
    title: 'EXERCISING YOUR RIGHTS UNDER CCPA AND VCDPA ',
    content: <ExercisingRights />,
  },
  {
    title: 'Other State Law Privacy Rights',
    content: <LawPrivacyRights />,
  },
  {
    title: 'Security Of Personal Information',
    content: <Security />,
  },
  {
    title: 'Retention Of Personal Information',
    content: <Retention />,
  },
  {
    title: "Children's Information",
    content: <ChildrensInfo />,
  },
  {
    title: 'Other Provisions',
    content: <OtherProvisions />,
  },
  {
    title: 'Contact Us',
    content: <ContactUs />,
  },
];

const PrivacyPolicyPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(privacyPolicyMenu[0]);

  return (
    <InfoPageWrapper title="Privacy Policy">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>

      <InfoPageMenu
        menu={privacyPolicyMenu}
        currentMenuItem={currentMenuItem}
        setCurrentMenuItem={setCurrentMenuItem}
      />
      <AnimatedWrapper animationKey={currentMenuItem.title}>
        <InfoPageText title="privacy policy">
          {currentMenuItem.content}
        </InfoPageText>
      </AnimatedWrapper>
    </InfoPageWrapper>
  );
};

export default PrivacyPolicyPage;
