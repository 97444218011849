import { useState } from 'react';
import { isEqual } from 'lodash';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePersonalInfoFormSchema } from 'helpers/validationSchemas';
import { LabelCheckbox } from 'components';
import FormInputController from 'components/Inputs/FormInputController';
import { StyledButton } from './styles';
import { FormWrapper } from '../styles';

interface State {
  c_LoyaltyMember: boolean;
  c_MarketingOptin: boolean;
}

interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneMobile: string;
  c_LoyaltyMember: boolean;
  c_MarketingOptin: boolean;
}

const PersonalInfoForm = ({
  firstName,
  lastName,
  email,
  phoneMobile,
  updatePersonalInfo,
  updateEmail,
  isLoading,
  loyaltyMember,
  subscribedToEmails,
  currentTierName,
}: {
  firstName: string;
  lastName: string;
  email: string;
  phoneMobile: string;
  updatePersonalInfo: (param: any) => void;
  updateEmail: (param: any) => void;
  isLoading: boolean;
  loyaltyMember: boolean;
  subscribedToEmails: boolean;
  currentTierName: string;
}) => {
  const [checkboxValues, setCheckboxValues] = useState<State>({
    c_LoyaltyMember: loyaltyMember,
    c_MarketingOptin: subscribedToEmails,
  });

  const methods = useForm<User>({
    resolver: yupResolver(updatePersonalInfoFormSchema),
    defaultValues: { firstName, lastName, email, phoneMobile },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<User> = (data: User, e: any) => {
    e.preventDefault();
    const values = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneMobile: data.phoneMobile,
      c_MarketingOptin: e.target[8].checked,
    };

    if (
      !isEqual(values, {
        firstName,
        lastName,
        phoneMobile,
        c_MarketingOptin: subscribedToEmails,
      })
    ) {
      updatePersonalInfo(values);
      e.target.reset();
    }

    if (!isEqual(data.email, email)) {
      updateEmail(data.email);
      e.target.reset();
    }
  };

  const onError = () => {};

  return (
    <FormWrapper
      style={{ maxWidth: 500 }}
      onSubmit={(e) => {
        handleSubmit(onSubmit, onError)(e);
      }}
    >
      <FormInputController
        control={control}
        name="firstName"
        label="First Name*"
        error={!!errors.firstName}
        errorMessage={
          errors?.firstName
            ? errors.firstName.message
            : 'Incorrect or empty entry'
        }
      />
      <FormInputController
        control={control}
        name="lastName"
        label="Last Name*"
        error={!!errors.lastName}
        errorMessage={
          errors?.lastName
            ? errors.lastName.message
            : 'Incorrect or empty entry'
        }
      />
      <FormInputController
        control={control}
        name="email"
        label="Email Address*"
        error={!!errors.email}
        errorMessage={
          errors?.email ? errors.email.message : 'Incorrect or empty entry'
        }
      />
      <FormInputController
        control={control}
        name="phoneMobile"
        label="Phone Number*"
        error={!!errors.phoneMobile}
        errorMessage={
          errors?.phoneMobile
            ? errors.phoneMobile.message
            : 'Incorrect or empty entry'
        }
      />
      {/* {loyaltyMember ? (
        <Typography style={{ marginTop: 20, fontSize: 14 }}>
          Thank you for being a {formatTierName(currentTierName, 'Member ')}
          of EG Elite Loyalty Program!
        </Typography>
      ) : (
          <LabelCheckbox
            label="Join EG Elite Loyalty program"
            checked={checkboxValues.c_LoyaltyMember}
            handleChange={() =>
              setCheckboxValues({
                ...checkboxValues,
                c_LoyaltyMember: !checkboxValues.c_LoyaltyMember,
              })
            }
          />
      )} */}
      <LabelCheckbox
        label="Subscribe to Marketing Emails"
        checked={checkboxValues.c_MarketingOptin}
        handleChange={() =>
          setCheckboxValues({
            ...checkboxValues,
            c_MarketingOptin: !checkboxValues.c_MarketingOptin,
          })
        }
      />

      <StyledButton
        width="289px"
        aria-label="save changes"
        isLoading={isLoading}
      >
        save changes
      </StyledButton>
    </FormWrapper>
  );
};

export default PersonalInfoForm;
