import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { ProductCategoryType } from 'types/ProductsTypes';
import ROUTES from 'router/Routes';
import CustomNavLink from 'components/Links/CustomNavLink';
import { formatNameForUrl } from 'helpers/formatters';
import { BreadcrumbText } from './styles';

const BreadcrumbMenu = ({
  productCategories,
}: {
  productCategories: ProductCategoryType[];
}) => {
  const breadcrumbs = productCategories.map(
    (category: ProductCategoryType, index: number) => {
      const isCategoryActive = index === productCategories.length - 1;

      return (
        <CustomNavLink
          key={category.id}
          to={`${ROUTES.PRODUCTS}/${formatNameForUrl(category.name)}/${
            category.id
          }`}
        >
          <BreadcrumbText isCategoryActive={isCategoryActive}>
            {category.name}
          </BreadcrumbText>
        </CustomNavLink>
      );
    },
  );

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
    </Stack>
  );
};

export default BreadcrumbMenu;
