export const empowerEvents: any[] = [
  {
    id: '1',
    title: 'French Balayage summer',
    day: 'Today',
    time: '11:30 pm ET ',
    hostedBy: 'Jenny Wilson',
    image: 'assets/jpg/events/events-1.jpg',
    isLive: true,
  },
  {
    id: '2',
    title: 'French Balayage summer',
    day: 'Today',
    time: '11:30 pm ET ',
    hostedBy: 'Jenny Wilson',
    image: 'assets/jpg/events/events-2.jpg',
  },
  {
    id: '3',
    title: 'French Balayage summer',
    day: 'Today',
    time: '11:30 pm ET ',
    hostedBy: 'Jenny Wilson',
    image: 'assets/jpg/events/events-3.jpg',
  },
  {
    id: '4',
    title: 'French Balayage summer',
    day: 'Today',
    time: '11:30 pm ET ',
    hostedBy: 'Jenny Wilson',
    image: 'assets/jpg/events/events-4.jpg',
  },
  {
    id: '5',
    title: 'French Balayage summer',
    day: 'Today',
    time: '11:30 pm ET ',
    hostedBy: 'Jenny Wilson',
    image: 'assets/jpg/events/events-5.jpg',
  },
];
