import { useSelector } from 'react-redux';
import { find, upperFirst } from 'lodash';
import { CustomNavLink, Loader } from 'components';
import { getBasketProductItems } from 'eg_SFCC_FE_core/store/selectors';
import ROUTES from 'router/Routes';
import { FONTS } from 'styles';
import {
  StyledColoredButton,
  AddToCartItemValues,
  AddToCartConfirmationContentWrapper,
  ConfirmationButtonsWrapper,
  AddToCartConfirmationWrapper,
  ConfirmationTitle,
  ConfirmationItemBrandName,
  ConfirmationItemProductName,
  ConfirmationItemBody,
  ConfirmationItemImage,
  ConfirmationContentCrossIcon,
  ConfirmationDetailsWrapper,
} from './styles';

const AddToCartConfirmationContent = ({
  unSetModal,
  title,
  productIdForBasket,
}: {
  unSetModal: () => void;
  title: string;
  productIdForBasket: string;
}) => {
  const productItems = useSelector(getBasketProductItems);
  const itemAddedToCart = find(productItems, { productId: productIdForBasket });

  if (!itemAddedToCart?.image) {
    return <Loader style={{ height: 100 }} />;
  }

  return (
    <AddToCartConfirmationWrapper>
      <ConfirmationContentCrossIcon onClick={unSetModal} />
      <ConfirmationTitle>{title}</ConfirmationTitle>
      {itemAddedToCart ? (
        <AddToCartConfirmationContentWrapper>
          <ConfirmationItemImage
            src={itemAddedToCart.image?.link}
            alt={itemAddedToCart.image?.alt}
          />
          <AddToCartItemValues>
            <ConfirmationDetailsWrapper>
              <ConfirmationItemBrandName>
                {itemAddedToCart.c_marketplacerSellerName}
              </ConfirmationItemBrandName>
              <ConfirmationItemProductName>
                {itemAddedToCart.productName}
              </ConfirmationItemProductName>
              {itemAddedToCart.variationValues
                ? Object.keys(itemAddedToCart.variationValues).map(
                    (key: string) => (
                      <div
                        key={key}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                        }}
                      >
                        <ConfirmationItemBody>
                          {upperFirst(key)}:
                        </ConfirmationItemBody>
                        <ConfirmationItemBody
                          style={{ fontFamily: FONTS.Helvetica.bold }}
                        >
                          {itemAddedToCart.variationValues[key]}
                        </ConfirmationItemBody>
                      </div>
                    ),
                  )
                : null}
            </ConfirmationDetailsWrapper>

            <ConfirmationButtonsWrapper>
              <StyledColoredButton
                id="continueShopping"
                aria-label="continue shopping"
                onClick={unSetModal}
              >
                continue shopping
              </StyledColoredButton>
              <CustomNavLink to={ROUTES.CART}>
                <StyledColoredButton id="viewCart" aria-label="view cart">
                  view cart
                </StyledColoredButton>
              </CustomNavLink>
            </ConfirmationButtonsWrapper>
          </AddToCartItemValues>
        </AddToCartConfirmationContentWrapper>
      ) : null}
    </AddToCartConfirmationWrapper>
  );
};

export default AddToCartConfirmationContent;
