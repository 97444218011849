import ROUTES from 'router/Routes';
import { Paragraph, StyledLink, Title } from '../styles';

const General = () => {
  return (
    <div>
      <Title>Is everything on the site Black-owned?</Title>
      <Paragraph>
        Yes. Our Marketplace Operations Team hand selects and interviews all
        brands on the site to ensure alignment with our mission.
      </Paragraph>

      <Title space>
        Interested in being a part of Empower Global as a Brand?
      </Title>
      <Paragraph>
        Contact us <StyledLink to={ROUTES.CONTACT_US}>here</StyledLink> to
        receive more information on our marketplace and how to join our
        community.
      </Paragraph>

      <Title space>
        How is Empower Global Different from Other Marketplaces?
      </Title>
      <Paragraph>
        Founded by Sean &quot;Diddy&quot; Combs in 2021 and under the umbrella
        of Combs Global, Empower Global is an immersive e-commerce experience
        offering best in class products from Black-owned businesses. With
        culture and community at its core, Empower Global’s digital marketplace
        elevates the Black economy by creating opportunities for Black
        entrepreneurs to build and scale successful businesses and for everyone
        to “Shop Black” daily with ease.This is the first step in our larger
        mission to build an economy of Black excellence that drives more dollars
        to Black entrepreneurs and founders.
      </Paragraph>
    </div>
  );
};

export default General;
