import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import SearchBrands from 'components/BrandsComponents/SearchBrands/SearchBrands';
import useGoogleTags from 'hooks/useGoogleTags';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getBrandsList,
  getBrandsListLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { PageWrapper, Title } from './styles';

const BrandsPage = () => {
  const dispatch = useDispatch();
  const brandsList = useSelector(getBrandsList);
  const isLoading = useSelector(getBrandsListLoader);
  const { addPageView } = useGoogleTags();

  const fetchBrands = async () => {
    await dispatch(AsyncThunks.getBrandList());
  };

  useEffect(() => {
    fetchBrands();
    addPageView('brands');
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brands</title>
        <meta name="description" content="Brands" />
      </Helmet>
      <PageWrapper>
        <div>
          <Title tag="h1">Brands</Title>
        </div>
        <div>
          <Title tag="h2">SEARCH BRAND BY LETTER</Title>
          <SearchBrands brandsList={brandsList} isLoading={isLoading} />
        </div>
      </PageWrapper>
    </>
  );
};

export default BrandsPage;
