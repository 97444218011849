import styled from 'styled-components';
import { Typography, CustomNavLink } from 'components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const OrderSummaryWrapper = styled.div`
  min-width: 300px;
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    margin: 30px 10px;
    max-width: 410px;
  }

  .checkout_component--has-background {
    padding: 20px 20px 0 20px;
    background-color: ${COLORS.cardBackground};
  }

  .MuiFormControl-root .MuiOutlinedInput-root {
    background-color: transparent;
  }
`;

export const OrderSummaryBox = styled.div`
  background-color: ${COLORS.cardBackground};
  padding: 20px;
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const PrintLink = styled(Typography)`
  position: absolute;
  top: 0;
  right: 20px;
  color: ${COLORS.primary};
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid ${COLORS.border};
  padding: 3px 5px;

  @media print {
    display: none;
  }
`;

export const Title = styled(Typography).attrs({
  type: 'title3Helv',
  tag: 'h3',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 15px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding-bottom: 8px;

    & p {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const Text = styled(Typography).attrs({
  type: 'bodyHelv',
})`
  text-transform: uppercase;
`;

export const Link = styled(CustomNavLink)`
  transition: 0.3s;
  color: inherit;
  opacity: 0.8;
  font-size: 16px;

  &:hover {
    color: ${COLORS.accent};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    & {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

export const OrderItemsWrapper = styled.div`
  max-height: 330px;
  overflow: auto;
  margin-bottom: 35px;
`;

export const OrderItemWrapper = styled.div<{
  orderable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: ${({ orderable }) => (orderable ? 1 : 0.3)};

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }

  @media (max-width: 1250px) {
    padding: 10px;
  }
`;

// cart modal item styles

export const CartItemsWrapper = styled.div`
  background-color: ${COLORS.background};
`;

export const CartItemWrapper = styled.div<{
  orderable?: boolean;
}>`
  display: flex;
  padding: 16px 0 10px 0;
  opacity: ${({ orderable }) => (orderable ? 1 : 0.3)};
`;

export const CartItemInfoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CartItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CartItemText = styled(Typography).attrs({
  type: 'title6Helv',
})`
  text-transform: uppercase;
`;

export const CartProductNameText = styled(Typography).attrs({
  type: 'xsmallHelv',
})`
  margin-top: 5px;
`;

export const CartItemVariationWrapper = styled.div`
  color: ${COLORS.black};
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const OptionChangeIcon = styled.button`
  cursor: pointer;
  font-family: ${FONTS.Helvetica.medium};
`;

export const CartActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.middleGrey};
  font-family: ${FONTS.TTNorms.regular};
  font-size: 12px;
  line-height: 12px;
  opacity: 0.6;
  padding-bottom: 10px;

  & > button {
    cursor: pointer;
    background: none;

    &:first-child {
      margin-right: 20px;
    }
  }
`;

export const InfoBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.black};
  margin: 10px 0 17px;
`;
