import { CustomNavLink, Loader } from 'components';
import ROUTES from 'router/Routes';
import { BrandType } from 'types/BrandsTypes';
import {
  BrandsContainer,
  MainLetter,
  LinksContainer,
  LinkTitle,
} from './styles';

const BrandsNames = ({
  currentMenuItem,
  brandsList,
  isLoading,
}: {
  currentMenuItem: string;
  brandsList: BrandType[];
  isLoading: boolean;
}) => {
  const filteredList = brandsList?.filter((brand) => {
    if (brand.businessName.toUpperCase().startsWith(currentMenuItem)) {
      return brand;
    }

    if (currentMenuItem === '#' && /\d/.test(brand.businessName)) {
      return brand;
    }
  });

  return (
    <BrandsContainer>
      <MainLetter>{currentMenuItem}</MainLetter>
      {filteredList?.length === 0 &&
        !isLoading &&
        'No brand started with this letter'}
      {!isLoading && filteredList?.length ? (
        <LinksContainer>
          {filteredList.map((brand) => {
            const navigateTo = brand.isDisabled
              ? `${ROUTES.PRODUCTS}/shop/seller?c_marketplacerSellerName=${brand.businessName}`
              : `${ROUTES.BRANDS}/${brand.id}`;

            return (
              <CustomNavLink key={brand.id} to={navigateTo}>
                <LinkTitle>{brand.businessName}</LinkTitle>
              </CustomNavLink>
            );
          })}
        </LinksContainer>
      ) : null}
      {isLoading && <Loader />}
    </BrandsContainer>
  );
};

export default BrandsNames;
