/* eslint-disable import/no-cycle */
import axios, { AxiosRequestConfig } from 'axios';
import { includes } from 'lodash';
import Cookies from 'js-cookie';

import endpoints from './endpoints';
import { COOKIES_KEYS } from './apiConstants';
import { silentTokenRefresh, getPublicGuestToken } from './api';
import { formatTokenType } from './helpers';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

let isRefreshing = false;

function waitForTokenRefresh(this: any, resolve: (value: boolean) => void) {
  if (!isRefreshing) resolve(true);
  else setTimeout(waitForTokenRefresh.bind(this, resolve), 100);
}

const endpointsWithoutToken = [
  endpoints.shopper.publicGuestLoginCode,
  endpoints.shopper.publicGuestLoginJwtToken,
  endpoints.sellers.sellers,
  endpoints.reset.sendEmail,
  endpoints.reset.resetPassword,
  endpoints.contactUsForms,
  endpoints.CSRFToken,
];

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (config.headers && !includes(endpointsWithoutToken, config?.url)) {
    // if the token is currently refreshing, wait until it finishes
    if (isRefreshing) {
      await new Promise(waitForTokenRefresh);
    }

    const accessToken = Cookies.get(COOKIES_KEYS.accessToken);
    const tokenType = Cookies.get(COOKIES_KEYS.tokenType);

    if (accessToken && tokenType) {
      config.headers.Authorization = `${formatTokenType(
        tokenType,
      )} ${accessToken}`;
      return config;
    }

    isRefreshing = true;

    const refreshedData = await silentTokenRefresh();

    if (refreshedData) {
      isRefreshing = false;

      const {
        access_token: refreshedAccessToken,
        token_type: refreshedTokenType,
      } = refreshedData;

      config.headers.Authorization = `${formatTokenType(
        refreshedTokenType,
      )} ${refreshedAccessToken}`;

      return config;
    }

    const {
      access_token: refreshedAccessToken,
      token_type: refreshedTokenType,
    } = await getPublicGuestToken();

    isRefreshing = false;

    config.headers.Authorization = `${formatTokenType(
      refreshedTokenType,
    )} ${refreshedAccessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    const { config, response } = error;

    if (!includes(endpointsWithoutToken, config?.url) && response) {
      const {
        status,
        data: { message },
      } = response;
      // access token was expired
      if (
        status === 401 &&
        !config.retry &&
        message !== 'Invalid Credentials.'
      ) {
        config.retry = true;

        try {
          await silentTokenRefresh();
          return await axiosInstance(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }

    // refresh token was expired
    if (response?.data?.message === 'invalid refresh_token') {
      await Cookies.remove(COOKIES_KEYS.refreshToken);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
