import styled from 'styled-components';
import { Box } from '@mui/material';
import { Typography, CustomNavLink } from 'components';
import { BREAKPOINTS, COLORS } from 'styles';

export const Image = styled(Box)`
  max-width: 100%;
  object-fit: cover;
`;

export const SlideTextContainer = styled.div<{ isLeft: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.isLeft ? 0 : 'unset')};
  right: ${(props) => (!props.isLeft ? 0 : 'unset')};
  margin-left: ${(props) => (!props.isLeft ? 0 : '20px')};
  margin-right: ${(props) => (props.isLeft ? 0 : '20px')};
  text-align: ${(props) => (props.isLeft ? 'left' : 'right')};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isLeft ? 'flex-start' : 'flex-end')};

  @media (min-width: ${BREAKPOINTS.xxs}px) {
    margin-left: ${(props) => (!props.isLeft ? 0 : '40px')};
    margin-right: ${(props) => (props.isLeft ? 0 : '40px')};
  }

  @media (min-width: ${BREAKPOINTS.xs}px) {
    margin-left: ${(props) => (!props.isLeft ? 0 : '40px')};
    margin-right: ${(props) => (props.isLeft ? 0 : '40px')};
  }
`;

export const SlideContainer = styled.div`
  position: relative;
`;

export const SlideHeading = styled(Typography).attrs({
  type: 'title1Helv',
})<{ isLarge: boolean }>`
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 20px;
  max-width: ${(props) => (props.isLarge ? 'unset' : '170px')};

  @media (min-width: ${BREAKPOINTS.xxs}px) {
    max-width: ${(props) => (props.isLarge ? 'unset' : '270px')};
    font-size: 24px;
  }

  @media (min-width: ${BREAKPOINTS.xs}px) {
    font-size: 36px;
  }

  @media (min-width: ${BREAKPOINTS.xl}px) {
    line-height: 44px;
    max-width: unset;
    font-size: ${(props) => (props.isLarge ? '70px' : '40px')};
  }
`;

export const SlideCopy = styled(Typography).attrs({
  type: 'sliderCopyHelv',
})<{ isDark: boolean }>`
  font-size: 12px;
  margin-bottom: 20px;
  max-width: 180px;
  color: ${(props) => (props.isDark ? COLORS.black : COLORS.white)};

  @media (min-width: ${BREAKPOINTS.xxs}px) {
    max-width: 250px;
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS.xs}px) {
    max-width: 310px;
    font-size: 18px;
  }

  @media (min-width: ${BREAKPOINTS.xl}px) {
    max-width: 460px;
    font-size: 24px;
  }
`;

export const SlideLink = styled(CustomNavLink)<{ isDark: boolean }>`
  color: ${(props) => (props.isDark ? 'black' : 'white')};
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 24px;
  font-weight: bold;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 13px;
  }
`;
