import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AnimatedWrapper from 'components/AnimatedWrapper/AnimatedWrapper';
import { letters } from 'data/letters';
import BrandsNames from './BrandsNames';
import LettersMenu from './LettersMenu';
import { SectionWrapper } from './styles';

const SearchBrands = ({
  brandsList,
  isLoading,
}: {
  brandsList: any[];
  isLoading: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const letterParam = searchParams.get('l') || letters[0];
  const [currentMenuItem, setCurrentMenuItem] = useState<string>(letters[0]);

  useEffect(() => {
    setCurrentMenuItem(letterParam);
  }, [letterParam]);
  return (
    <SectionWrapper>
      <LettersMenu
        letters={letters}
        brandsList={brandsList}
        currentMenuItem={currentMenuItem}
        setCurrentMenuItem={setCurrentMenuItem}
      />
      <AnimatedWrapper animationKey={currentMenuItem}>
        <BrandsNames
          currentMenuItem={currentMenuItem}
          brandsList={brandsList}
          isLoading={isLoading}
        />
      </AnimatedWrapper>
    </SectionWrapper>
  );
};

export default SearchBrands;
