import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { modalActions } from 'eg_SFCC_FE_core/store/reducers/slices/modalSlice';
import { FC, useEffect } from 'react';
import { useLocation, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ROUTES from 'router/Routes';
import styled from 'styled-components';

type LocationState = {
  prevPath: string;
};

export const AnimatedWrapper = styled.div`
  width: 100%;
  height: 100%;

  &.fade-enter {
    opacity: 0;
    z-index: 1;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out 300ms;
  }

  &.fade-exit {
    opacity: 1;
    z-index: 1;

    div#loader {
      display: none;
    }
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`;

const RoutesAnimation: FC<any> = ({ children }) => {
  const { pathname, state } = useLocation();
  const dispatch = useAppDispatch();
  const prevPath = (state as LocationState)?.prevPath;

  const isAnimationDisabled =
    prevPath?.includes(ROUTES.ACCOUNT) &&
    pathname?.includes(ROUTES.ACCOUNT) &&
    prevPath?.includes(ROUTES.FAQ) &&
    pathname?.includes(ROUTES.FAQ);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname !== ROUTES.SEARCH) {
      dispatch(modalActions.hideAllModals());
    }
  }, [pathname]);

  return (
    <TransitionGroup style={{ width: '100%' }}>
      <CSSTransition
        timeout={600}
        classNames={isAnimationDisabled ? '' : 'fade'}
        key={pathname}
      >
        <AnimatedWrapper>
          <Routes location={pathname}>{children}</Routes>
        </AnimatedWrapper>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RoutesAnimation;
