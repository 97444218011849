import styled from 'styled-components';

import { Box } from '@mui/material';
import { BREAKPOINTS } from 'styles';

export const FormWrapper = styled(Box)`
  max-width: 556px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    gap: 3px;
    & > div {
      row-gap: 3px;
    }
  }
`;
