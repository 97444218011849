import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { forIn, forOwn } from 'lodash';

import {
  ProductListParamsType,
  ProductSearchParamsType,
  ErrorResponse,
} from 'eg_SFCC_FE_core/types';
import axiosInstance from 'eg_SFCC_FE_core/axios/axiosInstance';
import endpoints from 'eg_SFCC_FE_core/axios/endpoints';
import { getCategoryById } from 'eg_SFCC_FE_core/axios/api';

export const getProductListByCategoryIdThunk: AsyncThunkPayloadCreator<
  any,
  ProductListParamsType,
  { rejectValue: ErrorResponse }
> = async (productListParams, { rejectWithValue }) => {
  try {
    const params = new URLSearchParams();

    forOwn(productListParams, (value, key) => {
      if ((value || value === 0) && key !== 'refine') {
        params.append(key, value.toString());
      }
    });

    params.append('siteId', process.env.REACT_APP_SITE_ID || '');

    forIn(productListParams.refine, (value: string) => {
      params.append('refine', value);
    });

    const response = await axiosInstance.get(endpoints.search.product, {
      params,
    });

    return {
      ...response.data,
    };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getProductSearchResultThunk: AsyncThunkPayloadCreator<
  any,
  ProductSearchParamsType,
  { rejectValue: ErrorResponse }
> = async (productSearchParams, { rejectWithValue }) => {
  try {
    const params = new URLSearchParams();

    forOwn(productSearchParams, (value, key) => {
      if ((value || value === 0) && key !== 'refine') {
        params.append(key, value.toString());
      }
    });

    params.append('siteId', process.env.REACT_APP_SITE_ID || '');

    forIn(productSearchParams.refine, (value: string) => {
      params.append('refine', value);
    });

    const response = await axiosInstance.get(endpoints.search.product, {
      params,
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};

export const getProductListCategoriesThunk: AsyncThunkPayloadCreator<
  any,
  string,
  { rejectValue: ErrorResponse }
> = async (categoryId, { rejectWithValue }) => {
  try {
    const response = await getCategoryById(categoryId || '');
    return response.parentCategoryTree;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
};
