import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';

import useHtml from 'hooks/useHtml';
import { AddressFormFieldsType } from 'types/AccountTypes';
import { PlaceResult, PlaceType } from 'types/GooglePlacesTypes';
import { Typography } from 'components';
import { ErrorText, Label } from 'components/Inputs/styles';
import { ArrowDownIcon } from 'assets/svg';
import { COLORS, FONTS } from 'styles';

const autocompleteService = { current: null };
const placesService = { current: null };

const AddressAutocomplete = ({
  control,
  name,
  label,
  values,
  defaultValue,
  methods,
  error,
  errorMessage,
}: {
  control: any;
  name: string;
  label: string;
  values: AddressFormFieldsType;
  defaultValue: string | undefined;
  methods: any;
  error?: any;
  errorMessage: string | undefined;
}) => {
  const { addScript } = useHtml();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            componentRestrictions: { country: string };
            types: string[];
          },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        200,
      ),
    [],
  );

  const getPlaceDetails = async (placeId: string): Promise<PlaceResult> => {
    return new Promise((resolve, reject) => {
      (placesService.current as any).getDetails(
        { placeId },
        (results: any, status: any) => {
          if (status === 'OK') {
            resolve(results);
          }
          reject(results);
        },
      );
    });
  };

  const extractAddress = (results: PlaceResult): AddressFormFieldsType => {
    const types: any = {
      locality: 'city',
      administrative_area_level_1: 'stateCode',
      postal_code: 'postalCode',
      country: 'countryCode',
    };
    const address: any = { address1: results?.name };
    results?.address_components.forEach((component) => {
      address[types[component.types[0]]] = component.short_name;
    });
    delete address.undefined;
    return address;
  };

  const handleAutoCompleteAddress = async (newValue: PlaceType) => {
    try {
      const placeDetails = await getPlaceDetails(newValue.place_id);
      const extractedAddress = extractAddress(placeDetails);
      // @ts-ignore
      Object.keys(extractedAddress).forEach((addressFieldKey) => {
        // @ts-ignore
        methods.setValue(addressFieldKey, extractedAddress?.[addressFieldKey]);
      });
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    addScript({
      src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      id: 'google-maps',
    });
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!placesService.current && (window as any).google) {
      placesService.current = new (
        window as any
      ).google.maps.places.PlacesService(document.createElement('div'));
    }

    if (!autocompleteService.current) {
      return;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: 'us' },
        types: ['address'],
      },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            id={name}
            {...field}
            freeSolo
            disablePortal
            value={defaultValue || value}
            forcePopupIcon
            clearIcon={null}
            popupIcon={<ArrowDownIcon />}
            sx={{
              width: '100%',
              margin: '8px 0 4px',
              position: 'relative',
              '& input': { padding: '0 !important', height: 19 },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: COLORS.black,
              },
              '& .MuiOutlinedInput-root': {
                fontFamily: `${FONTS.Helvetica.regular}`,
                backgroundColor: COLORS.white,
                borderRadius: 0,
                fontSize: 14,
                padding: '6px 12px !important',

                '@media (max-width: 1024px)': {
                  fontSize: 12,
                },

                '&.Mui-disabled fieldset': {
                  border: 'none',
                },

                '&.Mui-focused fieldset': {
                  borderColor: COLORS.accent,
                  borderWidth: 1,
                },
              },
            }}
            selectOnFocus
            size="small"
            getOptionLabel={(option) =>
              typeof option === 'string'
                ? option
                : option.structured_formatting.main_text
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(event: any, newValue: PlaceType | null | string) => {
              if (typeof newValue !== 'string') {
                const optionExists = options?.find(
                  (option) => option.place_id === newValue?.place_id,
                );
                if (!optionExists) {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                }
                newValue && handleAutoCompleteAddress(newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              field.onChange(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                inputRef={params.InputProps.ref}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            renderOption={(props, option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings;
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match: any) => [
                  match.offset,
                  match.offset + match.length,
                ]),
              );

              return (
                <li {...props} key={option.place_id}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box
                        component={LocationOnIcon}
                        sx={{ color: COLORS.opacityText, mr: 2 }}
                      />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part: any, index: number) => (
                        <span
                          key={index}
                          style={{
                            fontFamily: FONTS.Helvetica.medium,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      <Typography type="bodyHelv">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />

      {error && <ErrorText>{errorMessage}</ErrorText>}
    </div>
  );
};

export default AddressAutocomplete;
