import { Typography } from 'components';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  overflow: hidden;
`;

export const BrandName = styled(Typography).attrs({
  type: 'title2Helv',
})`
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const ShopNow = styled(Typography).attrs({
  type: 'body2Helv',
  color: 'primaryInverted',
})`
  white-space: nowrap;
  font-size: 0.7em;
`;
