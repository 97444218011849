import ROUTES from 'router/Routes';
import { List, ListItem, Paragraph, StyledLink, Title } from '../styles';

const Orders = () => {
  return (
    <div>
      <Title>How to place an order on Empower Global</Title>
      <Paragraph>
        You can create an account or place an order using our guest checkout.
      </Paragraph>
      <Paragraph space>
        Creating an account provides you with the ability to:
      </Paragraph>
      <List>
        <ListItem>
          Keep track of your current orders and view previous purchases.
        </ListItem>
        <ListItem>Request a return directly through your account;</ListItem>
        <ListItem>
          Ease at checkout; all billing and shipping information is saved.
        </ListItem>
      </List>

      <Title space>How to edit or cancel an order</Title>
      <Paragraph>
        We aim to ensure our brands ship out orders to our customers within
        three business days, but often quicker if possible.
      </Paragraph>
      <Paragraph space>
        After your order has been processed with a tracking number, we
        regretfully cannot cancel or make changes to your order including, but
        not limited to:
      </Paragraph>

      <List style={{ listStyle: 'none', marginTop: 10 }}>
        <ListItem>Canceling your order or specific items</ListItem>
        <ListItem>
          Changing your billing or shipping address after an order has shipped
        </ListItem>
        <ListItem>Changing payment method</ListItem>
        <ListItem>Changing color/size of items(s)</ListItem>
        <ListItem>Addition or removal of item(s)</ListItem>
      </List>

      <Title space>How to track an order</Title>
      <Paragraph>
        Once your order has been shipped, you will receive an email with your
        shipment’s tracking information, allowing you to keep tabs on the
        progress of your delivery. If you have items from multiple brands in a
        shipment, you may receive more than one tracking number.
      </Paragraph>
      <Paragraph space>
        If you have an EG account, it can also be found in account management
        under orders.
      </Paragraph>
      <Paragraph space>
        If you need additional assistance or information, please complete the
        form on the <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink>{' '}
        page and a member of our Customer Support team will reach out.
      </Paragraph>

      <Title space>If you received the wrong item(s) or quantity</Title>
      <Paragraph space>
        Please fill out the form on the{' '}
        <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink> page and a
        member of our Customer Support team will reach out for assistance.
      </Paragraph>

      <Title space>What to do if you received a damaged order</Title>
      <Paragraph space>
        Please fill out the form on the{' '}
        <StyledLink to={ROUTES.CONTACT_US}>contact us</StyledLink> page and a
        member of our Customer Support team will reach out for assistance.
      </Paragraph>
      <Paragraph space>
        Be sure to include a description of the issue, your name, and order
        number. We will do our best to ensure that the issue is resolved to your
        satisfaction as a matter of urgency.
      </Paragraph>
    </div>
  );
};

export default Orders;
