import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { find, isEmpty, omit, upperFirst } from 'lodash';
import { AfterpayClearpayMessageElement } from '@stripe/react-stripe-js';
import { yupResolver } from '@hookform/resolvers/yup';

import { AnimatedWrapper, LabelCheckbox, Typography } from 'components';
import PaymentOptions from 'components/Forms/PaymentForm/PaymentOptions';
import CheckoutAddressForm from 'components/CheckoutComponents/CheckoutAddressForm';
import CheckoutPaymentCardForm from 'components/CheckoutComponents/CheckoutPaymentCardForm';
import ROUTES from 'router/Routes';
import useStripePayment from 'hooks/useStripePayment';
import useGoogleTags from 'hooks/useGoogleTags';
import { AddressFormFieldsType, PaymentValuesType } from 'types/AccountTypes';
import {
  getBasket,
  getCustomerData,
  getCustomerId,
  getCustomerPaymentInstruments,
  getIsLoggedIn,
  getPaymentInProgressData,
} from 'eg_SFCC_FE_core/store/selectors';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  PaymentInstrumentDataType,
  StripePaymentInstrumentType,
} from 'eg_SFCC_FE_core/types';
import { basketActions } from 'eg_SFCC_FE_core/store/reducers/slices/basketSlice';
import { PaymentCardType, PaymentOptionsType } from 'types/CheckoutTypes';
import {
  addressFormSchema,
  paymentFormSchema,
} from 'helpers/validationSchemas';
import { COLORS } from 'styles';
import { formatSuggestedAddress } from 'helpers/formatters';
import { useCheckoutNextSteps } from '../CheckoutPage';
import { OutletPage, MainTitle } from '../styles';
import { CheckboxWrapper, PaymentContainer } from './styles';

let retry = true;

const Payment = () => {
  const dispatch = useAppDispatch();
  const customerId = useSelector(getCustomerId);
  const customerData = useSelector(getCustomerData);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const paymentInProgressData = useSelector(getPaymentInProgressData);
  const { trackEvent } = useGoogleTags();
  const { isSubmitPayment, onFormSubmitError } = useCheckoutNextSteps();
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [paymentOption, setPaymentOption] = useState<PaymentOptionsType>(
    query.get('return') ? 'afterpay' : 'card',
  );
  const { paymentMethods, result: basket } = useSelector(getBasket);
  // method is always CREDIT_CARD for now
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(
    paymentMethods?.find((method) => method?.id === 'CREDIT_CARD'),
  );
  const {
    createStripePaymentCard,
    confirmCardPayment,
    confirmAfterpayPayment,
  } = useStripePayment();

  // errors
  const [errorMessage, setErrorMessage] = useState<string>();

  // handle address form values
  const [isBillingAddressRequired, setIsBillingAddressRequired] =
    useState<boolean>(false);
  const [areAddressValuesValid, setAddressValuesValid] =
    useState<boolean>(false);
  const [arePaymentValuesValid, setPaymentValuesValid] =
    useState<boolean>(false);
  const [isAddressSaved, setIsAddressSaved] = useState<boolean>(false);
  const [addressValues, setAddressValues] = useState<AddressFormFieldsType>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    countryCode: '',
    stateCode: '',
    city: '',
    phone: '',
    postalCode: '',
  });

  const addressMethods = useForm<AddressFormFieldsType>({
    resolver: yupResolver(addressFormSchema),
    defaultValues: addressValues,
  });

  const handleAddress = useCallback((address: AddressFormFieldsType) => {
    address && setAddressValuesValid(true);
    setAddressValues(address);
  }, []);

  const onAddressSubmit: SubmitHandler<AddressFormFieldsType> = (
    data: AddressFormFieldsType,
  ) => {
    data && handleAddress(data);
  };

  const onAddressError = useCallback(() => {
    setAddressValuesValid(false);
  }, []);

  // handle payment form values
  const [paymentValues, setPaymentValues] = useState<PaymentValuesType>({
    country: '',
    zipCode: '',
  });

  const paymentFormMethods = useForm({
    resolver: yupResolver(paymentFormSchema),
    defaultValues: {
      ccnumber: '',
      ccexp: '',
      cvc: '',
      ...paymentValues,
    },
  });

  const onPaymentSubmit: SubmitHandler<PaymentValuesType> = async (data) => {
    if (data) {
      setPaymentValuesValid(true);
      setPaymentValues(data);
    }
  };

  const onPaymentError = useCallback(() => {
    onFormSubmitError();
    setPaymentValuesValid(false);
  }, []);

  const [suggestedAddress, setSuggestedAddress] =
    useState<AddressFormFieldsType>();

  // payment cards
  const customerPaymentCards = useSelector(getCustomerPaymentInstruments);
  const [isSavedPaymentCardUsed, setIsSavedPaymentCardUsed] = useState<boolean>(
    !isEmpty(customerPaymentCards),
  );
  const [isPaymentCardSaved, setIsPaymentCardSaved] = useState<boolean>(
    !!isLoggedIn,
  );
  const [selectedPaymentCard, setSelectedPaymentCard] =
    useState<PaymentCardType>(
      !isEmpty(customerPaymentCards) ? customerPaymentCards[0] : undefined,
    );

  const handleSuccessfulPayment = async () => {
    navigate(ROUTES.CHECKOUT_CONFIRMATION);
    await dispatch(AsyncThunks.syncBasket());

    if (isPaymentCardSaved) {
      await dispatch(AsyncThunks.getCustomer(customerId));
    }
  };

  const handleErrorPayment = async (error?: string) => {
    setErrorMessage(error || 'Payment Failed');
    trackEvent({ event: 'failed_purchase' });
    onFormSubmitError();
  };

  const checkRequiredData = () => {
    if (!isLoggedIn && !basket?.customerInfo.email) {
      throw new Error('Email not added to basket. Go to personal information');
    } else if (!basket?.shipments[0]?.shippingAddress) {
      throw new Error(
        'Shipping address not added to basket. Go to shipping details',
      );
    } else if (!basket.shipments[0].shippingMethod) {
      throw new Error('Shipping method not added to basket');
    }

    onFormSubmitError();
  };

  const getStripeCard = async () => {
    let usedPaymentCard: any;

    if (isSavedPaymentCardUsed) {
      if (!isEmpty(selectedPaymentCard)) {
        usedPaymentCard = selectedPaymentCard;
      } else {
        setErrorMessage('Something went wrong');
        throw new Error('Something went wrong');
      }
    } else {
      let error;

      ({ stripePaymentMethod: usedPaymentCard, error } =
        await createStripePaymentCard({
          country: paymentValues.country,
          zipCode: paymentValues.zipCode,
        }));

      if (error) {
        throw new Error(error);
      }

      const isCardAvailable = !!currentPaymentMethod?.cards.find(
        (card: any) => {
          return (
            card.cardType.replace(/\s/g, '').toLowerCase() ===
            usedPaymentCard?.card?.brand.toLowerCase()
          );
        },
      );

      if (!isCardAvailable) {
        setErrorMessage('Card type is unavailable');
        throw new Error('Card type is unavailable');
      }
    }

    const paymentCardData = {
      expirationMonth: usedPaymentCard?.card.exp_month,
      expirationYear: usedPaymentCard?.card.exp_year,
      maskedNumber: `*********${usedPaymentCard?.card.last4}`,
      cardType: upperFirst(usedPaymentCard?.card.brand),
    };

    // @ts-ignore
    const { error: basketError, payload } = await dispatch(
      AsyncThunks.addPaymentInstrument({
        amount: basket.orderTotal,
        paymentMethodId: currentPaymentMethod.id,
        paymentCard: paymentCardData,
      }),
    );

    if (basketError) {
      setErrorMessage(payload.detail || 'Something went wrong');
      return;
    }

    return {
      stripePaymentCardId: usedPaymentCard.id,
      paymentCard: paymentCardData,
    };
  };

  const getAfterpay = async () => {
    // @ts-ignore
    const { payload, error } = await dispatch(
      AsyncThunks.addPaymentInstrument({
        amount: basket.orderTotal,
        paymentCard: { cardType: 'Afterpay' },
        paymentMethodId: currentPaymentMethod.id,
      }),
    );

    if (error) {
      setErrorMessage(payload.detail);
      return;
    }

    return {
      paymentCard: { cardType: 'Afterpay' },
    };
  };

  const getPaymentInstrument = async () => {
    let paymentInstrument;

    if (paymentOption === 'card') {
      paymentInstrument = await getStripeCard();
    } else if (paymentOption === 'afterpay') {
      paymentInstrument = await getAfterpay();
    }

    if (!paymentInstrument) {
      throw new Error();
    }

    return paymentInstrument;
  };

  const processAddressAdding = async (address: AddressFormFieldsType) => {
    setSuggestedAddress(undefined);
    // @ts-ignore
    const { payload, error } = await dispatch(
      AsyncThunks.addBillingAddressToBasket(address),
    );

    if (payload?.addressError || error) {
      if (payload?.statusCode === 'has_suggested_address') {
        try {
          const formattedSuggestedAddress = formatSuggestedAddress(
            payload.statusMessage,
          );
          setSuggestedAddress(formattedSuggestedAddress);
        } catch (e) {
          // console.log(e)
        }
      }

      setAddressValuesValid(false);
      onFormSubmitError && onFormSubmitError();
      throw new Error();
    }
  };

  const addBillingAddress = async () => {
    if (isBillingAddressRequired && areAddressValuesValid) {
      await processAddressAdding(addressValues);
    } else {
      const shippingAddress = basket?.shipments[0]?.shippingAddress;

      await processAddressAdding({
        ...omit(shippingAddress, 'id'),
        firstName: shippingAddress?.firstName || '',
        lastName: shippingAddress?.lastName || '',
      });
    }
  };

  const createOrder = async () => {
    // @ts-ignore
    const { payload, error } = await dispatch(AsyncThunks.createOrder());

    if (error) {
      throw new Error(payload.detail);
    }

    return payload;
  };

  const getPaymentInstrumentData = (
    stripePaymentInstrument: {
      stripePaymentCardId?: string;
      paymentCard: StripePaymentInstrumentType;
    },
    orderTotal: number,
  ) => {
    if (paymentOption === 'card') {
      return {
        amount: orderTotal,
        paymentMethodId: currentPaymentMethod.id,
        c_stripePaymentMethodId: stripePaymentInstrument.stripePaymentCardId,
        c_stripeSaveCard: isPaymentCardSaved,
        paymentCard: stripePaymentInstrument.paymentCard,
      };
    }

    if (paymentOption === 'afterpay') {
      return {
        amount: orderTotal,
        paymentMethodId: currentPaymentMethod.id,
        paymentCard: stripePaymentInstrument.paymentCard,
        c_useAfterpay: true,
      };
    }
  };

  const handleRequiredStripeAction = async (
    stripePaymentIntentSecret: string,
    paymentInstrumentId: string,
    paymentInstrumentData: PaymentInstrumentDataType,
    orderNo: string,
  ) => {
    if (paymentOption === 'card') {
      const paymentResult = await confirmCardPayment(stripePaymentIntentSecret);

      if (paymentResult.result && paymentResult.result.error) {
        // 3D secure failed payment
        await dispatch(
          AsyncThunks.updatePaymentInstrument({
            orderNo,
            paymentInstrumentId,
            paymentInstrumentData,
          }),
        );
        await dispatch(AsyncThunks.syncBasket({ withShippingAddress: true }));
        throw new Error(paymentResult.result.error.message);
      }

      // @ts-ignore
      const { error: confirmError, payload: confirmPayload } = await dispatch(
        AsyncThunks.updatePaymentInstrument({
          orderNo,
          paymentInstrumentId,
          paymentInstrumentData,
        }),
      );

      if (confirmError && confirmPayload?.c_stripeActionRequired) {
        throw new Error();
      }
    }

    if (paymentOption === 'afterpay') {
      const shippingAddress = basket?.shipments[0]?.shippingAddress;
      const billingAddress = addressValues.address1
        ? addressValues
        : shippingAddress;

      await dispatch(
        basketActions.updatePaymentInProgressData({
          orderNo,
          paymentInstrumentId,
          paymentInstrumentData,
        }),
      );

      await confirmAfterpayPayment(stripePaymentIntentSecret, {
        address: {
          line1: billingAddress.address1,
          line2: billingAddress.address2 || '',
          city: billingAddress.city,
          state: billingAddress.stateCode,
          country: billingAddress.countryCode,
          postal_code: billingAddress.postalCode,
        },
        email: isLoggedIn ? customerData.email : basket.customerInfo.email,
        name: isLoggedIn
          ? `${customerData.firstName} ${customerData.lastName}`
          : billingAddress.fullName,
      });
    }
  };

  const makePayment = async (
    stripePaymentInstrument: {
      stripePaymentCardId?: string;
      paymentCard: StripePaymentInstrumentType;
    },
    orderNo: string,
    orderTotal: number,
    orderPaymentInstruments: PaymentInstrumentDataType[],
  ) => {
    const paymentInstrument = find(orderPaymentInstruments, {
      paymentCard: stripePaymentInstrument.paymentCard,
    });

    const paymentInstrumentData = getPaymentInstrumentData(
      stripePaymentInstrument,
      orderTotal,
    );

    if (!paymentInstrumentData || !paymentInstrument?.paymentInstrumentId) {
      throw new Error('Something went wrong');
    }

    // @ts-ignore
    const { error, payload } = await dispatch(
      AsyncThunks.updatePaymentInstrument({
        orderNo,
        paymentInstrumentId: paymentInstrument?.paymentInstrumentId,
        paymentInstrumentData,
      }),
    );

    if (payload?.c_stripeErrorCode) {
      const errorResult = JSON.parse(payload?.c_stripeErrorResult);
      handleErrorPayment(errorResult?.error.message);
      return;
    }

    if (error) {
      handleErrorPayment(payload.title);
      return;
    }

    if (payload?.c_stripeActionRequired) {
      await handleRequiredStripeAction(
        payload?.c_stripePaymentIntentSecret,
        paymentInstrument.paymentInstrumentId,
        paymentInstrumentData,
        orderNo,
      );
    }

    handleSuccessfulPayment();
  };

  const triggerPaymentActions = async () => {
    try {
      checkRequiredData();
      const paymentInstrument = await getPaymentInstrument();
      await addBillingAddress();
      const { orderNo, orderTotal, paymentInstruments } = await createOrder();
      await makePayment(
        paymentInstrument,
        orderNo,
        orderTotal,
        paymentInstruments,
      );
      // trackCheckoutEvent('add_payment_info');
      onFormSubmitError && onFormSubmitError();
    } catch (e: any) {
      handleErrorPayment(e.message);
    }
  };

  const fetchPaymentMethods = async () => {
    await dispatch(AsyncThunks.getPaymentMethods());
  };

  const continueAfterpayPayment = async () => {
    if (paymentInProgressData && retry) {
      retry = false;
      // @ts-ignore
      const { error: confirmError, payload: confirmPayload } = await dispatch(
        AsyncThunks.updatePaymentInstrument(paymentInProgressData),
      );

      await dispatch(basketActions.updatePaymentInProgressData(null));
      retry = true;

      if (confirmError || confirmPayload?.c_stripeActionRequired) {
        // loader?
        await dispatch(AsyncThunks.syncBasket({ withShippingAddress: true }));
        window.history.replaceState(
          null,
          '',
          window.location.origin + window.location.pathname,
        );
        handleErrorPayment();
        return;
      }

      handleSuccessfulPayment();
    }
  };

  useEffect(() => {
    if (query.get('return')) {
      continueAfterpayPayment();
    }
  }, []);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length) {
      setCurrentPaymentMethod(
        paymentMethods.find((method) => method?.id === 'CREDIT_CARD'),
      );
    } else {
      fetchPaymentMethods();
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (isSubmitPayment) {
      setErrorMessage('');

      if (!isSavedPaymentCardUsed && paymentOption === 'card') {
        paymentFormMethods.clearErrors();
        paymentFormMethods.handleSubmit(onPaymentSubmit, onPaymentError)();
      }
      addressMethods.handleSubmit(onAddressSubmit, onAddressError)();
      if (
        !isBillingAddressRequired ||
        (isBillingAddressRequired && areAddressValuesValid)
      ) {
        if (
          isSavedPaymentCardUsed ||
          (!isSavedPaymentCardUsed && arePaymentValuesValid) ||
          paymentOption === 'afterpay'
        ) {
          triggerPaymentActions();
        }
      }
    }
  }, [isSubmitPayment, areAddressValuesValid, arePaymentValuesValid]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      trackEvent({ event: 'idle_abandoned_purchase' });
    }, 300000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <OutletPage>
      <PaymentContainer>
        <MainTitle>Payment</MainTitle>
        <PaymentOptions
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
        />
        {errorMessage ? (
          <Typography style={{ color: COLORS.accent }}>
            {errorMessage}
          </Typography>
        ) : null}
        <AnimatedWrapper animationKey={paymentOption}>
          {paymentOption === 'card' ? (
            <CheckoutPaymentCardForm
              customerPaymentCards={customerPaymentCards}
              isSavedPaymentCardUsed={isSavedPaymentCardUsed}
              setIsSavedPaymentCardUsed={setIsSavedPaymentCardUsed}
              isLoggedIn={isLoggedIn}
              isPaymentCardSaved={isPaymentCardSaved}
              setIsPaymentCardSaved={setIsPaymentCardSaved}
              selectedPaymentCard={selectedPaymentCard}
              setSelectedPaymentCard={setSelectedPaymentCard}
              paymentValues={paymentValues}
              methods={paymentFormMethods}
              onSubmit={onPaymentSubmit}
            />
          ) : (
            <AfterpayClearpayMessageElement
              options={{ amount: basket.orderTotal * 100, currency: 'USD' }}
            />
          )}
        </AnimatedWrapper>
        <CheckboxWrapper>
          <LabelCheckbox
            id="useShippingAddressAsBillingAddress"
            label="Use shipping address as billing address"
            checked={!isBillingAddressRequired}
            handleChange={() =>
              setIsBillingAddressRequired(!isBillingAddressRequired)
            }
          />
        </CheckboxWrapper>
        {isBillingAddressRequired ? (
          <CheckoutAddressForm
            addressValues={addressValues}
            handleAddress={handleAddress}
            handleError={onAddressError}
            addressMethods={addressMethods}
            onAddressSubmit={onAddressSubmit}
            suggestedAddress={suggestedAddress}
            setSuggestedAddress={setSuggestedAddress}
            isAddressSaved={isAddressSaved}
            setIsAddressSaved={setIsAddressSaved}
          />
        ) : null}
      </PaymentContainer>
    </OutletPage>
  );
};

export default Payment;
