import { Divider } from '@mui/material';

const AuthFormDivider = () => {
  return (
    <Divider
      flexItem
      sx={{
        width: '100%',
        margin: '41px auto',
        maxWidth: '380px',
        color: '#8B8D8F',
        '&::after, &::before': { top: 0 },
      }}
    >
      Or
    </Divider>
  );
};

export default AuthFormDivider;
