import { MainTitle, Paragraph, Title } from '../styles';

const OtherProvisions = () => {
  return (
    <>
      <MainTitle>OTHER PROVISIONS</MainTitle>
      <Title space>A. THIRD-PARTY WEBSITES/APPLICATIONS.</Title>
      <Paragraph>
        The Services may contain links to other websites/applications and other
        websites/applications may reference or link to our Services. These
        third-party services are not controlled by us. We encourage our users to
        read the privacy policies of each website and application with which
        they interact. We do not endorse, screen or approve, and are not
        responsible for, the privacy practices or content of such other websites
        or applications. Providing Personal Information to third-party websites
        or applications is at your own risk.
      </Paragraph>

      <Title space>B. CHANGES TO OUR PRIVACY NOTICE.</Title>
      <Paragraph>
        We are constantly trying to improve our Services, so we may need to
        change this Privacy Notice from time to time, but we will alert you to
        any material changes by placing a notice on the Empower Global website,
        by sending you an email and/or by some other means. Please note that if
        you’ve opted not to receive legal notice emails from us (or you haven’t
        provided us with your email address), those legal notices will still
        govern your use of the Services, and you are still responsible for
        reading and understanding them. You understand and agree that you will
        be deemed to have accepted the updated Privacy Notice if you continue to
        use our Services after the new Privacy Notice takes effect. Use of
        information we collect is subject to the Privacy Notice in effect at the
        time such information is collected.
      </Paragraph>
    </>
  );
};

export default OtherProvisions;
