import styled from 'styled-components';

import { Typography } from 'components';
import FooterLink from 'components/Links/FooterLink';
import { UpdatedLogoIcon } from 'assets/svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

const footerDirectionBreakpoint = `${BREAKPOINTS.l}px`;

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: ${COLORS.footerBackground};

  @media (max-width: ${footerDirectionBreakpoint}) {
    padding: 30px 25px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 25px 25px 30px;
  }

  @media print {
    display: none;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 28px;
  justify-content: space-between;
  align-items: center;
`;

export const Icon = styled.a.attrs({
  target: '_blank',
})<{
  active?: boolean;
}>`
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  transition: 0.3s;
  cursor: pointer;
`;

export const Logo = styled(UpdatedLogoIcon)`
  @media (max-width: ${footerDirectionBreakpoint}) {
    width: 190px;
  }
  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 140px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  @media (max-width: ${footerDirectionBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin: 10px 0 20px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    gap: 2px;
  }
`;

export const StyledLink = styled(FooterLink).attrs(
  ({ id }: { id: string }) => ({ id }),
)`
  font-size: 12px;
  line-height: 15px;
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.grey};
  text-transform: uppercase;
  transition: all 0.3s;
  white-space: nowrap;

  &:hover {
    color: ${COLORS.accent};
  }

  @media (max-width: ${footerDirectionBreakpoint}) {
    padding: 0;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
  }
`;

export const Trademark = styled(Typography).attrs({
  type: 'smallHelv',
})`
  font-size: 12px;
  margin-top: 16px;
  color: ${COLORS.opacityText};
  text-transform: uppercase;

  @media (min-width: ${footerDirectionBreakpoint}) {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
  }
`;
