import { WBackground } from 'assets/svg';
import { ColoredButton, Typography } from 'components';
import styled from 'styled-components';
import { COLORS, FONTS } from 'styles';

export const HeroContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 700px;
`;

export const HeroTitle = styled.h1`
  font-family: ${FONTS.Bison.bold};
  font-size: 128px;
  line-height: 114px;
  text-transform: uppercase;
  color: ${COLORS.black};
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 825px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 45px;
  }
`;

export const HeroSubtitle = styled(Typography).attrs({
  type: 'subtitle1',
})`
  font-size: 21px;
  line-height: 26px;
  color: ${COLORS.black};
  max-width: 1013px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const HeroColoredButton = styled(ColoredButton)`
  width: 170px;
  background-color: ${COLORS.accent};

  & p {
    color: ${COLORS.black};
    font-family: ${FONTS.TTNorms.medium};
  }

  &:hover {
    background: ${COLORS.primary};
  }
`;

export const BackgroundIcon = styled(WBackground)`
  height: 1222px;
  width: 3127px;
  position: absolute;
  z-index: -5;
  right: 180px;
`;

export const DarkLine = styled.div`
  background: ${COLORS.black};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TopLineText = styled(Typography).attrs({
  type: 'subtitle1',
  color: 'primaryInverted',
})`
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const BottomLineText = styled.h2`
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  font-family: ${FONTS.TTNorms.medium};
  color: ${COLORS.white};

  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 80px 90px;
  gap: 50px;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 60px 20px;
  }
`;
