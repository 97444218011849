import { useState } from 'react';

import { AnimatedWrapper } from 'components';
import FavoriteProducts from 'pages/FavoritesPage/FavoriteProducts';
import FavoriteBrands from 'pages/FavoritesPage/FavoriteBrands';
import { Title, ButtonsWrapper, StyledButton, PageWrapper } from './styles';

const FavoritesPage = () => {
  const [selectedTab, setSelectedTab] = useState<'products' | 'brands'>(
    'products',
  );

  return (
    <PageWrapper>
      <div>
        <Title>your favorites</Title>
        <ButtonsWrapper>
          <StyledButton
            aria-label="favorite products"
            isActive={selectedTab === 'products'}
            onClick={() => setSelectedTab('products')}
          >
            Favorite products
          </StyledButton>
          <StyledButton
            aria-label="favorite brands"
            isActive={selectedTab === 'brands'}
            onClick={() => setSelectedTab('brands')}
          >
            Favorite brands
          </StyledButton>
        </ButtonsWrapper>
      </div>

      {selectedTab === 'products' ? (
        <AnimatedWrapper animationKey={selectedTab}>
          <FavoriteProducts />
        </AnimatedWrapper>
      ) : (
        <AnimatedWrapper animationKey={selectedTab}>
          <FavoriteBrands />
        </AnimatedWrapper>
      )}
    </PageWrapper>
  );
};

export default FavoritesPage;
