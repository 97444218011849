import { Typography } from 'components/Typography/Typography';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  margin-bottom: 29px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    gap: 16px;
    margin-bottom: 12px;
  }
`;

export const AddressBlockWrapper = styled.div<{
  useAddressCheckbox?: boolean;
  checkedAddress?: boolean;
}>`
  width: 100%;
  min-width: 258px;
  max-width: 452px;
  border: ${({ checkedAddress }) =>
    checkedAddress
      ? `1px solid ${COLORS.accent}`
      : `1px solid ${COLORS.black}60`};
  padding: 8px;
  cursor: ${({ useAddressCheckbox }) =>
    useAddressCheckbox ? 'pointer' : 'auto'};

  @media (max-width: ${BREAKPOINTS.s}px) {
    max-width: 360px;
  }
`;

export const AddressBlockTitle = styled.h3`
  font-size: 14px;
  line-height: 17px;
  font-family: ${FONTS.Helvetica.medium};
  color: ${COLORS.black};

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const AddressHeaderWrapper = styled.div`
  display: flex;
`;

export const AddressActionsWrapper = styled.div`
  width: 200px;
`;

export const AddressTitle = styled(Typography)`
  width: 179px;
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.6;

  @media (max-width: ${BREAKPOINTS.s}px) {
    width: 151px;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const Address = styled(Typography)`
  width: 151px;
  color: ${COLORS.primary};
  font-size: 12px;
  font-family: ${FONTS.Helvetica.regular};
  line-height: 24px;
  padding-top: 10px;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
    line-height: 20px;
    padding-top: 3px;
  }
`;

export const ActionButton = styled.button<{
  disabled?: boolean;
}>`
  height: 12px;
  text-transform: uppercase;
  font-family: ${FONTS.Helvetica.medium};
  font-size: 11px;
  line-height: 13px;
  opacity: 0.6;
  margin-right: 58px;
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:disabled {
    color: inherit;
  }

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 9px;
    margin-right: 35px;
  }

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    max-width: 85px;
    text-align: start;
  }
`;
