export default {
  black: '#000000',
  white: '#FFFFFF',
  primary: '#222222',
  cardBackground: '#F6F5F3',
  border: '#E3E3E3',
  buttonBorder: '#dcdcdc',
  searchInputBorder: '#C4C4C4',
  background: '#F6F6F6',
  semiDark: '#282828',
  opacityText: '#707474',
  accentBackground: '#DEE4EB',

  accent: '#ED0000',
  pale: '#D9D9D9',
  updatedAccentBackground: '#173808',
  headerBackground: '#F8F7F7',
  footerBackground: '#FDFDFD',
  middleGrey: '#B3B3B3',
  grey: '#777777',
  lightGrey: '#999999',
  textGrey: '#666666',
};
