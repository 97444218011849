import { CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import { ProductItemType } from 'types/ProductsTypes';
import {
  StyledColoredButton,
  AddToCartItemValues,
  AddToCartConfirmationContentWrapper,
  ConfirmationTitle,
  ConfirmationItemBrandName,
  ConfirmationItemProductName,
  ConfirmationButtonsWrapper,
  ConfirmationItemImage,
  ConfirmationContentCrossIcon,
  AddToCartConfirmationWrapper,
  ConfirmationDetailsWrapper,
} from './styles';

const AddToWishlistConfirmationContent = ({
  unSetModal,
  product,
}: {
  unSetModal: () => void;
  product: ProductItemType;
}) => {
  return (
    <AddToCartConfirmationWrapper>
      <ConfirmationContentCrossIcon onClick={unSetModal} />
      <ConfirmationTitle>Item added to wishlist</ConfirmationTitle>
      {product ? (
        <AddToCartConfirmationContentWrapper>
          <ConfirmationItemImage
            src={
              product.imageGroups ? product.imageGroups[0]?.images[0].link : ''
            }
            alt={product.name}
          />
          <AddToCartItemValues>
            <ConfirmationDetailsWrapper style={{ marginBottom: 45 }}>
              <ConfirmationItemBrandName>
                {product.brand}
              </ConfirmationItemBrandName>
              <ConfirmationItemProductName>
                {product.name}
              </ConfirmationItemProductName>
            </ConfirmationDetailsWrapper>

            <ConfirmationButtonsWrapper>
              <StyledColoredButton
                id="continueShopping"
                aria-label="continue shopping"
                onClick={unSetModal}
              >
                continue shopping
              </StyledColoredButton>
              <CustomNavLink to={ROUTES.FAVORITES}>
                <StyledColoredButton id="viewCart" aria-label="view wishlist">
                  view wishlist
                </StyledColoredButton>
              </CustomNavLink>
            </ConfirmationButtonsWrapper>
          </AddToCartItemValues>
        </AddToCartConfirmationContentWrapper>
      ) : null}
    </AddToCartConfirmationWrapper>
  );
};

export default AddToWishlistConfirmationContent;
