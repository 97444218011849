import { CustomNavLink } from 'components';
import ROUTES from 'router/Routes';
import { COLORS } from 'styles';
import { Paragraph, UppercaseTitle } from '../styles';

const YourPrivacy = () => (
  <>
    <UppercaseTitle>3. Your Privacy</UppercaseTitle>
    <Paragraph>
      We know your personal information is important to you, and we want you to
      know it’s important to us. Our Privacy Policy details how your information
      is used when you use our Services. By using our Services, you&apos;re also
      agreeing to our processing method as set out in the Privacy Policy, so
      please read it{' '}
      <CustomNavLink
        to={ROUTES.PRIVACY_POLICY}
        style={{ color: COLORS.accent }}
      >
        here
      </CustomNavLink>
      .
    </Paragraph>
    <Paragraph>
      If, however, Empower Global and sellers are found to be joint data
      controllers of buyers’ personal information, and if Empower Global is
      sued, fined, or otherwise incurs expenses because of something that you,
      as a seller, did as a joint data controller of buyer personal information,
      you agree to indemnify Empower Global for the expenses it occurs in
      connection with your processing of buyer personal information. See Section
      9. Indemnification (or What Happens If You Get Us Sued) below for more
      information about your indemnification obligations to Empower Global.
    </Paragraph>
  </>
);

export default YourPrivacy;
