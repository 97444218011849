import styled, { css } from 'styled-components';

import { UpdatedLogoIcon, EGLogoIcon, CrossIcon } from 'assets/svg';
import { BREAKPOINTS, COLORS, FONTS } from 'styles';
import { HEADER_NAV_HEIGHT } from 'helpers/positions';

export const Wrapper = styled.header<{
  isTransparent: boolean | undefined;
  isHeaderSticky: boolean;
  hasHeaderWhiteMenuText: boolean;
}>`
  background: ${({ isTransparent }) =>
    isTransparent ? 'transparent' : COLORS.headerBackground};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  ${({ isHeaderSticky }) =>
    isHeaderSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 999;
      height: ${HEADER_NAV_HEIGHT}px;
    `}

  @media (max-width: ${BREAKPOINTS.s}px) {
    padding: 0 15px;
  }

  a,
  div,
  path,
  svg {
    color: ${({ hasHeaderWhiteMenuText }) =>
      hasHeaderWhiteMenuText ? COLORS.white : COLORS.black};
    fill: ${({ hasHeaderWhiteMenuText }) =>
      hasHeaderWhiteMenuText ? COLORS.white : COLORS.black};
  }

  @media print {
    display: none;
  }
`;

export const StyledEGLogoIcon = styled(EGLogoIcon)`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 25px;
  }
`;

export const StyledUpdatedLogoIcon = styled(UpdatedLogoIcon)`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 25px;
  }

  max-width: 300px;
`;

export const RightBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
`;

export const LeftBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

export const IconsBlock = styled.div<{
  isLoggedIn?: boolean;
}>`
  max-width: ${({ isLoggedIn }) => (isLoggedIn ? 260 : 250)}px;
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 45px;
  }
`;

export const Icon = styled.div<{
  active?: boolean;
}>`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    path,
    rect {
      fill: ${COLORS.accent};
      transition: 0.3s;
    }

    line {
      stroke: ${COLORS.accent};
      transition: 0.3s;
    }

    div {
      color: ${COLORS.accent};
    }
  }

  ${({ active }) =>
    active &&
    css`
      & path {
        fill: ${COLORS.accent};
      }
      border: 1px solid ${COLORS.accent};
      transition: 0.3s ease-out;
    `}

  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 35px;
    width: 35px;

    & svg {
      height: 20px;
    }
  }
`;

export const StyledHeartIcon = styled(Icon)`
  path {
    fill: transparent;
  }

  &:hover {
    path {
      fill: transparent;
      stroke: ${COLORS.accent};
      transition: 0.3s;
    }
  }
`;

export const StyledSearchIcon = styled(Icon)`
  padding-top: 8px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-top: 6px;
  }
`;

export const TextButtonWrapper = styled.div`
  margin-right: 15px;

  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-right: 10px;
  }
`;

export const CartItemsNumber = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-family: ${FONTS.Helvetica.bold};

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
    bottom: 5px;
  }
`;

export const BottomBarContainer = styled.div`
  display: flex;
  position: relative;
  padding: 14px 10px;
  align-items: center;
  justify-content: center;
  background-color: #cbcbcb;
  text-align: center;

  font-size: 20px;
  font-family: ${FONTS.Helvetica.medium};

  @media (max-width: ${BREAKPOINTS.m}px) {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 10px;
  }
`;

export const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
`;
