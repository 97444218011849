import styled from 'styled-components';
import { AccentWBackground, EGStampIcon } from 'assets/svg';
import { FONTS } from 'styles';

export const Wrapper = styled.div`
  position: relative;
  height: 770px;
  margin-top: -126px;
  text-align: center;
  padding-top: 288px;
  overflow: hidden;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -600px;
  overflow: hidden;
  width: 100%;
`;

export const BackgroundImage = styled(AccentWBackground)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -600px;
`;

export const Title = styled.h2`
  font-family: ${FONTS.TTNorms.bold};
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 32px;
  margin-top: 15px;

  @media (max-width: 1000px) {
    font-size: 20px;
    margin: 0;
  }
`;

export const EGStamp = styled(EGStampIcon)`
  position: absolute;
  bottom: 80px;
  right: 5vw;
  transform: rotate(30deg);

  @media (max-width: 1000px) {
    display: none;
  }
`;
