import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components';
import OrderAccordion from 'components/Accordion/OrderAccordion';
import Pagination from 'components/Pagination/Pagination';
import usePagination from 'hooks/usePagination';
import useDimensions from 'hooks/useDimensions';
import { formatDate, formatPrice } from 'helpers/formatters';
import {
  getCustomerOrderLists,
  getCustomerOrderListsLoader,
} from 'eg_SFCC_FE_core/store/selectors';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import OrderAccordionContent from 'components/OrdersComponents/OrderAccordionContent';
import { BREAKPOINTS } from 'styles';
import {
  OrderHeaderTitle,
  OrderHelperTitle,
  OrderStatusWrapper,
  OrderStatusTitle,
  OrderTitlesWrapper,
  OrderTotalText,
} from 'components/OrdersComponents/styles';
import {
  BlockTitle,
  OrderHeader,
  OrdersCardWrapper,
  OrderTopContainer,
  RightBlockWrapper,
} from './styles';

const PAGE_SIZE = 5;

export const marketPlacerStatusData: any = {
  SENT: 'shipped',
  PARTIALLY_SENT: 'partially shipped',
  REFUNDED: 'returned',
  NOT_SHIPPED: 'in progress',
  CANCELLED: 'cancelled',
  SHIPPED: 'shipped',
};

export const zenkraftStatusData: any = {
  CREATED: () => 'Label Created',
  PICKED_UP: () => 'Product/Order Picked-Up',
  IN_TRANSIT: (target: 'Product' | 'Order') => `${target} In-Transit`,
  OUT_FOR_DELIVERY: (target: 'Product' | 'Order') =>
    `${target} Out for Delivery`,
  DELIVERED: (target: 'Product' | 'Order') => `${target} Delivered`,
  SHIPMENT_VOIDED: (target: 'Product' | 'Order') => `${target} Cancelled`,
  EXCEPTION: () => 'There is a delay or issue with the shipment',
};

const MyOrdersPage = () => {
  const { width } = useDimensions();
  const dispatch = useDispatch();
  const { orderList, orderListTotal } = useSelector(getCustomerOrderLists);
  const isLoading = useSelector(getCustomerOrderListsLoader);
  const { currentPage, setPage } = usePagination();

  const fetchOrders = async () => {
    await dispatch(
      AsyncThunks.getCustomerOrderList({
        limit: PAGE_SIZE,
        offset: (currentPage - 1) * PAGE_SIZE,
      }),
    );
  };

  const data = orderList?.map((order) => ({
    id: order.orderNo,
    title: (
      <div style={{ display: 'block', width: '100%', paddingTop: 15 }}>
        <OrderHeader>
          <OrderHeaderTitle>{formatDate(order.creationDate)}</OrderHeaderTitle>
          <OrderHeaderTitle>Order #{order.orderNo}</OrderHeaderTitle>
          <OrderHeaderTitle>{formatPrice(order.orderTotal)}</OrderHeaderTitle>
        </OrderHeader>
        <OrderStatusWrapper>
          <OrderStatusTitle>
            {order.c_marketplacerShippingStatus === 'SHIPPED' &&
            order.c_zenkraftShippingStatus
              ? zenkraftStatusData[order.c_zenkraftShippingStatus]('Order')
              : marketPlacerStatusData[order.c_marketplacerShippingStatus]}
          </OrderStatusTitle>
          <OrderTitlesWrapper>
            <OrderHeaderTitle>
              {width > BREAKPOINTS.s ? 'Estimated delivery: ' : 'ETA: '}
              {order?.shipments[0]?.shippingMethod.c_estimatedArrivalTime}
            </OrderHeaderTitle>
            <OrderHelperTitle>View Details</OrderHelperTitle>
          </OrderTitlesWrapper>
        </OrderStatusWrapper>
      </div>
    ),
    content: <OrderAccordionContent order={order} />,
  }));

  useEffect(() => {
    if (window.location.pathname.includes('/returns')) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    fetchOrders();
  }, [currentPage]);

  return (
    <RightBlockWrapper>
      <BlockTitle>Orders</BlockTitle>
      <OrderTopContainer>
        <OrderTotalText>Total orders: {orderListTotal}</OrderTotalText>
      </OrderTopContainer>

      <OrdersCardWrapper>
        {isLoading && <Loader />}
        {!isLoading && orderList?.length ? (
          <OrderAccordion data={data} />
        ) : null}
      </OrdersCardWrapper>

      <Pagination
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        totalCount={orderListTotal}
        setCurrentPage={setPage}
        style={{ padding: '0 10px', margin: '40px 0 20px' }}
        size="small"
      />
    </RightBlockWrapper>
  );
};

export default MyOrdersPage;
