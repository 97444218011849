import { NavLink } from 'react-router-dom';
import ROUTES from 'router/Routes';
import { List, ListItem, MainTitle, Paragraph, Title } from '../styles';

const CaliforniaResidentRights = () => {
  return (
    <>
      <MainTitle>CALIFORNIA RESIDENT RIGHTS</MainTitle>
      <Paragraph>
        If you are a California resident, you have the rights set forth in this
        section. Please see the “Exercising Your Rights” section below for
        instructions regarding how to exercise these rights. Please note that we
        may process Personal Information of our customers’ end users or
        employees in connection with our provision of certain services to our
        customers. If we are processing your Personal Information as a service
        provider, you should contact the entity that collected your Personal
        Information in the first instance to address your rights with respect to
        such data.
      </Paragraph>
      <Paragraph space>
        If there are any conflicts between this section and any other provisions
        of this Privacy Notice, and you are a California resident, the portion
        that is more protective of Personal Information shall control to the
        extent of such conflict. If you have any questions about this section,
        or whether any of the following rights apply to you, please email us at{' '}
        <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>{' '}
        or send an official notice by mail to CE Marketplace c/o Legal
        Department, General Counsel Empower Global, at 384 Northyards Blvd NW,
        #67, Atlanta, GA 30313.
      </Paragraph>

      <Title space>A. ACCESS</Title>
      <Paragraph>
        You have the right to request certain information about our collection
        and use of your Personal Information over the past 12 months. In
        response, we will provide you with the following information:
      </Paragraph>
      <List style={{ marginTop: 10 }}>
        <ListItem>
          Categories of Personal Information that we have collected about you
        </ListItem>
        <ListItem>
          Categories of sources from which that Personal Information was
          collected
        </ListItem>
        <ListItem>
          Business or commercial purpose for collecting or selling your Personal
          Information
        </ListItem>
        <ListItem>
          Categories of third parties with whom we have shared your Personal
          Information
        </ListItem>
        <ListItem>
          Specific pieces of Personal Information that we have collected about
          you
        </ListItem>
      </List>

      <Paragraph space>
        If we have disclosed your Personal Information to any third parties for
        a business purpose over the past 12 months, we will identify the
        categories of Personal Information shared with each category of third
        party recipient. If we have sold your Personal Information over the past
        12 months, we will identify the categories of Personal Information sold
        to each category of third party recipient.
      </Paragraph>

      <Title space>B. DELETION</Title>
      <Paragraph space>
        You have the right to request that we delete the Personal Information
        that we have collected about you. Under the CCPA, this right is subject
        to certain exceptions: for example, we may need to retain your Personal
        Information to provide you with the Services or complete a transaction
        or other action you have requested. If your deletion request is subject
        to one of these exceptions, we may deny your deletion request.
      </Paragraph>

      <Title space>
        C. PERSONAL INFORMATION SELLING AND SHARING OPT-OUT AND OPT-IN
      </Title>
      <Paragraph space>
        In this section, we use the term “sale” and “share” as it is defined in
        the CCPA. We may sell or share your Personal Information, subject to
        your right to opt out of such selling or sharing.
      </Paragraph>
      <Paragraph space>
        As described in the “Tracking Tools, Advertising, and Opt-Out” section
        above, we have incorporated Cookies from certain third parties into our
        Services. These Cookies allow those third parties to receive information
        about your activity on our Services that is associated with your browser
        or device. Those third parties may use that data to serve you relevant
        ads on our Services or on other websites you visit. Under the CCPA,
        sharing your data through third party Cookies for online advertising may
        be considered a “sale” or “share” of information. You can opt out of
        these sales or sharing by following the instructions in this section.
      </Paragraph>
      <Paragraph space>
        We may sell or share your Personal Information to the following
        categories of third parties:
      </Paragraph>

      <List>
        <ListItem>Ad networks</ListItem>
        <ListItem>Advertising Partners</ListItem>
        <ListItem>Certain Analytics Partners</ListItem>
      </List>

      <Paragraph space>
        Over the past 12 months, we have sold or shared the following categories
        of your Personal Information to the categories of third parties listed
        above:
      </Paragraph>

      <List>
        <ListItem>Identifiers</ListItem>
        <ListItem>Commercial Data</ListItem>
        <ListItem>Device/IP Data</ListItem>
        <ListItem>Web Analytics</ListItem>
        <ListItem>Social Network Data</ListItem>
        <ListItem>Location Data</ListItem>
      </List>

      <Paragraph space>
        You have the right to opt out from the selling or sharing of your
        Personal Information. You can opt out using the following methods:
      </Paragraph>

      <List>
        <ListItem>
          Email us at{' '}
          <a href="mailto:legal@EmpowerGlobal.shop">legal@EmpowerGlobal.shop</a>
          .
        </ListItem>
        <ListItem>
          By opting out from the selling or sharing of your Personal Information
          available in our website{' '}
          <NavLink to={ROUTES.COOKIES_PREFERENCES}>Cookie Preferences</NavLink>
        </ListItem>
      </List>
      <Paragraph space>
        Once you have submitted an opt-out request, we will not ask you to
        reauthorize the sale or sharing of your Personal Information for at
        least 12 months.
      </Paragraph>
      <Paragraph space>
        To our knowledge, we do not sell or share the Personal Information of
        minors under 16 years of age. If you are under the age of 16 years old,
        please do not use our service.{' '}
      </Paragraph>

      <Title space>
        D. WE WILL NOT DISCRIMINATE AGAINST YOU FOR EXERCISING YOUR RIGHTS UNDER
        THE CCPA
      </Title>
      <Paragraph space>
        California residents have the right not to receive discriminatory
        treatment by us for the exercise of their rights conferred by the CCPA.
        We will not discriminate against you for exercising your rights under
        the CCPA. We will not deny you our goods or services, charge you
        different prices or rates, or provide you a lower quality of goods and
        services if you exercise your rights under the CCPA. However, we may
        offer different tiers of our Services as allowed by applicable data
        privacy laws (including the CCPA) with varying prices, rates or levels
        of quality of the goods or services you receive related to the value of
        the Personal Information we receive from you.
      </Paragraph>
    </>
  );
};

export default CaliforniaResidentRights;
