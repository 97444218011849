import styled from 'styled-components';
import { AnimatedLinkUpdated, Typography } from 'components';
import { BREAKPOINTS, FONTS } from 'styles';

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  background-color: black;
`;

export const HoverContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const HoverOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: 0.4s;
`;

export const Link = styled(AnimatedLinkUpdated).attrs({
  type: 'white',
})<{ linkPosition: string }>`
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  left: ${(props) => (props.linkPosition.indexOf('left') === -1 ? 'unset' : 0)};
  right: ${(props) =>
    props.linkPosition.indexOf('right') === -1 ? 'unset' : 0};
  top: ${(props) => (props.linkPosition.indexOf('top') === -1 ? 'unset' : 0)};
  bottom: ${(props) =>
    props.linkPosition.indexOf('bottom') === -1 ? 'unset' : 0};

  @media (max-width: ${BREAKPOINTS.s}px) {
    left: 0;
    right: unset;
    top: unset;
    bottom: 0;
  }
`;

export const HoverableTitle = styled(Typography).attrs({
  color: 'primaryInverted',
  type: 'title2Helv',
})`
  font-family: ${FONTS.Helvetica.medium};
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: ${BREAKPOINTS.s}px) {
    font-size: 14px;
  }
`;

export const HoverableCardWrapper = styled.div`
  flex-basis: 50%;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  padding: 20px;

  &:hover {
    ${HoverOverlay} {
      opacity: 1;
    }

    ${HoverContent} {
      z-index: 5;
    }
  }

  @media (max-width: ${BREAKPOINTS.s}px) {
    min-height: 500px;
    flex-basis: 100%;
  }
`;
