import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';

import useGoogleTags from 'hooks/useGoogleTags';

import HomepageHeroSlider from 'components/Homepage/HeroSlider/HomepageHeroSlider';
import HomepageCategorySlider from 'components/Homepage/CategorySlider/HomepageCategorySlider';
import HomepageNewsletterSection from 'components/Homepage/NewsletterSection/HomepageNewsletterSection';
import HomepageFoundersSection from 'components/Homepage/FoundersSection/HomepageFoundersSection';
import HomepageFeaturedProducts from 'components/Homepage/FeaturedProducts/HomepageFeaturedProducts';

import { Wrapper, StyledContainer } from './styles';

const HomePage = () => {
  const { addPageView } = useGoogleTags();

  useEffect(() => {
    addPageView('home');
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Power The Black Economy | Empower Global</title>
        <meta
          name="description"
          content="Empower Global is the premiere online destination for Black-owned brands and consumers who want to shop with brands that align with their values."
        />
      </Helmet>

      <StyledContainer style={{ maxWidth: '1723px' }}>
        <Box>
          <HomepageHeroSlider />
        </Box>

        <Container style={{ padding: '0 10px' }}>
          <Box style={{ marginTop: '50px' }}>
            <HomepageCategorySlider />
          </Box>
        </Container>
      </StyledContainer>

      <StyledContainer>
        <Box style={{ marginTop: '50px' }}>
          <HomepageNewsletterSection />
        </Box>
      </StyledContainer>

      <StyledContainer style={{ maxWidth: '1723px' }}>
        <Box style={{ marginTop: '80px' }}>
          <HomepageFeaturedProducts />
        </Box>
      </StyledContainer>

      <StyledContainer>
        <Box style={{ marginTop: '50px' }}>
          <HomepageFoundersSection />
        </Box>
      </StyledContainer>
    </Wrapper>
  );
};

export default HomePage;
