const useHtml = () => {
  const addScript = ({
    src,
    id,
    async = true,
    onLoad,
    innerHtml,
    placeToInsert = 'head',
    noscript = false,
  }: {
    src?: string;
    id: string;
    async?: boolean;
    noscript?: boolean;
    onLoad?: () => void;
    innerHtml?: string;
    placeToInsert?: 'head' | 'body';
  }) => {
    const existing = document.getElementById(id);

    if (existing) {
      return existing;
    }

    let script = null;

    if (!noscript) {
      script = document.createElement('script');
      src && (script.src = src);
      async && (script.async = true);
      innerHtml && (script.textContent = innerHtml);
    } else {
      script = document.createElement('noscript');
      innerHtml && (script.innerHTML = innerHtml);
    }

    script.id = id;

    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
    };

    if (placeToInsert === 'head') {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    return script;
  };

  const removeScript = ({ id }: { id: string }) => {
    const script = document.getElementById(id);

    if (script) {
      document.head.removeChild(script);
    }
  };

  const addMeta = ({
    id,
    httpEquiv,
    content,
  }: {
    id: string;
    httpEquiv: string;
    content: string;
  }) => {
    const existing = document.getElementById(id);

    if (existing) {
      return existing;
    }

    const meta = document.createElement('meta');
    meta.httpEquiv = httpEquiv;
    meta.content = content;

    document.head.appendChild(meta);

    return meta;
  };

  return { addScript, removeScript, addMeta };
};

export default useHtml;
