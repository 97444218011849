import { Dispatch, SetStateAction } from 'react';
import { PaymentCardType } from 'types/CheckoutTypes';
import { CARD_ICONS } from 'appConstants';
import BasicSelect from './BasicSelect';
import { StyledMenuItem, StyledListItemText, PaymentCardText } from '../styles';

const PaymentCardSelect = ({
  items,
  currentMenuItem,
  setCurrentMenuItem,
}: {
  items: PaymentCardType[];
  currentMenuItem: PaymentCardType;
  setCurrentMenuItem: Dispatch<SetStateAction<PaymentCardType>>;
}) => {
  const generateCardText = (menuItem: PaymentCardType) => {
    return (
      <PaymentCardText>
        <span>
          <img
            src={require(`../../../${CARD_ICONS[menuItem.card.brand]}`)}
            alt="cards"
            style={{ marginRight: 10 }}
          />
          <span>**** **** **** {menuItem.card.last4}</span>
        </span>
        <span>
          Exp.{menuItem.card.exp_month}/{menuItem.card.exp_year}
        </span>
      </PaymentCardText>
    );
  };

  const handleClick = (_: any, menuItem: PaymentCardType) => {
    setCurrentMenuItem(menuItem);
  };

  const renderLabel = () => <p>{generateCardText(currentMenuItem)}</p>;

  return (
    <BasicSelect
      id="savedCardsDropdown"
      renderLabel={renderLabel}
      optionValues={[]}
      variant="standard"
      formControlSx={{ width: '100%', borderBottom: '1px solid black' }}
    >
      {items.map((menuItem: PaymentCardType, index: number) => (
        <StyledMenuItem
          value={menuItem.id}
          onClick={(event: any) => handleClick(event, menuItem)}
          key={index}
        >
          <StyledListItemText primary={generateCardText(menuItem)}>
            {generateCardText(menuItem)}
          </StyledListItemText>
        </StyledMenuItem>
      ))}
    </BasicSelect>
  );
};

export default PaymentCardSelect;
