import { upperFirst } from 'lodash';

import { ProductItemPrice } from 'components';
import CustomNavLink from 'components/Links/CustomNavLink';
import { Typography } from 'components/Typography/Typography';
import { formatNameForUrl } from 'helpers/formatters';
import ROUTES from 'router/Routes';
import { BasketProductItemType } from 'types/ProductsTypes';
import { FONTS } from 'styles';
import { useEffect, useState } from 'react';
import {
  CartItemWrapper,
  CartItemText,
  CartActionsWrapper,
  CartItemTitleWrapper,
  CartItemInfoWrapper,
  OptionChangeIcon,
  CartProductNameText,
  CartItemVariationWrapper,
} from './styles';

const CartModalItem = ({
  cartItem,
  removeFromCart,
  closeModal,
  addToWishList,
  updateItem,
}: {
  cartItem: BasketProductItemType;
  removeFromCart: (cartItem: BasketProductItemType) => void;
  closeModal: () => void;
  addToWishList: (itemId: string, productId: string, quantity: number) => void;
  updateItem?: (
    itemId: string,
    productId: string,
    updatedQuantity: number,
  ) => Promise<string | undefined>;
}) => {
  const [error, setError] = useState<string>();
  const {
    itemId,
    quantity,
    image,
    c_marketplacerSellerName: marketplacerSellerName,
    productId,
    productName,
    priceAfterItemDiscount,
    priceAfterOrderDiscount,
    variationValues,
    price,
    productPromotions,
    discountedPrice,
    originalPrice,
    inventory,
  } = cartItem;
  const isItemOrderable = inventory?.orderable;

  const decreaseQuantity = async () => {
    if (updateItem) {
      const err = await updateItem(itemId, productId, quantity - 1);
      err && setError(err);
    }
  };

  const increaseQuantity = async () => {
    if (updateItem) {
      const err = await updateItem(itemId, productId, quantity + 1);
      err && setError(err);
    }
  };

  useEffect(() => {
    if (!isItemOrderable && inventory) {
      setError('Item is not available for order. Remove it from basket');
    } else {
      setError('');
    }
  }, [isItemOrderable]);

  return (
    <div>
      <CartItemWrapper orderable={isItemOrderable}>
        <div style={{ width: 130 }}>
          {image ? (
            <img
              src={image?.link}
              alt={image?.alt}
              style={{
                width: 100,
                marginRight: 20,
              }}
            />
          ) : null}
        </div>
        <CartItemInfoWrapper>
          <CartItemTitleWrapper>
            <div>
              <CartItemText>{marketplacerSellerName}</CartItemText>
              <CustomNavLink
                to={`${ROUTES.PRODUCTS}/${formatNameForUrl(
                  cartItem.productName,
                )}-${productId}`}
                onClick={closeModal}
              >
                <CartProductNameText>{productName}</CartProductNameText>
              </CustomNavLink>
            </div>
            {variationValues
              ? Object.keys(variationValues).map((key: string) => (
                  <CartItemVariationWrapper key={key}>
                    <Typography type="xsmallHelv" style={{ marginRight: 5 }}>
                      {upperFirst(key)}:
                    </Typography>
                    <CartItemText>{variationValues[key]}</CartItemText>
                  </CartItemVariationWrapper>
                ))
              : null}
          </CartItemTitleWrapper>

          {productPromotions?.length ? (
            <div style={{ marginTop: 20 }}>
              {productPromotions.map(
                (promotion: { calloutMsg: string; promotionId: string }) => (
                  <Typography key={promotion.promotionId} color="accent">
                    {promotion.calloutMsg}
                  </Typography>
                ),
              )}
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <CartItemVariationWrapper>
              {updateItem ? (
                <OptionChangeIcon onClick={decreaseQuantity}>
                  -
                </OptionChangeIcon>
              ) : null}
              <Typography
                type="xsmallHelv"
                style={{ margin: '0 15px', fontFamily: FONTS.Helvetica.medium }}
              >
                {quantity}
              </Typography>
              {updateItem ? (
                <OptionChangeIcon onClick={increaseQuantity}>
                  +
                </OptionChangeIcon>
              ) : null}
            </CartItemVariationWrapper>
            <div style={{ whiteSpace: 'nowrap' }}>
              <ProductItemPrice
                price={price}
                priceAfterOrderDiscount={priceAfterOrderDiscount}
                priceAfterItemDiscount={priceAfterItemDiscount}
                discountedPrice={discountedPrice}
                originalPrice={originalPrice}
              />
            </div>
          </div>
        </CartItemInfoWrapper>
      </CartItemWrapper>
      {error && (
        <Typography
          type="xsmallHelv"
          color="accent"
          style={{ textAlign: 'center' }}
        >
          {error}
        </Typography>
      )}
      <CartActionsWrapper>
        <button
          type="button"
          id="moveToWishList"
          aria-label="move to wishlist"
          onClick={() => addToWishList(itemId, productId, quantity)}
        >
          Move To Wishlist
        </button>
        <button
          type="button"
          id="remove"
          aria-label="remove"
          onClick={() => removeFromCart(cartItem)}
        >
          Remove
        </button>
      </CartActionsWrapper>
    </div>
  );
};

export default CartModalItem;
