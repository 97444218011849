import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import { catalog } from 'data/catalog';
import { useAppDispatch } from 'eg_SFCC_FE_core/store';
import { AsyncThunks } from 'eg_SFCC_FE_core/store/actions';
import {
  getBasket,
  getCustomerId,
  getIsLoggedIn,
} from 'eg_SFCC_FE_core/store/selectors';
import { COOKIES_KEYS } from 'eg_SFCC_FE_core/axios/apiConstants';
import { basketActions } from 'eg_SFCC_FE_core/store/reducers/slices/basketSlice';
import { orderActions } from 'eg_SFCC_FE_core/store/reducers/slices/orderSlice';
import { customerProductListsActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerProductLists';

import Router from 'router/Router';
import useHtml from 'hooks/useHtml';
import { customerActions } from 'eg_SFCC_FE_core/store/reducers/slices/customerSlice';
import { useAuthModals } from 'hooks/useModals';
import ROUTES from 'router/Routes';

let stripePromise: any;

const getStripe = () => {
  if (!stripePromise) {
    try {
      stripePromise = loadStripe(
        String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.log(e);
    }
  }

  return stripePromise;
};

getStripe();

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID || '' });

const App = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { result } = useSelector(getBasket);
  const customerId = useSelector(getCustomerId);
  const dispatch = useAppDispatch();
  const { addScript } = useHtml();
  const { showLoginModal } = useAuthModals();
  const query = new URLSearchParams(useLocation().search);

  const fetchBasket = async () => {
    await dispatch(AsyncThunks.syncBasket());
  };

  const fetchCustomerProductLists = async () => {
    await dispatch(AsyncThunks.getCustomerProductLists(customerId));
  };

  const getGuestUserBasket = async () => {
    !result && (await dispatch(AsyncThunks.createBasket()));
  };

  const getCategories = async () => {
    const ids = [...catalog]
      .map(({ id }: { id: string | null }) => id)
      .join(',');

    await dispatch(AsyncThunks.getShopperCategoriesByIds(ids));
  };

  const clearStaleData = async () => {
    const refreshToken = await Cookies.get(COOKIES_KEYS.refreshToken);

    if (isLoggedIn && !refreshToken) {
      await dispatch(basketActions.reset());
      await dispatch(orderActions.reset());
      await dispatch(customerProductListsActions.reset());
      await dispatch(customerActions.reset());
    }
  };

  const addUserbackScript = () => {
    window.Userback = window.Userback || {};
    window.Userback.access_token = process.env.REACT_APP_USERBACK_ACCESS_TOKEN;

    addScript({
      src: 'https://static.userback.io/widget/v1.js',
      id: 'userback-script',
    });
  };

  const addAccessibeScript = () => {
    addScript({
      id: 'accessibe-script',
      placeToInsert: 'body',
      async: false,
      innerHtml: `
        (function () {
          var s = document.createElement('script');
          var h = document.querySelector('head') || document.body;
          s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
          s.async = true;
          s.onload = function () {
            acsbJS.init({
              statementLink: '',
              footerHtml: '',
              hideMobile: false,
              hideTrigger: false,
              disableBgProcess: false,
              language: 'en',
              position: 'right',
              leadColor: '#146FF8',
              triggerColor: '#146FF8',
              triggerRadius: '50%',
              triggerPositionX: 'right',
              triggerPositionY: 'bottom',
              triggerIcon: 'people',
              triggerSize: 'bottom',
              triggerOffsetX: 20,
              triggerOffsetY: 20,
              mobile: {
                triggerSize: 'small',
                triggerPositionX: 'right',
                triggerPositionY: 'bottom',
                triggerOffsetX: 20,
                triggerOffsetY: 20,
                triggerRadius: '20',
              },
            });
          };
          h.appendChild(s);
        })();
      `,
    });
  };

  const addFacebookPixelScript = () => {
    addScript({
      id: 'pixel-script',
      async: false,
      innerHtml: `
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1254171085259787');
        fbq('track', 'PageView');
      `,
    });
    addScript({
      id: 'pixel-noscript',
      async: false,
      noscript: true,
      innerHtml:
        '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1254171085259787&ev=PageView&noscript=1" />',
    });
  };

  useEffect(() => {
    !isLoggedIn &&
      process.env.REACT_APP_GUEST_LOGIN !== 'true' &&
      pathname !== ROUTES.RESET_PASSWORD &&
      showLoginModal();
  }, [pathname]);

  useEffect(() => {
    getCategories();
    clearStaleData();
    addUserbackScript();
    addAccessibeScript();
    addFacebookPixelScript();

    if (isLoggedIn) {
      // prevent creation basket after payment failure
      if (!query.get('return')) {
        fetchBasket();
      }
      fetchCustomerProductLists();
    } else {
      getGuestUserBasket();
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Router />
    </Elements>
  );
};

export default App;
